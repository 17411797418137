export enum WarehouseSpaceCategory {
  BASIC = 'Basic',
  CLIMATE_CONTROLLED = 'Climate-Controlled',
  REFRIGERATED = 'Refrigerated',
  FREEZER = 'Freezer',
  DRY_FOOD = 'Dry Food',
  HIGH_VALUE = 'High value',
  ODD_SHAPED = 'Odd Shaped',
  OTHER = 'Other',
}
