import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchServiceOrderReceiptDataRoutine,
  loadOrderReceiptAdditionalServicesWithAvailabilityRoutine
} from '@screens/ReceiptPage/routines';
import { IServiceOrderReceiptData } from '@screens/ReceiptPage/model/ServiceOrderReceipt';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';

export interface IReceiptPageReducerState {
  receiptData: IServiceOrderReceiptData;
  additionalServices?: IAdditionalServiceWithAvailability[];
}

const initialState: IReceiptPageReducerState = {
  receiptData: undefined
};

export const serviceOrderReceiptPageReducer = createReducer(initialState, {
  [fetchServiceOrderReceiptDataRoutine.SUCCESS]: (state, { payload }: PayloadAction<IServiceOrderReceiptData>) => {
    state.receiptData = payload;
  },
  [loadOrderReceiptAdditionalServicesWithAvailabilityRoutine.SUCCESS]: (
    state, { payload }: PayloadAction<IAdditionalServiceWithAvailability[]>
  ) => {
    state.additionalServices = payload;
  }
});
