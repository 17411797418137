import { callApi } from '@helpers/api.helper';
import { IBookingCancellationRequest } from '@screens/NeedsDashboard/Bookings/model/BookingCancellationRequest';
import { IBookingSearchParams } from '@screens/NeedsDashboard/Bookings/model/BookingSearchParams';

const bookingsService = {
  fetchBookingsForNeed: ({ page, size, ...rest }: IBookingSearchParams) => callApi({
    method: 'GET',
    endpoint: 'booking/need',
    queryParams: {
      page: page - 1,
      size,
      ...rest
    }
  }),
  cancelBooking: (requestData: IBookingCancellationRequest) => callApi({
    method: 'POST',
    endpoint: 'booking/cancel',
    requestData
  })
};

export default bookingsService;
