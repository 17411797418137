import React from 'react';
import classNames from 'classnames';
import { history } from '@helpers/history.helper';
import styles from './styles.module.scss';

interface ILinkWithAutoScrollButtonProps {
  to: string;
  className?: string;
}

const LinkWithAutoScrollButton: React.FC<ILinkWithAutoScrollButtonProps> = ({
  className, children, to
}) => {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
    history.push(to);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onClick={handleLinkClick}
      className={classNames(styles.container, className)}
    >
      {children}
    </div>
  );
};

export default LinkWithAutoScrollButton;
