import React from 'react';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import SideMenu, { ITabProps } from '@containers/SideMenu';
import { IBindingAction } from '@models/Callbacks';

export interface ISideMenuProps {
  className: string;
  linkClassName?: string;
  activeLinkClassName?: string;
  onItemClick?: IBindingAction;
}

const NeedsSideMenu: React.FC<ISideMenuProps> = (
  { className, linkClassName, activeLinkClassName, onItemClick }
) => {
  const { FAVORITES, ACCOUNT, BOOKINGS, ORDERS, SPACES,
    DASHBOARD, TRANSACTIONS, CARDS_CONFIG, MESSAGES } = NEEDS_DASHBOARD_ENDPOINTS;

  const tabs: ITabProps[] = [
    { title: 'Dashboard Overview', path: DASHBOARD, header: true },
    { title: 'Account', path: ACCOUNT },
    { title: 'Message Center', path: MESSAGES },
    { title: 'Favorites', path: FAVORITES },
    { title: 'Spaces', path: SPACES },
    { title: 'Transactions', path: TRANSACTIONS },
    { title: 'Bookings & Scheduling', path: BOOKINGS, excludeExact: true },
    { title: 'Additional Services', path: ORDERS, excludeExact: true },
    { title: 'Credit Cards', path: CARDS_CONFIG }
  ];

  return (
    <SideMenu
      personalized
      linkClassName={linkClassName}
      activeLinkClassName={activeLinkClassName}
      tabs={tabs}
      className={className}
      onItemClick={onItemClick}
    />
  );
};

export default NeedsSideMenu;
