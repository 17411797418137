import _ from 'lodash';
import { IFullCost, IPriceWithCurrency } from '@models/domain/Booking';

export const toPriceUp = (price: number): string => _.ceil(price, 2).toFixed(2);

export const toPrice = (price: number | IPriceWithCurrency): string => {
  if (typeof price === 'number') {
    return _.round(price, 2).toFixed(2);
  }
  let sign = '';
  if (typeof price === 'object') {
    if (price.currency === 'USD') {
      sign = '$';
    }
    return `${sign}${toPrice(price.amount)}`;
  }
  return price;
};

export const fullCostToDailyPrice = (price: IFullCost): string => {
  let sign = '';
  if (price.currency === 'USD') {
    sign = '$';
  }
  return `${sign}${toPrice(price.pricePerDay)}`;
};

export const fullCostToMonthlyPrice = (price: IFullCost): string => {
  let sign = '';
  if (price.currency === 'USD') {
    sign = '$';
  }
  return `${sign}${toPrice(price.pricePerMonth)}`;
};

export const priceAmountWithCurrencyToPrice = (price: number, currency: string): string => {
  let sign = '';
  if (currency === 'USD') {
    sign = '$';
  }
  return `${sign}${toPrice(price)}`;
};
