import { combineReducers } from 'redux';
import { RootState } from '@root/store';
import { reducerCreator } from '@helpers/reducer.helper';
/* PlopJS import placeholder. Do not remove */
import { ordersTableReducer } from '@screens/NeedsDashboard/Orders/containers/OrdersTable/reducer';
import {
  fetchOrdersRoutine,
  setCurrentPageRoutine
} from '@screens/NeedsDashboard/Orders/routines';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  setCurrentPageRequest: reducerCreator([setCurrentPageRoutine.TRIGGER]),
  fetchOrdersRequest: reducerCreator([fetchOrdersRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  orders: ordersTableReducer
});

const reqs = (state: RootState) => state.needsDashboard.orders.requests;
const orders = (state: RootState) => state.needsDashboard.orders.orders;

export const extractTotalPages = state => orders(state).totalPages;
export const extractCurrentPage = state => orders(state).page;
export const extractPageSize = state => orders(state).pageSize;
export const extractOrders = state => orders(state).items;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractSetOrderCurrentPageLoading = state => reqs(state).setCurrentPageRequest.loading;
export const extractSetOrderCurrentPageError = state => reqs(state).setCurrentPageRequest.error;
export const extractFetchOrdersLoading = state => reqs(state).fetchOrdersRequest.loading;
export const extractFetchOrdersError = state => reqs(state).fetchOrdersRequest.error;
