import { all, takeEvery } from 'redux-saga/effects';
import {
  tryFetchDashboardMessages,
  tryReadMessageFromDashboard,
  trySendMessage
} from '@screens/HavesDashboard/Dashboard/containers/DashboardPage/sagas';
import {
  fetchMessageCenterMessagesRoutine, readMessageFromMessageCenterRoutine,
  sendMessageFromMessageCenterRoutine, setWritingMessageFromMessageCenterRoutine
} from '@screens/HavesDashboard/MessageCenter/routines';
import { extractMessagesPagination } from '@screens/HavesDashboard/MessageCenter/reducers';

export default function* messageCenterPageSagas() {
  yield all([
    yield takeEvery(fetchMessageCenterMessagesRoutine.TRIGGER, tryFetchDashboardMessages(
      fetchMessageCenterMessagesRoutine
    )),
    yield takeEvery(sendMessageFromMessageCenterRoutine.TRIGGER, trySendMessage(
      sendMessageFromMessageCenterRoutine, setWritingMessageFromMessageCenterRoutine,
      fetchMessageCenterMessagesRoutine, extractMessagesPagination
    )),
    yield takeEvery(readMessageFromMessageCenterRoutine.TRIGGER, tryReadMessageFromDashboard(
      readMessageFromMessageCenterRoutine
    ))
  ]);
}
