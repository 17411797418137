import React from 'react';
import AsyncSelect from 'react-select/async';
import { autocompleteService } from '@services/autocomplete.service';
import { toastr } from 'react-redux-toastr';
import lodash from 'lodash';
import { DebounceDelay } from '@common/enum/debounce-delay';

const toOption = ({ id, firstName, lastName, email }) => ({ value: id, label: `${firstName} ${lastName} (${email})` });
const transformToOptionsMapper = results => results.map(toOption);

const UserSelect = ({ onChange, placeholder = 'Type user name or email...', roles = [] }) => {

  const handleLoadOptions = (
    value,
    cb
  ) => {
    autocompleteService.loadUsers({ query: value, roles })
      .then(results => cb(transformToOptionsMapper(results)))
      .catch(err => toastr.error('Unable to load users for autocomplete', err));
  };

  const handleLoadOptionsSetDebounced = lodash.debounce(handleLoadOptions, DebounceDelay.MEDIUM);

  return (
    <AsyncSelect
      placeholder={placeholder}
      loadOptions={handleLoadOptionsSetDebounced}
      defaultOptions
      onChange={onChange}
      cacheOptions
    />
  );
};

export { UserSelect };
