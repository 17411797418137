import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import { IBindingCallback1 } from '@models/Callbacks';
import {
  loadAdditionalServicesListRoutine,
  loadOneAdditionalServiceRoutine, setAdditionalServicesPageRoutine, setSelectedAdditionalServiceRoutine
} from '@screens/HavesDashboard/AdditionalServices/routines';
import { ICurrentUser } from '@screens/Authorization/models/CurrentUser';
import { extractCurrentUser } from '@screens/Authorization/reducers';
import { IServiceShortDto } from '@screens/HavesDashboard/AdditionalServices/model/ServiceShortDto';
import {
  extractAdditionalServicesListItems,
  extractAdditionalServicesListPage,
  extractAdditionalServicesListSize,
  extractAdditionalServicesTotalPages,
  extractLoadAdditionalServicesListLoading
} from '@screens/HavesDashboard/AdditionalServices/reducers';
import { Loader } from 'semantic-ui-react';
import AdditionalServicesList from '@screens/HavesDashboard/AdditionalServices/components/AdditionalServicesList';
import AdditionalServiceModal from '@screens/HavesDashboard/AdditionalServices/containers/AdditionalServiceModal';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import { IServiceDetailsDto } from '@screens/HavesDashboard/AdditionalServices/model/ServiceDetailsDto';
import { IServicePageRequest } from '@screens/HavesDashboard/AdditionalServices/model/ServicePageRequest';
import Pagination from '@components/Pagination';

export interface IAdditionalServicesProps extends IState, IActions {
}

interface IState {
  currentUser?: ICurrentUser;
  servicesList?: IServiceShortDto[];
  servicesListLoading?: boolean;
  totalPages: number;
  page: number;
  size: number;
}

interface IActions {
  loadAdditionalServicesList: IBindingCallback1<IServicePageRequest>;
  loadOneAdditionalService: IBindingCallback1<string>;
  setSelectedService: IBindingCallback1<Partial<IServiceDetailsDto> | undefined>;
  setPage: IBindingCallback1<number>;
}

const AdditionalServices: React.FC<IAdditionalServicesProps> = (
  { loadAdditionalServicesList, currentUser, servicesList, loadOneAdditionalService,
    setSelectedService, totalPages, page, size, setPage, servicesListLoading }
) => {
  useEffect(() => {
    if (currentUser?.id) {
      loadAdditionalServicesList({
        userId: currentUser.id,
        page,
        size
      });
    }
  }, [currentUser, loadAdditionalServicesList, page, size]);

  return (
    <DashboardPageWrapper
      title="Additional Services"
      rightElement={(
        <PrimaryButton
          content="Add new"
          icon="plus"
          labelPosition="left"
          onClick={() => setSelectedService({})}
        />
      )}
    >
      {servicesListLoading || !servicesList
        ? <Loader active inline="centered" />
        : (
          <div>
            <AdditionalServicesList
              services={servicesList}
              loadOneById={loadOneAdditionalService}
            />
            {totalPages > 1 && (
              <Pagination
                totalPages={totalPages}
                setPage={setPage}
                currentPage={page}
              />
            )}
          </div>
        )}
      <AdditionalServiceModal />
    </DashboardPageWrapper>
  );
};

const mapStateToProps: (state) => IState = state => ({
  currentUser: extractCurrentUser(state),
  servicesList: extractAdditionalServicesListItems(state),
  servicesListLoading: extractLoadAdditionalServicesListLoading(state),
  totalPages: extractAdditionalServicesTotalPages(state),
  page: extractAdditionalServicesListPage(state),
  size: extractAdditionalServicesListSize(state)
});

const mapDispatchToProps: IActions = {
  loadAdditionalServicesList: loadAdditionalServicesListRoutine,
  loadOneAdditionalService: loadOneAdditionalServiceRoutine,
  setSelectedService: setSelectedAdditionalServiceRoutine.fulfill,
  setPage: setAdditionalServicesPageRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalServices);
