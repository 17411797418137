import React, { useRef } from 'react';
import { toastr } from 'react-redux-toastr';
import { Button, Form, Grid, GridColumn, GridRow, Label } from 'semantic-ui-react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import FormikInput from '@components/formik/Input';
import { IBindingCallback1 } from '@models/Callbacks';
import { IJoinOurTeamData, supportedFileType } from '@screens/static/JoinOurTeam/model/IJoinOurTeamData';
import FormikSelect from '@components/formik/select/Select';
import { enumAsNullableOptions } from '@helpers/enum.helper';
import { CountryState } from '@models/domain/CountryState';
import FormikTextArea from '@components/formik/TextArea';
import { emailValidation, phoneValidation, zipValidation } from '@screens/Authorization/components/validation';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import InfoPopup from '@components/NewDesign/InfoPopup';
import styles from './styles.module.scss';

export interface IJoinOurTeamFormProps {
  validateAndSaveChanges?: IBindingCallback1<IJoinOurTeamData>;
  setSelectedFile?: IBindingCallback1<any>;
  joinOurTeamLoading?: boolean;
  selectedFile?: any | null;
  initialFormData?: IJoinOurTeamData;
}

export interface IFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  message: string;
  file: FormData;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('First name is required'),
  lastName: Yup.string()
    .trim()
    .required('Last name is required'),
  email: emailValidation,
  phone: phoneValidation,
  address1: Yup.string().trim().required(),
  address2: Yup.string().nullable(),
  city: Yup.string().trim().required(),
  state: Yup.string().trim().required(),
  zip: zipValidation,
  message: Yup.string()
    .trim()
    .required('Message is required')
    .max(800)
});

export function nilAreEqual(o1, o2): undefined | boolean {
  if (!o1 && !o2) {
    return true;
  }
  return undefined;
}

const JoinOurTeamForm: React.FC<IJoinOurTeamFormProps & FormikProps<IFormValues>> = (
  {
    handleSubmit, values, initialValues, isValid,
    setSelectedFile, joinOurTeamLoading, selectedFile
  }
) => {
  const inputRef = useRef<HTMLInputElement>();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const handleFileSelection = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (supportedFileType.includes(ev.target.files[0].type)) {
      setSelectedFile(ev.target.files[0]);
      ev.target.files = null;
    } else {
      toastr.error('File type not supported', 'Please upload pdf file');
    }
  };

  return (
    <Form name="joinOurTeamForm" onSubmit={handleSubmit} className={styles.form}>
      <Grid>
        <GridRow columns={2}>
          <GridColumn>
            <FormikInput
              propsOrFieldName={{
                name: 'firstName',
                placeholder: 'First Name',
                required: true
              }}
              semanticProps={{
                className: styles.inputField
              }}
            />
          </GridColumn>
          <GridColumn>
            <FormikInput
              propsOrFieldName={{
                name: 'lastName',
                placeholder: 'Last Name',
                required: true
              }}
              semanticProps={{
                className: styles.inputField
              }}
            />
          </GridColumn>
        </GridRow>
        <GridRow columns={2}>
          <GridColumn>
            <FormikInput
              propsOrFieldName={{
                name: 'email',
                placeholder: 'Email Address',
                required: true
              }}
              semanticProps={{
                className: styles.inputField
              }}
            />
          </GridColumn>
          <GridColumn>
            <FormikInput
              propsOrFieldName={{
                name: 'phone',
                placeholder: 'Phone No.',
                required: true
              }}
              semanticProps={{
                className: styles.inputField
              }}
            />
          </GridColumn>
        </GridRow>
        <GridRow columns={2}>
          <GridColumn>
            <FormikInput
              propsOrFieldName={{
                name: 'address1',
                placeholder: 'Address Line 1',
                required: true
              }}
              semanticProps={{
                className: styles.inputField
              }}
            />
          </GridColumn>
          <GridColumn>
            <FormikInput
              propsOrFieldName={{
                name: 'address2',
                placeholder: 'Address Line 2'
              }}
              semanticProps={{
                className: styles.inputField
              }}
            />
          </GridColumn>
        </GridRow>
        <GridRow columns={3}>
          <GridColumn width={4}>
            <FormikInput
              propsOrFieldName={{
                name: 'city',
                placeholder: 'City',
                required: true
              }}
              semanticProps={{
                className: styles.inputField
              }}
            />
          </GridColumn>
          <GridColumn width={4}>
            <FormikSelect
              propsOrFieldName={{
                name: 'state',
                placeholder: 'State',
                required: true
              }}
              semanticProps={{
                options: enumAsNullableOptions(CountryState),
                className: styles.inputField
              }}
            />
          </GridColumn>
          <GridColumn width={4}>
            <FormikInput
              propsOrFieldName={{
                name: 'zip',
                placeholder: 'ZIP',
                required: true
              }}
              semanticProps={{
                className: styles.inputField
              }}
            />
          </GridColumn>
        </GridRow>
        <GridRow columns={1}>
          <GridColumn width={8}>
            <input
              name="file"
              ref={inputRef}
              type="file"
              onChange={handleFileSelection}
              style={{ visibility: 'hidden', position: 'absolute' }}
              multiple
              accept="application/pdf"
            />
            <Button
              basic
              disabled={!!selectedFile}
              className={styles.fileButton}
              content="Upload a PDF copy of your resume here"
              onClick={e => handleClick(e)}
            />
          </GridColumn>
          <GridColumn width={4}>
            {
              (selectedFile
              && (
              <Label
                color="blue"
                className={styles.file_label}
                onClick={() => setSelectedFile(null)}
              >
                {selectedFile?.name}
              </Label>
              ))
            }
          </GridColumn>
        </GridRow>
        <GridRow columns={1}>
          <GridColumn width={14}>
            <FormikTextArea
              propsOrFieldName={{
                rows: 10,
                name: 'message',
                placeholder: 'Leave your message here',
                required: true
              }}
              semanticProps={{
                className: styles.inputField
              }}
            />
          </GridColumn>
        </GridRow>
      </Grid>
      <div className={styles.button_with_info}>
        <div className={styles.buttonWrapper}>
          <PrimaryButton
            className={styles.form_button}
            content="SUBMIT"
            type="submit"
            loading={joinOurTeamLoading}
            disabled={joinOurTeamLoading
            || _.isEqualWith(initialValues, values, nilAreEqual)
            || !(isValid && !!selectedFile)}
          />
        </div>
        {(_.isEqualWith(initialValues, values, nilAreEqual) || !(isValid && !!selectedFile)) && (
          <InfoPopup
            iconClassName={styles.info_icon}
            content="Please fill in all the fields and upload the PDF copy of your resume to submit the form"
          />
        )}
      </div>
    </Form>
  );
};

export default withFormik<IJoinOurTeamFormProps, IFormValues>({
  displayName: 'GetInTouchForm',
  enableReinitialize: false,
  validationSchema,
  handleSubmit: ((values, { props }) => {
    props.validateAndSaveChanges(values);
  })
})(JoinOurTeamForm);
