import React from 'react';
import classNames from 'classnames';
import { ISpaceBooked } from '@screens/BookingCheckout/model/BookingCheckout';
import BookingOneSpaceSummary from '@screens/BookingCheckout/components/BookingDetails/BookingOneSpaceSummary';
import { IBriefBookingDto } from '@models/domain/Booking';
import { IBookingPaymentPeriods } from '@screens/BookingCheckout/helpers/bookingPeriods.helper';
import styles from './styles.module.scss';

export interface IBookingSpacesSummaryProps {
  spaces: ISpaceBooked[];
  booking: IBriefBookingDto;
  periods: IBookingPaymentPeriods;
  containerClassName?: string;
  serviceSummaryClassName?: string;
}

const BookingSpacesSummary: React.FC<IBookingSpacesSummaryProps> = (
  {
    spaces,
    booking,
    periods,
    containerClassName,
    serviceSummaryClassName
  }
) => (
  <div className={classNames(styles.container, containerClassName)}>
    {spaces.map(sp => (
      <BookingOneSpaceSummary
        key={sp.id}
        space={sp}
        booking={booking}
        periods={periods}
        className={serviceSummaryClassName}
      />
    ))}
  </div>
);

export default BookingSpacesSummary;
