import React from 'react';
import { Input, InputProps } from 'semantic-ui-react';
import { INPUT_MAX_LENGTH } from '@helpers/validation.helper';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

const LabeledInputWrapper: React.FC<InputProps> = ({ label, ...props }) => {
  const { t } = useTranslation();

  const labelProps = (label as any)?.content ? { ...(label as any), content: t((label as any).content) } : label;

  return (
    <div className={styles.input}>
      <Input label={labelProps} {...props} maxLength={INPUT_MAX_LENGTH} />
    </div>
  );
};
export default LabeledInputWrapper;
