/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import ListView, { IListViewProps } from '@components/ListView';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import styles from './styles.module.scss';

export interface IAdditiveListViewProps<T extends { id }> extends IListViewProps<T> {
  onAddClick: () => void;
  addingDisabled?: boolean;
}

function AdditiveListView<T extends { id; status? }>(
  { onAddClick, loading, items, addingDisabled, ...props }: IAdditiveListViewProps<T>
) {
  const AddButton = useCallback(() => (
    addingDisabled
      ? <div />
      : (
        <div className={styles.add_new}>
          <PrimaryButton
            content="Add"
            icon="plus"
            labelPosition="left"
            onClick={onAddClick}
          />
        </div>
      )
  ), [addingDisabled, onAddClick]);

  return (
    <>
      {(!loading && items.length > 0) && <AddButton />}
      <ListView {...props} loading={loading} items={items} placeholderComponent={<AddButton />} />
    </>
  );
}

export default AdditiveListView;
