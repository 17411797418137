import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getInitialPageableState } from '@models/domain/PageableReducerState';
import { IMemberShort } from '@screens/AdminDashboard/MembersSummary/model/IMemberShort';
import {
  fetchMemberDetailsRoutine, fetchMembersByFilterRoutine,
  fetchMembersRoutine, hideMemberDetailsRoutine, resetMemberDetailsRoutine, saveMemberProfileRoutine,
  setPageRoutine, setWritingMessageFromMembersRoutine
} from '@screens/AdminDashboard/MembersSummary/routines';

const initialState = {
  list: getInitialPageableState<IMemberShort>(),
  details: undefined,
  writing: false
};

export const membersSummaryReducer = createReducer(initialState, {
  [fetchMembersRoutine.SUCCESS]: (state, { payload }) => {
    state.list.items = payload.items;
    state.list.totalResults = payload.totalResults;
    state.list.totalPages = payload.totalPages;
  },
  [fetchMembersByFilterRoutine.SUCCESS]: (state, { payload }) => {
    state.list.items = payload.items;
    state.list.totalResults = payload.totalResults;
    state.list.totalPages = payload.totalPages;
  },
  [fetchMemberDetailsRoutine.SUCCESS]: (state, { payload }) => {
    state.details = payload;
  },
  [saveMemberProfileRoutine.SUCCESS]: (state, { payload }) => {
    state.details = payload;
  },
  [resetMemberDetailsRoutine.FULFILL]: state => {
    state.details = {};
  },
  [hideMemberDetailsRoutine.FULFILL]: state => {
    state.details = undefined;
  },
  [setPageRoutine.FULFILL]: (state, { payload }) => {
    state.list.page = payload;
  },
  [setWritingMessageFromMembersRoutine.FULFILL]: (state, { payload }: PayloadAction<boolean>) => {
    state.writing = payload;
  }
});
