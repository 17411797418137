import React from 'react';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import classNames from 'classnames';
import { Checkbox } from 'semantic-ui-react';
import styles from './styles.module.scss';

const Switch: React.FC<CheckboxProps> = props => (
  <Checkbox
    {...props}
    toggle
    className={classNames(props.className, styles.switch)}
  >
    {props.children}
  </Checkbox>
);

export default Switch;
