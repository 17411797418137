import { all, put } from 'redux-saga/effects';
import { showErrorModalRoutine } from '@screens/ErrorModal/routines';

export function* showModalError(title: string, e: Error, redirect?: string) {
  yield put(showErrorModalRoutine.fulfill({
    title,
    message: e.message,
    tip: (e as any).tip,
    redirect
  }));
}

export default function* errorModalPageSagas() {
  yield all([
  ]);
}
