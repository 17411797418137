/* eslint-disable max-len */
import { createRoutine } from 'redux-saga-routines';

const createDashboardRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`HAVES_DASHBOARD__DASHBOARD:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const readMessageFromDashboardRoutine = createDashboardRoutine('READ_MESSAGE_FROM_DASHBOARD');
export const setWritingMessageFromDashboardRoutine = createDashboardRoutine('SET_WRITING_MESSAGE_FROM_DASHBOARD');
export const setMessageFullFromDashboardRoutine = createDashboardRoutine('SET_MESSAGE_FULL_FROM_DASHBOARD');
export const sendMessageFromDashboardRoutine = createDashboardRoutine('SEND_MESSAGE_FROM_DASHBOARD');
export const fetchDashboardMessagesRoutine = createDashboardRoutine('FETCH_DASHBOARD_MESSAGES');
export const toggleFavoriteBuildingRoutine = createDashboardRoutine('TOGGLE_FAVORITE_BUILDING');
export const fetchMyTransactionsRoutine = createDashboardRoutine('FETCH_MY_TRANSACTIONS');
export const fetchMyBuildingsRoutine = createDashboardRoutine('FETCH_MY_BUILDINGS');
