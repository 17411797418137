import React from 'react';
import classNames from 'classnames';
import GeneralTypographyBlock, { IGeneralTypographyBlockProps } from '@components/NewDesign/Typography/GeneralTypographyBlock';
import styles from './styles.module.scss';

const SubHeader2: React.FC<IGeneralTypographyBlockProps> = props => (
  <GeneralTypographyBlock
    {...props}
    tag="h5"
    className={classNames(styles.container, props.className)}
  >
    {props.children}
  </GeneralTypographyBlock>
);

export default SubHeader2;
