import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { IBindingCallback1 } from '@models/Callbacks';
import { IJoinOurTeamData } from '@screens/static/JoinOurTeam/model/IJoinOurTeamData';
import {
  extractSubmitJoinOurTeamFormError,
  extractSubmitJoinOurTeamFormLoading
} from '@screens/static/JoinOurTeam/reducers';
import {
  submitJoinOurTeamRoutine
} from '@screens/static/routines';
import StaticPageWrapper from '@components/NewDesign/StaticPageWrapper';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import JoinOurTeamForm from '@screens/static/JoinOurTeam/components/JoinOurTeamForm';
import MetaTags from '@components/MetaTags';

export interface IJoinOurTeamFromProps {
  saveChanges: IBindingCallback1<FormData>;
  joinOurTeamLoading?: boolean;
  selectedFile?: any | null;
  setSelectedFile?: IBindingCallback1<any>;
  validateAndSaveChanges?: IBindingCallback1<IJoinOurTeamData>;
}

const JoinOurTeamPage: React.FC<IJoinOurTeamFromProps> = (
  { saveChanges, joinOurTeamLoading }
) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const validateAndSaveChanges = (value: IJoinOurTeamData) => {
    if (selectedFile !== null) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('firstName', value.firstName);
      formData.append('lastName', value.lastName);
      formData.append('email', value.email);
      formData.append('phone', value.phone);
      formData.append('message', value.message);
      formData.append('address1', value.address1 || '');
      formData.append('address2', value.address2 || '');
      formData.append('city', value.city || '');
      formData.append('state', value.state || '');
      formData.append('zip', value.zip || '');
      saveChanges(formData);
    } else {
      toastr.error('File is Required', 'Please upload pdf file');
    }
  };
  return (
    <StaticPageWrapper title="Join Our Team">
      <MetaTags
        title="Join Our Team"
        withSuffix
        description="We are a rapidly growing company, and are always looking for exceptional talent
                  in a variety of functional areas. Drop us a line here, and tell us how you can
                  add value to Shared Spaces"
      />
      <BodyText1 center marginTop>
        Do you have what it takes to help Shared Spaces succeed?
      </BodyText1>
      <BodyText1 center marginTop>
        We are a rapidly growing company, and are always looking for exceptional talent
        in a variety of functional areas. Drop us a line here, and tell us how you can
        add value to Shared Spaces
      </BodyText1>
      <JoinOurTeamForm
        selectedFile={selectedFile}
        joinOurTeamLoading={joinOurTeamLoading}
        setSelectedFile={setSelectedFile}
        validateAndSaveChanges={validateAndSaveChanges}
      />
    </StaticPageWrapper>
  );
};

const mapStateToProps = state => ({
  joinOurTeamLoading: extractSubmitJoinOurTeamFormLoading(state),
  JoinOurTeamError: extractSubmitJoinOurTeamFormError(state)
});

const mapDispatchToProps = {
  saveChanges: submitJoinOurTeamRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinOurTeamPage);
