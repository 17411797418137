import { fetchTransactionsRoutine, setPageRoutine } from '@screens/AdminDashboard/Transactions/routines';
import { getInitialPageableState } from '@models/domain/PageableReducerState';
import { createReducer } from '@reduxjs/toolkit';
import { ITransactionDetailsDto } from '@screens/AdminDashboard/Transactions/model/TransactionDetailsDto';

export const transactionsReducer = createReducer(getInitialPageableState<ITransactionDetailsDto>(), {
  [fetchTransactionsRoutine.SUCCESS]: (state, { payload }) => {
    state.items = payload.items;
    state.totalResults = payload.totalResults;
    state.totalPages = payload.totalPages;
  },
  [setPageRoutine.FULFILL]: (state, { payload }) => {
    state.page = payload;
  }
});
