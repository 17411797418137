import { callApi } from '@helpers/api.helper';
import { IMakeOrderRequest } from '@models/domain/additional_service/MakeOrderRequest';
import { ILoadAdditionalServiceWithAvailabilityRequest } from '@models/domain/additional_service/LoadAdditionalServiceWithAvailabilityRequest';

const additionalServicesOrderService = {
  createAdditionalServicesOrder: (requestData: IMakeOrderRequest) => callApi({
    endpoint: 'service_orders',
    method: 'PUT',
    requestData
  }),
  fetchServicesWithAvailability: (requestData: ILoadAdditionalServiceWithAvailabilityRequest) => callApi({
    endpoint: 'services/building',
    method: 'POST',
    requestData
  })
};

export default additionalServicesOrderService;
