import React from 'react';
import styles from './styles.module.scss';
import { Modal } from 'semantic-ui-react';
import { ModalProps } from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import classNames from 'classnames';

type IModalWrapper = ModalProps

const ModalWrapper: React.FC<IModalWrapper> = props => {
  const { className, children } = props;

  return (
    <Modal
      {...props}
      className={classNames(styles.container, className)}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;
