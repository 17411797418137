import { combineReducers } from 'redux';
import { RootState } from '@root/store';
import { reducerCreator } from '@helpers/reducer.helper';
import { buildingsAndSpacesReducer } from '@screens/AdminDashboard/BuildingsAndSpaces/containers/BuildingsAndSpacesPage/reducer';
/* PlopJS import placeholder. Do not remove */
import {
  createBuildingRoutine,
  fetchAllTagsRoutine,
  fetchBuildingsSummaryRoutine
} from '@screens/AdminDashboard/BuildingsAndSpaces/routines';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  fetchBuildingsSummaryRequest: reducerCreator([fetchBuildingsSummaryRoutine.TRIGGER]),
  fetchAllTagsRequest: reducerCreator([fetchAllTagsRoutine.TRIGGER]),
  createBuildingRequest: reducerCreator([createBuildingRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  data: buildingsAndSpacesReducer
});

const reqs = (state: RootState) => state.adminDashboard.buildingsAndSpaces.requests;
const data = (state: RootState) => state.adminDashboard.buildingsAndSpaces.data;

export const extractBuildingsSummary = state => data(state).list.items;
export const extractTotalPages = state => data(state).list.totalPages;
export const extractTotalResults = state => data(state).list.totalResults;
export const extractCurrentPage = state => data(state).list.page;
export const extractCurrentSize = state => data(state).list.pageSize;
export const extractTags = state => data(state).tags;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractFetchBuildingsSummaryLoading = state => reqs(state).fetchBuildingsSummaryRequest.loading;
export const extractFetchBuildingsSummaryError = state => reqs(state).fetchBuildingsSummaryRequest.error;
export const extractFetchAllTagsLoading = state => reqs(state).fetchAllTagsRequest.loading;
export const extractFetchAllTagsError = state => reqs(state).fetchAllTagsRequest.error;
export const extractCreateBuildingLoading = state => reqs(state).createBuildingRequest.loading;
export const extractCreateBuildingError = state => reqs(state).createBuildingRequest.error;
