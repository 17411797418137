import React from 'react';
import { Modal, ModalContent } from 'semantic-ui-react';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import styles from './styles.module.scss';
import GoogleMap from '@components/GoogleMap';

export interface IBuildingOnMapsProps {
  showMap: boolean;
  setShowMap: (val: boolean) => void;
  building?: IBuildingForDisplaying;
}

const BuildingOnMaps: React.FC<IBuildingOnMapsProps> = ({ showMap, setShowMap, building }) => (
  <Modal open={showMap} className={styles.custom_modal} onClose={() => setShowMap(false)} closeIcon basic>
    <ModalContent>
      <p>{`Address: ${building?.location?.address || 'Unknown'}`}</p>
      {building?.location && (
        <GoogleMap
          location={{ lat: building.location.lat, lng: building.location.lon }}
        />
      )}
    </ModalContent>
  </Modal>
);

export default BuildingOnMaps;
