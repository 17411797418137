import React from 'react';
import { connect } from 'react-redux';
import { IExpertFormData } from '@screens/static/Expert/model/IExpertFormData';
import { IBindingCallback1 } from '@models/Callbacks';
import {
  extractSubmitExpertPageFormLoading,
  extractSubmitExpertPageFormError
} from '@screens/static/Expert/reducers';
import { submitExpertPageRoutine } from '@screens/static/routines';
import StaticPageWrapper from '@components/NewDesign/StaticPageWrapper';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import ExpertPageForm from '@screens/static/Expert/components/ExpertPageForm';
import MetaTags from '@components/MetaTags';

export interface IExpertPageFromProps {
  saveChanges: IBindingCallback1<IExpertFormData>;
  expertLoading?: boolean;
}

const ExpertPage: React.FC<IExpertPageFromProps> = (
  { expertLoading, saveChanges }
) => (
  <StaticPageWrapper title="I am a Logistics Expert">
    <MetaTags
      title="I am a Logistics Expert"
      withSuffix
      description="I have expertise in warehouse logistics, office space allocation,
                or other relevant skills, and would love to partner and help others."
    />
    <BodyText1 center>
      I have expertise in warehouse logistics, office space allocation,
      or other relevant skills, and would love to partner and help others.
    </BodyText1>
    <ExpertPageForm
      saveChanges={saveChanges}
      expertLoading={expertLoading}
    />
  </StaticPageWrapper>
);

const mapStateToProps = state => ({
  expertLoading: extractSubmitExpertPageFormLoading(state),
  expertError: extractSubmitExpertPageFormError(state)
});

const mapDispatchToProps = {
  saveChanges: submitExpertPageRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpertPage);
