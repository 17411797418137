import React from 'react';

export interface ITypographyTagProps extends React.HTMLAttributes<HTMLHeadingElement> {
  typographyTag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
}

const TypographyTag: React.FC<ITypographyTagProps> = (
  {
    typographyTag = 'p',
    children,
    className
  }
) => {
  const Tag = ({ ...props }: React.HTMLAttributes<HTMLHeadingElement>) => (
    React.createElement(typographyTag, props, children)
  );

  return (
    <Tag className={className}>
      {children}
    </Tag>
  );
};

export default TypographyTag;
