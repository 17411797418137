import React from 'react';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { IQueryData } from '@screens/BrowseSpaces/model/QueryData';
import SearchBarBase from '@components/SearchBarBase';

export interface ISearchBarProps {
  hide?: boolean;
  performSearch: IBindingAction;
  searchLoading: boolean;
  searchQuery: IQueryData;
  clearFilters: IBindingAction;
  updateFilters: IBindingCallback1<IQueryData>;
}

const SearchBar: React.FC<ISearchBarProps> = (
  { hide, performSearch, searchLoading, searchQuery, clearFilters, updateFilters }
) => {
  const handleSearchClick = (query: IQueryData) => {
    updateFilters(query);
    performSearch();
  };

  const handleClearFiltersClick = () => {
    clearFilters();
    performSearch();
  };

  const handleClearSearchClick = (query: IQueryData) => {
    const pendingText = '';
    updateFilters({ ...query, pendingText });
    performSearch();
  };

  return (
    <SearchBarBase
      hide={hide}
      previousQuery={searchQuery}
      handleSearchClick={handleSearchClick}
      onClearFiltersClick={handleClearFiltersClick}
      onClearSearchClick={handleClearSearchClick}
      searchLoading={searchLoading}
    />
  );
};

export default SearchBar;
