import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IBindingCallback1 } from '@models/Callbacks';
import {
  fetchBookedBuildingsRoutine,
  setPageRoutine,
  toggleFavoriteBuildingRoutine
} from '@screens/NeedsDashboard/BookedSpaces/routines';
import { IResultBuildingItem } from '@screens/BrowseSpaces/model/ResultItem';
import {
  extractBuildings,
  extractFetchMyBookedSpacesLoading,
  extractPage,
  extractPageSize,
  extractTotalPages
} from '@screens/NeedsDashboard/BookedSpaces/reducers';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import { IPageable } from '@models/domain/PageableReducerState';
import ResultGridWithPagination from '@components/ResultGridWithPagination';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';

export interface IBookedSpacesProps extends IPageable {
  loadBuildings: IBindingCallback1<IPageRequest>;
  buildings: IResultBuildingItem[];
  buildingsLoading: boolean;
  toggleFavorite: IBindingCallback1<string>;
  setPage: IBindingCallback1<number>;
}

const BookedSpaces: React.FC<IBookedSpacesProps> = (
  { loadBuildings, buildings, buildingsLoading, toggleFavorite, page, setPage, pageSize, totalPages }
) => {
  useEffect(() => {
    if (loadBuildings) {
      loadBuildings({ page, size: pageSize });
    }
  }, [loadBuildings, page, pageSize]);

  return (
    <DashboardPageWrapper title="My Booked Spaces">
      <DashboardSectionWrapper>
        <ResultGridWithPagination
          setPage={setPage}
          loading={buildingsLoading}
          items={buildings}
          page={page}
          toggleFavorite={toggleFavorite}
          totalPages={totalPages}
        />
      </DashboardSectionWrapper>
    </DashboardPageWrapper>
  );
};

const mapStateToProps = state => ({
  buildings: extractBuildings(state),
  buildingsLoading: extractFetchMyBookedSpacesLoading(state),
  page: extractPage(state),
  pageSize: extractPageSize(state),
  totalPages: extractTotalPages(state)
});

const mapDispatchToProps = {
  loadBuildings: fetchBookedBuildingsRoutine,
  toggleFavorite: toggleFavoriteBuildingRoutine,
  setPage: setPageRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(BookedSpaces);
