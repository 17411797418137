import { all, takeEvery } from 'redux-saga/effects';
import {
  fetchDashboardMessagesRoutine,
  readMessageFromDashboardRoutine,
  sendMessageFromDashboardRoutine,
  setWritingMessageFromDashboardRoutine
} from '@screens/AdminDashboard/Dashboard/routines';
import { tryFetchDashboardMessages, trySendMessage, tryReadMessageFromDashboard }
  from '@screens/HavesDashboard/Dashboard/containers/DashboardPage/sagas';

export default function* dashboardPageSagas() {
  yield all([
    yield takeEvery(fetchDashboardMessagesRoutine.TRIGGER, tryFetchDashboardMessages(fetchDashboardMessagesRoutine)),
    yield takeEvery(sendMessageFromDashboardRoutine.TRIGGER, trySendMessage(
      sendMessageFromDashboardRoutine, setWritingMessageFromDashboardRoutine, fetchDashboardMessagesRoutine
    )),
    yield takeEvery(readMessageFromDashboardRoutine.TRIGGER, tryReadMessageFromDashboard(
      readMessageFromDashboardRoutine
    ))
  ]);
}
