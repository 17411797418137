/* eslint-disable max-len */
import { createRoutine } from 'redux-saga-routines';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';

const createOrdersPageRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`NEEDS_DASHBOARD__ORDERS_PAGE:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const fetchOrdersRoutine = createOrdersPageRoutine<IPageRequest>('FETCH_ORDERS');
export const setCurrentPageRoutine = createOrdersPageRoutine<number>('SET_ORDERS_CURRENT_PAGE');

