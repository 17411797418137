import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { closeErrorModalRoutine, showErrorModalRoutine } from '@screens/ErrorModal/routines';

export interface IErrorModalInfo {
  title: string;
  message: string;
  tip?: string;
  redirect?: string;
}

export interface IErrorModalReducerState {
  info?: IErrorModalInfo;
}

const initialState: IErrorModalReducerState = {
};

const TOASTR_ADD_ACTION = '@ReduxToastr/toastr/ADD';
const TOASTR_ADD_ERROR_TYPE = 'error';

export interface IToastrPayload {
  type: string;
  title: string;
  message: string;
}

export const errorModalReducer = createReducer(initialState, {
  [TOASTR_ADD_ACTION]: (state, { payload }: PayloadAction<IToastrPayload>) => {
    const { type } = payload;
    if (type !== TOASTR_ADD_ERROR_TYPE) {
      return;
    }
    state.info = {
      message: payload.message,
      title: payload.title
    };
  },
  [showErrorModalRoutine.FULFILL]: (state, { payload }: PayloadAction<IErrorModalInfo>) => {
    state.info = payload;
  },
  [closeErrorModalRoutine.FULFILL]: state => {
    state.info = undefined;
  }
});
