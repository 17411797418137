import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';
import { cancelBookingRoutine, fetchBookingsForNeedRoutine, setExpandedBookingRoutine } from '@screens/NeedsDashboard/Bookings/routines';
import bookingsService from '@screens/NeedsDashboard/Bookings/service/bookings.service';
import { IBookingCancellationRequest } from '@screens/NeedsDashboard/Bookings/model/BookingCancellationRequest';
import { extractActiveBookings } from '@screens/NeedsDashboard/Bookings/reducers';
import { IBookingSearchParams } from '@screens/NeedsDashboard/Bookings/model/BookingSearchParams';

function* tryFetchBookingsForNeed({ payload }: PayloadAction<IBookingSearchParams>) {
  try {
    const resp = yield call(bookingsService.fetchBookingsForNeed, payload);
    yield put(fetchBookingsForNeedRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load bookings', e?.message);
    yield put(fetchBookingsForNeedRoutine.failure(e?.message));
  }
}

function* tryCancelBooking({ payload }: PayloadAction<IBookingCancellationRequest>) {
  try {
    yield call(bookingsService.cancelBooking, payload);
    yield put(cancelBookingRoutine.success(payload));
    const bookings = yield select(extractActiveBookings);
    const booking = bookings.find(b => b.booking.id === payload.bookingId);
    yield put(setExpandedBookingRoutine.fulfill(booking));
    toastr.success('Success', 'The end date has been updated successfully');
  } catch (e) {
    toastr.error('Unable to cancel booking', e?.message);
    yield put(cancelBookingRoutine.failure(e?.message));
  }
}

export default function* bookingsTableSagas() {
  yield all([
    yield takeEvery(fetchBookingsForNeedRoutine.TRIGGER, tryFetchBookingsForNeed),
    yield takeEvery(cancelBookingRoutine.TRIGGER, tryCancelBooking)
  ]);
}
