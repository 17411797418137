import React from 'react';
import Header4 from '@components/NewDesign/Typography/Headers/Header4';
import s from './styles.module.scss';

export interface IBuildingTitleProps {
  buildingName: string;
}

const BuildingTitle: React.FC<IBuildingTitleProps> = (
  { buildingName }
) => (
  <Header4 disableTranslation className={s.content}>{buildingName}</Header4>
);

export default BuildingTitle;
