const HOST = 'https://www.sharedspaces.io';

export const STATIC_SITE_LINKS = {
  HOME: `${HOST}/`,
  HOW_IT_WORKS: `${HOST}/how-it-works-seeking-space`,
  PARTNER_WITH_US: `${HOST}/partner-with-us`,
  JOIN_OUR_TEAM: `${HOST}/join-our-team`,
  OUR_STORY: `${HOST}/our-story`,
  CONTACT_US: `${HOST}/contact-us`,
  CANT_FIND: `${HOST}/cant-find`,
  BLOG: `${HOST}/blog`
};
