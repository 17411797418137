import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { loadBookingDetailsRoutine } from '@screens/BookingCheckout/routines';
import {
  extractBookingDetails,
  extractLoadBookingDetailsLoading
} from '@screens/BookingCheckout/reducers';
import { bookingToPeriods } from '@screens/BookingCheckout/helpers/bookingPeriods.helper';
import BookingSpacesSummary from '@screens/BookingCheckout/components/BookingDetails/BookingSpacesSummary';
import { IBookingCheckoutData } from '@screens/BookingCheckout/model/BookingCheckout';
import MetaTags from '@components/MetaTags';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';
import DividingLine from '@components/NewDesign/DividingLine';
import BuildingDetails from '@containers/Schedules/BookingDetailsModal/BuildingDetails';
import NeedsBookingDetails from '@containers/Schedules/BookingDetailsModal/BookingDetails';
import { Role } from '@screens/Authorization/models/Roles';
import {
  cancelBookingRoutine,
  setEditedScheduleRoutine
} from '@screens/NeedsDashboard/Bookings/routines';
import { extractCancelBookingLoading } from '@screens/NeedsDashboard/Bookings/reducers';
import {
  TransactionsBlockWithHeader
} from 'screens/NeedsDashboard/Bookings/components/TransactionsBlockWithHeader';
import { ContentSlider } from '@screens/NeedsDashboard/Bookings/components/ContentSlider';
import { DropOffsAndPickUpsTable } from '@screens/NeedsDashboard/Bookings/components/DropOffsAndPickUpsTable';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import { useScrollToElement } from '@root/hooks/use-scroll-to-element';
import { getBookedSpacesDetails } from '@helpers/bookings.helper';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/Bookings/model/ScheduleResponse';
import { BookingStatus } from '@models/domain/BookingStatus';
import styles from './styles.module.scss';

const BookingPage = () => {
  const { id: bookingId } = useParams<{id: string}>();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    bookingDetails,
    isBookingDetailsLoading,
    isCancelBookingLoading
  } = useSelector(state => ({
    bookingDetails: (extractBookingDetails(state) ?? {}) as IBookingCheckoutData,
    isBookingDetailsLoading: extractLoadBookingDetailsLoading(state),
    isCancelBookingLoading: extractCancelBookingLoading(state)
  }));
  const [isSchedulingOpened, setIsSchedulingOpened] = React.useState(false);
  useScrollToElement({ callback: () => setIsSchedulingOpened(true) });

  const { booking: bookingWithSpaces, building } = bookingDetails as IBookingCheckoutData;

  useEffect(() => {
    if (bookingId) {
      dispatch(loadBookingDetailsRoutine(bookingId));
    }
  }, [bookingId, dispatch]);

  const periods = useMemo(
    () => (bookingWithSpaces?.booking?.id ? bookingToPeriods(bookingWithSpaces.booking) : {}),
    [bookingWithSpaces]
  );

  const handleCancelBooking = useCallback(payload => {
    dispatch(cancelBookingRoutine.trigger(payload));
  }, [dispatch]);

  const handleOpenCreateScheduleModal = useCallback(() => {
    dispatch(setEditedScheduleRoutine.fulfill({} as IScheduleResponseDto));
  }, [dispatch]);

  if (isBookingDetailsLoading || !bookingWithSpaces?.booking?.id) {
    return <Loader active inline="centered" />;
  }

  const title = `${building?.buildingName} — ${getBookedSpacesDetails(bookingDetails?.templatesToAmounts)}`;

  const isBookingConfirmed = bookingWithSpaces?.booking?.status === BookingStatus.CONFIRMED;

  return (
    <div className={styles.container}>
      <MetaTags
        title={`Booking | ${building?.buildingName}`}
        withSuffix
        description={building?.description}
      />
      <div className={styles.container__top_buttons}>
        <SmallLinkButton
          to=""
          onClick={() => history.goBack()}
          left
        >
          Back to Bookings
        </SmallLinkButton>
      </div>
      <Header3 className={styles.header}>{title}</Header3>
      <NeedsBookingDetails
        booking={bookingWithSpaces.booking}
        onCancellationSubmit={handleCancelBooking}
        cancellationLoading={isCancelBookingLoading}
        currentRole={Role.NEED}
        hideHeader
      />
      <BookingSpacesSummary
        spaces={bookingWithSpaces.spaces || []}
        booking={bookingWithSpaces.booking}
        periods={periods}
        containerClassName={styles.services_summary__container}
        serviceSummaryClassName={styles.services_summary__summary_row}
      />
      <TransactionsBlockWithHeader bookingId={bookingId} buildingId={building.id} />
      <DividingLine className={styles.dividing_line} />
      <ContentSlider title="Building details">
        <BuildingDetails
          building={building}
          showDividingLine={false}
          hideHeader
        />
      </ContentSlider>
      <DividingLine className={styles.dividing_line} />
      <ContentSlider
        title="Drop-offs and pick-ups"
        id="drop-offs-and-pick-ups-section"
        open={isSchedulingOpened}
        rightTitleComponent={isBookingConfirmed && (
          <PrimaryButton
            content="Add"
            icon="plus"
            labelPosition="left"
            marginZero
            onClick={handleOpenCreateScheduleModal}
          />
        )}
      >
        <DropOffsAndPickUpsTable />
      </ContentSlider>
    </div>
  );
};

export { BookingPage };
