import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Pagination from '@components/Pagination';
import BuildingsAndSpacesSection from '@screens/AdminDashboard/BuildingsAndSpaces/components/BuildingsAndSpacesSection';
import {
  fetchBuildingsSummaryRoutine,
  setPageRoutine,
  fetchAllTagsRoutine,
  createBuildingRoutine
} from '@screens/AdminDashboard/BuildingsAndSpaces/routines';
import { IPageable } from '@models/domain/PageableReducerState';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import {
  extractBuildingsSummary, extractCreateBuildingLoading,
  extractCurrentPage,
  extractCurrentSize, extractFetchAllTagsLoading,
  extractFetchBuildingsSummaryLoading, extractTags,
  extractTotalPages,
  extractTotalResults
} from '@screens/AdminDashboard/BuildingsAndSpaces/reducers';
import { IBuildingsSummaryShort } from '@screens/AdminDashboard/BuildingsAndSpaces/model/IBuildingsSummaryShort';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import {
  CreateBuildingForHaveButtonWithModal
} from '@screens/AdminDashboard/BuildingsAndSpaces/components/CreateBuildingForHaveButtonWithModal';
import { extractCurrentUser } from '@screens/Authorization/reducers';
import { IBuildingForSaveWithHaveId } from '@screens/BuildingEditor/model/BuildingForSave';

export interface IBuildingsAndSpacesProps extends IPageable {
  buildingsSummary: IBuildingsSummaryShort[];
  buildingsSummaryLoading: boolean;
  setPage: IBindingCallback1<number>;
  fetchBuildingsSummary: IBindingCallback1<IPageRequest>;

  loadTags: IBindingAction;
  tagsLoading: boolean;
  onCreateBuilding: IBindingCallback1<IBuildingForSaveWithHaveId>;
  creationLoading: boolean;
  tags: string[];
}

const BuildingsAndSpaces: React.FC<IBuildingsAndSpacesProps> = (
  {
    buildingsSummary,
    buildingsSummaryLoading,
    totalPages,
    pageSize,
    page,
    fetchBuildingsSummary,
    setPage,
    loadTags,
    tagsLoading,
    onCreateBuilding,
    creationLoading,
    tags
  }
) => {
  useEffect(() => {
    if (fetchBuildingsSummary) {
      fetchBuildingsSummary({ size: pageSize, page });
    }
  }, [fetchBuildingsSummary, page, pageSize]);

  return (
    <DashboardPageWrapper
      title="Buildings and Spaces"
      rightElement={(
        <CreateBuildingForHaveButtonWithModal
          tags={tags}
          loadTags={loadTags}
          tagsLoading={tagsLoading}
          creationLoading={creationLoading}
          onCreateBuilding={onCreateBuilding}
        />
      )}
    >
      <DashboardSectionWrapper>
        <BuildingsAndSpacesSection
          items={buildingsSummary}
          fetchItems={() => null}
          itemsLoading={buildingsSummaryLoading}
        />
        {!buildingsSummaryLoading && totalPages > 1 && (
          <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
        )}
      </DashboardSectionWrapper>
    </DashboardPageWrapper>
  );
};

const mapStateToProps = state => ({
  buildingsSummary: extractBuildingsSummary(state),
  buildingsSummaryLoading: extractFetchBuildingsSummaryLoading(state),
  totalResults: extractTotalResults(state),
  totalPages: extractTotalPages(state),
  page: extractCurrentPage(state),
  pageSize: extractCurrentSize(state),
  currentUser: extractCurrentUser(state),
  tagsLoading: extractFetchAllTagsLoading(state),
  creationLoading: extractCreateBuildingLoading(state),
  tags: extractTags(state)
});

const mapDispatchToProps = {
  fetchBuildingsSummary: fetchBuildingsSummaryRoutine,
  setPage: setPageRoutine.fulfill,
  loadTags: fetchAllTagsRoutine,
  onCreateBuilding: createBuildingRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingsAndSpaces);
