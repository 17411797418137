import React from 'react';
import styles from '@screens/NeedsDashboard/Dashboard/components/TransactionsSection/styles.module.scss';
import { Image, TableCell, TableRow } from 'semantic-ui-react';
import { IMemberShort } from '@screens/AdminDashboard/MembersSummary/model/IMemberShort';
import noAvatar from '@images/no_avatar.webp';
import classNames from 'classnames';
import moment from 'moment';

interface IMemberRowProps {
  member: IMemberShort;
  onClick: () => void;
}

const MemberRow: React.FC<IMemberRowProps> = ({ member, onClick }) => (
  <TableRow
    key={member.id}
    className={classNames(styles.row, member.blocked && styles.blocked)}
    onClick={onClick}
  >
    <TableCell>
      <Image className={styles.avatar_image} src={member.avatar || noAvatar} circular />
    </TableCell>
    <TableCell className={styles.ellipsis} verticalAlign="middle">
      {`${member.firstName || ''} ${member.lastName || ''}`}
    </TableCell>
    <TableCell className={styles.ellipsis} verticalAlign="middle">
      {member.email}
    </TableCell>
    <TableCell className={styles.ellipsis} verticalAlign="middle">
      {member.roles.length ? member.roles[0] : ''}
    </TableCell>
    <TableCell className={styles.ellipsis} verticalAlign="middle">
      {member.createdAt ? moment(member.createdAt).format('YYYY-MM-DD') : ''}
    </TableCell>
  </TableRow>
);

export default MemberRow;
