import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IInformationSectionProps, InformationSection } from '@components/InformationSection';
import BuildingsSummaryRow
  from '@screens/AdminDashboard/BuildingsAndSpaces/components/BuildingsAndSpacesSection/BuildingsSummaryRow';
import { IBuildingsSummaryShort } from '@screens/AdminDashboard/BuildingsAndSpaces/model/IBuildingsSummaryShort';
import NoDataContainer from '@components/NoDataContainer';
import TableWrapper from '@components/NewDesign/Table/TableWrapper';
import { dashboardBuildingLink } from '@screens/BrowseSpaces/components/layouts/cards/link.helper';

export type IBuildingsAndSpacesSectionProps = IInformationSectionProps<IBuildingsSummaryShort>

const BuildingsAndSpacesSection: React.FC<IBuildingsAndSpacesSectionProps> = (
  {
    ...props
  }
) => {
  const history = useHistory();
  const location = useLocation();

  const buildingSummaryToItem = useCallback((building: IBuildingsSummaryShort) => {
    const { id, buildingName } = building;

    return (
      <BuildingsSummaryRow
        key={id}
        building={building}
        onClick={() => history.push(dashboardBuildingLink({ id, name: buildingName }, location))}
      />
    );
  }, [history, location]);

  return (
    <>
      <InformationSection
        {...props}
        hideTitle
        renderItem={buildingSummaryToItem}
        itemsWrapper={TableWrapper}
        itemsWrapperProps={{
          titles: ['Image', 'Building Name', 'Building Type', 'Address']
        }}
        noItemsPlaceholder={() => <></>}
      />
      {{ ...props }.items.length === 0 ? <NoDataContainer message="There are no buildings yet" /> : ''}
    </>
  );
};

export default BuildingsAndSpacesSection;
