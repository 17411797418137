import { createRoutine } from 'redux-saga-routines';

const createBuildingDetailsRoutine = actionName => createRoutine(`BUILDING_DETAILS:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const loadAdditionalServicesWithAvailabilityRoutine = createBuildingDetailsRoutine('LOAD_ADDITIONAL_SERVICES_WITH_AVAILABILITY');
export const setWritingMessageFromBrowseBuildingDetailsRoutine = createBuildingDetailsRoutine('SET_WRITING_MESSAGE_FROM_BROWSE_BUILDING_DETAILS');
export const sendMessageFromBrowseBuildingDetailsRoutine = createBuildingDetailsRoutine('SEND_MESSAGE_FROM_BROWSE_BUILDING_DETAILS');
export const toggleFavoriteBuildingRoutine = createBuildingDetailsRoutine('TOGGLE_FAVORITE_BUILDING');
export const bookSpacesRoutine = createBuildingDetailsRoutine('BOOK_SPACES');
export const redirectWithBookingStateRoutine = createBuildingDetailsRoutine('REDIRECT_WITH_STATE');
export const clearCurrentBookingRoutine = createBuildingDetailsRoutine('CLEAR_CURRENT_BOOKING');
export const fetchBuildingDetailsRoutine = createBuildingDetailsRoutine('FETCH_DETAILS');
export const fetchSpaceAvailabilityRoutine = createBuildingDetailsRoutine('SPACE_AVAILABILITY');
export const toggleResetSelectedSpacesRoutine = createBuildingDetailsRoutine('TOGGLE_RESET_SELECTED_SPACES');
