import {
  fetchAdditionalServicesRoutine,
  fetchBuildingBookingsForNeedRoutine,
  resetSpacesAndServicesSectionRoutine,
  setBookingsTablePageRoutine
} from '@screens/NeedsDashboard/BuildingDetails/routines';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getInitialPageableState, IPageableReducerState } from '@models/domain/PageableReducerState';
import { IBookingDetailsForSchedulingDto } from '@screens/NeedsDashboard/Bookings/model/BookingDetailsDto';
import { IPageableResult } from '@models/domain/PageableResult';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';

export interface ISpacesAndServicesSectionReducerState extends IPageableReducerState<IBookingDetailsForSchedulingDto> {
  additionalServices: IAdditionalServiceWithAvailability[];
}

const initialState: ISpacesAndServicesSectionReducerState = {
  ...getInitialPageableState<IBookingDetailsForSchedulingDto>(),
  additionalServices: []
};

export const spacesAndServicesSectionReducer = createReducer(initialState, {
  [fetchBuildingBookingsForNeedRoutine.SUCCESS]: (
    state,
    { payload }: PayloadAction<IPageableResult<IBookingDetailsForSchedulingDto>>
  ) => {
    state.items = payload.items;
    state.totalPages = payload.totalPages;
    state.totalResults = payload.totalResults;
  },
  [setBookingsTablePageRoutine.FULFILL]: (state, { payload }) => {
    state.page = payload;
  },
  [fetchAdditionalServicesRoutine.SUCCESS]: (
    state, { payload }: PayloadAction<IAdditionalServiceWithAvailability[]>
  ) => {
    state.additionalServices = payload;
  },
  [resetSpacesAndServicesSectionRoutine.FULFILL]: () => initialState
});
