import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import moment from 'moment';
import { fetchSchedulesRoutine, setEditedScheduleRoutine } from '@screens/NeedsDashboard/Bookings/routines';
import {
  extractFetchSchedulesLoading,
  extractSchedules
} from '@screens/NeedsDashboard/Bookings/reducers';
import NewTable from '@components/NewDesign/Table/NewTable';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/Bookings/model/ScheduleResponse';
import {
  ManageScheduleModal
} from '@screens/NeedsDashboard/Bookings/components/DropOffsAndPickUpsTable/ManageScheduleModal';
import { extractBookingData } from '@screens/BookingCheckout/reducers';
import { BookingStatus } from '@models/domain/BookingStatus';
import { RootState } from '@root/store';
import { ScheduleStatus } from '@models/domain/schedule/ScheduleStatus';
import NoDataContainer from '@components/NoDataContainer';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';
import { ScheduleStatusLabel } from '@components/ScheduleStatusLabel';
import { ScheduleTypeLabel } from '@components/ScheduleTypeLabel';

const DropOffsAndPickUpsTable = () => {
  const dispatch = useDispatch();
  const {
    bookingWithSpaces,
    schedules,
    isSchedulesLoading
  } = useSelector((state: RootState) => ({
    bookingWithSpaces: extractBookingData(state),
    schedules: extractSchedules(state),
    isSchedulesLoading: extractFetchSchedulesLoading(state)
  }));

  useEffect(() => {
    if (bookingWithSpaces?.booking?.id) {
      dispatch(fetchSchedulesRoutine.trigger(bookingWithSpaces.booking.id));
    }
  }, [bookingWithSpaces, dispatch]);

  if (isSchedulesLoading) {
    return <Loader active inline="centered" />;
  }

  return (
    <>
      <ManageScheduleModal schedules={schedules} />
      <SchedulesTable schedules={schedules} bookingStatus={bookingWithSpaces.booking.status} />
    </>
  );
};

const SchedulesTable = ({ schedules, bookingStatus }) => {
  const dispatch = useDispatch();

  const handleOpenEditScheduleModal = useCallback(schedule => () => {
    dispatch(setEditedScheduleRoutine.fulfill(schedule));
  }, [dispatch]);

  if (!schedules?.length) {
    return <NoDataContainer message="No schedules added" />;
  }

  const isBookingConfirmed = bookingStatus === BookingStatus.CONFIRMED;
  const checkIsDisabled = (schedule: IScheduleResponseDto) => !isBookingConfirmed
    || ![ScheduleStatus.PENDING, ScheduleStatus.ACCEPTED].includes(schedule.status);

  return (
    <NewTable
      titles={['Type', 'Date', 'Status']}
      rows={schedules.map((schedule: IScheduleResponseDto) => {
        const { id, type, scheduledTime, status } = schedule;
        return {
          id,
          cells: [
            { content: <ScheduleTypeLabel type={type} /> },
            { content: <TableImportantContent>{moment(scheduledTime).format('MMMM Do, YYYY')}</TableImportantContent> },
            { content: <ScheduleStatusLabel status={status} /> }
          ],
          rowProps: {
            onClick: handleOpenEditScheduleModal(schedule),
            disabled: checkIsDisabled(schedule)
          }
        };
      })}
    />
  );
};

export { DropOffsAndPickUpsTable };
