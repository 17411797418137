import { VirtualAddressSpaceCategory } from '@models/domain/space/VirtualAddressSpaceCategory';
import { OfficeSpaceCategory } from '@models/domain/space/OfficeSpaceCategory';
import { WarehouseSpaceCategory } from '@models/domain/space/WarehouseSpaceCategory';
import { ParkingSpaceCategory } from '@models/domain/space/ParkingSpaceCategory';

export const SpaceCategory = {
  ...VirtualAddressSpaceCategory,
  ...OfficeSpaceCategory,
  ...WarehouseSpaceCategory,
  ...ParkingSpaceCategory
};
export type SpaceCategory =
  VirtualAddressSpaceCategory |
  OfficeSpaceCategory |
  WarehouseSpaceCategory |
  ParkingSpaceCategory;

export const spaceCategoryKey = val => Object.keys(SpaceCategory).filter(k => SpaceCategory[k] === val)[0];
