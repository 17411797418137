/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { SelectProps } from 'semantic-ui-react';
import classNames from 'classnames';
import CommonInput, { ICommonInputProps } from '@components/formik/common';
import SelectWrapper from '@components/NewDesign/Select';
import common from '../../common/styles.module.scss';
import styles from './styles.module.scss';
import { IBindingCallback2 } from '@models/Callbacks';

export interface IFormikSelectBaseProps extends ICommonInputProps<HTMLElement, SelectProps> {
  onChange: IBindingCallback2<any, any>;
  onBlur: IBindingCallback2<any, boolean>;
}

const FormikSelectBase: React.FC<IFormikSelectBaseProps> = props => {
  const { semanticProps, onBlur, onChange, propsOrFieldName } = props;

  return (
    <div className={styles.wrapper}>
      {semanticProps.label && (
        <div className={classNames(styles.select_label, 'field', semanticProps.required && 'required')}>
          <label className={common.label}>
            {semanticProps.label}
          </label>
        </div>
      )}
      <CommonInput
        {...props}
        propsOrFieldName={propsOrFieldName}
        element={SelectWrapper as any}
        semanticProps={{
          ...semanticProps,
          onChange,
          onBlur,
          fluid: true,
          className: `${semanticProps.className}`
        }}
      />
    </div>
  );
};

export default FormikSelectBase;
