import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { IBindingAction } from '@models/Callbacks';
import { closeErrorModalRoutine } from '@screens/ErrorModal/routines';
import { IErrorModalInfo } from '@screens/ErrorModal/containers/ErrorModalPage/reducer';
import { extractErrorModalInfo } from '@screens/ErrorModal/reducers';
import { Modal, ModalContent, TransitionablePortal } from 'semantic-ui-react';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';
import Header2 from '@components/NewDesign/Typography/Headers/Header2';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import styles from './styles.module.scss';

export interface IErrorModalProps extends IState, IActions {
  errorInfo?: IErrorModalInfo;
}

interface IState {
}

interface IActions {
  onClose: IBindingAction;
}

const ErrorModal: React.FC<IErrorModalProps> = (
  { errorInfo, onClose }
) => {
  const history = useHistory();
  const handleClose = useCallback(() => {
    onClose();
    if (errorInfo?.redirect) {
      history.push(errorInfo.redirect);
    }
  }, [errorInfo, history, onClose]);

  return (
    <TransitionablePortal open={!!errorInfo} transition={{ duration: 500 }}>
      <Modal open={!!errorInfo} closeIcon onClose={handleClose} size="small">
        <ModalContent className={styles.container}>
          <Header2 className={styles.title}>
            {errorInfo?.title}
          </Header2>
          <Header3 className={styles.message}>
            {errorInfo?.message}
          </Header3>
          {errorInfo?.tip && (
            <div className={styles.tip}>
              <SubHeader1 className={styles.tip_label}>Tip:</SubHeader1>
              &nbsp;
              <BodyText1 className={styles.tip_message}>
                {errorInfo.tip}
              </BodyText1>
            </div>
          )}
          <div className={styles.buttonWrapper}>
            <PrimaryButton
              content="Ok"
              onClick={handleClose}
              className={styles.button}
            />
          </div>
        </ModalContent>
      </Modal>
    </TransitionablePortal>
  );
};

const mapStateToProps: (state) => IState = state => ({
  errorInfo: extractErrorModalInfo(state)
});

const mapDispatchToProps: IActions = {
  onClose: closeErrorModalRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
