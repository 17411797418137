import { LocationDescriptor } from 'history';
import { IPassedState } from '@screens/BuildingDetails/containers/BuildingDetailsPage';
import * as queryString from 'querystring';
import { IBriefBuildingItem } from '@screens/BrowseSpaces/model/BriefBuildingItem';

export const searchBrowserBuildingLink = (item: IBriefBuildingItem): LocationDescriptor => ({
  pathname: `/details/${item.id}`,
  state: { buildingName: item.name } as IPassedState
});

export const dashboardBuildingLink = (item: IBriefBuildingItem, prevLocation): LocationDescriptor => {
  const prevQuery = queryString.parse(prevLocation.search);
  return ({
    ...prevLocation,
    search: queryString.stringify({ ...prevQuery, building: item.id }),
    state: { buildingName: item.name } as IPassedState
  });
};
