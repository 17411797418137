import React from 'react';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import { IHowItWorksLocationState } from '@screens/static/HowItWorks/containers/HowItWorksPage';
import { Role } from '@screens/Authorization/models/Roles';

const SeekingSpace: React.FC = () => (
  <>
    <ol>
      <li>
        <BodyText2>
          You need space pronto, but don’t want a long-term, expensive commitment.
        </BodyText2>
      </li>
      <li>
        <BodyText2>
          Use the search bar, above, to find what you are looking for. Refine your search results as needed.
        </BodyText2>
      </li>
      <li>
        <BodyText2>
          Once you find what you need, follow the checkout process to book your space.
        </BodyText2>
      </li>
      <li>
        <BodyText2>
          Amounts for partial months are prorated at checkout,
          and you will be billed for every full month in advance, on the
          first of each month.
        </BodyText2>
      </li>
      <li>
        <BodyText2>
          Increase or decrease your storage needs on a monthly
          basis, as necessary. Cancel at any time, free of charge.
          No refunds for any monthly fees already paid.
        </BodyText2>
      </li>
    </ol>
    <GoToButton
      to={{
        pathname: ENDPOINTS.HOW_IT_WORKS,
        state: { role: Role.NEED } as IHowItWorksLocationState
      }}
    >
      Find out More about Finding Spaces
    </GoToButton>
  </>
);

export default SeekingSpace;
