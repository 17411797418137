import { CheckoutStep } from '@screens/Checkout/model/CheckoutStep';
import { loadBookingDetailsRoutine, setActiveStepRoutine } from '@screens/BookingCheckout/routines';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { loadServiceOrderDetailsRoutine } from '@screens/ServiceOrderCheckout/routines';
import { IServiceOrderCheckoutData } from '@screens/ServiceOrderCheckout/model/ServiceOrderCheckout';
import { IBookingCheckoutData } from '@screens/BookingCheckout/model/BookingCheckout';

export interface ICheckoutReducerState {
  step: CheckoutStep;
}

const initialState: ICheckoutReducerState = {
  step: CheckoutStep.LOADING
};

function stepFromStatus(status) {
  switch (status) {
    case 'PENDING':
      return CheckoutStep.DETAILS;
    case 'CONFIRMED':
    case 'CANCELLED':
      return CheckoutStep.RESULT;
    default:
      return CheckoutStep.LOADING;
  }
}

export const checkoutReducer = createReducer(initialState, {
  [setActiveStepRoutine.FULFILL]: (state, { payload }: PayloadAction<CheckoutStep>) => {
    state.step = payload;
  },
  [loadServiceOrderDetailsRoutine.SUCCESS]: (state, { payload }: PayloadAction<IServiceOrderCheckoutData>) => {
    state.step = stepFromStatus(payload.serviceOrder.order.status);
  },
  [loadBookingDetailsRoutine.SUCCESS]: (state, { payload }: PayloadAction<IBookingCheckoutData>) => {
    state.step = stepFromStatus(payload.booking.booking.status);
  }
});
