import React, { FC } from 'react';
import { Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import DangerButton from '@components/NewDesign/Button/DangerButton';
import styles from './styles.module.scss';

export interface IConfirmModalProps {
  open: boolean;
  onCancel: any;
  onConfirm: any;
  header: string;
}

const ConfirmModal: FC<IConfirmModalProps> = (
  { open, onCancel, onConfirm, header }
) => (
  <Modal
    open={open}
    onClose={onCancel}
    size="tiny"
    closeIcon
  >
    <ModalHeaderWrapper>
      {header}
    </ModalHeaderWrapper>
    <ModalContent className={styles.container}>
      <BodyText2>
        Are you sure?
      </BodyText2>
    </ModalContent>
    <ModalActions>
      <div className={styles.buttons}>
        <div>
          <DangerButton
            onClick={onCancel}
            content="Cancel"
          />
        </div>
        <div>
          <PrimaryButton
            onClick={onConfirm}
            content="Confirm"
          />
        </div>
      </div>
    </ModalActions>
  </Modal>
);

export default ConfirmModal;
