import React from 'react';
import { Form } from 'semantic-ui-react';
import * as Yup from 'yup';
import { FormikProps, withFormik } from 'formik';
import { history } from '@helpers/history.helper';
import { IBindingCallback1 } from '@models/Callbacks';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import FormikInput from '@components/formik/Input';
import { emailValidation } from '@screens/Authorization/components/validation';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import styles from './styles.module.scss';

export interface IForgottenPasswordFormProps {
  sendResetPassword: IBindingCallback1<string>;
  requestLoading: boolean;
}

interface IFormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: emailValidation
});

const ForgottenPasswordForm: React.FC<IForgottenPasswordFormProps & FormikProps<IFormValues>> = props => {
  const { values, handleSubmit, requestLoading, isValid } = props;

  return (
    <>
      <Form name="forgottenPasswordForm" onSubmit={handleSubmit}>
        <FormikInput
          propsOrFieldName={{
            name: 'email',
            type: 'email',
            autoFocus: true,
            placeholder: 'example@mail.com',
            required: true
          }}
        />
        <div className={styles.form_buttons}>
          <SmallLinkButton
            left
            onClick={history.goBack}
            to=""
          >
            Go back
          </SmallLinkButton>
          <div className={styles.button_wrapper}>
            <PrimaryButton
              type="submit"
              loading={requestLoading}
              disabled={values.email === undefined || !isValid}
            >
              Send
            </PrimaryButton>
          </div>
        </div>
      </Form>
    </>
  );
};

export default withFormik<IForgottenPasswordFormProps, IFormValues>({
  displayName: 'ForgottenPasswordForm',
  validationSchema,
  handleSubmit: ((values, { props }) => {
    props.sendResetPassword(values.email);
  })
})(ForgottenPasswordForm);
