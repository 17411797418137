import React, { ChangeEvent } from 'react';
import { IQuerySquareData } from '@screens/BrowseSpaces/model/QueryData';
import { IBindingCallback1 } from '@models/Callbacks';
import { filterStringNumeric } from '@helpers/string.helper';
import { LengthUnit, lengthUnitTypeKey } from '@models/domain/LengthUnit';
import { enumAsOptions } from '@helpers/enum.helper';
import { ISearchSelector } from '../model/SearchSelector';
import LabeledInputWrapper from '@components/NewDesign/LabeledInput';
import SelectWrapper from '@components/NewDesign/Select';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

export interface ISquareSelectorProps extends ISearchSelector {
  value: IQuerySquareData;
  onChange: IBindingCallback1<IQuerySquareData>;
}

const SquareSelector: React.FC<ISquareSelectorProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const inputToSquare = (val: string) => {
    const strNumbers = filterStringNumeric(val);
    return strNumbers
      ? Number.parseInt(strNumbers, 10)
      : undefined;
  };

  const handleChangeDimension = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      square: inputToSquare(e.target.value),
      unit: value?.unit || lengthUnitTypeKey(LengthUnit.FEET)
    });
  };

  const optionsWithSup = enumAsOptions(LengthUnit).map(option => ({ ...option, text: t(`Square ${option.text}`) }));

  return (
    <div className={styles.container}>
      <LabeledInputWrapper
        fluid
        label={{ content: t('Size'), className: styles.label }}
        onChange={e => handleChangeDimension(e)}
        labelPosition="left"
        value={value?.square || ''}
      />
      <SelectWrapper
        fluid
        options={optionsWithSup}
        placeholder={t('Units')}
        onChange={(e: any, { value: v }) => onChange({
          ...value,
          unit: v as any
        })}
        value={value?.unit}
      />
    </div>
  );
};

export default SquareSelector;
