import React from 'react';
import SubHeader2 from '@components/NewDesign/Typography/SubHeaders/SubHeader2';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import styles from './styles.module.scss';

export interface IHowItWorksContentBlockProps {
  title: string;
  subtitle?: string;
}

const HowItWorksContentBlock: React.FC<IHowItWorksContentBlockProps> = (
  { title, children, subtitle }
) => (
  <div className={styles.container}>
    <div className={styles.headerSection}>
      <SubHeader2>{title}</SubHeader2>
      {subtitle && (
        <SubHeader3 className={styles.subtitle}>{subtitle}</SubHeader3>
      )}
    </div>
    <BodyText2>
      {children}
    </BodyText2>
  </div>
);

export default HowItWorksContentBlock;
