import React from 'react';
import classNames from 'classnames';
import GeneralButton, { IButtonProps } from '@components/NewDesign/Button/GeneralButton';
import styles from './styles.module.scss';

const TransparentButton: React.FC<IButtonProps> = props => (
  <GeneralButton {...props} className={classNames(props.className, styles.container)}>
    {props.children}
  </GeneralButton>
);

export default TransparentButton;
