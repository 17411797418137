import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { ISpaceDto } from '@screens/SpaceEditor/models/ISpaceDto';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { ISpaceEdit } from '@screens/SpaceEditor/models/ISpaceEdit';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import TableWrapper from '@components/NewDesign/Table/TableWrapper';
import NoDataContainer from '@components/NoDataContainer';
import { InformationSection } from '@components/InformationSection';
import SpaceRow from '@components/NewDesign/Spaces/SpaceRow';
import {
  extractSpaceTemplate
} from '@screens/SpaceEditor/reducers';
import { ISpaceTemplateDto } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import { IChangeSpaceBlocked } from '@screens/SpaceEditor/models/IChangeSpaceBlocked';
import Caption4 from '@components/NewDesign/Typography/Caption/Caption4';
import InfoPopup from '@components/NewDesign/InfoPopup';
import {
  AddMultipleSpacesButtonWithModal
} from '@components/NewDesign/Spaces/SpacesTable/components/AddMultipleSpacesButtonWithModal';
import { bulkCreateSpacesRoutine } from '@screens/SpaceEditor/routines';
import styles from './styles.module.scss';

interface IState {
  spaceTemplate: ISpaceTemplateDto;
}

interface ISpacesTableProps extends IState {
  spaces: ISpaceDto[];
  saveSpace: IBindingCallback1<ISpaceEdit>;
  changeSpaceBlocked: IBindingCallback1<IChangeSpaceBlocked>;
  chooseSpace: IBindingCallback1<Partial<ISpaceDto>>;
  hideSpace: IBindingAction;
  chosenSpace?: Partial<ISpaceDto>;
  saveSpaceLoading: boolean;
  spacesLoading: boolean;
}

const SpacesTable: React.FC<ISpacesTableProps> = (
  { spaces, saveSpace, changeSpaceBlocked, spaceTemplate, chooseSpace, hideSpace,
    chosenSpace, saveSpaceLoading, spacesLoading
  }
) => {
  const dispatch = useDispatch();
  const definedSpaces = spaces || [];
  const spacesWithNew = [{}, ...definedSpaces] as Partial<ISpaceDto>[];

  const handleAdd = useCallback(() => {
    chooseSpace({});
  }, [chooseSpace]);

  const handleAddMultipleSpaces = useCallback((amount: number) => {
    dispatch(bulkCreateSpacesRoutine.trigger({ spaceTemplateId: spaceTemplate?.id, amount }));
  }, [dispatch, spaceTemplate]);

  const spaceToItem = space => (
    <SpaceRow
      key={space.id || 0}
      space={space}
      isEditing={chosenSpace && space.id === chosenSpace?.id}
      saveSpace={saveSpace}
      changeSpaceBlocked={changeSpaceBlocked}
      chooseSpace={chooseSpace}
      chosenSpace={chosenSpace}
      hideSpace={hideSpace}
      saveSpaceLoading={saveSpaceLoading}
    />
  );

  return (
    <div className={styles.container}>
      {spacesLoading
        ? <Loader active />
        : (
          <>
            <div className={styles.spaces_menu}>
              <div className={styles.button_wrapper}>
                { spaceTemplate && (
                  <>
                    <PrimaryButton
                      content="Add One Space"
                      onClick={handleAdd}
                    />
                    <AddMultipleSpacesButtonWithModal onSpacesAdd={handleAddMultipleSpaces} />
                  </>
                )}
              </div>
              <div className={styles.hint}>
                <Caption4>
                  * Click on the space to edit the space identifier
                </Caption4>
              </div>
            </div>
            <InformationSection
              items={spacesWithNew}
              hideTitle
              fetchItems={() => null}
              itemsLoading={spacesLoading}
              renderItem={item => spaceToItem(item)}
              itemsWrapper={TableWrapper}
              itemsWrapperProps={{
                titlesElements: [
                  (
                    <span key="0">
                      Space Identifier
                    </span>
                  ),
                  (
                    <span key="1" className={styles.right_title}>
                      Space Available?
                      <InfoPopup
                        transparent
                        content="This slider control allows you to manage the availability of individual spaces
                        in your Shared Spaces listing. If 'RED/OFF' the specific space will NOT show up in
                        search results."
                        position="left center"
                      />
                    </span>
                  )
                ]
              }}
              noItemsPlaceholder={() => <NoDataContainer message="You do not have any spaces yet" />}
            />
          </>
        )}
    </div>
  );
};

const mapStateToProps: (state) => IState = state => ({
  spaceTemplate: extractSpaceTemplate(state)
});

export default connect(mapStateToProps, null)(SpacesTable);
