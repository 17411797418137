import React from 'react';
import { connect } from 'react-redux';
import { IBindingAction } from '@root/models/Callbacks';
import { extractEmailResendError, extractEmailResendLoading, extractUserEmail } from '../../reducers';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import ErrorMessageContainer from '@components/NewDesign/ErrorMessageContainer';
import { resendEmailRoutine } from '../../routines';
import PaperAirplaneHeader from '@components/NewDesign/PaperAirplaneHeader';
import styles from './styles.module.scss';

export interface IActivateEmailPageProps {
  email: string;
  errorMessage: string | undefined;
  loading: boolean;
  resendMail: IBindingAction;
}

const ActivateEmailPage: React.FC<IActivateEmailPageProps> = (
  { email, errorMessage, loading, resendMail }
) => (
  <div className={styles.content_wrapper}>
    <div className={styles.container}>
      <div className={styles.content}>
        <PaperAirplaneHeader headerText="Almost there!" />
        <BodyText1 className={styles.text}>
          We have sent a verification email to the account you used to register with us.
          If you can’t see the email, make sure to check your spam folder.
          Then, follow the instructions to verify your account.
        </BodyText1>
      </div>
      <div className={styles.messageContainer}>
        <ErrorMessageContainer errorMessage={errorMessage} />
      </div>
      <div>
        <PrimaryButton loading={loading} onClick={resendMail}>Resend Email</PrimaryButton>
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  email: extractUserEmail(state),
  errorMessage: extractEmailResendError(state),
  loading: extractEmailResendLoading(state)
});

const mapDispatchToProps = {
  resendMail: resendEmailRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateEmailPage);
