import React, { memo } from 'react';
import { FormTextAreaProps } from 'semantic-ui-react/dist/commonjs/collections/Form/FormTextArea';
import CommonInput, { ICommonInputProps } from '@components/formik/common';
import TextAreaWrapper from '@components/NewDesign/TextArea';

export type IFormikTextAreaProps = ICommonInputProps<HTMLTextAreaElement, FormTextAreaProps>;

const FormikTextArea: React.FC<IFormikTextAreaProps> = props => (
  <CommonInput {...props as any} element={TextAreaWrapper} />
);

export default memo(FormikTextArea);
