import React from 'react';
import { Loader } from 'semantic-ui-react';
import BuildingListCard from '@screens/BrowseSpaces/components/layouts/cards/BuildingListCard';
import { IResultGridProps } from '@screens/BrowseSpaces/components/layouts/ResultsGrid';
import { IResultBuildingItem } from '@screens/BrowseSpaces/model/ResultItem';
import { searchBrowserBuildingLink } from '@screens/BrowseSpaces/components/layouts/cards/link.helper';
import { IBindingCallback1 } from '@models/Callbacks';
import AdvertisingBanner from '@components/NewDesign/AdvertisingBanner';
import styles from './styles.module.scss';
import { getDefaultImageFromBuildingType } from '@helpers/image.placeholder.helper';

export type IResultListProps = IResultGridProps;

const itemToCard = (item: IResultBuildingItem, toggleLike: IBindingCallback1<string>) => (
  <BuildingListCard
    key={item.id}
    imageSrc={item.avatar || getDefaultImageFromBuildingType(item.type)}
    name={item.name}
    type={item.type}
    linkLocation={searchBrowserBuildingLink({ id: item.id, name: item.name })}
    liked={item.liked}
    toggleLike={() => toggleLike(item.id)}
    likeLoading={item.likeLoading}
    address={item.location.shortAddress}
  />
);

export const getBanner = (className: string) => (
  <AdvertisingBanner className={className}>Advertise your space here</AdvertisingBanner>
);

const BANNER_INDEX = 5;

const getItemsWithBanner = (items: JSX.Element[]) => {
  const itemsWithoutBanner = [...items];
  const banner = getBanner(styles.banner);
  const spliceStartIndex = itemsWithoutBanner.length > BANNER_INDEX ? BANNER_INDEX : 0;
  itemsWithoutBanner.splice(spliceStartIndex, 0, banner);
  return itemsWithoutBanner;
};

const ResultsList: React.FC<IResultListProps> = (
  { results, loading, toggleLike, showAdvertising }
) => {
  const resultItems = [...results].map(i => itemToCard(i, toggleLike));
  const itemsToShow = showAdvertising ? getItemsWithBanner(resultItems) : resultItems;

  return (
    <>
      <Loader inline="centered" active={loading} />
      {loading || itemsToShow}
    </>
  );
};

export default ResultsList;

