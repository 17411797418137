import React from 'react';
import classNames from 'classnames';
import { Button, ButtonProps } from 'semantic-ui-react';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

export interface IButtonProps extends ButtonProps {
  marginZero?: boolean;
}

const GeneralButton: React.FC<IButtonProps> = ({ content, marginZero, ...props }) => {
  const { t } = useTranslation();

  return (
    <Button
      content={t(content)}
      {...props}
      className={classNames(props.className, styles.container, { [styles.margin_zero]: marginZero })}
    >
      {t(props.children)}
    </Button>
  );
};

export default GeneralButton;
