import { fetchTransactionsRoutine, setPageRoutine } from '@screens/NeedsDashboard/Transactions/routines';
import { ITransaction } from '@models/domain/Transaction';
import { getInitialPageableState } from '@models/domain/PageableReducerState';
import { createReducer } from '@reduxjs/toolkit';
import { logOutRoutine } from '@screens/Authorization/routines';

export const transactionsReducer = createReducer(getInitialPageableState<ITransaction>(), {
  [fetchTransactionsRoutine.SUCCESS]: (state, { payload }) => {
    state.items = payload.items;
    state.totalResults = payload.totalResults;
    state.totalPages = payload.totalPages;
  },
  [setPageRoutine.FULFILL]: (state, { payload }) => {
    state.page = payload;
  },
  [logOutRoutine.SUCCESS]: () => getInitialPageableState<ITransaction>()
});
