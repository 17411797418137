import { all } from 'redux-saga/effects';
/* PlopJS import placeholder. Do not remove */
import receiptPageSagas from '@screens/ReceiptPage/sagas';
import termsAndConditionsSagas from '@screens/TermsAndConditions/Root/sagas';
import errorModalSagas from '@screens/ErrorModal/sagas';
import adminDashboardSagas from '@screens/AdminDashboard/Root/sagas';
import creditCardConfigurationSagas from '@screens/CreditCardConfiguration/sagas';
import spaceEditorSagas from '@screens/SpaceEditor/sagas';
import buildingEditorSagas from '@screens/BuildingEditor/sagas';
import havesDashboardSagas from '@screens/HavesDashboard/Root/sagas';
import needsDashboardSagas from '@screens/NeedsDashboard/Root/sagas';
import bookingCheckoutSagas from '@screens/BookingCheckout/sagas';
import authSagas from '@screens/Authorization/sagas';
import browseSpacesSagas from '@screens/BrowseSpaces/sagas';
import buildingDetailsSagas from '@screens/BuildingDetails/sagas';
import staticSagas from '@screens/static/sagas';
import serviceOrderSagas from '@screens/ServiceOrderCheckout/sagas';
import checkoutSagas from '@screens/Checkout/sagas';

export default function* rootSaga() {
  yield all([
    /* PlopJS sagas placeholder. Do not remove */
    receiptPageSagas(),
    termsAndConditionsSagas(),
    errorModalSagas(),
    adminDashboardSagas(),
    creditCardConfigurationSagas(),
    spaceEditorSagas(),
    buildingEditorSagas(),
    havesDashboardSagas(),
    needsDashboardSagas(),
    bookingCheckoutSagas(),
    checkoutSagas(),
    serviceOrderSagas(),
    authSagas(),
    browseSpacesSagas(),
    buildingDetailsSagas(),
    staticSagas()
  ]);
}
