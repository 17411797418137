import { all, call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { Routine } from 'redux-saga-routines';
import * as service from '@screens/Authorization/services/email_verification.service';
import { resendEmailRoutine, verifyEmailActivationRoutine } from '@screens/Authorization/routines';
import { history } from '@helpers/history.helper';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import { PLEASE_CHECK_YOUR_EMAIL } from '@common/constants/event-messages';

function* trySendEmail() {
  try {
    yield call(service.resendEmail);
    yield put(resendEmailRoutine.success());
    toastr.success('Activation email was sent!', PLEASE_CHECK_YOUR_EMAIL);
  } catch (error) {
    yield put(resendEmailRoutine.failure(error?.message));
  }
}

function* tryVerifyEmailActivation({ payload }: Routine<any>) {
  try {
    yield call(service.verifyEmailActivation, payload);
    yield put(verifyEmailActivationRoutine.success());
    history.push(ENDPOINTS.USER);
  } catch (e) {
    yield put(verifyEmailActivationRoutine.failure(e?.message));
  }
}

export default function* emailActivationSagas() {
  yield all([
    yield takeEvery(resendEmailRoutine.TRIGGER, trySendEmail),
    yield takeEvery(verifyEmailActivationRoutine.TRIGGER, tryVerifyEmailActivation)
  ]);
}
