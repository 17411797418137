import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchTransactionsRoutine } from '@screens/ServiceOrderCheckout/routines';
import { PayloadAction } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';
import { IFetchTransactionsForOrderRequest } from '@screens/ServiceOrderCheckout/containers/ServiceOrderDetailsPage/TransactionsBlock/index';
import orderTransactionsService from '@screens/ServiceOrderCheckout/services/order.transactions.service';

function* tryFetchTransactionsForOrder({ payload }: PayloadAction<IFetchTransactionsForOrderRequest>) {
  try {
    const resp = yield call(orderTransactionsService.fetchOrderTransactions, payload);
    yield put(fetchTransactionsRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to fetch transactions', e?.message);
    yield put(fetchTransactionsRoutine.failure(e?.message));
  }
}

export default function* orderDetailsPageSagas() {
  yield all([
    yield takeEvery(fetchTransactionsRoutine.TRIGGER, tryFetchTransactionsForOrder)
  ]);
}
