import React from 'react';
import styles from './styles.module.scss';
import { IBindingCallback1 } from '@models/Callbacks';
import { IPasswordUpdateDto } from '@screens/NeedsDashboard/Account/model/PasswordUpdateDto';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import { Modal, ModalContent } from 'semantic-ui-react';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import PasswordUpdateForm from '@screens/NeedsDashboard/Account/components/PasswordUpdateForm';

export interface IPasswordUpdateModalLinkProps {
  updatePassword: IBindingCallback1<IPasswordUpdateDto>;
  setUpdatePasswordModalOpen?: IBindingCallback1<boolean>;
  updatePasswordLoading?: boolean;
  updatePasswordModalOpen?: boolean;
}

const PasswordUpdateModalLink: React.FC<IPasswordUpdateModalLinkProps> = (
  { updatePassword, setUpdatePasswordModalOpen, updatePasswordLoading, updatePasswordModalOpen }
) => (
  <div className={styles.container}>
    <GoToButton to="" onClick={() => setUpdatePasswordModalOpen(true)}>Update Password</GoToButton>
    {updatePasswordModalOpen && (
      <Modal
        open
        closeIcon
        onClose={() => setUpdatePasswordModalOpen(false)}
        size="tiny"
      >
        <ModalHeaderWrapper>Update Password</ModalHeaderWrapper>
        <ModalContent>
          <PasswordUpdateForm
            saveLoading={updatePasswordLoading}
            saveChanges={updatePassword}
          />
        </ModalContent>
      </Modal>
    )}
  </div>
);

export default PasswordUpdateModalLink;
