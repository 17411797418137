import React from 'react';
import SideMenu from '@containers/SideMenu';
import { legalDocumentsContent } from '@screens/TermsAndConditions/Root/components/Routing';

export interface ISideMenuProps {
  className: string;
}

const TermsAndConditionsSideMenu: React.FC<ISideMenuProps> = ({ className }) => (
  <SideMenu tabs={legalDocumentsContent} className={className} />
);

export default TermsAndConditionsSideMenu;
