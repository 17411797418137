import React, { useMemo } from 'react';
import { Divider, Form } from 'semantic-ui-react';
import { FormikProps, withFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import FormikInput from '@components/formik/Input';
import FormikTextArea from '@components/formik/TextArea';
import FormikTagSelector from '@components/formik/TagSelector';
import { IBindingCallback1 } from '@models/Callbacks';
import { IBuildingForEditing } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { IBuildingForSave } from '@screens/BuildingEditor/model/BuildingForSave';
import { buildingTypeOptions } from '@models/domain/BuildingType';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import AddressFormPart from '@components/AddressFormPart';
import FormikSelectInline from '@components/formik/select/SelectInline';
import { nullableValidation, requiredValidation, zipValidation } from '@screens/Authorization/components/validation';
import styles from './styles.module.scss';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import classNames from 'classnames';

export interface IBuildingEditorFormProps {
  initialValues?: IBuildingForEditing;
  loadingValues?: boolean;
  saveBuilding: IBindingCallback1<IBuildingForSave>;
  tags: string[];
  tagsLoading: boolean;
  className?: string;
  saveLoading: boolean;
  isFormValid?: boolean;
}

interface IFormProps {
  buildingName: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  state: string;
  country: string;
  description: string;
  type: string;
  tags: string[];
  latitude: string;
  longitude: string;
}

const defaultFormValues: IFormProps = {
  address1: '',
  address2: '',
  city: '',
  buildingName: '',
  zip: '',
  state: '',
  country: '',
  description: '',
  type: '',
  tags: [],
  latitude: '',
  longitude: ''
};

const buildingToForm: (building: IBuildingForEditing) => IFormProps = building => ({
  type: building.type,
  tags: building.tags,
  description: building.description,
  buildingName: building.buildingName,
  zip: building.location.zip,
  city: building.location.city,
  address2: building.location.address2,
  address1: building.location.address1,
  state: building.location.state,
  country: building.location.country,
  latitude: building.location.latitude,
  longitude: building.location.longitude
});

const validationSchema = Yup.object().shape({
  buildingName: requiredValidation,
  type: Yup.string().required('You must select the type of the building'),
  description: requiredValidation,
  address1: requiredValidation,
  address2: nullableValidation,
  city: requiredValidation,
  state: requiredValidation,
  zip: zipValidation,
  country: requiredValidation,
  latitude: nullableValidation,
  longitude: nullableValidation
});

const formToBuilding = (formValues: any) => ({
  buildingName: formValues.buildingName,
  description: formValues.description,
  type: formValues.type,
  tags: formValues.tags,
  location: {
    address1: formValues.address1,
    address2: formValues.address2,
    city: formValues.city,
    zip: formValues.zip,
    state: formValues.state,
    country: formValues.country,
    latitude: formValues.latitude,
    longitude: formValues.longitude
  }
});

export const vagueEquals = (o1, o2) => {
  if (!o1 && !o2) return true;
  // eslint-disable-next-line eqeqeq
  if (o1 == o2) return true;
  return undefined;
};

const BuildingEditorForm: React.FC<IBuildingEditorFormProps & FormikProps<IFormProps>> = (
  { handleSubmit, values, initialValues, tags, tagsLoading,
    loadingValues = false, className, saveLoading,
    submitCount, errors, setValues, setFieldValue, setFieldTouched, isValid,
    isFormValid = true }
) => {
  const tagsOptions = useMemo(
    () => tags.map(t => ({
      key: t,
      value: t,
      text: t
    })),
    [tags]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      loading={loadingValues}
      className={className}
    >
      <FormikSelectInline
        propsOrFieldName={{
          placeholder: 'Building type',
          name: 'type'
        }}
        semanticProps={{
          options: buildingTypeOptions,
          required: true,
          label: 'Building type'
        }}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
      <FormikInput
        propsOrFieldName={{
          name: 'buildingName',
          placeholder: 'Building Name'
        }}
        semanticProps={{
          label: 'Building Name',
          required: true
        }}
      />
      <Caption2
        className={styles.title}
        marginTop
      >
        Features
      </Caption2>
      <FormikTagSelector
        propsOrFieldName={{ name: 'tags', placeholder: 'Standard features' }}
        semanticProps={{
          className: styles.tags_selector,
          fluid: true,
          loading: tagsLoading,
          options: tagsOptions
        }}
        fieldValue={values.tags}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
      <Caption2
        className={classNames(styles.title, styles.required)}
        marginTop
      >
        More...
      </Caption2>
      <FormikTextArea
        propsOrFieldName={{
          name: 'description',
          placeholder: 'What makes this building attractive to propsective guests',
          rows: 4
        }}
        semanticProps={{
          label: 'About this building',
          required: true
        }}
      />
      <Divider />
      <AddressFormPart
        values={values}
        setValues={setValues as any}
        initialValues={initialValues}
        showErrorMessage={submitCount > 0 && !!errors.address1}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
      <div>
        <PrimaryButton
          className={styles.submit_button}
          type="submit"
          content="Save"
          disabled={_.isEqualWith(initialValues, values, vagueEquals) || !isValid || !isFormValid}
          loading={saveLoading}
        />
      </div>
    </Form>
  );
};

export default withFormik<IBuildingEditorFormProps, IFormProps>({
  displayName: 'BuildingEditorForm',
  enableReinitialize: true,
  mapPropsToValues: props => (props.initialValues ? buildingToForm(props.initialValues) : defaultFormValues),
  handleSubmit: ((values, { props }) => props.saveBuilding(formToBuilding(values))),
  validationSchema
})(BuildingEditorForm);
