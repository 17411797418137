import React from 'react';
import classNames from 'classnames';
import StaticPageWrapper from '@components/NewDesign/StaticPageWrapper';
import HaveImage from '@images/how_it_works/Have.png';
import BoxesImage from '@images/how_it_works/Boxes.png';
import HowItWorksContentBlock from '@screens/static/HowItWorks/components/HowItWorksContentBlock';
import TextLink from '@components/NewDesign/TextLink';
import {
  LIST_YOUR_SPACE_COMMON_ENDPOINT
} from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import Header4 from '@components/NewDesign/Typography/Headers/Header4';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import DividingLine from '@components/NewDesign/DividingLine';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import { IBuildingsPageLocationState } from '@screens/HavesDashboard/Buildings/containers/BuildingsPage';
import styles from './styles.module.scss';

export interface IHowItWorksHaveContentProps {
}

const HowItWorksHaveContent: React.FC<IHowItWorksHaveContentProps> = () => (
  <StaticPageWrapper
    title="So You have Extra Space"
    disableHeaderMaxWidth
    moreMaxWidth
  >
    <div className={styles.container}>
      <div>
        <div className={styles.overview}>
          <Header4 className={styles.overviewHeader}>Overview</Header4>
          <BodyText1 className={styles.overviewContent}>
            Shared Spaces is a platform with all the tools you need to list
            your available spaces, manage your hosted spaces, collect payment
            on your hosted spaces and additional services, and schedule drop-offs and pick-ups of
            items to be stored in or withdrawn from your warehouse (as applicable).
          </BodyText1>
        </div>
        <HowItWorksContentBlock title="Eligibility">
          In order for your space to be eligible for listing on Shared Spaces,
          it has to meet certain minimum requirements, which have been created
          to provide a good experience, and to protect both you and your
          potential space guests.
          <br />
          <br />
          Please review the full checklist of space host requirements
          {' '}
          <TextLink
            to={{
              pathname: LIST_YOUR_SPACE_COMMON_ENDPOINT,
              state: { showChecklist: true } as IBuildingsPageLocationState
            }}
          >
            here.
          </TextLink>
        </HowItWorksContentBlock>
        <HowItWorksContentBlock title="Listing Spaces">
          You can list your spaces after registering for a Shared Spaces
          account and choosing your account role as a “Have” (host).
          <br />
          <br />
          After you determine the available spaces
          (and any additional services you would like to offer) and have taken good
          pictures of your building and spaces, start the process by first
          creating a “building” in your user dashboard. Then you can create
          new spaces within that building to offer for hosting.
          The process is straight forward, and self-explanatory.
        </HowItWorksContentBlock>
        <HowItWorksContentBlock title="Guest Follow-Up">
          Once someone books any of your hosted spaces or additional services, our system
          will notify you so that you can prepare the space(s).
          You will need to contact your space guest within 24 hours
          of receiving a booking confirmation, with any details unique
          to their spaces, and with any additional useful information.
        </HowItWorksContentBlock>
        <HowItWorksContentBlock title="Payment">
          Billing occurs on the first of every month (prepaid for that month)
          and continues on a month-to-month basis until your guest terminates
          his/her space hosting agreement. If their booking starts or ends
          after the 1st of the month, their fee for that month will be pro-rated
          accordingly. You will receive payment into your default bank account
          (as defined in your user dashboard settings) within 5-10 business
          days from the time any payment is collected by
          Shared Spaces for your Hosted Services.
        </HowItWorksContentBlock>
      </div>
      <div>
        <img src={HaveImage} className={styles.image} alt="Have" />
      </div>
    </div>
    <div className={styles.container}>
      <div>
        <HowItWorksContentBlock title="Adjustable Host Space Needs">
          Guests may increase or decrease their space needs at any time,
          as necessary (and based on space availability), and they may cancel at any time, free-of-charge.
          If they have prepaid for a month’s worth of space hosting, they
          will have until the end of that month to use the space. If they
          do not use the space the entire month, they will not receive any
          refunds of any prepaid hosting fees.
        </HowItWorksContentBlock>
      </div>
      <div>
        <HowItWorksContentBlock title="Reviews">
          Your space guests will be invited to rate the service you provide
          them, so please do your very best to meet their needs—not only
          because it is the right thing to do, but because your reviews
          will follow you and be seen by Shared Spaces community members looking
          to book spaces from you.
        </HowItWorksContentBlock>
      </div>
    </div>
    <DividingLine className={styles.middleLine} />
    <Header3 center className={styles.secondaryHeader}>For Warehouse Owners</Header3>
    <div className={classNames(styles.container, styles.secondaryContainer)}>
      <div>
        <HowItWorksContentBlock title="Goods Drop-Offs">
          Your space guest will book a specific date and time for dropping off
          their goods at your warehouse. You will be able to manage the
          available times for drop-offs or pickups from your user dashboard.
          You will also need to confirm that the date/time selected by your
          space guest will work for you.
        </HowItWorksContentBlock>
        <HowItWorksContentBlock title="Managing Spaces">
          After the goods have arrived at your space, it is your
          responsibility to fulfill your host obligations—which,
          in short, means that you are responsible for safely putting
          the goods away, and protecting them from damage or theft
          while in your possession. You can read the details in our
          {' '}
          <TextLink to={ENDPOINTS.TERMS_AND_CONDITIONS}>Terms and Conditions.</TextLink>
        </HowItWorksContentBlock>
      </div>
      <div>
        <img src={BoxesImage} className={styles.secondaryImage} alt="Boxes" />
      </div>
    </div>
    <GoToButton to={LIST_YOUR_SPACE_COMMON_ENDPOINT} className={styles.goToButton}>
      List Your Space
    </GoToButton>
  </StaticPageWrapper>
);

export default HowItWorksHaveContent;
