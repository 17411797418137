import { createReducer } from '@reduxjs/toolkit';
import { IOrderPaymentRequirementResponse } from '@screens/ServiceOrderCheckout/model/OrderPaymentRequirementResponse';
import { fetchOrderPaymentRequirementsRoutine } from '@screens/ServiceOrderCheckout/routines';

export interface IOrderSummaryAndSubmissionReducerState {
  requirements: IOrderPaymentRequirementResponse;
}

const initialState: IOrderSummaryAndSubmissionReducerState = {
  requirements: undefined
};

export const orderSummaryAndSubmissionReducer = createReducer(initialState, {
  [fetchOrderPaymentRequirementsRoutine.SUCCESS]: (state, { payload }) => {
    state.requirements = payload;
  }
});

