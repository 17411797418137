import React from 'react';
import classNames from 'classnames';
import { Moment } from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { IBindingCallback1 } from '@models/Callbacks';
import styles from './styles.module.scss';

export interface ISimpleTimePickerProps {
  value: Moment;
  onChange: IBindingCallback1<Moment>;
  minuteStep?: number;
  label?: string;
  className?: string;
}

const SimpleTimePicker: React.FC<ISimpleTimePickerProps> = (
  { value, className, onChange, minuteStep, label }
) => (
  <div className={styles.time_picker_wrapper}>
    {label && (<label>{label}</label>)}
    <TimePicker
      className={classNames(
        styles.simple_time_picker,
        className
      )}
      popupClassName={classNames(
        styles.simple_time_picker,
        className
      )}
      showSecond={false}
      onChange={onChange}
      hideDisabledOptions
      minuteStep={minuteStep || 5}
      value={value}
      use12Hours
    />
  </div>
);

export default SimpleTimePicker;
