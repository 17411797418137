import { combineReducers } from 'redux';
import { RootState } from '@root/store';
import { reducerCreator } from '@helpers/reducer.helper';
import { dashboardReducer } from '@screens/AdminDashboard/Dashboard/containers/DashboardPage/reducer';
/* PlopJS import placeholder. Do not remove */
import { readMessageFromDashboardRoutine,
  sendMessageFromDashboardRoutine,
  fetchDashboardMessagesRoutine } from '@screens/AdminDashboard/Dashboard/routines';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  readMessageFromDashboardRequest: reducerCreator([readMessageFromDashboardRoutine.TRIGGER]),
  sendMessageFromDashboardRequest: reducerCreator([sendMessageFromDashboardRoutine.TRIGGER]),
  fetchDashboardMessagesRequest: reducerCreator([fetchDashboardMessagesRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  data: dashboardReducer
});

const reqs = (state: RootState) => state.adminDashboard.dashboard.requests;
const data = (state: RootState) => state.adminDashboard.dashboard.data;

export const extractMyMessages = state => data(state).messages;
export const extractMoreMessages = state => data(state).moreMessages;
export const extractWriting = state => data(state).writing;
export const extractFullMessage = state => data(state).messageFull;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractReadMessageFromDashboardLoading = state => reqs(state).readMessageFromDashboardRequest.loading;
export const extractReadMessageFromDashboardError = state => reqs(state).readMessageFromDashboardRequest.error;
export const extractSendMessageFromDashboardLoading = state => reqs(state).sendMessageFromDashboardRequest.loading;
export const extractSendMessageFromDashboardError = state => reqs(state).sendMessageFromDashboardRequest.error;
export const extractFetchDashboardMessagesLoading = state => reqs(state).fetchDashboardMessagesRequest.loading;
export const extractFetchDashboardMessagesError = state => reqs(state).fetchDashboardMessagesRequest.error;
