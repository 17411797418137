import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  LIST_YOUR_SPACE_COMMON_ENDPOINT
} from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import { ENDPOINTS } from '@root/containers/Routing/endpoints';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import styles from './styles.module.scss';
import Header2 from '@components/NewDesign/Typography/Headers/Header2';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import SubHeader2 from '@components/NewDesign/Typography/SubHeaders/SubHeader2';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';

export const MAIN_DESCRIPTION = 'Shared Spaces is a match-making platform where those looking for warehouse, office and flex space '
  + 'can connect with those with excess space who are willing to host. Everyone wins!';

const WhySharedSpacesBlock: React.FC = () => {
  const history = useHistory();
  return (
    <div className={styles.background}>
      <div className={classNames('content_wrapper', styles.container)}>
        <Header2 center>Why Shared Spaces?</Header2>
        <div className={styles.contentWrapper}>
          <BodyText1 center>
            {MAIN_DESCRIPTION}
          </BodyText1>
          <div className={styles.equal_columns}>
            <div>
              <div>
                <SubHeader2 center marginBottom>The haves</SubHeader2>
                <BodyText2 center marginBottom>
                  People or organizations with excess office, warehouse or flex space to HOST
                </BodyText2>
              </div>
              <div>
                <PrimaryButton onClick={() => history.push(LIST_YOUR_SPACE_COMMON_ENDPOINT)}>List spaces</PrimaryButton>
              </div>
            </div>
            <div>
              <div>
                <SubHeader2 center marginBottom>The needs</SubHeader2>
                <BodyText2 center marginBottom>
                  People or organizations looking for office, warehouse, or flex space for cheap.
                  Also known as GUESTS
                </BodyText2>
              </div>
              <div>
                <PrimaryButton onClick={() => history.push(ENDPOINTS.BROWSE)}>Find spaces</PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySharedSpacesBlock;
