import React, { useEffect, useState } from 'react';
import { Modal, ModalContent } from 'semantic-ui-react';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import SpacesManagementPage from '@components/NewDesign/Spaces/SpacesManagementPage';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import {
  extractChosenSpace,
  extractFetchSpaceDetailsLoading, extractFetchSpacesBySpaceTemplateLoading, extractSaveSpaceLoading,
  extractSaveSpaceTemplateLoading, extractSpaces,
  extractSpaceTemplate
} from '@screens/SpaceEditor/reducers';
import {
  clearSpacesRoutine,
  fetchSpaceDetailsRoutine,
  saveSpaceRoutine, saveSpaceTemplateRoutine
} from '@screens/SpaceEditor/routines';
import { connect } from 'react-redux';
import { ISpaceTemplateDto } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import { ISaveRequest } from '@screens/SpaceEditor/containers/SpaceEditorPage/sagas';
import { ISpaceEdit } from '@screens/SpaceEditor/models/ISpaceEdit';
import { fetchBuildingDetailsRoutine } from '@screens/BuildingEditor/routines';
import { extractBuilding } from '@screens/BuildingEditor/reducers';
import { IBuildingForEditing } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { ISpaceTemplateModificationRequest } from '@components/NewDesign/Spaces/SpacesEditingForm';

interface IState {
  saveLoading: boolean;
  spaceTemplateLoading: boolean;
  spaceTemplate: ISpaceTemplateDto;
  building: IBuildingForEditing;
}

interface IActions {
  saveTemplateSpace: IBindingCallback1<ISaveRequest>;
  loadSpaceTemplate: IBindingCallback1<string>;
  saveSpace: IBindingCallback1<ISpaceEdit>;
  clearSpaces: IBindingAction;
  loadBuilding: IBindingCallback1<string>;
}

export interface ISpaceCreationModalProps extends IState, IActions {
  triggerOpenRef?: React.MutableRefObject<IBindingAction>;
  createSpace: IBindingCallback1<ISpaceTemplateModificationRequest>;
  creationLoading: boolean;
}

const SpaceCreationModal: React.FC<ISpaceCreationModalProps> = (
  { triggerOpenRef, creationLoading, createSpace,
    spaceTemplate, saveTemplateSpace, clearSpaces, building, loadBuilding
  }
) => {
  const [open, setOpen] = useState<boolean>();

  useEffect(() => {
    triggerOpenRef.current = () => {
      setOpen(true);
    };
  }, [triggerOpenRef]);

  const saveTemplate = data => saveTemplateSpace({ id: spaceTemplate?.id, data });

  return (
    <Modal
      open={open}
      onClose={() => {
        clearSpaces();
        setOpen(false);
        loadBuilding(building?.id);
      }}
      closeOnEscape={false}
      size="small"
      closeIcon
    >
      <ModalHeaderWrapper>Create new space</ModalHeaderWrapper>
      <ModalContent>
        <SpacesManagementPage
          isSpaceTemplateLoaded={!!spaceTemplate}
          saveLoading={creationLoading}
          loadingValues={false}
          saveSpaceTemplate={spaceTemplate ? saveTemplate : createSpace}
          initialData={undefined}
        />
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps: (state) => IState = state => ({
  saveLoading: extractSaveSpaceTemplateLoading(state),
  spaceTemplateLoading: extractFetchSpaceDetailsLoading(state),
  spaceTemplate: extractSpaceTemplate(state),
  spacesLoading: extractFetchSpacesBySpaceTemplateLoading(state),
  spaces: extractSpaces(state),
  chosenSpace: extractChosenSpace(state),
  saveSpaceLoading: extractSaveSpaceLoading(state),
  building: extractBuilding(state)
});

const mapDispatchToProps: IActions = {
  loadSpaceTemplate: fetchSpaceDetailsRoutine,
  saveSpace: saveSpaceRoutine,
  saveTemplateSpace: saveSpaceTemplateRoutine,
  loadBuilding: fetchBuildingDetailsRoutine,
  clearSpaces: clearSpacesRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(SpaceCreationModal);

