/* eslint-disable max-len */
import { createRoutine } from 'redux-saga-routines';

const createPayoutsRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`ADMIN_DASHBOARD__PAYOUTS:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const doPayoutRoutine = createPayoutsRoutine('DO_PAYOUT');
export const loadPayoutsSummaryRoutine = createPayoutsRoutine('LOAD_PAYOUTS_SUMMARY');
export const loadPayoutsHistoryRoutine = createPayoutsRoutine('LOAD_PAYOUTS_HISTORY');
export const setCurrentPageRoutine = createPayoutsRoutine<number>('SET_PAYOUTS_HISTORY_CURRENT_PAGE');
