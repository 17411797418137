import React from 'react';
import StaticPageWrapper from '@components/NewDesign/StaticPageWrapper';
import PropertyOwnersManagers from '@screens/static/PartnerWithUs/components/PropertyOwnersManagers';
import WarehouseLogisticsOfficeSpaceManagement
  from '@screens/static/PartnerWithUs/components/WarehouseLogisticsOfficeSpaceManagement';
import MetaTags from '@components/MetaTags';

const PartnerWithUsPage: React.FC = () => (
  <StaticPageWrapper title="Partner with Us">
    <MetaTags
      title="Partner with Us"
      withSuffix
      description="For Property Owners/Managers or Warehouse/Logistics and Office Space Management Experts"
    />
    <PropertyOwnersManagers />
    <WarehouseLogisticsOfficeSpaceManagement />
  </StaticPageWrapper>
);

export default PartnerWithUsPage;
