import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import {
  blockUserRoutine,
  fetchMemberDetailsRoutine,
  fetchMembersRoutine,
  saveMemberProfileRoutine, unblockUserRoutine,
  fetchMembersByFilterRoutine, sendMessageFromMembersRoutine, setWritingMessageFromMembersRoutine
} from '@screens/AdminDashboard/MembersSummary/routines';
import { Routine } from 'redux-saga-routines';
import { toastr } from 'react-redux-toastr';
import userService from '@services/user.service';
import {
  extractCurrentPage,
  extractCurrentSize,
  extractMemberDetails
} from '@screens/AdminDashboard/MembersSummary/reducers';
import { trySendMessage } from '@screens/HavesDashboard/Dashboard/containers/DashboardPage/sagas';

export const tryFetchMembers = (routine: Routine) => function* fetchMembers({ payload }: Routine<any>) {
  try {
    const resp = yield call(userService.fetchMembers, payload);
    yield put(routine.success(resp));
  } catch (e) {
    toastr.error('Failed to fetch members', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const tryFetchMembersByFilter = (routine: Routine) => function* fetchMembersByFilter({ payload }: Routine<any>) {
  try {
    const resp = yield call(userService.fetchMembersByFilter, payload);
    yield put(routine.success(resp));
  } catch (e) {
    toastr.error('Failed to fetch members by filter', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const tryFetchMemberDetails = (routine: Routine) => function* fetchMemberDetails({ payload }: Routine<any>) {
  try {
    const resp = yield call(userService.fetchMemberById, payload);
    yield put(routine.success(resp));
  } catch (e) {
    toastr.error('Failed to fetch member details', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const trySaveMemberProfile = (routine: Routine, loadRoutine: Routine) => function* saveMemberProfile({ payload }: Routine<any>) {
  try {
    const { id } = yield select(extractMemberDetails);
    const resp = id
      ? yield call(userService.updateMemberProfile, { id, data: payload })
      : yield call(userService.createMemberProfile, payload);
    toastr.success('Success', 'Member profile has been successfully saved');
    yield put(routine.success(resp));
    const page = yield select(extractCurrentPage);
    const size = yield select(extractCurrentSize);
    yield put(loadRoutine.trigger({ page, size }));
  } catch (e) {
    toastr.error('Failed to update member profile', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const tryBlockUser = (
  routine: Routine, loadRoutine: Routine, loadOneRoutine: Routine
) => function* blockUser({ payload }: Routine<any>) {
  try {
    yield call(userService.blockUser, payload);
    toastr.success('Success', 'User has been successfully blocked');
    yield put(routine.success());
    yield put(loadOneRoutine.trigger(payload));
    const page = yield select(extractCurrentPage);
    const size = yield select(extractCurrentSize);
    yield put(loadRoutine.trigger({ page, size }));
  } catch (e) {
    toastr.error('Failed to block user', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const tryUnblockUser = (
  routine: Routine, loadRoutine: Routine, loadOneRoutine: Routine
) => function* unblockUser({ payload }: Routine<any>) {
  try {
    yield call(userService.unblockUser, payload);
    toastr.success('Success', 'User has been successfully unblocked');
    yield put(routine.success());
    yield put(loadOneRoutine.trigger(payload));
    const page = yield select(extractCurrentPage);
    const size = yield select(extractCurrentSize);
    yield put(loadRoutine.trigger({ page, size }));
  } catch (e) {
    toastr.error('Failed to unblock user', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export default function* membersSummaryPageSagas() {
  yield all([
    yield takeEvery(fetchMembersRoutine.TRIGGER, tryFetchMembers(fetchMembersRoutine)),
    yield takeEvery(fetchMembersByFilterRoutine.TRIGGER, tryFetchMembersByFilter(fetchMembersByFilterRoutine)),
    yield takeEvery(fetchMemberDetailsRoutine.TRIGGER, tryFetchMemberDetails(fetchMemberDetailsRoutine)),
    yield takeEvery(
      saveMemberProfileRoutine.TRIGGER, trySaveMemberProfile(saveMemberProfileRoutine, fetchMembersRoutine)
    ),
    yield takeEvery(
      blockUserRoutine.TRIGGER, tryBlockUser(blockUserRoutine, fetchMembersRoutine, fetchMemberDetailsRoutine)
    ),
    yield takeEvery(
      unblockUserRoutine.TRIGGER, tryUnblockUser(unblockUserRoutine, fetchMembersRoutine, fetchMemberDetailsRoutine)
    ),
    yield takeEvery(sendMessageFromMembersRoutine.TRIGGER, trySendMessage(
      sendMessageFromMembersRoutine, setWritingMessageFromMembersRoutine
    ))
  ]);
}
