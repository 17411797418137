import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as toastr } from 'react-redux-toastr';
/* PlopJS import placeholder. Do not remove */
import receiptPage from '@screens/ReceiptPage/reducers';
import termsAndConditions from '@screens/TermsAndConditions/Root/reducers';
import errorModal from '@screens/ErrorModal/reducers';
import adminDashboard from '@screens/AdminDashboard/Root/reducers';
import creditCardConfiguration from '@screens/CreditCardConfiguration/reducers';
import spaceEditor from '@screens/SpaceEditor/reducers';
import buildingEditor from '@screens/BuildingEditor/reducers';
import havesDashboard from '@screens/HavesDashboard/Root/reducers';
import needsDashboard from '@screens/NeedsDashboard/Root/reducers';
import checkout from '@screens/Checkout/reducers';
import bookingCheckout from '@screens/BookingCheckout/reducers';
import serviceOrderCheckout from '@screens/ServiceOrderCheckout/reducers';
import auth from '@screens/Authorization/reducers';
import spaceBrowse from '@screens/BrowseSpaces/reducers';
import buildingDetails from '@screens/BuildingDetails/reducers';
import routerReducer from '@containers/AppRoute/reducer';
import stompClient from './stompClient';
import staticPage from '@screens/static/reducers';
import scriptLoaderReducer from '@containers/ScriptLoader/reducer';
import { translationReducer } from './translation.reducer';

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  toastr,
  routerReducer,
  stompClient,
  scriptLoaderReducer,
  translation: translationReducer,
  /* PlopJS reducer placeholder. Do not remove */
  receiptPage,
  termsAndConditions,
  errorModal,
  adminDashboard,
  creditCardConfiguration,
  spaceEditor,
  buildingEditor,
  havesDashboard,
  needsDashboard,
  bookingCheckout,
  serviceOrderCheckout,
  auth,
  spaceBrowse,
  buildingDetails,
  staticPage,
  checkout
});

export default createRootReducer;
