import React from 'react';
import styles from '@screens/AdminDashboard/BuildingsAndSpaces/components/BuildingsAndSpacesSection/BuildingsSummaryRow/styles.module.scss';
import { Image, TableCell, TableRow } from 'semantic-ui-react';
import { IBuildingsSummaryShort } from '@screens/AdminDashboard/BuildingsAndSpaces/model/IBuildingsSummaryShort';
import { getDefaultImageFromBuildingType } from '@helpers/image.placeholder.helper';

interface IBuildingSummaryRowProps {
  building: IBuildingsSummaryShort;
  onClick: () => void;
}

const BuildingsSummaryRow: React.FC<IBuildingSummaryRowProps> = ({ building, onClick }) => (
  <TableRow key={building.id} className={styles.row} onClick={onClick}>
    <TableCell>
      <Image
        className={styles.avatar_image}
        src={building.avatar ? building.avatar : getDefaultImageFromBuildingType(building.type)}
      />
    </TableCell>
    <TableCell className={styles.ellipsis} verticalAlign="middle">
      {building.buildingName}
    </TableCell>
    <TableCell className={styles.ellipsis} verticalAlign="middle">
      {building.type}
    </TableCell>
    <TableCell className={styles.ellipsis} verticalAlign="middle">
      {building.address1}
    </TableCell>
  </TableRow>
);

export default BuildingsSummaryRow;
