import React from 'react';
import HtmlScript from '@components/HtmlScript';
import { env } from '@root/env';

export interface IGoogleAnalyticsScriptProps {
}

const GoogleAnalyticsScript: React.FC<IGoogleAnalyticsScriptProps> = () => {
  const srcAsync = `https://www.googletagmanager.com/gtag/js?id=${env.googleAnalyticsTrackingId}`;
  const content = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${env.googleAnalyticsTrackingId}');
  `;

  return (
    <>
      <HtmlScript async src={srcAsync} />
      <HtmlScript content={content} />
    </>
  );
};

export default GoogleAnalyticsScript;
