import React from 'react';
import { IServiceShortDto } from '@screens/HavesDashboard/AdditionalServices/model/ServiceShortDto';
import AdditionalServiceTile from '@screens/HavesDashboard/AdditionalServices/components/AdditionalServiceTile';
import TilesWrapper from '@components/TilesWrapper';
import { IBindingCallback1 } from '@models/Callbacks';

export interface IAdditionalServicesListProps {
  services: IServiceShortDto[];
  loadOneById: IBindingCallback1<string>;
}

const AdditionalServicesList: React.FC<IAdditionalServicesListProps> = (
  { services, loadOneById }
) => (
  <TilesWrapper defaultBorder>
    {services.map(service => (
      <AdditionalServiceTile
        key={service.id}
        service={service}
        onClick={() => loadOneById(service.id)}
      />
    ))}
  </TilesWrapper>
);

export default AdditionalServicesList;
