import { all, takeEvery } from 'redux-saga/effects';
import {
  fetchBuildingDetailsRoutine,
  fetchSpaceAvailabilityRoutine,
  requestBookingRoutine,
  sendMessageFromSpacesToOwnerRoutine,
  setWritingMessageFromSpacesToOwnerRoutine,
  toggleFavoriteBuildingRoutine
} from '@screens/NeedsDashboard/BuildingDetails/routines';
import {
  tryBookSpaces,
  tryFetchBuildingDetails,
  tryFetchSpaceAvailability
} from '@screens/BuildingDetails/containers/BuildingDetailsPage/sagas';
import { tryToggleFavoriteBuilding } from '@sagas/favorite.building.saga';
import { trySendMessage } from '@screens/HavesDashboard/Dashboard/containers/DashboardPage/sagas';
import { toggleResetSelectedSpacesRoutine } from '@screens/BuildingDetails/routines';

export default function* buildingDetailsPageSagas() {
  yield all([
    yield takeEvery(fetchBuildingDetailsRoutine.TRIGGER, tryFetchBuildingDetails(fetchBuildingDetailsRoutine)),
    yield takeEvery(fetchSpaceAvailabilityRoutine.TRIGGER, tryFetchSpaceAvailability(fetchSpaceAvailabilityRoutine)),
    yield takeEvery(requestBookingRoutine.TRIGGER,
      tryBookSpaces(requestBookingRoutine, fetchSpaceAvailabilityRoutine, toggleResetSelectedSpacesRoutine)),
    yield takeEvery(toggleFavoriteBuildingRoutine.TRIGGER, tryToggleFavoriteBuilding(toggleFavoriteBuildingRoutine)),
    yield takeEvery(sendMessageFromSpacesToOwnerRoutine.TRIGGER, trySendMessage(
      sendMessageFromSpacesToOwnerRoutine, setWritingMessageFromSpacesToOwnerRoutine
    ))
  ]);
}
