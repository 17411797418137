import React, { useCallback, useState } from 'react';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import { IBindingCallback1 } from '@models/Callbacks';
import { AddMultipleSpacesModal } from './AddMultipleSpacesModal';

interface IProps {
  onSpacesAdd: IBindingCallback1<any>;
}

const AddMultipleSpacesButtonWithModal = ({ onSpacesAdd }: IProps) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleOpenConfirmationModal = () => setConfirmationOpen(true);
  const handleCloseConfirmationModal = useCallback(() => setConfirmationOpen(false), []);

  const handleAddSpaces = useCallback((amount: number) => {
    handleCloseConfirmationModal();
    onSpacesAdd(amount);
  }, [handleCloseConfirmationModal, onSpacesAdd]);

  return (
    <>
      <PrimaryButton
        content="Add Multiple Spaces"
        type="button"
        onClick={handleOpenConfirmationModal}
      />
      <AddMultipleSpacesModal
        open={confirmationOpen}
        onClose={handleCloseConfirmationModal}
        onAddSpaces={handleAddSpaces}
      />
    </>
  );
};

export { AddMultipleSpacesButtonWithModal };
