import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import {
  addNewCreditCardRoutine,
  loadCreditCardsRoutine,
  removeCardRoutine,
  setDefaultCardRoutine
} from '@screens/CreditCardConfiguration/routines';
import { ICreditCard } from '@screens/CreditCardConfiguration/model/CreditCard';
import {
  extractAddNewCreditCardError,
  extractAddNewCreditCardLoading,
  extractCreditCards,
  extractLoadCreditCardsLoading, extractProcessingCreditCardId,
  extractRemoveCardLoading,
  extractSetDefaultCardLoading
} from '@screens/CreditCardConfiguration/reducers';
import { Loader } from 'semantic-ui-react';
import NewCardModal from '@screens/CreditCardConfiguration/components/NewCardModal';
import { extractUserEmail } from '@screens/Authorization/reducers';
import { ICreateCardRequest } from '@components/StripeAddCardForm';
import CreditCardItem from '@screens/CreditCardConfiguration/components/CreditCardItem';
import styles from './styles.module.scss';
import NoDataContainer from '@components/NoDataContainer';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import { ENDPOINTS } from '@containers/Routing/endpoints';

export interface ICreditCardConfigurationProps extends IState, IActions {
  newCardModalOpen?: boolean;
  setNewCardModalOpen?: IBindingCallback1<boolean>;
}

interface IState {
  creditCards: ICreditCard[];
  cardsLoading: boolean;
  email: string;
  addNewCardLoading: boolean;
  addNewCardError?: string;
  removeLoading: boolean;
  defaultLoading: boolean;
  processingId?: string;
}

interface IActions {
  loadCreditCards: IBindingAction;
  addNewCard: IBindingCallback1<ICreateCardRequest>;
  removeCard: IBindingCallback1<string>;
  setDefaultCard: IBindingCallback1<string>;
}

const CreditCardConfiguration: React.FC<ICreditCardConfigurationProps> = (
  {
    cardsLoading, creditCards, loadCreditCards, addNewCardLoading, addNewCard, email,
    addNewCardError, removeCard, setDefaultCard,
    newCardModalOpen, setNewCardModalOpen, processingId
  }
) => {
  useEffect(() => {
    loadCreditCards();
  }, [loadCreditCards]);

  return (
    <>
      {cardsLoading && <Loader active inline="centered" />}
      {!cardsLoading && (
        <>
          {creditCards.length === 0 ? (
            <NoDataContainer message="You have not added any cards yet" />
          ) : (
            <div className={styles.credit_cards_wrapper}>
              <div className={styles.cards_container}>
                {creditCards.map(cc => (
                  <CreditCardItem
                    className={styles.credit_card}
                    key={cc.id}
                    creditCard={cc}
                    onRemove={() => removeCard(cc.id)}
                    onSetPrimary={() => setDefaultCard(cc.id)}
                    loading={processingId === cc.id}
                    disabled={!!processingId}
                  />
                ))}
              </div>
              <div className={styles.go_to_container}>
                <GoToButton to={ENDPOINTS.BROWSE}>Browse Spaces</GoToButton>
              </div>
            </div>
          )}
        </>
      )}
      <NewCardModal
        email={email}
        addNewCardError={addNewCardError}
        addNewCardLoading={addNewCardLoading}
        open={newCardModalOpen}
        setOpen={setNewCardModalOpen}
        addNewCard={addNewCard}
      />
    </>
  );
};

const mapStateToProps: (state) => IState = state => ({
  creditCards: extractCreditCards(state),
  cardsLoading: extractLoadCreditCardsLoading(state),
  email: extractUserEmail(state),
  addNewCardLoading: extractAddNewCreditCardLoading(state),
  addNewCardError: extractAddNewCreditCardError(state),
  removeLoading: extractRemoveCardLoading(state),
  defaultLoading: extractSetDefaultCardLoading(state),
  processingId: extractProcessingCreditCardId(state)
});

const mapDispatchToProps: IActions = {
  loadCreditCards: loadCreditCardsRoutine,
  addNewCard: addNewCreditCardRoutine,
  removeCard: removeCardRoutine,
  setDefaultCard: setDefaultCardRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardConfiguration);
