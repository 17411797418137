import React from 'react';
import { connect } from 'react-redux';
import { IPartnerFormData } from '@screens/static/Partner/model/IPartnerFormData';
import { IBindingCallback1 } from '@models/Callbacks';
import {
  extractSubmitPartnerPageFormLoading,
  extractSubmitPartnerPageFormError
} from '@screens/static/Partner/reducers';
import { submitPartnerPageRoutine } from '@screens/static/routines';
import StaticPageWrapper from '@components/NewDesign/StaticPageWrapper';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import PartnerPageForm from '@screens/static/Partner/components/PartnerPageForm';
import MetaTags from '@components/MetaTags';

export interface IPartnerPageFromProps {
  saveChanges: IBindingCallback1<IPartnerFormData>;
  partnerLoading?: boolean;
}

const PartnerPage: React.FC<IPartnerPageFromProps> = (
  { partnerLoading, saveChanges }
) => (
  <StaticPageWrapper title="I Own or Manage Space">
    <MetaTags
      title="I Own or Manage Space"
      withSuffix
      description="I would like to share my spaces but do not have the time
                or resources to do this. I would like some help, please"
    />
    <BodyText1 center>
      I would like to share my spaces but do not have the time
      or resources to do this. I would like some help, please
    </BodyText1>
    <PartnerPageForm
      saveChanges={saveChanges}
      partnerLoading={partnerLoading}
    />
  </StaticPageWrapper>
);

const mapStateToProps = state => ({
  partnerLoading: extractSubmitPartnerPageFormLoading(state),
  partnerError: extractSubmitPartnerPageFormError(state)
});

const mapDispatchToProps = {
  saveChanges: submitPartnerPageRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerPage);
