import React from 'react';
import { Label, TableCell, TableRow } from 'semantic-ui-react';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';

export interface ITransactionTableRowProps {
  label: string;
  element: JSX.Element;
}

const TransactionTableRow: React.FC<ITransactionTableRowProps> = ({ label, element }) => (
  <TableRow>
    <TableCell>
      <Label size="large" horizontal>
        <TableImportantContent disableWordBreak>
          {label}
        </TableImportantContent>
      </Label>
    </TableCell>
    <TableCell>
      {element}
    </TableCell>
  </TableRow>
);

export default TransactionTableRow;
