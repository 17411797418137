/* eslint-disable max-len */
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getInitialPageableState } from '@models/domain/PageableReducerState';
import { IPageableResult } from '@models/domain/PageableResult';
import {
  completeOrderedServiceRoutine,
  completeOrderRoutine,
  fetchOrdersRoutine,
  setCurrentPageRoutine,
  setExpandedOrderRoutine
} from '@screens/HavesDashboard/Orders/routines';
import { IServiceOrderDetailsDto } from '@screens/NeedsDashboard/Orders/model/ServiceOrderDetailsDto';
import { logOutRoutine } from '@screens/Authorization/routines';
import { IOrderedServiceDto } from '@screens/ServiceOrderCheckout/model/OrderedServiceDto';
import { IServiceOrderDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderDto';

const initialState = {
  ...getInitialPageableState<IServiceOrderDetailsDto>(),
  expandedOrder: null
};

export const ordersTableReducer = createReducer(initialState, {
  [fetchOrdersRoutine.SUCCESS]: (state, { payload }: PayloadAction<IPageableResult<IServiceOrderDetailsDto>>) => {
    state.items = payload.items;
    state.totalPages = payload.totalPages;
    state.totalResults = payload.totalResults;
  },
  [setCurrentPageRoutine.FULFILL]: (state, { payload }) => {
    state.page = payload;
  },
  [logOutRoutine.SUCCESS]: () => getInitialPageableState<IServiceOrderDetailsDto>(),
  [setExpandedOrderRoutine.FULFILL]: (state, { payload }: PayloadAction<IServiceOrderDetailsDto>) => {
    state.expandedOrder = payload;
  },
  [completeOrderedServiceRoutine.SUCCESS]: (state, { payload }: PayloadAction<IOrderedServiceDto>) => {
    let updatedOrder = null;

    state.items = state.items.map(item => {
      if (item.order.id !== payload.orderId) {
        return item;
      }

      const updatedServices = item.services.map(service => (service.id === payload.id
        ? { ...service, completed: payload.completed }
        : service));

      const updatedItem = {
        ...item,
        services: updatedServices
      };

      updatedOrder = updatedItem;

      return updatedItem;
    });

    state.expandedOrder = updatedOrder;
  },
  [completeOrderRoutine.SUCCESS]: (state, { payload }: PayloadAction<IServiceOrderDto>) => {
    let updatedOrder = null;

    state.items = state.items.map(item => {
      if (item.order.id !== payload.id) {
        return item;
      }

      const updatedItem = {
        ...item,
        order: payload
      };

      updatedOrder = updatedItem;

      return updatedItem;
    });

    state.expandedOrder = updatedOrder;
  }
});
