import React from 'react';
import { lowerCase, startCase } from 'lodash';
import { toPrice } from '@helpers/price.helper';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';
import styles from './styles.module.scss';

export interface IServiceItemHeaderProps {
  service: IAdditionalServiceWithAvailability;
}

const ServiceItemHeader: React.FC<IServiceItemHeaderProps> = ({ service }) => (
  <div>
    <Caption1 className={styles.header}>
      {startCase(lowerCase(service.alias))}
      &nbsp;|
      {' '}
      {startCase(lowerCase(service.type))}
      &nbsp;|
      {' '}
      <span>
        {service.category}
      </span>
      &nbsp;|
      {' '}
      {`${service.availableAmount} ${service.billingUnit.toLowerCase()}${service.availableAmount === 1 ? '' : 's'} available`}
    </Caption1>
    <Caption2 className={styles.description}>
      {toPrice({ amount: service.price.amount, currency: service.price.currency })}
      /
      {lowerCase(service.billingUnit)}
    </Caption2>
  </div>
);

export default ServiceItemHeader;
