import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import AccountDetailsForm, {
  IAccountDetailsFormProps,
  IFormValues
} from '@screens/NeedsDashboard/Account/components/AccountDetailsForm';
import {
  loadProfileDetailsRoutine,
  saveProfileDetailsRoutine, setUpdatePasswordModalOpenRoutine, updatePasswordRoutine,
  uploadAvatarRoutine
} from '@screens/NeedsDashboard/Account/routines';
import {
  extractAvatar,
  extractLoadProfileDetailsLoading,
  extractProfileData,
  extractSaveProfileDetailsLoading, extractUpdatePasswordLoading, extractUpdatePasswordModalOpen,
  extractUploadAvatarLoading
} from '@screens/NeedsDashboard/Account/reducers';
import { IProfileData } from '@screens/NeedsDashboard/Account/model/ProfileData';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import AvatarEditorForm from '@screens/NeedsDashboard/Account/components/AvatarEditorForm';
import noAvatar from '@images/no_avatar.webp';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import styles from './styles.module.scss';
import PasswordUpdateModalLink from '@screens/NeedsDashboard/Account/components/PasswordUpdateModalLink';
import { IPasswordUpdateDto } from '@screens/NeedsDashboard/Account/model/PasswordUpdateDto';

export interface IAccountProps extends IAccountDetailsFormProps {
  profileData: IProfileData;
  loadDetails: IBindingAction;
  profileDataLoading: boolean;
  uploadAvatar: IBindingCallback1<Blob>;
  avatarUploading: boolean;
  avatarSrc: string;
  updatePassword: IBindingCallback1<IPasswordUpdateDto>;
  updatePasswordLoading?: boolean;
  setUpdatePasswordModalOpen: IBindingCallback1<boolean>;
  updatePasswordModalOpen?: boolean;
}

export const profileDataToForm = (value: IProfileData): IFormValues => ({
  ...value,
  ...value?.location,
  password: value?.password?.value
});

export interface IAccountLocationState {
  autoFocusInputName?: string;
}

const Account: React.FC<IAccountProps & RouteComponentProps> = (
  {
    loadDetails, saveChanges, savingLoading, profileData, profileDataLoading,
    uploadAvatar, avatarUploading, avatarSrc, history, updatePasswordModalOpen,
    updatePasswordLoading, updatePassword, setUpdatePasswordModalOpen
  }
) => {
  useEffect(() => {
    loadDetails();
  }, [loadDetails]);

  const locationState: IAccountLocationState | undefined = history.location.state;
  const autoFocusInputName = locationState?.autoFocusInputName;

  return (
    <DashboardPageWrapper title="Account Details">
      <PasswordUpdateModalLink
        updatePassword={updatePassword}
        updatePasswordLoading={updatePasswordLoading}
        updatePasswordModalOpen={updatePasswordModalOpen}
        setUpdatePasswordModalOpen={setUpdatePasswordModalOpen}
      />
      <DashboardSectionWrapper title="My Profile">
        <AvatarEditorForm
          uploadImage={uploadAvatar}
          existingAvatarSrc={avatarSrc || noAvatar}
          loading={avatarUploading}
        />
        <AccountDetailsForm
          initialValuesLoading={profileDataLoading}
          initialFormData={profileDataToForm(profileData)}
          className={styles.form}
          savingLoading={savingLoading}
          saveChanges={saveChanges}
          autoFocusInputName={autoFocusInputName}
        />
      </DashboardSectionWrapper>
    </DashboardPageWrapper>
  );
};

const mapStateToProps = state => ({
  savingLoading: extractSaveProfileDetailsLoading(state),
  profileData: extractProfileData(state),
  profileDataLoading: extractLoadProfileDetailsLoading(state),
  avatarUploading: extractUploadAvatarLoading(state),
  avatarSrc: extractAvatar(state),
  updatePasswordModalOpen: extractUpdatePasswordModalOpen(state),
  updatePasswordLoading: extractUpdatePasswordLoading(state)
});

const mapDispatchToProps = {
  loadDetails: loadProfileDetailsRoutine,
  saveChanges: saveProfileDetailsRoutine,
  uploadAvatar: uploadAvatarRoutine,
  updatePassword: updatePasswordRoutine,
  setUpdatePasswordModalOpen: setUpdatePasswordModalOpenRoutine.fulfill
};

export { Account };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));
