import React from 'react';
import Timer from '@components/NewDesign/Timer';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import PopupWrapper from '@components/NewDesign/PopupWrapper';
import { EXPIRATION_MINUTES } from '@screens/Checkout/containers/CheckoutPage';
import { ServiceOrderStatus } from '@models/domain/additional_service/ServiceOrderStatus';
import styles from './styles.module.scss';

export interface IOrderStatusLabelProps {
  status: ServiceOrderStatus;
  createdAt: string;
}

const orderStatusToTitle = (status: ServiceOrderStatus): string => {
  switch (status) {
    case ServiceOrderStatus.PENDING:
      return 'PENDING';
    case ServiceOrderStatus.CONFIRMED:
      return 'ACTIVE';
    case ServiceOrderStatus.CANCELLED:
      return 'CANCELLED';
    case ServiceOrderStatus.FINISHED:
      return 'COMPLETED';
    default:
      return '';
  }
};

const orderStatusToDescription = (status: ServiceOrderStatus): string => {
  switch (status) {
    case ServiceOrderStatus.PENDING:
      return 'You have a order waiting to be finalized';
    case ServiceOrderStatus.CONFIRMED:
      return 'Order has been made, and is currently active';
    case ServiceOrderStatus.CANCELLED:
      return 'Order has not been completed';
    case ServiceOrderStatus.FINISHED:
      return 'Order has been made, and is currently completed';
    default:
      return '';
  }
};

const OrderStatusLabel: React.FC<IOrderStatusLabelProps> = (
  { status, createdAt }
) => {
  const date = new Date(createdAt);
  date.setMinutes(date.getMinutes() + EXPIRATION_MINUTES);

  const content = status === ServiceOrderStatus.PENDING
    ? <Timer expirationDate={date} inline />
    : (
      <Caption1 className={styles[status]}>
        {orderStatusToTitle(status)}
      </Caption1>
    );

  return (
    <PopupWrapper
      trigger={(
        <span>
          {content}
        </span>
      )}
      content={orderStatusToDescription(status)}
    />
  );
};

export default OrderStatusLabel;
