import React, { useState } from 'react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Icon } from 'semantic-ui-react';
import { IBindingCallback1 } from '@models/Callbacks';
import { ILoginData } from '@screens/Authorization/models/LoginData';
import FormikInput from '@components/formik/Input';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import { emailValidation } from '@screens/Authorization/components/validation';
import styles from './styles.module.scss';

export interface ILoginFormProps {
  onLoginClick: IBindingCallback1<ILoginData>;
  loginLoading: boolean;
}

interface IFormValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: emailValidation
});

const LoginForm: React.FC<ILoginFormProps & FormikProps<IFormValues>> = props => {
  const { values, handleSubmit, loginLoading, isValid } = props;

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Form name="loginForm" onSubmit={handleSubmit}>
      <div className={styles.inputs_group}>
        <FormikInput
          propsOrFieldName={{
            name: 'email',
            placeholder: 'Email Address',
            autoComplete: 'email'
          }}
        />
        <div className={styles.passwordWrapper}>
          <FormikInput
            propsOrFieldName={{
              name: 'password',
              placeholder: 'Password',
              type: passwordShown ? 'text' : 'password',
              autoComplete: 'current-password'
            }}
          />
          <Icon name="eye" className={styles.showPassIcon} onClick={togglePasswordVisibility} />
        </div>
      </div>
      <div className={styles.buttons_section}>
        <SmallLinkButton to="/reset">Forgot password?</SmallLinkButton>
        <PrimaryButton
          className={styles.login_button}
          type="submit"
          disabled={!values.password || !values.email || !isValid}
          content="Log in"
          loading={loginLoading}
        />
      </div>
    </Form>
  );
};

export default withFormik<ILoginFormProps, IFormValues>({
  displayName: 'LoginForm',
  validationSchema,
  handleSubmit: ((values, { props }) => {
    props.onLoginClick(values);
  })
})(LoginForm);
