import { callApi } from '@helpers/api.helper';

const receiptService = {
  fetchBookingReceiptData: (transactionId: string) => callApi({
    endpoint: `/api/booking/receipt/${transactionId}`,
    method: 'GET'
  }),
  fetchServiceOrderReceiptData: (transactionId: string) => callApi({
    endpoint: `/api/service_orders/receipt/${transactionId}`,
    method: 'GET'
  })
};

export default receiptService;
