import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { IBindingAction } from '@models/Callbacks';

export interface IInformationSectionPropsExtended<T, WrapperProps extends IWrapperProps> {
  title?: string;
  items: T[];
  fetchItems: IBindingAction;
  itemsLoading: boolean;
  renderItem?: (item: T, index: number) => JSX.Element;
  itemsWrapper?: React.FC<WrapperProps>;
  noItemsPlaceholder?: React.FC;
  loadMoreComponent?: React.FC;
  haveMore?: boolean;
  hideTitle?: boolean;
  placeholderClassName?: any;
  itemsWrapperProps?: WrapperProps;
}

export type IInformationSectionProps<T> = IInformationSectionPropsExtended<T, IWrapperProps>

export interface IWrapperProps {
  haveMore?: boolean;
  titles?: string[];
  titlesElements?: JSX.Element[];
}

export function InformationSection<T, WrapperProps extends IWrapperProps>(
  {
    title, items, fetchItems, itemsLoading, loadMoreComponent: LoadMore, haveMore, noItemsPlaceholder: Placeholder,
    itemsWrapper: Wrapper, renderItem, hideTitle, itemsWrapperProps
  }: IInformationSectionPropsExtended<T, WrapperProps>
) {
  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!hideTitle && (
        <h2>{title}</h2>
      )}
      <Loader active={itemsLoading} inline="centered" />
      {itemsLoading || (
        <>
          {items.length === 0 ? (
            <Placeholder />
          ) : (
            <Wrapper {...itemsWrapperProps} haveMore={haveMore}>
              {items.map(renderItem)}
            </Wrapper>
          )}
          {haveMore && LoadMore && <LoadMore />}
        </>
      )}
    </>
  );
}
