import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalContent } from 'semantic-ui-react';
import BuildingEditorForm from '@screens/BuildingEditor/components/BuildingEditorForm';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { IBuildingForSave, IBuildingForSaveWithHaveId } from '@screens/BuildingEditor/model/BuildingForSave';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import { UserSelect } from '@components/SelectAutocomplete/UserSelect';
import { Role } from '@screens/Authorization/models/Roles';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import styles from './styles.module.scss';

export interface ICreateBuildingForHaveButtonWithModalProps {
  loadTags: IBindingAction;
  tagsLoading: boolean;
  onCreateBuilding: IBindingCallback1<IBuildingForSaveWithHaveId>;
  creationLoading: boolean;
  tags: string[];
}

const CreateBuildingForHaveButtonWithModal: React.FC<ICreateBuildingForHaveButtonWithModalProps> = (
  { creationLoading, onCreateBuilding, tagsLoading, loadTags, tags }
) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [haveId, setHaveId] = useState<number | null>(null);

  useEffect(() => {
    loadTags();
  }, [loadTags]);

  const handleHaveSelect = useCallback(({ value }) => {
    setHaveId(value);
  }, []);

  const handleCreateBuilding = useCallback((building: IBuildingForSave): void => {
    onCreateBuilding({
      ...building,
      haveId
    });
  }, [haveId, onCreateBuilding]);

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeOnEscape={false} closeIcon>
        <ModalHeaderWrapper>Create building for Have</ModalHeaderWrapper>
        <ModalContent>
          <Caption2
            className={styles.title}
            marginTop
          >
            Have
          </Caption2>
          <UserSelect onChange={handleHaveSelect} roles={[Role.HAVE]} />
          <BuildingEditorForm
            saveLoading={creationLoading}
            tagsLoading={tagsLoading}
            tags={tags}
            saveBuilding={handleCreateBuilding}
            isFormValid={!!haveId}
          />
        </ModalContent>
      </Modal>
      <PrimaryButton
        content="Add new"
        icon="plus"
        labelPosition="left"
        onClick={() => setModalOpen(true)}
      />
    </>
  );
};

export { CreateBuildingForHaveButtonWithModal };
