import React, { useEffect, useState } from 'react';
import { Modal, ModalContent } from 'semantic-ui-react';
import { ICommonSchedulesSegmentProps } from '@containers/Schedules/SchedulesSegment/Props';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import {
  IBookingDetailsForSchedulingDto, ITemplate2Amount
} from '@screens/NeedsDashboard/Bookings/model/BookingDetailsDto';
import { IBriefBookingDto } from '@models/domain/Booking';
import { Role } from '@screens/Authorization/models/Roles';
import { IBookingCancellationRequest } from '@screens/NeedsDashboard/Bookings/model/BookingCancellationRequest';
import { IScheduleSpaceNoteDto } from '@screens/NeedsDashboard/Bookings/model/ScheduleSpaceNoteDto';
import { ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { calcEmptySpaces, calcFilledSpaces } from '@helpers/spaces.helper';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/Bookings/model/ScheduleResponse';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import DividingLine from '@components/NewDesign/DividingLine';
import { IBookingDetailsProps } from './BookingDetails';
import SpacesDetails from './SpacesDetails';
import BuildingDetails from './BuildingDetails';

export interface IBookingDetailsModalProps<SchedulesProps extends ICommonSchedulesSegmentProps, BookingDto extends IBriefBookingDto> extends IModalProps {
  content: IBookingDetailsForSchedulingDto;
  schedulesSegmentProps: SchedulesProps;
  bookingDetailsComponent: React.FC<IBookingDetailsProps<BookingDto>>;
  schedulesSegmentComponent: React.FC<SchedulesProps>;
  currentRole: Role;
  onCancellationSubmit?: IBindingCallback1<IBookingCancellationRequest>;
  cancellationLoading?: boolean;
}

export interface IModalProps {
  open: boolean;
  onClose: IBindingAction;
}

function BookingDetailsModal<SchedulesProps extends ICommonSchedulesSegmentProps, BookingDto extends IBriefBookingDto>(
  {
    onClose, content, open, schedulesSegmentProps, bookingDetailsComponent: BookingDetailsComponent,
    schedulesSegmentComponent: SchedulesSegmentComponent, currentRole, onCancellationSubmit, cancellationLoading
  }: IBookingDetailsModalProps<SchedulesProps, BookingDto>
) {
  const { building, booking, templatesToAmounts } = content || {};
  const { load: loadSchedules, items: schedules } = schedulesSegmentProps.schedulesRequestDetails;
  const [availableSpaces, setAvailableSpaces] = useState<ISpaceWithAvailability[]>([]);
  const [filledSpaces, setFilledSpaces] = useState<IScheduleSpaceNoteDto[]>([]);

  useEffect(() => {
    if (booking?.id) loadSchedules(booking.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  const updateSpaces = (bookedT2A: ITemplate2Amount[], scheduleItems: IScheduleResponseDto[]) => {
    setAvailableSpaces(calcEmptySpaces(bookedT2A, scheduleItems));
    setFilledSpaces(calcFilledSpaces(scheduleItems));
  };

  useEffect(() => {
    if (templatesToAmounts && schedules) {
      updateSpaces(templatesToAmounts, schedules);
    }
  }, [schedules, templatesToAmounts]);

  return (
    <Modal open={open} onClose={onClose} closeIcon>
      {open && (
        <>
          <ModalHeaderWrapper>Booking details</ModalHeaderWrapper>
          <ModalContent>
            <BookingDetailsComponent
              booking={booking as BookingDto}
              currentRole={currentRole}
              onCancellationSubmit={onCancellationSubmit}
              cancellationLoading={cancellationLoading}
            />
            <BuildingDetails building={building} />
            <SpacesDetails
              templatesToAmounts={templatesToAmounts}
              availableSpaces={availableSpaces}
              filledSpaces={filledSpaces}
            />
            <DividingLine light marginTop marginBottom />
            <SchedulesSegmentComponent
              {...schedulesSegmentProps}
              availableSpaces={availableSpaces}
              filledSpaces={filledSpaces}
              updateSpaces={updateSpaces}
            />
          </ModalContent>
        </>
      )}
    </Modal>
  );
}

export default BookingDetailsModal;
