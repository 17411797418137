import React from 'react';
import { useDispatch } from 'react-redux';
import { env } from '@root/env';
import HtmlScript from '@components/HtmlScript';
import { setGooglePlacesScriptLoaded } from '@containers/ScriptLoader/reducer';

export const GOOGLE_MAPS_SCRIPT_CONTAINER_ID = 'shared-spaces-google-maps-script';

const GoogleMapsScript: React.FC = () => {
  const dispatch = useDispatch();
  const src = `https://maps.googleapis.com/maps/api/js?key=${env.googleApiKey}&libraries=places&loading=async&callback=initMap`;

  // eslint-disable-next-line func-names
  (window as any).initMap = function () {
    dispatch(setGooglePlacesScriptLoaded());
  };

  return (
    <HtmlScript src={src} id={GOOGLE_MAPS_SCRIPT_CONTAINER_ID} async />
  );
};

export default GoogleMapsScript;
