import React from 'react';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';
import { history } from '@helpers/history.helper';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import { IServiceOrderDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderDto';
import { ServiceOrderStatus } from '@models/domain/additional_service/ServiceOrderStatus';
import OrdersTable from '@screens/NeedsDashboard/Orders/containers/OrdersTable';
import OrderStatusLabel from '@components/OrderStatusLabel';
import { priceAmountWithCurrencyToPrice } from '@helpers/price.helper';
import TableRegularContent from '@components/NewDesign/Typography/FormAndTable/TableRegularContent';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import styles from './styles.module.scss';

export const serviceDetails = orderDetails => {
  const { services } = orderDetails;
  const title = services.length > 1
    ? `${services.length} services ordered`
    : services[0].alias;

  return <TableImportantContent>{title}</TableImportantContent>;
};

export const buildingName = orderDetails => (
  <TableRegularContent disableTranslation>
    {orderDetails.building.buildingName}
  </TableRegularContent>
);

export const orderStatusLabel = orderDetails => (
  <OrderStatusLabel
    status={orderDetails.order.status}
    createdAt={orderDetails.order.createdAt}
  />
);

export const totalPrice = orderDetails => {
  const { totalPrice: total, currency } = orderDetails.order;

  return (
    <TableRegularContent disableTranslation>
      {priceAmountWithCurrencyToPrice(total, currency)}
    </TableRegularContent>
  );
};

export const scheduledOn = orderDetails => {
  const { scheduledOnDate } = orderDetails.order;

  return (
    <TableRegularContent disableTranslation>
      {scheduledOnDate}
    </TableRegularContent>
  );
};

export const totalOrderedAmount = orderDetails => (
  <TableRegularContent disableTranslation>
    {orderDetails.services
      .map(service => service.orderedAmount)
      .reduce((prev, curr) => prev + curr, 0).toString()}
  </TableRegularContent>
);

export const buildingAddress = orderDetails => orderDetails.building.location?.address || 'Unknown';

export const isDisabled = (order: IServiceOrderDto) => order.status === ServiceOrderStatus.CANCELLED;

const OrdersPage: React.FC<{}> = () => {
  const handleRowClick = orderDetails => {
    if (orderDetails.order.status === ServiceOrderStatus.PENDING) {
      history.push(ENDPOINTS.SERVICE_ORDER_CHECKOUT(orderDetails.order.id));
    } else {
      history.push(NEEDS_DASHBOARD_ENDPOINTS.ORDER_DETAILS(orderDetails.order.id));
    }
  };

  return (
    <>
      <DashboardPageWrapper title="My Additional Services">
        <DashboardSectionWrapper>
          <OrdersTable
            tableProps={ordersDetails => ({
              titles: ['Service', 'Status', 'Building', 'Qty', 'Total', 'Scheduled On'],
              rows: ordersDetails.map(orderDetails => ({
                id: orderDetails.order.id,
                cells: [
                  { content: serviceDetails(orderDetails) },
                  { content: orderStatusLabel(orderDetails) },
                  { content: buildingName(orderDetails) },
                  { content: totalOrderedAmount(orderDetails) },
                  { content: totalPrice(orderDetails) },
                  { content: scheduledOn(orderDetails) }
                ],
                rowProps: {
                  onClick: () => handleRowClick(orderDetails),
                  className: isDisabled(orderDetails.order) && styles.disabled
                }
              }))
            })}
          />
        </DashboardSectionWrapper>
      </DashboardPageWrapper>
    </>
  );
};

export default OrdersPage;
