import { all, call, put, takeEvery } from 'redux-saga/effects';
import stripePaymentService from '@screens/BookingCheckout/services/payment.service';
import { doPayoutRoutine, loadPayoutsSummaryRoutine } from '@screens/AdminDashboard/Payouts/routines';
import { toastr } from 'react-redux-toastr';

function* tryLoadPayoutsSummary() {
  try {
    const resp = yield call(stripePaymentService.payoutsSummary);
    yield put(loadPayoutsSummaryRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load payouts summary', e?.message);
    yield put(loadPayoutsSummaryRoutine.failure());
  }
}

function* tryDoPayout() {
  try {
    yield call(stripePaymentService.doPayout);
    yield put(doPayoutRoutine.success());
    yield put(loadPayoutsSummaryRoutine.trigger());
    toastr.success('Success', 'Funds have been paid out successfully');
  } catch (e) {
    toastr.error('Unable to do payout', e?.message);
    yield put(doPayoutRoutine.failure());
  }
}

export default function* payoutsPageSagas() {
  yield all([
    yield takeEvery(loadPayoutsSummaryRoutine.TRIGGER, tryLoadPayoutsSummary),
    yield takeEvery(doPayoutRoutine.TRIGGER, tryDoPayout)
  ]);
}
