import { getOsEnv } from 'helpers/path.helper';

export const env = {
  stripeKey: getOsEnv('REACT_APP_STRIPE_PUBLISHABLE_KEY'),
  paypalClientId: getOsEnv('REACT_APP_PAYPAL_CLIENT_ID'),
  sentryDsn: getOsEnv('REACT_APP_SENTRY_DSN'),
  googleApiKey: getOsEnv('REACT_APP_GOOGLE_API_KEY'),
  geocodioApiKey: getOsEnv('REACT_APP_GEOCODIO_API_KEY'),
  pipeDriveLiveChatCompanyId: getOsEnv('REACT_APP_PIPEDRIVE_LIVE_CHAT_COMPANY_ID'),
  pipeDriveLiveChatPlaybookUuid: getOsEnv('REACT_APP_PIPEDRIVE_LIVE_CHAT_PLAYBOOK_UUID'),
  googleAnalyticsTrackingId: getOsEnv('REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID'),
  facebookPixelId: getOsEnv('REACT_APP_FACEBOOK_PIXEL_ID'),
  awsS3Documents: 'https://s3.amazonaws.com/sharedwarehouse/documents/',
  awsS3Translations: 'https://s3.amazonaws.com/sharedwarehouse/translations/',
  googleTranslateApiKey: getOsEnv('REACT_APP_GOOGLE_TRANSLATE_API_KEY')
};
