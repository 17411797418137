import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import LoginForm from '@screens/Authorization/components/LoginForm';
import { IBindingCallback1 } from '@models/Callbacks';
import { ILoginData } from '@screens/Authorization/models/LoginData';
import SocialAuthForm from '@screens/Authorization/components/SocialAuthForm';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import DividingLine from '@components/NewDesign/DividingLine';
import { logInRoutine } from '@screens/Authorization/routines';
import { isLoginLoading } from '@screens/Authorization/reducers';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import styles from './styles.module.scss';
import MetaTags from '@components/MetaTags';

export interface ILoginPageProps {
  logIn: IBindingCallback1<ILoginData>;
  loginLoading: boolean;
}

const LoginPage: React.FC<ILoginPageProps> = ({ logIn, loginLoading }) => {
  const loginCallback = useCallback((loginData: ILoginData) => {
    logIn(loginData);
  }, [logIn]);

  return (
    <>
      <MetaTags title="Log In" withSuffix />
      <SubHeader3 marginBottom className={styles.header}>Log In with an Existing Account</SubHeader3>
      <LoginForm onLoginClick={loginCallback} loginLoading={loginLoading} />
      <DividingLine className={styles.dividing_line} />
      <SubHeader3 marginBottom className={styles.header}>Don’t Have an Account?</SubHeader3>
      <SocialAuthForm />
      <div className={styles.register_button_wrapper}>
        <GoToButton to={ENDPOINTS.REGISTER}>OR REGISTER WITH EMAIL</GoToButton>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  loginLoading: isLoginLoading(state)
});

const mapDispatchToProps = {
  logIn: logInRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
