export interface IMessageDto {
  id: string;
  companion: ICompanionDto;
  direction: MessageDirection;
  topic: string;
  text: string;
  sentAt: Date;
  read: boolean;
}

export interface ICompanionDto {
  id: string;
  firstName: string;
  lastName: string;
}

export enum MessageDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}
