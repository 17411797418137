export enum Expertise {
    OFFICE_SPACE = 'Office Space',
    WAREHOUSE_SPACE = 'Warehouse Space',
    FLEX_SPACE = 'Flex Space',
    OTHER_LOGISTICS = 'Other Logistics'
  }
export enum Availablity {
    PART_TIME = 'Part-time',
    FULL_TIME = 'Full-time',
    WEEKENDS_ONLY = 'Weekends only'
}
