import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import GeneralPageHeader from '@components/NewDesign/GeneralPageHeader';
import ScrollToTopOnMount from '@components/ScrollToTop';
import DividingLine from '@components/NewDesign/DividingLine';

export interface IStaticPageWrapperProps {
  title: string;
  moreMaxWidth?: boolean;
  disableHeaderMaxWidth?: boolean;
}

const StaticPageWrapper: React.FC<IStaticPageWrapperProps> = (
  { children, title, moreMaxWidth, disableHeaderMaxWidth }
) => (
  <div className={classNames(
    styles.container,
    moreMaxWidth && styles.containerMoreMaxWidth,
    'content_wrapper'
  )}
  >
    <ScrollToTopOnMount />
    <GeneralPageHeader title={title} disableMaxWidth={disableHeaderMaxWidth} />
    <DividingLine />
    <div className={styles.content}>
      {children}
    </div>
  </div>
);

export default StaticPageWrapper;
