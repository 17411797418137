import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchTransactionsRoutine, setPageRoutine } from '@screens/NeedsDashboard/Transactions/routines';
import {
  extractCurrentPage,
  extractCurrentSize,
  extractFetchTransactionsLoading,
  extractTotalPages,
  extractTotalResults,
  extractTransactions
} from '@screens/NeedsDashboard/Transactions/reducers';
import { ITransaction } from '@models/domain/Transaction';
import { IBindingCallback1 } from '@models/Callbacks';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import TransactionsSection from '@screens/NeedsDashboard/Dashboard/components/TransactionsSection';
import Pagination from '@components/Pagination';
import { IPageable } from '@models/domain/PageableReducerState';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';

export interface ITransactionsProps extends IPageable {
  transactions: ITransaction[];
  fetchTransactions: IBindingCallback1<IPageRequest>;
  transactionsLoading: boolean;
  setPage: IBindingCallback1<number>;
}

const Transactions: React.FC<ITransactionsProps> = (
  { transactions, totalPages, page, fetchTransactions, setPage, pageSize, transactionsLoading }
) => {
  useEffect(() => {
    if (fetchTransactions) {
      fetchTransactions({ size: pageSize, page });
    }
  }, [fetchTransactions, page, pageSize]);

  return (
    <DashboardPageWrapper title="My Transactions">
      <DashboardSectionWrapper>
        <TransactionsSection
          items={transactions}
          hideTitle
          fetchItems={() => null}
          itemsLoading={transactionsLoading}
        />
        {!transactionsLoading && totalPages > 1 && (
          <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
        )}
      </DashboardSectionWrapper>
    </DashboardPageWrapper>
  );
};

const mapStateToProps = state => ({
  transactions: extractTransactions(state),
  totalPages: extractTotalPages(state),
  totalResults: extractTotalResults(state),
  page: extractCurrentPage(state),
  pageSize: extractCurrentSize(state),
  transactionsLoading: extractFetchTransactionsLoading(state)
});

const mapDispatchToProps = {
  fetchTransactions: fetchTransactionsRoutine,
  setPage: setPageRoutine.fulfill
};

export { Transactions };

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
