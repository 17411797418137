import { combineReducers } from 'redux';
import { reducerCreator } from '@helpers/reducer.helper';
import { dashboardReducer } from '@screens/NeedsDashboard/Dashboard/containers/DashboardPage/reducer';
import { RootState } from '@root/store';
/* PlopJS import placeholder. Do not remove */
import {
  fetchMyBuildingsRoutine,
  fetchMyTransactionsRoutine,
  toggleFavoriteBuildingRoutine,

  readMessageFromDashboardRoutine,
  sendMessageFromDashboardRoutine,
  fetchDashboardMessagesRoutine
} from '@screens/NeedsDashboard/Dashboard/routines';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  readMessageFromDashboardRequest: reducerCreator([readMessageFromDashboardRoutine.TRIGGER]),
  sendMessageFromDashboardRequest: reducerCreator([sendMessageFromDashboardRoutine.TRIGGER]),
  fetchDashboardMessagesRequest: reducerCreator([fetchDashboardMessagesRoutine.TRIGGER]),

  toggleFavoriteBuildingRequest: reducerCreator([toggleFavoriteBuildingRoutine.TRIGGER]),
  fetchMyTransactionsRequest: reducerCreator([fetchMyTransactionsRoutine.TRIGGER]),
  fetchMyBuildingsRequest: reducerCreator([fetchMyBuildingsRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  data: dashboardReducer
});

const reqs = (state: RootState) => state.needsDashboard.dashboard.requests;
const data = (state: RootState) => state.needsDashboard.dashboard.data;

export const extractMyBuildings = state => data(state).buildings;
export const extractMyTransactions = state => data(state).transactions;
export const extractMoreBuildings = state => data(state).moreBuildings;
export const extractMoreTransactions = state => data(state).moreTransactions;

export const extractMyMessages = state => data(state).messages;
export const extractMoreMessages = state => data(state).moreMessages;
export const extractWriting = state => data(state).writing;
export const extractFullMessage = state => data(state).messageFull;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractReadMessageFromDashboardLoading = state => reqs(state).readMessageFromDashboardRequest.loading;
export const extractReadMessageFromDashboardError = state => reqs(state).readMessageFromDashboardRequest.error;
export const extractSendMessageFromDashboardLoading = state => reqs(state).sendMessageFromDashboardRequest.loading;
export const extractSendMessageFromDashboardError = state => reqs(state).sendMessageFromDashboardRequest.error;
export const extractFetchDashboardMessagesLoading = state => reqs(state).fetchDashboardMessagesRequest.loading;
export const extractFetchDashboardMessagesError = state => reqs(state).fetchDashboardMessagesRequest.error;

export const extractFetchMyTransactionsLoading = state => reqs(state).fetchMyTransactionsRequest.loading;
export const extractFetchMyBuildingsLoading = state => reqs(state).fetchMyBuildingsRequest.loading;
