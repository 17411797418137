import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchAvailableAndScheduledSpaces, updateScheduleWithNewSpaces } from '@screens/HavesDashboard/DropOffAndPickUp/routines';
import { toastr } from 'react-redux-toastr';
import schedulesService from '@screens/HavesDashboard/DropOffAndPickUp/services/schedule.service';
import { IScheduleUpdateHaveRequest } from '@screens/HavesDashboard/DropOffAndPickUp/model/SpaceIdsWithScheduleId';

function* tryFetchAvailableAndScheduledSpaces({ payload }: PayloadAction<string>) {
  try {
    const resp = yield call(schedulesService.fetchAvailableAndScheduledSpaces, payload);
    yield put(fetchAvailableAndScheduledSpaces.success(resp));
  } catch (e) {
    toastr.error('Unable to load spaces', e?.message);
    yield put(fetchAvailableAndScheduledSpaces.failure(e?.message));
  }
}

function* tryUpdateScheduleWithNewSpaces({ payload }: PayloadAction<IScheduleUpdateHaveRequest>) {
  try {
    const resp = yield call(schedulesService.updateScheduleWithNewSpaces, payload);
    toastr.success('Success', 'Schedule successfully updated');
    yield put(updateScheduleWithNewSpaces.success(resp));
  } catch (e) {
    toastr.error('Unable to update schedule', e?.message);
    yield put(updateScheduleWithNewSpaces.failure(e?.message));
  }
}

export default function* schedulesSegmentSagas() {
  yield all([
    yield takeEvery(fetchAvailableAndScheduledSpaces, tryFetchAvailableAndScheduledSpaces),
    yield takeEvery(updateScheduleWithNewSpaces, tryUpdateScheduleWithNewSpaces)
  ]);
}
