import React from 'react';
import styles from './styles.module.scss';
import { Button, Modal, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react';
import { IBindingAction } from '@models/Callbacks';

interface IMemberRowBlockModalProps {
  onClose: IBindingAction;
  onBlock: IBindingAction;
  open?: boolean;
}

const MemberBlockModal: React.FC<IMemberRowBlockModalProps> = ({ onClose, open, onBlock }) => (
  <Modal open={open} onClose={onClose} closeIcon size="small">
    <ModalHeader>Block User</ModalHeader>
    <ModalContent>
      <div className={styles.text}>
        Are you sure you want to delete this user?
        <br />
        Some of the consequences of this action cannot be undone.
      </div>
    </ModalContent>
    <ModalActions>
      <Button
        content="Cancel"
        onClick={onClose}
      />
      <Button
        color="red"
        content="Block"
        onClick={onBlock}
      />
    </ModalActions>
  </Modal>
);

export default MemberBlockModal;
