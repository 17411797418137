import { dashboard, LIST_YOUR_SPACE_COMMON_ENDPOINT } from '@screens/HavesDashboard/Root/components/Routing/endpoints';

export const ADMIN_DASHBOARD_ENDPOINTS = {
  DASHBOARD: dashboard(''),
  MESSAGES: dashboard('messages'),
  MEMBERS: dashboard('members'),
  BUILDINGS_AND_SPACES: dashboard('buildings_and_spaces'),
  BUILDING_EDITOR: id => dashboard(`building/edit/${id}`),
  TRANSACTIONS: dashboard('transactions'),
  PAYOUTS: dashboard('payouts'),
  PAYOUTS_HISTORY: dashboard('payouts_history'),
  LIST_YOUR_SPACE: LIST_YOUR_SPACE_COMMON_ENDPOINT
};
