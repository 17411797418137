import { callApi } from '@helpers/api.helper';
import { IPaypalRequest } from '@screens/BookingCheckout/model/PaypalRequest';
import { IStripePaymentRequest } from '@components/StripePaymentForm';
import { PaymentIntent, StripeError } from '@stripe/stripe-js';
import { IPaymentTokenRequest } from '@screens/HavesDashboard/FinancialBanking/model/PaymentTokenRequest';
import { IBankAccountVerification } from '@screens/HavesDashboard/FinancialBanking/model/IBankAccountVerification';
import { IPaymentAccountIdRequest } from '@screens/HavesDashboard/FinancialBanking/model/PaymentAccountIdRequest';
import {IPageRequest} from "@screens/NeedsDashboard/BookedSpaces/model/PageableRequest";

interface IConfirmPaymentIntent {
  paymentIntent?: PaymentIntent;
  error?: StripeError;
}

const stripePaymentService = {
  sendStripePaymentIntent: (requestData: IStripePaymentRequest) => callApi({
    endpoint: 'stripe/payment_intent',
    method: 'POST',
    requestData: {
      paymentMethodId: requestData.paymentMethodId,
      transactionId: requestData.transactionId
    }
  }),
  confirmStripePaymentIntent: (requestData: IConfirmPaymentIntent) => callApi({
    endpoint: 'stripe/payment_intent',
    method: 'PATCH',
    requestData
  }),
  capturePaypalPayment: (requestData: IPaypalRequest) => callApi({
    endpoint: 'paypal/capture',
    method: 'POST',
    requestData
  }),
  addAccount: (requestData: IPaymentTokenRequest) => callApi({
    endpoint: 'stripe/account',
    method: 'POST',
    requestData
  }),
  verifyAccount: (requestData: IBankAccountVerification) => callApi({
    endpoint: 'stripe/account/verify',
    method: 'POST',
    requestData
  }),
  getAccount: () => callApi({
    endpoint: 'stripe/account',
    method: 'GET'
  }),
  setDefaultAccount: (requestData: IPaymentAccountIdRequest) => callApi({
    endpoint: 'stripe/account/default',
    method: 'PUT',
    requestData
  }),
  deleteAccount: (requestData: IPaymentAccountIdRequest) => callApi({
    endpoint: 'stripe/account',
    method: 'DELETE',
    requestData
  }),
  payoutsSummary: () => callApi({
    endpoint: 'stripe/payouts/summary',
    method: 'GET'
  }),
  payoutsHistory: (page: IPageRequest) => callApi({
    endpoint: 'stripe/payouts/history',
    method: 'GET',
    queryParams: {
      page: page.page - 1,
      size: page.size
    }
  }),
  doPayout: () => callApi({
    endpoint: 'stripe/payouts',
    method: 'POST'
  })
};

export default stripePaymentService;
