import React from 'react';
import { ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { SpaceType } from '@models/domain/space/SpaceTypes';
import { lowerCase, startCase } from 'lodash';
import { SpaceCategory } from '@models/domain/space/SpaceCategories';
import { toPrice } from '@helpers/price.helper';
import { LengthUnit } from '@models/domain/LengthUnit';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import styles from './styles.module.scss';

export interface ISpaceItemHeaderProps {
  space: ISpaceWithAvailability;
}

const SpaceItemHeader: React.FC<ISpaceItemHeaderProps> = ({ space }) => {
  const dimensionsPresent = !!space.lengthUnit;
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.nowrap_group}>
          <Caption1 className={styles.main_focus}>
            {space.alias}
          </Caption1>
          <Divider />
        </div>
        <div className={styles.nowrap_group}>
          <Caption1 className={styles.regular}>
            {startCase(lowerCase(SpaceType[space.spaceType]))}
          </Caption1>
          <Divider />
        </div>
        <div className={styles.nowrap_group}>
          <Caption1 className={styles.main_focus}>
            {SpaceCategory[space.spaceCategory]}
          </Caption1>
          <Divider />
        </div>
        <Caption1 className={styles.regular}>
          {`${space.amountAvailable} Space${space.amountAvailable === 1 ? '' : 's'} available`}
        </Caption1>
      </div>
      <Caption2 className={styles.description}>
        {toPrice({ amount: space.pricePerMonth, currency: space.currency })}
        {' '}
        Monthly Rate
        {dimensionsPresent && (
          <>
            &nbsp;|
            {' '}
            {`${space.length} x ${space.width} x ${space.height} ${LengthUnit[space.lengthUnit].toLowerCase()}`}
          </>
        )}
      </Caption2>
    </div>
  );
};

const Divider = () => (
  <Caption2 disableTranslation>
    |
  </Caption2>
);

export default SpaceItemHeader;
