import React from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonProps } from 'semantic-ui-react';
import { extractUserRoles } from '@screens/Authorization/reducers';
import { history } from '@helpers/history.helper';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import { IBindingAction } from '@models/Callbacks';
import styles from './styles.module.scss';

export interface ILikeButtonProps extends ButtonProps {
  liked: boolean;
  onToggleLike: IBindingAction;
}

const LikeButton: React.FC<ILikeButtonProps> = ({ liked, className, onToggleLike, ...props }) => {
  const role = useSelector(extractUserRoles)[0];

  const handleToggleLike = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    if (!role) {
      history.push(`${ENDPOINTS.LOGIN}?redirect=${history.location.pathname}`);
    } else {
      onToggleLike();
    }
  };

  return (
    <Button
      onClick={handleToggleLike}
      {...props}
      icon={liked ? 'heart' : 'heart outline'}
      className={`${liked ? styles.like_button__active : styles.like_button} ${className}`}
    />
  );
};

export default LikeButton;
