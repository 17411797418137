import moment, { Moment } from 'moment';
import { equalMomentDates, lessThanMomentDates, lessThanOrEqualMomentDates } from '@helpers/date.helper';
import { IBriefBookingDto } from '@models/domain/Booking';

export interface IBookingPaymentPeriodDays {
  start: Moment;
  end: Moment;
}

export interface IBookingPaymentPeriods {
  first?: IBookingPaymentPeriodDays;
  second?: Moment;
  third?: IBookingPaymentPeriodDays;
}

export const bookingToPeriods = (booking: IBriefBookingDto): IBookingPaymentPeriods => {
  const startMoment = moment(booking.startingDate);
  const endMoment = moment(booking.endingDate);

  const startMonthLastDay = moment(startMoment).endOf('month');
  const startNextMonthFirstDay = moment(startMonthLastDay).add(1, 'days');
  const endMonthFirstDay = moment(endMoment).startOf('month');

  const isStartFirstDayOfMonth = equalMomentDates(moment(startMoment).startOf('month'), startMoment);
  const isEndLastDayOfMonth = equalMomentDates(moment(endMoment).endOf('month'), endMoment);

  const firstPeriodLastDay = (!booking.endingDate || startMonthLastDay < endMoment)
    ? moment(startMonthLastDay)
    : moment(endMoment);
  const secondPeriodFirstDay = isStartFirstDayOfMonth
    ? startMoment
    : startNextMonthFirstDay;

  const secondPeriodPresent = !booking.endingDate
    || lessThanMomentDates(secondPeriodFirstDay, endMonthFirstDay)
    || (equalMomentDates(secondPeriodFirstDay, endMonthFirstDay) && isEndLastDayOfMonth);
  const firstPeriodPresent = !secondPeriodPresent
    || !equalMomentDates(moment(startMoment), moment(secondPeriodFirstDay));
  const thirdPeriodPresent = booking.endingDate
    && lessThanOrEqualMomentDates(startNextMonthFirstDay, endMonthFirstDay)
    && !isEndLastDayOfMonth;

  return {
    first: firstPeriodPresent && {
      start: startMoment,
      end: firstPeriodLastDay
    },
    second: secondPeriodPresent && secondPeriodFirstDay,
    third: thirdPeriodPresent && {
      start: endMonthFirstDay,
      end: endMoment
    }
  };
};
