import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import styles from './styles.module.scss';

export interface IAdvertisingBannerProps {
  hide?: boolean;
  className?: string;
}

const AdvertisingBanner: React.FC<IAdvertisingBannerProps> = props => (
  <div
    {...props}
    className={classNames(styles.container, props.hide && styles.hidden, props.className)}
  >
    <Link to={ENDPOINTS.CONTACT_US} className={styles.link}>
      <Header3 className={styles.header}>{props.children}</Header3>
    </Link>
  </div>
);

export default AdvertisingBanner;
