import React from 'react';
import { Icon, PopupContent, PopupHeader } from 'semantic-ui-react';
import classNames from 'classnames';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import styles from './styles.module.scss';
import { IPopupPosition } from '@components/FormErrorPopup';
import PopupWrapper from '@components/NewDesign/PopupWrapper';

export interface IInfoPopupProps {
  header?: string;
  content: string;
  transparent?: boolean;
  className?: string;
  iconClassName?: string;
  position?: IPopupPosition;
}

const InfoPopup: React.FC<IInfoPopupProps> = (
  {
    header, content, transparent, className,
    position, iconClassName
  }
) => (
  <PopupWrapper
    className={classNames(styles.popup_basic, className)}
    trigger={(
      <Icon
        name="info circle"
        className={classNames(
          styles.icon_basic,
          transparent && styles.icon_transparent,
          iconClassName
        )}
      />
    )}
    on={['hover', 'click']}
    position={position}
  >
    {header && (
      <PopupHeader>
        <Caption1>
          {header}
        </Caption1>
      </PopupHeader>
    )}
    <PopupContent>
      <Caption2>
        {content}
      </Caption2>
    </PopupContent>
  </PopupWrapper>
);
export default InfoPopup;
