import { callApi } from '@helpers/api.helper';
import { IServiceSaveDto } from '@screens/HavesDashboard/AdditionalServices/model/ServiceSaveDto';
import { IServicePageRequest } from '@screens/HavesDashboard/AdditionalServices/model/ServicePageRequest';

const serviceService = {
  getServicesByUserId: ({ userId, page, size }: IServicePageRequest) => callApi({
    method: 'GET',
    endpoint: `services/user/${userId}`,
    queryParams: {
      page: page - 1,
      size
    }
  }),
  getServiceById: (id: string) => callApi({
    method: 'GET',
    endpoint: `services/${id}`
  }),
  createService: (requestData: IServiceSaveDto) => callApi({
    method: 'POST',
    endpoint: 'services',
    requestData
  }),
  updateService: (id: string, requestData: IServiceSaveDto) => callApi({
    method: 'PUT',
    endpoint: `services/${id}`,
    requestData
  })
};

export default serviceService;
