import React from 'react';
import { iconOfScheduleType, ScheduleType } from '@models/domain/schedule/ScheduleType';
import LabelWrapper from '@components/NewDesign/LabelWrapper';
import styles from './styles.module.scss';

interface IScheduleTypeLabelProps {
  type: ScheduleType;
}

const scheduleTypeToStyle = (type: ScheduleType): string => styles[type.toString().toLowerCase()];

const ScheduleTypeLabel = ({ type }: IScheduleTypeLabelProps) => (
  <LabelWrapper
    transparent
    zeroHorizontalPadding
    content={ScheduleType[type]}
    icon={iconOfScheduleType(type)}
    className={scheduleTypeToStyle(type)}
  />
);

export { ScheduleTypeLabel };
