/* eslint-disable max-len */
import { createRoutine } from 'redux-saga-routines';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import { IScheduleDto } from '@screens/NeedsDashboard/Bookings/model/Schedule';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/Bookings/model/ScheduleResponse';
import { IBookingCancellationRequest } from '@screens/NeedsDashboard/Bookings/model/BookingCancellationRequest';

const createBookingsRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`NEEDS_DASHBOARD__BOOKINGS:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const setExpandedBookingRoutine = createBookingsRoutine('SET_EXPANDED_BOOKING');
export const cancelBookingRoutine = createBookingsRoutine<IBookingCancellationRequest>('CANCEL_BOOKING');
export const setEditedScheduleRoutine = createBookingsRoutine<IScheduleResponseDto>('SET_EDITED_SCHEDULE');
export const saveScheduleRoutine = createBookingsRoutine<IScheduleDto>('SAVE_SCHEDULE');
export const fetchSchedulesRoutine = createBookingsRoutine('FETCH_SCHEDULES');
export const setCurrentPageRoutine = createBookingsRoutine<number>('SET_CURRENT_PAGE');
export const fetchBookingsForNeedRoutine = createBookingsRoutine<IPageRequest>('FETCH_ACTIVE_BOOKINGS');
export const cancelScheduleRoutine = createBookingsRoutine<string>('CANCEL_SCHEDULE');
