import React from 'react';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import TransactionsBlock from '@screens/BookingCheckout/containers/BookingDetailsPage/TransactionsBlock';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import styles from './styles.module.scss';

interface ITransactionsBlockWithHeaderProps {
  bookingId: string;
  buildingId: string;
}

const TransactionsBlockWithHeader = ({ bookingId, buildingId }: ITransactionsBlockWithHeaderProps) => (
  <div className={styles.transactions_section}>
    <div className={styles.transactions_section__header}>
      <SubHeader3 disableWordBreak>Transactions</SubHeader3>
      <GoToButton
        to={`${NEEDS_DASHBOARD_ENDPOINTS.SPACES}?building=${buildingId}&booking=${bookingId}&tab=spaces#booking-section`}
        marginZero
      >
          Interested in booking more spaces here?
      </GoToButton>
    </div>
    <TransactionsBlock
      bookingId={bookingId}
      hideTableHeader
      className={styles.transactions_section__table}
    />
  </div>
);

export { TransactionsBlockWithHeader };
