import queryString from 'query-string';

export type QueryParamsState = { [key: string]: string | string[] | undefined };
export type UpdateQueryParamsFunction = (newState: QueryParamsState) => void;

const stringifyArrayParams = (params: QueryParamsState) => {
  const updatedParams: { [key: string]: string | undefined } = {};
  Object.keys(params).forEach(key => {
    const value = params[key];
    if (Array.isArray(value)) {
      updatedParams[key] = value.join(',');
    } else {
      updatedParams[key] = value;
    }
  });
  return updatedParams;
};

const parseQueryParams = (params: queryString.ParsedQuery): QueryParamsState => {
  const parsedParams: QueryParamsState = {};
  Object.keys(params).forEach(key => {
    const value = params[key];
    if (typeof value === 'string' && value.includes(',')) {
      parsedParams[key] = value.split(',');
    } else if (typeof value === 'string') {
      parsedParams[key] = [value];
    } else {
      parsedParams[key] = value;
    }
  });
  return parsedParams;
};

const serializeQueryParams = (params: QueryParamsState): string => {
  const serializedParams = Object.entries(params)
    .filter(([, value]) => value?.length)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  return `?${serializedParams}`;
};

export {
  stringifyArrayParams,
  parseQueryParams,
  serializeQueryParams
};
