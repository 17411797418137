import React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { LabelGroup } from 'semantic-ui-react';
import { IServiceOrderDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderDto';
import styles from '@screens/HavesDashboard/Orders/components/OrderDetails/styles.module.scss';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';
import OrderStatusLabel from '@components/OrderStatusLabel';
import { DATE_FORMAT } from '@helpers/date.helper';
import { priceAmountWithCurrencyToPrice } from '@helpers/price.helper';

export interface IOrderDetailsProps {
  order: IServiceOrderDto;
}

const OrderDetails: React.FC<IOrderDetailsProps> = ({ order }) => {
  const { totalPrice: total, currency, status, createdAt, scheduledOnDate, note } = order ?? {};

  return (
    <div className={styles.container}>
      <LabelGroupWrapper
        wrapper={LabelGroup}
        title="Status"
        value={(<OrderStatusLabel status={status} createdAt={createdAt} />)}
      />
      <LabelGroupWrapper
        wrapper={LabelGroup}
        title="Date of Service"
        value={moment(scheduledOnDate).format(DATE_FORMAT)}
      />
      <LabelGroupWrapper
        wrapper={LabelGroup}
        title="Total"
        value={priceAmountWithCurrencyToPrice(total, currency)}
      />
      {!isEmpty(note) && (
        <LabelGroupWrapper
          title="Notes"
          value={note}
        />
      )}
    </div>
  );
};

export { OrderDetails };
