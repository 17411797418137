import { createReducer } from '@reduxjs/toolkit';
import { setLanguageRoutine, setTranslationRoutine, translateRoutine } from '@reducers/translation.routines';

type TranslationReducerState = {
  language: string;
  translations: { [key: string]: string };
}

const LANGUAGE_KEY = 'language';
const savedLanguage = localStorage.getItem(LANGUAGE_KEY);

const initialState: TranslationReducerState = {
  language: savedLanguage ?? 'en',
  translations: {}
};

const translationReducer = createReducer(initialState, {
  [translateRoutine.SUCCESS]: (state, { payload }) => {
    const { key, translation } = payload;

    state.translations = {
      ...state.translations,
      [key]: translation
    };
  },
  [setLanguageRoutine.FULFILL]: (state, { payload }) => {
    state.language = payload;
    localStorage.setItem(LANGUAGE_KEY, payload);
  },
  [setTranslationRoutine.FULFILL]: (state, { payload }) => {
    const { key, translation } = payload;

    state.translations = {
      ...state.translations,
      [key]: translation
    };
  }
});

export { translationReducer };
