import { combineReducers } from 'redux';
/* PlopJS import placeholder. Do not remove */
import { createAdditionalServicesOrderRoutine } from '@screens/ServiceOrderCheckout/routines';
import { loadAdditionalServicesWithAvailabilityRoutine } from '@screens/ServiceOrderCheckout/routines';
import { editOrderNotesRoutine } from '@screens/ServiceOrderCheckout/routines';
import { setTransactionsPageRoutine } from '@screens/ServiceOrderCheckout/routines';
import { fetchTransactionsRoutine } from '@screens/ServiceOrderCheckout/routines';
import { reducerCreator } from '@helpers/reducer.helper';
import { RootState } from '@root/store';
import { serviceOrderRootPageReducer } from '@screens/ServiceOrderCheckout/containers/ServiceOrderRootPage/reducer';
import {
  fetchOrderPaymentRequirementsRoutine,
  loadServiceOrderDetailsRoutine,
  toggleFavoriteRoutine
} from '@screens/ServiceOrderCheckout/routines';
import { orderSummaryAndSubmissionReducer } from '@screens/ServiceOrderCheckout/containers/ServiceOrderCheckoutPage/SubmissionAndSummaryStep/reducer';
import { orderDetailsPageReducer } from '@screens/ServiceOrderCheckout/containers/ServiceOrderDetailsPage/TransactionsBlock/reducer';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  createAdditionalServicesOrderRequest: reducerCreator([createAdditionalServicesOrderRoutine.TRIGGER]),
  loadAdditionalServicesWithAvailabilityRequest: reducerCreator([loadAdditionalServicesWithAvailabilityRoutine.TRIGGER]),
  editOrderNotesRequest: reducerCreator([editOrderNotesRoutine.TRIGGER]),
  setTransactionsPageRequest: reducerCreator([setTransactionsPageRoutine.TRIGGER]),
  fetchTransactionsRequest: reducerCreator([fetchTransactionsRoutine.TRIGGER]),
  loadServiceOrderDetailsRequest: reducerCreator([loadServiceOrderDetailsRoutine.TRIGGER]),
  toggleFavoriteRequest: reducerCreator([toggleFavoriteRoutine.TRIGGER]),
  fetchOrderPaymentRequirementsRequest: reducerCreator([fetchOrderPaymentRequirementsRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  serviceOrderRoot: serviceOrderRootPageReducer,
  serviceOrderSummary: orderSummaryAndSubmissionReducer,
  serviceOrderDetails: orderDetailsPageReducer
});

const reqs = (state: RootState) => state.serviceOrderCheckout.requests;
const summary = (state: RootState) => state.serviceOrderCheckout.serviceOrderSummary;
const serviceOrderRoot = (state: RootState) => state.serviceOrderCheckout.serviceOrderRoot;
const serviceOrderDetails = (state: RootState) => state.serviceOrderCheckout.serviceOrderDetails;

export const extractPaymentRequirements = state => summary(state).requirements;
export const extractServiceOrderDetails = state => serviceOrderRoot(state).serviceOrderDetails;
export const extractBookingData = state => serviceOrderRoot(state).serviceOrderDetails.booking;
export const extractBookingId = state => serviceOrderRoot(state).serviceOrderDetails.booking.id;
export const extractBuildingData = state => serviceOrderRoot(state).serviceOrderDetails.building;
export const extractOrderWithServices = state => serviceOrderRoot(state).serviceOrderDetails.serviceOrder;
export const extractAdditionalServicesWithAvailability = state => serviceOrderRoot(state).additionalServices;

export const extractOrderTransactions = state => serviceOrderDetails(state).transactions.items;
export const extractCurrentTransactionPage = state => serviceOrderDetails(state).transactions.page;
export const extractTotalTransactionPages = state => serviceOrderDetails(state).transactions.totalPages;
export const extractTransactionPageSize = state => serviceOrderDetails(state).transactions.pageSize;
export const extractToggleFavoriteLoading = state => reqs(state).toggleFavoriteRequest.loading;
export const extractToggleFavoriteError = state => reqs(state).toggleFavoriteRequest.error;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractCreateAdditionalServicesOrderLoading = state => reqs(state).createAdditionalServicesOrderRequest.loading;
export const extractCreateAdditionalServicesOrderError = state => reqs(state).createAdditionalServicesOrderRequest.error;
export const extractLoadAdditionalServicesWithAvailabilityLoading = state => reqs(state).loadAdditionalServicesWithAvailabilityRequest.loading;
export const extractLoadAdditionalServicesWithAvailabilityError = state => reqs(state).loadAdditionalServicesWithAvailabilityRequest.error;
export const extractEditOrderNotesLoading = state => reqs(state).editOrderNotesRequest.loading;
export const extractEditOrderNotesError = state => reqs(state).editOrderNotesRequest.error;
export const extractSetTransactionsPageLoading = state => reqs(state).setTransactionsPageRequest.loading;
export const extractSetTransactionsPageError = state => reqs(state).setTransactionsPageRequest.error;
export const extractFetchTransactionsLoading = state => reqs(state).fetchTransactionsRequest.loading;
export const extractFetchTransactionsError = state => reqs(state).fetchTransactionsRequest.error;
export const extractLoadServiceOrderDetailsLoading = state => reqs(state).loadServiceOrderDetailsRequest.loading;
export const extractLoadServiceOrderDetailsError = state => reqs(state).loadServiceOrderDetailsRequest.error;

export const extractFetchPaymentRequirementsLoading = state => reqs(state).fetchOrderPaymentRequirementsRequest.loading;
export const extractFetchPaymentRequirementsError = state => reqs(state).fetchOrderPaymentRequirementsRequest.error;
