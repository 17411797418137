import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

const NavLinkButton: React.FC<NavLinkProps> = props => {
  const { className, children, activeClassName, to } = props;

  const { t } = useTranslation();

  return to
    ? (
      <NavLink
        {...props}
        className={classNames(styles.container, className)}
        activeClassName={classNames(styles.active, activeClassName)}
      >
        {t(children)}
      </NavLink>
    ) : (
      <div {...props as any} className={classNames(styles.container, className)}>
        {t(children)}
      </div>
    );
};

export default NavLinkButton;
