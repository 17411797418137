import { createRoutine } from 'redux-saga-routines';

const createBuildingDetailsRoutine = actionName => createRoutine(`NEEDS_DASHBOARD__BUILDING_DETAILS:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const setWritingMessageFromSpacesToOwnerRoutine = createBuildingDetailsRoutine('SET_WRITING_MESSAGE_FROM_SPACES_TO_OWNER');
export const sendMessageFromSpacesToOwnerRoutine = createBuildingDetailsRoutine('SEND_MESSAGE_FROM_SPACES_TO_OWNER');
export const toggleFavoriteBuildingRoutine = createBuildingDetailsRoutine('TOGGLE_FAVORITE_BUILDING');
export const requestBookingRoutine = createBuildingDetailsRoutine('REQUEST_BOOKING');
export const fetchSpaceAvailabilityRoutine = createBuildingDetailsRoutine('FETCH_SPACE_AVAILABILITY');
export const fetchBuildingDetailsRoutine = createBuildingDetailsRoutine('FETCH_BUILDING_DETAILS');
export const resetBuildingDetailsRoutine = createBuildingDetailsRoutine('RESET_BUILDING_DETAILS');

export const setBookingsTablePageRoutine = createBuildingDetailsRoutine('SET_BOOKINGS_TABLE_PAGE');
export const fetchBuildingBookingsForNeedRoutine = createBuildingDetailsRoutine('FETCH_BUILDING_BOOKINGS_FOR_NEED');
export const resetSpacesAndServicesSectionRoutine = createBuildingDetailsRoutine('RESET_SPACES_AND_SERVICES_SECTION');
export const orderAdditionalServicesRoutine = createBuildingDetailsRoutine('ORDER_ADDITIONAL_SERVICES');
export const fetchAdditionalServicesRoutine = createBuildingDetailsRoutine('FETCH_ADDITIONAL_SERVICES');
