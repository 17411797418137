import { callApi } from '@helpers/api.helper';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import { IBuildingForSave, IBuildingForSaveWithHaveId } from '@screens/BuildingEditor/model/BuildingForSave';

const buildingsService = {
  fetchOwnedBuildings: ({ page, size }: IPageRequest) => callApi({
    endpoint: 'buildings/owned',
    method: 'GET',
    queryParams: {
      page: page - 1,
      size
    }
  }),
  fetchBuildingTitles: () => callApi({
    endpoint: 'buildings/titles',
    method: 'GET'
  }),
  fetchBookedBuildings: ({ size, page }: IPageRequest) => callApi({
    method: 'GET',
    endpoint: 'buildings/booked',
    queryParams: {
      page: page - 1,
      size
    }
  }),
  createBuilding: (requestData: IBuildingForSave) => callApi({
    method: 'PUT',
    requestData,
    endpoint: 'buildings/create'
  }),
  createBuildingForHave: ({ haveId, ...requestData }: IBuildingForSaveWithHaveId) => callApi({
    method: 'PUT',
    requestData,
    endpoint: `buildings/create-for-have/${haveId}`
  }),
  fetchAdminBuildingsSummary: ({ page, size }: IPageRequest) => callApi({
    endpoint: 'buildings/admin/summary',
    method: 'GET',
    queryParams: {
      page: page - 1,
      size
    }
  })
};

export default buildingsService;
