import { connect } from 'react-redux';
import {
  extractCurrentPage,
  extractPageSize,
  extractTotalPages,
  extractFetchOrdersLoading,
  extractOrders
} from '@screens/HavesDashboard/Orders/reducers';
import { fetchOrdersRoutine, setCurrentPageRoutine } from '@screens/HavesDashboard/Orders/routines';
import { OrdersTable } from '@screens/NeedsDashboard/Orders/containers/OrdersTable';

const mapStateToProps = state => ({
  totalPages: extractTotalPages(state),
  page: extractCurrentPage(state),
  pageSize: extractPageSize(state),
  orders: extractOrders(state),
  ordersLoading: extractFetchOrdersLoading(state)
});

const mapDispatchToProps = {
  loadOrders: fetchOrdersRoutine,
  setPage: setCurrentPageRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTable);
