import React, { FC, useCallback, useState } from 'react';
import { DateUtils } from 'react-day-picker';
import { Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import DangerButton from '@components/NewDesign/Button/DangerButton';
import DateSelectorWrapper from '@components/NewDesign/DateSelectorWrapper';
import styles from './styles.module.scss';

export interface ICancelBookingModalProps {
  open?: boolean;
  onClose?: IBindingAction;
  firstDayNextMonth: Date;
  lastBookingDay: Date;
  onSubmitDate: IBindingCallback1<Date>;
}

const CancelBookingModal: FC<ICancelBookingModalProps> = (
  { open, onClose, firstDayNextMonth, lastBookingDay, onSubmitDate }
) => {
  const [day, setDay] = useState<Date | undefined>();

  const handleSubmitDate = useCallback(() => {
    onClose();
    onSubmitDate(day);
  }, [day, onClose, onSubmitDate]);

  return (
    <Modal open={open} onClose={onClose} size="tiny" closeIcon>
      <ModalHeaderWrapper>Edit Booking</ModalHeaderWrapper>
      <ModalContent className={styles.container}>
        <div className={styles.note}>
          <BodyText2>
            Choose the day to cancel.
            <br />
            All drop-offs and pick-ups starting from the selected date will be deleted
          </BodyText2>
        </div>
        <div className={styles.date_selector}>
          <DateSelectorWrapper
            initialMonth={firstDayNextMonth}
            disabledDays={
              date => (DateUtils.isDayBefore(date, firstDayNextMonth))
                || (lastBookingDay && DateUtils.isDayAfter(date, lastBookingDay))
            }
            value={{ startingDate: day }}
            onChange={({ startingDate }) => setDay(startingDate)}
            numberOfMonths={1}
            singleDate
          />
        </div>
      </ModalContent>
      <ModalActions>
        <div className={styles.buttons}>
          <div>
            <DangerButton
              content="Cancel"
              onClick={onClose}
            />
          </div>
          <div>
            <PrimaryButton
              content="Submit"
              disabled={!day}
              onClick={handleSubmitDate}
            />
          </div>
        </div>
      </ModalActions>
    </Modal>
  );
};

export default CancelBookingModal;
