/* eslint-disable max-len */
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchAvailableAndScheduledSpaces
} from '@screens/HavesDashboard/DropOffAndPickUp/routines';
import { IScheduleSpacesDto } from '../../model/ScheduleSpacesDto';

export interface IDropOffAndPickUpReducerState {
  availableAndScheduleSpaces: IScheduleSpacesDto;
}

const initialState: IDropOffAndPickUpReducerState = {
  availableAndScheduleSpaces: {
    spaces: []
  }
};

export const scheduleSegmentReducer = createReducer(initialState, {
  [fetchAvailableAndScheduledSpaces.SUCCESS]: (state, { payload }: PayloadAction<IScheduleSpacesDto>) => {
    state.availableAndScheduleSpaces = payload;
  }
});
