import React from 'react';
import classNames from 'classnames';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import styles from './styles.module.scss';

export interface ILabelGroupWrapperProps<WrapperProps extends IWrapperProps> {
  wrapper?: React.FC<WrapperProps> | undefined;
  itemsWrapperProps?: WrapperProps;
  title: string;
  importantTitle?: boolean;
  value: string | JSX.Element;
  disableValueTranslation?: boolean;
  className?: string;
}

export interface IWrapperProps {
  className: string;
}

export function LabelGroupWrapper<WrapperProps extends IWrapperProps>(
  {
    wrapper: Wrapper, itemsWrapperProps, title, value, importantTitle, disableValueTranslation, className
  }: ILabelGroupWrapperProps<WrapperProps>
) {
  const titleElement = importantTitle ? (
    <TableImportantContent disableWordBreak>
      {title}
      :
    </TableImportantContent>
  ) : (
    <Caption1 disableWordBreak>
      {title}
      :
    </Caption1>
  );

  const isNumberOrString = typeof value === 'number' || typeof value === 'string';
  const valueElement = isNumberOrString ? (
    <Caption2
      disableTranslation={disableValueTranslation}
      className={styles.value}
    >
      {value}
    </Caption2>
  ) : value;

  if (Wrapper !== undefined) {
    return (
      <Wrapper {...itemsWrapperProps} className={styles.text_group}>
        {titleElement}
        {valueElement}
      </Wrapper>
    );
  }
  return (
    <div className={classNames(styles.text_group, className)}>
      {titleElement}
      {valueElement}
    </div>
  );
}

export default LabelGroupWrapper;
