import React, { useCallback, useState } from 'react';
import { Label } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import BookingsTable from '@screens/HavesDashboard/DropOffAndPickUp/containers/BookingsTable';
import ConnectedBookingDetailsModal
  from '@screens/HavesDashboard/DropOffAndPickUp/containers/ConnectedBookingDetailsModal';
import { IBookingDetailsForSchedulingDto } from '@screens/NeedsDashboard/Bookings/model/BookingDetailsDto';
import { IBookingDetailsForSchedulingWithPerson } from '@screens/HavesDashboard/DropOffAndPickUp/model/BookingDetailsForSchedulingWithPerson';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import { fetchActiveBookingsRoutine } from '@screens/HavesDashboard/DropOffAndPickUp/routines';
import { getBookedSpacesDetails, getBookedSpacesQty } from '@helpers/bookings.helper';
import styles from './styles.module.scss';

export const DropOffAndPickUp: React.FC = () => {
  const dispatch = useDispatch();
  const [expandedBooking, setExpandedBooking] = useState<IBookingDetailsForSchedulingDto>();

  const handleLoadBookings = useCallback(params => {
    dispatch(fetchActiveBookingsRoutine.trigger(params));
  }, [dispatch]);

  return (
    <DashboardPageWrapper title="Drop-Off/Pick-Up Scheduling">
      <DashboardSectionWrapper>
        <BookingsTable
          onLoadBookings={handleLoadBookings}
          tableProps={(bookings: IBookingDetailsForSchedulingWithPerson[]) => ({
            titles: ['', 'Building Name', 'Space Details', 'Qty', 'Person'],
            rows: bookings.map(b => ({
              id: b.booking.id,
              cells: [
                {
                  content: b.needsReview && <Label circular size="mini" color="orange" empty />,
                  props: { className: styles.narrow_cell }
                },
                { content: (
                  <TableImportantContent disableTranslation>{b.building.buildingName}</TableImportantContent>
                ) },
                { content: getBookedSpacesDetails(b.templatesToAmounts) },
                { content: getBookedSpacesQty(b.templatesToAmounts) },
                { content: `${b.booking.personBooked.firstName} ${b.booking.personBooked.lastName}` }
              ],
              rowProps: {
                onClick: () => setExpandedBooking(b),
                warning: b.needsReview
              }
            }))
          })}
        />
        <ConnectedBookingDetailsModal expandedBooking={expandedBooking} setExpandedBooking={setExpandedBooking} />
      </DashboardSectionWrapper>
    </DashboardPageWrapper>
  );
};

export default DropOffAndPickUp;
