import React, { useCallback, useMemo, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import moment from 'moment';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import {
  areTwoQueriesEqual,
  IDatesData,
  IProximityData,
  IQueryData,
  IQueryDimensionsData,
  IQuerySquareData
} from '@screens/BrowseSpaces/model/QueryData';
import ProximitySelector from '@screens/Landing/components/search/selectors/ProximitySelector';
import DateSelector from '@screens/Landing/components/search/selectors/DateSelector';
import SearchSelectorMenuItem from '@screens/Landing/components/search/SearchSelectorMenuItem';
import { SpaceType } from '@models/domain/SpaceType';
import { VirtualAddressType } from '@models/domain/space/VirtualAddressType';
import VirtualAddressCategorySelector from '@screens/Landing/components/search/selectors/VirtualAddressTypeSelector';
import { VirtualAddressSpaceCategory } from '@models/domain/space/VirtualAddressSpaceCategory';
import ProximityRangeSelector from '@screens/Landing/components/search/selectors/ProximityRangeSelector';
import SpaceAmountSelector from '@screens/Landing/components/search/selectors/SpaceAmountSelector';
import SquareSelector from '@screens/Landing/components/search/selectors/SquareSelector';
import DimensionsSelector from '@screens/Landing/components/search/selectors/DimensionsSelector';
import { spaceCategoryKey } from '@models/domain/space/SpaceCategories';
import { LengthUnit } from '@models/domain/LengthUnit';
import styles from './styles.module.scss';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import SimpleInputWrapper from '@components/NewDesign/SimpleInput';
import { useTranslation } from '@root/hooks/use-translation';

export interface IBrowseSpacesSearchBarProps {
  searchLoading?: boolean;
  onClearFiltersClick?: any;
  onClearSearchClick?: any;
}

export interface ISearchBarBaseProps extends IBrowseSpacesSearchBarProps {
  landing?: boolean;
  hide?: boolean;
  className?: string;
  previousQuery?: IQueryData;
  handleSearchClick: any;
}

const SearchBarBase: React.FC<ISearchBarBaseProps> = (
  { landing, hide, className, handleSearchClick, previousQuery,
    searchLoading, onClearFiltersClick
  }
) => {
  const { t } = useTranslation();
  const [activeSelector, setActiveSelector] = useState(-1);

  const [lastQuery, setLastQuery] = useState(previousQuery);
  const [query, setQuery] = useState(previousQuery);

  const queriesAreEqual = useMemo(
    () => areTwoQueriesEqual(lastQuery, query),
    [lastQuery, query]
  );

  const performSearch = (queryToSearch: IQueryData) => {
    setLastQuery(queryToSearch);
    handleSearchClick(queryToSearch);
  };

  const appendToQuery = (queryValues: IQueryData) => {
    setQuery(prev => ({ ...prev, ...queryValues }));
  };

  const handleChangeProximity = (value: IProximityData) => {
    appendToQuery({ proximity: value });
  };

  const handleChangeDate = (value: IDatesData) => {
    const dates = value?.startingDate || value?.endingDate
      ? value
      : undefined;
    appendToQuery({ dates });
  };

  const handleChangeDimensions = (value: IQueryDimensionsData) => {
    appendToQuery({ dimensions: value });
  };

  const handleChangeAmount = (value: number) => {
    appendToQuery({ amount: value });
  };

  const handleChangeSquare = (value: IQuerySquareData) => {
    appendToQuery({ square: value });
  };

  const handleChangeVirtualAddressCategory = (value: VirtualAddressType) => {
    const spaceCategory = spaceCategoryKey(VirtualAddressSpaceCategory[value]);
    setQuery(prev => ({ ...prev, spaceCategory }));
  };

  const handleClearFiltersClick = () => {
    const defaultQuery: IQueryData = { pendingText: undefined, spaceType: null };
    setQuery(defaultQuery);
    setLastQuery(defaultQuery);
    onClearFiltersClick();
  };

  const handleClearSearchClick = () => {
    appendToQuery({ pendingText: '' });
  };

  const handleChangeSpaceType = (selectedSpaceType: SpaceType) => {
    if (selectedSpaceType !== query.spaceType) {
      setQuery(prev => ({
        ...prev,
        dimensions: null,
        square: null,
        spaceCategory: null,
        amount: null,
        spaceType: selectedSpaceType
      }));
    }
  };

  const handleEnterKeyPress = e => {
    if (e.key === 'Enter') {
      performSearch(query);
    }
  };

  const handleChangeSearchInput = (event, { value }) => {
    setQuery(prev => ({ ...prev, pendingText: value }));
  };

  const handleSelectorClick = index => {
    setActiveSelector(activeSelector === index ? -1 : index);
  };

  const dateText = useCallback(() => {
    if (isNil(query.dates?.startingDate)) {
      return t('Pick a date');
    }
    if (isNil(query.dates.endingDate)) {
      return t(`Starting ${moment(query.dates.startingDate).format('L')}`);
    }
    return `${moment(query.dates.startingDate).format('L')} - ${
      moment(query.dates.endingDate).format('L')}`;
  }, [query.dates, t]);

  const squareSelectorText = !isEmpty(query.square) && !isNil(query.square.square) ? (
    <>
      {`${query.square.square} ${t(LengthUnit[query.square.unit])}`}
      <sup>2</sup>
    </>
  ) : t('Size');

  const spaceAmountText = {
    WAREHOUSE: query.amount || t('# of Pallets'),
    OFFICE: squareSelectorText
  };

  const getActiveStyleIfTypeSelected = (spaceType: SpaceType) => (query.spaceType === spaceType ? styles.active : '');

  const amountSelectorWithIndex = (index: number) => (
    <SearchSelectorMenuItem
      index={index}
      ownStyle
      activeIndex={activeSelector}
      handleClick={handleSelectorClick}
      className={classNames(styles.item, styles.left_border)}
      trigger={(
        <div className={classNames(styles.vertical, styles.same_width)}>
          <span className={styles.item_title}>{t('Amount')}</span>
          <span>{query.amount || '#'}</span>
        </div>
      )}
      content={(
        <SpaceAmountSelector
          onChange={handleChangeAmount}
          value={query.amount}
        />
      )}
    />
  );

  const squareSelectorWithIndex = (index: number) => (
    <SearchSelectorMenuItem
      index={index}
      ownStyle
      activeIndex={activeSelector}
      handleClick={handleSelectorClick}
      className={classNames(styles.item, styles.left_border)}
      trigger={(
        <div className={classNames(styles.vertical, styles.same_width)}>
          <span className={styles.item_title}>{t('Square')}</span>
          <span>{squareSelectorText}</span>
        </div>
      )}
      content={(
        <SquareSelector
          onChange={handleChangeSquare}
          value={query.square}
        />
      )}
    />
  );

  const dimension = {
    length: query.dimensions?.length ?? '_',
    width: query.dimensions?.width ?? '_',
    height: query.dimensions?.height ?? '_'
  };

  const dimensionsText = () => {
    const dims = query.dimensions;
    if (!isNil(dims?.length) || !isNil(dims?.width) || !isNil(dims?.height)) {
      return `${t('LxWxH')}: ${dimension.length}*${dimension.width}*${dimension.height} ${t(LengthUnit[dims.unit])}`;
    }
    return t('Size');
  };

  const dimensionsSelectorWithIndex = (index: number) => (
    <SearchSelectorMenuItem
      index={index}
      ownStyle
      activeIndex={activeSelector}
      handleClick={handleSelectorClick}
      className={classNames(styles.item, styles.left_border)}
      trigger={(
        <div className={classNames(styles.vertical, styles.same_width)}>
          <span className={styles.item_title}>{t('Dimensions')}</span>
          <span>{dimensionsText()}</span>
        </div>
      )}
      content={(
        <DimensionsSelector
          value={query.dimensions}
          onChange={handleChangeDimensions}
        />
      )}
    />
  );

  const virtualAddressCategorySelector = (
    <SearchSelectorMenuItem
      index={4}
      ownStyle
      activeIndex={activeSelector}
      handleClick={handleSelectorClick}
      className={classNames(styles.item, styles.left_border)}
      trigger={(
        <div className={classNames(styles.vertical, styles.same_width)}>
          <span className={styles.item_title}>{t('What type?')}</span>
          <span>
            { t(isNil(query.spaceCategory)
              ? 'Select a type'
              : VirtualAddressSpaceCategory[query.spaceCategory]) }
          </span>
        </div>
      )}
      content={(
        <VirtualAddressCategorySelector
          onChange={handleChangeVirtualAddressCategory}
          value={VirtualAddressType[query.spaceCategory] || null}
        />
      )}
    />
  );

  const selectorGroups = {
    WAREHOUSE: (
      <>
        { dimensionsSelectorWithIndex(4) }
        { amountSelectorWithIndex(5) }
      </>
    ),
    OFFICE: (
      <>
        { squareSelectorWithIndex(4) }
        { amountSelectorWithIndex(5) }
      </>
    ),
    VIRTUAL_ADDRESS: (
      <>
        { virtualAddressCategorySelector }
        { amountSelectorWithIndex(5) }
      </>
    )

  };

  const basicItems = (
    <>
      <SearchSelectorMenuItem
        index={1}
        ownStyle
        activeIndex={activeSelector}
        handleClick={handleSelectorClick}
        className={classNames(styles.item)}
        trigger={(
          <>
            <div className={`${styles.vertical}`}>
              <span className={styles.item_title}>{t('Proximity to')}</span>
              <span>
                {t(query.proximity
                  ? query.proximity.location.title
                  : 'Zip code')}
              </span>
            </div>
          </>
      )}
        content={(
          <ProximitySelector
            value={query.proximity}
            onChange={handleChangeProximity}
          />
      )}
      />
      {query.proximity?.location && (
        <SearchSelectorMenuItem
          index={2}
          ownStyle
          activeIndex={activeSelector}
          handleClick={handleSelectorClick}
          className={classNames(styles.item, styles.left_border)}
          trigger={(
            <>
              <div className={styles.vertical}>
                <span className={styles.item_title}>{t('Range')}</span>
                <span>{t(query.proximity ? `${query.proximity.range} miles` : 'Miles')}</span>
              </div>
            </>
          )}
          content={(
            <ProximityRangeSelector
              value={query.proximity}
              onChange={handleChangeProximity}
            />
          )}
        />
      )}
      <SearchSelectorMenuItem
        mobileFixed
        index={3}
        ownStyle
        activeIndex={activeSelector}
        handleClick={handleSelectorClick}
        className={classNames(styles.item, styles.left_border)}
        trigger={(
          <div className={`${styles.vertical} ${styles.date_picker_selector}`}>
            <span className={styles.item_title}>{t('Available Date')}</span>
            <span>{dateText()}</span>
          </div>
    )}
        content={(
          <DateSelector
            onChange={handleChangeDate}
            value={query.dates}
          />
    )}
      />
    </>
  );

  const landingPageSelectors = (
    <LandingPageSelectors
      query={query}
      activeSelector={activeSelector}
      handleChangeAmount={handleChangeAmount}
      handleChangeSquare={handleChangeSquare}
      handleSelectorClick={handleSelectorClick}
      virtualAddressCategorySelector={virtualAddressCategorySelector}
      spaceAmountText={spaceAmountText}
      t={t}
      performSearch={performSearch}
    />
  );

  const browseSpacesPageSelectors = (
    <>
      { !isNil(query.spaceType) ? selectorGroups[query.spaceType] : '' }
      <div className={classNames(styles.item, styles.right_group)}>
        <div className={styles.search_group}>
          <SimpleInputWrapper
            className={classNames(styles.search, styles.vertical)}
            placeholder="Search..."
            transparent
            value={query.pendingText || ''}
            onKeyPress={handleEnterKeyPress}
            onChange={handleChangeSearchInput}
            icon={<Icon name="delete" link onClick={handleClearSearchClick} />}
          />
        </div>
        <Button
          basic
          className={classNames(styles.clear_button)}
          onClick={handleClearFiltersClick}
        >
          {t('Clear filters')}
        </Button>
        <div className={classNames(styles.apply_filters)}>
          <PrimaryButton
            onClick={() => performSearch(query)}
            disabled={queriesAreEqual}
            loading={searchLoading}
          >
            GO
          </PrimaryButton>
        </div>
      </div>
    </>
  );

  return (
    <div className={classNames(
      styles.searchBar,
      landing ? styles.landing_searchbar : styles.browseSpaces_searchbar,
      className,
      hide ? styles.show_search_bar : ''
    )}
    >
      <div className={styles.typesMenu}>
        {landing ? null : (
          <div
            className={classNames(styles.item, getActiveStyleIfTypeSelected(null))}
            onClick={() => handleChangeSpaceType(null)}
          >
            {t('All')}
          </div>
        )}
        <div
          className={classNames(styles.item, getActiveStyleIfTypeSelected(SpaceType.WAREHOUSE))}
          onClick={() => handleChangeSpaceType(SpaceType.WAREHOUSE)}
        >
          {t('Warehouse')}
        </div>
        <div
          className={classNames(styles.item, getActiveStyleIfTypeSelected(SpaceType.OFFICE))}
          onClick={() => handleChangeSpaceType(SpaceType.OFFICE)}
        >
          {t('Office')}
        </div>
        <div
          className={classNames(styles.item, getActiveStyleIfTypeSelected(SpaceType.VIRTUAL_ADDRESS))}
          onClick={() => handleChangeSpaceType(SpaceType.VIRTUAL_ADDRESS)}
        >
          {t('Virtual Address')}
        </div>
      </div>
      <div className={styles.menu}>
        { basicItems }
        { landing ? landingPageSelectors : browseSpacesPageSelectors }
      </div>
    </div>
  );
};

const LandingPageSelectors = ({
  query,
  virtualAddressCategorySelector,
  activeSelector,
  handleSelectorClick,
  t,
  spaceAmountText,
  handleChangeAmount,
  handleChangeSquare,
  performSearch
}) => {
  const GoButton = (
    <div className={classNames(styles.item, styles.search_button)}>
      <PrimaryButton
        onClick={() => performSearch(query)}
        className={styles.innerButton}
      >
      GO
      </PrimaryButton>
    </div>
  );

  return (
    <>
      { query.spaceType === SpaceType.VIRTUAL_ADDRESS ? (
        virtualAddressCategorySelector
      ) : (
        <SearchSelectorMenuItem
          index={4}
          ownStyle
          activeIndex={activeSelector}
          handleClick={handleSelectorClick}
          className={classNames(styles.item, styles.left_border)}
          trigger={(
            <div className={classNames(styles.vertical, styles.same_width)}>
              <span className={styles.item_title}>{t('How Much Space?')}</span>
              <span>{spaceAmountText[query.spaceType]}</span>
            </div>
          )}
          content={
            query.spaceType === SpaceType.WAREHOUSE ? (
              <SpaceAmountSelector
                onChange={handleChangeAmount}
                value={query.amount}
              />
            ) : (
              <SquareSelector
                onChange={handleChangeSquare}
                value={query.square}
              />
            )
          }
        />
      )}
      { GoButton }
    </>
  );
};

export default SearchBarBase;
