import { Language } from '@common/enum/language';

const languageToAwsCodeMap = {
  [Language.EN]: 'en',
  [Language.ES]: 'es',
  [Language.PT]: 'pt',
  [Language.CN]: 'zh_CN'
};

const languageToAwsCode = (code: Language): string => languageToAwsCodeMap[code];

export { languageToAwsCode, languageToAwsCodeMap };
