import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { PayloadAction } from '@reduxjs/toolkit';
import { IBookingSearchParams } from '@screens/NeedsDashboard/Bookings/model/BookingSearchParams';
import bookingsService from '@screens/NeedsDashboard/Bookings/service/bookings.service';
import {
  fetchAdditionalServicesRoutine,
  fetchBuildingBookingsForNeedRoutine,
  orderAdditionalServicesRoutine
} from '@screens/NeedsDashboard/BuildingDetails/routines';
import {
  tryCreateAdditionalServicesOrder,
  tryLoadAdditionalServicesWithAvailability
} from '@screens/BookingCheckout/containers/BookingRootPage/sagas';

function* tryFetchBookingsForNeed({ payload }: PayloadAction<IBookingSearchParams>) {
  try {
    const resp = yield call(bookingsService.fetchBookingsForNeed, payload);
    yield put(fetchBuildingBookingsForNeedRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load building bookings', e?.message);
    yield put(fetchBuildingBookingsForNeedRoutine.failure(e?.message));
  }
}

export function* spacesAndServicesSectionSagas() {
  yield all([
    takeLatest(fetchBuildingBookingsForNeedRoutine.TRIGGER, tryFetchBookingsForNeed),
    takeLatest(
      orderAdditionalServicesRoutine.TRIGGER,
      tryCreateAdditionalServicesOrder(orderAdditionalServicesRoutine)
    ),
    takeLatest(
      fetchAdditionalServicesRoutine.TRIGGER,
      tryLoadAdditionalServicesWithAvailability(fetchAdditionalServicesRoutine)
    )
  ]);
}
