import { all, call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { PayloadAction } from '@reduxjs/toolkit';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import { fetchOrdersRoutine } from '@screens/NeedsDashboard/Orders/routines';
import ordersService from '@screens/NeedsDashboard/Orders/service/orders.service';

function* tryFetchOrders({ payload }: PayloadAction<IPageRequest>) {
  try {
    const resp = yield call(ordersService.fetchOrders, payload);
    yield put(fetchOrdersRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load orders', e?.message);
    yield put(fetchOrdersRoutine.failure(e?.message));
  }
}

export default function* ordersTableSagas() {
  yield all([
    yield takeEvery(fetchOrdersRoutine.TRIGGER, tryFetchOrders)
  ]);
}
