import React, { useCallback, useState } from 'react';
import styles from './styles.module.scss';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import {
  notNullValidation,
  passwordValidation
} from '@screens/Authorization/components/validation';
import { IPasswordUpdateDto } from '@screens/NeedsDashboard/Account/model/PasswordUpdateDto';
import { IBindingCallback1 } from '@models/Callbacks';
import { Form, Icon } from 'semantic-ui-react';
import FormikInput from '@components/formik/Input';
import Caption4 from '@components/NewDesign/Typography/Caption/Caption4';
import {
  CONFIRM_PASSWORD_REQUIREMENTS,
  PASSWORD_REQUIREMENTS
} from '@screens/Authorization/components/RegistrationForm';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';

export interface IPasswordUpdateFormProps {
  saveChanges: IBindingCallback1<IPasswordUpdateDto>;
  saveLoading?: boolean;
}

export interface IFormValues extends IPasswordUpdateDto {
  confirmPassword: string;
}

const validationSchema = Yup.object().shape({
  oldPassword: notNullValidation,
  newPassword: passwordValidation,
  confirmPassword: Yup.string()
    .required('Field is required')
    .oneOf([Yup.ref('newPassword')])
});

const PasswordUpdateForm: React.FC<IPasswordUpdateFormProps & FormikProps<IFormValues>> = (
  { handleSubmit, saveLoading, isValid }
) => {
  const [oldPasswordShown, setOldPasswordShown] = useState<boolean>(false);
  const [newPasswordShown, setNewPasswordShown] = useState<boolean>(false);
  const [repeatPasswordShown, setRepeatPasswordShown] = useState<boolean>(false);

  const toggleOldPasswordShown = useCallback(() => {
    setOldPasswordShown(v => !v);
  }, []);
  const toggleNewPasswordShown = useCallback(() => {
    setNewPasswordShown(v => !v);
  }, []);
  const toggleRepeatPasswordShown = useCallback(() => {
    setRepeatPasswordShown(v => !v);
  }, []);

  return (
    <Form name="passwordForm" onSubmit={handleSubmit}>
      <div className={styles.passwordWrapper}>
        <FormikInput
          propsOrFieldName={{
            type: oldPasswordShown ? 'text' : 'password',
            name: 'oldPassword',
            placeholder: 'Enter Old Password',
            required: true
          }}
          semanticProps={{
            label: 'Old Password',
            required: true
          }}
        />
        <Icon name="eye" className={styles.showPassIcon} onClick={toggleOldPasswordShown} />
      </div>
      <div className={styles.passwordWrapper}>
        <FormikInput
          propsOrFieldName={{
            type: newPasswordShown ? 'text' : 'password',
            name: 'newPassword',
            placeholder: 'Enter New Password',
            required: true
          }}
          semanticProps={{
            label: 'New Password',
            required: true
          }}
        />
        <Icon name="eye" className={styles.showPassIcon} onClick={toggleNewPasswordShown} />
      </div>
      <Caption4 className={styles.requirements}>
        {PASSWORD_REQUIREMENTS}
      </Caption4>
      <div className={styles.passwordWrapper}>
        <FormikInput
          propsOrFieldName={{
            type: repeatPasswordShown ? 'text' : 'password',
            name: 'confirmPassword',
            placeholder: 'Confirm New Password',
            required: true
          }}
          semanticProps={{
            label: 'Confirm Password',
            required: true
          }}
        />
        <Icon name="eye" className={styles.showPassIcon} onClick={toggleRepeatPasswordShown} />
      </div>
      <Caption4 className={styles.requirements}>
        {CONFIRM_PASSWORD_REQUIREMENTS}
      </Caption4>
      <div className={styles.form_button}>
        <PrimaryButton
          content="Save"
          type="submit"
          loading={saveLoading}
          disabled={!isValid}
        />
      </div>
    </Form>
  );
};

export default withFormik<IPasswordUpdateFormProps, IFormValues>({
  displayName: 'PasswordUpdateForm',
  validationSchema,
  handleSubmit: ((values, { props }) => { props.saveChanges(values); })
})(PasswordUpdateForm);
