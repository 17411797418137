import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import s from '@screens/BookingCheckout/containers/BookingCheckoutPage/BookingDetailsCheckoutStep/styles.module.scss';
// import Rating from '@screens/BookingCheckout/components/BookingDetails/Rating';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { IBindingCallback1 } from '@models/Callbacks';
import BuildingHeaderInfo from '@screens/BookingCheckout/components/BookingDetails/BuildingHeaderInfo';
import { getDefaultImageFromBuildingType } from '@helpers/image.placeholder.helper';
import ZoomableImage from '@screens/BuildingDetails/components/ZoomableImage';
import BuildingTags from '@screens/BuildingDetails/components/DescriptionSection/components/BuildingTags';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import TransactionsBlock from '@screens/ServiceOrderCheckout/containers/ServiceOrderDetailsPage/TransactionsBlock';
import { IOrderWithServicesDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderCheckout';
import OrderedServicesSummary
  from '@screens/ServiceOrderCheckout/components/ServiceOrderDetails/OrderedServicesSummary';
import { IBriefBookingDto } from '@models/domain/Booking';
import {
  extractAdditionalServicesWithAvailability,
  extractLoadAdditionalServicesWithAvailabilityLoading,
  extractToggleFavoriteLoading
} from '@screens/ServiceOrderCheckout/reducers';
import {
  createAdditionalServicesOrderRoutine,
  loadAdditionalServicesWithAvailabilityRoutine,
  toggleFavoriteRoutine
} from '@screens/ServiceOrderCheckout/routines';
import styles from './styles.module.scss';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import AdditionalServicesSection from '@components/NewDesign/AdditionalServicesSection';
import { ILoadAdditionalServiceWithAvailabilityRequest } from '@models/domain/additional_service/LoadAdditionalServiceWithAvailabilityRequest';
import { IMakeOrderRequest } from '@models/domain/additional_service/MakeOrderRequest';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';
import MetaTags from '@components/MetaTags';
import NeedNotes from '@components/NewDesign/NeedNotes';

export interface IServiceOrderDetailsPageProps extends IState, IActions {
  orderWithServices: IOrderWithServicesDto;
  booking: IBriefBookingDto;
  building: IBuildingForDisplaying;
}

interface IState {
  loadingFavorite: boolean;
  servicesList: IAdditionalServiceWithAvailability[];
  servicesListLoading: boolean;
}

interface IActions {
  toggleFavorite: IBindingCallback1<string>;
  loadAdditionalServicesList: IBindingCallback1<ILoadAdditionalServiceWithAvailabilityRequest>;
  createAdditionalServicesOrder: IBindingCallback1<IMakeOrderRequest>;
}

const ServiceOrderDetailsPage: React.FC<IServiceOrderDetailsPageProps> = (
  {
    building, booking, toggleFavorite, orderWithServices,
    loadingFavorite, servicesListLoading, servicesList, loadAdditionalServicesList, createAdditionalServicesOrder
  }
) => {
  const history = useHistory();

  return (
    <div className={`content_wrapper ${s.details__container}`}>
      <MetaTags
        title={`Order | ${building.buildingName}`}
        withSuffix
        description={building.description}
      />
      <div className={s.details__left}>
        <ZoomableImage
          src={building?.avatar || getDefaultImageFromBuildingType(building?.type)}
        />
      </div>
      <div className={s.details__right}>
        <div className={s.top_buttons_container}>
          <SmallLinkButton
            to=""
            onClick={() => history.goBack()}
            left
          >
            Back to Dashboard
          </SmallLinkButton>
        </div>
        <BuildingHeaderInfo
          building={building}
          likeLoading={loadingFavorite}
          toggleLike={toggleFavorite}
        />
        {/* <Rating />*/}
        <BuildingTags building={building} />
        <OrderedServicesSummary
          services={orderWithServices.services}
          order={orderWithServices.order}
        />
        <NeedNotes
          notesText={orderWithServices.order.note}
          marginTop
        />
        <div className={styles.bookingButtonWrapper}>
          <GoToButton to={ENDPOINTS.BOOKING(booking.id)}>
            Open Booking
          </GoToButton>
        </div>
        <TransactionsBlock
          orderId={orderWithServices.order.id}
          className={styles.transactions_section}
        />
        <AdditionalServicesSection
          marginTop
          booking={booking}
          buildingId={building.id}
          className={styles.servicesWrapper}
          servicesList={servicesList}
          servicesListLoading={servicesListLoading}
          loadAdditionalServicesList={loadAdditionalServicesList}
          createAdditionalServicesOrder={createAdditionalServicesOrder}
        />
      </div>
    </div>
  );
};

const mapStateToProps: (state) => IState = state => ({
  loadingFavorite: extractToggleFavoriteLoading(state),
  servicesListLoading: extractLoadAdditionalServicesWithAvailabilityLoading(state),
  servicesList: extractAdditionalServicesWithAvailability(state)
});

const mapDispatchToProps: IActions = {
  toggleFavorite: toggleFavoriteRoutine,
  loadAdditionalServicesList: loadAdditionalServicesWithAvailabilityRoutine,
  createAdditionalServicesOrder: createAdditionalServicesOrderRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrderDetailsPage);
