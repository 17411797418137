import React from 'react';
import { connect } from 'react-redux';
import MessagesSection from '@components/MessagesSection';
import TransactionsSection from '@screens/NeedsDashboard/Dashboard/components/TransactionsSection';
import SpacesSection from '@screens/NeedsDashboard/Dashboard/components/SpacesSection';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { IResultBuildingItem } from '@screens/BrowseSpaces/model/ResultItem';
import { ITransaction } from '@models/domain/Transaction';
import {
  fetchDashboardMessagesRoutine,
  fetchMyBuildingsRoutine,
  fetchMyTransactionsRoutine,
  readMessageFromDashboardRoutine,
  sendMessageFromDashboardRoutine, setMessageFullFromDashboardRoutine,
  setWritingMessageFromDashboardRoutine,
  toggleFavoriteBuildingRoutine
} from '@screens/HavesDashboard/Dashboard/routines';
import {
  extractFetchDashboardMessagesLoading,
  extractFetchMyBuildingsLoading,
  extractFetchMyTransactionsLoading, extractFullMessage,
  extractMoreBuildings, extractMoreMessages,
  extractMoreTransactions,
  extractMyBuildings, extractMyMessages,
  extractMyTransactions, extractSendMessageFromDashboardLoading, extractWriting
} from '@screens/HavesDashboard/Dashboard/reducers';
import { IMessageDto } from '@models/domain/message/MessageDto';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import styles from '@screens/NeedsDashboard/Dashboard/containers/DashboardPage/styles.module.scss';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import TableColumnTitle from '@components/NewDesign/Typography/FormAndTable/TableColumnTitle';

export interface IDashboardProps {
  toggleFavoriteBuilding: IBindingCallback1<string>;
  fetchOwnedBuildings: IBindingAction;
  buildingsLoading: boolean;
  buildings: IResultBuildingItem[];
  messages: IMessageDto[];
  fetchMyTransactions: IBindingAction;
  fetchMyMessages: IBindingAction;
  messagesLoading: boolean;
  transactionsLoading: boolean;
  transactions: ITransaction[];
  moreMessages: boolean;
  moreBuildings: boolean;
  moreTransactions: boolean;
  writing: boolean;
  setWriting: IBindingCallback1<boolean>;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  sendMessageLoading: boolean;
  readMessage: IBindingCallback1<string>;
  messageFull?: IMessageDto;
  setMessageFull: IBindingCallback1<IMessageDto | undefined>;
}

const Dashboard: React.FC<IDashboardProps> = (
  {
    buildings, buildingsLoading, fetchOwnedBuildings, transactions, transactionsLoading,
    fetchMyTransactions, toggleFavoriteBuilding, moreTransactions, moreBuildings, messages,
    messagesLoading, moreMessages, fetchMyMessages, writing, sendMessage, sendMessageLoading,
    readMessage, setWriting, messageFull, setMessageFull
  }
) => (
  <DashboardPageWrapper title="Dashboard">
    <DashboardSectionWrapper title="Recent Messages">
      <MessagesSection
        items={messages}
        fetchItems={fetchMyMessages}
        itemsLoading={messagesLoading}
        haveMore={moreMessages}
        writing={writing}
        sendMessageLoading={sendMessageLoading}
        sendMessage={sendMessage}
        setWriting={setWriting}
        readMessage={readMessage}
        messageFull={messageFull}
        setMessageFull={setMessageFull}
        messageCenterLink={HAVES_DASHBOARD_ENDPOINTS.MESSAGES}
      />
    </DashboardSectionWrapper>
    <DashboardSectionWrapper title="My Spaces">
      <SpacesSection
        toggleLike={toggleFavoriteBuilding}
        items={buildings}
        fetchItems={fetchOwnedBuildings}
        itemsLoading={buildingsLoading}
        haveMore={moreBuildings}
      />
    </DashboardSectionWrapper>
    <DashboardSectionWrapper
      title="Transactions"
      rightElement={(
        <TableColumnTitle className={styles.transactions_subtitle}>
          SHOWING THE LAST 5 TRANSACTIONS
        </TableColumnTitle>
      )}
    >
      <TransactionsSection
        items={transactions}
        fetchItems={fetchMyTransactions}
        itemsLoading={transactionsLoading}
        haveMore={moreTransactions}
        placeholderClassName={styles.transactionSection}
      />
    </DashboardSectionWrapper>
  </DashboardPageWrapper>
);

const mapStateToProps = state => ({
  messages: extractMyMessages(state),
  buildings: extractMyBuildings(state),
  transactions: extractMyTransactions(state),
  buildingsLoading: extractFetchMyBuildingsLoading(state),
  transactionsLoading: extractFetchMyTransactionsLoading(state),
  moreBuildings: extractMoreBuildings(state),
  moreTransactions: extractMoreTransactions(state),
  moreMessages: extractMoreMessages(state),
  messagesLoading: extractFetchDashboardMessagesLoading(state),
  writing: extractWriting(state),
  sendMessageLoading: extractSendMessageFromDashboardLoading(state),
  messageFull: extractFullMessage(state)
});

const mapDispatchToProps = {
  toggleFavoriteBuilding: toggleFavoriteBuildingRoutine,
  fetchOwnedBuildings: fetchMyBuildingsRoutine,
  fetchMyTransactions: fetchMyTransactionsRoutine,
  fetchMyMessages: fetchDashboardMessagesRoutine,
  setWriting: setWritingMessageFromDashboardRoutine.fulfill,
  sendMessage: sendMessageFromDashboardRoutine,
  readMessage: readMessageFromDashboardRoutine,
  setMessageFull: setMessageFullFromDashboardRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
