// @ts-nocheck
import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import {
  IServiceBuildingDto,
  IServiceDetailsDto
} from '@screens/HavesDashboard/AdditionalServices/model/ServiceDetailsDto';
import * as Yup from 'yup';
import {
  priceValidation,
  requiredValidation
} from '@screens/Authorization/components/validation';
import { Currency } from '@models/domain/Currency';
import { Checkbox, Form, Loader, TableCell, TableRow } from 'semantic-ui-react';
import FormikInput from '@components/formik/Input';
import { FormikProps, withFormik } from 'formik';
import { vagueEquals } from '@screens/BuildingEditor/components/BuildingEditorForm';
import FormikSelect from '@components/formik/select/Select';
import { enumAsOptions } from '@helpers/enum.helper';
import { ServiceType, serviceTypeKey } from '@screens/HavesDashboard/AdditionalServices/model/ServiceType';
import {
  LogisticsServiceCategory, ServiceCategory, TransportationServiceCategory,
  WarehouseServiceCategory
} from '@screens/HavesDashboard/AdditionalServices/model/ServiceCategory';
import { ServiceBillingUnit } from '@screens/HavesDashboard/AdditionalServices/model/ServiceBillingUnit';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import _ from 'lodash';
import { IBuildingTitleDto } from '@screens/HavesDashboard/AdditionalServices/model/BuildingTitleDto';
import TableWrapper from '@components/NewDesign/Table/TableWrapper';
import CounterInput from '@components/CouterInput';
import FormGroupWrapper from '@components/NewDesign/Form/FormGroupWrapper';
import { IServiceSaveDto } from '@screens/HavesDashboard/AdditionalServices/model/ServiceSaveDto';
import { IBindingCallback1 } from '@models/Callbacks';

export interface IAdditionalServiceFormProps {
  initialValues?: Partial<IServiceDetailsDto>;
  loadingValues?: boolean;
  className?: string;
  onSave: IBindingCallback1<IServiceSaveDto>;
  saveLoading?: boolean;
  buildingTitles?: IBuildingTitleDto[];
  buildingTitlesLoading?: boolean;
}

interface IFormProps {
  alias: string;
  category: string;
  type: string;
  billingUnit: string;
  priceAmount: string;
  buildings: any;
}

const defaultFormValues: IFormProps = {
  alias: '',
  category: '',
  type: '',
  billingUnit: '',
  priceAmount: '',
  buildings: {}
};

const serviceToForm: (service: Partial<IServiceDetailsDto>) => IFormProps = service => {
  const buildings = {};
  (service.buildings || []).forEach(b => { buildings[b.id] = b.availablePerDay; });

  return {
    alias: service.alias,
    category: service.category,
    type: service.type,
    billingUnit: service.billingUnit,
    priceAmount: service.price?.amount.toString(),
    buildings
  };
};

const validationSchema = Yup.object().shape({
  alias: requiredValidation,
  category: requiredValidation,
  type: requiredValidation,
  billingUnit: requiredValidation,
  priceAmount: priceValidation
});

const formToService = (formValues: IFormProps): IServiceSaveDto => {
  const buildings: IServiceBuildingDto[] = Object.keys(formValues.buildings)
    .filter(buildingId => formValues.buildings[buildingId])
    .map(buildingId => ({
      id: buildingId,
      availablePerDay: formValues.buildings[buildingId]
    }));

  return {
    alias: formValues.alias,
    category: formValues.category as ServiceCategory,
    type: formValues.type as ServiceType,
    billingUnit: formValues.billingUnit as ServiceBillingUnit,
    price: {
      currency: Currency.USD,
      amount: formValues.priceAmount as any
    },
    buildings
  };
};

const AdditionalServiceForm: React.FC<IAdditionalServiceFormProps & FormikProps<IFormProps>> = (
  { className, loadingValues, values,
    initialValues, isValid, saveLoading, setFieldValue,
    setTouched, buildingTitlesLoading, buildingTitles, setValues,
    handleSubmit }
) => {
  const categoryEnum = useMemo(() => {
    switch (values.type) {
      case serviceTypeKey(ServiceType.WAREHOUSE):
        return WarehouseServiceCategory;
      case serviceTypeKey(ServiceType.LOGISTICS):
        return LogisticsServiceCategory;
      case serviceTypeKey(ServiceType.TRANSPORTATION):
        return TransportationServiceCategory;
      default:
        return {};
    }
  }, [values.type]);

  useEffect(() => {
    if (!(values.category in categoryEnum)) {
      setFieldValue('category', null);
      setTouched({ category: false });
    }
  }, [categoryEnum, setFieldValue, setTouched, values.category]);

  const handleCounterChange = useCallback((buildingId: string, num: number) => {
    setValues(oldValues => ({
      ...oldValues,
      buildings: {
        ...oldValues.buildings,
        [buildingId]: num
      }
    }));
  }, [setValues]);

  const handleCheckboxChange = useCallback((buildingId: string) => {
    setValues(oldValues => ({
      ...oldValues,
      buildings: {
        ...oldValues.buildings,
        [buildingId]: oldValues.buildings[buildingId] ? undefined : 1
      }
    }));
  }, [setValues]);

  return (
    <Form
      loading={loadingValues}
      className={className}
      onSubmit={handleSubmit}
    >
      <FormikInput
        propsOrFieldName={{
          name: 'alias',
          placeholder: 'Service Name'
        }}
        semanticProps={{
          label: 'Name',
          required: true
        }}
      />
      <FormGroupWrapper>
        <FormikSelect
          propsOrFieldName={{
            name: 'type',
            placeholder: 'Service Type',
            disabled: !!initialValues?.type
          }}
          semanticProps={{
            label: 'Type',
            options: enumAsOptions(ServiceType),
            required: true
          }}
        />
        <FormikSelect
          propsOrFieldName={{
            name: 'category',
            placeholder: 'Service Category',
            disabled: !values.type
          }}
          semanticProps={{
            label: 'Category',
            options: enumAsOptions(categoryEnum),
            required: true
          }}
        />
      </FormGroupWrapper>
      <FormGroupWrapper>
        <FormikInput
          propsOrFieldName={{
            name: 'priceAmount',
            placeholder: 'Price'
          }}
          semanticProps={{
            label: 'Price per Unit, USD',
            required: true
          }}
        />
        <FormikSelect
          propsOrFieldName={{
            name: 'billingUnit',
            placeholder: 'Service Billing Unit',
            disabled: !!initialValues?.billingUnit
          }}
          semanticProps={{
            label: 'Billing Unit',
            options: enumAsOptions(ServiceBillingUnit),
            required: true
          }}
        />
      </FormGroupWrapper>
      {buildingTitlesLoading && (
        <Loader active inline="centered" />
      )}
      {!buildingTitlesLoading && buildingTitles && !!buildingTitles.length && (
        <div className={styles.tableWrapper}>
          <TableWrapper titles={['', 'Building', 'Available amount']}>
            <>
              {buildingTitles.map(building => (
                <TableRow key={building.id}>
                  <TableCell>
                    <Checkbox
                      checked={!!values.buildings[building.id]}
                      onChange={() => handleCheckboxChange(building.id)}
                    />
                  </TableCell>
                  <TableCell>
                    {building.name}
                  </TableCell>
                  <TableCell>
                    <CounterInput
                      className={styles.counter}
                      min={1}
                      value={values.buildings[building.id]}
                      disabled={!values.buildings[building.id]}
                      onChange={num => handleCounterChange(building.id, num)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </>
          </TableWrapper>
        </div>
      )}
      <div>
        <PrimaryButton
          className={styles.button}
          type="submit"
          content="Save"
          disabled={_.isEqualWith(initialValues, values, vagueEquals) || !isValid}
          loading={saveLoading}
        />
      </div>
    </Form>
  );
};

export default withFormik<IAdditionalServiceFormProps, IFormProps>({
  displayName: 'AdditionalServiceForm',
  enableReinitialize: true,
  mapPropsToValues: props => (props.initialValues ? serviceToForm(props.initialValues) : defaultFormValues),
  handleSubmit: ((values, { props }) => props.onSave(formToService(values))),
  validationSchema
})(AdditionalServiceForm);
