import React, { useEffect, useState } from 'react';
import { Modal, ModalContent } from 'semantic-ui-react';
import BuildingEditorForm from '@screens/BuildingEditor/components/BuildingEditorForm';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { IBuildingForSave } from '@screens/BuildingEditor/model/BuildingForSave';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';

export interface IAddBuildingButtonWithModalProps {
  loadTags: IBindingAction;
  tagsLoading: boolean;
  createBuilding: IBindingCallback1<IBuildingForSave>;
  creationLoading: boolean;
  tags: string[];
}

const AddBuildingButtonWithModal: React.FC<IAddBuildingButtonWithModalProps> = (
  { creationLoading, createBuilding, tagsLoading, loadTags, tags }
) => {
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    loadTags();
  }, [loadTags]);

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeOnEscape={false} closeIcon>
        <ModalHeaderWrapper>Create building</ModalHeaderWrapper>
        <ModalContent>
          <BuildingEditorForm
            saveLoading={creationLoading}
            tagsLoading={tagsLoading}
            tags={tags}
            saveBuilding={createBuilding}
          />
        </ModalContent>
      </Modal>
      <PrimaryButton
        content="Add new"
        icon="plus"
        labelPosition="left"
        onClick={() => setModalOpen(true)}
      />
    </>
  );
};

export default AddBuildingButtonWithModal;
