import { all } from 'redux-saga/effects';
/* PlopJS import placeholder. Do not remove */
import payoutsSagas from '@screens/AdminDashboard/Payouts/sagas';
import buildingsAndSpacesSagas from '@screens/AdminDashboard/BuildingsAndSpaces/sagas';
import membersSummarySagas from '@screens/AdminDashboard/MembersSummary/sagas';
import dashboardSagas from '@screens/AdminDashboard/Dashboard/sagas';
import transactionsSagas from '@screens/AdminDashboard/Transactions/sagas';

export default function* adminDashboardSagas() {
  yield all([
    /* PlopJS sagas placeholder. Do not remove */
    payoutsSagas(),
    buildingsAndSpacesSagas(),
    membersSummarySagas(),
    dashboardSagas(),
    transactionsSagas()
  ]);
}
