import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import {
  extractAdditionalServices,
  extractBuildingBookingsData,
  extractFetchAdditionalServicesLoading
} from '@screens/NeedsDashboard/BuildingDetails/reducers';
import AdditionalServicesSection from '@components/NewDesign/AdditionalServicesSection';
import {
  fetchAdditionalServicesRoutine,
  orderAdditionalServicesRoutine
} from '@screens/NeedsDashboard/BuildingDetails/routines';
import { IBindingCallback1 } from '@models/Callbacks';
import {
  ILoadAdditionalServiceWithAvailabilityRequest
} from '@models/domain/additional_service/LoadAdditionalServiceWithAvailabilityRequest';
import { IMakeOrderRequest } from '@models/domain/additional_service/MakeOrderRequest';
import { BookingSelect } from './components/BookingSelect';
import { IBookingDetailsForSchedulingDto } from '@screens/NeedsDashboard/Bookings/model/BookingDetailsDto';
import styles from './styles.module.scss';

const AdditionalServicesSectionWrapper = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { booking: bookingId } = parse(location.search);

  const [selectedBooking, setSelectedBooking] = useState<IBookingDetailsForSchedulingDto | null>(null);

  const {
    items: bookings
  } = useSelector(extractBuildingBookingsData);
  const additionalServices = useSelector(extractAdditionalServices);
  const isAdditionalServicesLoading = useSelector(extractFetchAdditionalServicesLoading);

  const handleFetchAdditionalServices = useCallback<IBindingCallback1<ILoadAdditionalServiceWithAvailabilityRequest>>(
    payload => {
      dispatch(fetchAdditionalServicesRoutine.trigger(payload));
    }, [dispatch]
  );

  const handleOrderAdditionalServices = useCallback<IBindingCallback1<IMakeOrderRequest>>(
    payload => {
      dispatch(orderAdditionalServicesRoutine.trigger(payload));
    }, [dispatch]
  );

  const handleBookingSelect = useCallback(booking => {
    setSelectedBooking(booking);
  }, []);

  return (
    <div className={styles.additional_services_section}>
      <BookingSelect
        bookings={bookings}
        locationBookingId={typeof bookingId === 'object' ? bookingId[0] : bookingId}
        selectedBooking={selectedBooking}
        onBookingSelect={handleBookingSelect}
      />
      {selectedBooking?.booking?.id && (
        <AdditionalServicesSection
          hideHeader
          booking={selectedBooking.booking}
          buildingId={bookings[0].building.id}
          servicesList={additionalServices}
          servicesListLoading={isAdditionalServicesLoading}
          loadAdditionalServicesList={handleFetchAdditionalServices}
          createAdditionalServicesOrder={handleOrderAdditionalServices}
        />
      )}
    </div>
  );
};

export { AdditionalServicesSectionWrapper };
