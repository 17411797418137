import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  loadProfileDetailsRoutine,
  saveProfileDetailsRoutine, setUpdatePasswordModalOpenRoutine, updatePasswordRoutine,
  uploadAvatarRoutine
} from '@screens/NeedsDashboard/Account/routines';
import profileService from '@services/profile.service';
import { toastr } from 'react-redux-toastr';
import { Routine } from 'redux-saga-routines';
import { syncUserRoutine } from '@screens/Authorization/routines';
import { ICurrentUser } from '@screens/Authorization/models/CurrentUser';
import { extractCurrentUser } from '@screens/Authorization/reducers';
import { Role } from '@screens/Authorization/models/Roles';
import { history } from '@helpers/history.helper';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';

export const tryLoadProfileDetails = (routine: Routine) => function* loadProfile() {
  try {
    const resp = yield call(profileService.loadProfileData);
    yield put(routine.success(resp));
  } catch (e) {
    toastr.error('Failed to load profile info', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const trySaveProfileDetails = (routine: Routine, updateUserRoutine: Routine) => function* saveProfile({ payload }: Routine<any>) {
  try {
    const userBeforeSave: ICurrentUser = yield select(extractCurrentUser);
    const resp = yield call(profileService.saveProfileData, payload);
    yield put(routine.success(resp));
    toastr.success('Success', 'Profile saved!');
    yield put(updateUserRoutine.trigger());
    if (userBeforeSave.hasDetails) {
      return;
    }
    if (userBeforeSave.roles.includes(Role.NEED)) {
      history.push(NEEDS_DASHBOARD_ENDPOINTS.CARDS_CONFIG);
    }
    if (userBeforeSave.roles.includes(Role.HAVE)) {
      history.push(HAVES_DASHBOARD_ENDPOINTS.FINANCIAL);
    }
  } catch (e) {
    toastr.error('Failed to save profile info', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const tryUpdatePassword = (routine: Routine, setModalOpenRoutine: Routine) => function* updatePassword({ payload }: Routine<any>) {
  try {
    yield call(profileService.updatePassword, payload);
    yield put(routine.success());
    yield put(setModalOpenRoutine.fulfill(false));
    toastr.success('Success', 'Password has been successfully updated!');
  } catch (e) {
    toastr.error('Failed to update password', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const tryToUploadAvatar = (routine: Routine) => function* uploadAvatar({ payload }: Routine<any>) {
  try {
    const formData = new FormData();
    formData.append('avatarFile', payload, payload.name);
    const resp = yield call(profileService.uploadAvatar, formData);
    yield put(routine.success(resp));
    toastr.success('Success', 'Avatar uploaded!');
  } catch (e) {
    toastr.error('Failed to upload avatar', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export default function* accountPageSagas() {
  yield all([
    yield takeEvery(loadProfileDetailsRoutine.TRIGGER, tryLoadProfileDetails(loadProfileDetailsRoutine)),
    yield takeEvery(
      saveProfileDetailsRoutine.TRIGGER, trySaveProfileDetails(saveProfileDetailsRoutine, syncUserRoutine)
    ),
    yield takeEvery(uploadAvatarRoutine.TRIGGER, tryToUploadAvatar(uploadAvatarRoutine)),
    yield takeEvery(
      updatePasswordRoutine.TRIGGER, tryUpdatePassword(updatePasswordRoutine, setUpdatePasswordModalOpenRoutine)
    )
  ]);
}
