import React from 'react';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import { IBindingAction } from '@models/Callbacks';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';
import styles from './styles.module.scss';

export interface ISliderStepProps {
  index: number;
  title: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: IBindingAction;
}

const SliderStep: React.FC<ISliderStepProps> = (
  { index, title, active, disabled, onClick, children }
) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick();
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.header, disabled && styles.disabledHeader)} onClick={handleClick}>
        <div className={styles.index}>
          {index}
        </div>
        <Header3 disableWordBreak className={styles.title}>{title}</Header3>
        <div className={styles.arrowWrapper}>
          <Icon className={styles.arrow} name={active ? 'chevron right' : 'chevron down'} />
        </div>
      </div>
      <div className={classNames(styles.content, active ? styles.activeContent : styles.disabledContent)}>
        {children}
      </div>
    </div>
  );
};

export default SliderStep;
