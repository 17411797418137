import React, { useRef } from 'react';
import { Icon } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import { ImageWrapper } from '@screens/BuildingEditor/components/ImagesTab/ImageMiniature/Wrapper';
import { IImageDto } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { IBindingCallback1 } from '@models/Callbacks';
import { maxImagesCount } from '@screens/BuildingEditor/model/ConstValue';
import Caption3 from '@components/NewDesign/Typography/Caption/Caption3';
import styles from './styles.module.scss';

export interface IAddImageMiniatureProps {
  onImagesSelection: IBindingCallback1<File[]>;
  images: IImageDto[];
}

const AddImageMiniature: React.FC<IAddImageMiniatureProps> = ({ onImagesSelection, images }) => {
  const inputRef = useRef<HTMLInputElement>();

  const handleFilesSelection = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onImagesSelection(Array.from(ev.target.files));
    window.scrollTo(0, 0);
    ev.target.value = null;
  };
  const handelAddImage = () => {
    if (images.length >= maxImagesCount) {
      toastr.error('Unable to upload image', `Only ${maxImagesCount} images could be uploaded totally`);
    } else {
      inputRef.current.click();
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        onChange={handleFilesSelection}
        multiple
        style={{ visibility: 'hidden', position: 'absolute' }}
        accept="image/*"
      />
      <ImageWrapper
        className={styles.add_image}
        onClick={handelAddImage}
      >
        <Icon name="image outline" />
        <Caption3 className={styles.add_image__text}>Add image</Caption3>
      </ImageWrapper>
    </>
  );
};

export default AddImageMiniature;
