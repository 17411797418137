import React from 'react';
import { Label, TableCell, TableRow } from 'semantic-ui-react';
import moment from 'moment';
import styles from '@screens/NeedsDashboard/Dashboard/components/TransactionsSection/styles.module.scss';
import { toPrice } from '@helpers/price.helper';
import { ITransaction } from '@models/domain/Transaction';
import { IBindingCallback1 } from '@models/Callbacks';
import { DATE_FORMAT } from '@helpers/date.helper';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';

interface ITransactionRowProps {
  transaction: ITransaction;
  onItemClick: IBindingCallback1<ITransaction>;
}

const TransactionRow: React.FC<ITransactionRowProps> = ({ transaction, onItemClick }) => (
  <TableRow key={transaction.id} onClick={() => onItemClick(transaction)} className={styles.row}>
    <TableCell>
      {transaction.paid ? <Label content="YES" color="green" /> : <Label content="NO" color="red" />}
    </TableCell>
    <TableCell>{moment(transaction.paymentInitialDate).format(DATE_FORMAT)}</TableCell>
    <TableCell>{transaction.dateTime && moment(transaction.dateTime).format(`HH:mm:ss ${DATE_FORMAT}`)}</TableCell>
    <TableCell>
      <TableImportantContent disableTranslation>
        {transaction.price && (`${toPrice(transaction.price)}`)}
      </TableImportantContent>
    </TableCell>
    <TableCell>
      {transaction.type}
    </TableCell>
  </TableRow>
);

export default TransactionRow;
