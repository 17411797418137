import React from 'react';
import classNames from 'classnames';
import { IBindingAction } from '@models/Callbacks';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

export interface IActivationButtonProps {
  onClick: IBindingAction;
  active?: boolean;
  title: string;
}

const ActivationButton: React.FC<IActivationButtonProps> = (
  { onClick, active, title }
) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.container, active && styles.active)}
      onClick={onClick}
    >
      {t(title)}
    </div>
  );
};

export default ActivationButton;
