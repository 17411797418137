import { callApi } from '@helpers/api.helper';

const orderPaymentService = {
  fetchPaymentRequirements: (orderId: string) => callApi({
    endpoint: `service_orders/${orderId}/payment_requirements`,
    method: 'GET'
  })
};

export default orderPaymentService;
