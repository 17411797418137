import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { IBindingCallback1 } from '@models/Callbacks';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import { IMessageDto } from '@models/domain/message/MessageDto';
import {
  extractFetchMessageCenterMessagesLoading,
  extractFullMessage, extractMessages,
  extractSendMessageFromMessageCenterLoading,
  extractWriting
} from '@screens/HavesDashboard/MessageCenter/reducers';
import {
  fetchMessageCenterMessagesRoutine,
  readMessageFromMessageCenterRoutine,
  sendMessageFromMessageCenterRoutine,
  setMessageCenterMessagesPageRoutine,
  setMessageFullFromMessageCenterRoutine,
  setWritingMessageFromMessageCenterRoutine
} from '@screens/HavesDashboard/MessageCenter/routines';
import { IPageableReducerState } from '@models/domain/PageableReducerState';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import Pagination from '@components/Pagination';
import NoDataContainer from '@components/NoDataContainer';
import MessageTableWrapper from '@components/NewDesign/MessageSection/MessageSectionWrapper';
import MessageRow from '@components/NewDesign/MessageSection/MessageRow';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import styles from './styles.module.scss';

export interface IMessageCenterProps extends IState, IActions {
}

interface IState {
  writing: boolean;
  sendMessageLoading: boolean;
  fetchMessagesLoading: boolean;
  messageFull?: IMessageDto;
  messages: IPageableReducerState<IMessageDto>;
}

interface IActions {
  setWriting: IBindingCallback1<boolean>;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  readMessage: IBindingCallback1<string>;
  setMessageFull: IBindingCallback1<IMessageDto | undefined>;
  fetchMessages: IBindingCallback1<IPageRequest>;
  setPage: IBindingCallback1<number>;
}

const MessageCenter: React.FC<IMessageCenterProps> = (
  { writing, setWriting, sendMessageLoading, sendMessage, setMessageFull, readMessage, messageFull,
    messages, fetchMessages, fetchMessagesLoading, setPage }
) => {
  useEffect(() => {
    fetchMessages({ size: messages.pageSize, page: messages.page });
  }, [messages.page, messages.pageSize, fetchMessages]);

  const renderMessage = (message: IMessageDto) => (
    <MessageRow
      key={message.id}
      message={message}
      readMessage={readMessage}
      setMessageFull={setMessageFull}
    />
  );

  return (
    <DashboardPageWrapper title="Message Center">
      <DashboardSectionWrapper>
        {fetchMessagesLoading ? <Loader active inline="centered" /> : (
          <>
            {messages.totalResults ? (
              <MessageTableWrapper
                writing={writing}
                setWriting={setWriting}
                sendMessage={sendMessage}
                sendMessageLoading={sendMessageLoading}
                setMessageFull={setMessageFull}
                messageFull={messageFull}
              >
                {messages.items.map(renderMessage)}
              </MessageTableWrapper>
            ) : <NoDataContainer className={styles.no_data} message="There are no messages yet" />}
          </>
        )}
        {!fetchMessagesLoading && messages.totalPages > 1 && (
          <Pagination totalPages={messages.totalPages} currentPage={messages.page} setPage={setPage} />
        )}
      </DashboardSectionWrapper>
    </DashboardPageWrapper>
  );
};

const mapStateToProps: (state) => IState = state => ({
  messages: extractMessages(state),
  writing: extractWriting(state),
  sendMessageLoading: extractSendMessageFromMessageCenterLoading(state),
  messageFull: extractFullMessage(state),
  fetchMessagesLoading: extractFetchMessageCenterMessagesLoading(state)
});

const mapDispatchToProps: IActions = {
  setWriting: setWritingMessageFromMessageCenterRoutine.fulfill,
  sendMessage: sendMessageFromMessageCenterRoutine,
  setMessageFull: setMessageFullFromMessageCenterRoutine.fulfill,
  readMessage: readMessageFromMessageCenterRoutine,
  fetchMessages: fetchMessageCenterMessagesRoutine,
  setPage: setMessageCenterMessagesPageRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageCenter);
