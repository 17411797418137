import { Routine } from 'redux-saga-routines';
import {
  fetchMyBuildingsRoutine,
  fetchMyTransactionsRoutine,
  toggleFavoriteBuildingRoutine,

  fetchDashboardMessagesRoutine,
  readMessageFromDashboardRoutine,
  setMessageFullFromDashboardRoutine,
  setWritingMessageFromDashboardRoutine
} from '@screens/NeedsDashboard/Dashboard/routines';
import { ITransaction } from '@models/domain/Transaction';
import { IResultBuildingItem } from '@screens/BrowseSpaces/model/ResultItem';
import { favFailure, favSuccess, favTriggered } from '@screens/BrowseSpaces/containers/BrowseSpacesPage/reducer';
import { IMessageDto } from '@models/domain/message/MessageDto';

export interface IDashboardReducerState {
  buildings: IResultBuildingItem[];
  moreBuildings: boolean;
  transactions: ITransaction[];
  moreTransactions: boolean;

  messages: IMessageDto[];
  moreMessages: boolean;
  writing: boolean;
  messageFull?: IMessageDto;
}

const initialState: IDashboardReducerState = {
  buildings: [],
  transactions: [],
  moreBuildings: false,
  moreTransactions: false,

  messages: [],
  moreMessages: false,
  writing: false
};

export const dashboardReducer = (state = initialState, action: Routine<any>) => {
  const { type, payload } = action;
  switch (type) {
    case setMessageFullFromDashboardRoutine.FULFILL:
      return {
        ...state,
        messageFull: payload
      };
    case readMessageFromDashboardRoutine.SUCCESS:
      return {
        ...state,
        messages: state.messages.map(m => (m.id === payload
          ? { ...m, read: true }
          : m
        ))
      };
    case setWritingMessageFromDashboardRoutine.FULFILL:
      return {
        ...state,
        writing: payload
      };
    case fetchDashboardMessagesRoutine.SUCCESS:
      return {
        ...state,
        messages: payload.items,
        moreMessages: payload.totalPages > 1
      };
    case fetchMyBuildingsRoutine.SUCCESS:
      return {
        ...state,
        buildings: payload.items,
        moreSpaces: payload.totalPages > 1
      };
    case fetchMyTransactionsRoutine.SUCCESS:
      return {
        ...state,
        transactions: payload.items,
        moreTransactions: payload.totalPages > 1
      };
    case toggleFavoriteBuildingRoutine.TRIGGER:
      return {
        ...state,
        buildings: favTriggered(state.buildings, payload)
      };
    case toggleFavoriteBuildingRoutine.SUCCESS:
      return {
        ...state,
        buildings: favSuccess(state.buildings, payload)
      };
    case toggleFavoriteBuildingRoutine.FAILURE:
      return {
        ...state,
        buildings: favFailure(state.buildings, payload.id)
      };
    default:
      return state;
  }
};
