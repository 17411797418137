import { BuildingType } from '@models/domain/BuildingType';
import blueImage from '@images/buildingFillers/blue.png';
import greenImage from '@images/buildingFillers/green.png';
import orangeImage from '@images/buildingFillers/orange.png';

export const placeholderSrc = (width, height) => `https://via.placeholder.com/${width}x${height}`;

export const getDefaultImageFromBuildingType = (type: BuildingType | string): string => {
  switch (type) {
    case BuildingType.OFFICE:
      return blueImage;
    case BuildingType.WAREHOUSE:
      return greenImage;
    case BuildingType.MIXED:
      return orangeImage;
    default:
      return null;
  }
};
