import React, { useCallback } from 'react';
import { ILocationWithTitleData, IProximityData } from '@screens/BrowseSpaces/model/QueryData';
import { IBindingCallback1 } from '@models/Callbacks';
import { ISearchSelector } from '../model/SearchSelector';
import styles from './styles.module.scss';
import GoogleAutocompleteInput, { IGooglePlace } from '@components/GoogleAutocompleteInput';

export interface IProximitySelectorProps extends ISearchSelector {
  value: IProximityData | undefined;
  onChange: IBindingCallback1<IProximityData>;
}

const DEFAULT_RANGE = 10;

const ProximitySelector: React.FC<IProximitySelectorProps> = ({ value, onChange }) => {
  const handleChangeLocation = useCallback((location: ILocationWithTitleData) => {
    onChange({
      ...value,
      location,
      range: value?.range || DEFAULT_RANGE
    });
  }, [onChange, value]);

  const handlePlaceChange = useCallback((place: IGooglePlace) => {
    handleChangeLocation({
      literal: {
        lng: place.geometry.location.lng(),
        lat: place.geometry.location.lat()
      },
      title: place.formatted_address
    });
  }, [handleChangeLocation]);

  return (
    <div className={styles.container}>
      <div className={styles.input}>
        <GoogleAutocompleteInput
          onPlaceChange={handlePlaceChange}
          selectedAddress={value?.location?.title}
          resultType="area"
          placeholder="Enter ZIP or City"
        />
      </div>
    </div>
  );
};

export default ProximitySelector;
