import React from 'react';
import { isEmpty } from 'lodash';
import NeedsBookingDetails, { IBookingDetailsProps as NeedsBookingProps } from '@containers/Schedules/BookingDetailsModal/BookingDetails';
import { IBriefBookingWithPersonDto } from '@screens/HavesDashboard/DropOffAndPickUp/model/BriefBookingWithPerson';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';

export type IBookingDetailsProps = NeedsBookingProps<IBriefBookingWithPersonDto>;

const BookingDetails: React.FC<IBookingDetailsProps> = ({ booking }) => (
  <>
    <NeedsBookingDetails booking={booking} />
    <LabelGroupWrapper
      title="Person booked"
      value={`${booking.personBooked.firstName} ${booking.personBooked.lastName}`}
      disableValueTranslation
    />
    { !isEmpty(booking.note) && (
      <LabelGroupWrapper
        title="Notes"
        value={booking.note}
      />
    )}
  </>
);

export default BookingDetails;
