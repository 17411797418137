import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import Dashboard from '@screens/HavesDashboard/Dashboard/containers/DashboardPage';
import AppRoute from '@containers/AppRoute';
import Account from '@screens/HavesDashboard/Account/containers/AccountPage';
import { withOptionalBuilding } from '@screens/NeedsDashboard/Root/components/Routing';
import Transactions from '@screens/HavesDashboard/Transactions/containers/TransactionsPage';
import Buildings from '@screens/HavesDashboard/Buildings/containers/BuildingsPage';
import NotFound from '@components/404NotFound';
import DropOffAndPickUp from '@screens/HavesDashboard/DropOffAndPickUp/containers/DropOffAndPickUpPage';
import Favorites from '@screens/HavesDashboard/Favorites/containers/FavoritesPage';
import FinancialBanking from '@screens/HavesDashboard/FinancialBanking/containers/FinancialBankingPage';
import MessageCenter from '@screens/HavesDashboard/MessageCenter/containers/MessageCenterPage';
import {
  userEmailActivatedRedir,
  userIsAuthenticatedRedir,
  userIsHaveRedir,
  userSelectedRole
} from '@helpers/authRules.helper';
import BuildingEditor from '@screens/BuildingEditor/containers/BuildingEditorPage';
import AdditionalServices from '@screens/HavesDashboard/AdditionalServices/containers/AdditionalServicesPage';
import Orders from '@screens/HavesDashboard/Orders/containers/OrdersPage';

const HavesDashboardRouting: React.FC = () => {
  const location = useLocation();

  const {
    DASHBOARD, ACCOUNT, TRANSACTIONS, BUILDINGS, ADDITIONAL_SERVICES,
    SCHEDULE, FAVORITES, FINANCIAL, ORDERS,
    MESSAGES, LIST_YOUR_SPACE, BUILDING_EDITOR
  } = HAVES_DASHBOARD_ENDPOINTS;

  const userValidRedir = component => userIsAuthenticatedRedir(userEmailActivatedRedir(userSelectedRole(component)));

  const BuildingEditorPage = userValidRedir(userIsHaveRedir(BuildingEditor));

  return (
    <Switch>
      <AppRoute lightBlue exact path={DASHBOARD} component={withOptionalBuilding(Dashboard)} />
      <AppRoute lightBlue exact path={ACCOUNT} component={Account} />
      <AppRoute lightBlue exact path={MESSAGES} component={MessageCenter} />
      <AppRoute lightBlue exact path={TRANSACTIONS} component={Transactions} />
      <AppRoute lightBlue exact path={BUILDINGS} component={withOptionalBuilding(Buildings)} />
      <AppRoute lightBlue exact path={ADDITIONAL_SERVICES} component={AdditionalServices} />
      <AppRoute lightBlue exact path={ORDERS} component={Orders} />
      <AppRoute lightBlue exact path={SCHEDULE} component={DropOffAndPickUp} />
      <AppRoute lightBlue exact path={FAVORITES} component={withOptionalBuilding(Favorites)} />
      <AppRoute lightBlue exact path={FINANCIAL} component={FinancialBanking} />
      <AppRoute
        lightBlue
        exact
        path={LIST_YOUR_SPACE}
        component={() => (
          <Redirect to={{ pathname: BUILDINGS, state: location.state }} />
        )}
      />
      <AppRoute exact path={BUILDING_EDITOR(':id')} component={BuildingEditorPage} />
      <AppRoute lightBlue component={NotFound} />
    </Switch>
  );
};

export default HavesDashboardRouting;
