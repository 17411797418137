import { callApi } from '@helpers/api.helper';
import { ISpaceTemplateModificationRequest } from '@components/NewDesign/Spaces/SpacesEditingForm';

const spaceTemplatesService = {
  createTemplate: (requestData: ISpaceTemplateModificationRequest) => callApi({
    endpoint: 'space_templates/create',
    method: 'PUT',
    requestData
  })
};

export default spaceTemplatesService;
