/* eslint-disable max-len */
import React, { useCallback } from 'react';
import classNames from 'classnames';
import BrandLogoBlack from '@images/brand_logo_black.svg';
import BrandLogoWhite from '@images/brand_logo_white.png';
import { STATIC_SITE_LINKS } from '@root/assets/links/static-site-links';
import styles from './styles.module.scss';

interface IHorizontalLogoProps {
  className?: string;
  white?: boolean;
}

const HorizontalLogo: React.FC<IHorizontalLogoProps> = (
  { className, white }
) => {
  const openLandingPage = useCallback(() => {
    window.location.href = STATIC_SITE_LINKS.HOME;
  }, []);

  return (
    <img
      src={white ? BrandLogoWhite : BrandLogoBlack}
      className={classNames(styles.logo, className)}
      alt="logo"
      onClick={openLandingPage}
      role="presentation"
    />
  );
};

export default HorizontalLogo;
