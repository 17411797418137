import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { IBookingWithSpaces } from '@screens/BookingCheckout/model/BookingCheckout';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { extractBookingData, extractBuildingData, extractToggleFavoriteLoading } from '@screens/BookingCheckout/reducers';
import { toggleFavoriteRoutine, editBookingNotesRoutine } from '@screens/BookingCheckout/routines';
// import Rating from '@screens/BookingCheckout/components/BookingDetails/Rating';
import BookingSpacesSummary from '@screens/BookingCheckout/components/BookingDetails/BookingSpacesSummary';
import OtherNeedsNotes from '@screens/BookingCheckout/components/BookingDetails/OtherNeedsNotes';
import BuildingHeaderInfo from '@screens/BookingCheckout/components/BookingDetails/BuildingHeaderInfo';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import BookingGrandTotal from '@screens/BookingCheckout/components/BookingDetails/BookingGrandTotal';
import { bookingToPeriods } from '@screens/BookingCheckout/helpers/bookingPeriods.helper';
import { getDefaultImageFromBuildingType } from '@helpers/image.placeholder.helper';
import ZoomableImage from '@screens/BuildingDetails/components/ZoomableImage';
import BuildingTags from '@screens/BuildingDetails/components/DescriptionSection/components/BuildingTags';
import s from './styles.module.scss';

export interface IBookingDetailsProps extends IState, IActions, IDetailsCheckoutStepProps {

}

export interface IDetailsCheckoutStepProps {
  proceedToNextStep?: IBindingAction;
}

interface IState {
  bookingWithSpaces: IBookingWithSpaces;
  building: IBuildingForDisplaying;
  loadingFavorite: boolean;
}

export interface IEditBookingNotes {
  bookingId: string;
  note: string;
}

interface IActions {
  toggleFavorite: IBindingCallback1<string>;
  editBookingNotes: IBindingCallback1<IEditBookingNotes>;
}
export interface IBookingDetailsLocationState {
  prevPath?: string;
  search?: string;
}

const BookingDetailsCheckoutStep: React.FC<IBookingDetailsProps> = (
  { bookingWithSpaces, building, toggleFavorite,
    editBookingNotes, loadingFavorite, proceedToNextStep }
) => {
  const location = useLocation<IBookingDetailsLocationState>();
  const [notesText, setNotesText] = useState('');
  const [prevPath, setPrevPath] = useState('');
  const { booking, spaces } = bookingWithSpaces || {};

  useEffect(() => {
    setNotesText(booking?.note);
  }, [booking]);

  useEffect(() => {
    if (location.state?.prevPath) setPrevPath(location.state.prevPath);
  }, [location]);

  const handleClick = () => {
    proceedToNextStep();
    editBookingNotes({
      bookingId: booking.id,
      note: notesText
    });
  };

  const periods = useMemo(() => bookingToPeriods(bookingWithSpaces?.booking), [bookingWithSpaces]);

  return (
    <div className={s.wrapper}>
      <div className={s.details__container}>
        <div className={s.details__left}>
          <ZoomableImage
            src={building?.avatar || getDefaultImageFromBuildingType(building?.type)}
          />
        </div>
        <div className={s.details__right}>
          <BuildingHeaderInfo
            building={building}
            toggleLike={toggleFavorite}
            likeLoading={loadingFavorite}
          />
          {/* <Rating />*/}
          <BuildingTags building={building} />
          <BookingSpacesSummary spaces={spaces || []} booking={booking} periods={periods} />
          <Link
            to={{
              pathname: prevPath,
              state: {
                booking: { ...booking, note: notesText },
                spaces
              },
              search: location.state?.search
            }}
            className={classNames(s.editLink, s.hidden)}
          >
            Edit
          </Link>
          <OtherNeedsNotes notesText={notesText} setNotesText={setNotesText} />
        </div>
      </div>
      <BookingGrandTotal periods={periods} booking={booking} />
      <div className={s.details__buttons}>
        <PrimaryButton onClick={handleClick} content="Confirm & Continue" />
      </div>
    </div>
  );
};

const mapStateToProps: (state) => IState = state => ({
  building: extractBuildingData(state),
  bookingWithSpaces: extractBookingData(state),
  loadingFavorite: extractToggleFavoriteLoading(state)
});

const mapDispatchToProps: IActions = {
  toggleFavorite: toggleFavoriteRoutine,
  editBookingNotes: editBookingNotesRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailsCheckoutStep);
