import React, { useCallback, useEffect, useState } from 'react';
import { Icon, Loader, Modal, ModalContent } from 'semantic-ui-react';
import classNames from 'classnames';
import { IProfileData } from '@screens/NeedsDashboard/Account/model/ProfileData';
import AccountDetailsForm from '@screens/NeedsDashboard/Account/components/AccountDetailsForm';
import { profileDataToForm } from '@screens/NeedsDashboard/Account/containers/AccountPage';
import { IBindingCallback1 } from '@models/Callbacks';
import { IMemberProfile } from '@screens/AdminDashboard/MembersSummary/model/IMemberProfile';
import MemberCard from '@screens/AdminDashboard/MembersSummary/components/MembersSection/MemberCard';
import common from '@screens/NeedsDashboard/styles/common.module.scss';
import MemberBlockModal from '@screens/AdminDashboard/MembersSummary/components/MembersSection/MemberBlockModal';
import s from '@screens/BuildingDetails/components/DescriptionSection/styles.module.scss';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import WritingMessageModal from '@components/MessagesSection/WritingMessageModal';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import DangerButton from '@components/NewDesign/Button/DangerButton';
import styles from './styles.module.scss';

export interface IMemberDetailsModalProps {
  memberDetails?: IMemberProfile;
  hideMemberDetails: IBindingCallback1<void>;
  memberDetailsLoading: boolean;
  saveMemberProfile: IBindingCallback1<IProfileData>;
  saveMemberProfileLoading: boolean;
  blockUser: IBindingCallback1<string>;
  unblockUser: IBindingCallback1<string>;
  blockUserLoading?: boolean;
  unblockUserLoading?: boolean;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  setWriting: IBindingCallback1<boolean>;
  sendMessageLoading: boolean;
  writing: boolean;
}

const MemberDetailsModal: React.FC<IMemberDetailsModalProps> = (
  {
    memberDetails, hideMemberDetails, memberDetailsLoading,
    saveMemberProfile, saveMemberProfileLoading, blockUser, blockUserLoading,
    unblockUser, unblockUserLoading, sendMessage, sendMessageLoading, setWriting,
    writing
  }
) => {
  const isNew = !memberDetails?.id;
  const [editing, setEditing] = useState<boolean>(false);
  const [blockModalShown, setBlockModalShown] = useState<boolean>(false);

  useEffect(() => {
    setEditing(isNew);
  }, [setEditing, isNew, memberDetails]);

  const handleBlock = useCallback(() => {
    if (!memberDetails) {
      return;
    }
    blockUser(memberDetails.id);
    setBlockModalShown(false);
  }, [memberDetails, blockUser]);

  return (
    <>
      <Modal open={!!memberDetails || memberDetailsLoading} onClose={() => hideMemberDetails()} closeIcon>
        <ModalContent className={classNames(common.container, styles.wrapper)}>
          {memberDetailsLoading
            ? (
              <Loader className={styles.loader} active size="large" inline="centered" />
            )
            : (
              <>
                <div className={styles.header}>
                  <h1>Member details</h1>
                  {!isNew && (
                  <div className={styles.edit} onClick={() => setEditing(!editing)}>
                    <Icon name={editing ? 'undo' : 'edit outline'} />
                    {editing ? 'Cancel' : 'Edit'}
                  </div>
                  )}
                </div>
                {editing
                  ? (
                    <AccountDetailsForm
                      saveChanges={saveMemberProfile}
                      savingLoading={saveMemberProfileLoading}
                      initialValuesLoading={memberDetailsLoading}
                      initialFormData={profileDataToForm(memberDetails)}
                      enableEmailModification
                    />
                  )
                  : (
                    <>
                      <MemberBlockModal
                        onClose={() => setBlockModalShown(false)}
                        onBlock={handleBlock}
                        open={blockModalShown}
                      />
                      <MemberCard member={memberDetails} />
                      <div className={styles.buttons_container}>
                        <div className={styles.blockWrapper}>
                          {memberDetails?.blocked
                            ? (
                              <DangerButton
                                content="Unblock user"
                                loading={unblockUserLoading}
                                onClick={() => unblockUser(memberDetails?.id)}
                              />
                            )
                            : (
                              <DangerButton
                                content="Block user"
                                loading={blockUserLoading}
                                onClick={() => setBlockModalShown(true)}
                              />
                            )}
                        </div>
                        <div>
                          <GoToButton to="" onClick={() => setWriting(true)} className={s.contactButton}>
                            Write To User
                          </GoToButton>
                        </div>
                      </div>
                    </>
                  )}
              </>
            )}
        </ModalContent>
      </Modal>
      {writing && (
        <WritingMessageModal
          onClose={() => setWriting(false)}
          onSend={sendMessage}
          sendLoading={sendMessageLoading}
          receiverId={memberDetails?.id}
        />
      )}
    </>
  );
};

export default MemberDetailsModal;
