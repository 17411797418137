import React, { memo } from 'react';
import { FormInputProps } from 'semantic-ui-react/dist/commonjs/collections/Form/FormInput';
import CommonInput, { ICommonInputProps } from '@components/formik/common';
import InputWrapper from '@components/NewDesign/Input';

export type IFormikInputProps = ICommonInputProps<HTMLInputElement, FormInputProps>;

const FormikInput: React.FC<IFormikInputProps> = props => (
  <CommonInput {...props as any} element={InputWrapper} />
);

export default memo(FormikInput);
