import React from 'react';
import DashboardEntry from '@containers/DashboardEntry';
import TermsAndConditionsSideMenu from '@screens/TermsAndConditions/Root/components/SideMenu';
import TermsAndConditionsRouting from '@screens/TermsAndConditions/Root/components/Routing';

const TermsAndConditionsDashboard: React.FC = () => (
  <DashboardEntry sideMenu={TermsAndConditionsSideMenu} routing={TermsAndConditionsRouting} />
);

export default TermsAndConditionsDashboard;
