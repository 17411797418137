import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { IBindingAction } from '@models/Callbacks';

type UseScrollToElementProps = {
  callback?: IBindingAction;
  topMargin?: number;
  scrollTimeout?: number;
} | undefined;

const useScrollToElement = (params?: UseScrollToElementProps) => {
  const location = useLocation();
  const { callback, topMargin = 20, scrollTimeout = 200 } = params ?? {};

  useEffect(() => {
    let timeoutId;

    if (location.hash) {
      const element = document.querySelector(location.hash);
      const headerHeight = document.getElementById('navbar')?.offsetHeight;
      const gap = topMargin ?? 0;

      timeoutId = setTimeout(() => {
        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - headerHeight - gap;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });

          if (typeof callback === 'function') {
            callback();
          }

          window.history.replaceState(null, '', window.location.pathname + window.location.search);
        }
      }, scrollTimeout);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [callback, location, scrollTimeout, topMargin]);
};

export { useScrollToElement };
