import React from 'react';
import styles from './styles.module.scss';
import Banner from '@containers/Banner';

export interface IDashboardEntryProps {
  sideMenu: React.FC<IClassNameable>;
  routing: React.FC;
  enableBanner?: boolean;
}

interface IClassNameable {
  className: string;
}

const DashboardEntry: React.FC<IDashboardEntryProps> = (
  { sideMenu: SideMenu, routing: Routing, enableBanner }
) => (
  <>
    <div className="content_wrapper">
      {enableBanner && <Banner />}
      <div className={styles.container}>
        <SideMenu className={styles.side_menu} />
        <div className={styles.content}>
          <Routing />
        </div>
      </div>
    </div>
  </>
);

export default DashboardEntry;
