import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { visibleToAdmin } from '@helpers/authRules.helper';
import { ADMIN_DASHBOARD_ENDPOINTS } from '@screens/AdminDashboard/Root/components/Routing/endpoints';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';

export interface IAdminEditButtonProps {
  buildingId: string;
}

const AdminEditButton: React.FC<IAdminEditButtonProps> = ({ buildingId }) => {
  const history = useHistory();

  const handleButtonClick = useCallback(() => {
    history.push(ADMIN_DASHBOARD_ENDPOINTS.BUILDING_EDITOR(buildingId));
  }, [buildingId, history]);

  const Btn = useCallback(visibleToAdmin()(
    () => (
      <PrimaryButton
        onClick={handleButtonClick}
        marginZero
      >
        Edit
      </PrimaryButton>
    )
  ), [buildingId]);

  return <Btn />;
};

export { AdminEditButton };
