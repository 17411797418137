import React from 'react';
import { extractUserEmail } from '@screens/Authorization/reducers';
import {
  addNewCardRoutine,
  loadCreditCardsRoutine
} from '@screens/BookingCheckout/routines';
import { connect } from 'react-redux';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import {
  extractAddNewCardError,
  extractAddNewCardLoading,
  extractCreditCards,
  extractLoadCreditCardsLoading
} from '@screens/BookingCheckout/reducers';
import styles from './styles.module.scss';
import { ICreditCard } from '@screens/CreditCardConfiguration/model/CreditCard';
import { ICreateCardRequest } from '@components/StripeAddCardForm';
import StripeCardChoiceForm, { IPaymentMethodWithTitle } from '@components/StripePaymentForm';

export interface IPaymentStepProps extends IState, IActions {
  selectPM: IBindingCallback1<IPaymentMethodWithTitle>;
}

interface IState {
  userEmail: string;
  cardsLoading: boolean;
  creditCards: ICreditCard[];
  addNewCardLoading: boolean;
  addNewCardError?: string;
}

interface IActions {
  loadCreditCards: IBindingAction;
  addNewCard: IBindingCallback1<ICreateCardRequest>;
}

const PaymentInformationStep: React.FC<IPaymentStepProps> = (
  {
    userEmail, loadCreditCards, creditCards, cardsLoading,
    addNewCardError, addNewCard, addNewCardLoading, selectPM
  }
) => (
  <div className={styles.payment_container}>
    <StripeCardChoiceForm
      loadCards={loadCreditCards}
      cardsLoading={cardsLoading}
      creditCards={creditCards}
      newCardProps={{
        addNewCardLoading,
        addNewCardError,
        addNewCard
      }}
      selectPM={selectPM}
      userEmail={userEmail}
    />
  </div>
);

const mapStateToProps: (state) => IState = state => ({
  userEmail: extractUserEmail(state),
  cardsLoading: extractLoadCreditCardsLoading(state),
  creditCards: extractCreditCards(state),
  addNewCardLoading: extractAddNewCardLoading(state),
  addNewCardError: extractAddNewCardError(state)
});

const mapDispatchToProps: IActions = {
  loadCreditCards: loadCreditCardsRoutine,
  addNewCard: addNewCardRoutine
};

export { PaymentInformationStep };

export type IPaymentStepState = IState;
export type IPaymentStepActions = IActions;

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInformationStep);
