/* eslint-disable max-len */
import { createRoutine } from 'redux-saga-routines';

const createFinancialBankingRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`HAVES_DASHBOARD__FINANCIAL_BANKING:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const setBankAccountProcessingRoutine = createFinancialBankingRoutine('SET_BANK_ACCOUNT_PROCESSING');
export const setDefaultAccountDataRoutine = createFinancialBankingRoutine('SET_DEFAULT_ACCOUNT_DATA');
export const verifyBankAccountRoutine = createFinancialBankingRoutine('VERIFY_BANK_ACCOUNT');
export const setVerifyingBankAccountRoutine = createFinancialBankingRoutine('SET_VERIFYING_BANK_ACCOUNT');
export const setAddingBankAccountRoutine = createFinancialBankingRoutine('SET_ADDING_BANK_ACCOUNT');
export const removeHaveAccountDataRoutine = createFinancialBankingRoutine('REMOVE_HAVE_ACCOUNT_DATA');
export const loadHaveAccountsListDataRoutine = createFinancialBankingRoutine('LOAD_HAVE_ACCOUNT_DATA');
export const addBankingDataRoutine = createFinancialBankingRoutine('ADD_BANKING_DATA');
