import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ADMIN_DASHBOARD_ENDPOINTS } from '@screens/AdminDashboard/Root/components/Routing/endpoints';
import AppRoute from '@containers/AppRoute';
import NotFound from '@components/404NotFound';
import Dashboard from '@screens/AdminDashboard/Dashboard/containers/DashboardPage';
import MembersSummary from '@screens/AdminDashboard/MembersSummary/containers/MembersSummaryPage';
import BuildingsAndSpaces from '@screens/AdminDashboard/BuildingsAndSpaces/containers/BuildingsAndSpacesPage';
import Transactions from '@screens/AdminDashboard/Transactions/containers/TransactionsPage';
import MessageCenter from '@screens/AdminDashboard/MessageCenter/containers/MessageCenterPage';
import Payouts from '@screens/AdminDashboard/Payouts/containers/PayoutsPage';
import PayoutsHistory from '@screens/AdminDashboard/Payouts/containers/PayoutsHistoryPage';
import { withOptionalBuilding } from '@screens/NeedsDashboard/Root/components/Routing';
import {
  userEmailActivatedRedir,
  userIsAdminRedir,
  userIsAuthenticatedRedir,
  userSelectedRole
} from '@helpers/authRules.helper';
import BuildingEditor from '@screens/BuildingEditor/containers/BuildingEditorPage';

const AdminDashboardRouting: React.FC = () => {
  const {
    DASHBOARD, MEMBERS, BUILDINGS_AND_SPACES, BUILDING_EDITOR, TRANSACTIONS, MESSAGES, LIST_YOUR_SPACE,
    PAYOUTS, PAYOUTS_HISTORY
  } = ADMIN_DASHBOARD_ENDPOINTS;

  const userValidRedir = component => userIsAuthenticatedRedir(userEmailActivatedRedir(userSelectedRole(component)));

  const BuildingEditorPage = userValidRedir(userIsAdminRedir(BuildingEditor));

  return (
    <Switch>
      <AppRoute lightBlue exact path={DASHBOARD} component={Dashboard} />
      <AppRoute lightBlue exact path={MESSAGES} component={MessageCenter} />
      <AppRoute lightBlue exact path={MEMBERS} component={MembersSummary} />
      <AppRoute lightBlue exact path={BUILDINGS_AND_SPACES} component={withOptionalBuilding(BuildingsAndSpaces)} />
      <AppRoute lightBlue exact path={BUILDING_EDITOR(':id')} component={BuildingEditorPage} />
      <AppRoute lightBlue exact path={TRANSACTIONS} component={Transactions} />
      <AppRoute lightBlue exact path={PAYOUTS} component={Payouts} />
      <AppRoute lightBlue exact path={PAYOUTS_HISTORY} component={PayoutsHistory} />
      <AppRoute lightBlue exact path={LIST_YOUR_SPACE} component={() => <Redirect to={BUILDINGS_AND_SPACES} />} />
      <AppRoute lightBlue component={NotFound} />
    </Switch>
  );
};

export default AdminDashboardRouting;
