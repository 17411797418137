import React, { useEffect } from 'react';
import { IBindingAction } from '@models/Callbacks';

export interface IHtmlScriptProps {
  src?: string;
  content?: string;
  async?: boolean;
  id?: string;
  onLoad?: IBindingAction;
}

const HtmlScript: React.FC<IHtmlScriptProps> = (
  { src, content, async, id, onLoad }
) => {
  useEffect(() => {
    const script = document.createElement('script');
    if (content) {
      script.innerHTML = content;
    }
    if (src) {
      script.src = src;
    }
    if (onLoad) {
      script.onload = onLoad;
    }
    script.async = async;

    const divWrapper = document.createElement('div');
    if (id) {
      divWrapper.id = id;
    }

    divWrapper.appendChild(script);
    document.body.appendChild(divWrapper);
  }, [id, async, content, src, onLoad]);

  return <div />;
};

export default HtmlScript;
