import React from 'react';
import { useHistory } from 'react-router-dom';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import styles from '../styles.module.scss';

const PropertyOwnersManagers: React.FC = () => {
  const history = useHistory();
  return (
    <div className={styles.wrapper}>
      <SubHeader1>Property Owners/Managers</SubHeader1>
      <BodyText2 marginTop>
        If you are a Property Owner or Manager with some idle/vacant spaces that you would like to list with
        Shared Spaces, but don’t have the time to manage all the details of a shared spaces solution, we can help.
      </BodyText2>
      <BodyText2 marginTop>
        We are building a network of Shared Spaces Managed Solutions Partners that have tons of experience in
        logistics, warehouse space management, office space management, etc.
      </BodyText2>
      <BodyText2 marginTop>
        The arrangement is simple: You provide the space, we provide the resources to set-up, and manage the space
        on an ongoing basis, we split the revenue. Everyone wins!
      </BodyText2>
      <div className={styles.buttonWrapper}>
        <PrimaryButton
          onClick={() => history.push(ENDPOINTS.PARTNER_PAGE)}
        >
          LET’S PARTNER!
        </PrimaryButton>
      </div>
    </div>
  );
};

export default PropertyOwnersManagers;
