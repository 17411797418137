import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import { toastr } from 'react-redux-toastr';
import {
  createOrderReceiptAdditionalServicesOrderRoutine,
  fetchServiceOrderReceiptDataRoutine,
  loadOrderReceiptAdditionalServicesWithAvailabilityRoutine
} from '@screens/ReceiptPage/routines';
import { history } from '@helpers/history.helper';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import receiptService from '@screens/ReceiptPage/services/receipt.service';
import {
  tryCreateAdditionalServicesOrder,
  tryLoadAdditionalServicesWithAvailability
} from '@screens/BookingCheckout/containers/BookingRootPage/sagas';

export const tryFetchServiceOrderReceiptData = (routine: Routine) => function* fetchServiceOrderReceiptData({ payload }: Routine<any>) {
  try {
    const response = yield call(receiptService.fetchServiceOrderReceiptData, payload);
    yield put(routine.success(response));
  } catch (e) {
    toastr.error('Failed to load service order receipt data', e?.message);
    yield put(routine.failure(e?.message));
    history.push(ENDPOINTS.BROWSE);
  }
};

export default function* serviceOrderReceiptPagePageSagas() {
  yield all([
    yield takeEvery(
      fetchServiceOrderReceiptDataRoutine.TRIGGER,
      tryFetchServiceOrderReceiptData(fetchServiceOrderReceiptDataRoutine)
    ),
    yield takeEvery(
      createOrderReceiptAdditionalServicesOrderRoutine.TRIGGER,
      tryCreateAdditionalServicesOrder(createOrderReceiptAdditionalServicesOrderRoutine)
    ),
    yield takeEvery(
      loadOrderReceiptAdditionalServicesWithAvailabilityRoutine.TRIGGER,
      tryLoadAdditionalServicesWithAvailability(loadOrderReceiptAdditionalServicesWithAvailabilityRoutine)
    )
  ]);
}
