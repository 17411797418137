import React from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

interface ISmallLinkButtonProps extends LinkProps {
  left?: boolean;
  right?: boolean;
}

const SmallLinkButton: React.FC<ISmallLinkButtonProps> = props => {
  const { className, children, left, right, to, ...restProps } = props;
  const { t } = useTranslation();

  const content = (
    <>
      {left && <Icon name="chevron left" className={styles.left} /> }
      {t(children)}
      {right && <Icon name="chevron right" className={styles.right} /> }
    </>
  );

  return to
    ? (
      <Link
        to={to}
        {...restProps}
        className={classNames(styles.container, className)}
      >
        {content}
      </Link>
    )
    : (
      <div {...restProps as any} className={classNames(styles.container, className)}>
        {content}
      </div>
    );
};

export default SmallLinkButton;
