import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BookingSection from '@screens/BuildingDetails/components/BookingSection';
import {
  extractFetchBuildingDetailsLoading, extractFetchSpaceAvailabilityLoading,
  extractRequestBookingLoading, extractSpaces
} from '@screens/NeedsDashboard/BuildingDetails/reducers';
import { IBindingCallback1 } from '@models/Callbacks';
import { ISpacesAvailabilityRequest } from '@screens/BuildingDetails/model/SpacesAvailabilityRequest';
import { fetchSpaceAvailabilityRoutine, requestBookingRoutine } from '@screens/NeedsDashboard/BuildingDetails/routines';
import { IBookingRequest } from '@screens/BuildingDetails/model/BookingRequest';
import styles from './styles.module.scss';

const SpacesSectionWrapper = () => {
  const dispatch = useDispatch();

  const {
    isBuildingDetailsLoading,
    isBookSpacesRequestLoading,
    spaces,
    spacesLoading
  } = useSelector(state => ({
    isBuildingDetailsLoading: extractFetchBuildingDetailsLoading(state),
    isBookSpacesRequestLoading: extractRequestBookingLoading(state),
    spaces: extractSpaces(state),
    spacesLoading: extractFetchSpaceAvailabilityLoading(state)
  }));

  const handleFetchSpaceAvailability = useCallback<IBindingCallback1<ISpacesAvailabilityRequest>>(payload => {
    dispatch(fetchSpaceAvailabilityRoutine.trigger(payload));
  }, [dispatch]);

  const handleBookSpaces = useCallback<IBindingCallback1<IBookingRequest>>(payload => {
    dispatch(requestBookingRoutine.trigger(payload));
  }, [dispatch]);

  return (
    <BookingSection
      className={styles.booking_margin}
      loading={isBuildingDetailsLoading}
      spaces={spaces}
      fetchSpaceAvailability={handleFetchSpaceAvailability}
      spacesLoading={spacesLoading}
      requestBooking={handleBookSpaces}
      bookingLoading={isBookSpacesRequestLoading}
    />
  );
};

export { SpacesSectionWrapper };
