import React from 'react';
import { Label, LabelProps } from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface ILabelWrapperProps extends LabelProps {
  className?: string;
  transparent?: boolean;
  zeroHorizontalPadding?: boolean;
}

const LabelWrapper = ({ className, transparent, zeroHorizontalPadding, ...props }: ILabelWrapperProps) => (
  <div className={styles.label_wrapper}>
    <Label
      {...props}
      className={classNames(
        styles.basic,
        { [styles.transparent]: transparent,
          [styles.zero_horizontal_padding]: zeroHorizontalPadding
        },
        className
      )}
    />
  </div>
);
export default LabelWrapper;
