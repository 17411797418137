import React, { useCallback, useEffect } from 'react';
import { FormikProps, withFormik } from 'formik';
import { Form } from 'semantic-ui-react';
import _ from 'lodash';
import * as Yup from 'yup';
import FormikInput from '@components/formik/Input';
import FormikSelect from '@components/formik/select/Select';
import { enumAsOptions } from '@helpers/enum.helper';
import { LengthUnit } from '@models/domain/LengthUnit';
import { SpaceType, spaceTypeKey } from '@models/domain/space/SpaceTypes';
import { vagueEquals } from '@screens/BuildingEditor/components/BuildingEditorForm';
import { ISpaceTemplateDto } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import {
  nullablePositiveIntegerValidation, priceValidation, requiredValidation
} from '@screens/Authorization/components/validation';
import { OfficeSpaceCategory } from '@models/domain/space/OfficeSpaceCategory';
import { WarehouseSpaceCategory } from '@models/domain/space/WarehouseSpaceCategory';
import { VirtualAddressSpaceCategory } from '@models/domain/space/VirtualAddressSpaceCategory';
import FormGroupWrapper from '@components/NewDesign/Form/FormGroupWrapper';
import styles from './styles.module.scss';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import { Regex } from '@models/domain/Regex';
import { ParkingSpaceCategory } from '@models/domain/space/ParkingSpaceCategory';

export interface ISpacesEditingFormProps {
  initialData?: ISpaceTemplateDto;
  loadingValues: boolean;
  saveLoading: boolean;
  saveSpaceTemplate: (space: ISpaceTemplateModificationRequest) => void;
  isSpaceTemplateLoaded: boolean;
  className?: string;
}

interface IFormValues {
  alias: string;
  category: string;
  type: string;
  length: string;
  width: string;
  height: string;
  lengthUnit: string;
  price: string;
  amount: string;
}

const defaultFormValues: IFormValues = {
  alias: '',
  category: '',
  type: '',
  length: null,
  width: null,
  height: null,
  lengthUnit: null,
  price: '',
  amount: null
};

export const dtoToForm: (dto: ISpaceTemplateDto) => IFormValues = dto => ({
  lengthUnit: dto.lengthUnit,
  length: dto.length ? dto.length.toString() : null,
  height: dto.height ? dto.height.toString() : null,
  category: dto.category,
  alias: dto.alias,
  type: dto.type,
  width: dto.width ? dto.width.toString() : null,
  price: dto.fullCost.pricePerMonth.toString(),
  amount: dto.amount ? dto.amount.toString() : null
});

const validationSchema = Yup.object().shape({
  alias: requiredValidation,
  price: priceValidation,
  width: nullablePositiveIntegerValidation,
  length: nullablePositiveIntegerValidation,
  height: nullablePositiveIntegerValidation,
  type: Yup.string().required(),
  category: Yup.string().required(),
  lengthUnit: Yup.string().nullable(),
  amount: Yup.string().nullable()
});

export type ISpaceTemplateModificationRequest = IFormValues;

const MAX_SPACES_AMOUNT = 1000;

const SpacesEditingForm: React.FC<ISpacesEditingFormProps & FormikProps<IFormValues>> = (
  {
    handleSubmit, loadingValues, className, values, initialValues,
    saveLoading, errors, setFieldValue, setTouched, touched,
    isSpaceTemplateLoaded, isValid
  }
) => {
  const getCategoryEnum = useCallback(() => {
    switch (values.type) {
      case spaceTypeKey(SpaceType.WAREHOUSE):
        return WarehouseSpaceCategory;
      case spaceTypeKey(SpaceType.OFFICE):
        return OfficeSpaceCategory;
      case spaceTypeKey(SpaceType.PARKING):
        return ParkingSpaceCategory;
      case spaceTypeKey(SpaceType.VIRTUAL_ADDRESS):
        return VirtualAddressSpaceCategory;
      default:
        return {};
    }
  }, [values.type]);

  const areDimensionsRequired = values.type && values.type !== spaceTypeKey(SpaceType.VIRTUAL_ADDRESS);

  useEffect(() => {
    if (!(values.category in getCategoryEnum())) {
      setFieldValue('category', null);
      setTouched({ category: false });
    }
    if (
      values.type === spaceTypeKey(SpaceType.VIRTUAL_ADDRESS)
      && (values.lengthUnit || values.length || values.width || values.height)
    ) {
      setFieldValue('length', null);
      setFieldValue('width', null);
      setFieldValue('height', null);
      setFieldValue('lengthUnit', null);
      setTouched({ length: false, width: false, height: false, lengthUnit: false });
    }
  }, [
    getCategoryEnum, setFieldValue, setTouched, values.category, values.height,
    values.length, values.lengthUnit, values.type, values.width
  ]);

  return (
    <Form onSubmit={handleSubmit} loading={loadingValues} className={className}>
      <div className={styles.form_inputs}>
        <FormikInput
          propsOrFieldName={{
            name: 'alias',
            placeholder: 'Wooden pallet'
          }}
          semanticProps={{
            label: 'Name',
            required: true
          }}
        />
        <FormikSelect
          propsOrFieldName={{
            name: 'type',
            placeholder: 'Space type',
            disabled: !!initialValues?.type
          }}
          semanticProps={{
            options: enumAsOptions(SpaceType),
            required: true
          }}
        />
        {values.type && (
        <FormikSelect
          propsOrFieldName={{
            name: 'category',
            placeholder: 'Space category'
          }}
          semanticProps={{
            options: enumAsOptions(getCategoryEnum()),
            required: true
          }}
        />
        )}
        {areDimensionsRequired && (
        <>
          <FormGroupWrapper widths="equal">
            <FormikInput
              propsOrFieldName={{ name: 'length', placeholder: '2' }}
              semanticProps={{ label: 'Length', required: true, error: touched.length && errors.length }}
            />
            <FormikInput
              propsOrFieldName={{ name: 'height', placeholder: '1' }}
              semanticProps={{ label: 'Height', required: true, error: touched.height && errors.height }}
            />
            <FormikInput
              propsOrFieldName={{ name: 'width', placeholder: '2' }}
              semanticProps={{ label: 'Width', required: true, error: touched.width && errors.width }}
            />
          </FormGroupWrapper>
          <FormikSelect
            propsOrFieldName={{ name: 'lengthUnit', placeholder: 'Meters/Feet' }}
            semanticProps={{
              options: enumAsOptions(LengthUnit),
              className: styles.selector,
              required: true
            }}
          />
        </>
        )}
        <FormikInput
          propsOrFieldName={{
            name: 'price',
            placeholder: '30.00'
          }}
          semanticProps={{
            label: 'Price per month (USD)',
            required: true,
            error: touched.price && errors.price
          }}
        />
        { isSpaceTemplateLoaded || (
          <FormikInput
            propsOrFieldName={{
              name: 'amount',
              placeholder: `1 - ${MAX_SPACES_AMOUNT}`
            }}
            semanticProps={{
              label: 'Number of spaces to add'
            }}
          />
        ) }
      </div>
      <div>
        <PrimaryButton
          className={styles.button}
          type="submit"
          content="Save"
          disabled={_.isEqualWith(initialValues, values, vagueEquals) || !isValid}
          loading={saveLoading}
        />
      </div>
    </Form>
  );
};

export default withFormik<ISpacesEditingFormProps, IFormValues>({
  displayName: 'SpaceEditorForm',
  enableReinitialize: true,
  mapPropsToValues: props => (props.initialData ? dtoToForm(props.initialData) : defaultFormValues),
  handleSubmit: (values, { props }) => props.saveSpaceTemplate(values),
  validationSchema,
  validate: (values, props) => {
    const errors: any = {};

    if (values.type !== spaceTypeKey(SpaceType.VIRTUAL_ADDRESS)) {
      if (!values.length) {
        errors.length = 'Length is required';
      }
      if (!values.height) {
        errors.height = 'Height is required';
      }
      if (!values.width) {
        errors.width = 'Width is required';
      }
      if (!values.lengthUnit) {
        errors.lengthUnit = 'Length unit is required';
      }
    }

    if (!props.isSpaceTemplateLoaded && !values.amount) {
      errors.amount = 'Amount is required';
    }
    if (!props.isSpaceTemplateLoaded && values.amount) {
      if (!values.amount.match(new RegExp(Regex.POSITIVE_INTEGER_REGEX))) {
        errors.amount = 'Number should be positive integer';
      }
      const amount = Number.parseInt(values.amount, 10);
      if (amount > MAX_SPACES_AMOUNT) {
        errors.amount = `Number should be between 1 and ${MAX_SPACES_AMOUNT}`;
      }
    }

    return errors;
  }
})(SpacesEditingForm);
