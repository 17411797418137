import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IBookingCheckoutData } from '@screens/BookingCheckout/model/BookingCheckout';
import {
  clearBookingCheckoutDetailsRoutine,
  editBookingNotesRoutine, loadAdditionalServicesWithAvailabilityRoutine,
  loadBookingDetailsRoutine,
  toggleFavoriteRoutine
} from '@screens/BookingCheckout/routines';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';

export interface IBookingDetailsReducerState {
  bookingDetails: IBookingCheckoutData;
  additionalServices: IAdditionalServiceWithAvailability[];
}

const initialState: IBookingDetailsReducerState = {
  bookingDetails: undefined,
  additionalServices: undefined
};

export const bookingRootPageReducer = createReducer(initialState, {
  [loadBookingDetailsRoutine.SUCCESS]: (state, { payload }: PayloadAction<IBookingCheckoutData>) => {
    state.bookingDetails = payload;
  },
  [toggleFavoriteRoutine.SUCCESS]: state => {
    state.bookingDetails.building.favorite = !state.bookingDetails.building.favorite;
  },
  [editBookingNotesRoutine.SUCCESS]: (state, { payload }: PayloadAction<string>) => {
    state.bookingDetails.booking.booking.note = payload;
  },
  [clearBookingCheckoutDetailsRoutine.FULFILL]: state => {
    state.bookingDetails = undefined;
  },
  [loadAdditionalServicesWithAvailabilityRoutine.SUCCESS]: (
    state, { payload }: PayloadAction<IAdditionalServiceWithAvailability[]>
  ) => {
    state.additionalServices = payload;
  }
});
