import React from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { IBindingCallback1 } from '@models/Callbacks';
import FormikInput from '@components/formik/Input';
import FormikTextArea from '@components/formik/TextArea';
import FormikSelect from '@components/formik/select/Select';
import { enumAsNullableOptions, enumAsOptions } from '@helpers/enum.helper';
import { CountryState } from '@models/domain/CountryState';
import { TypeOfSpace } from '@screens/static/Partner/model/TypeOfSpace';
import { IPartnerFormData } from '@screens/static/Partner/model/IPartnerFormData';
import {
  emailValidation,
  phoneValidation,
  positiveIntegerValidation,
  zipValidation
} from '@screens/Authorization/components/validation';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import InfoPopup from '@components/NewDesign/InfoPopup';
import styles from './styles.module.scss';

export interface IPartnerPageFromProps {
  saveChanges: IBindingCallback1<IPartnerFormData>;
  className?: string;
  partnerLoading?: boolean;
}
export interface IFormValues {
  firstName: string;
  lastName: string;
  companyName: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  typeOfSpace: TypeOfSpace[];
  approximateSquareFootage: string;
  message: string;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('First name is required'),
  lastName: Yup.string()
    .trim()
    .required('Last name is required'),
  companyName: Yup.string()
    .trim()
    .required('Company name is required'),
  email: emailValidation,
  phone: phoneValidation,
  address1: Yup.string().trim().required(),
  address2: Yup.string().nullable(),
  city: Yup.string().trim().required(),
  state: Yup.string().trim().required(),
  zip: zipValidation,
  message: Yup.string()
    .trim()
    .required('Message is required')
    .max(800),
  typeOfSpace: Yup.array().of(Yup.string())
    .required('Type of Space is required')
    .nullable(),
  approximateSquareFootage: positiveIntegerValidation
});

export function nilAreEqual(o1, o2): undefined | boolean {
  if (!o1 && !o2) {
    return true;
  }
  return undefined;
}

const PartnerPageForm: React.FC<IPartnerPageFromProps & FormikProps<IFormValues>> = (
  { handleSubmit, values, initialValues, partnerLoading, isValid }
) => (
  <Form name="getInTouch" onSubmit={handleSubmit} className={styles.form}>
    <Grid>
      <GridRow columns={2}>
        <GridColumn>
          <FormikInput
            propsOrFieldName={{
              name: 'firstName',
              placeholder: 'First Name',
              required: true
            }}
            semanticProps={{
              className: styles.inputField
            }}
          />
        </GridColumn>
        <GridColumn>
          <FormikInput
            propsOrFieldName={{
              name: 'lastName',
              placeholder: 'Last Name',
              required: true
            }}
            semanticProps={{
              className: styles.inputField
            }}
          />
        </GridColumn>
      </GridRow>
      <GridRow columns={2}>
        <GridColumn>
          <FormikInput
            propsOrFieldName={{
              name: 'companyName',
              placeholder: 'Company Name',
              required: true
            }}
            semanticProps={{
              className: styles.inputField
            }}
          />
        </GridColumn>
        <GridColumn>
          <FormikInput
            propsOrFieldName={{
              name: 'phone',
              placeholder: 'Phone No.',
              required: true
            }}
            semanticProps={{
              className: styles.inputField
            }}
          />
        </GridColumn>
      </GridRow>
      <GridRow columns={2}>
        <GridColumn>
          <FormikInput
            propsOrFieldName={{
              name: 'address1',
              placeholder: 'Address Line 1',
              required: true
            }}
            semanticProps={{
              className: styles.inputField
            }}
          />
        </GridColumn>
        <GridColumn>
          <FormikInput
            propsOrFieldName={{
              name: 'address2',
              placeholder: 'Address Line 2'
            }}
            semanticProps={{
              className: styles.inputField
            }}
          />
        </GridColumn>
      </GridRow>
      <GridRow columns={3}>
        <GridColumn width={4}>
          <FormikInput
            propsOrFieldName={{
              name: 'city',
              placeholder: 'City',
              required: true
            }}
            semanticProps={{
              className: styles.inputField
            }}
          />
        </GridColumn>
        <GridColumn width={4}>
          <FormikSelect
            propsOrFieldName={{
              name: 'state',
              placeholder: 'State',
              required: true
            }}
            semanticProps={{
              options: enumAsNullableOptions(CountryState),
              className: styles.inputField
            }}
          />
        </GridColumn>
        <GridColumn width={4}>
          <FormikInput
            propsOrFieldName={{
              name: 'zip',
              placeholder: 'ZIP',
              required: true
            }}
            semanticProps={{
              className: styles.inputField
            }}
          />
        </GridColumn>
      </GridRow>
      <GridRow columns={1}>
        <GridColumn width={8}>
          <FormikInput
            propsOrFieldName={{
              name: 'email',
              placeholder: 'Email Address',
              required: true
            }}
            semanticProps={{
              className: styles.inputField
            }}
          />
        </GridColumn>
      </GridRow>
      <GridRow columns={2}>
        <GridColumn>
          <FormikSelect
            propsOrFieldName={{
              name: 'typeOfSpace',
              multiple: true,
              placeholder: 'Type Of Space',
              required: true
            }}
            semanticProps={{
              options: enumAsOptions(TypeOfSpace),
              className: styles.inputField
            }}
          />
        </GridColumn>
        <GridColumn>
          <FormikInput
            propsOrFieldName={{
              name: 'approximateSquareFootage',
              placeholder: 'Approximate Square Footage',
              required: true
            }}
            semanticProps={{
              className: styles.inputField
            }}
          />
        </GridColumn>
      </GridRow>
      <GridRow columns={1}>
        <GridColumn width={14}>
          <FormikTextArea
            propsOrFieldName={{
              rows: 10,
              name: 'message',
              placeholder: 'Leave your message here',
              required: true
            }}
            semanticProps={{
              className: styles.inputField
            }}
          />
        </GridColumn>
      </GridRow>
    </Grid>
    <div className={styles.button_with_info}>
      <div className={styles.buttonWrapper}>
        <PrimaryButton
          className={styles.form_button}
          content="SUBMIT"
          type="submit"
          loading={partnerLoading}
          disabled={partnerLoading || _.isEqualWith(initialValues, values, nilAreEqual) || !isValid}
        />
      </div>
      {(_.isEqualWith(initialValues, values, nilAreEqual) || !isValid) && (
        <InfoPopup
          iconClassName={styles.info_icon}
          content="Please fill in all the fields to submit the form"
        />
      )}
    </div>
  </Form>
);

export default withFormik<IPartnerPageFromProps, IFormValues>({
  displayName: 'PartnerPageForm',
  enableReinitialize: false,
  validationSchema,
  handleSubmit: ((values, { props }) => {
    props.saveChanges(values);
  })
})(PartnerPageForm);
