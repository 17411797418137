import { createRoutine } from 'redux-saga-routines';

const createStaticPageRoutine = actionName => createRoutine(`STATIC_PAGE:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const submitContactUsRoutine = createStaticPageRoutine('SUBMIT_CONTACT_US');
export const submitJoinOurTeamRoutine = createStaticPageRoutine('SUBMIT_JOIN_OUR_TEAM');
export const submitPartnerPageRoutine = createStaticPageRoutine('SUBMIT_PARTNER_PAGE');
export const submitExpertPageRoutine = createStaticPageRoutine('SUBMIT_EXPERT_PAGE');

