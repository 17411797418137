import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { IBindingCallback1 } from '@models/Callbacks';
import { BookingStatus } from '@models/domain/BookingStatus';
import { IBookingCheckoutData } from '@screens/BookingCheckout/model/BookingCheckout';
import { loadBookingDetailsRoutine } from '@screens/BookingCheckout/routines';
import { extractBookingDetails, extractLoadBookingDetailsLoading } from '@screens/BookingCheckout/reducers';
import BookingDetailsPage from '@screens/BookingCheckout/containers/BookingDetailsPage';
import Checkout from '@screens/Checkout/containers/CheckoutPage';
import BookingDetailsCheckoutStep
  from '@screens/BookingCheckout/containers/BookingCheckoutPage/BookingDetailsCheckoutStep';
import SubmissionAndSummaryStep from '@screens/BookingCheckout/containers/BookingCheckoutPage/SubmissionAndSummaryStep';
import useIsMount from '@root/hooks/use-is-mount';

export interface IBookingDetailsProps extends IState, IActions {
}

interface IState {
  bookingLoading: boolean;
  bookingDetails: IBookingCheckoutData;
}

interface IActions {
  loadBooking: IBindingCallback1<string>;
}

const BookingRootPage: React.FC<IBookingDetailsProps> = (
  { loadBooking, bookingDetails, bookingLoading }
) => {
  const { id: bookingId } = useParams<{id: string}>();
  useEffect(() => {
    loadBooking(bookingId);
  }, [bookingId, loadBooking]);

  const bookingDetailsStep = proceedFromDetails => (
    <BookingDetailsCheckoutStep
      proceedToNextStep={proceedFromDetails}
    />
  );

  const submission = paymentMethod => (
    <SubmissionAndSummaryStep
      bookingId={bookingId}
      paymentMethod={paymentMethod}
    />
  );

  const isMount = useIsMount();
  const loading = isMount || bookingLoading || !bookingDetails;

  return (
    <>
      {loading && <Loader active inline="centered" />}
      {!loading && (
        bookingDetails.booking.booking.status === BookingStatus.PENDING ? (
          <Checkout
            createdAt={bookingDetails.booking.booking.createdAt}
            detailsCheckoutStepComponent={bookingDetailsStep}
            submissionAndSummaryStepComponent={submission}
            buildingName={bookingDetails.building.buildingName}
            buildingDescription={bookingDetails.building.description}
          />
        ) : (
          <BookingDetailsPage />
        )
      )}
    </>
  );
};

const mapStateToProps: (state) => IState = state => ({
  bookingLoading: extractLoadBookingDetailsLoading(state),
  bookingDetails: extractBookingDetails(state)
});

const mapDispatchToProps: IActions = {
  loadBooking: loadBookingDetailsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingRootPage);
