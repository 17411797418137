import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  Placeholder,
  PlaceholderHeader,
  PlaceholderImage,
  PlaceholderLine,
  PlaceholderParagraph
} from 'semantic-ui-react';
import classNames from 'classnames';
import LikeButton from '@components/LikeButton';
import ImageContainer from '@components/ImageContainer';
import { IBuildingListCardProps } from '@screens/BrowseSpaces/components/layouts/cards/BuildingListCard';
import { history } from '@helpers/history.helper';
import defaultImg from '@images/default_250x205.jpg';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import Caption3 from '@components/NewDesign/Typography/Caption/Caption3';
import common from '../common.module.scss';
import styles from './styles.module.scss';

interface IBuildingGridCardProps extends IBuildingListCardProps {
  sponsored?: boolean;
}

const BuildingGridCard: React.FC<IBuildingGridCardProps> = (
  {
    imageSrc, name, liked,
    linkLocation, loading,
    toggleLike, likeLoading, address, sponsored, type
  }
) => {
  const handleLike = () => {
    toggleLike();
  };

  return (
    <Card
      onClick={() => history.push(linkLocation)}
      className={classNames(styles.card, sponsored ? styles.sponsored_card : '')}
      raised={false}
      as="div"
    >
      {loading ? (
        <Placeholder>
          <PlaceholderImage />
        </Placeholder>
      ) : (
        <>
          <ImageContainer
            className={styles.image}
            src={imageSrc || defaultImg}
          />
          <div className={styles.like_container}>
            <LikeButton
              liked={liked}
              onToggleLike={handleLike}
              className={styles.like}
              size="massive"
              loading={likeLoading}
            />
          </div>
          {sponsored ? (
            <div className={styles.sponsored_container}>
              <Caption3>Sponsored Content</Caption3>
            </div>
          ) : ''}
        </>
      )}
      <CardContent className={styles.content}>
        {loading ? (
          <Placeholder>
            <PlaceholderHeader>
              <PlaceholderLine length="short" />
            </PlaceholderHeader>
            <PlaceholderParagraph>
              <PlaceholderLine />
            </PlaceholderParagraph>
          </Placeholder>
        ) : (
          <>
            <CardHeader className={`${styles.name}`} title={name}>
              <Link to={linkLocation} className={styles.link} onClick={ev => ev.preventDefault()}>
                <BodyText1 disableTranslation className={common.no_overflow}>{name}</BodyText1>
              </Link>
            </CardHeader>
            <CardDescription className={`${styles.description}`} title={type}>
              <BodyText2 disableTranslation className={classNames(styles.address, common.no_overflow)}>
                {address}
              </BodyText2>
              <BodyText2 className={classNames(styles.type, common.no_overflow)}>{type}</BodyText2>
            </CardDescription>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default BuildingGridCard;
