import React, { memo } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import styles from './styles.module.scss';

const DropdownWrapper: React.FC<DropdownProps> = props => (
  <div className={styles.dropdown_wrapper}>
    <Dropdown {...props} />
  </div>
);
export default memo(DropdownWrapper);
