import React, { ChangeEvent } from 'react';
import { filterStringNumeric } from '@helpers/string.helper';
import { ISearchSelector } from '../model/SearchSelector';
import styles from './styles.module.scss';
import InputWrapper from '@components/NewDesign/Input';
import { useTranslation } from '@root/hooks/use-translation';

export type ISpaceAmountSelectorProps = ISearchSelector

const SpaceAmountSelector: React.FC<ISpaceAmountSelectorProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const inputToAmount = (val: string) => {
    const strNumbers = filterStringNumeric(val);
    return strNumbers
      ? Number.parseInt(strNumbers, 10)
      : undefined;
  };

  const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(inputToAmount(e.target.value));
  };

  return (
    <div className={styles.container}>
      <InputWrapper
        fluid
        placeholder={`${t('Amount')}...`}
        onChange={e => handleChangeAmount(e)}
        value={value || ''}
      />
    </div>
  );
};

export default SpaceAmountSelector;
