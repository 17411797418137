import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalContent } from 'semantic-ui-react';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { ISpaceTemplateDto } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import {
  extractChosenSpace,
  extractFetchSpaceDetailsLoading, extractFetchSpacesBySpaceTemplateLoading,
  extractSaveSpaceTemplateLoading, extractSpaces,
  extractSpaceTemplate, extractSaveSpaceLoading
} from '@screens/SpaceEditor/reducers';
import {
  chooseSpaceRoutine,
  fetchSpaceDetailsRoutine,
  fetchSpacesBySpaceTemplateRoutine, hideSpaceRoutine,
  saveSpaceTemplateRoutine, saveSpaceRoutine, clearSpacesRoutine
} from '@screens/SpaceEditor/routines';
import { ISaveRequest } from '@screens/SpaceEditor/containers/SpaceEditorPage/sagas';
import { ISpaceDto } from '@screens/SpaceEditor/models/ISpaceDto';
import { ISpaceEdit } from '@screens/SpaceEditor/models/ISpaceEdit';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import SpacesManagementPage from '@components/NewDesign/Spaces/SpacesManagementPage';
import { extractBuilding } from '@screens/BuildingEditor/reducers';
import { IBuildingForEditing } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { fetchBuildingDetailsRoutine } from '@screens/BuildingEditor/routines';

export interface ISpaceEditorModalProps extends IState, IActions {
  spaceEditor: string;
  setSpaceEditor: any;
}

interface IState {
  saveLoading: boolean;
  spaceTemplateLoading: boolean;
  spaceTemplate: ISpaceTemplateDto;
  building: IBuildingForEditing;
}

interface IActions {
  saveTemplateSpace: IBindingCallback1<ISaveRequest>;
  loadSpaceTemplate: IBindingCallback1<string>;
  saveSpace: IBindingCallback1<ISpaceEdit>;
  loadSpaces: IBindingCallback1<string>;
  chooseSpace: IBindingCallback1<Partial<ISpaceDto>>;
  hideSpace: IBindingAction;
  clearSpaces: IBindingAction;
  loadBuilding: IBindingCallback1<string>;
}

const SpaceEditorModal: React.FC<ISpaceEditorModalProps> = (
  { saveTemplateSpace, spaceTemplate, saveLoading, spaceTemplateLoading,
    spaceEditor, setSpaceEditor, loadSpaceTemplate, loadSpaces, clearSpaces, building, loadBuilding
  }
) => {
  useEffect(() => {
    if (spaceEditor !== undefined) {
      loadSpaceTemplate(spaceEditor);
    }
  }, [loadSpaceTemplate, spaceEditor]);

  return (
    <Modal
      open={!!spaceEditor}
      onClose={() => {
        clearSpaces();
        setSpaceEditor(undefined);
        loadBuilding(building?.id);
      }}
      closeOnEscape={false}
      size="small"
      closeIcon
    >
      <ModalHeaderWrapper>Edit space</ModalHeaderWrapper>
      <ModalContent>
        <SpacesManagementPage
          isSpaceTemplateLoaded={!!spaceTemplate}
          saveSpaceTemplate={data => saveTemplateSpace({ id: spaceEditor, data })}
          initialData={spaceTemplate}
          loadingValues={spaceTemplateLoading}
          saveLoading={saveLoading}
        />
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps: (state) => IState = state => ({
  saveLoading: extractSaveSpaceTemplateLoading(state),
  spaceTemplateLoading: extractFetchSpaceDetailsLoading(state),
  spaceTemplate: extractSpaceTemplate(state),
  spacesLoading: extractFetchSpacesBySpaceTemplateLoading(state),
  spaces: extractSpaces(state),
  chosenSpace: extractChosenSpace(state),
  saveSpaceLoading: extractSaveSpaceLoading(state),
  building: extractBuilding(state)
});

const mapDispatchToProps: IActions = {
  loadSpaceTemplate: fetchSpaceDetailsRoutine,
  loadSpaces: fetchSpacesBySpaceTemplateRoutine,
  saveSpace: saveSpaceRoutine,
  saveTemplateSpace: saveSpaceTemplateRoutine,
  loadBuilding: fetchBuildingDetailsRoutine,
  chooseSpace: chooseSpaceRoutine.fulfill,
  hideSpace: hideSpaceRoutine.fulfill,
  clearSpaces: clearSpacesRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(SpaceEditorModal);
