/* eslint-disable @typescript-eslint/camelcase */
import { CreateTokenBankAccountData } from '@stripe/stripe-js';

export interface IBankingData {
  bankAccountNumber: string;
  bankRoutingNumber: string;
  beneficiaryName: string;
}

export const bankingDataToStripeAccountRequest = (data: IBankingData): CreateTokenBankAccountData => ({
  country: 'US',
  currency: 'USD',
  routing_number: data.bankRoutingNumber,
  account_number: data.bankAccountNumber,
  account_holder_name: data.beneficiaryName,
  account_holder_type: 'individual'
});
