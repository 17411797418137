import React from 'react';
import {
  iconOfScheduleStatus,
  ScheduleStatus,
  statusToDisplayText
} from '@models/domain/schedule/ScheduleStatus';
import LabelWrapper from '@components/NewDesign/LabelWrapper';
import styles from './styles.module.scss';

export interface IScheduleStatusLabelProps {
  status: ScheduleStatus;
}

const scheduleStatusToStyle = (status: ScheduleStatus): string => styles[status.toString().toLowerCase()];

const ScheduleStatusLabel: React.FC<IScheduleStatusLabelProps> = ({ status }) => (
  <LabelWrapper
    transparent
    zeroHorizontalPadding
    content={statusToDisplayText(status)}
    icon={iconOfScheduleStatus(status)}
    className={scheduleStatusToStyle(status)}
  />
);

export { ScheduleStatusLabel };
