import { createReducer } from '@reduxjs/toolkit';
import {
  fetchAllTagsRoutine,
  fetchBuildingsSummaryRoutine,
  setPageRoutine
} from '@screens/AdminDashboard/BuildingsAndSpaces/routines';
import { getInitialPageableState } from '@models/domain/PageableReducerState';
import { IBuildingsSummaryShort } from '@screens/AdminDashboard/BuildingsAndSpaces/model/IBuildingsSummaryShort';

const initialState = {
  list: getInitialPageableState<IBuildingsSummaryShort>(),
  details: undefined,
  tags: []
};

export const buildingsAndSpacesReducer = createReducer(initialState, {
  [fetchBuildingsSummaryRoutine.SUCCESS]: (state, { payload }) => {
    state.list.items = payload.items;
    state.list.totalResults = payload.totalResults;
    state.list.totalPages = payload.totalPages;
  },
  [setPageRoutine.FULFILL]: (state, { payload }) => {
    state.list.page = payload;
  },
  [fetchAllTagsRoutine.SUCCESS]: (state, { payload }) => {
    state.tags = payload.map(t => t.name);
  }
});
