import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Label, LabelProps, Modal, ModalContent, Table, TableBody } from 'semantic-ui-react';
import moment from 'moment';
import { DATE_FORMAT } from '@helpers/date.helper';
import { toPrice } from '@helpers/price.helper';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import FormPlaceholder from '@components/NewDesign/Typography/FormAndTable/FormPlaceholder';
import formPlaceholderStyles from '@components/NewDesign/Typography/FormAndTable/FormPlaceholder/styles.module.scss';
import TransparentButton from '@components/NewDesign/Button/TransparentButton';
import { TransactionType } from '@models/domain/transaction/TransactionType';
import { ITransactionDetailsDto } from '@screens/AdminDashboard/Transactions/model/TransactionDetailsDto';
import TransactionTableRow from '@screens/NeedsDashboard/Dashboard/components/TransactionsSection/TransactionTableRow';
import { linkWithStripeDomain, STRIPE_ENDPOINTS } from '@helpers/stripe.link.helper';
import styles from './styles.module.scss';

export interface IAdminTransactionDetailsModalProps {
  expandedTransaction?: ITransactionDetailsDto;
  setExpandedTransaction: (transaction: ITransactionDetailsDto) => void;
}

const AdminTransactionDetailsModal: React.FC<IAdminTransactionDetailsModalProps> = (
  { expandedTransaction, setExpandedTransaction }
) => {
  const history = useHistory();

  const handleOpenReceipt = useCallback((transaction: ITransactionDetailsDto) => {
    if (transaction.type === TransactionType.BOOKING) {
      history.push(ENDPOINTS.BOOKING_RECEIPT(expandedTransaction.id));
    } else if (transaction.type === TransactionType.SERVICE) {
      history.push(ENDPOINTS.SERVICE_ORDER_RECEIPT(expandedTransaction.id));
    } else {
      throw new Error();
    }
  }, [expandedTransaction, history]);

  return (
    <Modal
      open={!!expandedTransaction}
      onClose={() => setExpandedTransaction(undefined)}
      closeIcon
      size="small"
    >
      <ModalHeaderWrapper>Transaction</ModalHeaderWrapper>
      <ModalContent>
        <div className={styles.content}>
          <Table>
            <TableBody>
              <TransactionTableRow
                label="ID:"
                element={(
                  <FormPlaceholder>
                    {expandedTransaction?.id}
                  </FormPlaceholder>
                )}
              />
              <TransactionTableRow
                label="Type:"
                element={(
                  <FormPlaceholder>
                    {expandedTransaction?.type}
                  </FormPlaceholder>
                )}
              />
              <TransactionTableRow
                label="Scheduled On:"
                element={(
                  <FormPlaceholder>
                    {moment(expandedTransaction?.paymentInitialDate).format(DATE_FORMAT)}
                  </FormPlaceholder>
                )}
              />
              <TransactionTableRow
                label="Price:"
                element={(
                  <FormPlaceholder>
                    {expandedTransaction?.price && (`${toPrice(expandedTransaction?.price)}`)}
                  </FormPlaceholder>
                )}
              />
              <TransactionTableRow
                label="Paid:"
                element={expandedTransaction?.paid
                  ? <ValueLabel content="YES" color="green" />
                  : <ValueLabel content="NO" color="red" />}
              />
              {expandedTransaction?.dateTime ? (
                <TransactionTableRow
                  label="Paid on:"
                  element={(
                    <FormPlaceholder>
                      {expandedTransaction?.dateTime
                        && moment(expandedTransaction.dateTime).format(`HH:mm:ss ${DATE_FORMAT}`)}
                    </FormPlaceholder>
                    )}
                />
              ) : null}
              {expandedTransaction?.stripeId ? (
                <TransactionTableRow
                  label="Provider:"
                  element={(
                    <>
                      {expandedTransaction?.stripeId && (
                        <a
                          href={linkWithStripeDomain(STRIPE_ENDPOINTS.PAYMENTS(expandedTransaction?.stripeId))}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ValueLabel color="purple" content="Stripe" />
                        </a>
                      )}
                      {expandedTransaction?.paypalId && (<ValueLabel color="blue" content="Paypal" />)}
                    </>
                    )}
                />
              ) : null}
              {expandedTransaction?.stripeId ? (
                <TransactionTableRow
                  label="Stripe Id:"
                  element={(
                    <FormPlaceholder>
                      {expandedTransaction.stripeId}
                    </FormPlaceholder>
                  )}
                />
              ) : null}
              {expandedTransaction?.paypalId ? (
                <TransactionTableRow
                  label="PayPal Id:"
                  element={(
                    <FormPlaceholder>
                      {expandedTransaction.paypalId}
                    </FormPlaceholder>
                  )}
                />
              ) : null}
              {expandedTransaction?.stripeFee ? (
                <TransactionTableRow
                  label="Stripe Fee:"
                  element={(
                    <FormPlaceholder>
                      {toPrice({
                        amount: expandedTransaction.stripeFee,
                        currency: expandedTransaction.price.currency
                      })}
                    </FormPlaceholder>
                  )}
                />
              ) : null}
              <TransactionTableRow
                label="Transferred:"
                element={expandedTransaction?.transferred
                  ? <ValueLabel content="YES" color="green" />
                  : <ValueLabel content="NO" color="red" />}
              />
              {expandedTransaction?.transferStripeId ? (
                <TransactionTableRow
                  label="Transfer Stripe Id:"
                  element={(
                    <FormPlaceholder>
                      {expandedTransaction.transferStripeId}
                    </FormPlaceholder>
                  )}
                />
              ) : null}
              {expandedTransaction?.transferStripeFee ? (
                <TransactionTableRow
                  label="Transfer Stripe Fee:"
                  element={(
                    <FormPlaceholder>
                      {toPrice({
                        amount: expandedTransaction.transferStripeFee,
                        currency: expandedTransaction.price.currency
                      })}
                    </FormPlaceholder>
                  )}
                />
              ) : null}
              {expandedTransaction?.transferAmount ? (
                <TransactionTableRow
                  label="Transfer Amount:"
                  element={(
                    <FormPlaceholder>
                      {toPrice({
                        amount: expandedTransaction.transferAmount,
                        currency: expandedTransaction.price.currency
                      })}
                    </FormPlaceholder>
                  )}
                />
              ) : null}
              <TransactionTableRow
                label="Available:"
                element={expandedTransaction?.available
                  ? <ValueLabel content="YES" color="green" />
                  : <ValueLabel content="NO" color="red" />}
              />
              <TransactionTableRow
                label="Paid Out:"
                element={expandedTransaction?.paidOut
                  ? <ValueLabel content="YES" color="green" />
                  : <ValueLabel content="NO" color="red" />}
              />
              {expandedTransaction?.payoutAmount ? (
                <TransactionTableRow
                  label="Payout Amount:"
                  element={(
                    <FormPlaceholder>
                      {toPrice({
                        amount: expandedTransaction.payoutAmount,
                        currency: expandedTransaction.price.currency
                      })}
                    </FormPlaceholder>
                  )}
                />
              ) : null}
            </TableBody>
          </Table>
          <div className={styles.buttons_container}>
            <div className={styles.open_receipt_btn_container}>
              <TransparentButton
                onClick={() => handleOpenReceipt(expandedTransaction)}
              >
                Open Receipt
              </TransparentButton>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

const ValueLabel = ({ color, content }: LabelProps) => (
  <Label color={color} content={content} className={formPlaceholderStyles.container} />
);

export default AdminTransactionDetailsModal;
