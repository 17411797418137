import React from 'react';
import {
  IInformationSectionPropsExtended,
  InformationSection
} from '@components/InformationSection';
import { IMessagesSectionWrapperProps } from '@components/MessagesSection/SectionWrapper';
import { IMessageDto } from '@models/domain/message/MessageDto';
import { IBindingCallback1 } from '@models/Callbacks';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import NoDataContainer from '@components/NoDataContainer';
import MessageRow from '@components/NewDesign/MessageSection/MessageRow';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import MessageTableWrapper from '@components/NewDesign/MessageSection/MessageSectionWrapper';
import styles from './styles.module.scss';

export interface IMessagesSectionProps extends IInformationSectionPropsExtended<IMessageDto, IMessagesSectionWrapperProps> {
  writing: boolean;
  setWriting: IBindingCallback1<boolean>;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  sendMessageLoading: boolean;
  readMessage: IBindingCallback1<string>;
  messageFull?: IMessageDto;
  setMessageFull: IBindingCallback1<IMessageDto | undefined>;
  messageCenterLink?: string;
}

const MessagesSection: React.FC<IMessagesSectionProps> = (
  {
    writing, setWriting, sendMessageLoading, sendMessage, readMessage,
    setMessageFull, messageFull, messageCenterLink, ...props }
) => {

  return (
    <InformationSection
      {...props}
      renderItem={m => (
        <MessageRow
          key={m.id}
          message={m}
          readMessage={readMessage}
          setMessageFull={setMessageFull}
        />
      )}
      itemsWrapper={MessageTableWrapper}
      itemsWrapperProps={{
        writing,
        setWriting,
        sendMessage,
        sendMessageLoading,
        messageFull,
        setMessageFull,
        messageCenterLink
      }}
      noItemsPlaceholder={() => <NoDataContainer message="No items" className={styles.messageSection} />}
      loadMoreComponent={() => (
        <GoToButton
          to={HAVES_DASHBOARD_ENDPOINTS.MESSAGES}
        >
          GO TO MESSAGE CENTER
        </GoToButton>
      )}
    />
  );
};

export default MessagesSection;
