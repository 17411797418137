import React from 'react';
import { Button, DropdownItemProps, Icon, Menu, MenuItem } from 'semantic-ui-react';
import _ from 'lodash';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { PageLayout } from '@screens/BrowseSpaces/model/PageLayout';
import { PageSize } from '@screens/BrowseSpaces/model/PageSize';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import PageSizeDropdownWrapper from '@components/NewDesign/Dropdown/PageSizeDropdown';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

export interface ILayoutSelectorProps {
  resultsNumber: number;
  setLayout: IBindingCallback1<PageLayout>;
  setPageSize: IBindingCallback1<PageSize>;
  activeLayout: PageLayout;
  pageSize: PageSize | string;
  isShowSearchBar?: boolean;
  toggleHideSearchBar?: IBindingAction;
}

const LayoutSelector: React.FC<ILayoutSelectorProps> = (
  { resultsNumber, setLayout, setPageSize, activeLayout, pageSize,
    isShowSearchBar, toggleHideSearchBar
  }
) => {
  const { t } = useTranslation();

  const pageSizeOptions: DropdownItemProps[] = Object.keys(PageSize).filter(k => _.isNumber(PageSize[k]))
    .map(sizeName => ({
      key: sizeName,
      value: sizeName,
      text: `${PageSize[sizeName]} ${t('per page')}`
    }));

  return (
    <>
      <label className={styles.filtersLabel} htmlFor="touch">
        <div className={styles.filtersText}>
          <Icon name="filter" size="small" />
          {t(isShowSearchBar ? 'Close filters' : 'Filters')}
        </div>
      </label>
      <input type="checkbox" id="touch" checked={isShowSearchBar} onChange={toggleHideSearchBar} />
      <Menu className={styles.menu} compact text>
        <Button
          icon
          className={styles.button}
          active={activeLayout === PageLayout.LIST}
          onClick={() => setLayout(PageLayout.LIST)}
        >
          <Icon name="list layout" />
        </Button>
        <Button
          icon
          className={styles.button}
          active={activeLayout === PageLayout.GRID}
          onClick={() => setLayout(PageLayout.GRID)}
        >
          <Icon name="block layout" />
        </Button>
        <MenuItem className={styles.results_amount}>
          <Caption2 className={styles.caption}>{`${resultsNumber} results`}</Caption2>
        </MenuItem>
        <PageSizeDropdownWrapper
          className={styles.dropdown}
          item
          placeholder="Results per page"
          onChange={(ev, { value }) => setPageSize(PageSize[value as string])}
          value={PageSize[pageSize]}
          options={pageSizeOptions}
        />
      </Menu>
    </>
  );
};

export default LayoutSelector;
