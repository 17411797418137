import React from 'react';
import styles from './styles.module.scss';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';

export type ITextLinkProps = LinkProps

const TextLink: React.FC<ITextLinkProps> = (
  { children, className, to }
) => (
  <Link
    to={to}
    className={classNames(styles.container, className)}
  >
    {children}
  </Link>
);

export default TextLink;
