import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export interface ITilesWrapperProps {
  defaultBorder?: boolean;
}

const TilesWrapper: React.FC<ITilesWrapperProps> = (
  { children, defaultBorder }
) => (
  <div className={classNames(styles.container, defaultBorder && styles.border)}>{children}</div>
);

export default TilesWrapper;
