import React from 'react';
import styles from './styles.module.scss';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';

export interface IBuildingDescriptionProps {
  text: string;
}

const BuildingDescription: React.FC<IBuildingDescriptionProps> = (
  { text }
) => (
  <BodyText2 className={styles.container}>
    {text}
  </BodyText2>
);

export default BuildingDescription;
