import React, { memo } from 'react';
import { env } from '@root/env';
import HtmlScript from '@components/HtmlScript';

export interface IFacebookPixelScriptProps {
}

const FacebookPixelScript: React.FC<IFacebookPixelScriptProps> = () => {
  if (!env.facebookPixelId) {
    return null;
  }

  const content = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${env.facebookPixelId}');
    fbq('track', 'PageView');
  `;

  const imgLink = `https://www.facebook.com/tr?id=${env.facebookPixelId}&ev=PageView&noscript=1`;

  return (
    <>
      <HtmlScript content={content} />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src={imgLink}
        />
      </noscript>
    </>
  );
};

export default memo(FacebookPixelScript);
