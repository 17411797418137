import React, { useCallback } from 'react';
import { Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import { IBindingAction } from '@root/models/Callbacks';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import DangerButton from '@components/NewDesign/Button/DangerButton';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import styles from './styles.module.scss';

export interface IConfirmationModalProps {
  open: boolean;
  onClose: IBindingAction;
  onConfirm: IBindingAction;
  title?: string;
  text?: string;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmationModal: React.FC<IConfirmationModalProps> = (
  { open, onClose, onConfirm,
    title = 'Confirmation',
    text = 'Are you sure that you want to confirm this action',
    confirmText = 'Confirm',
    cancelText = 'Cancel'
  }
) => {
  const handleConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, [onConfirm, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="small"
      closeIcon
    >
      <ModalHeaderWrapper>
        {title}
      </ModalHeaderWrapper>
      <ModalContent>
        <BodyText2>
          {text}
        </BodyText2>
      </ModalContent>
      <ModalActions>
        <div className={styles.buttons}>
          <div>
            <DangerButton
              onClick={onClose}
              content={cancelText}
            />
          </div>
          <div>
            <PrimaryButton
              onClick={handleConfirm}
              content={confirmText}
            />
          </div>
        </div>
      </ModalActions>
    </Modal>
  );
};

export default ConfirmationModal;
