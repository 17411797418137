import React, { useCallback, useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Role } from '@screens/Authorization/models/Roles';
import { STATIC_SITE_LINKS } from '@root/assets/links/static-site-links';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import { IBindingAction } from '@models/Callbacks';
import NeedsSideMenu from '@screens/NeedsDashboard/Root/components/SideMenu';
import AdminSideMenu from '@screens/AdminDashboard/Root/components/SideMenu';
import HavesSideMenu from '@screens/HavesDashboard/Root/components/SideMenu';
import { NavLinkWithTranslate } from '@components/WithTranslate/NavLinkWithTranslate';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

export interface IHeaderProps {
  role: Role;
  loggedIn: boolean;
  listYourSpaceLink: string;
  handleLogOut: IBindingAction;
}

const HamburgerMenu: React.FC<IHeaderProps> = ({
  role,
  loggedIn,
  listYourSpaceLink,
  handleLogOut
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isShowDropdownAbout, setIsShowDropdownAbout] = useState(false);
  const [isShowDropdownProfile, setIsShowDropdownProfile] = useState(false);

  useEffect(() => {
    if (!isShowMenu) {
      setIsShowDropdownAbout(false);
      setIsShowDropdownProfile(false);
    }
  }, [isShowMenu]);

  useEffect(() => {
    if (isShowMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isShowMenu]);

  const hideMenu = useCallback(() => setIsShowMenu(false), []);
  const toggleHideMenu = () => setIsShowMenu(!isShowMenu);
  const toggleHideDropdownAbout = () => setIsShowDropdownAbout(!isShowDropdownAbout);
  const toggleHideDropdownProfile = () => setIsShowDropdownProfile(!isShowDropdownProfile);

  const renderDashboardMenu = () => {
    switch (role) {
      case Role.ADMIN:
        return (
          <div className={styles.li}>
            <AdminSideMenu
              className={styles.sideMenu}
              linkClassName={styles.link}
              activeLinkClassName={styles.activeLink}
              onItemClick={hideMenu}
            />
          </div>
        );
      case Role.HAVE:
        return (
          <div className={styles.li}>
            <HavesSideMenu
              className={styles.sideMenu}
              linkClassName={styles.link}
              activeLinkClassName={styles.activeLink}
              onItemClick={hideMenu}
            />
          </div>
        );
      case Role.NEED:
        return (
          <div className={styles.li}>
            <NeedsSideMenu
              className={styles.sideMenu}
              linkClassName={styles.link}
              activeLinkClassName={styles.activeLink}
              onItemClick={hideMenu}
            />
          </div>
        );
      default:
        return '';
    }
  };

  return (
    <div className={styles.hamburgerMenu}>
      <menu>
        <label htmlFor="trigger">
          <input id="trigger" type="checkbox" checked={isShowMenu} onChange={toggleHideMenu} />
          <section className={styles.drawerList}>
            <div className={styles.ul}>
              {location.pathname.startsWith('/user') ? (
                <>
                  {renderDashboardMenu()}
                  <br />
                </>
              ) : ''}
              <div className={styles.li}>
                <NavLinkWithTranslate
                  exact
                  to={ENDPOINTS.BROWSE}
                  className={styles.link}
                  activeClassName={styles.boldLink}
                  onClick={hideMenu}
                >
                  Browse Spaces
                </NavLinkWithTranslate>
              </div>
              <div className={styles.li}>
                {role !== Role.NEED && (
                  <NavLinkWithTranslate
                    to={listYourSpaceLink}
                    className={styles.link}
                    activeClassName={styles.boldLink}
                    onClick={hideMenu}
                  >
                    List Your Space
                  </NavLinkWithTranslate>
                )}
              </div>
              <div className={styles.li}>
                <NavLinkWithTranslate
                  to={STATIC_SITE_LINKS.HOW_IT_WORKS}
                  className={styles.link}
                  activeClassName={styles.boldLink}
                  onClick={hideMenu}
                >
                  How it Works
                </NavLinkWithTranslate>
              </div>
              <div className={styles.li}>
                <div className={styles.dropdown}>
                  <nav className={styles.nav}>
                    <label htmlFor="touch_aboutUs">
                      <div className={styles.navText}>{t('About Us')}</div>
                    </label>
                    <input
                      type="checkbox"
                      id="touch_aboutUs"
                      checked={isShowDropdownAbout}
                      onChange={toggleHideDropdownAbout}
                    />
                    <div className={styles.slide}>
                      <div className={styles.li}>
                        <NavLinkWithTranslate
                          to={STATIC_SITE_LINKS.PARTNER_WITH_US}
                          className={styles.subLink}
                          onClick={hideMenu}
                        >
                          Partner with Us
                        </NavLinkWithTranslate>
                      </div>
                      <div className={styles.li}>
                        <NavLinkWithTranslate
                          to={STATIC_SITE_LINKS.JOIN_OUR_TEAM}
                          className={styles.subLink}
                          onClick={hideMenu}
                        >
                          Join our Team
                        </NavLinkWithTranslate>
                      </div>
                      <div className={styles.li}>
                        <NavLinkWithTranslate
                          to={STATIC_SITE_LINKS.OUR_STORY}
                          className={styles.subLink}
                          onClick={hideMenu}
                        >
                          Our Story
                        </NavLinkWithTranslate>
                      </div>
                      <div className={styles.li}>
                        <NavLinkWithTranslate
                          to={STATIC_SITE_LINKS.CONTACT_US}
                          className={styles.subLink}
                          onClick={hideMenu}
                        >
                          Contact Us
                        </NavLinkWithTranslate>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div className={styles.li}>
                { loggedIn
                  ? (
                    <span className={styles.dropdown}>
                      <nav className={styles.nav}>
                        <label htmlFor="touch_profile">
                          <div className={styles.navText}>{t('Profile')}</div>
                        </label>
                        <input
                          type="checkbox"
                          id="touch_profile"
                          checked={isShowDropdownProfile}
                          onChange={toggleHideDropdownProfile}
                        />
                        <div className={styles.slide}>
                          <div className={styles.li}>
                            <NavLink
                              to={NEEDS_DASHBOARD_ENDPOINTS.DASHBOARD}
                              className={styles.subLink}
                              onClick={hideMenu}
                            >
                              <Icon name="cog" />
                              {t('Dashboard')}
                            </NavLink>
                          </div>
                          <div className={styles.li}>
                            <NavLinkWithTranslate
                              to={STATIC_SITE_LINKS.CONTACT_US}
                              className={styles.subLink}
                              onClick={hideMenu}
                              disableTranslation
                            >
                              <Icon name="question circle" />
                              {t('Help')}
                            </NavLinkWithTranslate>
                          </div>
                          <div className={styles.li}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                            <a onClick={handleLogOut} className={styles.subLink}>
                              <Icon name="sign-out" />
                              {t('Log Out')}
                            </a>
                          </div>
                        </div>
                      </nav>
                    </span>
                  )
                  : (
                    <NavLinkWithTranslate to="/login" className={classNames(styles.link)}>Log in</NavLinkWithTranslate>
                  )}
              </div>
            </div>
          </section>
          <div className={styles.hamburger}>
            <i />
          </div>
        </label>
      </menu>
    </div>
  );
};

export default HamburgerMenu;
