export const dashboard = path => `/user${path === '' ? '' : `/${path}`}`;
export const LIST_YOUR_SPACE_COMMON_ENDPOINT = dashboard('list_your_space');

export const HAVES_DASHBOARD_ENDPOINTS = {
  DASHBOARD: dashboard(''),
  MESSAGES: dashboard('messages'),
  ACCOUNT: dashboard('account'),
  TRANSACTIONS: dashboard('transactions'),
  BUILDINGS: dashboard('buildings'),
  ADDITIONAL_SERVICES: dashboard('additional_services'),
  ORDERS: dashboard('orders'),
  SCHEDULE: dashboard('schedule'),
  FAVORITES: dashboard('favorites'),
  FINANCIAL: dashboard('financial'),
  LIST_YOUR_SPACE: LIST_YOUR_SPACE_COMMON_ENDPOINT,
  BUILDING_EDITOR: id => dashboard(`building/edit/${id}`)
};
