import React, { useCallback, useState } from 'react';
import { ITransaction } from '@models/domain/Transaction';
import { IInformationSectionProps } from '@components/InformationSection';
import TransactionRow from '@screens/NeedsDashboard/Dashboard/components/TransactionsSection/TransactionRow';
import TransactionDetailsModal from './TransactionDetailsModal';
import InformationSectionWrapper
  from '@screens/NeedsDashboard/Dashboard/components/TransactionsSection/InformationSectionWrapper';

const transactionToItem = (tr: ITransaction, setExpanded) => (
  <TransactionRow key={tr.id} onItemClick={setExpanded} transaction={tr} />
);

const TransactionsSection: React.FC<IInformationSectionProps<ITransaction>> = (
  {
    placeholderClassName,
    ...props
  }
) => {
  const [expandedTransaction, setExpandedTransaction] = useState<ITransaction>();
  const itemToElement = useCallback(item => transactionToItem(item, setExpandedTransaction), [setExpandedTransaction]);

  return (
    <>
      <TransactionDetailsModal
        setExpandedTransaction={setExpandedTransaction}
        expandedTransaction={expandedTransaction}
      />
      <InformationSectionWrapper
        {...props}
        placeholderClassName={placeholderClassName}
        itemToElement={itemToElement}
      />
    </>
  );
};

export default TransactionsSection;
