import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useTranslation } from '@root/hooks/use-translation';

type Props = {
  disableTranslation?: boolean;
}

const NavLinkWithTranslate = ({
  children,
  disableTranslation = false,
  to,
  activeClassName,
  ...props
}: Props & NavLinkProps) => {
  const { t } = useTranslation();

  const content = disableTranslation ? children : t(children);
  const isExternal = typeof to === 'string' && (to.startsWith('http') || to.startsWith('https'));

  if (isExternal) {
    return (
      <a href={to as string} {...props} rel="noopener noreferrer">
        {content}
      </a>
    );
  }

  return (
    <NavLink to={to} activeClassName={activeClassName} {...props}>
      {content}
    </NavLink>
  );
};

export { NavLinkWithTranslate };
