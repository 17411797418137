import React from 'react';
import { ILoadableBuildingComponent } from '../interface';
import BodyText3 from '@components/NewDesign/Typography/BodyText/BodyText3';
import TableRegularContent from '@components/NewDesign/Typography/FormAndTable/TableRegularContent';
import styles from './styles.module.scss';

export type IBuildingTagsProps = ILoadableBuildingComponent

const BuildingTags: React.FC<IBuildingTagsProps> = ({ loading, building }) => (
  <>
    {loading || (
    <div className={styles.container}>
      <BodyText3 className={styles.header}>Included Features:</BodyText3>
      <div className={styles.tags}>
        {building?.tags.map(t => (
          <TableRegularContent key={t} className={styles.tag}>
            {t}
          </TableRegularContent>
        ))}
      </div>
    </div>
    )}
  </>
);

export default BuildingTags;
