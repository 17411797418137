import { combineReducers } from 'redux';
/* PlopJS import placeholder. Do not remove */
import payouts from '@screens/AdminDashboard/Payouts/reducers';
import buildingsAndSpaces from '@screens/AdminDashboard/BuildingsAndSpaces/reducers';
import membersSummary from '@screens/AdminDashboard/MembersSummary/reducers';
import dashboard from '@screens/AdminDashboard/Dashboard/reducers';
import transactions from '@screens/AdminDashboard/Transactions/reducers';

export default combineReducers({
  /* PlopJS reducer placeholder. Do not remove */
  payouts,
  buildingsAndSpaces,
  membersSummary,
  dashboard,
  transactions
});
