import React from 'react';
import { CarouselProvider } from 'pure-react-carousel';
import SimpleCarouselContent from '@components/SimpleCarouselContent';

export interface ISimpleCarouselProps {
  visibleSlides: number;
  naturalSlideWidth?: number;
  naturalSlideHeight?: number;
  vertical?: boolean;
}

const SimpleCarousel: React.FC<ISimpleCarouselProps> = (
  { visibleSlides, naturalSlideHeight = 1, naturalSlideWidth = 1,
    children, vertical
  }
) => {
  const totalSlides = (children as any).length;

  return (
    <CarouselProvider
      naturalSlideWidth={naturalSlideWidth}
      naturalSlideHeight={naturalSlideHeight}
      totalSlides={totalSlides}
      visibleSlides={visibleSlides}
      orientation={vertical ? 'vertical' : 'horizontal'}
    >
      <SimpleCarouselContent
        visibleSlides={visibleSlides}
        totalSlides={totalSlides}
        vertical={vertical}
      >
        {children}
      </SimpleCarouselContent>
    </CarouselProvider>
  );
};

export default SimpleCarousel;
