import { all, put, takeEvery } from 'redux-saga/effects';
import { showModalError } from '@screens/ErrorModal/containers/ErrorModalPage/sagas';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import { clearCheckoutDetailsRoutine, showErrorCheckoutExpirationRoutine } from '@screens/Checkout/routines';

function* tryShowErrorCheckoutExpiration() {
  yield showModalError(
    'Reservation time is up',
    new Error('Checkout process takes 15 minutes to confirm. Your time has been reached and '
      + 'your reservation has been canceled.'),
    ENDPOINTS.BROWSE
  );
  yield put(clearCheckoutDetailsRoutine.fulfill());
}

export default function* checkoutPageSagas() {
  yield all([
    yield takeEvery(showErrorCheckoutExpirationRoutine.FULFILL, tryShowErrorCheckoutExpiration)
  ]);
}
