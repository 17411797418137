import React, { useCallback } from 'react';
import { IProximityData } from '@screens/BrowseSpaces/model/QueryData';
import { IBindingCallback1 } from '@models/Callbacks';
import SelectWrapper from '@components/NewDesign/Select';
import { ISearchSelector } from '../model/SearchSelector';
import styles from './styles.module.scss';

export interface IProximityRangeSelectorProps extends ISearchSelector {
  value: IProximityData | undefined;
  onChange: IBindingCallback1<IProximityData>;
}

const rangeValues = [10, 50, 100, 500];

const ProximityRangeSelector: React.FC<IProximityRangeSelectorProps> = ({ value, onChange }) => {
  const handleChangeRange = useCallback((range: number) => {
    onChange({ ...value, range });
  }, [onChange, value]);

  return (
    <div>
      <SelectWrapper
        value={value?.range}
        disabled={!value?.location}
        onChange={(_, { value: v }) => handleChangeRange(v as number)}
        options={rangeValues.map(v => ({ text: `${v} miles`, value: v, key: v }))}
        placeholder="Range"
        className={styles.select}
      />
    </div>
  );
};

export default ProximityRangeSelector;
