import { IScheduleReviewRequest } from '@screens/HavesDashboard/DropOffAndPickUp/model/ScheduleReviewRequest';
import { callApi } from '@helpers/api.helper';
import { fetchSchedules } from '@screens/NeedsDashboard/Bookings/service/schedules.service';
import { IScheduleUpdateHaveRequest } from '@screens/HavesDashboard/DropOffAndPickUp/model/SpaceIdsWithScheduleId';

const schedulesService = {
  reviewSchedule: (requestData: IScheduleReviewRequest) => callApi({
    method: 'PUT',
    endpoint: 'schedules',
    requestData
  }),
  completeSchedule: (scheduleId: string) => callApi({
    method: 'POST',
    endpoint: `schedules/complete/${scheduleId}`
  }),
  updateScheduleWithNewSpaces: ({ request, scheduleId }: IScheduleUpdateHaveRequest) => callApi({
    method: 'POST',
    endpoint: `schedules/update-spaces/${scheduleId}`,
    requestData: request
  }),
  fetchAvailableAndScheduledSpaces: (scheduleId: string) => callApi({
    method: 'GET',
    endpoint: `schedules/${scheduleId}/spaces`
  }),
  fetchSchedules
};

export default schedulesService;
