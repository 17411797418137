import { useCallback, useEffect, useRef, useState } from 'react';
import { env } from '@root/env';
import { useOwnTranslation } from '@root/context/TranslationProvider';

const useTranslation = () => {
  const { t: translateOwn, language } = useOwnTranslation();
  const [translated, setTranslated] = useState();
  const isMounted = useRef(true);

  useEffect(() => () => {
    isMounted.current = false;
  }, []);

  const getTranslation = useCallback(key => {
    const item = localStorage.getItem(key);
    return item ?? null;
  }, []);

  const setTranslation = useCallback((key, value) => {
    if (isMounted.current) {
      setTranslated(value);
      localStorage.setItem(key, value);
    }
  }, []);

  const t = useCallback(text => {
    if (typeof text !== 'string' || language === 'en' || !text?.trim()?.length) {
      return text;
    }

    const ownTranslation = translateOwn(text);
    if (ownTranslation) {
      return ownTranslation;
    }

    const cacheKey = `${language}-${text}`;
    const cachedTranslation = getTranslation(cacheKey);
    if (cachedTranslation) {
      return cachedTranslation;
    }

    if (!isMounted.current) {
      return text;
    }

    const url = 'https://translation.googleapis.com/language/translate/v2';
    const params = new URLSearchParams({
      q: text,
      target: language,
      format: 'text',
      key: env.googleTranslateApiKey
    });

    fetch(`${url}?${params}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.data && data.data.translations) {
          const { translatedText } = data.data.translations[0];
          setTranslation(cacheKey, translatedText);
        }
      })
      .catch(error => {
        if (isMounted.current) {
          // eslint-disable-next-line no-console
          console.error('Error translating:', error);
        }
        return text;
      });

    return translated ?? text;
  }, [getTranslation, language, setTranslation, translateOwn, translated]);

  return { t };
};

export { useTranslation };
