import React, { createContext, useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@root/store';
import { translationCodeToS3Url } from '@root/helpers/s3.helper';
import { Language } from '@root/common/enum/language';

type Translations = {
  [key: string]: string;
}

type TranslationContextProps = {
  t: (key: string) => string;
  language: string;
}

const TranslationContext = createContext<TranslationContextProps | undefined>(undefined);

const TranslationProvider: React.FC = ({ children }) => {
  const language = useSelector((state: RootState) => state.translation.language);
  const latestLang = useRef(null);
  const [translations, setTranslations] = useState<Translations | null>(null);

  useEffect(() => {
    if ((translations && latestLang.current === language) || language === Language.EN) {
      latestLang.current = language;
      return;
    }

    latestLang.current = language;
    const translationUrl = translationCodeToS3Url(language);

    fetch(translationUrl)
      .then(response => response.json())
      .then(data => {
        setTranslations(data);
      }).catch(error => {
      // eslint-disable-next-line no-console
        console.error(`Failed to load translations file for language ${language}.`, error);
      });
  }, [language, latestLang, translations]);

  const t = useCallback(
    (key: string): string => translations?.[key],
    [translations]
  );

  return (
    <TranslationContext.Provider value={{ t, language }}>
      {children}
    </TranslationContext.Provider>
  );
};

const useOwnTranslation = () => useContext(TranslationContext);

export { TranslationProvider, useOwnTranslation };
