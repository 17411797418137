import React from 'react';
import { IBankAccount } from '@screens/HavesDashboard/FinancialBanking/model/IBankAccount';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';
import styles from './styles.module.scss';

export interface IBankAccountDataProps {
  accountData: IBankAccount;
}

const BankAccountData: React.FC<IBankAccountDataProps> = (
  { accountData }
) => (
  <div className={styles.container}>
    <LabelGroupWrapper
      title="Account Holder Name"
      value={accountData.accountHolderName}
    />
    <LabelGroupWrapper
      title="Account Holder Type"
      value={accountData.accountHolderType}
    />
    <LabelGroupWrapper
      title="Bank Name"
      value={accountData.bankName}
    />
    <LabelGroupWrapper
      title="Country"
      value={accountData.country}
    />
    <LabelGroupWrapper
      title="Currency"
      value={accountData.currency.toUpperCase()}
    />
    <LabelGroupWrapper
      title="Last 4 Digits"
      value={accountData.last4}
    />
    <LabelGroupWrapper
      title="Routing Number"
      value={accountData.routingNumber}
    />
  </div>
);

export default BankAccountData;
