import React, { useCallback, useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import InputWrapper from '@components/NewDesign/Input';
import CommonInput from '@components/formik/common';
import { IFormikInputProps } from '@components/formik/Input';
import { IFormValues } from '@screens/NeedsDashboard/Account/components/AccountDetailsForm';
import { formatPhoneNumber } from '@helpers/phone-number.helper';

const FormikPhoneInput = (props: IFormikInputProps) => {
  const { propsOrFieldName } = props;
  const [field,, helpers] = useField(propsOrFieldName);
  const { initialValues } = useFormikContext<IFormValues>();
  const latestInitialPhoneValue = useRef<string | null>(null);

  const handleChange = useCallback(e => {
    const formattedValue = formatPhoneNumber(e.target.value);
    helpers.setValue(formattedValue);
  }, [helpers]);

  useEffect(() => {
    if (field.value && (latestInitialPhoneValue.current !== initialValues.phone)) {
      helpers.setValue(formatPhoneNumber(String(field.value)));
      latestInitialPhoneValue.current = String(field.value);
    }
  }, [field.value, helpers, initialValues.phone]);

  const fieldProps = {
    ...propsOrFieldName as object,
    onChange: handleChange,
    value: field.value || ''
  };

  return (
    <CommonInput
      {...props as any}
      element={InputWrapper}
      propsOrFieldName={fieldProps}
    />
  );
};

export { FormikPhoneInput };
