import { callApi } from '@helpers/api.helper';
import { IFetchTransactionsForOrderRequest } from '@screens/ServiceOrderCheckout/containers/ServiceOrderDetailsPage/TransactionsBlock';

const orderTransactionsService = {
  fetchOrderTransactions: (
    { orderId, pageRequest: { size, page } }: IFetchTransactionsForOrderRequest
  ) => callApi({
    method: 'GET',
    endpoint: `transactions/order/${orderId}`,
    queryParams: {
      page: page - 1,
      size
    }
  })
};

export default orderTransactionsService;
