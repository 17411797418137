import { callApi } from '@helpers/api.helper';
import { IEditBookingNotes } from '../containers/BookingCheckoutPage/BookingDetailsCheckoutStep';

const bookingDetailsService = {
  fetchBookingDetails: (id: string) => callApi({
    endpoint: `booking/${id}`,
    method: 'GET'
  }),
  editBookingNotes: ({ bookingId, ...requestData }: IEditBookingNotes) => callApi({
    endpoint: `booking/notes/${bookingId}`,
    method: 'PUT',
    requestData
  })
};

export default bookingDetailsService;
