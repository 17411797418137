import React, { forwardRef, ReactNode } from 'react';
import styles from '../styles.module.scss';

interface IImageWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

const ImageWrapper = forwardRef<HTMLDivElement, IImageWrapperProps>(
  ({ children, className, ...props }, ref) => (
    <div ref={ref} className={`${styles.wrapper} ${className ?? ''}`} {...props}>
      {children}
    </div>
  )
);

ImageWrapper.displayName = 'ImageWrapper';

export { ImageWrapper };
