import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface IDividingLineProps {
  light?: boolean;
  dashed?: boolean;
  dotted?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
  className?: string;
}

const DividingLine: React.FC<IDividingLineProps> = (
  { className, light, dashed, dotted,
    marginTop, marginBottom
  }
) => (
  <div
    className={classNames(
      styles.basic,
      styles.default,
      className,
      light && styles.light,
      dashed && styles.dashed,
      dotted && styles.dotted,
      marginTop && styles.margin_top,
      marginBottom && styles.margin_bottom
    )}
  />
);
export default DividingLine;
