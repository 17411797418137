import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import {
  IBuildingForDisplaying,
  IOwnerShortInfoDto
} from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import {
  extractBuildingDetails,
  extractFetchBuildingDetailsLoading,
  extractOwner,
  extractSendMessageFromSpacesToOwnerLoading,
  extractToggleFavoriteBuildingLoading,
  extractWriting
} from '@screens/NeedsDashboard/BuildingDetails/reducers';
import {
  fetchBuildingDetailsRoutine, resetBuildingDetailsRoutine,
  sendMessageFromSpacesToOwnerRoutine,
  setWritingMessageFromSpacesToOwnerRoutine,
  toggleFavoriteBuildingRoutine
} from '@screens/NeedsDashboard/BuildingDetails/routines';
import { IBindingCallback1 } from '@models/Callbacks';
// import BuildingRating from '@screens/BuildingDetails/components/DescriptionSection/components/BuildingRating';
import BuildingTags from '@screens/BuildingDetails/components/DescriptionSection/components/BuildingTags';
import { IBuildingDetailsRequest } from '@screens/BuildingDetails/model/BuildingDetailsRequest';
import ImageGalleryCarousel from '@screens/NeedsDashboard/BuildingDetails/components/ImageGalleryCarousel';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import WritingMessageModal from '@components/MessagesSection/WritingMessageModal';
import { extractCurrentUser } from '@screens/Authorization/reducers';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import BuildingHeaderInfo from '@screens/BookingCheckout/components/BookingDetails/BuildingHeaderInfo';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import { SpacesAndServicesSection } from '@screens/NeedsDashboard/BuildingDetails/containers/SpacesAndServicesSection';
import styles from './styles.module.scss';

export interface IBuildingDetailsProps extends IState, IActions {
}

interface IState {
  loading: boolean;
  building: IBuildingForDisplaying | undefined;
  owner?: IOwnerShortInfoDto;
  favoriteLoading: boolean;
  writing: boolean;
  sendMessageLoading: boolean;
}

interface IActions {
  loadBuilding: IBindingCallback1<IBuildingDetailsRequest>;
  toggleFavorite: IBindingCallback1<string>;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  setWriting: IBindingCallback1<boolean>;
}

const BuildingDetails: React.FC<IBuildingDetailsProps> = ({
  loading,
  building,
  loadBuilding,
  toggleFavorite,
  favoriteLoading,
  setWriting,
  sendMessageLoading,
  sendMessage,
  writing,
  owner
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentUser = useSelector(extractCurrentUser);
  const { building: buildingId } = parse(location.search);

  useEffect(() => {
    if (buildingId) {
      loadBuilding({ id: buildingId as string, from: new Date() });
    }

    return () => dispatch(resetBuildingDetailsRoutine.fulfill());
  }, [buildingId, dispatch, loadBuilding]);

  return (
    <DashboardPageWrapper>
      <DashboardSectionWrapper>
        <div className={styles.top_buttons_container}>
          <SmallLinkButton
            to=""
            onClick={() => history.goBack()}
            left
          >
            Back to Dashboard
          </SmallLinkButton>
          <div className={styles.filler} />
          {building && building.ownerId !== currentUser?.id && (
            <GoToButton to="" onClick={() => setWriting(true)}>
              Write to owner
            </GoToButton>
          )}
        </div>
        <BuildingHeaderInfo building={building} toggleLike={toggleFavorite} likeLoading={favoriteLoading} />
        {/* <BuildingRating loading={loading} building={building} /> */}
        <BuildingTags loading={loading} building={building} />
        <ImageGalleryCarousel
          className={styles.image_container}
          images={building?.gallery ?? []}
          loading={loading}
          building={building}
        />
        <SpacesAndServicesSection buildingId={building?.id} />
        {writing && owner && (
          <WritingMessageModal
            onClose={() => setWriting(false)}
            onSend={sendMessage}
            sendLoading={sendMessageLoading}
            receiverId={owner.id}
            initValues={{ topic: building.buildingName }}
          />
        )}
      </DashboardSectionWrapper>
    </DashboardPageWrapper>
  );
};

const mapStateToProps: (state) => IState = state => ({
  loading: extractFetchBuildingDetailsLoading(state),
  building: extractBuildingDetails(state),
  favoriteLoading: extractToggleFavoriteBuildingLoading(state),
  sendMessageLoading: extractSendMessageFromSpacesToOwnerLoading(state),
  writing: extractWriting(state),
  owner: extractOwner(state)
});

const mapDispatchToProps: IActions = {
  loadBuilding: fetchBuildingDetailsRoutine,
  toggleFavorite: toggleFavoriteBuildingRoutine,
  sendMessage: sendMessageFromSpacesToOwnerRoutine,
  setWriting: setWritingMessageFromSpacesToOwnerRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingDetails);
