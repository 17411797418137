import React from 'react';
import { Form } from 'semantic-ui-react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { IBindingCallback3 } from '@models/Callbacks';
import FormikInput from '@components/formik/Input';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import styles from './styles.module.scss';

interface IUnauthorizedSpacesHelpFormProps {
  onSend: IBindingCallback3<string, string, string>;
}

export interface IFormValues {
  firstName: string;
  lastName: string;
  email: string;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required(),
  lastName: Yup.string().trim().required(),
  email: Yup.string().email()
});

const UnauthorizedSpacesHelpForm: React.FC<IUnauthorizedSpacesHelpFormProps & FormikProps<IFormValues>> = (
  { handleSubmit }
) => (
  <Form onSubmit={handleSubmit}>
    <br />
    <br />
    <FormikInput
      propsOrFieldName={{
        name: 'firstName',
        placeholder: 'First Name',
        required: true
      }}
    />
    <br />
    <FormikInput
      propsOrFieldName={{
        name: 'lastName',
        placeholder: 'Last Name',
        required: true
      }}
    />
    <br />
    <FormikInput
      propsOrFieldName={{
        name: 'email',
        placeholder: 'Email',
        required: true
      }}
    />
    <div className={styles.buttonWrapper}>
      <PrimaryButton
        content="Submit"
        type="submit"
      />
    </div>
  </Form>
);

export default withFormik<IUnauthorizedSpacesHelpFormProps, IFormValues>({
  displayName: 'HelpToFindSpaces',
  validationSchema,
  handleSubmit: ((values, { props }) => {
    props.onSend(values.firstName, values.lastName, values.email);
  })
})(UnauthorizedSpacesHelpForm);
