import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { IInformationSectionProps, InformationSection } from '@components/InformationSection';
import { IResultBuildingItem } from '@screens/BrowseSpaces/model/ResultItem';
import { toPrice } from '@helpers/price.helper';
import BuildingGridCard from '@screens/BrowseSpaces/components/layouts/cards/BuildingGridCard';
import { IBindingCallback1 } from '@models/Callbacks';
import { dashboardBuildingLink } from '@screens/BrowseSpaces/components/layouts/cards/link.helper';
import OptionalSimpleCarousel from '@components/OptionalSimpleCarousel';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import NoDataContainer from '@components/NoDataContainer';
import { getDefaultImageFromBuildingType } from '@helpers/image.placeholder.helper';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

export function lowestPriceMeta({ lowestFullCost }: IResultBuildingItem) {
  if (lowestFullCost) {
    return `Starting at ${toPrice({
      amount: lowestFullCost.pricePerMonth,
      currency: lowestFullCost.currency
    })} monthly`;
  }
  return 'No available spaces';
}

const spaceToItem = (result: IResultBuildingItem, toggleLike, location) => (
  <div key={result.id} style={{ maxWidth: '22em', width: '95%', maxHeight: '100%' }}>
    <BuildingGridCard
      key={result.id}
      imageSrc={result.avatar || getDefaultImageFromBuildingType(result.type)}
      name={result.name}
      type={result.type}
      meta={lowestPriceMeta(result)}
      liked={result.liked}
      toggleLike={() => toggleLike(result.id)}
      likeLoading={result.likeLoading}
      linkLocation={dashboardBuildingLink({ id: result.id, name: result.name }, location)}
    />
  </div>
);

const horizontalWrapper: React.FC = ({ children }) => (
  <OptionalSimpleCarousel visibleSlides={3}>{children}</OptionalSimpleCarousel>
);

const verticalWrapper: React.FC = ({ children }) => (
  <OptionalSimpleCarousel visibleSlides={1} vertical>{children}</OptionalSimpleCarousel>
);

export interface ISpacesSectionProps extends IInformationSectionProps<IResultBuildingItem> {
  toggleLike: IBindingCallback1<string>;
}

const SpacesSection: React.FC<ISpacesSectionProps> = (
  {
    itemsWrapper,
    renderItem = spaceToItem,
    toggleLike,
    ...props
  }
) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.horizontal_section}>
        <InformationSection
          {...props}
          itemsWrapper={itemsWrapper || horizontalWrapper}
          renderItem={item => renderItem(item, toggleLike, location)}
          noItemsPlaceholder={() => <NoDataContainer message="No items" className={styles.spacesSection} />}
          loadMoreComponent={() => (
            <Button
              content={t('Load more...')}
              attached="bottom"
              onClick={() => history.push(HAVES_DASHBOARD_ENDPOINTS.BUILDINGS)}
            />
          )}
        />
      </div>
      <div className={styles.vertical_section}>
        <InformationSection
          {...props}
          itemsWrapper={itemsWrapper || verticalWrapper}
          renderItem={item => renderItem(item, toggleLike, location)}
          noItemsPlaceholder={() => <NoDataContainer message="No items" className={styles.spacesSection} />}
          loadMoreComponent={() => (
            <Button
              content={t('Load more...')}
              attached="bottom"
              onClick={() => history.push(HAVES_DASHBOARD_ENDPOINTS.BUILDINGS)}
            />
          )}
        />
      </div>
    </>
  );
};

export default SpacesSection;
