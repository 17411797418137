import { createReducer } from '@reduxjs/toolkit';
import {
  loadHaveAccountsListDataRoutine,
  setAddingBankAccountRoutine,
  setBankAccountProcessingRoutine,
  setVerifyingBankAccountRoutine
} from '@screens/HavesDashboard/FinancialBanking/routines';
import { IBankAccount } from '@screens/HavesDashboard/FinancialBanking/model/IBankAccount';

export interface IFinancialBankingReducerState {
  accountsListData?: IBankAccount[];
  addingBankAccount?: boolean;
  verifyingBankAccount?: boolean;
  processingId?: string;
}

const initialState: IFinancialBankingReducerState = {
};

export const financialBankingReducer = createReducer(initialState, {
  [setBankAccountProcessingRoutine.FULFILL]: (state, { payload }) => {
    state.processingId = payload;
  },
  [loadHaveAccountsListDataRoutine.SUCCESS]: (state, { payload }) => {
    state.accountsListData = payload;
  },
  [setAddingBankAccountRoutine.FULFILL]: (state, { payload }) => {
    state.addingBankAccount = payload;
  },
  [setVerifyingBankAccountRoutine.FULFILL]: (state, { payload }) => {
    state.verifyingBankAccount = payload;
  }
});
