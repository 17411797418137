import { all, call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { PayloadAction } from '@reduxjs/toolkit';
import { IEditOrderNotes } from '@screens/ServiceOrderCheckout/containers/ServiceOrderCheckoutPage/ServiceOrderDetailsCheckoutStep';
import orderDetailsService from '@screens/ServiceOrderCheckout/services/order.details.service';
import { editOrderNotesRoutine } from '@screens/ServiceOrderCheckout/routines';

function* tryEditOrderNotes({ payload }: PayloadAction<IEditOrderNotes>) {
  try {
    const response = yield call(orderDetailsService.editNotes, payload);
    yield put(editOrderNotesRoutine.success(response));
  } catch (e) {
    toastr.error('Unable to edit order notes', e?.message);
    yield put(editOrderNotesRoutine.failure(e?.message));
  }
}

export default function* orderCheckoutPageSagas() {
  yield all([
    yield takeEvery(editOrderNotesRoutine.TRIGGER, tryEditOrderNotes)
  ]);
}
