import React from 'react';
import { Dropdown, Menu, MenuItem } from 'semantic-ui-react';
import { Role } from '@screens/Authorization/models/Roles';
import { enumAsOptions } from '@helpers/enum.helper';
import { IBindingCallback2, IBindingAction } from '@models/Callbacks';
import { IMemberFilterData } from '@screens/AdminDashboard/MembersSummary/model/IMemberSearchFilterRequest';
import InputWrapper from '@components/NewDesign/Input';
import styles from './styles.module.scss';

interface IMemberSectionFilterProps {
    updateFilterState?: IBindingCallback2<string, any>;
    clearFilterState?: IBindingAction;
    applyFilterChanges?: IBindingAction;
    memberFilterData?: IMemberFilterData;
}

const MembersSectionFilter: React.FC<IMemberSectionFilterProps> = ({
  updateFilterState, clearFilterState, applyFilterChanges, memberFilterData
}) => (
  <Menu text className={styles.menu}>
    <MenuItem header>Filter by</MenuItem>
    <div className={styles.input_container}>
      <InputWrapper
        className={styles.search}
        placeholder="Full name"
        transparent
        value={memberFilterData.fullName}
        onChange={(event, { value }) => updateFilterState('fullName', value)}
      />
    </div>
    <div className={styles.input_container}>
      <InputWrapper
        className={styles.search}
        placeholder="Email"
        transparent
        value={memberFilterData.email}
        onChange={(event, { value }) => updateFilterState('email', value)}
      />
    </div>
    <Dropdown
      className={styles.search_dropdown}
      options={enumAsOptions(Role)}
      placeholder="Member Type"
      clearable
      value={memberFilterData.role}
      onChange={(e: any, { value: v }) => updateFilterState('role', v)}
    />
    <MenuItem
      className={styles.clear_button}
      name="Clear filters"
      onClick={clearFilterState}
    />
    <MenuItem
      className={(memberFilterData.filterChangeStatus) ? styles.search_button_fade : styles.search_button_orange}
      name="Apply Filter"
      icon="filter"
      onClick={applyFilterChanges}
    />
  </Menu>
);

export default MembersSectionFilter;
