import { all } from 'redux-saga/effects';
import bookingReceiptPagePageSagas from '@screens/ReceiptPage/containers/BookingReceiptPage/sagas';
import serviceOrderReceiptPagePageSagas from '@screens/ReceiptPage/containers/ServiceOrderReceiptPage/sagas';

export default function* receiptPageSagas() {
  yield all([
    bookingReceiptPagePageSagas(),
    serviceOrderReceiptPagePageSagas()
  ]);
}
