import { all, call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  submitHelpWithSearchRoutine,
  submitUnauthorizedHelpWithSearchRoutine,
  toggleFavoriteBuildingRoutine
} from '@screens/BrowseSpaces/routines';
import { tryToggleFavoriteBuilding } from '@sagas/favorite.building.saga';
import {
  submitHelpWithSearch, submitUnauthorizedHelpWithSearch
} from '@screens/static/services/staticPageForm.service';
import { IHelpSearchRequest, IUnauthorizedHelpSearchRequest } from '@screens/BrowseSpaces/model/HelpSearchRequest';
import { YOUR_REQUEST_SENT } from '@common/constants/constants';

function* trySubmitHelpWithSearch({ payload }: PayloadAction<IHelpSearchRequest>) {
  try {
    yield call(submitHelpWithSearch, payload);
    yield put(submitHelpWithSearchRoutine.success());
    toastr.success('Success', YOUR_REQUEST_SENT);
  } catch (e) {
    toastr.error('Unable to send help request', e?.message);
    yield put(submitHelpWithSearchRoutine.failure(e?.message));
  }
}

function* trySubmitUnauthorizedHelpWithSearch({ payload }: PayloadAction<IUnauthorizedHelpSearchRequest>) {
  try {
    yield call(submitUnauthorizedHelpWithSearch, payload);
    yield put(submitUnauthorizedHelpWithSearchRoutine.success());
    toastr.success('Success', YOUR_REQUEST_SENT);
  } catch (e) {
    toastr.error('Unable to send help request', e?.message);
    yield put(submitUnauthorizedHelpWithSearchRoutine.failure(e?.message));
  }
}

export default function* spacesSagas() {
  yield all([
    yield takeEvery(toggleFavoriteBuildingRoutine.TRIGGER, tryToggleFavoriteBuilding(toggleFavoriteBuildingRoutine)),
    yield takeEvery(submitHelpWithSearchRoutine.TRIGGER, trySubmitHelpWithSearch),
    yield takeEvery(submitUnauthorizedHelpWithSearchRoutine.TRIGGER, trySubmitUnauthorizedHelpWithSearch)
  ]);
}
