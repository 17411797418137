import React from 'react';
import { SpaceType, spaceTypeKey, spaceTypeToAmountLabel } from '@models/domain/space/SpaceTypes';
import { toPrice } from '@helpers/price.helper';
import { ISpaceBooked } from '@screens/BookingCheckout/model/BookingCheckout';
import BookingInfoTitleWithNote from '@screens/BookingCheckout/components/BookingDetails/BookingInfoTitleWithNote';
import { SpaceCategory } from '@models/domain/space/SpaceCategories';

export interface IBookingOneSpaceMetaDataProps {
  space: ISpaceBooked;
}

const BookingOneSpaceMetaData: React.FC<IBookingOneSpaceMetaDataProps> = (
  { space }
) => (
  <BookingInfoTitleWithNote
    note="Billing occurs on the first of every month. Fees are prorated accordingly."
  >
    Monthly contract
    &nbsp;|
    {' '}
    {spaceTypeToAmountLabel(space.amountBooked, SpaceType[space.spaceType])}
    &nbsp;|
    {' '}
    {(space.spaceType === spaceTypeKey(SpaceType.WAREHOUSE) || space.spaceType === spaceTypeKey(SpaceType.PARKING)) && (
      <>
        {space.length}
        x
        {space.width}
        x
        {space.height}
        &nbsp;
        {space.lengthUnit.toLowerCase()}
        &nbsp;|
        {' '}
      </>
    )}
    {space.spaceType === spaceTypeKey(SpaceType.OFFICE) && (
      <>
        {space.length * space.width}
        &nbsp;
        {space.lengthUnit.toLowerCase()}
        <sup>2</sup>
        &nbsp;|
        {' '}
      </>
    )}
    {SpaceCategory[space.spaceCategory]}
    &nbsp;|
    {' '}
    {toPrice({ amount: space.pricePerMonth * space.amountBooked, currency: space.currency })}
    &nbsp;monthly
  </BookingInfoTitleWithNote>
);

export default BookingOneSpaceMetaData;
