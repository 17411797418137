import React, { useCallback } from 'react';
import classNames from 'classnames';
import { toPrice } from '@helpers/price.helper';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import { IServiceOrderDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderDto';
import TransactionFee from '@screens/BookingCheckout/components/BookingDetails/TransactionFee';
import { calculatePriceWithFee } from '@screens/BookingCheckout/components/BookingDetails/BookingGrandTotal';
import styles from './styles.module.scss';

export interface IServiceOrderGrandTotalProps {
  order: IServiceOrderDto;
  className?: string;
}

const ServiceOrderGrandTotal: React.FC<IServiceOrderGrandTotalProps> = (
  { order, className }
) => {
  const { priceWithFee, feeAmount } = calculatePriceWithFee(order.totalPrice);

  const priceToPriceWithCurrency = useCallback(
    price => ({
      amount: price,
      currency: order.currency
    }),
    [order.currency]
  );

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.content}>
        <div className={styles.total}>
          <div className={styles.label}>
            <SubHeader1>
              Grand total:
            </SubHeader1>
          </div>
          <div className={styles.value}>
            <SubHeader1>
              {toPrice(priceToPriceWithCurrency(priceWithFee))}
              {' '}
              today
            </SubHeader1>
          </div>
        </div>
        <TransactionFee feeAmount={toPrice(priceToPriceWithCurrency(feeAmount))} />
      </div>
    </div>
  );
};

export default ServiceOrderGrandTotal;
