import React from 'react';
import { Input, InputProps } from 'semantic-ui-react';
import classNames from 'classnames';
import { INPUT_MAX_LENGTH } from '@helpers/validation.helper';
import { useTranslation } from '@root/hooks/use-translation';
import styles from '../Input/styles.module.scss';

const SimpleInputWrapper: React.FC<InputProps> = React.forwardRef(({ placeholder, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.input, props.className)}>
      <Input {...props} placeholder={t(placeholder)} ref={ref as any} maxLength={INPUT_MAX_LENGTH} />
    </div>
  );
});

export default SimpleInputWrapper;
