/* eslint-disable max-len */
import { createRoutine } from 'redux-saga-routines';

const createCheckoutRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`CHECKOUT:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const loadCreditCardsRoutine = createCheckoutRoutine('LOAD_CREDIT_CARDS');
export const setActiveStepRoutine = createCheckoutRoutine('SET_ACTIVE_STEP');
export const showErrorCheckoutExpirationRoutine = createCheckoutRoutine('SHOW_ERROR_CHECKOUT_EXPIRATION');
export const clearCheckoutDetailsRoutine = createCheckoutRoutine('CLEAR_CHECKOUT_DETAILS');

