import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import { IPayoutsSummary } from '@screens/AdminDashboard/Payouts/models/PayoutsSummary';
import { IBindingAction } from '@models/Callbacks';
import {
  extractDoPayoutLoading,
  extractLoadPayoutsSummaryLoading,
  extractPayoutsSummary
} from '@screens/AdminDashboard/Payouts/reducers';
import { doPayoutRoutine, loadPayoutsSummaryRoutine } from '@screens/AdminDashboard/Payouts/routines';
import PayoutsSummaryInformation from '@screens/AdminDashboard/Payouts/components/PayoutsSummaryInformation';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import { toPrice } from '@helpers/price.helper';
import { ADMIN_DASHBOARD_ENDPOINTS } from '@screens/AdminDashboard/Root/components/Routing/endpoints';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import styles from './styles.module.scss';

export interface IPayoutsProps extends IState, IActions {
}

interface IState {
  summary?: IPayoutsSummary;
  summaryLoading?: boolean;
  doPayoutLoading?: boolean;
}

interface IActions {
  loadSummary: IBindingAction;
  doPayout: IBindingAction;
}

const Payouts: React.FC<IPayoutsProps> = (
  { summary, summaryLoading, loadSummary, doPayout, doPayoutLoading }
) => {
  useEffect(() => {
    loadSummary();
  }, [loadSummary]);

  return (
    <DashboardPageWrapper title="Payouts">
      <div className={styles.container}>
        {summaryLoading && (
          <Loader active inline="centered" />
        )}
        {!summaryLoading && summary && (
          <div>
            <PayoutsSummaryInformation summary={summary} />
            <div className={styles.gotoButtonWrapper}>
              <GoToButton
                className={styles.go_to_history}
                to={ADMIN_DASHBOARD_ENDPOINTS.PAYOUTS_HISTORY}
              >
                Payouts History
              </GoToButton>
            </div>
            <div className={styles.buttonWrapper}>
              <PrimaryButton
                onClick={doPayout}
                loading={doPayoutLoading}
                disabled={summary.availablePayoutVolume.amount === 0}
              >
                Pay Out
                {' '}
                {toPrice(summary.availablePayoutVolume)}
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </DashboardPageWrapper>
  );
};

const mapStateToProps: (state) => IState = state => ({
  summary: extractPayoutsSummary(state),
  summaryLoading: extractLoadPayoutsSummaryLoading(state),
  doPayoutLoading: extractDoPayoutLoading(state)
});

const mapDispatchToProps: IActions = {
  loadSummary: loadPayoutsSummaryRoutine,
  doPayout: doPayoutRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(Payouts);
