import React from 'react';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import styles from './styles.module.scss';

export interface IHostedByLabelProps {
  companyName: string;
}

const HostedByLabel: React.FC<IHostedByLabelProps> = ({ companyName }) => (
  <>
    <SubHeader3 className={styles.text}>
      HOSTED BY
      {' '}
      <>{companyName}</>
    </SubHeader3>
  </>
);

export default HostedByLabel;
