import React, { memo } from 'react';
import { FormInput } from 'semantic-ui-react';
import { FormInputProps } from 'semantic-ui-react/dist/commonjs/collections/Form/FormInput';
import { INPUT_MAX_LENGTH } from '@helpers/validation.helper';
import styles from './styles.module.scss';

const InputWrapper: React.FC<FormInputProps> = props => (
  <div className={styles.input}>
    <FormInput {...props} maxLength={INPUT_MAX_LENGTH} />
  </div>
);
export default memo(InputWrapper);
