
export enum LogisticsServiceCategory {
  CROSS_DOCKING = 'Cross Docking',
  OTHER = 'Other'
}

export enum WarehouseServiceCategory {
  DOCK_DROP = 'Dock Drop',
  DOCK_PICK_UP = 'Dock Pick Up',
  WAREHOUSE_LABOR = 'Warehouse Labor',
  OTHER = 'Other'
}

export enum TransportationServiceCategory {
  DELIVERY = 'Delivery',
  PICKUP = 'Pickup',
  OTHER = 'Other'
}

export const ServiceCategory = {
  ...LogisticsServiceCategory,
  ...WarehouseServiceCategory,
  ...TransportationServiceCategory
};
export type ServiceCategory =
  LogisticsServiceCategory |
  WarehouseServiceCategory |
  TransportationServiceCategory;

export const serviceCategoryKey = val => Object.keys(ServiceCategory).filter(k => ServiceCategory[k] === val)[0];
