import React from 'react';
import classNames from 'classnames';
import { MenuItem } from 'semantic-ui-react';
import SearchSelector, { ISearchSelectorProps } from '@screens/Landing/components/search/SearchSelector';

export type ISearchSelectorMenuItemProps = ISearchSelectorProps;

const SearchSelectorMenuItem: React.FC<ISearchSelectorMenuItemProps> = props => {
  const { activeIndex, index, trigger, className, ownStyle } = props;

  const item = () => (ownStyle ? (
    <div
      onClick={() => props.handleClick(index)}
      className={classNames(className, `${activeIndex === index ? ' active' : ''}`)}
    >
      {trigger}
    </div>
  ) : (
    <MenuItem link onClick={() => props.handleClick(index)} active={activeIndex === index}>
      {trigger}
    </MenuItem>
  ));

  return (
    <SearchSelector
      {...props}
      trigger={item()}
    />
  );
};

export default SearchSelectorMenuItem;
