import { combineReducers } from 'redux';
import { RootState } from '@root/store';
import { reducerCreator } from '@helpers/reducer.helper';
import { errorModalReducer } from '@screens/ErrorModal/containers/ErrorModalPage/reducer';
/* PlopJS import placeholder. Do not remove */
import { showErrorModalRoutine } from '@screens/ErrorModal/routines';
import { closeErrorModalRoutine } from '@screens/ErrorModal/routines';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  showErrorModalRequest: reducerCreator([showErrorModalRoutine.TRIGGER]),
  closeErrorModalRequest: reducerCreator([closeErrorModalRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  data: errorModalReducer
});

const reqs = (state: RootState) => state.errorModal.requests;
const data = (state: RootState) => state.errorModal.data;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractShowErrorModalLoading = state => reqs(state).showErrorModalRequest.loading;
export const extractShowErrorModalError = state => reqs(state).showErrorModalRequest.error;
export const extractCloseErrorModalLoading = state => reqs(state).closeErrorModalRequest.loading;
export const extractCloseErrorModalError = state => reqs(state).closeErrorModalRequest.error;
export const extractErrorModalInfo = state => data(state).info;
