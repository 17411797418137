import React from 'react';
import classNames from 'classnames';
import Header2 from '@components/NewDesign/Typography/Headers/Header2';
import airplaneImage from '@images/paper_airplane.jpg';
import styles from './styles.module.scss';

interface IPaperAirplaneHeaderProps {
  headerText?: string;
  className?: string;
}

const PaperAirplaneHeader: React.FC<IPaperAirplaneHeaderProps> = ({ headerText, className }) => (
  <div className={classNames(styles.container, className)}>
    {/* eslint-disable-next-line jsx-a11y/alt-text */}
    <img className={styles.image} src={airplaneImage} alt="paper_airplane_image" />
    <Header2 className={styles.header}>{headerText}</Header2>
  </div>
);

export default PaperAirplaneHeader;
