import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { IBookingPaymentPeriods } from '@screens/BookingCheckout/helpers/bookingPeriods.helper';
import { IBriefBookingDto, IPriceWithCurrency } from '@models/domain/Booking';
import { toPrice } from '@helpers/price.helper';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import Caption3 from '@components/NewDesign/Typography/Caption/Caption3';
import { DATE_FORMAT } from '@helpers/date.helper';
import TransactionFee from '../TransactionFee';
import styles from './styles.module.scss';

export interface IBookingGrandTotalProps {
  periods: IBookingPaymentPeriods;
  booking: IBriefBookingDto;
}

const MINIMAL_TRANSACTION_COST = 1;
const REVENUE_PERCENT = 0.10;

export const calculatePriceWithFee = price => {
  const feeAmount = price * REVENUE_PERCENT;
  const priceWithFee = price + feeAmount;
  return {
    priceWithFee,
    feeAmount
  };
};

const BookingGrandTotal: React.FC<IBookingGrandTotalProps> = (
  { periods, booking }
) => {
  const priceToPriceWithCurrency = useCallback(
    (amount: number): IPriceWithCurrency => ({ amount, currency: booking.fullCost.currency }),
    [booking.fullCost.currency]
  );

  const firstPrice = useMemo(() => {
    if (!periods.first) {
      return null;
    }
    const days = periods.first.end.diff(periods.first.start, 'days') + 1;
    const price = Math.max(days * booking.fullCost.pricePerDay, MINIMAL_TRANSACTION_COST);
    return calculatePriceWithFee(price);
  }, [booking.fullCost.pricePerDay, periods.first]);

  const secondPrice = useMemo(() => {
    if (!periods.second) {
      return null;
    }
    const price = Math.max(booking.fullCost.pricePerMonth, MINIMAL_TRANSACTION_COST);
    return calculatePriceWithFee(price);
  }, [booking.fullCost.pricePerMonth, periods.second]);

  const thirdPrice = useMemo(() => {
    if (!periods.third) {
      return null;
    }
    const days = periods.third.end.diff(periods.third.start, 'days') + 1;
    const price = Math.max(days * booking.fullCost.pricePerDay, MINIMAL_TRANSACTION_COST);
    return calculatePriceWithFee(price);
  }, [booking.fullCost.pricePerDay, periods.third]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {periods.first && (
          <div className={styles.group}>
            <div className={styles.total}>
              <div className={styles.label}>
                <SubHeader1>
                  Grand total:
                </SubHeader1>
              </div>
              <div className={styles.value}>
                <SubHeader1>
                  {toPrice(priceToPriceWithCurrency(firstPrice.priceWithFee))}
                  {' '}
                  today
                </SubHeader1>
              </div>
            </div>
            <TransactionFee feeAmount={toPrice(priceToPriceWithCurrency(firstPrice.feeAmount))} />
          </div>
        )}
        {periods.second && (
          <>
            <div className={styles.group}>
              <div className={classNames(styles.total)}>
                <div className={styles.label}>
                  <SubHeader1>
                    Grand total:
                  </SubHeader1>
                </div>
                <div className={styles.value}>
                  <SubHeader1>
                    {toPrice(priceToPriceWithCurrency(secondPrice.priceWithFee))}
                    {' '}
                    monthly
                  </SubHeader1>
                </div>
              </div>
              <TransactionFee feeAmount={toPrice(priceToPriceWithCurrency(secondPrice.feeAmount))} />
            </div>
            <div className={styles.noteWrapper}>
              <Caption3 className={styles.note}>
                This amount will automatically bill to your payment method until cancelled.
              </Caption3>
            </div>
          </>
        )}
        {periods.third && (
          <div className={styles.group}>
            <div className={styles.total}>
              <div className={styles.label}>
                <SubHeader1>
                  Grand total:
                </SubHeader1>
              </div>
              <div className={styles.value}>
                <SubHeader1>
                  {toPrice(priceToPriceWithCurrency(thirdPrice.priceWithFee))}
                  {' '}
                  on
                  {' '}
                  {periods.third.start.format(DATE_FORMAT)}
                </SubHeader1>
              </div>
            </div>
            <TransactionFee feeAmount={toPrice(priceToPriceWithCurrency(thirdPrice.feeAmount))} />
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingGrandTotal;
