import { PageSize } from '@screens/BrowseSpaces/model/PageSize';
import { SpaceType } from '@models/domain/SpaceType';
import { ILatLngLiteral } from '@models/domain/location';

export interface IQueryData {
  text?: string;
  pendingText?: string;
  page?: number;
  size?: PageSize;
  spaceType?: SpaceType;
  dates?: IDatesData;
  proximity?: IProximityData;
  amount?: number;
  dimensions?: IQueryDimensionsData;
  square?: IQuerySquareData;
  spaceCategory?: string;
}

export interface IDatesData {
  startingDate: Date;
  endingDate?: Date;
}

export interface IProximityData {
  location: ILocationWithTitleData;
  range: number;
}

export interface ILocationWithTitleData {
  literal: ILatLngLiteral;
  title: string;
}

export interface IQueryDimensionsData {
  height?: number;
  length?: number;
  width?: number;
  unit: string;
}

export interface IQuerySquareData {
  square?: number;
  unit: string;
}

const approximatelyEqual = (a: any, b: any) => (!a && !b) || (a === b);

const areDatesEqual = (
  a: Date, b: Date
) => approximatelyEqual(a?.getTime(), b?.getTime());

const areDimensionsEqual = (
  a: IQueryDimensionsData, b: IQueryDimensionsData
) => approximatelyEqual(a?.width, b?.width)
    && approximatelyEqual(a?.height, b?.height)
    && approximatelyEqual(a?.length, b?.length)
    && (approximatelyEqual(a?.unit, b?.unit) || (!a?.width && !a?.length && !a?.height));

const areSquaresEqual = (
  a: IQuerySquareData, b: IQuerySquareData
) => approximatelyEqual(a?.square, b?.square)
    && (approximatelyEqual(a?.unit, b?.unit) || (!a?.square));

export const areTwoQueriesEqual = (a: IQueryData, b: IQueryData) => {
  const pendingTextEqual = approximatelyEqual(a.pendingText, b.pendingText);
  const textEqual = approximatelyEqual(a.text, b.text);
  const pageEqual = approximatelyEqual(a.page, b.page);
  const sizeEqual = approximatelyEqual(a.size, b.size);
  const spaceTypeEqual = approximatelyEqual(a.spaceType, b.spaceType);
  const startDateEqual = areDatesEqual(a.dates?.startingDate, b.dates?.startingDate);
  const endDateEqual = areDatesEqual(a.dates?.endingDate, b.dates?.endingDate);
  const literalEqual = approximatelyEqual(a.proximity?.location?.literal, b.proximity?.location?.literal);
  const rangeEqual = approximatelyEqual(a.proximity?.range, b.proximity?.range);
  const amountEqual = approximatelyEqual(a.amount, b.amount);
  const dimensionsEqual = areDimensionsEqual(a.dimensions, b.dimensions);
  const squareEqual = areSquaresEqual(a.square, b.square);
  const categoryEqual = approximatelyEqual(a.spaceCategory, b.spaceCategory);

  return pendingTextEqual && textEqual && pageEqual && sizeEqual && spaceTypeEqual
    && startDateEqual && endDateEqual && literalEqual && rangeEqual && amountEqual
    && dimensionsEqual && squareEqual && categoryEqual;
};

