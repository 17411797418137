import React from 'react';
import { Divider, Form } from 'semantic-ui-react';
import { FormikProps, withFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';
import * as Yup from 'yup';
import _ from 'lodash';
import { IBindingCallback1 } from '@models/Callbacks';
import {
  emailValidation,
  nullableValidation,
  phoneValidation,
  requiredValidation, zipValidation
} from '@screens/Authorization/components/validation';
import { IProfileData } from '@screens/NeedsDashboard/Account/model/ProfileData';
import FormikInput from '@components/formik/Input';
import FormGroupWrapper from '@components/NewDesign/Form/FormGroupWrapper';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import AddressFormPart from '@components/AddressFormPart';
import { FormikPhoneInput } from '@components/formik/FormikPhoneInput';
import styles from './styles.module.scss';

export interface IAccountDetailsFormProps {
  saveChanges: IBindingCallback1<IProfileData>;
  savingLoading: boolean;
  className?: string;
  initialValuesLoading: boolean;
  initialFormData: IFormValues;
  autoFocusInputName?: string;
  enableEmailModification?: boolean;
}

export interface IFormValues {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  password: string;
  repeatPassword: string;
  phone: string;
  address1: string;
  address2: string;
  state: string;
  city: string;
  country: string;
  zip: string;
  latitude: string;
  longitude: string;
}

const validationSchema = Yup.object().shape({
  firstName: requiredValidation,
  lastName: requiredValidation,
  phone: phoneValidation,
  companyName: requiredValidation,
  email: emailValidation,
  address1: requiredValidation,
  address2: nullableValidation,
  city: requiredValidation,
  state: requiredValidation,
  zip: zipValidation,
  country: requiredValidation,
  latitude: nullableValidation,
  longitude: nullableValidation
});

const cleanPhone = (value: string): string => (value?.length ? value.replace(/\D/g, '') : value);

const formToProfileData = (value: IFormValues): IProfileData => ({
  ...value,
  phone: cleanPhone(value.phone),
  password: value.password
    ? { value: value.password }
    : null,
  location: {
    ...value
  }
});

export function nilAreEqual(o1, o2): undefined | boolean {
  if (!o1 && !o2) {
    return true;
  }
  return undefined;
}

const COUNTRY = 'US';

const validatePhone = value => {
  if (!value) return {};
  let valid = false;
  const cleanedValue = cleanPhone(value);
  const phoneNumber = parsePhoneNumber(`+1${cleanedValue}`, COUNTRY);
  if (phoneNumber) {
    valid = phoneNumber.isValid();
  }
  return valid ? {} : { phone: 'Invalid phone number' };
};

const AccountDetailsForm: React.FC<IAccountDetailsFormProps & FormikProps<IFormValues>> = (
  {
    handleSubmit,
    savingLoading,
    className,
    initialValuesLoading,
    values,
    initialValues,
    autoFocusInputName,
    enableEmailModification,
    setValues,
    errors,
    setFieldValue,
    setFieldTouched,
    submitCount,
    isValid
  }
) => (
  <Form name="profileForm" onSubmit={handleSubmit} className={className} loading={initialValuesLoading}>
    <FormGroupWrapper widths="equal">
      <FormikInput
        propsOrFieldName={{
          name: 'firstName',
          placeholder: 'John',
          required: true
        }}
        semanticProps={{
          label: 'First name'
        }}
      />
      <FormikInput
        propsOrFieldName={{
          name: 'lastName',
          placeholder: 'Doe',
          required: true
        }}
        semanticProps={{
          label: 'Last name'
        }}
      />
    </FormGroupWrapper>
    <FormGroupWrapper widths="equal">
      <FormikInput
        propsOrFieldName={{
          name: 'email',
          placeholder: 'example@mail.com',
          required: true,
          disabled: !enableEmailModification
        }}
        semanticProps={{
          label: 'Email'
        }}
      />
      <FormikPhoneInput
        propsOrFieldName={{
          name: 'phone',
          placeholder: '213-373-4253',
          required: true
        }}
        semanticProps={{
          label: 'Phone number',
          className: styles.phone
        }}
      />
    </FormGroupWrapper>
    <FormikInput
      propsOrFieldName={{
        name: 'companyName',
        placeholder: 'Name of your company',
        autoFocus: autoFocusInputName === 'companyName',
        required: true
      }}
      semanticProps={{
        label: 'Company name'
      }}
    />
    <Divider />
    <AddressFormPart
      values={values}
      setValues={setValues as any}
      initialValues={initialValues}
      showErrorMessage={submitCount > 0 && !!errors.address1}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
    />
    <div className={styles.form_button}>
      <PrimaryButton
        content="Save"
        type="submit"
        loading={savingLoading}
        disabled={_.isEqualWith(initialValues, values, nilAreEqual) || !isValid}
      />
    </div>
  </Form>
);

export default withFormik<IAccountDetailsFormProps, IFormValues>({
  displayName: 'AccountDetailsForm',
  validationSchema,
  enableReinitialize: true,
  handleSubmit: ((values, { props, setFieldValue, setFieldTouched }) => {
    props.saveChanges(formToProfileData(values));
    setFieldValue('password', null);
    setFieldValue('repeatPassword', null);
    setFieldTouched('password', false);
    setFieldTouched('repeatPassword', false);
  }),
  mapPropsToValues: props => ({ ...props.initialFormData, password: '', repeatPassword: '' }),
  validate: values => validatePhone(values.phone)
})(AccountDetailsForm);
