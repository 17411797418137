import { RootState } from '@root/store';

const SET_GOOGLE_PLACES_SCRIPT_LOADED = 'SCRIPT_LOADER:SET_GOOGLE_SCRIPT_LOADED';

export const setGooglePlacesScriptLoaded = () => ({ type: SET_GOOGLE_PLACES_SCRIPT_LOADED });

interface IScriptLoaderReducerState {
  googlePlaces: boolean;
}

const initialState: IScriptLoaderReducerState = {
  googlePlaces: false
};

const scriptLoaderReducer = (
  state = initialState, action
) => {
  const { type } = action;
  switch (type) {
    case SET_GOOGLE_PLACES_SCRIPT_LOADED:
      return {
        ...state,
        googlePlaces: true
      };
    default:
      return state;
  }
};

export const extractIsGooglePlacesScriptLoaded = (state: RootState) => state.scriptLoaderReducer.googlePlaces;

export default scriptLoaderReducer;
