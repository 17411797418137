/* eslint-disable max-len */
import { createRoutine } from 'redux-saga-routines';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import {
  ICompleteOrderedServiceRequestPayload
} from '@screens/HavesDashboard/Orders/model/CompleteOrderedServiceRequestPayload';
import { IServiceOrderDetailsDto } from '@screens/NeedsDashboard/Orders/model/ServiceOrderDetailsDto';

const createOrdersPageRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`HAVES_DASHBOARD__ORDERS_PAGE:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const fetchOrdersRoutine = createOrdersPageRoutine<IPageRequest>('FETCH_ORDERS');
export const setCurrentPageRoutine = createOrdersPageRoutine<number>('SET_ORDERS_CURRENT_PAGE');
export const setExpandedOrderRoutine = createOrdersPageRoutine<IServiceOrderDetailsDto>('SET_EXPANDED_ORDER');
export const completeOrderedServiceRoutine = createOrdersPageRoutine<ICompleteOrderedServiceRequestPayload>('COMPLETE_ORDERED_SERVICE');
export const completeOrderRoutine = createOrdersPageRoutine<number>('COMPLETE_ORDER');
