import React from 'react';
import classNames from 'classnames';
import FrontBlock from '@screens/Landing/components/FrontBlock';
import WhySharedSpacesBlock from '@screens/Landing/components/WhySharedSpacesBlock';
import HowItWorksBlock from '@screens/Landing/components/HowItWorksBlock';
import ScrollToTopOnMount from '@components/ScrollToTop';
import s from './styles.module.scss';
import MetaTags from '@components/MetaTags';

const LandingPage: React.FC = () => (
  <div className={classNames(s.container, s.top_offset)}>
    <MetaTags title="Shared Spaces. Putting Idle Spaces to Work" />
    <ScrollToTopOnMount />
    <FrontBlock />
    <WhySharedSpacesBlock />
    <HowItWorksBlock />
  </div>
);

export default LandingPage;
