import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import { lowerCase, startCase } from 'lodash';
import NewTable from '@components/NewDesign/Table/NewTable';
import { IOrderedServiceDto } from '@screens/ServiceOrderCheckout/model/OrderedServiceDto';
import { generateLabel } from '@models/domain/space/SpaceTypes';
import TableRegularContent from '@components/NewDesign/Typography/FormAndTable/TableRegularContent';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';
import { completeOrderedServiceRoutine } from '@screens/HavesDashboard/Orders/routines';
import { extractCompleteOrderedServiceRequestLoading } from '@screens/HavesDashboard/Orders/reducers';
import { ServiceOrderStatus } from '@models/domain/additional_service/ServiceOrderStatus';
import { IServiceOrderDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderDto';
import styles from './styles.module.scss';

interface IServicesTableProps {
  services: IOrderedServiceDto[];
  order: IServiceOrderDto;
}

const ServicesTable = ({ services, order }: IServicesTableProps) => {
  const dispatch = useDispatch();

  const completeServiceLoading = useSelector(extractCompleteOrderedServiceRequestLoading);

  const amountLabel = (service: IOrderedServiceDto) => {
    const unitOne = lowerCase(service.billingUnit);
    const unitPlural = `${unitOne}s`;
    return generateLabel(service.orderedAmount, unitOne, unitPlural);
  };

  const isOrderFinished = order?.status === ServiceOrderStatus.FINISHED;

  const handleServiceComplete = serviceId => () => {
    if (order?.id && serviceId && !completeServiceLoading && !isOrderFinished) {
      dispatch(completeOrderedServiceRoutine.trigger({ orderId: order.id, serviceId }));
    }
  };

  return (
    <NewTable
      titles={['Service', 'Category', 'Type', 'Qty', 'Done']}
      rows={services.map(service => ({
        id: service.id,
        cells: [
          { content: <TableImportantContent>{service.alias}</TableImportantContent> },
          { content: <TableRegularContent>{startCase(lowerCase(service.category))}</TableRegularContent> },
          { content: <TableRegularContent>{startCase(lowerCase(service.type))}</TableRegularContent> },
          { content: <TableRegularContent>{amountLabel(service)}</TableRegularContent> },
          { content: <Checkbox
            checked={service.completed}
            onClick={handleServiceComplete(service.id)}
            disabled={isOrderFinished}
          /> }
        ],
        rowProps: {
          className: styles.row
        }
      }))}
    />
  );
};

export { ServicesTable };
