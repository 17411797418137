import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IServiceShortDto } from '@screens/HavesDashboard/AdditionalServices/model/ServiceShortDto';
import {
  loadAdditionalServicesBuildingTitlesRoutine,
  loadAdditionalServicesListRoutine, setAdditionalServicesPageRoutine,
  setSelectedAdditionalServiceRoutine
} from '@screens/HavesDashboard/AdditionalServices/routines';
import { IServiceDetailsDto } from '@screens/HavesDashboard/AdditionalServices/model/ServiceDetailsDto';
import { IBuildingTitleDto } from '@screens/HavesDashboard/AdditionalServices/model/BuildingTitleDto';
import { getInitialPageableState, IPageableReducerState } from '@models/domain/PageableReducerState';
import { IPageableResult } from '@models/domain/PageableResult';
import { logOutRoutine } from '@screens/Authorization/routines';

export interface IAdditionalServicesReducerState extends IPageableReducerState<IServiceShortDto> {
  selected?: Partial<IServiceDetailsDto>;
  buildings?: IBuildingTitleDto[];
}

const initialState: IAdditionalServicesReducerState = {
  ...getInitialPageableState<IServiceShortDto>()
};

export const additionalServicesReducer = createReducer(initialState, {
  [loadAdditionalServicesListRoutine.SUCCESS]: (
    state, { payload }: PayloadAction<IPageableResult<IServiceShortDto>>
  ) => {
    state.items = payload.items;
    state.totalPages = payload.totalPages;
    state.totalResults = payload.totalResults;
  },
  [setAdditionalServicesPageRoutine.FULFILL]: (state, { payload }: PayloadAction<number>) => {
    state.page = payload;
  },
  [loadAdditionalServicesBuildingTitlesRoutine.SUCCESS]: (
    state, { payload }: PayloadAction<IBuildingTitleDto[]>
  ) => {
    state.buildings = payload;
  },
  [setSelectedAdditionalServiceRoutine.FULFILL]: (
    state, { payload }: PayloadAction<Partial<IServiceDetailsDto> | undefined>
  ) => {
    state.selected = payload;
  },
  [logOutRoutine.SUCCESS]: () => initialState
});
