import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import {
  fetchDashboardMessagesRoutine,
  fetchMyBuildingsRoutine,
  fetchMyTransactionsRoutine,
  readMessageFromDashboardRoutine,
  sendMessageFromDashboardRoutine,
  setWritingMessageFromDashboardRoutine,
  toggleFavoriteBuildingRoutine
} from '@screens/HavesDashboard/Dashboard/routines';
import { toastr } from 'react-redux-toastr';
import buildingsService from '@services/buildings.service';
import transactionsService from '@services/transactions.service';
import { tryToggleFavoriteBuilding } from '@sagas/favorite.building.saga';
import { Routine } from 'redux-saga-routines';
import messageService from '@services/message.service';
import { PayloadAction } from '@reduxjs/toolkit';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import { IBindingCallback1 } from '@models/Callbacks';

function* tryFetchMyBuildings() {
  try {
    const resp = yield call(buildingsService.fetchOwnedBuildings, { page: 1, size: 7 });
    yield put(fetchMyBuildingsRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load spaces', e?.message);
    yield put(fetchMyBuildingsRoutine.failure(e?.message));
  }
}

function* tryFetchMyTransactions() {
  try {
    const resp = yield call(transactionsService.fetchTransactions, { page: 1, size: 5 });
    yield put(fetchMyTransactionsRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load transactions', e?.message);
    yield put(fetchMyTransactionsRoutine.failure(e?.message));
  }
}

export const tryFetchDashboardMessages = (routine: Routine) => function* fetchDashboardMessages(
  { payload = { page: 1, size: 7 } }: Routine<any>
) {
  try {
    const resp = yield call(messageService.getMessages, payload);
    yield put(routine.success(resp));
  } catch (e) {
    toastr.error('Unable to load messages', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const trySendMessage = (
  routine: Routine, setWritingRoutine: Routine, fetchRoutine?: Routine, extracter?: IBindingCallback1<any>
) => function* sendMessageFromDashboard({ payload }: PayloadAction<ISendMessageDto>) {
  try {
    const resp = yield call(messageService.sendMessage, payload);
    yield put(routine.success(resp));
    toastr.success('Success', 'Message has been successfully sent');
    yield put(setWritingRoutine.fulfill(undefined));
    if (fetchRoutine) {
      const pagination = extracter && (yield select(extracter));
      yield put(fetchRoutine.trigger(pagination));
    }
  } catch (e) {
    toastr.error('Unable to send message', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const tryReadMessageFromDashboard = (
  routine: Routine
) => function* readMessageFromDashboard({ payload }: PayloadAction<string>) {
  try {
    yield call(messageService.readMessage, payload);
    yield put(routine.success(payload));
  } catch (e) {
    toastr.error('Unable to read message', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export default function* dashboardPageSagas() {
  yield all([
    yield takeEvery(fetchMyBuildingsRoutine.TRIGGER, tryFetchMyBuildings),
    yield takeEvery(fetchMyTransactionsRoutine.TRIGGER, tryFetchMyTransactions),
    yield takeEvery(toggleFavoriteBuildingRoutine.TRIGGER, tryToggleFavoriteBuilding(toggleFavoriteBuildingRoutine)),
    yield takeEvery(fetchDashboardMessagesRoutine.TRIGGER, tryFetchDashboardMessages(fetchDashboardMessagesRoutine)),
    yield takeEvery(sendMessageFromDashboardRoutine.TRIGGER, trySendMessage(
      sendMessageFromDashboardRoutine, setWritingMessageFromDashboardRoutine, fetchDashboardMessagesRoutine
    )),
    yield takeEvery(readMessageFromDashboardRoutine.TRIGGER, tryReadMessageFromDashboard(
      readMessageFromDashboardRoutine
    ))
  ]);
}
