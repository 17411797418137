import { all, call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  editBookingNotesRoutine,
  toggleFavoriteRoutine
} from '@screens/BookingCheckout/routines';
import { tryToggleFavoriteBuilding } from '@sagas/favorite.building.saga';
import { IEditBookingNotes } from './BookingDetailsCheckoutStep';
import bookingDetailsService from '../../services/booking.details.service';

function* tryEditBookingNotes({ payload }: PayloadAction<IEditBookingNotes>) {
  try {
    const response = yield call(bookingDetailsService.editBookingNotes, payload);
    yield put(editBookingNotesRoutine.success(response));
  } catch (e) {
    toastr.error('Unable to edit booking notes', e?.message);
    yield put(editBookingNotesRoutine.failure(e?.message));
  }
}

export default function* bookingCheckoutPageSagas() {
  yield all([
    yield takeEvery(toggleFavoriteRoutine.TRIGGER, tryToggleFavoriteBuilding(toggleFavoriteRoutine)),
    yield takeEvery(editBookingNotesRoutine.TRIGGER, tryEditBookingNotes)
  ]);
}
