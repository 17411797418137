import React from 'react';
import { IBindingAction, IBindingCallback3 } from '@models/Callbacks';
import noResultsFoundImage from '@images/no_results_found.jpg';
import ImageContainer from '@components/ImageContainer';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import styles from './styles.module.scss';
import UnauthorizedSpacesHelpForm from '@screens/BrowseSpaces/components/UnauthorizedSpacesHelpForm';

interface INoResultsFoundProps {
  onSubmit: IBindingAction;
  onUnauthorizedSubmit: IBindingCallback3<string, string, string>;
  loggedIn: boolean;
}

export const NoResultsFound: React.FC<INoResultsFoundProps> = (
  { onSubmit, loggedIn, onUnauthorizedSubmit }
) => (
  <div className={styles.content_wrapper}>
    <div className={styles.container}>
      <ImageContainer src={noResultsFoundImage} alt="No results found image" className={styles.image} />
      <BodyText1 className={styles.header}>
        Hi! We don’t currently have any active listings that meet your needs.
      </BodyText1>
      {loggedIn
        ? (
          <>
            <BodyText2 className={styles.text}>
              However, we are dedicated to helping you find that perfect space, and will
              go out of our way to look for something that fits your needs.
              Our system has magically captured your space search parameters,
              and we’ll get in touch with you to see if we can find space
              that is perfect for you.
            </BodyText2>
            <BodyText2 className={styles.text}>
              Simply click the &quot;SUBMIT&quot; button below,
              and we will get all of your search information, and start working on it.
            </BodyText2>
            <div className={styles.submit}>
              <PrimaryButton onClick={onSubmit}>SUBMIT</PrimaryButton>
            </div>
          </>
        )
        : (
          <>
            <BodyText2 className={styles.text}>
              However, we are dedicated to helping you find that perfect space,
              and will go out of our way to look for something that fits your needs.
              Our system has magically captured your space search parameters,
              and we’ll get in touch with you to see if we can find space that is perfect for you.
            </BodyText2>
            <BodyText2 className={styles.text}>
              We do need a few basic bits of information, such as your name and email address,
              so we can follow-up with you. Please enter that info below:
            </BodyText2>
            <UnauthorizedSpacesHelpForm onSend={onUnauthorizedSubmit} />
          </>
        )}
    </div>
  </div>
);
