import React, { useCallback } from 'react';
import classNames from 'classnames';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import { IBindingCallback1 } from '@models/Callbacks';
import styles from './styles.module.scss';

type LanguageItemProps = {
  name: string;
  code: string;
  displayName: string;
  selectedLangCode: string;
  onClick: IBindingCallback1<object>;
}

const LanguageItem = ({ name, code, displayName, onClick, selectedLangCode }: LanguageItemProps) => {
  const handleClick = useCallback(() => {
    onClick({ name, code, displayName });
  }, [code, displayName, name, onClick]);

  return (
    <div
      className={classNames(
        styles.language_item,
        { [styles.active]: code === selectedLangCode }
      )}
      onClick={handleClick}
    >
      <Caption2 disableTranslation>{name}</Caption2>
    </div>
  );
};

export { LanguageItem };
