import React, { useEffect, useState } from 'react';
import { IBindingCallback1 } from '@models/Callbacks';
import CounterInput from '@components/CouterInput';
import { toPrice } from '@helpers/price.helper';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import { IServiceIdToAmount } from '@models/domain/additional_service/MakeOrderRequest';
import ServiceItemHeader from '@components/NewDesign/AdditionalServicesOrder/components/ServiceItem/ServiceItemHeader';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';
import styles from './styles.module.scss';

export interface IServiceItemProps {
  service: IAdditionalServiceWithAvailability;
  amountSelected: number;
  onClick: IBindingCallback1<IServiceIdToAmount>;
  initialAmount?: number;
  showLocalSum?: boolean;
  hideCounterInput?: boolean;
}

const ServiceItem: React.FC<IServiceItemProps> = (
  {
    service, amountSelected, onClick,
    initialAmount, showLocalSum, hideCounterInput
  }
) => {
  const [tempAmountSelected, setAmountSelected] = useState<number>(initialAmount || 0);

  useEffect(
    () => {
      setAmountSelected(amountSelected);
    },
    [amountSelected]
  );

  const changeAmount = (amount: number) => {
    setAmountSelected(amount);
    onClick({
      amount,
      serviceId: service.id
    });
  };

  return (
    <div className={styles.card}>
      <ServiceItemHeader service={service} />
      {!hideCounterInput && (
        <div className={styles.selection}>
          <CounterInput
            disabled={service.availableAmount === 0}
            max={service.availableAmount}
            min={0}
            onChange={changeAmount}
            value={tempAmountSelected}
          />
          <Caption1 className={styles.total}>
            {showLocalSum && !!amountSelected && (
              <>
                {toPrice({ amount: service.price.amount * amountSelected, currency: service.price.currency })}
              </>
            )}
          </Caption1>
        </div>
      )}
    </div>
  );
};

export default ServiceItem;
