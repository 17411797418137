import { callApi } from '@helpers/api.helper';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';

const messageService = {
  getMessages: ({ page, size }: IPageRequest) => callApi({
    endpoint: 'message',
    method: 'GET',
    queryParams: {
      page: page - 1,
      size
    }
  }),
  sendMessage: (requestData: ISendMessageDto) => callApi({
    endpoint: 'message/send',
    method: 'POST',
    requestData
  }),
  readMessage: (id: string) => callApi({
    endpoint: `message/read/${id}`,
    method: 'POST'
  })
};

export default messageService;
