import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchMyBuildingsRoutine,
  fetchMyTransactionsRoutine,
  toggleFavoriteBuildingRoutine,

  fetchDashboardMessagesRoutine,
  readMessageFromDashboardRoutine,
  sendMessageFromDashboardRoutine,
  setWritingMessageFromDashboardRoutine
} from '@screens/NeedsDashboard/Dashboard/routines';
import transactionsService from '@services/transactions.service';
import buildingsService from '@services/buildings.service';
import { tryToggleFavoriteBuilding } from '@sagas/favorite.building.saga';
import { tryFetchDashboardMessages, trySendMessage, tryReadMessageFromDashboard }
  from '@screens/HavesDashboard/Dashboard/containers/DashboardPage/sagas';

function* tryFetchMyTransactions() {
  try {
    const resp = yield call(transactionsService.fetchTransactions, { page: 1, size: 5 });
    yield put(fetchMyTransactionsRoutine.success(resp));
  } catch (e) {
    yield put(fetchMyTransactionsRoutine.failure(e?.message));
  }
}

function* tryFetchMyBuildings() {
  try {
    const resp = yield call(buildingsService.fetchBookedBuildings, { page: 1, size: 7 });
    yield put(fetchMyBuildingsRoutine.success(resp));
  } catch (e) {
    yield put(fetchMyBuildingsRoutine.failure(e?.message));
  }
}

export default function* dashboardPageSagas() {
  yield all([
    takeEvery(fetchMyTransactionsRoutine.TRIGGER, tryFetchMyTransactions),
    takeEvery(fetchMyBuildingsRoutine.TRIGGER, tryFetchMyBuildings),
    takeEvery(toggleFavoriteBuildingRoutine.TRIGGER, tryToggleFavoriteBuilding(toggleFavoriteBuildingRoutine)),

    yield takeEvery(fetchDashboardMessagesRoutine.TRIGGER, tryFetchDashboardMessages(fetchDashboardMessagesRoutine)),
    yield takeEvery(sendMessageFromDashboardRoutine.TRIGGER, trySendMessage(
      sendMessageFromDashboardRoutine, setWritingMessageFromDashboardRoutine, fetchDashboardMessagesRoutine
    )),
    yield takeEvery(readMessageFromDashboardRoutine.TRIGGER, tryReadMessageFromDashboard(
      readMessageFromDashboardRoutine
    ))
  ]);
}
