import React from 'react';
import { Helmet } from 'react-helmet';
import { MAIN_DESCRIPTION } from '@screens/Landing/components/WhySharedSpacesBlock';

export interface IMetaTagsProps {
  title: string;
  description?: string;
  withSuffix?: boolean;
}

const MetaTags: React.FC<IMetaTagsProps> = (
  { title, description, withSuffix }
) => (
  <div>
    <Helmet>
      <title>
        {
          withSuffix
            ? `${title} | Shared Spaces`
            : title
        }
      </title>
      <meta
        name="description"
        content={description || MAIN_DESCRIPTION}
      />
    </Helmet>
  </div>
);

export default MetaTags;
