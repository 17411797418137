import React from 'react';
import moment from 'moment';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import { ADMIN_DASHBOARD_ENDPOINTS } from '@screens/AdminDashboard/Root/components/Routing/endpoints';
import PayoutsTable from '@screens/AdminDashboard/Payouts/containers/PayoutsTable';
import { DATE_FORMAT } from '@helpers/date.helper';
import { toPrice } from '@helpers/price.helper';
import { linkWithStripeDomain, STRIPE_ENDPOINTS } from '@helpers/stripe.link.helper';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import styles from './styles.module.scss';

export interface IPayoutsHistoryProps {
}

const PayoutsHistory: React.FC<IPayoutsHistoryProps> = () => (
  <DashboardPageWrapper title="Payouts History">
    <div className={styles.container}>
      <div className={styles.back_button_container}>
        <SmallLinkButton to={ADMIN_DASHBOARD_ENDPOINTS.PAYOUTS} left>
          Back to Payouts
        </SmallLinkButton>
      </div>
      <div className={styles.tiles_container}>
        <PayoutsTable
          tableProps={payoutsHistory => ({
            titles: ['Created At', 'Price', 'Open In Stripe'],
            rows: payoutsHistory.map(payout => ({
              id: payout.id,
              cells: [
                { content: moment(payout.createdAt).format(`HH:mm:ss ${DATE_FORMAT}`) },
                { content: toPrice(payout.price) },
                { content: (
                  <a
                    href={linkWithStripeDomain(STRIPE_ENDPOINTS.PAYOUTS(payout.stripeId))}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.open_stripe}
                  >
                    <Caption2>Open</Caption2>
                  </a>
                ) }
              ],
              rowProps: {
              }
            }))
          })}
        />
      </div>
    </div>
  </DashboardPageWrapper>
);

export default PayoutsHistory;
