import { connect } from 'react-redux';
import {
  extractAvatar,
  extractLoadProfileDetailsLoading,
  extractProfileData,
  extractSaveProfileDetailsLoading,
  extractUploadAvatarLoading,
  extractUpdatePasswordLoading,
  extractUpdatePasswordModalOpen
} from '@screens/HavesDashboard/Account/reducers';
import {
  loadProfileDetailsRoutine,
  saveProfileDetailsRoutine,
  uploadAvatarRoutine,
  setUpdatePasswordModalOpenRoutine,
  updatePasswordRoutine
} from '@screens/HavesDashboard/Account/routines';
import { Account } from '@screens/NeedsDashboard/Account/containers/AccountPage';

const mapStateToProps = state => ({
  savingLoading: extractSaveProfileDetailsLoading(state),
  profileData: extractProfileData(state),
  profileDataLoading: extractLoadProfileDetailsLoading(state),
  avatarUploading: extractUploadAvatarLoading(state),
  avatarSrc: extractAvatar(state),
  updatePasswordModalOpen: extractUpdatePasswordModalOpen(state),
  updatePasswordLoading: extractUpdatePasswordLoading(state)
});

const mapDispatchToProps = {
  loadDetails: loadProfileDetailsRoutine,
  saveChanges: saveProfileDetailsRoutine,
  uploadAvatar: uploadAvatarRoutine,
  updatePassword: updatePasswordRoutine,
  setUpdatePasswordModalOpen: setUpdatePasswordModalOpenRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
