import React from 'react';
import styles from './styles.module.scss';
import { IServiceShortDto } from '@screens/HavesDashboard/AdditionalServices/model/ServiceShortDto';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';
import { toPrice } from '@helpers/price.helper';
import { IBindingAction } from '@models/Callbacks';

export interface IAdditionalServiceTileProps {
  service: IServiceShortDto;
  onClick: IBindingAction;
}

const AdditionalServiceTile: React.FC<IAdditionalServiceTileProps> = (
  { service, onClick }
) => (
  <div className={styles.container} onClick={onClick}>
    <LabelGroupWrapper title="Name" value={service.alias} />
    <LabelGroupWrapper title="Category" value={service.category} />
    <LabelGroupWrapper title="Type" value={service.type} />
    <LabelGroupWrapper title="Price" value={`${toPrice(service.price)}/${service.billingUnit}`} />
  </div>
);

export default AdditionalServiceTile;
