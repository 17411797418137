import React, { useState } from 'react';
import * as Yup from 'yup';
import { FormikProps, withFormik } from 'formik';
import { Form, FormGroup, Icon } from 'semantic-ui-react';
import { IBindingCallback1 } from '@models/Callbacks';
import { IRegistrationData } from '@screens/Authorization/models/RegistrationData';
import {
  emailValidation,
  passwordValidation,
  repeatPasswordValidation
} from '@screens/Authorization/components/validation';
import ErrorPopup from '@components/FormErrorPopup';
import FormikInput from '@components/formik/Input';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import Caption3 from '@components/NewDesign/Typography/Caption/Caption3';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import Caption4 from '@components/NewDesign/Typography/Caption/Caption4';
import { LinkWithTranslate } from '@components/WithTranslate/LinkWithTranslate';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

export interface IRegistrationFormProps {
  onRegisterClick: IBindingCallback1<IRegistrationData>;
  registrationLoading: boolean;
}

const validationSchema = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
  repeatPassword: repeatPasswordValidation,
  agreed: Yup.boolean()
    .oneOf([true], 'Must Accept Terms and Conditions')
});

interface IFormValues {
  email: string;
  password: string;
  repeatPassword: string;
  agreed: boolean;
}

export const PASSWORD_REQUIREMENTS = '* A valid password MUST be at least 8 characters long, '
  + 'must contain at least: one digit, one UPPERCASE letter, one lowercase letter, '
  + 'and one special character (!@#$%^&*?)';
export const CONFIRM_PASSWORD_REQUIREMENTS = '* Passwords must MATCH';

const RegistrationForm: React.FC<IRegistrationFormProps & FormikProps<IFormValues>> = props => {
  const { values, touched, errors, handleSubmit, registrationLoading, setFieldValue } = props;

  const { t } = useTranslation();

  const [passwordShown, setPasswordShown] = useState(false);
  const [repeatPasswordShown, setRepeatPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleRepeatPasswordVisibility = () => {
    setRepeatPasswordShown(!repeatPasswordShown);
  };

  return (
    <Form name="loginForm" onSubmit={handleSubmit}>
      <div className={styles.inputs_group}>
        <FormikInput
          propsOrFieldName={{
            name: 'email',
            autoFocus: true,
            placeholder: 'Email Address',
            autoComplete: 'email'
          }}
        />
        <div className={styles.password_group}>
          <div className={styles.passwordWrapper}>
            <FormikInput
              propsOrFieldName={{
                name: 'password',
                placeholder: 'Password',
                type: passwordShown ? 'text' : 'password',
                autoComplete: 'new-password'
              }}
            />
            <Icon name="eye" className={styles.showPassIcon} onClick={togglePasswordVisibility} />
          </div>
          <Caption4 className={styles.inputGuide}>
            {PASSWORD_REQUIREMENTS}
          </Caption4>
        </div>
        <div className={styles.password_group}>
          <div className={styles.passwordWrapper}>
            <FormikInput
              propsOrFieldName={{
                name: 'repeatPassword',
                type: repeatPasswordShown ? 'text' : 'password',
                placeholder: 'Repeat password',
                autoComplete: 'new-password'
              }}
            />
            <Icon name="eye" className={styles.showPassIcon} onClick={toggleRepeatPasswordVisibility} />
          </div>
          <Caption4 className={styles.inputGuide}>
            {CONFIRM_PASSWORD_REQUIREMENTS}
          </Caption4>
        </div>
      </div>
      <ErrorPopup
        open={!!(touched.agreed && errors.agreed)}
        label={errors.agreed}
        component={(
          <FormGroup className={styles.terms}>
            <Form.Checkbox
              name="agreed"
              onChange={(e, { name, checked }) => setFieldValue(name, checked)}
              error={errors.agreed && touched.agreed}
            />
            <Caption3 disableTranslation>
              {t('I agree to')}
              &nbsp;
              <LinkWithTranslate to={ENDPOINTS.TERMS_AND_CONDITIONS}>terms and conditions</LinkWithTranslate>
            </Caption3>
          </FormGroup>
        )}
      />
      <div className={styles.buttons_section}>
        <PrimaryButton
          className={styles.login_button}
          type="submit"
          disabled={!values.password || !values.email || !values.agreed || !values.repeatPassword}
          content="Register"
          loading={registrationLoading}
        />
      </div>
    </Form>
  );
};

export default withFormik<IRegistrationFormProps, IFormValues>({
  displayName: 'RegistrationForm',
  validationSchema,
  handleSubmit: ((values, { props }) => {
    props.onRegisterClick({ email: values.email, password: values.password });
  })
})(RegistrationForm);
