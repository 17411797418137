import { all } from 'redux-saga/effects';
import contactUsSagas from '@screens/static/GetInTouch/sagas';
import joinOurTeamSagas from '@screens/static/JoinOurTeam/sagas';
import partnerPageSagas from '@screens/static/Partner/sagas';
import expertPageSagas from '@screens/static/Expert/sagas';

export default function* staticSagas() {
  yield all([
    contactUsSagas(),
    joinOurTeamSagas(),
    partnerPageSagas(),
    expertPageSagas()
  ]);
}
