import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import OrderDetails from '@screens/HavesDashboard/Orders/components/OrderDetails';
import BuildingDetails from '@containers/Schedules/BookingDetailsModal/BuildingDetails';
import ServicesDetails from '@screens/HavesDashboard/Orders/components/ServicesDetails';
import { extractExpandedOrder } from '@screens/HavesDashboard/Orders/reducers';
import { completeOrderRoutine, setExpandedOrderRoutine } from '@screens/HavesDashboard/Orders/routines';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import { ServiceOrderStatus } from '@models/domain/additional_service/ServiceOrderStatus';
import ConfirmModal from '@components/NewDesign/ConfirmModal';
import styles from './styles.module.scss';

const OrderDetailsModal = () => {
  const dispatch = useDispatch();
  const expandedOrder = useSelector(extractExpandedOrder);
  const { order, services, building } = expandedOrder || {};
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  const handleResetExpandedOrder = useCallback(() => {
    dispatch(setExpandedOrderRoutine.fulfill(null));
  }, [dispatch]);

  const handleCloseConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const handleOpenConfirmationModal = useCallback(() => {
    setShowConfirmationModal(true);
  }, []);

  const isAllCompleted = services?.every(service => service.completed);

  const handleCompleteOrder = useCallback(() => {
    if (order?.id && isAllCompleted) {
      dispatch(completeOrderRoutine.trigger(order.id));
      handleCloseConfirmationModal();
    }
  }, [dispatch, handleCloseConfirmationModal, isAllCompleted, order]);

  const isOrderFinished = order?.status === ServiceOrderStatus.FINISHED;

  return (
    <>
      <Modal
        open={!!expandedOrder}
        onClose={handleResetExpandedOrder}
        closeIcon
      >
        <ModalHeaderWrapper>Order details</ModalHeaderWrapper>
        <ModalContent>
          <OrderDetails order={order} />
          <BuildingDetails building={building} />
          <ServicesDetails services={services} order={order} />
        </ModalContent>
        <ModalActions className={styles.actions}>
          <PrimaryButton
            disabled={!isAllCompleted || isOrderFinished}
            content={isOrderFinished ? 'ORDER COMPLETED' : 'COMPLETE ORDER'}
            icon="check square outline"
            labelPosition="left"
            onClick={handleOpenConfirmationModal}
          />
        </ModalActions>
      </Modal>
      <ConfirmModal
        open={showConfirmationModal}
        onCancel={handleCloseConfirmationModal}
        onConfirm={handleCompleteOrder}
        header="Complete order"
      />
    </>
  );
};

export default OrderDetailsModal;
