import React from 'react';
import { Switch } from 'react-router-dom';
import { TERMS_AND_CONDITIONS_ENDPOINTS } from './endpoints';
import AppRoute from '@containers/AppRoute';
import NotFound from '@components/404NotFound';
import PdfPage from '@screens/TermsAndConditions/Root/components/PdfPage';
import { fileNameToS3Url } from '@helpers/s3.helper';
import ScrollToTopOnMount from '@components/ScrollToTop';

const basicRequirementsForHostsPdf = fileNameToS3Url('Shared_Spaces_Basic_Requirements_for_Hosts.pdf');
const communityStandardsPdf = fileNameToS3Url('Shared_Spaces_Community_Standards.pdf');
const contentPolicyPdf = fileNameToS3Url('Shared_Spaces_Content_Policy.pdf');
const cookiePolicyPdf = fileNameToS3Url('Shared_Spaces_Cookie_Policy.pdf');
const copyrightPolicyPdf = fileNameToS3Url('Shared_Spaces_Copyright_Policy.pdf');
const dataSubjectRightsPdf = fileNameToS3Url('Shared_Spaces_Data_Subject_Rights.pdf');
const disputeModerationForReviewsPdf = fileNameToS3Url('Shared_Spaces_Dispute_Moderation_for_Reviews.pdf');
const extenuatingCircumstancesPolicyPdf = fileNameToS3Url('Shared_Spaces_Extenuating_Circumstances_Policy.pdf');
const hostPrivacyStandardsPdf = fileNameToS3Url('Shared_Spaces_Host_Privacy_Standards.pdf');
const lawEnforcementGuidelinesPdf = fileNameToS3Url('Shared_Spaces_Law_Enforcement_Guidelines.pdf');
const nondiscriminationPolicyPdf = fileNameToS3Url('Shared_Spaces_Nondiscrimination_Policy.pdf');
const offPlatformPolicyPdf = fileNameToS3Url('Shared_Spaces_Off_Platform_Policy.pdf');
const optimizingSearchResultsPdf = fileNameToS3Url('Shared_Spaces_Optimizing_Search_Results.pdf');
const paymentTermsOfServicePdf = fileNameToS3Url('Shared_Spaces_Payments_Terms_of_Service.pdf');
const privacyPolicyPdf = fileNameToS3Url('Shared_Spaces_Privacy_Policy.pdf');
const privacySupplementOutsideOfTheUsPdf = fileNameToS3Url('Shared_Spaces_Privacy_Supplement_Outside_of_the_United_States.pdf');
const referralProgramTermsAndConditionsPdf = fileNameToS3Url('Shared_Spaces_Referral_Program_Terms_and_Conditions.pdf');
const reviewPolicyPdf = fileNameToS3Url('Shared_Spaces_Review_Policy.pdf');
const serviceFeesPdf = fileNameToS3Url('Shared_Spaces_Service_Fees.pdf');
const smsTermsForTheUsPdf = fileNameToS3Url('Shared_Spaces_SMS_Terms_for_the_United_States.pdf');
const termsAndConditionsPdf = fileNameToS3Url('Shared_Spaces_Terms_and_Conditions.pdf');
const termsAndPoliciesListPdf = fileNameToS3Url('Shared_Spaces_Terms_and_Policies_List.pdf');
const weatherEventsExcludedFromExtenuatingPdf = fileNameToS3Url('Shared_Spaces_Weather_Events_Excluded_From_Extenuating.pdf');

export interface ILegalDocumentDto {
  path: string;
  title: string;
  document: string;
}

const {
  BASIC_REQUIREMENTS_FOR_HOSTS, COMMUNITY_STANDARDS,
  CONTENT_POLICY, COOKIE_POLICY, COPYRIGHT_POLICY,
  DATA_SUBJECT_RIGHTS, DISPUTE_MODERATION_FOR_REVIEWS,
  EXTENUATING_CIRCUMSTANCES_POLICY, HOST_PRIVACY_STANDARDS,
  LAW_ENFORCEMENT_GUIDELINES, NONDISCRIMINATION_POLICY,
  OFF_PLATFORM_POLICY, OPTIMIZING_SEARCH_RESULTS,
  PAYMENT_TERMS_OF_SERVICE, PRIVACY_POLICY,
  PRIVACY_SUPPLEMENT_OUTSIDE_OF_THE_US,
  REFERRAL_PROGRAM_TERMS_AND_CONDITIONS,
  REVIEW_POLICY, SERVICE_FEES,
  SMS_TERMS_FOR_THE_US, TERMS_AND_CONDITIONS,
  TERMS_AND_POLICIES_LIST, WEATHER_EVENTS_EXCLUDED_FROM_EXTENUATING
} = TERMS_AND_CONDITIONS_ENDPOINTS;

export const legalDocumentsContent: ILegalDocumentDto[] = [
  { title: 'Terms and Policies List', path: TERMS_AND_POLICIES_LIST, document: termsAndPoliciesListPdf },
  { title: 'Terms and Conditions', path: TERMS_AND_CONDITIONS, document: termsAndConditionsPdf },
  { title: 'Payments Terms of Service', path: PAYMENT_TERMS_OF_SERVICE, document: paymentTermsOfServicePdf },
  { title: 'Privacy Policy', path: PRIVACY_POLICY, document: privacyPolicyPdf },
  { title: 'Privacy Supplement Outside of the US', path: PRIVACY_SUPPLEMENT_OUTSIDE_OF_THE_US, document: privacySupplementOutsideOfTheUsPdf },
  { title: 'Basic Requirements for Hosts', path: BASIC_REQUIREMENTS_FOR_HOSTS, document: basicRequirementsForHostsPdf },
  { title: 'Service Fees', path: SERVICE_FEES, document: serviceFeesPdf },
  { title: 'Copyright Policy', path: COPYRIGHT_POLICY, document: copyrightPolicyPdf },
  { title: 'Extenuating Circumstances Policy', path: EXTENUATING_CIRCUMSTANCES_POLICY, document: extenuatingCircumstancesPolicyPdf },
  { title: 'SMS Terms for the US', path: SMS_TERMS_FOR_THE_US, document: smsTermsForTheUsPdf },
  { title: 'Community Standards', path: COMMUNITY_STANDARDS, document: communityStandardsPdf },
  { title: 'Content Policy', path: CONTENT_POLICY, document: contentPolicyPdf },
  { title: 'Cookie Policy', path: COOKIE_POLICY, document: cookiePolicyPdf },
  { title: 'Data Subject Rights', path: DATA_SUBJECT_RIGHTS, document: dataSubjectRightsPdf },
  { title: 'Dispute Moderation for Reviews', path: DISPUTE_MODERATION_FOR_REVIEWS, document: disputeModerationForReviewsPdf },
  { title: 'Host Privacy Standards', path: HOST_PRIVACY_STANDARDS, document: hostPrivacyStandardsPdf },
  { title: 'Law Enforcement Guidelines', path: LAW_ENFORCEMENT_GUIDELINES, document: lawEnforcementGuidelinesPdf },
  { title: 'Nondiscrimination Policy', path: NONDISCRIMINATION_POLICY, document: nondiscriminationPolicyPdf },
  { title: 'Off Platform Policy', path: OFF_PLATFORM_POLICY, document: offPlatformPolicyPdf },
  { title: 'Optimizing Search Results', path: OPTIMIZING_SEARCH_RESULTS, document: optimizingSearchResultsPdf },
  { title: 'Referral Program Terms and Conditions', path: REFERRAL_PROGRAM_TERMS_AND_CONDITIONS, document: referralProgramTermsAndConditionsPdf },
  { title: 'Review Policy', path: REVIEW_POLICY, document: reviewPolicyPdf },
  { title: 'Weather Events Excluded from Extenuating', path: WEATHER_EVENTS_EXCLUDED_FROM_EXTENUATING, document: weatherEventsExcludedFromExtenuatingPdf }
];

const TermsAndConditionsRouting: React.FC = () => (
  <>
    <ScrollToTopOnMount />
    <Switch>
      {legalDocumentsContent.map(doc => (
        <AppRoute
          lightBlue
          exact
          path={doc.path}
          component={
            () => <PdfPage documentDto={doc} />
          }
        />
      ))}
      <AppRoute component={NotFound} lightBlue />
    </Switch>
  </>
);

export default TermsAndConditionsRouting;
