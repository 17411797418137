import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { IBindingAction } from '@models/Callbacks';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import styles from './styles.module.scss';

export interface ITimerProps {
  expirationDate: Date;
  onExpiration?: IBindingAction;
  className?: string;
  inline?: boolean;
}

const Timer: React.FC<ITimerProps> = (
  { expirationDate, onExpiration, className, inline }
) => {
  const Ref = useRef(null);

  const getTotalTimeRemaining = useCallback(() => expirationDate.getTime() - new Date().getTime(), [expirationDate]);

  const getTimeRemaining = useCallback(() => {
    const total = getTotalTimeRemaining();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return { total, minutes, seconds };
  }, [getTotalTimeRemaining]);

  const getTimeRemainingString = useCallback(() => {
    const { total, minutes, seconds } = getTimeRemaining();
    if (total >= 0) {
      return `
        ${minutes > 9 ? minutes : `0${minutes}`}
        :
        ${seconds > 9 ? seconds : `0${seconds}`}
      `;
    }
    return '00:00';
  }, [getTimeRemaining]);

  const [timer, setTimer] = useState(getTimeRemainingString());

  const updateTimer = useCallback(() => {
    if (getTotalTimeRemaining() >= 0) {
      setTimer(getTimeRemainingString());
    } else {
      clearInterval(Ref.current);
      if (onExpiration) {
        onExpiration();
      }
    }
  }, [getTimeRemainingString, getTotalTimeRemaining, onExpiration]);

  useEffect(() => {
    if (Ref.current) clearInterval(Ref.current);
    Ref.current = setInterval(() => {
      updateTimer();
    }, 1000);

    return () => clearInterval(Ref.current);
  }, [updateTimer]);

  return (
    <div
      className={classNames(
        styles.container,
        className,
        inline ? styles.inline : styles.full
      )}
    >
      <SubHeader1 disableTranslation className={styles.timer_numbers}>{ timer }</SubHeader1>
    </div>
  );
};

export default Timer;
