import { toastr } from 'react-redux-toastr';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import stripePaymentService from '@screens/BookingCheckout/services/payment.service';
import {
  loadPayoutsHistoryRoutine
} from '@screens/AdminDashboard/Payouts/routines';
import { PayloadAction } from '@reduxjs/toolkit';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';

function* tryLoadPayoutsHistory({ payload }: PayloadAction<IPageRequest>) {
  try {
    const resp = yield call(stripePaymentService.payoutsHistory, payload);
    yield put(loadPayoutsHistoryRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load payouts history', e?.message);
    yield put(loadPayoutsHistoryRoutine.failure());
  }
}

export default function* payoutsHistoryPageSagas() {
  yield all([
    yield takeEvery(loadPayoutsHistoryRoutine.TRIGGER, tryLoadPayoutsHistory)
  ]);
}
