/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { ICreditCard } from '@screens/CreditCardConfiguration/model/CreditCard';
import { Stripe } from '@stripe/stripe-js';
import NewCardModal, { INewCardExternalProps } from '@screens/CreditCardConfiguration/components/NewCardModal';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import SelectWrapper from '@components/NewDesign/Select';
import { TransactionType } from '@models/domain/transaction/TransactionType';
import styles from './styles.module.scss';

export interface IStripeFormProps {
  className?: string;
  loadCards: IBindingAction;
  cardsLoading: boolean;
  creditCards: ICreditCard[];
  newCardProps: INewCardExternalProps;
  selectPM: IBindingCallback1<IPaymentMethodWithTitle>;
  userEmail: string;
}

export interface IStripePaymentRequest {
  paymentMethodId: string;
  transactionId: string;
  transactionType: TransactionType;
}

export interface IPaymentRequestWithStripe extends IStripePaymentRequest {
  stripe: Stripe;
}

export interface IPaymentMethodWithTitle {
  id: string;
  title: string;
}

const cardToTitle = (card: ICreditCard) => `${card.brand.toUpperCase()} **** **** **** ${card.last4}`;

const StripeCardChoiceForm: React.FC<IStripeFormProps> = (
  {
    className, creditCards,
    cardsLoading, loadCards, userEmail, newCardProps: { addNewCard, addNewCardError, addNewCardLoading },
    selectPM
  }
) => {
  const defaultPm = useCallback(() => {
    const card = creditCards.find(cc => cc.primary)
      || (creditCards.length > 0 && creditCards[0]);
    return card && { id: card.id, title: cardToTitle(card) };
  }, [creditCards]);

  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethodWithTitle | undefined>(defaultPm());
  const [openNewCardModal, setOpenNewCardModal] = useState(false);

  useEffect(() => {
    loadCards();
  }, [loadCards]);

  useEffect(() => {
    setPaymentMethod(defaultPm());
  }, [defaultPm]);

  return (
    <>
      <Form
        className={className}
        loading={cardsLoading}
        onSubmit={() => selectPM(paymentMethod)}
      >
        <div className={styles.headerWrapper}>
          <SubHeader1 className={styles.title}>Pay with Card</SubHeader1>
          <SmallLinkButton
            to=""
            onClick={() => setOpenNewCardModal(true)}
            right
          >
            Add new card
          </SmallLinkButton>
        </div>
        <SelectWrapper
          options={creditCards.map(cc => (
            {
              text: cardToTitle(cc),
              value: cc.id
            }))}
          onChange={(event, obj) => {
            setPaymentMethod({
              id: obj.value as string,
              title: obj.options.find(o => o.value === obj.value).text as string
            });
          }}
          value={paymentMethod?.id}
          className={styles.select}
          placeholder="No cards"
        />
        <div>
          <PrimaryButton
            type="submit"
            content="Save & Continue"
            disabled={!paymentMethod}
          />
        </div>
      </Form>
      <NewCardModal
        email={userEmail}
        addNewCardError={addNewCardError}
        addNewCardLoading={addNewCardLoading}
        open={openNewCardModal}
        setOpen={setOpenNewCardModal}
        addNewCard={addNewCard}
      />
    </>
  );
};

export default StripeCardChoiceForm;
