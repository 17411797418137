import React, { useEffect } from 'react';
import { Modal, ModalContent } from 'semantic-ui-react';
import { IMessageDto, MessageDirection } from '@models/domain/message/MessageDto';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import WritingMessageForm from '@components/MessagesSection/WritingMessageForm';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import styles from './styles.module.scss';

interface IFullMessageModal {
  message?: IMessageDto;
  onClose: IBindingAction;
  onSend: IBindingCallback1<ISendMessageDto>;
  sendLoading: boolean;
}

const FullMessageModal: React.FC<IFullMessageModal> = (
  { message, onClose, onSend, sendLoading }
) => {
  useEffect(() => {
    if (message && !sendLoading) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendLoading]);

  const companion = message?.companion;

  return (
    <Modal open={!!message} size="small" closeIcon onClose={onClose}>
      <ModalHeaderWrapper>
        Message
      </ModalHeaderWrapper>
      <ModalContent>
        <div className={styles.text_pair}>
          <Caption2 disableWordBreak>
            {message?.direction === MessageDirection.INCOMING
              ? 'From:'
              : 'To:'}
            &nbsp;
          </Caption2>
          <Caption1 disableTranslation>
            {companion?.firstName}
            {' '}
            {companion?.lastName}
          </Caption1>
        </div>
        <div className={styles.text_pair}>
          <Caption2 disableWordBreak>
            Topic:&nbsp;
          </Caption2>
          <Caption1 disableTranslation>
            {message?.topic}
          </Caption1>
        </div>
        <div className={styles.text}>
          <BodyText2 disableTranslation>
            {message?.text}
          </BodyText2>
        </div>
      </ModalContent>
      <ModalContent>
        {message && (
          <WritingMessageForm
            onSend={onSend}
            loading={sendLoading}
            receiverId={message.companion.id}
            initValues={{
              topic: message.topic
            }}
            hideTopic
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default FullMessageModal;
