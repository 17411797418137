import React from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { IBindingCallback1 } from '@models/Callbacks';
import { extractRoleSelectionError, extractRoleSelectionLoading } from '@screens/Authorization/reducers';
import { selectRolesRoutine } from '@screens/Authorization/routines';
import { Role } from '@screens/Authorization/models/Roles';
import PaperAirplaneHeader from '@components/NewDesign/PaperAirplaneHeader';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import InfoPopup from '@components/NewDesign/InfoPopup';
import styles from './styles.module.scss';

export interface IActivatedEmailRoleSelectProps {
  selectRoles: IBindingCallback1<Role[]>;
  errorMessage: string;
  loading: boolean;
}

const ActivatedEmailRoleSelect: React.FC<IActivatedEmailRoleSelectProps> = ({ selectRoles, loading, errorMessage }) => {
  const handleHaveClick = () => {
    selectRoles([Role.HAVE]);
  };

  const handleNeedClick = () => {
    selectRoles([Role.NEED]);
  };

  return (
    <div className={styles.content_wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <PaperAirplaneHeader headerText="Your email has been verified!" />
          <BodyText1 className={styles.description_text}>
            Finish your account sign-up by selecting the type of account you’d like to create.
            Select carefully, because once you create one type of account, you cannot change it.
          </BodyText1>
        </div>
        <SubHeader1 className={styles.role_selection_text}>Continue creating your account as a: </SubHeader1>
        <div className={styles.role_selection_buttons}>
          <div className={styles.button_group}>
            <PrimaryButton content="Have" color="olive" onClick={handleHaveClick} loading={loading} />
            <InfoPopup
              header="HAVE account"
              content="This account type will allow you to list spaces you want to share/host.
                     If you would also like to find spaces, you will have to create a second account."
            />
          </div>
          <div className={styles.button_group}>
            <PrimaryButton content="Need" color="olive" onClick={handleNeedClick} loading={loading} />
            <InfoPopup
              header="NEED account"
              content="This account type will allow you to browse/find spaces.
                     If you would also like to list your own spaces, you will have to create a second account."
            />
          </div>
        </div>
        {errorMessage && <Message error>{errorMessage}</Message>}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  loading: extractRoleSelectionLoading(state),
  errorMessage: extractRoleSelectionError(state)
});

const mapDispatchToProps = {
  selectRoles: selectRolesRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivatedEmailRoleSelect);

