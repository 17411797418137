import React, { useEffect } from 'react';
import { history } from '@helpers/history.helper';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import Header4 from '@components/NewDesign/Typography/Headers/Header4';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import styles from './styles.module.scss';

const NotFound: React.FC = () => {
  useEffect(() => {
    history.replace({ state: { footerLightBlue: true } });
  }, []);

  return (
    <div className={`${styles.container} content_wrapper`}>
      <Header4 marginTop>404</Header4>
      <SubHeader1>Looks like we can&apos;t find that page</SubHeader1>
      <BodyText2>
        It appears that you have reached this page in error, and our servers cannot find what you are looking for.
        Let’s try this again, and start from our Home Page.
      </BodyText2>
      <GoToButton to={ENDPOINTS.ROOT}>Return to Main Page</GoToButton>
    </div>
  );
};

export default NotFound;
