import { all } from 'redux-saga/effects';
import payoutsPageSagas from '@screens/AdminDashboard/Payouts/containers/PayoutsPage/sagas';
import payoutsHistoryPageSagas from '@screens/AdminDashboard/Payouts/containers/PayoutsHistoryPage/sagas';

export default function* payoutsSagas() {
  yield all([
    payoutsPageSagas(),
    payoutsHistoryPageSagas()
  ]);
}
