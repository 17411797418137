import React from 'react';
import DateSelector, { IDateSelectorProps } from '@screens/Landing/components/search/selectors/DateSelector';
import styles from './styles.module.scss';

const DateSelectorWrapper: React.FC<IDateSelectorProps> = props => (
  <div className={styles.date_selector_wrapper}>
    <DateSelector {...props} />
  </div>
);
export default DateSelectorWrapper;
