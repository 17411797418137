import { IDatesData } from '@screens/BrowseSpaces/model/QueryData';
import { isNil } from 'lodash';
import moment from 'moment';
import { DATE_FORMAT } from '@helpers/date.helper';

export const textFromDates = (dates?: IDatesData) => {
  if (isNil(dates?.startingDate)) {
    return 'Pick a date';
  }
  if (isNil(dates?.endingDate)) {
    return moment(dates.startingDate).format(DATE_FORMAT);
  }
  return `${moment(dates.startingDate).format(DATE_FORMAT)} - ${
    moment(dates.endingDate).format(DATE_FORMAT)}`;
};
