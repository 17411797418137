import { SemanticCOLORS } from 'semantic-ui-react';

export enum ServiceOrderStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  RETRYING = 'RETRYING',
  FAILED = 'FAILED'
}

export const colorOfStatus: (status: ServiceOrderStatus) => SemanticCOLORS = status => {
  switch (status) {
    case ServiceOrderStatus.CANCELLED:
      return 'red';
    case ServiceOrderStatus.CONFIRMED:
      return 'green';
    case ServiceOrderStatus.PENDING:
      return 'yellow';
    case ServiceOrderStatus.FINISHED:
      return 'teal';
    case ServiceOrderStatus.RETRYING:
      return 'orange';
    case ServiceOrderStatus.FAILED:
      return 'black';
    default:
      return undefined;
  }
};
