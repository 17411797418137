import { callApi } from '@helpers/api.helper';
import { UserAutocompleteParams } from '@models/domain/user/UserAutocompleteParams';

const autocompleteService = {
  loadUsers: (queryParams: UserAutocompleteParams) => callApi({
    endpoint: 'user/members/autocomplete',
    method: 'GET',
    queryParams
  })
};

export { autocompleteService };
