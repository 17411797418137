/* eslint-disable max-len */
import { createRoutine } from 'redux-saga-routines';

const createAdditionalServicesRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`HAVES_DASHBOARD__ADDITIONAL_SERVICES:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const setAdditionalServicesPageRoutine = createAdditionalServicesRoutine('SET_ADDITIONAL_SERVICES_PAGE');
export const saveAdditionalServiceRoutine = createAdditionalServicesRoutine('SAVE_ADDITIONAL_SERVICE');
export const loadAdditionalServicesBuildingTitlesRoutine = createAdditionalServicesRoutine('LOAD_ADDITIONAL_SERVICES_BUILDING_TITLES');
export const loadOneAdditionalServiceRoutine = createAdditionalServicesRoutine('LOAD_ONE_ADDITIONAL_SERVICE');
export const setSelectedAdditionalServiceRoutine = createAdditionalServicesRoutine('SET_SELECTED_ADDITIONAL_SERVICE');
export const loadAdditionalServicesListRoutine = createAdditionalServicesRoutine('LOAD_ADDITIONAL_SERVICES_LIST');
