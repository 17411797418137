import React from 'react';
import SearchSelector, { ISearchSelectorProps } from '@screens/Landing/components/search/SearchSelector';

export type ISearchSelectorItemProps = ISearchSelectorProps;

const SearchSelectorItem: React.FC<ISearchSelectorItemProps> = props => {
  const { index, trigger } = props;

  return (
    <SearchSelector
      {...props}
      mobileFixed
      trigger={(
        <div onClick={() => props.handleClick(index)}>
          {trigger}
        </div>
      )}
    />
  );
};

export default SearchSelectorItem;
