import React from 'react';
import classNames from 'classnames';
import TypographyTag from '@components/NewDesign/Typography/TypographyTag';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

export interface IGeneralTypographyBlockProps {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  marginTop?: boolean;
  marginBottom?: boolean;
  center?: boolean;
  disableWordBreak?: boolean;
  disableTranslation?: boolean;
  className?: string;
}

const GeneralTypographyBlock: React.FC<IGeneralTypographyBlockProps> = (
  {
    children, marginTop,
    marginBottom, center, disableWordBreak, disableTranslation,
    className, tag
  }
) => {
  const { t } = useTranslation();

  const checkShouldTranslate = (text): boolean => {
    const trimmedText = text?.trim();
    return !(!trimmedText || !trimmedText.length || trimmedText === '|'
      || trimmedText === '/' || trimmedText[0] === '$');
  };

  const generateContent = () => {
    if (disableTranslation) {
      return children;
    }
    if (typeof children === 'string' && checkShouldTranslate(children)) {
      return t(children);
    }
    if (Array.isArray(children)) {
      return React.Children.map(
        children,
        child => (typeof child === 'string' && checkShouldTranslate(child) ? t(child) : child)
      );
    }
    return children;
  };

  return (
    <TypographyTag
      typographyTag={tag}
      className={classNames(
        styles.container,
        marginTop && styles.marginTop,
        marginBottom && styles.marginBottom,
        center && styles.center,
        !disableWordBreak && styles.word_break,
        className
      )}
    >
      {generateContent()}
    </TypographyTag>
  );
};

export default GeneralTypographyBlock;
