import React from 'react';
import styles from './styles.module.scss';
import StaticPageWrapper from '@components/NewDesign/StaticPageWrapper';
import HowItWorksContentBlock from '@screens/static/HowItWorks/components/HowItWorksContentBlock';
import NeedImage from '@images/how_it_works/Need.png';
import TextLink from '@components/NewDesign/TextLink';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import GoToButton from '@components/NewDesign/Button/GoToButton';

export interface IHowItWorksNeedContentProps {
}

const HowItWorksNeedContent: React.FC<IHowItWorksNeedContentProps> = () => (
  <StaticPageWrapper
    title="So You're Looking for some Space"
    disableHeaderMaxWidth
    moreMaxWidth
  >
    <div className={styles.container}>
      <div>
        <img src={NeedImage} alt="So You're Looking for some Space" className={styles.image} />
      </div>
      <div>
        <HowItWorksContentBlock title="Searching">
          You can browse and search for available spaces (and any additional services)
          by using our search bar
          {' '}
          <TextLink to={ENDPOINTS.BROWSE}>here</TextLink>
          .
          We’ve added filters to make it easier for you to find the perfect spaces.
        </HowItWorksContentBlock>
        <HowItWorksContentBlock title="Booking Spaces">
          Once you’ve found the right space(s) and are ready to book them,
          simply continue with the checkout process. As a courtesy to other members,
          we limit the amount of time during checkout to 15 minutes, so that the
          spaces are not “locked” to any given transaction indefinitely. You will receive
          an email booking confirmation immediately after completing the booking.
        </HowItWorksContentBlock>
      </div>
    </div>
    <div className={styles.container}>
      <div>
        <HowItWorksContentBlock title="Billing Details">
          Billing occurs on the first of every month (prepaid for that month)
          and continues on a month-to-month basis until you terminate your
          space hosting agreement. If your booking starts or ends after the
          1st of the month, your fee for that month will be pro-rated accordingly.
        </HowItWorksContentBlock>
        <HowItWorksContentBlock title="Adjusting Your Space Needs">
          You can increase or decrease your space needs at any time,
          as necessary, and you can cancel at any time, free-of-charge.
          If you have prepaid for a month’s worth of space hosting,
          you will have until the end of that month to use the space.
          If you do not use the space the entire month, you will not
          receive any refunds of the prepaid hosting fees.
          <br />
          <br />
          You can read more details in our
          {' '}
          <TextLink to={ENDPOINTS.TERMS_AND_CONDITIONS}>
            Terms and Conditions.
          </TextLink>
        </HowItWorksContentBlock>
      </div>
      <div>
        <HowItWorksContentBlock title="Host Follow-Up">
          Your space Host will message you, via our built-in message center
          (in your user dashboard area) within 24 hours of booking,
          to let you know that your space(s) is/are ready, and to share
          with you any necessary information unique to your space(s) and
          how to drop off your goods (for warehouse spaces).
        </HowItWorksContentBlock>
        <HowItWorksContentBlock title="Dropping Off Your Goods" subtitle="Warehouse Spaces">
          If you booked warehouse spaces, you will be able to schedule
          the drop-off of your goods at the host warehouse location
          (in your user dashboard area). The space host will need to
          approve/confirm your selected drop-off date and time, and then
          you are good to go. Once you drop off your goods, the space
          host will assign them to specific warehouse spaces and will keep
          your goods in storage for you. Depending on your host, you may
          have the option to schedule various pickups of your goods,
          deliveries, and other additional services.
        </HowItWorksContentBlock>
      </div>
    </div>
    <GoToButton to={ENDPOINTS.BROWSE} className={styles.goToButton}>
      Browse Spaces
    </GoToButton>
  </StaticPageWrapper>
);

export default HowItWorksNeedContent;
