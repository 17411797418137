import React from 'react';
import classNames from 'classnames';
import { Dropdown } from 'semantic-ui-react';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import styles from './styles.module.scss';

const PageSizeDropdownWrapper: React.FC<DropdownProps> = props => (
  <Dropdown
    {...props}
    className={classNames(styles.container, props.className)}
  >
    {props.children}
  </Dropdown>
);

export default PageSizeDropdownWrapper;
