import React from 'react';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import DividingLine from '@components/NewDesign/DividingLine';
import { IOrderedServiceDto } from '@screens/ServiceOrderCheckout/model/OrderedServiceDto';
import { ServicesTable } from '@screens/HavesDashboard/Orders/components/ServicesTable';
import { IServiceOrderDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderDto';
import styles from './styles.module.scss';

export interface IServicesDetailsProps {
  services: IOrderedServiceDto[];
  order: IServiceOrderDto;
}

const ServicesDetails: React.FC<IServicesDetailsProps> = (
  { services, order }
) => (
  <>
    <DividingLine light marginBottom marginTop />
    <SubHeader1 className={styles.subheader}>Services</SubHeader1>
    <ServicesTable services={services} order={order} />
  </>
);

export default ServicesDetails;
