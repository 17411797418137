import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { IServiceDetailsDto } from '@screens/HavesDashboard/AdditionalServices/model/ServiceDetailsDto';
import {
  extractBuildingTitles, extractLoadAdditionalServicesBuildingTitlesLoading,
  extractLoadOneAdditionalServiceLoading, extractSaveAdditionalServiceLoading,
  extractSelectedAdditionalService
} from '@screens/HavesDashboard/AdditionalServices/reducers';
import {
  loadAdditionalServicesBuildingTitlesRoutine, saveAdditionalServiceRoutine,
  setSelectedAdditionalServiceRoutine
} from '@screens/HavesDashboard/AdditionalServices/routines';
import AdditionalServiceForm from '@screens/HavesDashboard/AdditionalServices/components/AdditionalServiceForm';
import { IBuildingTitleDto } from '@screens/HavesDashboard/AdditionalServices/model/BuildingTitleDto';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import { Modal, ModalContent } from 'semantic-ui-react';
import { IServiceSaveDto } from '@screens/HavesDashboard/AdditionalServices/model/ServiceSaveDto';
import styles from './styles.module.scss';

export interface IAdditionalServiceModalProps extends IState, IActions {
}

interface IState {
  selectedService?: Partial<IServiceDetailsDto>;
  serviceLoading?: boolean;
  buildingTitles?: IBuildingTitleDto[];
  buildingTitlesLoading?: boolean;
  saveServiceLoading?: boolean;
}

interface IActions {
  setSelectedService: IBindingCallback1<Partial<IServiceDetailsDto> | undefined>;
  loadBuildingTitles: IBindingAction;
  saveService: IBindingCallback1<IServiceSaveDto>;
}

const AdditionalServiceModal: React.FC<IAdditionalServiceModalProps> = (
  { selectedService, serviceLoading, setSelectedService,
    loadBuildingTitles, buildingTitles, buildingTitlesLoading,
    saveService, saveServiceLoading }
) => {
  const handleClose = useCallback(() => {
    setSelectedService(undefined);
  }, [setSelectedService]);

  useEffect(() => {
    loadBuildingTitles();
  }, [loadBuildingTitles]);

  return (
    <Modal open={!!selectedService || serviceLoading} closeIcon onClose={handleClose}>
      {!serviceLoading && (
        <ModalHeaderWrapper>
          {selectedService?.id
            ? 'Edit Service'
            : 'Create Service'}
        </ModalHeaderWrapper>
      )}
      <ModalContent>
        <div className={styles.container}>
          <AdditionalServiceForm
            loadingValues={serviceLoading}
            initialValues={selectedService}
            buildingTitles={buildingTitles}
            buildingTitlesLoading={buildingTitlesLoading}
            onSave={saveService}
            saveLoading={saveServiceLoading}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps: (state) => IState = state => ({
  selectedService: extractSelectedAdditionalService(state),
  serviceLoading: extractLoadOneAdditionalServiceLoading(state),
  buildingTitles: extractBuildingTitles(state),
  buildingTitlesLoading: extractLoadAdditionalServicesBuildingTitlesLoading(state),
  saveServiceLoading: extractSaveAdditionalServiceLoading(state)
});

const mapDispatchToProps: IActions = {
  setSelectedService: setSelectedAdditionalServiceRoutine.fulfill,
  loadBuildingTitles: loadAdditionalServicesBuildingTitlesRoutine,
  saveService: saveAdditionalServiceRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalServiceModal);
