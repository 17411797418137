import React from 'react';
import { connect } from 'react-redux';
import SpacesSection from '@screens/NeedsDashboard/Dashboard/components/SpacesSection';
import TransactionsSection from '@screens/NeedsDashboard/Dashboard/components/TransactionsSection';
import MessagesSection from '@components/MessagesSection';
import {
  fetchMyBuildingsRoutine,
  fetchMyTransactionsRoutine,
  toggleFavoriteBuildingRoutine,
  fetchDashboardMessagesRoutine,
  sendMessageFromDashboardRoutine,
  setWritingMessageFromDashboardRoutine,
  readMessageFromDashboardRoutine,
  setMessageFullFromDashboardRoutine
} from '@screens/NeedsDashboard/Dashboard/routines';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { IMessageDto } from '@models/domain/message/MessageDto';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import {
  extractFetchMyBuildingsLoading,
  extractFetchMyTransactionsLoading,
  extractMoreBuildings,
  extractMoreTransactions,
  extractMyBuildings,
  extractMyTransactions,
  extractMyMessages,
  extractFetchDashboardMessagesLoading,
  extractMoreMessages,
  extractWriting,
  extractSendMessageFromDashboardLoading,
  extractFullMessage
} from '@screens/NeedsDashboard/Dashboard/reducers';
import { ITransaction } from '@models/domain/Transaction';
import { IResultBuildingItem } from '@screens/BrowseSpaces/model/ResultItem';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import TableColumnTitle from '@components/NewDesign/Typography/FormAndTable/TableColumnTitle';
import styles from './styles.module.scss';

export interface IDashboardProps {
  buildings: IResultBuildingItem[];
  fetchBuildings: IBindingAction;
  buildingsLoading: boolean;
  toggleBuildingLike: IBindingCallback1<string>;
  transactions: ITransaction[];
  fetchTransactions: IBindingAction;
  transactionsLoading: boolean;
  moreTransactions: boolean;
  moreBuildings: boolean;

  messages: IMessageDto[];
  fetchMyMessages: IBindingAction;
  messagesLoading: boolean;
  moreMessages: boolean;
  writing: boolean;
  sendMessageLoading: boolean;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  setWriting: IBindingCallback1<boolean>;
  readMessage: IBindingCallback1<string>;
  messageFull?: IMessageDto;
  setMessageFull: IBindingCallback1<IMessageDto | undefined>;
}

const Dashboard: React.FC<IDashboardProps> = (
  {
    fetchBuildings, fetchTransactions, buildings, transactions, buildingsLoading,
    transactionsLoading, toggleBuildingLike, moreBuildings, moreTransactions, messages,
    fetchMyMessages, messagesLoading, moreMessages, writing, sendMessageLoading,
    sendMessage, setWriting, readMessage, messageFull, setMessageFull
  }
) => (
  <DashboardPageWrapper title="Dashboard">
    <DashboardSectionWrapper title="Recent Messages">
      <MessagesSection
        items={messages}
        fetchItems={fetchMyMessages}
        itemsLoading={messagesLoading}
        haveMore={moreMessages}
        writing={writing}
        sendMessageLoading={sendMessageLoading}
        sendMessage={sendMessage}
        setWriting={setWriting}
        readMessage={readMessage}
        messageFull={messageFull}
        setMessageFull={setMessageFull}
        messageCenterLink={NEEDS_DASHBOARD_ENDPOINTS.MESSAGES}
      />
    </DashboardSectionWrapper>
    <DashboardSectionWrapper title="My Shared Spaces">
      <SpacesSection
        items={buildings}
        itemsLoading={buildingsLoading}
        fetchItems={fetchBuildings}
        toggleLike={toggleBuildingLike}
        haveMore={moreBuildings}
      />
    </DashboardSectionWrapper>
    <DashboardSectionWrapper
      title="Transactions"
      rightElement={(
        <TableColumnTitle className={styles.transactions_subtitle}>
          SHOWING THE LAST 5 TRANSACTIONS
        </TableColumnTitle>
      )}
    >
      <TransactionsSection
        items={transactions}
        itemsLoading={transactionsLoading}
        fetchItems={fetchTransactions}
        haveMore={moreTransactions}
        placeholderClassName={styles.transactionSection}
      />
    </DashboardSectionWrapper>
  </DashboardPageWrapper>
);

const mapStateToProps = state => ({
  buildings: extractMyBuildings(state),
  transactions: extractMyTransactions(state),
  buildingsLoading: extractFetchMyBuildingsLoading(state),
  transactionsLoading: extractFetchMyTransactionsLoading(state),
  moreTransactions: extractMoreTransactions(state),
  moreBuildings: extractMoreBuildings(state),

  messages: extractMyMessages(state),
  messagesLoading: extractFetchDashboardMessagesLoading(state),
  moreMessages: extractMoreMessages(state),
  writing: extractWriting(state),
  sendMessageLoading: extractSendMessageFromDashboardLoading(state),
  messageFull: extractFullMessage(state)
});

const mapDispatchToProps = {
  fetchBuildings: fetchMyBuildingsRoutine,
  fetchTransactions: fetchMyTransactionsRoutine,
  toggleBuildingLike: toggleFavoriteBuildingRoutine,

  fetchMyMessages: fetchDashboardMessagesRoutine,
  sendMessage: sendMessageFromDashboardRoutine,
  setWriting: setWritingMessageFromDashboardRoutine.fulfill,
  readMessage: readMessageFromDashboardRoutine,
  setMessageFull: setMessageFullFromDashboardRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
