import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SearchBar from '@screens/BrowseSpaces/containers/SearchBar';
import LayoutSelector from '@screens/BrowseSpaces/components/LayoutSelector';
import SearchResults from '@screens/BrowseSpaces/components/SearchResults';
import { PageLayout } from '@screens/BrowseSpaces/model/PageLayout';
import {
  extractCurrentPage,
  extractPageLayout,
  extractPageSize,
  extractQuery,
  extractResults,
  extractSearchLoading,
  extractTotalPages,
  extractTotalResults
} from '@screens/BrowseSpaces/reducers';
import { IResultBuildingItem } from '@screens/BrowseSpaces/model/ResultItem';
import { PageSize } from '@screens/BrowseSpaces/model/PageSize';
import {
  clearFiltersRoutine,
  performSearchRoutine,
  setPageIndexRoutine,
  setPageLayoutRoutine,
  setPageSizeRoutine,
  toggleFavoriteBuildingRoutine,
  updateSearchQueryRoutine
} from '@screens/BrowseSpaces/routines';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { IQueryData } from '@screens/BrowseSpaces/model/QueryData';
import { IPageable } from '@models/domain/PageableReducerState';
import ImageContainer from '@components/ImageContainer';
import headerImage from '@images/BrowseSpacesPage_Header_Image.jpg';
import MetaTags from '@components/MetaTags';
import { STATIC_SITE_LINKS } from '@root/assets/links/static-site-links';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import styles from './styles.module.scss';

export interface IBrowseSpacesPageProps extends IPageable {
  activeLayout: PageLayout;
  results: IResultBuildingItem[];
  searchLoading: boolean;
  currentPage: number;
  searchQuery: IQueryData;

  setLayout: IBindingCallback1<PageLayout>;
  setPageSize: IBindingCallback1<PageSize>;
  setPage: IBindingCallback1<number>;
  performSearch: IBindingAction;
  toggleFavorite: IBindingCallback1<string>;
  clearFilters: IBindingAction;
  updateFilters: IBindingCallback1<IQueryData>;
}

const BrowseSpacesPage: React.FC<IBrowseSpacesPageProps> = (
  {
    results, activeLayout, currentPage, pageSize, searchLoading, totalPages,
    totalResults, searchQuery, setLayout, setPageSize, setPage, performSearch, toggleFavorite,
    clearFilters, updateFilters
  }
) => {
  const [isShowSearchBar, setIsShowSearchBar] = useState(false);

  useEffect(() => {
    performSearch();
  }, [performSearch]);

  const toggleHideSearchBar = () => setIsShowSearchBar(!isShowSearchBar);

  return (
    <div className={`${styles.container} content_wrapper`}>
      <MetaTags title="Browse Spaces" withSuffix />
      <ImageContainer src={headerImage} alt="Header Image" className={styles.header_image} />
      <SearchBar
        hide={isShowSearchBar}
        searchLoading={searchLoading}
        performSearch={performSearch}
        searchQuery={searchQuery}
        clearFilters={clearFilters}
        updateFilters={updateFilters}
      />
      <div className={styles.layout_selector_wrapper}>
        <GoToButton
          external
          to={STATIC_SITE_LINKS.CANT_FIND}
          marginZero
          className={styles.cant_find_button}
        >
          Can&apos;t Find What You Need?
        </GoToButton>
        <LayoutSelector
          isShowSearchBar={isShowSearchBar}
          toggleHideSearchBar={toggleHideSearchBar}
          resultsNumber={totalResults}
          activeLayout={activeLayout}
          pageSize={pageSize}
          setLayout={setLayout}
          setPageSize={setPageSize}
        />
      </div>
      <SearchResults
        results={results}
        layout={activeLayout}
        loading={searchLoading}
        page={currentPage}
        totalPages={totalPages}
        setPage={setPage}
        toggleFavorite={toggleFavorite}
        searchQuery={searchQuery}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  activeLayout: extractPageLayout(state),
  results: extractResults(state),
  searchLoading: extractSearchLoading(state),
  pageSize: extractPageSize(state),
  currentPage: extractCurrentPage(state),
  totalPages: extractTotalPages(state),
  totalResults: extractTotalResults(state),
  searchQuery: extractQuery(state)
});

const mapDispatchToProps = {
  setLayout: setPageLayoutRoutine.fulfill,
  setPageSize: setPageSizeRoutine.fulfill,
  setPage: setPageIndexRoutine.fulfill,
  performSearch: performSearchRoutine,
  toggleFavorite: toggleFavoriteBuildingRoutine,
  clearFilters: clearFiltersRoutine.fulfill,
  updateFilters: updateSearchQueryRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowseSpacesPage);
