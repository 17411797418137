import { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import {
  parseQueryParams,
  QueryParamsState,
  serializeQueryParams,
  stringifyArrayParams,
  UpdateQueryParamsFunction
} from '@helpers/search-params.helper';

export const useQueryParams = (initialState: QueryParamsState = {}): [QueryParamsState, UpdateQueryParamsFunction] => {
  const history = useHistory();
  const location = useLocation();
  const isFirstRenderRef = useRef(true);
  const prevQueryParamsRef = useRef(null);

  const initialQueryParams = parseQueryParams(queryString.parse(location.search));
  const [state, setState] = useState<QueryParamsState>(
    _.isEmpty(initialQueryParams) ? initialState : initialQueryParams
  );

  const updateQueryParams = useCallback((newState: QueryParamsState) => {
    setState(prevState => ({
      ...prevState,
      ...newState
    }));

    const currentParams = queryString.parse(location.search);
    const updatedParams = {
      ...currentParams,
      ...stringifyArrayParams(newState)
    };
    const serializedParams = serializeQueryParams(updatedParams);

    prevQueryParamsRef.current = serializedParams;
    history.push({ search: serializedParams });
  }, [history, location.search]);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const isSameParams = location.search === prevQueryParamsRef.current;

    if (isFirstRenderRef.current && Object.keys(queryParams).length === 0) {
      setState(prevState => ({
        ...prevState,
        ...initialState
      }));

      const serializedParams = serializeQueryParams(stringifyArrayParams(initialState));

      history.replace({ search: serializedParams });
      prevQueryParamsRef.current = serializedParams;
      isFirstRenderRef.current = false;
    } else if (!isSameParams) {
      const parsedParams = parseQueryParams(queryParams);
      setState(prevState => ({
        ...prevState,
        ...parsedParams
      }));
      prevQueryParamsRef.current = location.search;
      if (isFirstRenderRef.current) {
        isFirstRenderRef.current = false;
      }
    }
  }, [initialState, history, location.search]);

  return [state, updateQueryParams];
};
