import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ButtonBack, ButtonNext, CarouselContext, Dot, Slider } from 'pure-react-carousel';
import { Icon } from 'semantic-ui-react';
import _ from 'lodash';
import classNames from 'classnames';

export interface ISimpleCarouselContentProps {
  visibleSlides: number;
  totalSlides: number;
  vertical?: boolean;
}

const SimpleCarouselContent: React.FC<ISimpleCarouselContentProps> = (
  { visibleSlides, totalSlides, vertical, children }
) => {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }

    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  const horizontalCarousel = (
    <div className={styles.carousel}>
      <ButtonBack className={styles.horizontal_button_back}>
        <Icon
          name="angle left"
          size="huge"
          className={classNames(
            styles.angle_icon,
            currentSlide === 0 ? styles.grey : styles.brown
          )}
        />
      </ButtonBack>
      <Slider className={styles.slider}>
        {children}
      </Slider>
      <ButtonNext className={styles.horizontal_button_next}>
        <Icon
          name="angle right"
          size="huge"
          className={classNames(
            styles.angle_icon,
            currentSlide === totalSlides - visibleSlides ? styles.grey : styles.brown
          )}
        />
      </ButtonNext>
    </div>
  );

  const verticalCarousel = (
    <div className={styles.vertical_carousel}>
      <ButtonBack className={styles.vertical_button_back}>
        <Icon
          name="angle up"
          size="huge"
          className={classNames(
            styles.angle_icon,
            currentSlide === 0 ? styles.grey : styles.brown
          )}
        />
      </ButtonBack>
      <Slider className={styles.slider}>
        {children}
      </Slider>
      <ButtonNext className={styles.vertical_button_next}>
        <Icon
          name="angle down"
          size="huge"
          className={classNames(
            styles.angle_icon,
            currentSlide === totalSlides - visibleSlides ? styles.grey : styles.brown
          )}
        />
      </ButtonNext>
    </div>
  );

  return (
    <>
      {vertical ? verticalCarousel : horizontalCarousel}
      <div className={styles.dot_array}>
        {_.times(totalSlides - visibleSlides + 1).map(index => (
          <Dot
            key={index}
            className={`${styles.dot} ${index === currentSlide ? styles.active_dot : ''}`}
            slide={index}
            disabled={false}
          />
        ))}
      </div>
    </>
  );
};

export default SimpleCarouselContent;
