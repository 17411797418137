import React from 'react';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import { Role } from '@screens/Authorization/models/Roles';
import { IHowItWorksLocationState } from '@screens/static/HowItWorks/containers/HowItWorksPage';

const OfferingSpace: React.FC = () => (
  <>
    <ol>
      <li>
        <BodyText2>
          You want to make money from your empty warehouse or office spaces.
        </BodyText2>
      </li>
      <li>
        <BodyText2>
          Once you are ready, create a Shared Spaces account, and select “Have” as your role.
        </BodyText2>
      </li>
      <li>
        <BodyText2>
          Create buildings, and list spaces within those buildings. Add good pictures, set
          your hosting rates and availability, and publish your listing.
        </BodyText2>
      </li>
      <li>
        <BodyText2>
          Once someone finds your space and it meets their needs, they will book it, and
          you will start making money on your empty space!
        </BodyText2>
      </li>
      <li>
        <BodyText2>
          Use our awesome set of tools to manage your shared spaces and to coordinate
          drop-offs and pick-ups for your warehouse spaces.
        </BodyText2>
      </li>
    </ol>
    <GoToButton
      to={{
        pathname: ENDPOINTS.HOW_IT_WORKS,
        state: { role: Role.HAVE } as IHowItWorksLocationState
      }}
    >
      Find out More about Hosting Spaces
    </GoToButton>
  </>
);

export default OfferingSpace;
