import React from 'react';
import { Switch } from 'react-router-dom';
import Header from '@containers/Header';
import { Helmet } from 'react-helmet';
import LandingPage from '@screens/Landing/containers/LandingPage';
import {
  userEmailActivatedRedir,
  userIsAuthenticatedRedir,
  userSelectedRole
} from '@helpers/authRules.helper';
import Footer from '@containers/Footer';
import NotFound from '@components/404NotFound';
import OAuth2Handler from '@containers/OAuth2Handler';
import EmailVerificationHandler from '@containers/EmailVerificationHandler';
import HowItWorksPage from '@screens/static/HowItWorks/containers/HowItWorksPage';
import OurStoryPage from '@screens/static/OurStory/containers/OurStoryPage';
import BrowseSpacesPage from '@screens/BrowseSpaces/containers/BrowseSpacesPage';
import BuildingDetailsPage from '@screens/BuildingDetails/containers/BuildingDetailsPage';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import AppRoute from '@containers/AppRoute';
import AuthorizationRouter from '@screens/Authorization/containers/AuthorizationRouter';
import DashboardRouting from '@containers/DashboardRouting';
import BookingDetails from '@screens/BookingCheckout/containers/BookingRootPage';
import ServiceOrderDetails from '@screens/ServiceOrderCheckout/containers/ServiceOrderRootPage';
import PartnerWithUsPage from '@screens/static/PartnerWithUs/containers/PartnerWithUsPage';
import JoinOurTeamPage from '@screens/static/JoinOurTeam/containers/JoinOurTeamPage';
import GetInTouchPage from '@screens/static/GetInTouch/containers/GetInTouchPage';
import PartnerPage from '@screens/static/Partner/containers/PartnerPage';
import ExpertPage from '@screens/static/Expert/containers/ExpertPage';
import ErrorModal from '@screens/ErrorModal/containers/ErrorModalPage';
import TermsAndConditionsDashboard from '@screens/TermsAndConditions/Root/containers/TermsAndConditionsRouting';
import BookingReceiptPage from '@screens/ReceiptPage/containers/BookingReceiptPage';
import ServiceOrderReceiptPage from '@screens/ReceiptPage/containers/ServiceOrderReceiptPage';
import styles from './styles.module.scss';
import { MAIN_DESCRIPTION } from '@screens/Landing/components/WhySharedSpacesBlock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRoutingProps {
}

const userValidRedir = component => userIsAuthenticatedRedir(userEmailActivatedRedir(userSelectedRole(component)));

const UserDashboard = userValidRedir(DashboardRouting);
const BookingCheckoutPage = userValidRedir(BookingDetails);
const ServiceOrderCheckoutPage = userValidRedir(ServiceOrderDetails);
const {
  LOGIN, REGISTER, RESET, ROOT, BROWSE, HOW_IT_WORKS, OAUTH_REDIRECT, OUR_STORY,
  USER, VERIFY, BOOKING, DETAILS, PARTNER_WITH_US, JOIN_OUR_TEAM,
  CONTACT_US, PARTNER_PAGE, EXPERT_PAGE, TERMS_AND_CONDITIONS, BOOKING_RECEIPT,
  SERVICE_ORDER_RECEIPT, SERVICE_ORDER_CHECKOUT
} = ENDPOINTS;
const blackListPaths = [LOGIN, REGISTER, RESET];

const Routing: React.FunctionComponent<IRoutingProps> = () => (
  <div className={styles.fill}>
    <Helmet defaultTitle="Shared Spaces">
      <meta
        name="description"
        content={MAIN_DESCRIPTION}
      />
    </Helmet>
    <Header blackListPaths={blackListPaths} />
    <div className={styles.content}>
      <Switch>
        <AppRoute exact path={ROOT} component={LandingPage} lightBlue />
        <AppRoute exact path={[LOGIN, REGISTER, RESET]} component={AuthorizationRouter} />
        <AppRoute path={USER} component={UserDashboard} lightBlue />
        <AppRoute exact path={OAUTH_REDIRECT} component={OAuth2Handler} />
        <AppRoute exact path={VERIFY} component={EmailVerificationHandler} />
        <AppRoute exact path={HOW_IT_WORKS} component={HowItWorksPage} />
        <AppRoute exact path={OUR_STORY} component={OurStoryPage} />
        <AppRoute exact path={PARTNER_WITH_US} component={PartnerWithUsPage} />
        <AppRoute exact path={JOIN_OUR_TEAM} component={JoinOurTeamPage} />
        <AppRoute exact path={PARTNER_PAGE} component={PartnerPage} />
        <AppRoute exact path={EXPERT_PAGE} component={ExpertPage} />
        <AppRoute exact path={CONTACT_US} component={GetInTouchPage} />
        <AppRoute exact path={BROWSE} component={BrowseSpacesPage} />
        <AppRoute path={TERMS_AND_CONDITIONS} component={TermsAndConditionsDashboard} />
        <AppRoute exact path={DETAILS(':id')} component={BuildingDetailsPage} />
        <AppRoute exact path={BOOKING(':id')} component={BookingCheckoutPage} />
        <AppRoute exact path={SERVICE_ORDER_CHECKOUT(':id')} component={ServiceOrderCheckoutPage} />
        <AppRoute exact path={BOOKING_RECEIPT(':id')} component={BookingReceiptPage} />
        <AppRoute exact path={SERVICE_ORDER_RECEIPT(':id')} component={ServiceOrderReceiptPage} />
        <AppRoute component={NotFound} lightBlue />
      </Switch>
    </div>
    <Footer blackListPaths={blackListPaths} />
    <ErrorModal />
  </div>
);

export default Routing;
