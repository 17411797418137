import React from 'react';
import { lowerCase, startCase } from 'lodash';
import BodyText3 from '@components/NewDesign/Typography/BodyText/BodyText3';
import TableRegularContent from '@components/NewDesign/Typography/FormAndTable/TableRegularContent';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';
import { toPrice } from '@helpers/price.helper';
import styles from './styles.module.scss';

export interface IAdditionalServicesInfoProps {
  loading: boolean;
  services: IAdditionalServiceWithAvailability[];
}

const AdditionalServicesInfo: React.FC<IAdditionalServicesInfoProps> = (
  {
    services, loading
  }
) => (
  <>
    {loading || (
    <div className={styles.container}>
      <BodyText3 className={styles.header}>Additional Services:</BodyText3>
      <div className={styles.services}>
        {services?.map(service => (
          <div key={service.id} className={styles.service}>
            <TableRegularContent className={styles.service_alias}>
              {service.alias}
            </TableRegularContent>
            <TableRegularContent className={styles.service_type}>
              &nbsp;|
              {' '}
              {startCase(lowerCase(service.type))}
              &nbsp;|
              {' '}
            </TableRegularContent>
            <TableRegularContent className={styles.service_category}>
              {startCase(lowerCase(service.category))}
              &nbsp;|
              {' '}
            </TableRegularContent>
            <TableRegularContent className={styles.service_price}>
              {toPrice({ amount: service.price.amount, currency: service.price.currency })}
              /
              {lowerCase(service.billingUnit)}
            </TableRegularContent>
          </div>
        ))}
      </div>
    </div>
    )}
  </>
);

export default AdditionalServicesInfo;

