import { all, call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  createAdditionalServicesOrderRoutine,
  loadAdditionalServicesWithAvailabilityRoutine,
  loadServiceOrderDetailsRoutine,
  toggleFavoriteRoutine
} from '@screens/ServiceOrderCheckout/routines';
import { IServiceOrderCheckoutData } from '@screens/ServiceOrderCheckout/model/ServiceOrderCheckout';
import orderDetailsService from '@screens/ServiceOrderCheckout/services/order.details.service';
import { tryToggleFavoriteBuilding } from '@sagas/favorite.building.saga';
import {
  tryCreateAdditionalServicesOrder,
  tryLoadAdditionalServicesWithAvailability
} from '@screens/BookingCheckout/containers/BookingRootPage/sagas';

function* tryLoadServiceOrderDetails({ payload }: PayloadAction<string>) {
  try {
    const response: IServiceOrderCheckoutData = yield call(orderDetailsService.fetchServiceOrderDetails, payload);
    yield put(loadServiceOrderDetailsRoutine.success(response));
  } catch (e) {
    toastr.error('Unable to load service order information', e?.message);
    yield put(loadServiceOrderDetailsRoutine.failure(e?.message));
  }
}

export default function* serviceOrderRootPageSagas() {
  yield all([
    yield takeEvery(loadServiceOrderDetailsRoutine.TRIGGER, tryLoadServiceOrderDetails),
    yield takeEvery(toggleFavoriteRoutine.TRIGGER, tryToggleFavoriteBuilding(toggleFavoriteRoutine)),
    yield takeEvery(
      loadAdditionalServicesWithAvailabilityRoutine.TRIGGER,
      tryLoadAdditionalServicesWithAvailability(loadAdditionalServicesWithAvailabilityRoutine)
    ),
    yield takeEvery(
      createAdditionalServicesOrderRoutine.TRIGGER,
      tryCreateAdditionalServicesOrder(createAdditionalServicesOrderRoutine)
    )
  ]);
}
