import React from 'react';
import Header2 from '@components/NewDesign/Typography/Headers/Header2';
import ScrollToTopOnMount from '@components/ScrollToTop';
import styles from './styles.module.scss';
import MetaTags from '@components/MetaTags';

export interface IDashboardPageWrapperProps {
  title?: string;
  rightElement?: JSX.Element | undefined;
}

const DashboardPageWrapper: React.FC<IDashboardPageWrapperProps> = (
  { children, title, rightElement }
) => (
  <div className={styles.container}>
    {title && (
      <MetaTags title={title} withSuffix />
    )}
    <ScrollToTopOnMount />
    { title && (
      <div className={styles.header_section}>
        <Header2>{title}</Header2>
        <div>
          { rightElement ?? '' }
        </div>
      </div>
    ) }
    <div className={styles.content}>
      {children}
    </div>
  </div>
);

export default DashboardPageWrapper;
