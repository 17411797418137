import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { IPageable } from '@models/domain/PageableReducerState';
import { IBindingCallback1 } from '@models/Callbacks';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import ResultsWithPagination from '@components/ResultsWithPagination';
import NewTable, { INewTableProps } from '@components/NewDesign/Table/NewTable';
import NoDataContainer from '@components/NoDataContainer';
import { IPayoutDto } from '@screens/AdminDashboard/Payouts/models/PayoutDto';
import { loadPayoutsHistoryRoutine, setCurrentPageRoutine } from '@screens/AdminDashboard/Payouts/routines';
import {
  extractCurrentPage,
  extractLoadPayoutsHistoryLoading,
  extractPageSize,
  extractPayoutsHistory,
  extractTotalPages
} from '@screens/AdminDashboard/Payouts/reducers';

export interface IPayoutsTableProps extends IActions, IState {
  tableProps: (payouts: IPayoutDto[]) => INewTableProps;
}

interface IActions {
  setPage: IBindingCallback1<number>;
  loadPayouts: IBindingCallback1<IPageRequest>;
}

interface IState extends IPageable {
  payouts: IPayoutDto[];
  payoutsLoading: boolean;
}

const PayoutsTable: React.FC<IPayoutsTableProps> = (
  {
    setPage, loadPayouts, payoutsLoading, page, totalPages,
    pageSize, tableProps, payouts
  }
) => {
  useEffect(() => {
    loadPayouts({ page, size: pageSize });
  }, [loadPayouts, page, pageSize]);

  return (
    <>
      {payoutsLoading && <Loader active inline="centered" />}
      {!payoutsLoading && (
        <ResultsWithPagination loading={payoutsLoading} setPage={setPage} totalPages={totalPages} page={page}>
          <NewTable {...tableProps(payouts)} />
        </ResultsWithPagination>
      )}
      {!payoutsLoading && { ...tableProps(payouts) }.rows.length === 0
        ? <NoDataContainer message="There are no payouts yet" />
        : ''}
    </>
  );
};

const mapStateToProps: (state) => IState = state => ({
  totalPages: extractTotalPages(state),
  page: extractCurrentPage(state),
  pageSize: extractPageSize(state),
  payouts: extractPayoutsHistory(state),
  payoutsLoading: extractLoadPayoutsHistoryLoading(state)
});

const mapDispatchToProps: IActions = {
  loadPayouts: loadPayoutsHistoryRoutine,
  setPage: setCurrentPageRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutsTable);
