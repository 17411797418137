import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import { logOutRoutine } from '@screens/Authorization/routines';
import { IBindingAction } from '@models/Callbacks';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import { extractCurrentUser } from '@screens/Authorization/reducers';
import { ICurrentUser } from '@screens/Authorization/models/CurrentUser';
import { useTranslation } from '@root/hooks/use-translation';
import { NavLinkWithTranslate } from '@components/WithTranslate/NavLinkWithTranslate';
import styles from './styles.module.scss';

interface IState {
  currentUser: ICurrentUser;
}

interface IActions {
  logOut: IBindingAction;
}

export interface ISideMenuProps {
  tabs: ITabProps[];
  className?: string;
  linkClassName?: string;
  activeLinkClassName?: string;
  personalized?: boolean;
  onItemClick?: IBindingAction;
}

export interface ITabProps {
  title: string;
  path: string;
  header?: boolean;
  excludeExact?: boolean;
}

const SideMenu: React.FC<IActions & IState & ISideMenuProps> = (
  {
    tabs, className, linkClassName,
    activeLinkClassName, personalized, logOut, currentUser, onItemClick
  }
) => {
  const history = useHistory();
  const { t } = useTranslation();

  const withHistory = useCallback((path: string) => {
    const isOldLocation = history.location.pathname === path;
    return isOldLocation ? undefined : () => {
      if (typeof onItemClick === 'function') {
        onItemClick();
      }
      history.push(path);
    };
  }, [history, onItemClick]);

  const goTo = useCallback(path => withHistory(path), [withHistory]);

  return (
    <div className={`${styles.menu} ${className || ''}`}>
      {personalized && (
        <Caption1 className={styles.hello} disableTranslation>
          {t('Hi there')}
          {currentUser?.firstName && `, ${currentUser.firstName}`}
          !
        </Caption1>
      )}
      <div>
        {tabs.map(tab => (
          <NavLinkWithTranslate
            exact={!tab.excludeExact}
            className={classNames(styles.item, linkClassName)}
            activeClassName={classNames(styles.activeItem, activeLinkClassName)}
            key={tab.title}
            to={tab.path}
            onClick={goTo(tab.path)}
          >
            {tab.title}
          </NavLinkWithTranslate>
        ))}
      </div>
      {personalized && (
        <SmallLinkButton to="" className={styles.signOut} onClick={logOut}>
          Sign out
        </SmallLinkButton>
      )}
    </div>
  );
};

const mapStateToProps = (state): IState => ({
  currentUser: extractCurrentUser(state)
});

const mapDispatchToProps: IActions = {
  logOut: logOutRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
