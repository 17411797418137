const ERROR_OCCURED_PELASE_TRY_REFRESH_PAGE = 'Uh-oh! An error has occurred. '
  + 'Please try refreshing the page in a few minutes';
const PLEASE_CHECK_YOUR_EMAIL = 'Please check your email inbox';
const YOUR_REQUEST_SENT = 'Your request has been sent. Our team will contact you shortly!';

export {
  ERROR_OCCURED_PELASE_TRY_REFRESH_PAGE,
  PLEASE_CHECK_YOUR_EMAIL,
  YOUR_REQUEST_SENT
};
