import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import { callApi } from '@helpers/api.helper';
import {
  ICompleteOrderedServiceRequestPayload
} from '@screens/HavesDashboard/Orders/model/CompleteOrderedServiceRequestPayload';

const ordersService = {
  fetchActiveOrders: (page: IPageRequest) => callApi({
    method: 'GET',
    endpoint: 'service_orders/active',
    queryParams: {
      page: page.page - 1,
      size: page.size
    }
  }),
  completeOrderedService: ({ orderId, serviceId }: ICompleteOrderedServiceRequestPayload) => callApi({
    method: 'PATCH',
    endpoint: `service_orders/${orderId}/complete_service/${serviceId}`
  }),
  completeOrder: (orderId: number) => callApi({
    method: 'PATCH',
    endpoint: `service_orders/${orderId}/complete`
  })
};

export default ordersService;
