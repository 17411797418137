import React, { useState } from 'react';
import { isNil } from 'lodash';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import { IDateSelectorProps } from '@screens/Landing/components/search/selectors/DateSelector';
import SearchSelectorItem from '@screens/Landing/components/search/SearchSelectorItem';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import { DATE_FORMAT } from '@helpers/date.helper';
import DateSelectorWrapper from '@components/NewDesign/DateSelectorWrapper';
import styles from './styles.module.scss';

export type ISingleDatePickerProps = IDateSelectorProps

const SingleDatePicker: React.FC<ISingleDatePickerProps> = ({ value, onChange, ...props }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleSelectorClick = index => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const textFromDate = () => {
    if (isNil(value?.startingDate)) {
      return 'Pick a date';
    }
    return moment(value?.startingDate).format(DATE_FORMAT);
  };

  return (
    <SearchSelectorItem
      index={0}
      activeIndex={activeIndex}
      handleClick={handleSelectorClick}
      trigger={(
        <div
          className={classNames(styles.label_container, activeIndex === 0 && styles.active)}
        >
          <Caption2 className={styles.date_text}>
            {textFromDate()}
            <Icon name="dropdown" className={styles.icon} />
          </Caption2>
        </div>
      )}
      content={(
        <DateSelectorWrapper
          {...props}
          numberOfMonths={1}
          singleDate
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export default SingleDatePicker;
