import { callApi } from '@helpers/api.helper';
import { IEditOrderNotes } from '@screens/ServiceOrderCheckout/containers/ServiceOrderCheckoutPage/ServiceOrderDetailsCheckoutStep';

const orderDetailsService = {
  fetchServiceOrderDetails: (id: string) => callApi({
    endpoint: `service_orders/${id}`,
    method: 'GET'
  }),
  editNotes: ({ orderId, note }: IEditOrderNotes) => callApi({
    endpoint: `service_orders/notes/${orderId}`,
    method: 'PUT',
    requestData: {
      note
    }
  })
};

export default orderDetailsService;
