import React, { useState } from 'react';
import { Accordion, Card, Icon } from 'semantic-ui-react';
import { lowerCase, startCase } from 'lodash';
import { ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { IScheduleSpaceNoteDto } from '@screens/NeedsDashboard/Bookings/model/ScheduleSpaceNoteDto';
import { SpaceType, spaceTypeKey } from '@models/domain/space/SpaceTypes';
import { ITemplate2Amount } from '@screens/NeedsDashboard/Bookings/model/BookingDetailsDto';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import DividingLine from '@components/NewDesign/DividingLine';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';
import Caption3 from '@components/NewDesign/Typography/Caption/Caption3';
import styles from './styles.module.scss';

export interface ISpacesDetailsProps {
  templatesToAmounts: ITemplate2Amount[];
  availableSpaces: ISpaceWithAvailability[];
  filledSpaces: IScheduleSpaceNoteDto[];
}

const SpacesDetails: React.FC<ISpacesDetailsProps> = (
  { templatesToAmounts, availableSpaces, filledSpaces }
) => {
  const [activeIndex, setActiveIndex] = useState<string>('');

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? '' : index;
    setActiveIndex(newIndex);
  };

  const getTemplateSpacesCountById = id => {
    const template = templatesToAmounts.find(t => t.spaceTemplate.id === id);
    return template ? template.spaces.length : 0;
  };

  return (
    <>
      <DividingLine light marginBottom marginTop />
      <SubHeader1 className={styles.subheader}>Spaces</SubHeader1>
      <Card.Group>
        {availableSpaces.map(s => (
          <Card key={s.id}>
            <Card.Content>
              <Card.Header>
                <SubHeader3 className={styles.alias}>{s.alias}</SubHeader3>
              </Card.Header>
              <Card.Meta>
                <BodyText2>
                  {startCase(lowerCase(SpaceType[s.spaceType]))}
                </BodyText2>
              </Card.Meta>
              {s.spaceType === spaceTypeKey(SpaceType.WAREHOUSE) && (
                <Card.Description className={styles.description}>
                  <LabelGroupWrapper
                    className={styles.spaces_free}
                    title="Free"
                    value={`${s.amountAvailable}/${getTemplateSpacesCountById(s.id)}`}
                  />
                  <Accordion>
                    <Accordion.Title
                      active={activeIndex === s.id}
                      index={s.id}
                      onClick={handleClick}
                      className={styles.text_pair}
                    >
                      <TableImportantContent>Show filled</TableImportantContent>
                      <Icon name="dropdown" />
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === s.id}>
                      {filledSpaces.length
                        ? filledSpaces
                          .filter(filledSpace => filledSpace.spaceTemplateId === s.id)
                          .map(filledSpace => (
                            <React.Fragment key={filledSpace.id}>
                              <Caption3>
                                {filledSpace.index}
                                {' - '}
                                {filledSpace.note}
                              </Caption3>
                            </React.Fragment>
                          ))
                        : (<Caption2>There are no filled spaces</Caption2>)}
                    </Accordion.Content>
                  </Accordion>
                </Card.Description>
              )}
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </>
  );
};

export default SpacesDetails;
