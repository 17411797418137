import { combineReducers } from 'redux';
import { reducerCreator } from '@helpers/reducer.helper';
import { buildingDetailsReducer } from '@screens/NeedsDashboard/BuildingDetails/containers/BuildingDetailsPage/reducer';
/* PlopJS import placeholder. Do not remove */
import {
  fetchBuildingDetailsRoutine,
  fetchSpaceAvailabilityRoutine,
  requestBookingRoutine,
  toggleFavoriteBuildingRoutine,
  setWritingMessageFromSpacesToOwnerRoutine,
  sendMessageFromSpacesToOwnerRoutine,
  setBookingsTablePageRoutine,
  fetchBuildingBookingsForNeedRoutine,
  fetchAdditionalServicesRoutine, orderAdditionalServicesRoutine
} from '@screens/NeedsDashboard/BuildingDetails/routines';
import { RootState } from '@root/store';
import {
  spacesAndServicesSectionReducer
} from '@screens/NeedsDashboard/BuildingDetails/containers/SpacesAndServicesSection/reducer';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  setWritingMessageFromSpacesToOwnerRequest: reducerCreator([setWritingMessageFromSpacesToOwnerRoutine.TRIGGER]),
  sendMessageFromSpacesToOwnerRequest: reducerCreator([sendMessageFromSpacesToOwnerRoutine.TRIGGER]),
  toggleFavoriteBuildingRequest: reducerCreator([toggleFavoriteBuildingRoutine.TRIGGER]),
  requestBookingRequest: reducerCreator([requestBookingRoutine.TRIGGER]),
  fetchSpaceAvailabilityRequest: reducerCreator([fetchSpaceAvailabilityRoutine.TRIGGER]),
  fetchBuildingDetailsRequest: reducerCreator([fetchBuildingDetailsRoutine.TRIGGER]),
  setBookingsTablePageRequest: reducerCreator([setBookingsTablePageRoutine.TRIGGER]),
  fetchBuildingBookingsForNeedRequest: reducerCreator([fetchBuildingBookingsForNeedRoutine.TRIGGER]),
  fetchAdditionalServicesRequest: reducerCreator([fetchAdditionalServicesRoutine.TRIGGER]),
  orderAdditionalServicesRequest: reducerCreator([orderAdditionalServicesRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  data: buildingDetailsReducer,
  spacesAndServices: spacesAndServicesSectionReducer
});

const reqs = (state: RootState) => state.needsDashboard.buildingDetails.requests;
const data = (state: RootState) => state.needsDashboard.buildingDetails.data;
const spacesAndServices = (state: RootState) => state.needsDashboard.buildingDetails.spacesAndServices;

export const extractOwner = state => data(state).owner;
export const extractBuildingDetails = state => data(state).building;
export const extractSpaces = state => data(state).spaces;
export const extractWriting = state => data(state).writing;
export const extractBuildingBookingsData = state => spacesAndServices(state);
export const extractAdditionalServices = state => spacesAndServices(state).additionalServices;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractSetWritingMessageFromSpacesToOwnerLoading = state => reqs(state).setWritingMessageFromSpacesToOwnerRequest.loading;
export const extractSetWritingMessageFromSpacesToOwnerError = state => reqs(state).setWritingMessageFromSpacesToOwnerRequest.error;
export const extractSendMessageFromSpacesToOwnerLoading = state => reqs(state).sendMessageFromSpacesToOwnerRequest.loading;
export const extractSendMessageFromSpacesToOwnerError = state => reqs(state).sendMessageFromSpacesToOwnerRequest.error;
export const extractToggleFavoriteBuildingLoading = state => reqs(state).toggleFavoriteBuildingRequest.loading;
export const extractToggleFavoriteBuildingError = state => reqs(state).toggleFavoriteBuildingRequest.error;
export const extractRequestBookingLoading = state => reqs(state).requestBookingRequest.loading;
export const extractRequestBookingError = state => reqs(state).requestBookingRequest.error;
export const extractFetchSpaceAvailabilityLoading = state => reqs(state).fetchSpaceAvailabilityRequest.loading;
export const extractFetchSpaceAvailabilityError = state => reqs(state).fetchSpaceAvailabilityRequest.error;
export const extractFetchBuildingDetailsLoading = state => reqs(state).fetchBuildingDetailsRequest.loading;
export const extractFetchBuildingDetailsError = state => reqs(state).fetchBuildingDetailsRequest.error;
export const extractFetchBuildingBookingsForNeedLoading = state => reqs(state).fetchBuildingBookingsForNeedRequest.loading;
export const extractFetchBuildingBookingsForNeedError = state => reqs(state).fetchBuildingBookingsForNeedRequest.error;
export const extractFetchAdditionalServicesLoading = state => reqs(state).fetchAdditionalServicesRequest.loading;
export const extractFetchAdditionalServicesError = state => reqs(state).fetchAdditionalServicesRequest.error;
export const extractOrderAdditionalServicesLoading = state => reqs(state).orderAdditionalServicesRequest.loading;
export const extractOrderAdditionalServicesError = state => reqs(state).orderAdditionalServicesRequest.error;
