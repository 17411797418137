import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import classNames from 'classnames';
import { Language } from '@common/enum/language';
import { LanguageItem } from '@containers/LanguageSelect/components/LanguageItem';
import { RootState } from '@root/store';
import { setLanguageRoutine } from '@reducers/translation.routines';
import styles from './styles.module.scss';

const languages = [
  {
    name: 'English',
    code: Language.EN,
    displayName: 'EN'
  },
  {
    name: 'Español',
    code: Language.ES,
    displayName: 'ES'
  },
  {
    name: 'Português',
    code: Language.PT,
    displayName: 'PT'
  },
  {
    name: '简体中文',
    code: Language.CN,
    displayName: 'ZH'
  }
];

interface ILanguageSelectProps {
  wrapperClassName?: string;
  languagePickerClassName?: string;
}

const LanguageSelect = ({ wrapperClassName, languagePickerClassName }: ILanguageSelectProps) => {
  const dispatch = useDispatch();
  const language = useSelector((state: RootState) => state.translation.language);

  const defaultLanguage = languages.find(lang => lang.code === language) ?? languages[0];
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const handleLanguageChange = useCallback(item => {
    setSelectedLanguage(item);
    dispatch(setLanguageRoutine.fulfill(item.code));
  }, [dispatch]);

  const renderLanguageItems = languages.map(lang => (
    <LanguageItem
      key={lang.code}
      selectedLangCode={selectedLanguage.code}
      {...lang}
      onClick={handleLanguageChange}
    />
  ));

  const languagePicker = (
    <div className={classNames(styles.language_picker, languagePickerClassName)}>
      <span className={styles.label}>{selectedLanguage.displayName}</span>
    </div>
  );

  return (
    <Dropdown
      item
      trigger={languagePicker}
      icon={null}
      className={classNames(styles.dropdown_wrapper, wrapperClassName)}
    >
      <Dropdown.Menu className={styles.menu_wrapper}>
        {renderLanguageItems}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { LanguageSelect };
