import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import ResultsWithPagination from '@components/ResultsWithPagination';
import { IBindingCallback1 } from '@models/Callbacks';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import { IPageable } from '@models/domain/PageableReducerState';
import NoDataContainer from '@components/NoDataContainer';
import NewTable, { INewTableProps } from '@components/NewDesign/Table/NewTable';

export interface ITableWithOrdersProps extends IPageable {
  ordersLoading: boolean;
  setPage: IBindingCallback1<number>;
  tableProps: INewTableProps;
  loadOrders: IBindingCallback1<IPageRequest>;
}

const TableWithOrders: React.FC<ITableWithOrdersProps> = (
  { totalPages, page, ordersLoading, loadOrders, tableProps, setPage, pageSize }
) => {
  useEffect(() => {
    loadOrders({ page, size: pageSize });
  }, [loadOrders, page, pageSize]);

  return (
    <>
      {ordersLoading && <Loader active inline="centered" />}
      {!ordersLoading && (
        <ResultsWithPagination loading={ordersLoading} setPage={setPage} totalPages={totalPages} page={page}>
          <NewTable {...tableProps} />
        </ResultsWithPagination>
      )}
      {!ordersLoading && { ...tableProps }.rows.length === 0
        ? <NoDataContainer message="You do not have any Orders yet" />
        : ''}
    </>
  );
};

export default TableWithOrders;
