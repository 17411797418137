import { combineReducers } from 'redux';
import { reducerCreator } from '@helpers/reducer.helper';
import { partnerPageReducer } from '@screens/static/Partner/containers/PartnerPage/reducer';
/* PlopJS import placeholder. Do not remove */
import { submitPartnerPageRoutine } from '@screens/static/routines';
import { RootState } from '@root/store';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  submitPartnerPageFormRequest: reducerCreator([submitPartnerPageRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  data: partnerPageReducer
});

const reqs = (state: RootState) => state.staticPage.partnerPageReducer.requests;
const data = (state: RootState) => state.staticPage.partnerPageReducer.data;

export const extractContactUsData = state => data(state).contactUsResponse;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractSubmitPartnerPageFormLoading = state => reqs(state).submitPartnerPageFormRequest.loading;
export const extractSubmitPartnerPageFormError = state => reqs(state).submitPartnerPageFormRequest.error;
