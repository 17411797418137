import { createReducer } from '@reduxjs/toolkit';
import { IMessageDto } from '@models/domain/message/MessageDto';
import {
  fetchMessageCenterMessagesRoutine,
  readMessageFromMessageCenterRoutine, setMessageCenterMessagesPageRoutine,
  setMessageFullFromMessageCenterRoutine, setWritingMessageFromMessageCenterRoutine
} from '@screens/HavesDashboard/MessageCenter/routines';
import { getInitialPageableState, IPageableReducerState } from '@models/domain/PageableReducerState';

export interface IMessageCenterReducerState {
  messages: IPageableReducerState<IMessageDto>;
  writing: boolean;
  messageFull?: IMessageDto;
}

const initialState: IMessageCenterReducerState = {
  messages: getInitialPageableState<IMessageDto>(),
  writing: false
};

export const messageCenterReducer = createReducer(initialState, {
  [setMessageFullFromMessageCenterRoutine.FULFILL]: (state, { payload }) => {
    state.messageFull = payload;
  },
  [readMessageFromMessageCenterRoutine.SUCCESS]: (state, { payload }) => {
    state.messages.items = state.messages.items.map(m => (m.id === payload
      ? { ...m, read: true }
      : m
    ));
  },
  [setWritingMessageFromMessageCenterRoutine.FULFILL]: (state, { payload }) => {
    state.writing = payload;
  },
  [fetchMessageCenterMessagesRoutine.SUCCESS]: (state, { payload }) => {
    state.messages.items = payload.items;
    state.messages.totalResults = payload.totalResults;
    state.messages.totalPages = payload.totalPages;
  },
  [setMessageCenterMessagesPageRoutine.FULFILL]: (state, { payload }) => {
    state.messages.page = payload;
  }
});
