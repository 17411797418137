import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import {
  extractBuildingData,
  extractOrderWithServices, extractToggleFavoriteLoading
} from '@screens/ServiceOrderCheckout/reducers';
// import Rating from '@screens/BookingCheckout/components/BookingDetails/Rating';
import BuildingHeaderInfo from '@screens/BookingCheckout/components/BookingDetails/BuildingHeaderInfo';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import { getDefaultImageFromBuildingType } from '@helpers/image.placeholder.helper';
import ZoomableImage from '@screens/BuildingDetails/components/ZoomableImage';
import BuildingTags from '@screens/BuildingDetails/components/DescriptionSection/components/BuildingTags';
import { IOrderWithServicesDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderCheckout';
import OrderedServicesSummary from '@screens/ServiceOrderCheckout/components/ServiceOrderDetails/OrderedServicesSummary';
import ServiceOrderGrandTotal
  from '@screens/ServiceOrderCheckout/components/ServiceOrderDetails/ServiceOrderGrandTotal';
import s from './styles.module.scss';
import { editOrderNotesRoutine, toggleFavoriteRoutine } from '@screens/ServiceOrderCheckout/routines';
import OtherNeedsNotes from '@screens/BookingCheckout/components/BookingDetails/OtherNeedsNotes';

export interface IBookingDetailsProps extends IState, IActions, IDetailsCheckoutStepProps {
}

export interface IDetailsCheckoutStepProps {
  proceedToNextStep?: IBindingAction;
}

interface IState {
  orderWithServices: IOrderWithServicesDto;
  building: IBuildingForDisplaying;
  loadingFavorite: boolean;
}

interface IActions {
  toggleFavorite: IBindingCallback1<string>;
  editNotes: IBindingCallback1<IEditOrderNotes>;
}
export interface IBookingDetailsLocationState {
  prevPath?: string;
  search?: string;
}

export interface IEditOrderNotes {
  orderId: string;
  note: string;
}

const ServiceOrderDetailsCheckoutStep: React.FC<IBookingDetailsProps> = (
  { orderWithServices, building, toggleFavorite,
    loadingFavorite, proceedToNextStep, editNotes }
) => {
  const location = useLocation<IBookingDetailsLocationState>();
  const [, setPrevPath] = useState('');
  const [notesText, setNotesText] = useState('');

  useEffect(() => {
    if (location.state?.prevPath) setPrevPath(location.state.prevPath);
  }, [location]);

  useEffect(() => {
    setNotesText(orderWithServices?.order?.note);
  }, [orderWithServices]);

  const handleClick = () => {
    proceedToNextStep();
    editNotes({
      orderId: orderWithServices.order.id,
      note: notesText
    });
  };

  return (
    <div className={s.wrapper}>
      <div className={s.details__container}>
        <div className={s.details__left}>
          <ZoomableImage
            src={building?.avatar || getDefaultImageFromBuildingType(building?.type)}
          />
        </div>
        <div className={s.details__right}>
          <BuildingHeaderInfo
            building={building}
            toggleLike={toggleFavorite}
            likeLoading={loadingFavorite}
          />
          {/* <Rating />*/}
          <BuildingTags building={building} />
          <OrderedServicesSummary
            services={orderWithServices.services}
            order={orderWithServices.order}
          />
          <OtherNeedsNotes notesText={notesText} setNotesText={setNotesText} />
        </div>
      </div>
      <ServiceOrderGrandTotal order={orderWithServices.order} />
      <div className={s.details__buttons}>
        <PrimaryButton onClick={handleClick} content="Confirm & Continue" />
      </div>
    </div>
  );
};

const mapStateToProps: (state) => IState = state => ({
  building: extractBuildingData(state),
  orderWithServices: extractOrderWithServices(state),
  loadingFavorite: extractToggleFavoriteLoading(state)
});

const mapDispatchToProps: IActions = {
  toggleFavorite: toggleFavoriteRoutine,
  editNotes: editOrderNotesRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrderDetailsCheckoutStep);
