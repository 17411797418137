import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useTranslation } from '@root/hooks/use-translation';

const LinkWithTranslate = ({ children, ...props }: LinkProps) => {
  const { t } = useTranslation();

  return (
    <Link {...props}>
      {t(children)}
    </Link>
  );
};

export { LinkWithTranslate };
