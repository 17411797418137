import ScheduleSavingModal
, { ISavingModalProps } from '@screens/NeedsDashboard/Bookings/components/EditingSchedulesSegment/ScheduleSavingModal';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  extractEditedSchedule,
  extractSaveScheduleLoading
} from '@screens/NeedsDashboard/Bookings/reducers';
import {
  cancelScheduleRoutine,
  saveScheduleRoutine,
  setEditedScheduleRoutine
} from '@screens/NeedsDashboard/Bookings/routines';
import {
  IBookingDetailsForSchedulingDto,
  ITemplate2Amount
} from '@screens/NeedsDashboard/Bookings/model/BookingDetailsDto';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/Bookings/model/ScheduleResponse';
import { ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { IScheduleSpaceNoteDto } from '@screens/NeedsDashboard/Bookings/model/ScheduleSpaceNoteDto';
import { calcEmptySpaces, calcFilledSpaces } from '@helpers/spaces.helper';
import { IBookingCheckoutData } from '@screens/BookingCheckout/model/BookingCheckout';
import { extractBookingDetails } from '@screens/BookingCheckout/reducers';

interface IManageScheduleModalProps {
  schedules: IScheduleResponseDto[];
}

const ManageScheduleModal = ({ schedules }: IManageScheduleModalProps) => {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState<ISavingModalProps>(undefined);
  const {
    bookingDetails,
    editedScheduleData,
    isSaveScheduleLoading
  } = useSelector(state => ({
    bookingDetails: extractBookingDetails(state),
    editedScheduleData: extractEditedSchedule(state),
    isSaveScheduleLoading: extractSaveScheduleLoading(state)
  }));
  const [availableSpaces, setAvailableSpaces] = useState<ISpaceWithAvailability[]>([]);
  const [filledSpaces, setFilledSpaces] = useState<IScheduleSpaceNoteDto[]>([]);

  const { booking: bookingWithSpaces, templatesToAmounts, building } = (bookingDetails ?? {}) as IBookingCheckoutData;

  const handleSaveSchedule = useCallback(payload => {
    dispatch(saveScheduleRoutine.trigger(payload));
  }, [dispatch]);

  const handleCancelSchedule = useCallback(payload => {
    dispatch(cancelScheduleRoutine.trigger(payload));
  }, [dispatch]);

  useEffect(() => {
    if (editedScheduleData) {
      setModalData({ initialData: editedScheduleData, save: handleSaveSchedule, cancelSchedule: handleCancelSchedule });
    } else {
      setModalData(undefined);
    }
  }, [editedScheduleData, handleCancelSchedule, handleSaveSchedule, setModalData]);

  const updateSpaces = (bookedT2A: ITemplate2Amount[], scheduleItems: IScheduleResponseDto[]) => {
    setAvailableSpaces(calcEmptySpaces(bookedT2A, scheduleItems));
    setFilledSpaces(calcFilledSpaces(scheduleItems));
  };

  useEffect(() => {
    if (templatesToAmounts && schedules) {
      updateSpaces(templatesToAmounts, schedules);
    }
  }, [schedules, templatesToAmounts]);

  const handleCloseExpandedScheduleModal = useCallback(() => {
    dispatch(setEditedScheduleRoutine.fulfill(undefined));
  }, [dispatch]);

  const bookingDetailsProps = ({
    templatesToAmounts,
    booking: bookingWithSpaces.booking,
    building
  }) as unknown as IBookingDetailsForSchedulingDto;

  return (
    <ScheduleSavingModal
      createdSchedules={schedules}
      modalProps={modalData}
      saveLoading={isSaveScheduleLoading}
      open={modalData !== undefined}
      onClose={handleCloseExpandedScheduleModal}
      bookingDetails={bookingDetailsProps}
      availableSpaces={availableSpaces}
      filledSpaces={filledSpaces}
      updateSpaces={updateSpaces}
    />
  );
};

export { ManageScheduleModal };
