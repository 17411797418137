import React from 'react';
import styles from './styles.module.scss';
import commonInputStyles from '@components/formik/common/styles.module.scss';
import { useTranslation } from '@root/hooks/use-translation';

export interface IFormLabelWrapperProps {
}

const FormLabelWrapper: React.FC<IFormLabelWrapperProps> = (
  { children }
) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <label className={commonInputStyles.label}>{t(children)}</label>
    </div>
  );
};

export default FormLabelWrapper;
