import * as Yup from 'yup';
import { Regex } from '@models/domain/Regex';
import { DECIMAL_MAX_VALUE } from '@helpers/validation.helper';

export const passwordValidation = Yup.string()
  .required('Password is required')
  .matches(new RegExp(Regex.PASSWORD_REGEX));

export const repeatPasswordValidation = Yup.string()
  .oneOf([Yup.ref('password'), null])
  .required('Password confirm is required');

export const emailValidation = Yup.string()
  .email('Email must be correct')
  .required('E-mail is required');

export const phoneValidation = Yup.string()
  .required('Phone number is required')
  .matches(new RegExp(Regex.PHONE_REGEX), 'Phone number must contain only digits');

export const positiveIntegerValidation = Yup.string()
  .required('This number is required')
  .matches(new RegExp(Regex.POSITIVE_INTEGER_REGEX), 'Number should be positive integer');

export const nullablePositiveIntegerValidation = Yup.string()
  .nullable()
  .matches(new RegExp(Regex.POSITIVE_INTEGER_REGEX), 'Number should be positive integer');

export const nullableValidation = Yup.string()
  .nullable();

export const notNullValidation = Yup.string()
  .required();

export const requiredValidation = Yup.string()
  .trim()
  .required();

export const priceValidation = Yup.string()
  .required('The price is required')
  .matches(new RegExp(Regex.PRICE_REGEX), 'Illegal price')
  .test(
    'minimal value',
    'Price should be positive',
    (v: string) => Number(v) > 0 && Number(v) < DECIMAL_MAX_VALUE
  );

export const zipValidation = Yup.string()
  .required()
  .matches(new RegExp(Regex.ZIP_CODE), 'Zip should be correct');

