import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Header, Label, Segment } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import { IBindingCallback1, IBindingAction } from '@models/Callbacks';
import { IImageDto } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { maxImagesCount } from '@screens/BuildingEditor/model/ConstValue';
import { showErrorModalRoutine } from '@screens/ErrorModal/routines';
import { IErrorModalInfo } from '@screens/ErrorModal/containers/ErrorModalPage/reducer';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import LabelWrapper from '@components/NewDesign/LabelWrapper';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';
import styles from './styles.module.scss';

export interface IImageUploaderProps extends IActions {
  selectedImages: File[];
  setSelectedImages: React.Dispatch<React.SetStateAction<File[]>>;
  uploading: boolean;
  previewImages: IBindingAction;
  uploadImages: IBindingCallback1<FormData>;
  uploadError: string;
  images: IImageDto[];
}

interface IActions {
  showError: IBindingCallback1<IErrorModalInfo>;
}

const ImageUploader: React.FC<IImageUploaderProps> = (
  {
    selectedImages, setSelectedImages, uploading, uploadImages,
    uploadError, previewImages, images
  }
) => {
  const [localError, setLocalError] = useState(uploadError);
  const [localUploading, setLocalUploading] = useState(uploading);

  const selectedImagesContainsAtLeastOneNotImageFile = () => {
    let errorPresent = false;
    selectedImages.forEach(file => {
      if (!file.type.startsWith('image/')) {
        errorPresent = true;
      }
    });
    return errorPresent;
  };

  const handleUploadClick = () => {
    if (selectedImagesContainsAtLeastOneNotImageFile()) {
      toastr.error('Failed to upload images', 'Please upload only pictures, other file types are not allowed.');
    } else {
      const formData = new FormData();
      selectedImages.forEach(file => formData.append('files', file));
      uploadImages(formData);
    }
  };

  useEffect(() => {
    setLocalError(uploadError);
  }, [uploadError]);

  useEffect(() => {
    setLocalError(undefined);
  }, [selectedImages]);

  useEffect(() => {
    if (!uploading && !uploadError && localUploading) {
      setSelectedImages([]);
    }
    setLocalUploading(uploading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploading]);

  return (
    <Segment className={styles.container}>
      <Header size="medium">
        <Header3>Images to upload</Header3>
      </Header>
      {((images.length + selectedImages.length) > maxImagesCount)
      && (
      <LabelWrapper color="red" basic className={styles.error}>
        {`Only ${maxImagesCount} images could be uploaded totally`}
      </LabelWrapper>
      )}
      {localError && <Label color="red" basic className={styles.error}>{localError}</Label>}
      {!uploading && (
        <div className={styles.file_selection}>
          <LabelWrapper basic>
            {`Selected ${selectedImages.length} file(s).`}
          </LabelWrapper>
          {Array.from(selectedImages).map((img, index) => (
            <LabelWrapper
              color="blue"
              key={img.name}
              className={styles.file_label}
              onClick={() => setSelectedImages(prev => prev.filter((_, i) => i !== index))}
            >
              {img.name}
            </LabelWrapper>
          ))}
        </div>
      )}
      <div className={styles.buttons}>
        <PrimaryButton
          icon="cloud upload"
          labelPosition="left"
          content="Upload"
          disabled={(images.length + selectedImages.length) > maxImagesCount ? Boolean(true) : Boolean(false)}
          loading={uploading}
          onClick={handleUploadClick}
          marginZero
        />
        <PrimaryButton
          icon="image"
          labelPosition="left"
          content="Preview"
          onClick={previewImages}
          marginZero
          className={styles.preview_button}
        />
      </div>
    </Segment>
  );
};

const mapDispatchToProps: IActions = {
  showError: showErrorModalRoutine.fulfill
};

export default connect(null, mapDispatchToProps)(ImageUploader);
