import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

export interface IGoToButtonProps extends LinkProps {
  marginZero?: boolean;
  external?: boolean;
}

const GoToButton: React.FC<IGoToButtonProps> = props => {
  const { className, children, to, marginZero, external, ...restProps } = props;

  const { t } = useTranslation();

  const content = (
    <>
      {t(children)}
      <Icon name="arrow right" className={styles.arrow} />
    </>
  );

  if (external && typeof to === 'string') {
    const redirect = () => {
      window.location.href = to;
    };

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        {...restProps as any}
        onClick={redirect}
        className={classNames(styles.container, { [styles.margin_zero]: marginZero }, className)}
      >
        {content}
      </div>
    );
  }

  return to
    ? (
      <Link
        {...restProps}
        to={to}
        className={classNames(styles.container, { [styles.margin_zero]: marginZero }, className)}
      >
        {content}
      </Link>
    )
    : (
      <div
        {...restProps as any}
        className={classNames(styles.container, { [styles.margin_zero]: marginZero }, className)}
      >
        {content}
      </div>
    );
};

export default GoToButton;
