import React from 'react';
import { connect } from 'react-redux';
import { IPageable } from '@models/domain/PageableReducerState';
import { IBindingCallback1 } from '@models/Callbacks';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import {
  extractCurrentPage,
  extractPageSize,
  extractTotalPages,
  extractFetchOrdersLoading, extractOrders } from '@screens/NeedsDashboard/Orders/reducers';
import { ISimpleTableProps } from '@components/SimpleTable';
import TableWithOrders from '@screens/NeedsDashboard/Orders/components/TableWithOrders';
import { IServiceOrderDetailsDto } from '@screens/NeedsDashboard/Orders/model/ServiceOrderDetailsDto';
import { fetchOrdersRoutine, setCurrentPageRoutine } from '@screens/NeedsDashboard/Orders/routines';

export interface IOrdersTableProps extends IActions, IState {
  tableProps: (orders: IServiceOrderDetailsDto[]) => ISimpleTableProps;
}

interface IActions {
  setPage: IBindingCallback1<number>;
  loadOrders: IBindingCallback1<IPageRequest>;
}

interface IState extends IPageable {
  orders: IServiceOrderDetailsDto[];
  ordersLoading: boolean;
}

const OrdersTable: React.FC<IOrdersTableProps> = (
  {
    setPage, loadOrders, ordersLoading, page, totalPages,
    pageSize, tableProps, orders
  }
) => (
  <TableWithOrders
    ordersLoading={ordersLoading}
    loadOrders={loadOrders}
    totalPages={totalPages}
    page={page}
    setPage={setPage}
    pageSize={pageSize}
    tableProps={tableProps(orders)}
  />
);

const mapStateToProps: (state) => IState = state => ({
  totalPages: extractTotalPages(state),
  page: extractCurrentPage(state),
  pageSize: extractPageSize(state),
  orders: extractOrders(state),
  ordersLoading: extractFetchOrdersLoading(state)
});

const mapDispatchToProps: IActions = {
  loadOrders: fetchOrdersRoutine,
  setPage: setCurrentPageRoutine.fulfill
};

export { OrdersTable };

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTable);
