import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import { callApi } from '@helpers/api.helper';

const ordersService = {
  fetchOrders: (page: IPageRequest) => callApi({
    method: 'GET',
    endpoint: 'service_orders',
    queryParams: {
      page: page.page - 1,
      size: page.size
    }
  })
};

export default ordersService;
