import React from 'react';
import { Label, LabelGroup, Item, Table } from 'semantic-ui-react';
import moment from 'moment';
import { colorOfScheduleStatus, iconOfScheduleStatus } from '@models/domain/schedule/ScheduleStatus';
import { colorOfScheduleType, iconOfScheduleType, ScheduleType } from '@models/domain/schedule/ScheduleType';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/Bookings/model/ScheduleResponse';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';
import FormPlaceholder from '@components/NewDesign/Typography/FormAndTable/FormPlaceholder';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import LabelWrapper from '@components/NewDesign/LabelWrapper';
import styles from '../styles.module.scss';

export interface IExistingScheduleInfoProps {
  formValues: IScheduleResponseDto;
}

const ExistingScheduleInfo: React.FC<IExistingScheduleInfoProps> = ({ formValues: values }) => (
  <>
    <LabelGroupWrapper
      wrapper={LabelGroup}
      title="Appointed time"
      value={(
        <LabelWrapper content={moment(values.scheduledTime).format('LLL')} />
      )}
    />
    <LabelGroupWrapper
      wrapper={LabelGroup}
      title="Status"
      value={(
        <LabelWrapper
          color={colorOfScheduleStatus(values.status)}
          content={values.status}
          icon={iconOfScheduleStatus(values.status)}
        />
      )}
    />
    <LabelGroupWrapper
      wrapper={LabelGroup}
      title="Schedule type"
      value={(
        <LabelWrapper
          color={colorOfScheduleType(values.type)}
          content={ScheduleType[values.type]}
          icon={iconOfScheduleType(values.type)}
        />
      )}
    />
    <Item.Group divided>
      {values?.spaces.map(space => (
        <Item key={space.id}>
          <Item.Content>
            <Item.Header>
              <SubHeader1 className={styles.subheader}>
                {space.index}
              </SubHeader1>
            </Item.Header>
            <div className={styles.content}>
              <Table basic="very" collapsing size="small" compact attached>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Label size="small">
                        <TableImportantContent>ID:</TableImportantContent>
                      </Label>
                    </Table.Cell>
                    <Table.Cell>
                      <FormPlaceholder>{space.id}</FormPlaceholder>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Label size="small">
                        <TableImportantContent>Space template ID:</TableImportantContent>
                      </Label>
                    </Table.Cell>
                    <Table.Cell>
                      <FormPlaceholder>{space.spaceTemplateId}</FormPlaceholder>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Label size="small">
                        <TableImportantContent>Note:</TableImportantContent>
                      </Label>
                    </Table.Cell>
                    <Table.Cell>
                      <FormPlaceholder>{space.note}</FormPlaceholder>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </Item.Content>
        </Item>
      ))}
    </Item.Group>
  </>
);

export default ExistingScheduleInfo;
