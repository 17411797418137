import { RootState } from '@root/store';

const SET_FOOTER_COLOR = 'APP_ROUTER:SET_FOOTER_COLOR';

export const setFooterLightBlue = (payload: boolean) => ({ type: SET_FOOTER_COLOR, payload });

const routerReducer = (state = { footerLightBlue: false }, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FOOTER_COLOR:
      return {
        ...state,
        footerLightBlue: payload
      };
    default:
      return state;
  }
};

export const extractIsFooterLightBlue = (state: RootState) => state.routerReducer.footerLightBlue;

export default routerReducer;
