import React from 'react';
import styles from './styles.module.scss';
import { ILegalDocumentDto } from '@screens/TermsAndConditions/Root/components/Routing';
import MetaTags from '@components/MetaTags';

export interface IPdfPageProps {
  documentDto: ILegalDocumentDto;
}

const PdfPage: React.FC<IPdfPageProps> = ({ documentDto }) => (
  <div className={styles.container}>
    <MetaTags title={documentDto.title} withSuffix />
    <object
      data={documentDto.document}
      type="application/pdf"
      className={styles.embed}
    >
      <iframe
        title="Document"
        src={`https://docs.google.com/viewerng/viewer?url=${documentDto.document}&embedded=true`}
        frameBorder="0"
        className={styles.embed}
      />
    </object>

  </div>
);

export default PdfPage;
