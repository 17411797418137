import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import LinkedinIcon from '@components/icons/linkedin';
import FacebookIcon from '@components/icons/facebook';
import { extractLocationPathname } from '@helpers/url.helper';
import { extractIsFooterLightBlue } from '@containers/AppRoute/reducer';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import { TERMS_AND_CONDITIONS_ENDPOINTS } from '@screens/TermsAndConditions/Root/components/Routing/endpoints';
import HorizontalLogo from '@components/logos/HorizontalLetters';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import Caption4 from '@components/NewDesign/Typography/Caption/Caption4';
import LinkWithAutoScrollButton from '@components/NewDesign/Button/LinkWithAutoScrollButton';
import ExternalLinkButton from '@components/NewDesign/Button/ExternalLinkButton';
import { STATIC_SITE_LINKS } from '@root/assets/links/static-site-links';
import { useWindowSize } from '@root/hooks/use-window-size';
import s from './styles.module.scss';

const {
  TERMS_AND_CONDITIONS
} = ENDPOINTS;
const {
  PRIVACY_POLICY
} = TERMS_AND_CONDITIONS_ENDPOINTS;

export interface IFooterProps {
  lightBlue: boolean;
  hidden: boolean;
  blackListPaths: string[];
}

const MOBILE_FOOTER_LAYOUT_WIDTH_THRESHOLD = 750;

const Footer: React.FC<IFooterProps> = ({ lightBlue = false, hidden }) => {
  const { width } = useWindowSize();

  return (
    <>
      {hidden ? null : (
        <div className={lightBlue ? s.footer__lightBlue : s.footer__dark}>
          <div className={classNames('content_wrapper', s.vertical_space, s.columns)}>
            { width > MOBILE_FOOTER_LAYOUT_WIDTH_THRESHOLD ? <DefaultLayout /> : <MobileLayout />}
          </div>
        </div>
      )}
    </>
  );
};

const Logo = () => (
  <>
    <div className={s.logoLink}>
      <HorizontalLogo className={classNames(s.logoImg, s.logoWhite)} white />
      <HorizontalLogo className={classNames(s.logoImg, s.logoBlack)} />
    </div>
  </>
);

const Copyright = ({ className }: { className?: string }) => (
  <Caption4 disableTranslation className={classNames(s.text, className)}>
    &copy; Copyright&nbsp;
    <>2024 Shared Spaces LLC</>
  </Caption4>
);

const Contact = () => (
  <>
    <BodyText2 className={s.text}>Contact</BodyText2>
    <Caption2 className={s.text}>
      801 N. East Street Ste. 9A
      <br />
      Frederick, Maryland 21701
    </Caption2>
    <a href="mailto:support@sharedspaces.io" rel="noopener noreferrer" target="_blank">
      <Caption2 disableTranslation className={s.text}>
        support@sharedspaces.io
      </Caption2>
    </a>
    <a href="tel:2403572333" rel="noopener noreferrer" target="_blank">
      <Caption2 disableTranslation className={s.text}>
        240-357-2333
      </Caption2>
    </a>
  </>
);

const Links = () => (
  <>
    <ExternalLinkButton to={STATIC_SITE_LINKS.BLOG}>
      <BodyText2 className={s.text}>Blog</BodyText2>
    </ExternalLinkButton>
    <ExternalLinkButton to={STATIC_SITE_LINKS.JOIN_OUR_TEAM}>
      <BodyText2 className={s.text}>Join Our Team</BodyText2>
    </ExternalLinkButton>
    <ExternalLinkButton to={STATIC_SITE_LINKS.OUR_STORY}>
      <BodyText2 className={s.text}>Our Story</BodyText2>
    </ExternalLinkButton>
    <LinkWithAutoScrollButton to={TERMS_AND_CONDITIONS}>
      <BodyText2 className={s.text}>Terms & Conditions</BodyText2>
    </LinkWithAutoScrollButton>
    <LinkWithAutoScrollButton to={PRIVACY_POLICY}>
      <BodyText2 className={s.text}>Privacy Policy</BodyText2>
    </LinkWithAutoScrollButton>
  </>
);

const Socials = () => (
  <div className={classNames(s.row, s.socials)}>
    <a
      href="https://www.facebook.com/sharedspacesgroup"
      rel="noopener noreferrer"
      target="_blank"
    >
      <FacebookIcon />
    </a>
    <a
      href="https://www.linkedin.com/company/72685708"
      rel="noopener noreferrer"
      target="_blank"
      className={s.linkedin}
    >
      <LinkedinIcon />
    </a>
  </div>
);

const DefaultLayout = () => (
  <>
    <div className={classNames(s.column, s.logoColumn)}>
      <Logo />
      <Copyright />
    </div>
    <div className={s.column}>
      <Contact />
    </div>
    <div className={classNames(s.column, s.links_list)}>
      <Links />
      <Socials />
    </div>
  </>
);

const MobileLayout = () => (
  <>
    <div className={classNames(s.logoColumn)}>
      <Logo />
    </div>
    <div className={s.column}>
      <Contact />
      <Socials />
    </div>
    <Copyright className={s.copyright_mobile} />
  </>
);

const mapStateToProps = (state, ownProps) => ({
  lightBlue: extractIsFooterLightBlue(state),
  hidden: ownProps.blackListPaths.includes(extractLocationPathname(state))
});

export default connect(mapStateToProps, null)(Footer);
