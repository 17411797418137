import { combineReducers } from 'redux';
/* PlopJS import placeholder. Do not remove */
import contactUsReducer from '@screens/static/GetInTouch/reducers';
import joinOurTeamReducer from '@screens/static/JoinOurTeam/reducers';
import partnerPageReducer from '@screens/static/Partner/reducers';
import expertPageReducer from '@screens/static/Expert/reducers';

export default combineReducers({
  /* PlopJS reducer placeholder. Do not remove */
  contactUsReducer,
  joinOurTeamReducer,
  partnerPageReducer,
  expertPageReducer
});
