import React from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import Tabs from '@components/Tabs';
import HowItWorksNeedContent from '@screens/static/HowItWorks/components/HowItWorksNeedContent';
import HowItWorksHaveContent from '@screens/static/HowItWorks/components/HowItWorksHaveContent';
import { Role } from '@screens/Authorization/models/Roles';
import { extractUserRoles } from '@screens/Authorization/reducers';
import styles from './styles.module.scss';
import MetaTags from '@components/MetaTags';

export interface IHowItWorksLocationState {
  role?: Role;
}

const HowItWorksPage: React.FC = () => {
  const location = useLocation();

  const userRoles = useSelector(extractUserRoles);
  const userRole: Role | null = userRoles.length ? userRoles[0] : null;

  const locationState = location.state as IHowItWorksLocationState;
  const locationRole = locationState?.role;

  const finalRole = locationRole || userRole || Role.NEED;

  const initialIndex = finalRole === Role.NEED
    ? 0
    : 1;

  return (
    <div className={styles.container}>
      <MetaTags
        title="How It Works"
        withSuffix
        description="So You&apos;re Looking for some Space or have Extra Space"
      />
      <Tabs
        initialIndex={initialIndex}
        content={[
          {
            key: 0,
            name: 'Seeking Space',
            element: {
              Element: HowItWorksNeedContent
            }
          },
          {
            key: 1,
            name: 'Offering Space',
            element: {
              Element: HowItWorksHaveContent
            }
          }
        ]}
      />
    </div>
  );
};

export default HowItWorksPage;
