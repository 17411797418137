import React from 'react';
import HowItWorksContent from '@screens/Landing/components/HowItWorksBlock/PageContent';
import styles from './styles.module.scss';

const HowItWorksBlock: React.FC = () => (
  <div className={styles.background}>
    <div className="content_wrapper">
      <HowItWorksContent title="How it Works" />
    </div>
  </div>
);

export default HowItWorksBlock;
