import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import { IBindingCallback1 } from '@root/models/Callbacks';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/Bookings/model/ScheduleResponse';
import { IBookingDetailsForSchedulingDto } from '@screens/NeedsDashboard/Bookings/model/BookingDetailsDto';
import { ScheduleType, schTypeKey } from '@models/domain/schedule/ScheduleType';
import {
  calcEmptySpaces,
  calcFilledSpaces,
  schedulesWithoutGiven
} from '@helpers/spaces.helper';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import DangerButton from '@components/NewDesign/Button/DangerButton';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import Caption3 from '@components/NewDesign/Typography/Caption/Caption3';
import SelectWrapper from '@components/NewDesign/Select';
import LabeledInputWrapper from '@components/NewDesign/LabeledInput';
import BodyText3 from '@components/NewDesign/Typography/BodyText/BodyText3';
import { IScheduleSpacesDto, ISpaceWithNote } from '../../../model/ScheduleSpacesDto';
import { extractAvailableAndScheduledSpaces } from '../../../reducers';
import { IScheduleUpdateHaveRequest } from '../../../model/SpaceIdsWithScheduleId';
import { fetchAvailableAndScheduledSpaces, updateScheduleWithNewSpaces } from '../../../routines';
import styles from './styles.module.scss';

export interface IHandleScheduleSpacesModalProps extends IState, IActions {
  scheduleId: string;
  setExpandedScheduleData: IBindingCallback1<IScheduleResponseDto>;
  booking?: IBookingDetailsForSchedulingDto;
  schedule?: IScheduleResponseDto;
  allSchedules: IScheduleResponseDto[];
}

interface IState {
  scheduleSpaces: IScheduleSpacesDto;
}

interface IActions {
  getAvailableAndScheduledSpaces: IBindingCallback1<string>;
  updateSchedule: IBindingCallback1<IScheduleUpdateHaveRequest>;
}

const HandleScheduleSpacesModal: React.FC<IHandleScheduleSpacesModalProps> = (
  { scheduleId, scheduleSpaces, setExpandedScheduleData, updateSchedule, getAvailableAndScheduledSpaces,
    booking, schedule, allSchedules }
) => {
  const [tempSpacesIndexes, setTempSpacesIndexes] = useState<ISpaceWithNote[]>([]);

  useEffect(() => {
    getAvailableAndScheduledSpaces(scheduleId);
  }, [getAvailableAndScheduledSpaces, scheduleId]);

  useEffect(() => {
    if (scheduleSpaces) {
      setTempSpacesIndexes(scheduleSpaces.spaces);
    }
  }, [scheduleSpaces]);

  const setNewSpaceToBeScheduled = (scheduleSpaceId: string, spaceId: any) => {
    setTempSpacesIndexes(prev => prev.map(spaceWithNote => (spaceWithNote.scheduleSpaceId !== scheduleSpaceId
      ? spaceWithNote
      : {
        ...spaceWithNote,
        spaceId
      }
    )));
  };

  const updateScheduleSpaces = () => {
    updateSchedule({
      request: tempSpacesIndexes.map(t => ({ spaceId: t.spaceId, scheduleSpaceId: t.scheduleSpaceId })),
      scheduleId
    });
    setExpandedScheduleData(undefined);
  };

  const allSchedulesWithoutEdited = useMemo(
    () => schedulesWithoutGiven(allSchedules, schedule?.id),
    [allSchedules, schedule]
  );
  const filledSpaceIds = useMemo(
    () => calcFilledSpaces(allSchedulesWithoutEdited).map(s => s.id),
    [allSchedulesWithoutEdited]
  );
  const emptySpaceIds = useMemo(
    () => calcEmptySpaces(booking.templatesToAmounts, allSchedulesWithoutEdited).flatMap(s => s.spaceIdsAvailable),
    [booking, allSchedulesWithoutEdited]
  );

  const getOptions = useCallback((spaceTemplateId: string) => {
    const currTemplate = booking.templatesToAmounts?.find(t => t.spaceTemplate.id === spaceTemplateId);
    const allOptions = currTemplate.spaces
      .map(item => ({ text: item.index, value: item.id }))
      .sort((a, b) => a.text.localeCompare(b.text));
    switch (schedule?.type) {
      case schTypeKey(ScheduleType.PICK_UP):
        return allOptions.filter(o => filledSpaceIds.find(i => i === o.value));
      case schTypeKey(ScheduleType.DROP_OFF):
        return allOptions.filter(o => emptySpaceIds.find(i => i === o.value));
      default:
        return [];
    }
  }, [booking.templatesToAmounts, emptySpaceIds, filledSpaceIds, schedule]);

  const chosenSpaceIds = tempSpacesIndexes.map(i => i.spaceId);
  const duplicatesPresent = tempSpacesIndexes.length !== new Set(chosenSpaceIds).size;

  return (
    <Modal
      open={!!scheduleId}
      onClose={() => setExpandedScheduleData(undefined)}
      size="small"
      closeIcon
    >
      <ModalHeaderWrapper>Map spaces to indexes</ModalHeaderWrapper>
      {tempSpacesIndexes && (
        <ModalContent>
          {
            tempSpacesIndexes.map((item, index) => (
              <div className={styles.map_div} key={item.scheduleSpaceId}>
                <Caption3 className={styles.space_index}>
                  Space #
                  {index + 1}
                </Caption3>
                <div className={styles.input}>
                  <LabeledInputWrapper
                    icon="tags"
                    iconPosition="left"
                    label={{ tag: true, content: 'User note' }}
                    labelPosition="right"
                    value={item.note}
                    disabled
                  />
                </div>
                <div className={styles.select}>
                  <SelectWrapper
                    defaultValue={item.spaceId}
                    selection
                    options={getOptions(item.spaceTemplateId)}
                    onChange={(event, data) => setNewSpaceToBeScheduled(item.scheduleSpaceId, data.value)}
                  />
                </div>
              </div>
            ))
          }
          {duplicatesPresent && (
            <div className={styles.duplicatesWarning}>
              <BodyText3>
                Some spaces are chosen twice or more.
              </BodyText3>
            </div>
          )}
        </ModalContent>
      )}
      <ModalActions>
        <div className={styles.buttons}>
          <div>
            <PrimaryButton
              onClick={updateScheduleSpaces}
              disabled={duplicatesPresent}
              content="Save"
            />
          </div>
          <div>
            <DangerButton
              onClick={() => setExpandedScheduleData(undefined)}
              content="Cancel"
            />
          </div>
        </div>
      </ModalActions>
    </Modal>
  );
};

const mapStateToProps: (state) => IState = state => ({
  scheduleSpaces: extractAvailableAndScheduledSpaces(state)
});

const mapDispatchToProps: IActions = {
  getAvailableAndScheduledSpaces: fetchAvailableAndScheduledSpaces,
  updateSchedule: updateScheduleWithNewSpaces
};

export default connect(mapStateToProps, mapDispatchToProps)(HandleScheduleSpacesModal);
