import { combineReducers } from 'redux';
import { RootState } from '@root/store';
import { reducerCreator } from '@helpers/reducer.helper';
import { additionalServicesReducer } from '@screens/HavesDashboard/AdditionalServices/containers/AdditionalServicesPage/reducer';
/* PlopJS import placeholder. Do not remove */
import { setAdditionalServicesPageRoutine } from '@screens/HavesDashboard/AdditionalServices/routines';
import { saveAdditionalServiceRoutine } from '@screens/HavesDashboard/AdditionalServices/routines';
import { loadAdditionalServicesBuildingTitlesRoutine } from '@screens/HavesDashboard/AdditionalServices/routines';
import { loadOneAdditionalServiceRoutine } from '@screens/HavesDashboard/AdditionalServices/routines';
import { setSelectedAdditionalServiceRoutine } from '@screens/HavesDashboard/AdditionalServices/routines';
import { loadAdditionalServicesListRoutine } from '@screens/HavesDashboard/AdditionalServices/routines';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  setAdditionalServicesPageRequest: reducerCreator([setAdditionalServicesPageRoutine.TRIGGER]),
  saveAdditionalServiceRequest: reducerCreator([saveAdditionalServiceRoutine.TRIGGER]),
  loadAdditionalServicesBuildingTitlesRequest: reducerCreator([loadAdditionalServicesBuildingTitlesRoutine.TRIGGER]),
  loadOneAdditionalServiceRequest: reducerCreator([loadOneAdditionalServiceRoutine.TRIGGER]),
  setSelectedAdditionalServiceRequest: reducerCreator([setSelectedAdditionalServiceRoutine.TRIGGER]),
  loadAdditionalServicesListRequest: reducerCreator([loadAdditionalServicesListRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  data: additionalServicesReducer
});

const reqs = (state: RootState) => state.havesDashboard.additionalServices.requests;
const data = (state: RootState) => state.havesDashboard.additionalServices.data;

export const extractAdditionalServicesListItems = state => data(state).items;
export const extractAdditionalServicesListPage = state => data(state).page;
export const extractAdditionalServicesListSize = state => data(state).pageSize;
export const extractAdditionalServicesTotalPages = state => data(state).totalPages;
export const extractBuildingTitles = state => data(state).buildings;
export const extractSelectedAdditionalService = state => data(state).selected;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractSetAdditionalServicesPageLoading = state => reqs(state).setAdditionalServicesPageRequest.loading;
export const extractSetAdditionalServicesPageError = state => reqs(state).setAdditionalServicesPageRequest.error;
export const extractSaveAdditionalServiceLoading = state => reqs(state).saveAdditionalServiceRequest.loading;
export const extractSaveAdditionalServiceError = state => reqs(state).saveAdditionalServiceRequest.error;
export const extractLoadAdditionalServicesBuildingTitlesLoading = state => reqs(state).loadAdditionalServicesBuildingTitlesRequest.loading;
export const extractLoadAdditionalServicesBuildingTitlesError = state => reqs(state).loadAdditionalServicesBuildingTitlesRequest.error;
export const extractLoadOneAdditionalServiceLoading = state => reqs(state).loadOneAdditionalServiceRequest.loading;
export const extractLoadOneAdditionalServiceError = state => reqs(state).loadOneAdditionalServiceRequest.error;
export const extractSetSelectedAdditionalServiceLoading = state => reqs(state).setSelectedAdditionalServiceRequest.loading;
export const extractSetSelectedAdditionalServiceError = state => reqs(state).setSelectedAdditionalServiceRequest.error;
export const extractLoadAdditionalServicesListLoading = state => reqs(state).loadAdditionalServicesListRequest.loading;
export const extractLoadAdditionalServicesListError = state => reqs(state).loadAdditionalServicesListRequest.error;
