import { combineReducers } from 'redux';
import { reducerCreator } from '@helpers/reducer.helper';
import { expertPageReducer } from '@screens/static/Expert/containers/ExpertPage/reducer';
/* PlopJS import placeholder. Do not remove */
import { submitExpertPageRoutine } from '@screens/static/routines';
import { RootState } from '@root/store';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  submitExpertPageFormRequest: reducerCreator([submitExpertPageRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  data: expertPageReducer
});

const reqs = (state: RootState) => state.staticPage.expertPageReducer.requests;
const data = (state: RootState) => state.staticPage.expertPageReducer.data;

export const extractExpertPageData = state => data(state);

/* PlopJS request_extractor placeholder. Do not remove */
export const extractSubmitExpertPageFormLoading = state => reqs(state).submitExpertPageFormRequest.loading;
export const extractSubmitExpertPageFormError = state => reqs(state).submitExpertPageFormRequest.error;
