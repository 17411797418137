import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { IQueryData } from '@screens/BrowseSpaces/model/QueryData';
import { PageSize } from '@screens/BrowseSpaces/model/PageSize';
import { setSearchFiltersRoutine } from '@screens/Landing/routines';
import { IBindingCallback1 } from '@models/Callbacks';
import SearchBarBase from '@components/SearchBarBase';
import { SpaceType } from '@models/domain/SpaceType';

export interface ISearchBarProps {
  setFilters: IBindingCallback1<IQueryData>;
  className?: string;
}

const SearchBar: React.FC<ISearchBarProps> = ({ setFilters, className }) => {
  const [initialQuery] = useState<IQueryData>({
    dates: { startingDate: new Date() },
    spaceType: SpaceType.WAREHOUSE
  });

  const handleSearchClick = (query: IQueryData) => {
    setFilters({
      ...query,
      page: 1,
      size: PageSize.TWENTY_FIVE
    });
  };

  return (
    <SearchBarBase
      className={classNames(className)}
      previousQuery={initialQuery}
      landing
      handleSearchClick={handleSearchClick}
    />
  );
};

const mapDispatchToProps = {
  setFilters: setSearchFiltersRoutine
};

export default connect(null, mapDispatchToProps)(SearchBar);
