import { combineReducers } from 'redux';
import { RootState } from '@root/store';
import { reducerCreator } from '@helpers/reducer.helper';
import { messageCenterReducer } from '@screens/HavesDashboard/MessageCenter/containers/MessageCenterPage/reducer';
import {
  fetchMessageCenterMessagesRoutine,
  readMessageFromMessageCenterRoutine,
  sendMessageFromMessageCenterRoutine
} from '@screens/NeedsDashboard/MessageCenter/routines';
/* PlopJS import placeholder. Do not remove */

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  readMessageFromMessageCenterRequest: reducerCreator([readMessageFromMessageCenterRoutine.TRIGGER]),
  sendMessageFromMessageCenterRequest: reducerCreator([sendMessageFromMessageCenterRoutine.TRIGGER]),
  fetchDashboardMessageCenterRequest: reducerCreator([fetchMessageCenterMessagesRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  data: messageCenterReducer
});

const reqs = (state: RootState) => state.havesDashboard.messageCenter.requests;
const data = (state: RootState) => state.havesDashboard.messageCenter.data;

export const extractMessages = state => data(state).messages;
export const extractMessagesPagination = state => (
  { size: data(state).messages.pageSize, page: data(state).messages.page }
);
export const extractWriting = state => data(state).writing;
export const extractFullMessage = state => data(state).messageFull;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractReadMessageFromMessageCenterLoading = state => reqs(state).readMessageFromMessageCenterRequest.loading;
export const extractReadMessageFromMessageCenterError = state => reqs(state).readMessageFromMessageCenterRequest.error;
export const extractSendMessageFromMessageCenterLoading = state => reqs(state).sendMessageFromMessageCenterRequest.loading;
export const extractSendMessageFromMessageCenterError = state => reqs(state).sendMessageFromMessageCenterRequest.error;
export const extractFetchMessageCenterMessagesLoading = state => reqs(state).fetchDashboardMessageCenterRequest.loading;
export const extractFetchMessageCenterMessagesError = state => reqs(state).fetchDashboardMessageCenterRequest.error;
