import { Routine } from 'redux-saga-routines';
import { submitPartnerPageRoutine } from '@screens/static/routines';

export const partnerPageReducer = (state = null, action: Routine<any>) => {
  switch (action.type) {
    case submitPartnerPageRoutine.SUCCESS:
      return {
        ...state
      };
    default:
      return state;
  }
};
