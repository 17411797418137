import React, { forwardRef, useState } from 'react';
import { Button, Label } from 'semantic-ui-react';
import ImageContainer from '@components/ImageContainer';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { ImageWrapper } from '@screens/BuildingEditor/components/ImagesTab/ImageMiniature/Wrapper';
import styles from './styles.module.scss';

export interface IImageMiniatureProps {
  id: string;
  src: string;
  onClick: IBindingCallback1<string>;
  onDeleteClick: IBindingAction;
  deleting: boolean;
  setAsAvatar: IBindingAction;
  settingAvatar: boolean;
  isAvatar: boolean;
  isDragging?: boolean;
  style?: any;
  hideButtons?: boolean;
}

const withStopPropagation = action => ev => {
  action();
  ev.stopPropagation();
};

const ImageMiniature = forwardRef<HTMLDivElement, IImageMiniatureProps>((
  {
    src, onClick, onDeleteClick, deleting, setAsAvatar, settingAvatar, isAvatar,
    isDragging = false, style, hideButtons, ...props
  }, ref
) => {
  const [isHovering, setHovering] = useState(false);
  const displayOverlay = isHovering || deleting || settingAvatar;

  const handleClick = () => {
    onClick(src);
  };

  const inlineStyles = {
    cursor: isDragging ? 'grabbing !important' : 'grab !important',
    transform: isDragging ? 'scale(1.05)' : 'scale(1)',
    ...style
  };

  const isControlButtonsShown = !hideButtons && !isAvatar;

  return (
    <ImageWrapper
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      ref={ref}
      style={inlineStyles}
      {...props}
    >
      {isAvatar && (
        <Label corner="left" color="orange" icon="star" title="Current avatar" />
      )}
      <ImageContainer src={src} className={`${styles.image} ${isHovering ? styles.image_hovered : ''}`} />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={`${styles.overlay} ${displayOverlay ? styles.overlay_hovering : ''}`}
        onClick={handleClick}
      >
        {isControlButtonsShown && (
          <Button
            className={styles.btn_set_avatar}
            compact
            circular
            color="orange"
            icon="star"
            onClick={withStopPropagation(setAsAvatar)}
            loading={settingAvatar}
            title="Set as avatar"
          />
        )}
        {isControlButtonsShown && (
          <Button
            className={styles.btn_remove}
            compact
            circular
            color="red"
            icon="trash alternate"
            onClick={withStopPropagation(onDeleteClick)}
            loading={deleting}
            title="Delete"
          />
        )}
      </div>
    </ImageWrapper>
  );
});

ImageMiniature.displayName = 'ImageMiniature';

export { ImageMiniature };
