import React from 'react';
import classNames from 'classnames';
import { Icon } from 'semantic-ui-react';
import { lowerCase, startCase } from 'lodash';
import moment from 'moment';
import { toPrice } from '@helpers/price.helper';
import Caption3 from '@components/NewDesign/Typography/Caption/Caption3';
import { IOrderedServiceDto } from '@screens/ServiceOrderCheckout/model/OrderedServiceDto';
import { IServiceOrderDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderDto';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import PopupWrapper from '@components/NewDesign/PopupWrapper';
import styles from './styles.module.scss';

export interface IOrderOneServiceSummaryProps {
  service: IOrderedServiceDto;
  order: IServiceOrderDto;
  showCompletedIndicator?: boolean;
  className?: string;
}

const OrderOneServiceSummary: React.FC<IOrderOneServiceSummaryProps> = (
  { service, order, showCompletedIndicator, className }
) => {
  const scheduledMoment = moment(order.scheduledOnDate);

  const scheduledOnDateInfo = scheduledMoment.format('MMMM Do, YYYY');

  const oneServicePriceToSum = () => ({
    amount: service.price.amount * service.orderedAmount,
    currency: order.currency
  });

  const orderedAmountLabel = `${service.orderedAmount} ${service.billingUnit.toLowerCase()}${service.orderedAmount === 1 ? '' : 's'} ordered`;

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.left_section}>
        <BodyText1 className={styles.header}>
          {startCase(lowerCase(service.alias))}
          &nbsp;|
          {' '}
          {startCase(lowerCase(service.type))}
          &nbsp;|
          {' '}
          {startCase(lowerCase(service.category))}
          &nbsp;|
          {' '}
          {orderedAmountLabel}
          &nbsp;|
          {' '}
          {toPrice({ amount: service.price.amount, currency: service.price.currency })}
          /
          {lowerCase(service.billingUnit)}
        </BodyText1>
        <div className={styles.content}>
          <div className={styles.payment_line}>
            <div className={styles.text}>
              <Caption3>{scheduledOnDateInfo}</Caption3>
            </div>
            <div className={styles.dots} />
            <div className={styles.price}>
              <Caption3>{toPrice(oneServicePriceToSum())}</Caption3>
            </div>
          </div>
        </div>
      </div>
      {showCompletedIndicator && service.completed && (
        <PopupWrapper
          basic
          trigger={<Icon name="check" />}
          content="This service has been marked as completed"
        />
      )}
    </div>
  );
};

export default OrderOneServiceSummary;
