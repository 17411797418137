import React, { useCallback } from 'react';
import { Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import { IBindingCallback1 } from '@root/models/Callbacks';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/Bookings/model/ScheduleResponse';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import styles
  from '@screens/HavesDashboard/DropOffAndPickUp/containers/SchedulesSegment/HandleScheduleSpacesModal/styles.module.scss';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import DangerButton from '@components/NewDesign/Button/DangerButton';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';

export interface IHandleCompleteScheduleModalProps {
  schedule?: IScheduleResponseDto;
  setSchedule: IBindingCallback1<IScheduleResponseDto | undefined>;
  completeSchedule: IBindingCallback1<IScheduleResponseDto>;
}

const HandleCompleteScheduleModal: React.FC<IHandleCompleteScheduleModalProps> = (
  { schedule, setSchedule, completeSchedule }
) => {
  const handleSave = useCallback(() => {
    completeSchedule(schedule);
    setSchedule(undefined);
  }, [completeSchedule, setSchedule, schedule]);

  return (
    <Modal
      open={!!schedule}
      onClose={() => setSchedule(undefined)}
      size="small"
      closeIcon
    >
      <ModalHeaderWrapper>Schedule completion</ModalHeaderWrapper>
      <ModalContent>
        <BodyText2>
          The scheduled time is in the future. Do you really want to mark it as completed?
        </BodyText2>
      </ModalContent>
      <ModalActions>
        <div className={styles.buttons}>
          <div>
            <PrimaryButton
              onClick={handleSave}
              content="Complete"
            />
          </div>
          <div>
            <DangerButton
              onClick={() => setSchedule(undefined)}
              content="Cancel"
            />
          </div>
        </div>
      </ModalActions>
    </Modal>
  );
};

export default HandleCompleteScheduleModal;
