import { combineReducers } from 'redux';
import { reducerCreator } from '@helpers/reducer.helper';
import { contactUsReducer } from '@screens/static/GetInTouch/containers/GetInTouchPage/reducer';
/* PlopJS import placeholder. Do not remove */
import { submitContactUsRoutine } from '@screens/static/routines';
import { RootState } from '@root/store';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  submitContactUsFormRequest: reducerCreator([submitContactUsRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  data: contactUsReducer
});

const reqs = (state: RootState) => state.staticPage.contactUsReducer.requests;
const data = (state: RootState) => state.staticPage.contactUsReducer.data;

export const extractContactUsData = state => data(state).contactUsResponse;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractSubmitContactUsFormLoading = state => reqs(state).submitContactUsFormRequest.loading;
export const extractSubmitContactUsFormError = state => reqs(state).submitContactUsFormRequest.error;
