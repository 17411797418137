import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { loadPayoutsHistoryRoutine, setCurrentPageRoutine } from '@screens/AdminDashboard/Payouts/routines';
import { IPayoutDto } from '@screens/AdminDashboard/Payouts/models/PayoutDto';
import { IPageableResult } from '@models/domain/PageableResult';
import { getInitialPageableState } from '@models/domain/PageableReducerState';

export const payoutsHistoryReducer = createReducer(getInitialPageableState<IPayoutDto>(), {
  [loadPayoutsHistoryRoutine.SUCCESS]: (state, { payload }: PayloadAction<IPageableResult<IPayoutDto>>) => {
    state.items = payload.items;
    state.totalPages = payload.totalPages;
    state.totalResults = payload.totalResults;
  },
  [setCurrentPageRoutine.FULFILL]: (state, { payload }) => {
    state.page = payload;
  }
});
