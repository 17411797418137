import React from 'react';
import { useHistory } from 'react-router-dom';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import styles from '../styles.module.scss';

const WarehouseLogisticsOfficeSpaceManagement: React.FC = () => {
  const history = useHistory();
  return (
    <div className={styles.wrapper}>
      <SubHeader1>Warehouse/Logistics and Office Space Management Experts</SubHeader1>
      <BodyText2 marginTop>
        So you are an expert in warehouse management and logistics, and have a desire to share your skills by
        helping others manage their idle warehouse spaces.
      </BodyText2>
      <BodyText2 marginTop>
        Or...
      </BodyText2>
      <BodyText2 marginTop>
        You have expertise in office space allocation and management, and have the bandwith to help others manage
        their idle office spaces.
      </BodyText2>
      <BodyText2 marginTop>
        If you have the skills, talent, and desire to help others, apply to join our Shared Spaces Managed
        Solutions Partners network, and get ready to work!
      </BodyText2>
      <div className={styles.buttonWrapper}>
        <PrimaryButton
          onClick={() => history.push(ENDPOINTS.EXPERT_PAGE)}
        >
          I’M AN EXPERT!
        </PrimaryButton>
      </div>
    </div>
  );
};

export default WarehouseLogisticsOfficeSpaceManagement;
