import React, { useState } from 'react';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import BuildingOnMaps from '@components/BuildingOnMaps';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import styles from './styles.module.scss';

export interface IBuildingAddressProps {
  building: IBuildingForDisplaying;
  hideMap?: boolean;
}

const BuildingAddress: React.FC<IBuildingAddressProps> = (
  { building, hideMap }
) => {
  const [showMap, setShowMap] = useState(false);

  return (
    <div className={styles.container}>
      <SubHeader3 disableTranslation className={styles.address}>{building?.location?.address}</SubHeader3>
      <BuildingOnMaps showMap={showMap} setShowMap={setShowMap} building={building} />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      { !!building?.location?.verified && !hideMap && (
        <SmallLinkButton
          to=""
          onClick={() => setShowMap(true)}
          className={styles.link}
          right
        >
          Open in Google Maps
        </SmallLinkButton>
      ) }
    </div>
  );
};

export default BuildingAddress;
