import React from 'react';
import SideMenu from '@containers/SideMenu';
import { ADMIN_DASHBOARD_ENDPOINTS } from '@screens/AdminDashboard/Root/components/Routing/endpoints';
import { IBindingAction } from '@models/Callbacks';

export interface IAdminSideMenuProps {
  className: string;
  linkClassName?: string;
  activeLinkClassName?: string;
  onItemClick?: IBindingAction;
}

const AdminSideMenu: React.FC<IAdminSideMenuProps> = (
  { className, linkClassName, activeLinkClassName, onItemClick }
) => {
  const {
    DASHBOARD, MEMBERS, BUILDINGS_AND_SPACES, TRANSACTIONS, MESSAGES, PAYOUTS
  } = ADMIN_DASHBOARD_ENDPOINTS;

  return (
    <SideMenu
      personalized
      linkClassName={linkClassName}
      activeLinkClassName={activeLinkClassName}
      className={className}
      onItemClick={onItemClick}
      tabs={[
        { title: 'Dashboard Overview', header: true, path: DASHBOARD },
        { title: 'Members', path: MEMBERS },
        { title: 'Message Center', path: MESSAGES },
        { title: 'Buildings/Spaces', path: BUILDINGS_AND_SPACES },
        { title: 'Transactions', path: TRANSACTIONS },
        { title: 'Payouts', path: PAYOUTS }
      ]}
    />
  );
};

export default AdminSideMenu;
