import { all, call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { Routine } from 'redux-saga-routines';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  createBuildingRoutine,
  fetchAllTagsRoutine,
  fetchBuildingsSummaryRoutine
} from '@screens/AdminDashboard/BuildingsAndSpaces/routines';
import buildingsService from '@services/buildings.service';
import { IBuildingForSaveWithHaveId } from '@screens/BuildingEditor/model/BuildingForSave';
import { history } from '@helpers/history.helper';
import tagsService from '@screens/BuildingEditor/services/tags.service';
import { ADMIN_DASHBOARD_ENDPOINTS } from '@screens/AdminDashboard/Root/components/Routing/endpoints';

export const tryFetchBuildingsSummary = (routine: Routine) => function* fetchMBuildingsSummary({ payload }: Routine<any>) {
  try {
    const resp = yield call(buildingsService.fetchAdminBuildingsSummary, payload);
    yield put(routine.success(resp));
  } catch (e) {
    toastr.error('Failed to fetch buildings', e?.message);
    yield put(routine.failure(e?.message));
  }
};

function* tryCreateBuilding({ payload }: PayloadAction<IBuildingForSaveWithHaveId>) {
  try {
    const respId = yield call(buildingsService.createBuildingForHave, payload);
    yield put(createBuildingRoutine.success());
    toastr.success('Success', 'The building has been created!');
    history.push(ADMIN_DASHBOARD_ENDPOINTS.BUILDING_EDITOR(respId));
  } catch (e) {
    toastr.error('Unable to create the building', e?.message);
    yield put(createBuildingRoutine.failure(e?.message));
  }
}

function* tryFetchAllTags() {
  try {
    const resp = yield call(tagsService.fetchAllTags);
    yield put(fetchAllTagsRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load tags', e?.message);
    yield put(fetchAllTagsRoutine.failure(e?.message));
  }
}

export default function* buildingsAndSpacesPageSagas() {
  yield all([
    yield takeEvery(fetchBuildingsSummaryRoutine.TRIGGER, tryFetchBuildingsSummary(fetchBuildingsSummaryRoutine)),
    yield takeEvery(createBuildingRoutine.TRIGGER, tryCreateBuilding),
    yield takeEvery(fetchAllTagsRoutine.TRIGGER, tryFetchAllTags)
  ]);
}
