import { callApi } from '@helpers/api.helper';
import { ISpaceUpdateDto } from '@screens/SpaceEditor/models/ISpaceUpdateDto';
import { ISpaceCreateDto } from '@screens/SpaceEditor/models/ISpaceCreateDto';
import { IChangeSpaceBlocked } from '@screens/SpaceEditor/models/IChangeSpaceBlocked';
import { IBulkCreateSpacesDto } from '@screens/SpaceEditor/models/IBulkCreateSpacesDto';

const spacesService = {
  fetchSpacesBySpaceTemplate: (spaceTemplateId: string) => callApi({
    method: 'GET',
    endpoint: `spaces/space_template/${spaceTemplateId}`
  }),
  updateSpace: (requestData: ISpaceUpdateDto) => callApi({
    method: 'PUT',
    endpoint: 'spaces',
    requestData
  }),
  createSpace: (requestData: ISpaceCreateDto) => callApi({
    method: 'POST',
    endpoint: 'spaces',
    requestData
  }),
  bulkCreateSpaces: (requestData: IBulkCreateSpacesDto) => callApi({
    method: 'POST',
    endpoint: 'spaces/bulk',
    requestData
  }),
  changeSpaceBlocked: (requestData: IChangeSpaceBlocked) => callApi({
    method: 'PUT',
    endpoint: 'spaces/change_blocked',
    requestData
  })
};

export default spacesService;
