import React from 'react';
import { connect } from 'react-redux';
import { IGetInTouchData } from '@screens/static/GetInTouch/model/IGetInTouchData';
import { IBindingCallback1 } from '@models/Callbacks';
import {
  extractSubmitContactUsFormLoading,
  extractSubmitContactUsFormError
} from '@screens/static/GetInTouch/reducers';
import {
  submitContactUsRoutine
} from '@screens/static/routines';
import StaticPageWrapper from '@components/NewDesign/StaticPageWrapper';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import GetInTouchForm from '@screens/static/GetInTouch/components/GetInTouchForm';
import MetaTags from '@components/MetaTags';

export interface IContactUsFromProps {
  saveChanges: IBindingCallback1<IGetInTouchData>;
  contactUsLoading?: boolean;
}

const GetInTouchPage: React.FC<IContactUsFromProps> = (
  { contactUsLoading, saveChanges }
) => (
  <StaticPageWrapper title="Get in Touch">
    <MetaTags
      title="Get in Touch"
      withSuffix
      description="Drop us a line, we’d love to hear from you."
    />
    <BodyText1 center>Drop us a line, we’d love to hear from you.</BodyText1>
    <GetInTouchForm
      saveChanges={saveChanges}
      contactUsLoading={contactUsLoading}
    />
  </StaticPageWrapper>
);

const mapStateToProps = state => ({
  contactUsLoading: extractSubmitContactUsFormLoading(state),
  contactUsError: extractSubmitContactUsFormError(state)
});

const mapDispatchToProps = {
  saveChanges: submitContactUsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(GetInTouchPage);
