import React from 'react';
import SideMenu from '@containers/SideMenu';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import { IBindingAction } from '@models/Callbacks';

export interface IHavesSideMenuProps {
  className: string;
  linkClassName?: string;
  activeLinkClassName?: string;
  onItemClick?: IBindingAction;
}

const HavesSideMenu: React.FC<IHavesSideMenuProps> = (
  { className, linkClassName, activeLinkClassName, onItemClick }
) => {
  const {
    DASHBOARD, ACCOUNT, TRANSACTIONS, BUILDINGS, ADDITIONAL_SERVICES,
    SCHEDULE, FAVORITES, FINANCIAL, MESSAGES, ORDERS
  } = HAVES_DASHBOARD_ENDPOINTS;

  return (
    <SideMenu
      personalized
      linkClassName={linkClassName}
      activeLinkClassName={activeLinkClassName}
      className={className}
      onItemClick={onItemClick}
      tabs={[
        { title: 'Dashboard Overview', header: true, path: DASHBOARD },
        { title: 'Account', path: ACCOUNT },
        { title: 'Message Center', path: MESSAGES },
        { title: 'Favorites', path: FAVORITES },
        { title: 'Buildings', path: BUILDINGS },
        { title: 'Additional Services', path: ADDITIONAL_SERVICES },
        { title: 'Orders', path: ORDERS },
        { title: 'Bookings & Scheduling', path: SCHEDULE },
        { title: 'Transactions', path: TRANSACTIONS },
        { title: 'Financial/Banking', path: FINANCIAL }
      ]}
    />
  );
};

export default HavesSideMenu;
