import React from 'react';
import classNames from 'classnames';
import noDataImage from '@images/no_data.png';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import styles from './styles.module.scss';

export interface INoDataContainerProps {
  message: string;
  className?: any;
}

const NoDataContainer: React.FC<INoDataContainerProps> = (
  { message, className }
) => (
  <div className={classNames(styles.basicContainer, className || styles.defaultStyles)}>
    <img src={noDataImage} alt="No data" />
    <SubHeader1 className={styles.text}>
      {message}
    </SubHeader1>
  </div>
);

export default NoDataContainer;
