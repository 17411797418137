import React from 'react';
import { connect } from 'react-redux';
import { PageLayout } from '@screens/BrowseSpaces/model/PageLayout';
import ResultsList from '@screens/BrowseSpaces/components/layouts/ResultsList';
import ResultsGrid from '@screens/BrowseSpaces/components/layouts/ResultsGrid';
import { IResultBuildingItem } from '@screens/BrowseSpaces/model/ResultItem';
import { IBindingCallback1 } from '@models/Callbacks';
import Pagination from '@root/components/Pagination';
import { IPageable } from '@models/domain/PageableReducerState';
import { NoResultsFound } from '@screens/BrowseSpaces/components/NoResultsFound';
import { extractIsUserLoggedIn, extractUserId } from '@screens/Authorization/reducers';
import { IQueryData } from '@screens/BrowseSpaces/model/QueryData';
import { submitHelpWithSearchRoutine, submitUnauthorizedHelpWithSearchRoutine } from '@screens/BrowseSpaces/routines';
import { IHelpSearchRequest, IUnauthorizedHelpSearchRequest } from '@screens/BrowseSpaces/model/HelpSearchRequest';
import styles from './styles.module.scss';

export interface ISearchResultsProps extends IPageable {
  layout: PageLayout;
  results: IResultBuildingItem[];
  loading: boolean;
  setPage: IBindingCallback1<number>;
  toggleFavorite: IBindingCallback1<string>;
  loggedIn: boolean;
  currentUserId: string;
  searchQuery: IQueryData;
  submitHelpWithSearch: IBindingCallback1<IHelpSearchRequest>;
  submitUnauthorizedHelpWithSearch: IBindingCallback1<IUnauthorizedHelpSearchRequest>;
}

const SearchResults: React.FC<ISearchResultsProps> = (
  { layout, totalPages, page, results,
    loading, setPage, toggleFavorite, loggedIn, searchQuery,
    currentUserId, submitHelpWithSearch, submitUnauthorizedHelpWithSearch }
) => {
  const handleNoResults = () => {
    submitHelpWithSearch({
      userId: currentUserId,
      query: searchQuery
    });
  };

  const handleUnauthorizedNoResults = (firstName: string, lastName: string, email: string) => {
    submitUnauthorizedHelpWithSearch({
      email,
      firstName,
      lastName,
      query: searchQuery
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.results}>
        {totalPages === 0 ? (
          <NoResultsFound
            onSubmit={handleNoResults}
            onUnauthorizedSubmit={handleUnauthorizedNoResults}
            loggedIn={loggedIn}
          />
        ) : (
          <>
            {layout === PageLayout.LIST && (
              <ResultsList
                results={results}
                loading={loading}
                toggleLike={toggleFavorite}
              />
            )}
            {layout === PageLayout.GRID && (
              <ResultsGrid
                results={results}
                loading={loading}
                toggleLike={toggleFavorite}
              />
            )}
          </>
        )}
      </div>
      {totalPages !== 0 && !loading && (
        <Pagination
          totalPages={totalPages}
          currentPage={page}
          setPage={setPage}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  loggedIn: extractIsUserLoggedIn(state),
  currentUserId: extractUserId(state)
});

const mapDispatchToProps = {
  submitHelpWithSearch: submitHelpWithSearchRoutine,
  submitUnauthorizedHelpWithSearch: submitUnauthorizedHelpWithSearchRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
