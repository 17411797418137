import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import { toastr } from 'react-redux-toastr';
import orderPaymentService from '@screens/ServiceOrderCheckout/services/order.payment.service';
import {
  fetchOrderPaymentRequirementsRoutine
} from '@screens/ServiceOrderCheckout/routines';

function* tryFetchOrderPaymentRequirements({ payload }: Routine<any>) {
  try {
    const resp = yield call(orderPaymentService.fetchPaymentRequirements, payload);
    yield put(fetchOrderPaymentRequirementsRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load your payment information', e?.message);
    yield put(fetchOrderPaymentRequirementsRoutine.failure(e?.message));
  }
}

export default function* orderSubmissionAndSummaryStepSagas() {
  yield all([
    yield takeEvery(fetchOrderPaymentRequirementsRoutine.TRIGGER, tryFetchOrderPaymentRequirements)
  ]);
}
