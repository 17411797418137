import { all, call, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { PayloadAction } from '@reduxjs/toolkit';
import { IBookingCheckoutData } from '@screens/BookingCheckout/model/BookingCheckout';
import bookingDetailsService from '@screens/BookingCheckout/services/booking.details.service';
import {
  createAdditionalServicesOrderRoutine, loadAdditionalServicesWithAvailabilityRoutine,
  loadBookingDetailsRoutine
} from '@screens/BookingCheckout/routines';
import additionalServicesOrderService from '@screens/BookingCheckout/services/additional.services.order.service';
import { IMakeOrderRequest } from '@models/domain/additional_service/MakeOrderRequest';
import { ILoadAdditionalServiceWithAvailabilityRequest } from '@models/domain/additional_service/LoadAdditionalServiceWithAvailabilityRequest';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';
import { history } from '@helpers/history.helper';
import { Routine } from 'redux-saga-routines';

function* tryLoadBookingDetails({ payload }: PayloadAction<string>) {
  try {
    const resp: IBookingCheckoutData = yield call(bookingDetailsService.fetchBookingDetails, payload);
    yield put(loadBookingDetailsRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load booking information', e?.message);
    yield put(loadBookingDetailsRoutine.failure(e?.message));
  }
}

export const tryLoadAdditionalServicesWithAvailability = (routine: Routine) => function* loadAdditionalServicesWithAvailability(
  { payload }: PayloadAction<ILoadAdditionalServiceWithAvailabilityRequest>
) {
  try {
    const resp: IAdditionalServiceWithAvailability[] = yield call(
      additionalServicesOrderService.fetchServicesWithAvailability,
      payload
    );
    yield put(routine.success(resp));
  } catch (e) {
    toastr.error('Unable to load additional services with availability', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const tryCreateAdditionalServicesOrder = (routine: Routine) => function* createAdditionalServicesOrder({ payload }: PayloadAction<IMakeOrderRequest>) {
  try {
    const response = yield call(additionalServicesOrderService.createAdditionalServicesOrder, payload);
    yield put(routine.success(response));
    const { location: { pathname, search } } = history;
    history.push(`/service_order/${response.id}`, { prevPath: `${pathname}`, search });
  } catch (e) {
    toastr.error('Failed to create additional services order', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export default function* bookingRootPageSagas() {
  yield all([
    yield takeEvery(loadBookingDetailsRoutine.TRIGGER, tryLoadBookingDetails),
    yield takeEvery(
      createAdditionalServicesOrderRoutine.TRIGGER,
      tryCreateAdditionalServicesOrder(createAdditionalServicesOrderRoutine)
    ),
    yield takeEvery(
      loadAdditionalServicesWithAvailabilityRoutine.TRIGGER,
      tryLoadAdditionalServicesWithAvailability(loadAdditionalServicesWithAvailabilityRoutine)
    )
  ]);
}
