import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { loadServiceOrderDetailsRoutine } from '@screens/ServiceOrderCheckout/routines';
import {
  extractLoadServiceOrderDetailsLoading,
  extractServiceOrderDetails
} from '@screens/ServiceOrderCheckout/reducers';
import { IServiceOrderCheckoutData } from '@screens/ServiceOrderCheckout/model/ServiceOrderCheckout';
import MetaTags from '@components/MetaTags';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';
import OrderedServicesSummary
  from '@screens/ServiceOrderCheckout/components/ServiceOrderDetails/OrderedServicesSummary';
import TransactionsBlock from '@screens/ServiceOrderCheckout/containers/ServiceOrderDetailsPage/TransactionsBlock';
import BuildingDetails from '@containers/Schedules/BookingDetailsModal/BuildingDetails';
import { OrderDetails } from '@screens/NeedsDashboard/Orders/components/OrderDetails';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import { Slider } from '@components/NewDesign/Slider';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import DividingLine from '@components/NewDesign/DividingLine';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import styles from './styles.module.scss';

interface IState {
  orderDetails: IServiceOrderCheckoutData;
  isOrderLoading: boolean;
}

const serviceDetailsLabel = orderDetails => {
  const { services } = orderDetails;
  return services.length > 1
    ? `${services.length} services ordered`
    : services[0].alias;
};

const OrderDetailsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: orderId } = useParams<{ id: string }>();
  const [isBuildingActive, setIsBuildingActive] = useState<boolean>(false);

  const { orderDetails, isOrderLoading }: IState = useSelector(state => ({
    orderDetails: extractServiceOrderDetails(state),
    isOrderLoading: extractLoadServiceOrderDetailsLoading(state)
  }));

  const { serviceOrder, building, booking } = orderDetails ?? {};

  useEffect(() => {
    if (orderId) {
      dispatch(loadServiceOrderDetailsRoutine.trigger(orderId));
    }
  }, [orderId, dispatch]);

  const handleOpenBuilding = useCallback(() => {
    setIsBuildingActive(prev => !prev);
  }, []);

  if (isOrderLoading || !serviceOrder?.order?.id) {
    return <Loader active inline="centered" />;
  }

  const title = `${building.buildingName} — ${serviceDetailsLabel(serviceOrder)}`;

  return (
    <>
      <MetaTags
        title={`Order Details | ${building?.buildingName}`}
        withSuffix
        description={building?.description}
      />
      <div className={styles.container}>
        <div className={styles.container__top_buttons}>
          <SmallLinkButton
            to=""
            onClick={() => history.goBack()}
            left
          >
            Back to My Additional Services
          </SmallLinkButton>
        </div>
        <Header3 className={styles.header}>{title}</Header3>
        <OrderDetails order={serviceOrder.order} />
        <OrderedServicesSummary
          services={serviceOrder.services}
          order={serviceOrder.order}
          showCompletedIndicator
          containerClassName={styles.services_summary__container}
          serviceSummaryClassName={styles.services_summary__summary_row}
        />
        <div className={styles.transactions_section__header}>
          <SubHeader3 disableWordBreak>Transactions</SubHeader3>
          <GoToButton
            to={`${NEEDS_DASHBOARD_ENDPOINTS.SPACES}?building=${orderDetails.building.id}&tab=services&booking=${booking.id}#booking-section`}
            marginZero
          >
            Need to order additional services at this building?
          </GoToButton>
        </div>
        <TransactionsBlock
          orderId={serviceOrder.order.id}
          hideTableHeader
          className={styles.transactions_section__table}
        />
        <DividingLine className={styles.dividing_line} />
        <Slider
          titleComponent={(
            <SubHeader1
              disableWordBreak
              className={styles.building_slider__title}
            >
            Building Details
            </SubHeader1>
          )}
          contentClassName={styles.building_slider__content}
          headerClassName={styles.building_slider__header}
          active={isBuildingActive}
          onClick={handleOpenBuilding}
        >
          <BuildingDetails
            building={building}
            showDividingLine={false}
            hideHeader
          />
        </Slider>
      </div>
    </>
  );
};

export { OrderDetailsPage };
