import React, { useCallback, useEffect, useState } from 'react';
import { Label, Loader, Modal, ModalContent } from 'semantic-ui-react';
import { connect } from 'react-redux';
import FinancialBankingForm from '@screens/HavesDashboard/FinancialBanking/components/FinancialBankingForm';
import {
  loadHaveAccountsListDataRoutine,
  removeHaveAccountDataRoutine,
  setAddingBankAccountRoutine, setVerifyingBankAccountRoutine,
  addBankingDataRoutine, verifyBankAccountRoutine, setDefaultAccountDataRoutine
} from '@screens/HavesDashboard/FinancialBanking/routines';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import {
  extractHaveAccountsListData,
  extractLoadHaveAccountsListDataLoading,
  extractRemoveHaveAccountDataLoading,
  extractAddBankingDataLoading,
  extractAddingBankAccount,
  extractVerifyBankAccountLoading,
  extractVerifyingBankAccount,
  extractSetDefaultAccountDataLoading, extractProcessingBankAccountId
} from '@screens/HavesDashboard/FinancialBanking/reducers';
import { useStripe } from '@stripe/react-stripe-js';
import { IStripeBankingDataPayload } from '@screens/HavesDashboard/FinancialBanking/model/StripeBankingDataPayload';
import { IBankAccount } from '@screens/HavesDashboard/FinancialBanking/model/IBankAccount';
import BankAccountData from '@screens/HavesDashboard/FinancialBanking/components/BankAccountData';
import { IBankAccountVerification } from '@screens/HavesDashboard/FinancialBanking/model/IBankAccountVerification';
import ConfirmationModal from '@components/ConfirmationModal';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import DangerButton from '@components/NewDesign/Button/DangerButton';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import TilesWrapper from '@components/TilesWrapper';
import NoDataContainer from '@components/NoDataContainer';
import TransparentButton from '@components/NewDesign/Button/TransparentButton';
import styles from './styles.module.scss';

export interface IFinancialBankingProps {
  loadAccountsListData: IBindingAction;
  setDefaultAccountData: IBindingCallback1<string>;
  removeAccountData: IBindingCallback1<string>;
  setDefaultAccountDataLoading: boolean;
  removeAccountDataLoading: boolean;
  addBankingData: IBindingCallback1<IStripeBankingDataPayload>;
  accountsListData?: IBankAccount[];
  bankingDataSaving: boolean;
  addingBankAccount?: boolean;
  setAddingBankAccount: IBindingCallback1<boolean>;
  verifyingBankAccount?: boolean;
  setVerifyingBankAccount: IBindingCallback1<boolean>;
  loadAccountsListDataLoading: boolean;
  verifyBankAccountLoading: boolean;
  verifyBankAccount: IBindingCallback1<IBankAccountVerification>;
  processingId?: string;
}

const FinancialBanking: React.FC<IFinancialBankingProps> = (
  { loadAccountsListData, accountsListData, loadAccountsListDataLoading,
    addBankingData, bankingDataSaving, setDefaultAccountData,
    removeAccountData, setAddingBankAccount, addingBankAccount,
    processingId
  }
) => {
  const stripe = useStripe();
  const [deleteModalId, setDeleteModalId] = useState<string | undefined>();

  useEffect(() => {
    loadAccountsListData();
  }, [loadAccountsListData]);

  const handleBankingDetailsUpdate = useCallback(details => {
    addBankingData({ details, stripe });
  }, [stripe, addBankingData]);

  return (
    <DashboardPageWrapper
      title="My Banking Info"
      rightElement={(
        <PrimaryButton
          content="Add New"
          onClick={() => setAddingBankAccount(true)}
          icon="plus"
          labelPosition="left"
        />
      )}
    >
      <DashboardSectionWrapper>
        <SubHeader3 marginBottom>
          This is your Banking Information,
          where you will receive your monthly payments for hosting your spaces
        </SubHeader3>
        <BodyText2>
          You will need to get Bank Routing and Account Number,
          so you can enter it all here.
          This information is usually found at the bottom of your checks.
        </BodyText2>
        {loadAccountsListDataLoading || !accountsListData
          ? <Loader active inline className={styles.loader} />
          : (
            <div className={styles.tilesContainer}>
              <TilesWrapper defaultBorder>
                {accountsListData.map(account => (
                  <div key={account.id} className={styles.accountWrapper}>
                    {account.defaultForCurrency && (
                      <Label corner="right" color="orange" icon="star" title="Default Bank Account" />
                    )}
                    <BankAccountData accountData={account} />
                    <div className={styles.buttons}>
                      <DangerButton
                        content="Remove"
                        onClick={() => setDeleteModalId(account.id)}
                        loading={processingId === account.id}
                        disabled={account.defaultForCurrency || !!processingId}
                      />
                      {!account.defaultForCurrency && (
                        <TransparentButton
                          content="Make Default"
                          onClick={() => setDefaultAccountData(account.id)}
                          loading={processingId === account.id}
                          disabled={!!processingId}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </TilesWrapper>
              {accountsListData.length
                ? (
                  <div className={styles.go_to_container}>
                    <GoToButton to={HAVES_DASHBOARD_ENDPOINTS.LIST_YOUR_SPACE}>List Your Space</GoToButton>
                  </div>
                )
                : (
                  <NoDataContainer message="No information provided" className={styles.bankAccountData} />
                )}
            </div>
          )}
        {addingBankAccount && (
          <Modal open closeIcon onClose={() => setAddingBankAccount(false)} size="small">
            <ModalContent>
              <FinancialBankingForm
                saveChanges={handleBankingDetailsUpdate}
                savingLoading={bankingDataSaving}
                className={styles.form}
              />
            </ModalContent>
          </Modal>
        )}
        <ConfirmationModal
          text="Are sure that you want to remove bank account data?"
          confirmText="Remove"
          cancelText="Cancel"
          open={!!deleteModalId}
          onClose={() => setDeleteModalId(undefined)}
          onConfirm={() => removeAccountData(deleteModalId)}
        />
      </DashboardSectionWrapper>
    </DashboardPageWrapper>
  );
};

const mapStateToProps = state => ({
  accountsListData: extractHaveAccountsListData(state),
  setDefaultAccountDataLoading: extractSetDefaultAccountDataLoading(state),
  removeAccountDataLoading: extractRemoveHaveAccountDataLoading(state),
  bankingDataSaving: extractAddBankingDataLoading(state),
  addingBankAccount: extractAddingBankAccount(state),
  loadAccountsListDataLoading: extractLoadHaveAccountsListDataLoading(state),
  verifyingBankAccount: extractVerifyingBankAccount(state),
  verifyBankAccountLoading: extractVerifyBankAccountLoading(state),
  processingId: extractProcessingBankAccountId(state)
});

const mapDispatchToProps = {
  loadAccountsListData: loadHaveAccountsListDataRoutine,
  setDefaultAccountData: setDefaultAccountDataRoutine,
  removeAccountData: removeHaveAccountDataRoutine,
  addBankingData: addBankingDataRoutine,
  verifyBankAccount: verifyBankAccountRoutine,
  setAddingBankAccount: setAddingBankAccountRoutine.fulfill,
  setVerifyingBankAccount: setVerifyingBankAccountRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialBanking);
