import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import moment from 'moment';
import classNames from 'classnames';
import { createStripePaymentAtCheckoutRoutine, fetchPaymentRequirementsRoutine } from '@screens/BookingCheckout/routines';
import { IBindingCallback1 } from '@models/Callbacks';
import {
  extractBookingData,
  extractBuildingData,
  extractCreateStripePaymentAtCheckoutError,
  extractCreateStripePaymentAtCheckoutLoading,
  extractFetchPaymentRequirementsLoading,
  extractPaymentRequirements
} from '@screens/BookingCheckout/reducers';
import { IPaymentRequirementResponse } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import { IPaymentMethodWithTitle, IPaymentRequestWithStripe } from '@components/StripePaymentForm';
import { useStripe } from '@stripe/react-stripe-js';
import { IBookingWithSpaces } from '@screens/BookingCheckout/model/BookingCheckout';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import BookingInfoTitleWithNote from '@screens/BookingCheckout/components/BookingDetails/BookingInfoTitleWithNote';
import { fullCostToMonthlyPrice } from '@helpers/price.helper';
import { SpaceType, spaceTypeKey, spaceTypeToAmountLabel } from '@models/domain/space/SpaceTypes';
import BookingSummaryGrandTotal from '@screens/BookingCheckout/components/BookingDetails/BookingSummaryGrandTotal';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import { TransactionType } from '@models/domain/transaction/TransactionType';
import styles from './styles.module.scss';

export interface ISubmissionAndSummaryStepProps extends IState, IActions, ISubmissionAndSummaryStepGeneralProps {
  bookingId: string;
}

export interface ISubmissionAndSummaryStepGeneralProps {
  paymentMethod?: IPaymentMethodWithTitle;
}

interface IState {
  building: IBuildingForDisplaying;
  bookingWithSpaces: IBookingWithSpaces;
  paymentRequirementsLoading: boolean;
  paymentRequirements: IPaymentRequirementResponse;
  stripePaymentLoading: boolean;
  stripePaymentError?: string;
}

interface IActions {
  fetchPaymentRequirements: IBindingCallback1<string>;
  createStripePayment: IBindingCallback1<IPaymentRequestWithStripe>;
}

const SubmissionAndSummaryStep: React.FC<ISubmissionAndSummaryStepProps> = (
  {
    bookingId, fetchPaymentRequirements, paymentRequirements,
    createStripePayment, stripePaymentLoading,
    paymentRequirementsLoading, paymentMethod, bookingWithSpaces,
    building
  }
) => {
  useEffect(() => {
    fetchPaymentRequirements(bookingId);
  }, [fetchPaymentRequirements, bookingId]);

  const stripe = useStripe();

  const spacesToTypeAmount = useCallback(
    (type: SpaceType) => bookingWithSpaces.spaces
      .filter(space => space.spaceType === spaceTypeKey(type))
      .map(space => space.amountBooked)
      .reduce((a, b) => a + b, 0),
    [bookingWithSpaces]
  );

  const officeAmount = spacesToTypeAmount(SpaceType.OFFICE);
  const warehouseAmount = spacesToTypeAmount(SpaceType.WAREHOUSE);
  const parkingAmount = spacesToTypeAmount(SpaceType.PARKING);
  const virtualAddressAmount = spacesToTypeAmount(SpaceType.VIRTUAL_ADDRESS);

  return (
    <div className={classNames('content_wrapper', styles.container)}>
      <Form
        loading={paymentRequirementsLoading}
        onSubmit={() => createStripePayment({
          transactionId: paymentRequirements.transactionId,
          paymentMethodId: paymentMethod.id,
          transactionType: TransactionType.BOOKING,
          stripe
        })}
      >
        <div>
          <BodyText2 className={styles.startLabel}>
            FROM
            {' '}
            {moment(bookingWithSpaces.booking.startingDate).format('MMMM Do, YYYY')}
          </BodyText2>
          {bookingWithSpaces.booking.endingDate && (
            <BodyText2 className={styles.startLabel}>
              TO
              {' '}
              {moment(bookingWithSpaces.booking.endingDate).format('MMMM Do, YYYY')}
            </BodyText2>
          )}
        </div>
        <BookingInfoTitleWithNote
          note={`Located at ${building?.location?.address}`}
        >
          {warehouseAmount > 0 && (
            <>
              {spaceTypeToAmountLabel(warehouseAmount, SpaceType.WAREHOUSE)}
              &nbsp;|
              {' '}
            </>
          )}
          {parkingAmount > 0 && (
            <>
              {spaceTypeToAmountLabel(parkingAmount, SpaceType.PARKING)}
              &nbsp;|
              {' '}
            </>
          )}
          {officeAmount > 0 && (
            <>
              {spaceTypeToAmountLabel(officeAmount, SpaceType.OFFICE)}
              &nbsp;|
              {' '}
            </>
          )}
          {virtualAddressAmount > 0 && (
            <>
              {spaceTypeToAmountLabel(virtualAddressAmount, SpaceType.VIRTUAL_ADDRESS)}
              &nbsp;|
              {' '}
            </>
          )}
          {fullCostToMonthlyPrice(bookingWithSpaces.booking.fullCost)}
          {' '}
          monthly
        </BookingInfoTitleWithNote>
        <BookingInfoTitleWithNote
          note={`Billing starts ${moment(bookingWithSpaces.booking.startingDate).format('MMMM Do')} and recurs on the first of every month.`}
        >
          Paying with
          {' '}
          {paymentMethod?.title}
        </BookingInfoTitleWithNote>
        {paymentRequirements && (
          <BookingSummaryGrandTotal
            todaySum={paymentRequirements.priceSummary.sumToPay}
            feeAmount={paymentRequirements.priceSummary.feeAmount}
          />
        )}
        <div>
          <PrimaryButton
            type="submit"
            content="Finish & Pay"
            loading={stripePaymentLoading}
          />
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps: (state) => IState = state => ({
  building: extractBuildingData(state),
  bookingWithSpaces: extractBookingData(state),
  paymentRequirementsLoading: extractFetchPaymentRequirementsLoading(state),
  paymentRequirements: extractPaymentRequirements(state),
  stripePaymentLoading: extractCreateStripePaymentAtCheckoutLoading(state),
  stripePaymentError: extractCreateStripePaymentAtCheckoutError(state)
});

const mapDispatchToProps: IActions = {
  fetchPaymentRequirements: fetchPaymentRequirementsRoutine,
  createStripePayment: createStripePaymentAtCheckoutRoutine
};

export { SubmissionAndSummaryStep };

export type IPaymentStepState = IState;
export type IPaymentStepActions = IActions;

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionAndSummaryStep);
