import React from 'react';
import styles from './styles.module.scss';
import Header4 from '@components/NewDesign/Typography/Headers/Header4';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import classNames from 'classnames';
import Caption4 from '@components/NewDesign/Typography/Caption/Caption4';

export interface IOurStoryBlockProps {
  title: string;
  paragraphs: string[];
  image: string;
  bgImage: string;
  reflected?: boolean;
  note?: IOurStoryBlockImageNote;
}

export interface IOurStoryBlockImageNote {
  text: string;
  image: string;
}

const OurStoryBlock: React.FC<IOurStoryBlockProps> = (
  { title, paragraphs, image, bgImage,
    reflected, note }
) => {
  const imagePart = (
    <div className={classNames(styles.imagePart, note && styles.imagePartWithNote)}>
      <img src={bgImage} alt="Our Story Block" className={styles.bgImage} />
      <img src={image} alt="Our Story Block" className={styles.image} />
      {note && (
        <div className={styles.note}>
          <Caption4 className={styles.noteText}>{note.text}</Caption4>
          <img src={note.image} alt="Note" className={styles.noteImage} />
        </div>
      )}
    </div>
  );
  const textPart = (
    <div className={styles.textPart}>
      <Header4 className={styles.header}>{title}</Header4>
      <div className={styles.paragraphsWrapper}>
        {paragraphs.map((p, i) => (
          <BodyText2 key={i} className={styles.paragraph}>{p}</BodyText2>
        ))}
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.container, reflected && styles.reflected)}>
      {imagePart}
      <div className={styles.arrow} />
      {textPart}
    </div>
  );
};

export default OurStoryBlock;
