import { connect } from 'react-redux';
import { IBindingCallback1 } from '@models/Callbacks';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import { IMessageDto } from '@models/domain/message/MessageDto';
import {
  extractFetchMessageCenterMessagesLoading,
  extractFullMessage, extractMessages,
  extractSendMessageFromMessageCenterLoading,
  extractWriting
} from '@screens/AdminDashboard/MessageCenter/reducers';
import {
  fetchMessageCenterMessagesRoutine,
  readMessageFromMessageCenterRoutine,
  sendMessageFromMessageCenterRoutine, setMessageCenterMessagesPageRoutine, setMessageFullFromMessageCenterRoutine,
  setWritingMessageFromMessageCenterRoutine
} from '@screens/AdminDashboard/MessageCenter/routines';
import { IPageableReducerState } from '@models/domain/PageableReducerState';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import MessageCenter from '@screens/HavesDashboard/MessageCenter/containers/MessageCenterPage';

export interface IMessageCenterProps extends IState, IActions {
}

interface IState {
  writing: boolean;
  sendMessageLoading: boolean;
  fetchMessagesLoading: boolean;
  messageFull?: IMessageDto;
  messages: IPageableReducerState<IMessageDto>;
}

interface IActions {
  setWriting: IBindingCallback1<boolean>;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  readMessage: IBindingCallback1<string>;
  setMessageFull: IBindingCallback1<IMessageDto | undefined>;
  fetchMessages: IBindingCallback1<IPageRequest>;
  setPage: IBindingCallback1<number>;
}

const mapStateToProps: (state) => IState = state => ({
  messages: extractMessages(state),
  writing: extractWriting(state),
  sendMessageLoading: extractSendMessageFromMessageCenterLoading(state),
  messageFull: extractFullMessage(state),
  fetchMessagesLoading: extractFetchMessageCenterMessagesLoading(state)
});

const mapDispatchToProps: IActions = {
  setWriting: setWritingMessageFromMessageCenterRoutine.fulfill,
  sendMessage: sendMessageFromMessageCenterRoutine,
  setMessageFull: setMessageFullFromMessageCenterRoutine.fulfill,
  readMessage: readMessageFromMessageCenterRoutine,
  fetchMessages: fetchMessageCenterMessagesRoutine,
  setPage: setMessageCenterMessagesPageRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageCenter);
