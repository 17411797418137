import React, { useState } from 'react';
import { CardContent } from 'semantic-ui-react';
import { times } from 'lodash';
import classNames from 'classnames';
import { IBindingCallback1 } from '@models/Callbacks';
import CounterInput from '@components/CouterInput';
import { ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import SpaceItemHeader from '@screens/BuildingDetails/components/SpaceItem/SpaceItemHeader';
import { toPrice } from '@helpers/price.helper';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import InputWrapper from '@components/NewDesign/Input';
import Caption3 from '@components/NewDesign/Typography/Caption/Caption3';
import { ISpaceToAmount } from '../../model/BookingRequest';
import styles from './styles.module.scss';

export interface ISpaceItemProps {
  space: ISpaceWithAvailability;
  amountSelected: number;
  notesRequired?: boolean;
  onClick: IBindingCallback1<ISpaceToAmount>;
  initialAmount?: number;
  initialNotes?: string[];
  showLocalSum?: boolean;
  hideCounterInput?: boolean;
}

const SpaceItem: React.FC<ISpaceItemProps> = (
  { space, amountSelected, notesRequired, onClick, initialAmount,
    initialNotes, showLocalSum, hideCounterInput }
) => {
  const [tempAmountSelected, setAmountSelected] = useState<number>(initialAmount || 0);
  const [spaceNotes, setSpaceNotes] = useState<string[]>(initialNotes || []);

  const handleSpacesNotes = (value: string, index: number) => {
    let newSpaces;
    if (value && (!spaceNotes || !spaceNotes[index])) {
      newSpaces = [...spaceNotes, value];
    } else {
      newSpaces = spaceNotes.map((v, i) => (i === index ? value : v)).filter(x => x);
    }
    setSpaceNotes(newSpaces);
    onClick({
      amount: tempAmountSelected,
      spaceTemplateId: space.id,
      spacesNotes: newSpaces
    });
  };

  const changeAmountAndReflectNotes = (amount: number) => {
    setAmountSelected(amount);

    // clear last note if decrease amount of spaces clicked
    if (notesRequired && tempAmountSelected > amount) {
      handleSpacesNotes(undefined, amount);
    } else {
      onClick({
        amount,
        spaceTemplateId: space.id,
        spacesNotes: spaceNotes
      });
    }
  };

  return (
    <div className={classNames(styles.card, hideCounterInput && styles.hide_counter)}>
      <SpaceItemHeader space={space} />
      { !hideCounterInput && (
        <div className={styles.selection}>
          <CounterInput
            disabled={space.amountAvailable === 0}
            max={space.amountAvailable}
            min={0}
            onChange={changeAmountAndReflectNotes}
            value={tempAmountSelected}
          />
          <Caption1 className={styles.total}>
            {showLocalSum && !!amountSelected && (
              <>
                {toPrice({ amount: space.pricePerMonth * amountSelected, currency: space.currency })}
                {' '}
                Monthly
              </>
            )}
          </Caption1>
        </div>
      )}
      {notesRequired && tempAmountSelected > 0
      && (
        <CardContent extra>
          <div className={styles.notes}>
            {
              times(tempAmountSelected).map(index => (
                <div key={index}>
                  <Caption3 className={styles.space_index}>
                    Space #
                    {index + 1}
                  </Caption3>
                  <InputWrapper
                    defaultValue={spaceNotes[index]}
                    onChange={(event, data) => handleSpacesNotes(data.value, index)}
                    placeholder="Describe your stuff..."
                  />
                </div>
              ))
            }
          </div>
        </CardContent>
      )}
    </div>
  );
};

export default SpaceItem;
