import React, { ReactElement, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from '@root/hooks/use-translation';
import styles from '../NavLinkButton/styles.module.scss';

interface IExternalNavLinkButtonProps {
  to: string;
  children?: ReactElement | string;
  className?: string;
}

const ExternalNavLinkButton: React.FC<IExternalNavLinkButtonProps> = ({
  className,
  children,
  to
}) => {
  const { t } = useTranslation();

  const redirect = useCallback(() => {
    window.location.href = to;
  }, [to]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div className={classNames(styles.container, className)} onClick={redirect}>
      {t(children)}
    </div>
  );
};

export default ExternalNavLinkButton;
