import React, { useState } from 'react';
import CreditCardConfiguration from '@screens/CreditCardConfiguration/containers/CreditCardConfiguration';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';

const CreditCardConfigPage: React.FC = () => {
  const [newCardModalOpen, setNewCardModalOpen] = useState(false);

  const addNewCardButton = (
    <div>
      <PrimaryButton
        icon="plus"
        content="Add new"
        labelPosition="left"
        onClick={() => setNewCardModalOpen(true)}
      />
    </div>
  );

  return (
    <DashboardPageWrapper title="Credit Cards" rightElement={addNewCardButton}>
      <DashboardSectionWrapper>
        <CreditCardConfiguration
          newCardModalOpen={newCardModalOpen}
          setNewCardModalOpen={setNewCardModalOpen}
        />
      </DashboardSectionWrapper>
    </DashboardPageWrapper>
  );
};

export default CreditCardConfigPage;
