import React from 'react';
import styles from './styles.module.scss';

export interface IVideoPlayerProps {
  link: string;
  title: string;
  allowFullScreen?: boolean;
  className?: string;
}

const VideoPlayer: React.FC<IVideoPlayerProps> = (
  { link, title, allowFullScreen, className }
) => (
  <div className={className || styles.basic_player}>
    <iframe
      src={link}
      title={title}
      frameBorder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={allowFullScreen}
    />
  </div>
);

export default VideoPlayer;
