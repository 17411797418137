import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  loadAdditionalServicesBuildingTitlesRoutine,
  loadAdditionalServicesListRoutine, loadOneAdditionalServiceRoutine, saveAdditionalServiceRoutine,
  setSelectedAdditionalServiceRoutine
} from '@screens/HavesDashboard/AdditionalServices/routines';
import { toastr } from 'react-redux-toastr';
import serviceService from '@screens/HavesDashboard/AdditionalServices/services/services.service';
import buildingsService from '@services/buildings.service';
import { IServiceSaveDto } from '@screens/HavesDashboard/AdditionalServices/model/ServiceSaveDto';
import {
  extractAdditionalServicesListPage, extractAdditionalServicesListSize,
  extractSelectedAdditionalService
} from '@screens/HavesDashboard/AdditionalServices/reducers';
import { IServiceDetailsDto } from '@screens/HavesDashboard/AdditionalServices/model/ServiceDetailsDto';
import { ICurrentUser } from '@screens/Authorization/models/CurrentUser';
import { extractCurrentUser } from '@screens/Authorization/reducers';
import { IServicePageRequest } from '@screens/HavesDashboard/AdditionalServices/model/ServicePageRequest';

function* tryLoadAdditionalServicesList({ payload }: PayloadAction<IServicePageRequest>) {
  try {
    const resp = yield call(serviceService.getServicesByUserId, payload);
    yield put(loadAdditionalServicesListRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load services', e?.message);
    yield put(loadAdditionalServicesListRoutine.failure(e?.message));
  }
}

function* tryLoadAdditionalServicesBuildingTitles() {
  try {
    const resp = yield call(buildingsService.fetchBuildingTitles);
    yield put(loadAdditionalServicesBuildingTitlesRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load buildings', e?.message);
    yield put(loadAdditionalServicesBuildingTitlesRoutine.failure(e?.message));
  }
}

function* tryLoadOneAdditionalService({ payload }: PayloadAction<string>) {
  try {
    const resp = yield call(serviceService.getServiceById, payload);
    yield put(setSelectedAdditionalServiceRoutine.fulfill(resp));
    yield put(loadOneAdditionalServiceRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load service', e?.message);
    yield put(loadOneAdditionalServiceRoutine.failure(e?.message));
  }
}

function* trySaveAdditionalService({ payload }: PayloadAction<IServiceSaveDto>) {
  try {
    const selectedService: Partial<IServiceDetailsDto> = yield select(extractSelectedAdditionalService);
    const currentUser: ICurrentUser = yield select(extractCurrentUser);
    const page: number = yield select(extractAdditionalServicesListPage);
    const size: number = yield select(extractAdditionalServicesListSize);
    if (selectedService.id) {
      yield call(serviceService.updateService, selectedService.id, payload);
    } else {
      yield call(serviceService.createService, payload);
    }
    yield put(saveAdditionalServiceRoutine.success());
    yield put(setSelectedAdditionalServiceRoutine.fulfill(undefined));
    yield put(loadAdditionalServicesListRoutine.trigger({ userId: currentUser.id, page, size } as IServicePageRequest));
    toastr.success('Success', 'Service has been successfully saved');
  } catch (e) {
    toastr.error('Unable to save service', e?.message);
    yield put(saveAdditionalServiceRoutine.failure(e?.message));
  }
}

export default function* additionalServicesPageSagas() {
  yield all([
    yield takeEvery(loadAdditionalServicesListRoutine, tryLoadAdditionalServicesList),
    yield takeEvery(loadAdditionalServicesBuildingTitlesRoutine, tryLoadAdditionalServicesBuildingTitles),
    yield takeEvery(loadOneAdditionalServiceRoutine, tryLoadOneAdditionalService),
    yield takeEvery(saveAdditionalServiceRoutine, trySaveAdditionalService)
  ]);
}
