import { all, call, put, takeEvery } from 'redux-saga/effects';
import { submitJoinOurTeamRoutine } from '@screens/static/routines';
import * as staticPageForm from '@screens/static/services/staticPageForm.service';
import { Routine } from 'redux-saga-routines';
import { toastr } from 'react-redux-toastr';
import { history } from '@helpers/history.helper';
import { ENDPOINTS } from '@containers/Routing/endpoints';

export const trySubmitJoinOurTeamForm = (routine: Routine) => function* submitJoinOurTeam({ payload }: Routine<any>) {
  try {
    const resp = yield call(staticPageForm.submitJoinOurTeam, payload);
    toastr.success('Success',
      'Your form has been successfully submitted to Shared Spaces. We will be in touch with you soon.');
    yield put(routine.success(resp));
    history.push(ENDPOINTS.ROOT);
  } catch (e) {
    toastr.error('Failed to submit your details', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export default function* joinOurTeamSaga() {
  yield all([
    yield takeEvery(submitJoinOurTeamRoutine.TRIGGER, trySubmitJoinOurTeamForm(submitJoinOurTeamRoutine))
  ]);
}
