import React from 'react';
import styles from './styles.module.scss';
import Header2 from '@components/NewDesign/Typography/Headers/Header2';
import classNames from 'classnames';

export interface IGeneralPageHeaderProps {
  title: string;
  disableMaxWidth?: boolean;
}

const GeneralPageHeader: React.FC<IGeneralPageHeaderProps> = (
  { title, disableMaxWidth }
) => (
  <Header2
    className={classNames(styles.header, disableMaxWidth && styles.disabledMaxWidth)}
    center
  >
    {title}
  </Header2>
);

export default GeneralPageHeader;
