import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchBuildingDetailsRoutine,
  fetchSpaceAvailabilityRoutine,
  setWritingMessageFromBrowseBuildingDetailsRoutine,
  toggleFavoriteBuildingRoutine,
  redirectWithBookingStateRoutine,
  clearCurrentBookingRoutine, toggleResetSelectedSpacesRoutine,
  loadAdditionalServicesWithAvailabilityRoutine } from '@screens/BuildingDetails/routines';
import {
  IBuildingDetailsResponse,
  IBuildingDetailsWithAvailability, IOwnerShortInfoDto,
  ISpaceWithAvailability
} from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { ICurrentBooking } from '@screens/BuildingDetails/model/ICurrentBooking';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';

export interface IBuildingDetailsReducerState extends IBuildingDetailsWithAvailability {
  writing: boolean;
  owner?: IOwnerShortInfoDto;
  currentBooking: ICurrentBooking;
  isNeedToResetSelectedSpaces: boolean;
  additionalServices?: IAdditionalServiceWithAvailability[];
}

const initialState: IBuildingDetailsReducerState = {
  owner: undefined,
  writing: false,
  building: undefined,
  spaces: [],
  currentBooking: undefined,
  isNeedToResetSelectedSpaces: false
};

export function findAndReplaceThenDispose<T>(arr1: T[], arr2: T[], predicate) {
  let arr2Copy = arr2.slice();
  return arr1.map(oldElem => {
    for (let i = 0; i < arr2Copy.length; i += 1) {
      if (predicate(arr2Copy[i], oldElem)) {
        const deletedElem = arr2Copy[i];
        arr2Copy = arr2Copy.filter(el => el !== deletedElem);
        return deletedElem;
      }
    }
    return oldElem;
  });
}

export const buildingDetailsReducer = createReducer(initialState, {
  // eslint-disable-next-line arrow-body-style
  [fetchBuildingDetailsRoutine.SUCCESS]: (draftState, { payload }: PayloadAction<IBuildingDetailsResponse>) => {
    return {
      ...draftState,
      ...payload.buildingResult,
      owner: payload.owner
    };
  },
  [setWritingMessageFromBrowseBuildingDetailsRoutine.FULFILL]: (draftState, { payload }: PayloadAction<boolean>) => {
    draftState.writing = payload;
  },
  [fetchSpaceAvailabilityRoutine.SUCCESS]: (draftState, { payload }: PayloadAction<ISpaceWithAvailability[]>) => {
    draftState.spaces = findAndReplaceThenDispose(draftState.spaces, payload, (el1, el2) => el1.id === el2.id);
  },
  [toggleFavoriteBuildingRoutine.SUCCESS]: draftState => {
    const draftBuilding = draftState.building;
    draftBuilding.favorite = !draftBuilding.favorite;
  },
  [redirectWithBookingStateRoutine.SUCCESS]: (draftState, { payload }) => {
    draftState.currentBooking = payload;
  },
  [clearCurrentBookingRoutine.FULFILL]: draftState => {
    draftState.currentBooking = undefined;
  },
  [toggleResetSelectedSpacesRoutine.FULFILL]: draftState => {
    draftState.isNeedToResetSelectedSpaces = !draftState.isNeedToResetSelectedSpaces;
  },
  [loadAdditionalServicesWithAvailabilityRoutine.SUCCESS]: (
    state, { payload }: PayloadAction<IAdditionalServiceWithAvailability[]>
  ) => {
    state.additionalServices = payload;
  }
});
