import React, { memo, useCallback, useMemo } from 'react';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import CommonInput, { ICommonInputProps } from '@components/formik/common';
import DropdownWrapper from '@components/NewDesign/Dropdown';
import { IBindingCallback2 } from '@models/Callbacks';

export interface IFormikTagSelectorProps extends ICommonInputProps<HTMLElement, DropdownProps> {
  fieldValue: any;
  setFieldValue: IBindingCallback2<string, any>;
  setFieldTouched: IBindingCallback2<string, boolean>;
}

const FormikTagSelector: React.FC<IFormikTagSelectorProps> = (
  { fieldValue, setFieldTouched, setFieldValue, propsOrFieldName, semanticProps, ...props }
) => {
  const name = useMemo(
    () => (propsOrFieldName as any)?.name || propsOrFieldName,
    [propsOrFieldName]
  );

  const onChange = useCallback(
    (ev, { value }) => setFieldValue(name, value as any),
    [name, setFieldValue]
  );

  const onBlur = useCallback(
    () => setFieldTouched(name, true),
    [name, setFieldTouched]
  );

  return (
    <CommonInput
      {...props}
      semanticProps={{
        ...semanticProps,
        onChange,
        onBlur,
        value: fieldValue,
        search: true,
        multiple: true,
        selection: true
      }}
      propsOrFieldName={propsOrFieldName}
      element={DropdownWrapper}
    />
  );
};

export default memo(FormikTagSelector);
