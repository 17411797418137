import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { ITransactionWithBookingDetails } from '@models/domain/TransactionWithBookingDetails';
import { IBookingWithSpaces } from '@screens/BookingCheckout/model/BookingCheckout';
import { fetchBookingReceiptDataRoutine,
  createBookingReceiptAdditionalServicesOrderRoutine,
  loadBookingReceiptAdditionalServicesWithAvailabilityRoutine
} from '@screens/ReceiptPage/routines';
import { IBindingCallback1 } from '@models/Callbacks';
import {
  extractBookingReceiptAdditionalServicesWithAvailability,
  extractBookingReceiptLoading,
  extractBookingWithSpaces,
  extractBuilding, extractLoadBookingReceiptAdditionalServicesWithAvailabilityLoading,
  extractTransactionWithBookingDetails
} from '@screens/ReceiptPage/reducers';
import Header2 from '@components/NewDesign/Typography/Headers/Header2';
import DividingLine from '@components/NewDesign/DividingLine';
import ScrollToTopOnMount from '@components/ScrollToTop';
import { bookingToPeriods, IBookingPaymentPeriods } from '@screens/BookingCheckout/helpers/bookingPeriods.helper';
import BookingSpacesSummary from '@screens/BookingCheckout/components/BookingDetails/BookingSpacesSummary';
import { toPrice } from '@helpers/price.helper';
import { IPriceWithCurrency } from '@models/domain/Booking';
import { Period } from '@screens/ReceiptPage/model/Period';
import { equalMomentDates, lessThanOrEqualMomentDates } from '@helpers/date.helper';
import {
  extractAdditionalServicesListPage, extractAdditionalServicesListSize
} from '@screens/HavesDashboard/AdditionalServices/reducers';
import AdditionalServicesSection from '@components/NewDesign/AdditionalServicesSection';
import ReceiptSection from '@screens/ReceiptPage/components/ReceiptSection';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';
import { ILoadAdditionalServiceWithAvailabilityRequest } from '@models/domain/additional_service/LoadAdditionalServiceWithAvailabilityRequest';
import { IMakeOrderRequest } from '@models/domain/additional_service/MakeOrderRequest';
import useIsMount from '@root/hooks/use-is-mount';
import styles from './styles.module.scss';

export interface IReceiptPageProps extends IState, IActions {
}

interface IState {
  transaction: ITransactionWithBookingDetails;
  building: IBuildingForDisplaying;
  bookingWithSpaces: IBookingWithSpaces;
  loading: boolean;
  servicesList: IAdditionalServiceWithAvailability[];
  servicesListLoading: boolean;
}

interface IActions {
  fetchReceiptData: IBindingCallback1<string>;
  loadAdditionalServicesList: IBindingCallback1<ILoadAdditionalServiceWithAvailabilityRequest>;
  createAdditionalServicesOrder: IBindingCallback1<IMakeOrderRequest>;
}

const BookingReceiptPage: React.FC<IReceiptPageProps> = (
  {
    transaction, building, bookingWithSpaces,
    fetchReceiptData, loading, servicesList, servicesListLoading,
    loadAdditionalServicesList, createAdditionalServicesOrder
  }
) => {
  const { id } = useParams<{ id: string }>();
  const [periods, setPeriods] = useState<IBookingPaymentPeriods>({});
  const [newPeriods, setNewPeriods] = useState<IBookingPaymentPeriods>({});

  useEffect(
    () => {
      fetchReceiptData(id);
    },
    [fetchReceiptData, id]
  );

  useEffect(
    () => {
      if (bookingWithSpaces) {
        const periodsToSet = bookingToPeriods(bookingWithSpaces.booking);
        setPeriods(periodsToSet);
      }
    }, [bookingWithSpaces]
  );

  const getPeriodToShow = useCallback((): Period | null => {
    const { paidFrom, paidTo } = transaction;
    const { first, second, third } = periods;
    if (first && equalMomentDates(first.start, moment(paidFrom)) && equalMomentDates(first.end, moment(paidTo))) {
      return Period.FIRST;
    }
    if (second && (moment(paidFrom).date() === 1)
      && lessThanOrEqualMomentDates(second, moment(paidFrom))
      && equalMomentDates(moment(paidTo), moment(paidFrom).endOf('month'))) {
      return Period.SECOND;
    }
    if (third && equalMomentDates(third.start, moment(paidFrom)) && equalMomentDates(third.end, moment(paidTo))) {
      return Period.THIRD;
    }
    return null;
  }, [periods, transaction]);

  const getPeriods = useCallback((periodName: Period) => {
    if (periodName === Period.FIRST) {
      return { ...periods, second: undefined, third: undefined };
    }
    if (periodName === Period.SECOND) {
      return { ...periods, first: undefined, third: undefined };
    }
    if (periodName === Period.THIRD) {
      return { ...periods, first: undefined, second: undefined };
    }
    return { first: undefined, second: undefined, third: undefined };
  }, [periods]);

  useEffect(
    () => {
      if (transaction && periods) {
        const periodName = getPeriodToShow();
        const periodsWithCurrentOne = getPeriods(periodName);
        setNewPeriods(periodsWithCurrentOne);
      }
    }, [bookingWithSpaces, getPeriodToShow, getPeriods, periods, transaction]
  );

  const priceToPriceWithCurrency = useCallback(
    (amount: number): IPriceWithCurrency => ({ amount, currency: bookingWithSpaces?.booking.fullCost.currency }),
    [bookingWithSpaces]
  );

  const getSpacesSummaryComponent = () => newPeriods && (
    <BookingSpacesSummary
      spaces={bookingWithSpaces?.spaces || []}
      booking={bookingWithSpaces?.booking}
      periods={newPeriods}
    />
  );

  const isMount = useIsMount();

  return (
    <>
      <ScrollToTopOnMount />
      {isMount || loading || !transaction ? (
        <Loader active inline="centered" />
      ) : (
        <div className={`content_wrapper ${styles.container}`}>
          <Header2 center marginTop marginBottom>Receipt</Header2>
          <DividingLine />
          <div className={styles.content}>
            <ReceiptSection
              transactionId={transaction.id}
              transactionDateTime={transaction.dateTime}
              building={building}
              summaryComponent={getSpacesSummaryComponent}
              notes={bookingWithSpaces.booking.note}
              totalPriceLabel={toPrice(priceToPriceWithCurrency(transaction.price.amount))}
              creditCard={transaction.creditCard}
            />
            <AdditionalServicesSection
              marginTop
              booking={bookingWithSpaces?.booking}
              buildingId={building.id}
              className={styles.servicesWrapper}
              servicesList={servicesList}
              servicesListLoading={servicesListLoading}
              loadAdditionalServicesList={loadAdditionalServicesList}
              createAdditionalServicesOrder={createAdditionalServicesOrder}
            />
          </div>
          <DividingLine />
        </div>
      )}
    </>
  );
};

const mapStateToProps: (state) => IState = state => ({
  transaction: extractTransactionWithBookingDetails(state),
  building: extractBuilding(state),
  bookingWithSpaces: extractBookingWithSpaces(state),
  loading: extractBookingReceiptLoading(state),
  page: extractAdditionalServicesListPage(state),
  size: extractAdditionalServicesListSize(state),
  servicesList: extractBookingReceiptAdditionalServicesWithAvailability(state),
  servicesListLoading: extractLoadBookingReceiptAdditionalServicesWithAvailabilityLoading(state)
});

const mapDispatchToProps: IActions = {
  fetchReceiptData: fetchBookingReceiptDataRoutine,
  loadAdditionalServicesList: loadBookingReceiptAdditionalServicesWithAvailabilityRoutine,
  createAdditionalServicesOrder: createBookingReceiptAdditionalServicesOrderRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingReceiptPage);
