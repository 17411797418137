import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalContent } from 'semantic-ui-react';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import SpaceAmountSelector from '@screens/Landing/components/search/selectors/SpaceAmountSelector';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import ErrorPopup from '@components/FormErrorPopup';
import FormPlaceholder from '@components/NewDesign/Typography/FormAndTable/FormPlaceholder';
import styles from './styles.module.scss';

const INITIAL_SPACES_AMOUNT = 0;
const MAX_AMOUNT = 1000;
const MAX_AMOUNT_ERROR = `You can only add up to ${MAX_AMOUNT} spaces`;

const AddMultipleSpacesModal = ({ open, onClose, onAddSpaces }) => {
  const [amount, setAmount] = useState<number>(INITIAL_SPACES_AMOUNT);
  const [error, setError] = useState<string | null>(null);
  const handleAmountChange = useCallback(value => setAmount(value), []);

  useEffect(() => {
    if (amount > MAX_AMOUNT) {
      setError(MAX_AMOUNT_ERROR);
    } else {
      setError(null);
    }
  }, [amount]);

  const handleAddSpaces = () => {
    onAddSpaces(amount);
    setAmount(INITIAL_SPACES_AMOUNT);
  };

  return (
    <Modal open={open} size="tiny" closeIcon onClose={onClose} style={{ marginTop: '14px' }}>
      <ModalHeaderWrapper>
        Add Multiple Spaces
      </ModalHeaderWrapper>
      <ModalContent>
        <div className={styles.content}>
          <FormPlaceholder>Enter amount of spaces to add:</FormPlaceholder>
          <ErrorPopup
            open={!!error}
            label={error}
            component={(
              <SpaceAmountSelector value={amount} onChange={handleAmountChange} />
            )}
          />
        </div>
        <PrimaryButton
          content="Add"
          onClick={handleAddSpaces}
          disabled={!amount || !!error}
          marginZero
        />
      </ModalContent>
    </Modal>
  );
};

export { AddMultipleSpacesModal };
