import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { extractCurrentUser } from '@screens/Authorization/reducers';
import { Role } from '@screens/Authorization/models/Roles';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import styles from './styles.module.scss';

const Banner: React.FC = () => {
  const currentUser = useSelector(extractCurrentUser);
  if (currentUser?.hasDetails) {
    return null;
  }

  return (
    <div className={`${styles.wrapper} content_wrapper`}>
      <div className={styles.container}>
        <BodyText1 className={classNames(styles.element, styles.bold)}>
          Welcome to your new account!
        </BodyText1>
        &nbsp;
        <BodyText1 className={classNames(styles.element, styles.regular)}>
          {currentUser.roles.includes(Role.HAVE) && (
            <>
              To finish,&nbsp;
              <Link to={HAVES_DASHBOARD_ENDPOINTS.ACCOUNT}>
                fill out your information and add a bank account.
              </Link>
            </>
          )}
          {currentUser.roles.includes(Role.NEED) && (
            <>
              To finish,&nbsp;
              <Link to={NEEDS_DASHBOARD_ENDPOINTS.ACCOUNT}>
                fill out your information and add a credit card.
              </Link>
            </>
          )}
        </BodyText1>
      </div>
    </div>
  );
};

export default Banner;
