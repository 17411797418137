import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IBindingCallback1, IBindingFunction0 } from '@models/Callbacks';
import SimpleInputWrapper from '@components/NewDesign/SimpleInput';
import { extractIsGooglePlacesScriptLoaded } from '@containers/ScriptLoader/reducer';

export interface IGoogleAutocompleteInputProps {
  onPlaceChange: IBindingCallback1<IGooglePlace>;
  selectedAddress?: string;
  clearOnSelect?: boolean;
  placeholder?: string;
  resultType: IGooglePlaceResultType;
}

export interface IGooglePlace {
  address_components: IGooglePlaceAddressComponent[];
  formatted_address: string;
  geometry: IGooglePlaceGeometry;
}

export interface IGooglePlaceAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface IGooglePlaceLocation {
  lat: IBindingFunction0<number>;
  lng: IBindingFunction0<number>;
}

export interface IGooglePlaceGeometry {
  location: IGooglePlaceLocation;
}

export type IGooglePlaceResultType = 'area' | 'address';

const googlePlaceResultTypeToTypeParam = (type: IGooglePlaceResultType) => {
  switch (type) {
    case 'area':
      return '(regions)';
    case 'address':
      return 'address';
    default:
      throw new Error();
  }
};

const GoogleAutocompleteInput: React.FC<IGoogleAutocompleteInputProps> = (
  {
    onPlaceChange, selectedAddress, clearOnSelect,
    placeholder = 'Search...', resultType
  }
) => {
  const googlePlacesScriptLoaded = useSelector(extractIsGooglePlacesScriptLoaded);
  const [query, setQuery] = useState(selectedAddress ?? '');
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    setQuery(selectedAddress ?? '');
  }, [selectedAddress]);

  // Prevents browser's autocomplete from showing
  useLayoutEffect(() => {
    if (!autoCompleteRef.current?.inputRef?.current) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    const observerHack = new MutationObserver(() => {
      observerHack.disconnect();
      if (autoCompleteRef.current?.inputRef?.current) {
        autoCompleteRef.current.inputRef.current.setAttribute('autocomplete', 'none');
      }
    });

    observerHack.observe(autoCompleteRef.current?.inputRef?.current, {
      attributes: true,
      attributeFilter: ['autocomplete']
    });

    return () => {
      observerHack.disconnect();
    };
  }, []);

  const handlePlaceSelect = useCallback(autoComplete => {
    const addressObject: IGooglePlace = autoComplete.getPlace();
    onPlaceChange(addressObject);
    if (clearOnSelect) {
      setQuery('');
    }
  }, [clearOnSelect, onPlaceChange]);

  useEffect(() => {
    if (!googlePlacesScriptLoaded) {
      return;
    }
    const autoComplete = new (window as any).google.maps.places.Autocomplete(
      (autoCompleteRef.current as any).inputRef.current,
      { componentRestrictions: { country: 'us' } }
    );
    autoComplete.setFields(['address_components', 'formatted_address', 'geometry']);
    autoComplete.setTypes([googlePlaceResultTypeToTypeParam(resultType)]);
    autoComplete.addListener('place_changed', () => handlePlaceSelect(autoComplete));
  }, [handlePlaceSelect, resultType, googlePlacesScriptLoaded]);

  return (
    <>
      <SimpleInputWrapper
        ref={autoCompleteRef}
        onChange={event => setQuery(event.target.value)}
        placeholder={placeholder}
        value={query}
      />
    </>
  );
};

export default GoogleAutocompleteInput;
