import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateUtils } from 'react-day-picker';
import { times } from 'lodash';
import { Placeholder, PlaceholderHeader, PlaceholderLine } from 'semantic-ui-react';
import moment from 'moment';
import { IBindingCallback1 } from '@models/Callbacks';
import { IBriefBookingDto } from '@models/domain/Booking';
import { extractUserRoles } from '@screens/Authorization/reducers';
import { Role } from '@screens/Authorization/models/Roles';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import OtherNeedsNotes from '@screens/BookingCheckout/components/BookingDetails/OtherNeedsNotes';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import { toPrice } from '@helpers/price.helper';
import { Currency } from '@models/domain/Currency';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import { IMakeOrderRequest, IServiceIdToAmount } from '@models/domain/additional_service/MakeOrderRequest';
import ServiceItem from '@components/NewDesign/AdditionalServicesOrder/components/ServiceItem';
import styles from './styles.module.scss';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';
import { ILoadAdditionalServiceWithAvailabilityRequest } from '@models/domain/additional_service/LoadAdditionalServiceWithAvailabilityRequest';
import SingleDatePicker from '@components/NewDesign/SingleDatePicker';
import { maxDate } from '@helpers/date.helper';
import TransactionFee from '@screens/BookingCheckout/components/BookingDetails/TransactionFee';
import { calculatePriceWithFee } from '@screens/BookingCheckout/components/BookingDetails/BookingGrandTotal';

export interface IAdditionalServicesOrderSectionProps {
  booking: IBriefBookingDto;
  buildingId: string;
  className?: string;
  servicesList: IAdditionalServiceWithAvailability[];
  servicesListLoading: boolean;
  loadAdditionalServicesList: IBindingCallback1<ILoadAdditionalServiceWithAvailabilityRequest>;
  createAdditionalServicesOrder: IBindingCallback1<IMakeOrderRequest>;
}

const AdditionalServicesOrder: React.FC<IAdditionalServicesOrderSectionProps> = (
  {
    servicesList, loadAdditionalServicesList,
    createAdditionalServicesOrder, booking, buildingId, servicesListLoading
  }
) => {
  const [selectedServices, setSelectedServices] = useState<IServiceIdToAmount[]>([]);
  const [selectedDate, setSelectedDate] = useState(maxDate(new Date(), booking.startingDate));
  const [notesText, setNotesText] = useState<string>('');
  const role = useSelector(extractUserRoles)[0];

  useEffect(() => {
    if (buildingId) {
      loadAdditionalServicesList({
        buildingId,
        scheduledOn: selectedDate
      });
    }
  }, [buildingId, loadAdditionalServicesList, selectedDate]);

  const handleServiceItemClick = (serviceIdToAmount: IServiceIdToAmount) => {
    if (serviceIdToAmount.amount === 0) {
      setSelectedServices(prev => [
        ...prev.filter(selService => selService.serviceId !== serviceIdToAmount.serviceId)
      ]);
    } else {
      setSelectedServices(prev => [
        ...prev.filter(selService => selService.serviceId !== serviceIdToAmount.serviceId),
        serviceIdToAmount
      ]);
    }
  };

  const handleDateChange = ({ startingDate }) => {
    setSelectedDate(startingDate);
    setSelectedServices([]);
  };

  const makeOrderRequest = () => {
    createAdditionalServicesOrder({
      scheduledOn: selectedDate,
      note: notesText,
      bookingId: booking.id,
      servicesToAmount: selectedServices
    });
  };

  const onMakeOrderClick = () => {
    makeOrderRequest();
    setSelectedServices([]);
  };

  const total = selectedServices
    .map(s => s.amount * servicesList.find(service => service.id === s.serviceId).price.amount)
    .reduce((a, b) => a + b, 0);
  const { priceWithFee, feeAmount } = calculatePriceWithFee(total);

  const bookingEndMoment = booking?.endingDate && moment(booking.endingDate, 'YYYY-MM-DD');
  const bookingStartMoment = moment(booking.startingDate, 'YYYY-MM-DD');

  const isDateDisabled = useCallback(date => {
    const isPast = DateUtils.isPastDay(date);
    const isAfterEndingDate = bookingEndMoment
      && DateUtils.isDayAfter(date, bookingEndMoment.toDate());
    const isBeforeStartDate = DateUtils.isDayBefore(date, bookingStartMoment.toDate());

    return isPast || isAfterEndingDate || isBeforeStartDate;
  }, [bookingEndMoment, bookingStartMoment]);

  return (
    <div>
      <div className={styles.date_wrapper}>
        <SubHeader3 className={styles.date_label}>
          Schedule services on
        </SubHeader3>
        <div>
          <SingleDatePicker
            value={{ startingDate: selectedDate }}
            onChange={handleDateChange}
            disabledDays={isDateDisabled}
          />
        </div>
      </div>
      {selectedDate && (
        <>
          {servicesListLoading || !servicesList ? (
            <>
              {times(2, i => (
                <Placeholder key={i}>
                  <PlaceholderHeader image>
                    <PlaceholderLine />
                    <PlaceholderLine />
                  </PlaceholderHeader>
                  <Placeholder.Paragraph>
                    <PlaceholderLine length="medium" />
                    <PlaceholderLine length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              ))}
            </>
          ) : (
            <div>
              {servicesList.map(s => (
                <ServiceItem
                  key={s.id}
                  service={s}
                  amountSelected={selectedServices.find(selService => (selService.serviceId === s.id))?.amount || 0}
                  initialAmount={selectedServices.find(selService => (selService.serviceId === s.id))?.amount || 0}
                  onClick={handleServiceItemClick}
                  showLocalSum
                  hideCounterInput={role !== Role.NEED}
                />
              ))}
            </div>
          )}
        </>
      )}
      { role === Role.NEED && (
        <>
          <OtherNeedsNotes notesText={notesText} setNotesText={setNotesText} />
          <div className={styles.order_wrapper}>
            <div className={styles.total_section}>
              <div className={styles.total}>
                <div className={styles.label}>
                  <Caption1>
                    Grand total:
                  </Caption1>
                </div>
                <div className={styles.value}>
                  <Caption1>
                    {toPrice({
                      amount: priceWithFee,
                      currency: Currency.USD
                    })}
                  </Caption1>
                </div>
              </div>
              <TransactionFee
                feeAmount={toPrice({
                  amount: feeAmount,
                  currency: Currency.USD
                })}
                className={styles.fee}
              />
            </div>
            <PrimaryButton
              className={styles.book_btn}
              disabled={selectedServices.length === 0}
              onClick={onMakeOrderClick}
            >
              Order
            </PrimaryButton>
          </div>
        </>
      )}
    </div>
  );
};

export default AdditionalServicesOrder;

