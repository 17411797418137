import React from 'react';
import { LocationDescriptor } from 'history';
import { Loader } from 'semantic-ui-react';
import { IBindingCallback1 } from '@models/Callbacks';
import BuildingGridCard from '@screens/BrowseSpaces/components/layouts/cards/BuildingGridCard';
import { searchBrowserBuildingLink } from '@screens/BrowseSpaces/components/layouts/cards/link.helper';
import { IResultBuildingItem } from '@screens/BrowseSpaces/model/ResultItem';
import { getBanner } from '@screens/BrowseSpaces/components/layouts/ResultsList';
import styles from './styles.module.scss';
import { getDefaultImageFromBuildingType } from '@helpers/image.placeholder.helper';
import TilesWrapper from '@components/TilesWrapper';

export interface IResultGridProps {
  results: IResultBuildingItem[];
  loading: boolean;
  toggleLike: IBindingCallback1<string>;
  linkGenerator?: (item: IResultBuildingItem) => LocationDescriptor;
  itemToMeta?: (item: IResultBuildingItem) => string;
  showAdvertising?: boolean;
}

const itemToCard = (item: IResultBuildingItem, toggleLike: IBindingCallback1<string>, linkGenerator, itemToMeta) => (
  <BuildingGridCard
    key={item.id}
    imageSrc={item.avatar || getDefaultImageFromBuildingType(item.type)}
    name={item.name}
    type={item.type}
    meta={itemToMeta(item)}
    linkLocation={linkGenerator(item)}
    liked={item.liked}
    toggleLike={() => toggleLike(item.id)}
    likeLoading={item.likeLoading}
    address={item.location.shortAddress}
  />
);

const simpleItemMeta = item => item.meta;
const ITEMS_THRESHOLD = 4;

const getItemsWithBanner = (items: JSX.Element[]) => {
  const banner = items.length >= ITEMS_THRESHOLD ? getBanner(styles.second_row) : getBanner(styles.first_row);
  return [banner, ...items];
};

const ResultsGrid: React.FC<IResultGridProps> = (
  { results, loading, toggleLike, linkGenerator = searchBrowserBuildingLink,
    itemToMeta = simpleItemMeta, showAdvertising }
) => {
  const resultItems = [...results].map(i => itemToCard(i, toggleLike, linkGenerator, itemToMeta));
  const itemsToShow = showAdvertising ? getItemsWithBanner(resultItems) : resultItems;

  return (
    <>
      <Loader inline="centered" active={loading} />
      {loading || (
        <TilesWrapper>
          {itemsToShow}
        </TilesWrapper>
      )}
    </>
  );
};

export default ResultsGrid;
