import React, { useCallback } from 'react';
import { IInformationSectionProps, InformationSection } from '@components/InformationSection';
import { IMemberShort } from '@screens/AdminDashboard/MembersSummary/model/IMemberShort';
import MemberRow from '@screens/AdminDashboard/MembersSummary/components/MembersSection/MemberRow';
import MemberDetailsModal from '@screens/AdminDashboard/MembersSummary/components/MembersSection/MemberDetailsModal';
import MembersSectionFilter from '@root/screens/AdminDashboard/MembersSummary/components/MembersSection/MembersSectionFilter';
import { IBindingCallback1, IBindingCallback2, IBindingAction } from '@models/Callbacks';
import { IMemberProfile } from '@screens/AdminDashboard/MembersSummary/model/IMemberProfile';
import { IProfileData } from '@screens/NeedsDashboard/Account/model/ProfileData';
import { IMemberFilterData } from '@screens/AdminDashboard/MembersSummary/model/IMemberSearchFilterRequest';
import NoDataContainer from '@components/NoDataContainer';
import TableWrapper from '@components/NewDesign/Table/TableWrapper';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';

interface IMembersSectionProps extends IInformationSectionProps<IMemberShort> {
  fetchMemberDetails: IBindingCallback1<string>;
  hideMemberDetails: IBindingCallback1<void>;
  memberDetails?: IMemberProfile;
  memberDetailsLoading: boolean;
  saveMemberProfile: IBindingCallback1<IProfileData>;
  saveMemberProfileLoading: boolean;
  blockUser: IBindingCallback1<string>;
  unblockUser: IBindingCallback1<string>;
  blockUserLoading?: boolean;
  unblockUserLoading?: boolean;
  updateFilterState?: IBindingCallback2<string, any>;
  clearFilterState?: IBindingAction;
  applyFilterChanges?: IBindingAction;
  memberFilterData?: IMemberFilterData;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  setWriting: IBindingCallback1<boolean>;
  sendMessageLoading: boolean;
  writing: boolean;
}

const MembersSection: React.FC<IMembersSectionProps> = (
  {
    fetchMemberDetails,
    hideMemberDetails,
    memberDetails,
    memberDetailsLoading,
    saveMemberProfileLoading,
    saveMemberProfile,
    blockUser,
    unblockUserLoading,
    blockUserLoading,
    unblockUser,
    updateFilterState,
    clearFilterState,
    applyFilterChanges,
    memberFilterData,
    setWriting,
    sendMessageLoading,
    sendMessage,
    writing,
    ...props
  }
) => {
  const memberToItem = useCallback((member: IMemberShort) => (
    <MemberRow
      key={member.id}
      member={member}
      onClick={() => fetchMemberDetails(member.id)}
    />
  ), [fetchMemberDetails]);

  return (
    <>
      <MemberDetailsModal
        memberDetails={memberDetails}
        memberDetailsLoading={memberDetailsLoading}
        saveMemberProfile={saveMemberProfile}
        saveMemberProfileLoading={saveMemberProfileLoading}
        hideMemberDetails={hideMemberDetails}
        blockUser={blockUser}
        unblockUser={unblockUser}
        blockUserLoading={blockUserLoading}
        unblockUserLoading={unblockUserLoading}
        sendMessage={sendMessage}
        sendMessageLoading={sendMessageLoading}
        setWriting={setWriting}
        writing={writing}
      />
      <MembersSectionFilter
        updateFilterState={updateFilterState}
        clearFilterState={clearFilterState}
        applyFilterChanges={applyFilterChanges}
        memberFilterData={memberFilterData}
      />
      <InformationSection
        {...props}
        hideTitle
        renderItem={memberToItem}
        itemsWrapper={TableWrapper}
        itemsWrapperProps={{
          titles: ['Profile Pic', 'Member Name', 'Email', 'Member Type', 'Created At']
        }}
        noItemsPlaceholder={() => <></>}
      />
      {{ ...props }.items.length === 0 ? <NoDataContainer message="There are no members yet" /> : ''}
    </>
  );
};

export default MembersSection;
