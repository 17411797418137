import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { visibleToSpecificHave } from '@helpers/authRules.helper';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';

export interface IOwnerEditButtonProps {
  ownerId: string;
  buildingId: string;
}

const OwnerEditButton: React.FC<IOwnerEditButtonProps> = (
  { ownerId, buildingId }
) => {
  const history = useHistory();

  const handleButtonClick = useCallback(() => {
    history.push(HAVES_DASHBOARD_ENDPOINTS.BUILDING_EDITOR(buildingId));
  }, [buildingId, history]);

  const Btn = useCallback(visibleToSpecificHave(ownerId)(
    () => (
      <PrimaryButton
        onClick={handleButtonClick}
        marginZero
      >
        Edit
      </PrimaryButton>
    )
  ), [ownerId, buildingId]);

  return <Btn />;
};

export default OwnerEditButton;
