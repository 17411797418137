import React from 'react';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';
import BuildingHeaderInfo from '@screens/BookingCheckout/components/BookingDetails/BuildingHeaderInfo';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import { history } from '@helpers/history.helper';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import { ICreditCard } from '@screens/CreditCardConfiguration/model/CreditCard';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';
import MetaTags from '@components/MetaTags';
import NeedNotes from '@components/NewDesign/NeedNotes';
import styles from './styles.module.scss';

export interface IReceiptSectionProps {
  transactionId: string;
  transactionDateTime: Date;
  building: IBuildingForDisplaying;
  summaryComponent: () => JSX.Element;
  totalPriceLabel: string;
  creditCard?: ICreditCard;
  notes: string;
}

const ReceiptSection: React.FC<IReceiptSectionProps> = (
  {
    transactionId, building, transactionDateTime,
    totalPriceLabel, summaryComponent, creditCard, notes
  }
) => (
  <>
    <MetaTags title="Receipt" withSuffix />
    <div className={styles.top_button_container}>
      <SmallLinkButton
        to=""
        onClick={() => history.push(ENDPOINTS.USER)}
        left
      >
        Go to Dashboard
      </SmallLinkButton>
    </div>
    <Header3>
      Transaction #
      {transactionId ?? ''}
    </Header3>
    <div className={styles.info_with_summary}>
      <BuildingHeaderInfo building={building} hideMap />
      <div className={styles.summary}>
        {summaryComponent()}
      </div>
      <NeedNotes
        notesText={notes}
        marginTop
      />
    </div>
    <div className={styles.total}>
      <div className={styles.label}>
        <SubHeader1>
          Billed to your card:
        </SubHeader1>
      </div>
      <div className={styles.value}>
        <SubHeader1>
          {totalPriceLabel ?? ''}
          {' '}
        </SubHeader1>
      </div>
      {transactionDateTime && (
        <div className={styles.datetime}>
          <BodyText1>
            on
            {' '}
            { new Date(transactionDateTime).toLocaleDateString('en-US')}
          </BodyText1>
        </div>
      )}
    </div>
    {creditCard && transactionDateTime && (
      <div className={styles.card}>
        <LabelGroupWrapper
          title="Payment Method"
          value={`**** **** **** ${creditCard.last4} ${creditCard.brand.toUpperCase()}`}
        />
      </div>
    )}
  </>
);

export default ReceiptSection;
