import React, {useCallback, useEffect, useState} from 'react';
import { TableCell, TableRow } from 'semantic-ui-react';
import classNames from 'classnames';
import { ISpaceDto } from '@screens/SpaceEditor/models/ISpaceDto';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { ISpaceEdit } from '@screens/SpaceEditor/models/ISpaceEdit';
import { IChangeSpaceBlocked } from '@screens/SpaceEditor/models/IChangeSpaceBlocked';
import Switch from '@components/NewDesign/Switch';
import InputWrapper from '@components/NewDesign/Input';
import TransparentButton from '@components/NewDesign/Button/TransparentButton';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import TableRegularContent from '@components/NewDesign/Typography/FormAndTable/TableRegularContent';
import styles from './styles.module.scss';

interface ISpaceRowProps {
  space: Partial<ISpaceDto>;
  isEditing: boolean;
  saveSpace: IBindingCallback1<ISpaceEdit>;
  changeSpaceBlocked: IBindingCallback1<IChangeSpaceBlocked>;
  chooseSpace: IBindingCallback1<Partial<ISpaceDto>>;
  chosenSpace?: Partial<ISpaceDto>;
  hideSpace: IBindingAction;
  saveSpaceLoading: boolean;
}

const SpaceRow: React.FC<ISpaceRowProps> = (
  { space, isEditing, chooseSpace, hideSpace, saveSpace, saveSpaceLoading,
    changeSpaceBlocked, chosenSpace
  }
) => {
  const [index, setIndex] = useState<string>(space.index || '');

  useEffect(
    () => {
      if (chosenSpace?.id === space.id) {
        setIndex(chosenSpace?.index || '');
      }
    }, [chosenSpace, space.id]
  );

  const handleSave = useCallback(() => {
    saveSpace({ index, blockedByOwner: !!space.blockedByOwner });
  }, [saveSpace, index, space.blockedByOwner]);

  const handleChangeBlocked = useCallback(() => {
    changeSpaceBlocked({ id: space.id, blockedByOwner: !space.blockedByOwner });
  }, [changeSpaceBlocked, space.blockedByOwner, space.id]);

  const handleCancel = useCallback(() => {
    hideSpace();
  }, [hideSpace]);

  const isValid = !!index.trim();

  if (!space?.id && !isEditing) {
    return null;
  }

  return (
    <TableRow
      key={space.id}
      className={classNames(styles.row)}
    >
      <TableCell>
        <div className={styles.index_wrapper}>
          {isEditing
            ? (
              <div className={styles.container}>
                <div className={styles.input_wrapper}>
                  <InputWrapper
                    value={index}
                    onChange={event => setIndex(event.target.value)}
                    placeholder="Index"
                  />
                </div>
                <div className={styles.buttons_wrapper}>
                  <div className={styles.cancel}>
                    <TransparentButton
                      content="Cancel"
                      onClick={handleCancel}
                    />
                  </div>
                  <div className={styles.save}>
                    <PrimaryButton
                      content="Save"
                      onClick={handleSave}
                      loading={saveSpaceLoading}
                      disabled={!isValid}
                    />
                  </div>
                </div>
              </div>
            )
            : (
              <div
                className={styles.container}
                onClick={() => chooseSpace(space)}
              >
                <div className={styles.index}>
                  <TableRegularContent>
                    {space.index}
                  </TableRegularContent>
                </div>
              </div>
            )}
        </div>
      </TableCell>
      <TableCell width={1}>
        <div className={styles.switch_container}>
          <Switch
            className={styles.checkbox}
            checked={!space.blockedByOwner}
            onChange={handleChangeBlocked}
            disabled={isEditing}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default SpaceRow;
