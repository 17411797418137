import React from 'react';
import { ModalHeader, ModalHeaderProps } from 'semantic-ui-react';
import classNames from 'classnames';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';
import styles from './styles.module.scss';

const ModalHeaderWrapper: React.FC<ModalHeaderProps> = props => {
  const { className, children } = props;

  return (
    <ModalHeader
      {...props}
      className={classNames(styles.container, className)}
    >
      <Header3>
        {children}
      </Header3>
    </ModalHeader>
  );
};

export default ModalHeaderWrapper;
