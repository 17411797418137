import React, { useCallback } from 'react';
import { SelectProps } from 'semantic-ui-react';
import { useField } from 'formik';
import { ICommonInputProps } from '@components/formik/common';
import FormikSelectBase from '@components/formik/select/SelectBase';

export type IFormikSelectProps = ICommonInputProps<HTMLElement, SelectProps>;

const FormikSelect: React.FC<IFormikSelectProps> = props => {
  const { propsOrFieldName } = props;
  const [,, helpers] = useField(propsOrFieldName);

  const onChange = useCallback(
    (ev, { value }) => helpers.setValue(value as any),
    [helpers]
  );

  const onBlur = useCallback(
    () => helpers.setTouched(true),
    [helpers]
  );

  return <FormikSelectBase onBlur={onBlur} onChange={onChange} {...props} />;
};

export default FormikSelect;
