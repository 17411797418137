import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IServiceOrderCheckoutData } from '@screens/ServiceOrderCheckout/model/ServiceOrderCheckout';
import {
  clearServiceOrderCheckoutDetailsRoutine, loadAdditionalServicesWithAvailabilityRoutine,
  loadServiceOrderDetailsRoutine, toggleFavoriteRoutine
} from '@screens/ServiceOrderCheckout/routines';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';

export interface IBookingDetailsReducerState {
  serviceOrderDetails: IServiceOrderCheckoutData;
  additionalServices?: IAdditionalServiceWithAvailability[];
}

const initialState: IBookingDetailsReducerState = {
  serviceOrderDetails: undefined
};

export const serviceOrderRootPageReducer = createReducer(initialState, {
  [loadServiceOrderDetailsRoutine.SUCCESS]: (state, { payload }: PayloadAction<IServiceOrderCheckoutData>) => {
    state.serviceOrderDetails = payload;
  },
  [clearServiceOrderCheckoutDetailsRoutine.FULFILL]: state => {
    state.serviceOrderDetails = undefined;
  },
  [toggleFavoriteRoutine.SUCCESS]: state => {
    state.serviceOrderDetails.building.favorite = !state.serviceOrderDetails.building.favorite;
  },
  [loadAdditionalServicesWithAvailabilityRoutine.SUCCESS]: (
    state, { payload }: PayloadAction<IAdditionalServiceWithAvailability[]>
  ) => {
    state.additionalServices = payload;
  }
});
