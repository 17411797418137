import React, { useCallback, useState } from 'react';
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  DragStartEvent,
  DragEndEvent
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { IImageDto } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { ImageMiniature } from '@screens/BuildingEditor/components/ImagesTab/ImageMiniature';
import { SortableImageMiniature } from '@screens/BuildingEditor/components/ImagesTab/ImagesDragAndDropArea/SortableImageMiniature';
import styles from './styles.module.scss';

type Props = {
  images: IImageDto[];
  onImagesOrderChange: any;
  setExpandedImage: any;
  setPendingDelete: any;
  setImageAsAvatar: any;
}

const ImagesDragAndDropArea = ({
  images, onImagesOrderChange, setExpandedImage, setPendingDelete, setImageAsAvatar
}) => {
  const [activeImage, setActiveImage] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const active = images.find(image => image.id === event.active.id);
    setActiveImage(active);
  }, [images]);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      onImagesOrderChange(items => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over?.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveImage(null);
  }, [onImagesOrderChange]);

  const handleDragCancel = useCallback(() => {
    setActiveImage(null);
  }, []);

  const renderSortableImage = i => (
    <SortableImageMiniature
      id={i.id}
      onClick={setExpandedImage}
      key={i.id}
      src={i.url}
      onDeleteClick={() => setPendingDelete(i.id)}
      deleting={i.deleteLoading}
      setAsAvatar={() => setImageAsAvatar(i.id)}
      settingAvatar={i.setAsAvatarLoading}
      isAvatar={i.avatar}
      hideButtons
    />
  );

  const renderOverlayImage = i => (
    <ImageMiniature
      id={i.id}
      onClick={setExpandedImage}
      key={i.id}
      src={i.url}
      onDeleteClick={() => setPendingDelete(i.id)}
      deleting={i.deleteLoading}
      setAsAvatar={() => setImageAsAvatar(i.id)}
      settingAvatar={i.setAsAvatarLoading}
      isAvatar={i.avatar}
      isDragging
      hideButtons
    />
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={images.map(item => item.id)}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }} className={styles.images}>
          {images.map(renderSortableImage)}
        </div>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: '0 0' }}>
        { activeImage ? renderOverlayImage(activeImage) : null }
      </DragOverlay>
    </DndContext>
  );
};

export { ImagesDragAndDropArea };
