import moment, { Moment } from 'moment';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const COMPARABLE_DATE_FORMAT = 'YYYY.MM.DD';

export const equalMomentDates = (m1: Moment, m2: Moment): boolean => m1.format(COMPARABLE_DATE_FORMAT) === m2.format(COMPARABLE_DATE_FORMAT);
export const lessThanMomentDates = (m1: Moment, m2: Moment): boolean => m1.format(COMPARABLE_DATE_FORMAT) < m2.format(COMPARABLE_DATE_FORMAT);
export const lessThanOrEqualMomentDates = (m1: Moment, m2: Moment): boolean => m1.format(COMPARABLE_DATE_FORMAT) <= m2.format(COMPARABLE_DATE_FORMAT);

export const maxDate = (a: Date, b: Date): Date => (lessThanMomentDates(moment(a), moment(b)) ? b : a);
