/* eslint-disable max-len */
import { createRoutine } from 'redux-saga-routines';

const createBuildingsAndSpacesRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`ADMIN_DASHBOARD__BUILDINGS_AND_SPACES:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const fetchBuildingsSummaryRoutine = createBuildingsAndSpacesRoutine('FETCH_BUILDINGS_SUMMARY');
export const setPageRoutine = createBuildingsAndSpacesRoutine('SET_PAGE');
export const fetchAllTagsRoutine = createBuildingsAndSpacesRoutine('FETCH_ALL_TAGS');
export const createBuildingRoutine = createBuildingsAndSpacesRoutine('CREATE_BUILDING');
