import React from 'react';
import classNames from 'classnames';
import { IOrderedServiceDto } from '@screens/ServiceOrderCheckout/model/OrderedServiceDto';
import { IServiceOrderDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderDto';
import OrderOneServiceSummary from '@screens/ServiceOrderCheckout/components/ServiceOrderDetails/OrderOneServiceSummary';
import styles from './styles.module.scss';

export interface IOrderedServicesSummaryProps {
  services: IOrderedServiceDto[];
  order: IServiceOrderDto;
  showCompletedIndicator?: boolean;
  containerClassName?: string;
  serviceSummaryClassName?: string;
}

const OrderedServicesSummary: React.FC<IOrderedServicesSummaryProps> = ({
  services,
  order,
  showCompletedIndicator,
  containerClassName,
  serviceSummaryClassName
}) => (
  <div className={classNames(styles.container, containerClassName)}>
    {services.map(service => (
      <OrderOneServiceSummary
        key={service.id}
        service={service}
        order={order}
        showCompletedIndicator={showCompletedIndicator}
        className={serviceSummaryClassName}
      />
    ))}
  </div>
);

export default OrderedServicesSummary;
