import { fetchPaymentRequirementsRoutine } from '@screens/BookingCheckout/routines';
import { IPaymentRequirementResponse } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import { createReducer } from '@reduxjs/toolkit';

export interface ISummaryAndSubmissionReducerState {
  requirements: IPaymentRequirementResponse;
}

const initialState: ISummaryAndSubmissionReducerState = {
  requirements: undefined
};

export const summaryAndSubmissionReducer = createReducer(initialState, {
  [fetchPaymentRequirementsRoutine.SUCCESS]: (state, { payload }) => {
    state.requirements = payload;
  }
});

