import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import moment from 'moment';
import classNames from 'classnames';
import {
  createStripePaymentAtCheckoutRoutine
} from '@screens/BookingCheckout/routines';
import { IBindingCallback1 } from '@models/Callbacks';
import {
  extractCreateStripePaymentAtCheckoutError,
  extractCreateStripePaymentAtCheckoutLoading
} from '@screens/BookingCheckout/reducers';
import { IPaymentMethodWithTitle, IPaymentRequestWithStripe } from '@components/StripePaymentForm';
import { useStripe } from '@stripe/react-stripe-js';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import BookingInfoTitleWithNote from '@screens/BookingCheckout/components/BookingDetails/BookingInfoTitleWithNote';
import { priceAmountWithCurrencyToPrice } from '@helpers/price.helper';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import { IOrderWithServicesDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderCheckout';
import {
  extractBuildingData,
  extractFetchPaymentRequirementsLoading,
  extractOrderWithServices, extractPaymentRequirements
} from '@screens/ServiceOrderCheckout/reducers';
import ServiceOrderGrandTotal
  from '@screens/ServiceOrderCheckout/components/ServiceOrderDetails/ServiceOrderGrandTotal';
import { fetchOrderPaymentRequirementsRoutine } from '@screens/ServiceOrderCheckout/routines';
import { IOrderPaymentRequirementResponse } from '@screens/ServiceOrderCheckout/model/OrderPaymentRequirementResponse';
import { generateLabel } from '@models/domain/space/SpaceTypes';
import styles from './styles.module.scss';

export interface IOrderSubmissionAndSummaryStepProps extends IState, IActions, ISubmissionAndSummaryStepGeneralProps {
  orderId: string;
}

interface ISubmissionAndSummaryStepGeneralProps {
  paymentMethod?: IPaymentMethodWithTitle;
}

interface IState {
  building: IBuildingForDisplaying;
  orderWithServices: IOrderWithServicesDto;
  paymentRequirementsLoading: boolean;
  paymentRequirements: IOrderPaymentRequirementResponse;
  stripePaymentLoading: boolean;
  stripePaymentError?: string;
}

interface IActions {
  fetchPaymentRequirements: IBindingCallback1<string>;
  createStripePayment: IBindingCallback1<IPaymentRequestWithStripe>;
}

const SubmissionAndSummaryStep: React.FC<IOrderSubmissionAndSummaryStepProps> = (
  {
    orderId, fetchPaymentRequirements, paymentRequirements,
    createStripePayment, stripePaymentLoading,
    paymentRequirementsLoading, paymentMethod, orderWithServices,
    building
  }
) => {
  useEffect(() => {
    fetchPaymentRequirements(orderId);
  }, [fetchPaymentRequirements, orderId]);

  const stripe = useStripe();

  const servicesToTotalAmount = useCallback(
    () => orderWithServices.services
      .map(service => service.orderedAmount)
      .reduce((a, b) => a + b, 0),
    [orderWithServices]
  );

  return (
    <div className={classNames('content_wrapper', styles.container)}>
      <Form
        loading={paymentRequirementsLoading}
        onSubmit={() => createStripePayment({
          transactionId: paymentRequirements.transaction.id,
          paymentMethodId: paymentMethod.id,
          transactionType: paymentRequirements.transaction.type,
          stripe
        })}
      >
        <div>
          <BodyText2 className={styles.startLabel}>
            SCHEDULED ON
            {' '}
            {moment(orderWithServices.order.scheduledOnDate).format('MMMM Do, YYYY')}
          </BodyText2>
        </div>
        <BookingInfoTitleWithNote
          note={`Located at ${building?.location?.address}`}
        >
          {generateLabel(servicesToTotalAmount(), 'unit', 'units')}
          &nbsp;|
          {' '}
          {priceAmountWithCurrencyToPrice(orderWithServices.order.totalPrice, orderWithServices.order.currency)}
          {' '}
        </BookingInfoTitleWithNote>
        <BookingInfoTitleWithNote
          note="Will be paid today"
        >
          Paying with
          {' '}
          {paymentMethod?.title}
        </BookingInfoTitleWithNote>
        {paymentRequirements && (
          <ServiceOrderGrandTotal
            order={orderWithServices.order}
            className={styles.grand_total}
          />
        )}
        <div>
          <PrimaryButton
            type="submit"
            content="Finish & Pay"
            loading={stripePaymentLoading}
          />
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps: (state) => IState = state => ({
  building: extractBuildingData(state),
  orderWithServices: extractOrderWithServices(state),
  paymentRequirementsLoading: extractFetchPaymentRequirementsLoading(state),
  paymentRequirements: extractPaymentRequirements(state),
  stripePaymentLoading: extractCreateStripePaymentAtCheckoutLoading(state),
  stripePaymentError: extractCreateStripePaymentAtCheckoutError(state)
});

const mapDispatchToProps: IActions = {
  fetchPaymentRequirements: fetchOrderPaymentRequirementsRoutine,
  createStripePayment: createStripePaymentAtCheckoutRoutine
};

export { SubmissionAndSummaryStep };

export type IPaymentStepState = IState;
export type IPaymentStepActions = IActions;

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionAndSummaryStep);
