import { Routine } from 'redux-saga-routines';
import { submitJoinOurTeamRoutine } from '@screens/static/routines';

export const joinOurTeamReducer = (state = null, action: Routine<any>) => {
  switch (action.type) {
    case submitJoinOurTeamRoutine.SUCCESS:
      return {
        ...state
      };
    default:
      return state;
  }
};
