import React from 'react';
import { IPriceWithCurrency } from '@models/domain/Booking';
import { toPrice } from '@helpers/price.helper';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import TransactionFee from '@screens/BookingCheckout/components/BookingDetails/TransactionFee';
import styles from './styles.module.scss';

export interface IBookingGrandSummaryTotalProps {
  todaySum: IPriceWithCurrency;
  feeAmount: IPriceWithCurrency;
}

const BookingSummaryGrandTotal: React.FC<IBookingGrandSummaryTotalProps> = (
  { todaySum, feeAmount }
) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.total}>
        <div className={styles.label}>
          <SubHeader1>
            Billed to your card:
          </SubHeader1>
        </div>
        <div className={styles.value}>
          <SubHeader1>
            {toPrice(todaySum)}
            {' '}
            today
          </SubHeader1>
        </div>
      </div>
      <TransactionFee feeAmount={toPrice(feeAmount)} className={styles.fee} />
    </div>
  </div>
);

export default BookingSummaryGrandTotal;
