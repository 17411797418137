import React from 'react';
import { Container } from 'semantic-ui-react';
import styles from '@screens/Landing/components/HowItWorksBlock/PageContent/styles.module.scss';
import SeekingSpace from '@screens/Landing/components/HowItWorksBlock/SeekingSpace';
import OfferingSpace from '@screens/Landing/components/HowItWorksBlock/OfferingSpace';
import Tabs from '@components/Tabs';
import VideoPlayer from '@components/VideoPlayer';
import Header2 from '@components/NewDesign/Typography/Headers/Header2';

export interface IHowItWorksContentProps {
  title?: string;
}

const HowItWorksContent: React.FC<IHowItWorksContentProps> = ({ title = 'Learn the process' }) => (
  <div className={styles.container}>
    <div className={styles.centered}>
      <VideoPlayer
        allowFullScreen
        className={styles.video_player}
        link="https://www.youtube.com/embed/JgEbaV10g7k"
        title="YouTube video player"
      />
      <Header2 center>{title}</Header2>
    </div>
    <Tabs
      content={[
        {
          key: 1,
          name: 'Seeking Space',
          element: {
            Element: SeekingSpace
          }
        },
        {
          key: 2,
          name: 'Offering Space',
          element: {
            Element: OfferingSpace
          }
        }
      ]}
      wrapper={{
        Element: Container,
        props: {
          text: true,
          className: styles.text
        }
      }}
    />
  </div>
);

export default HowItWorksContent;
