export enum ParkingSpaceCategory {
  CAR_PARKING_OUTDOORS_UNCOVERED = 'Car Parking – Outdoors Uncovered',
  CAR_PARKING_OUTDOORS_COVERED = 'Car Parking – Outdoors Covered',
  CAR_PARKING_INDOORS = 'Car Parking – Indoors',
  CAR_PARKING_INDOORS_CLIMATE_CONTROLLED = 'Car Parking – Indoors Climate Controlled',
  RV_PARKING_OUTDOORS_UNCOVERED = 'RV Parking – Outdoors Uncovered',
  RV_PARKING_OUTDOORS_COVERED = 'RV Parking – Outdoors Covered',
  RV_PARKING_INDOORS = 'RV Parking – Indoors',
  RV_PARKING_INDOORS_CLIMATE_CONTROLLED = 'RV Parking – Indoors Climate Controlled',
  TRUCK_PARKING_SEMI_TRAILER_ONLY_OUTDOORS = 'Truck Parking – Semi-Trailer Only Outdoors',
  TRUCK_PARKING_SEMI_TRUCK_AND_TRAILER_OUTDOORS = 'Truck Parking – Semi-Truck and Trailer Only Outdoors',
  BOAT_PARKING_OUTDOORS_DRY_UNCOVERED = 'Boat Parking – Outdoors Dry Uncovered',
  BOAT_PARKING_OUTDOORS_WET_UNCOVERED = 'Boat Parking – Outdoors Wet Uncovered',
  BOAT_PARKING_OUTDOORS_DRY_COVERED = 'Boat Parking – Outdoors Dry Covered',
  BOAT_PARKING_OUTDOORS_WET_COVERED = 'Boat Parking – Outdoors Wet Covered',
  BOAT_PARKING_INDOORS = 'Boat Parking – Indoors',
  BOAT_PARKING_INDOORS_CLIMATE_CONTROLLED = 'Boat Parking – Indoors Climate Controlled',
  OTHER = 'Other',
}
