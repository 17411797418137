import { Routine } from 'redux-saga-routines';
import { IMessageDto } from '@models/domain/message/MessageDto';
import {
  fetchDashboardMessagesRoutine,
  readMessageFromDashboardRoutine,
  setMessageFullFromDashboardRoutine,
  setWritingMessageFromDashboardRoutine
} from '@screens/AdminDashboard/Dashboard/routines';

export interface IDashboardReducerState {
    messages: IMessageDto[];
    moreMessages: boolean;
    writing: boolean;
    messageFull?: IMessageDto;
}

const initialState: IDashboardReducerState = {
  messages: [],
  moreMessages: false,
  writing: false
};

export const dashboardReducer = (state = initialState, action: Routine<any>) => {
  const { type, payload } = action;
  switch (type) {
    case setMessageFullFromDashboardRoutine.FULFILL:
      return {
        ...state,
        messageFull: payload
      };
    case readMessageFromDashboardRoutine.SUCCESS:
      return {
        ...state,
        messages: state.messages.map(m => (m.id === payload
          ? { ...m, read: true }
          : m
        ))
      };
    case setWritingMessageFromDashboardRoutine.FULFILL:
      return {
        ...state,
        writing: payload
      };
    case fetchDashboardMessagesRoutine.SUCCESS:
      return {
        ...state,
        messages: payload.items,
        moreMessages: payload.totalPages > 1
      };
    default:
      return state;
  }
};
