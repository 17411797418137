import React from 'react';
import { ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import SpaceItem from '@screens/BuildingDetails/components/SpaceItem';
import { IScheduleSpaceRequest } from '@screens/NeedsDashboard/Bookings/model/Schedule';
import { IBindingCallback1 } from '@models/Callbacks';
import { ISpaceToAmount } from '@screens/BuildingDetails/model/BookingRequest';
import { SpaceType, spaceTypeKey } from '@models/domain/space/SpaceTypes';
import styles from './styles.module.scss';

export interface ISpacePickUpBlock {
  availableSpaces: ISpaceWithAvailability[];
  chosenSpaces: IScheduleSpaceRequest[];
  handleUpdate: IBindingCallback1<ISpaceToAmount>;
  initialRequests: IScheduleSpaceRequest[];
}

const SpaceDropOffBlock: React.FC<ISpacePickUpBlock> = (
  { availableSpaces, chosenSpaces, handleUpdate, initialRequests }
) => (
  <div className={styles.container}>
    {availableSpaces.filter(t => t.spaceType === spaceTypeKey(SpaceType.WAREHOUSE)).map(t => (
      <SpaceItem
        key={t.id}
        space={t}
        amountSelected={
          t.spaceIdsAvailable
            .filter(id => chosenSpaces.find(sp => sp.spaceId === id))
            .length
        }
        notesRequired
        onClick={handleUpdate}
        initialAmount={initialRequests.filter(req => t.spaceIdsAvailable.includes(req.spaceId)).length}
        initialNotes={initialRequests.filter(req => t.spaceIdsAvailable.includes(req.spaceId)).map(sp => sp.note)}
      />
    ))}
  </div>
);

export default SpaceDropOffBlock;
