import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import MembersSection from '@screens/AdminDashboard/MembersSummary/components/MembersSection';
import {
  extractFetchMembersLoading,
  extractMembers,
  extractCurrentPage,
  extractCurrentSize,
  extractTotalResults,
  extractTotalPages,
  extractFetchMemberDetailsLoading,
  extractMemberDetails,
  extractSaveMemberProfileLoading,
  extractBlockUserLoading,
  extractUnblockUserLoading,
  extractSendMessageFromMembersLoading, extractWriting
} from '@screens/AdminDashboard/MembersSummary/reducers';
import {
  fetchMemberDetailsRoutine,
  fetchMembersRoutine,
  resetMemberDetailsRoutine,
  fetchMembersByFilterRoutine,
  setPageRoutine,
  saveMemberProfileRoutine,
  hideMemberDetailsRoutine,
  blockUserRoutine,
  unblockUserRoutine,
  setWritingMessageFromMembersRoutine,
  sendMessageFromMembersRoutine
} from '@screens/AdminDashboard/MembersSummary/routines';
import { IPageable } from '@models/domain/PageableReducerState';
import { IBindingCallback1 } from '@models/Callbacks';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import { IMemberShort } from '@screens/AdminDashboard/MembersSummary/model/IMemberShort';
import Pagination from '@components/Pagination';
import { IMemberProfile } from '@screens/AdminDashboard/MembersSummary/model/IMemberProfile';
import { IMemberFilterData } from '@screens/AdminDashboard/MembersSummary/model/IMemberSearchFilterRequest';
import { IProfileData } from '@screens/NeedsDashboard/Account/model/ProfileData';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';

export interface IMembersSummaryProps extends IPageable {
  members: IMemberShort[];
  memberDetails?: IMemberProfile;
  resetMemberDetails: IBindingCallback1<void>;
  hideMemberDetails: IBindingCallback1<void>;
  fetchMembers: IBindingCallback1<IPageRequest>;
  fetchMembersByFilter: IBindingCallback1<any>;
  fetchMemberDetails: IBindingCallback1<string>;
  saveMemberProfile: IBindingCallback1<IProfileData>;
  memberDetailsLoading: boolean;
  saveMemberProfileLoading: boolean;
  membersLoading: boolean;
  setPage: IBindingCallback1<number>;
  blockUser: IBindingCallback1<string>;
  unblockUser: IBindingCallback1<string>;
  blockUserLoading?: boolean;
  unblockUserLoading?: boolean;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  setWriting: IBindingCallback1<boolean>;
  sendMessageLoading: boolean;
  writing: boolean;
}
const defaultFilterState = {
  fullName: '',
  email: '',
  role: ''
};

const DEFAULT_PAGE = 1;

const MembersSummary: React.FC<IMembersSummaryProps> = (
  {
    members, fetchMembers, membersLoading, setPage, pageSize, page,
    totalPages, memberDetails, fetchMemberDetails, memberDetailsLoading,
    saveMemberProfile, saveMemberProfileLoading, resetMemberDetails, hideMemberDetails, blockUser,
    blockUserLoading, unblockUser, unblockUserLoading, fetchMembersByFilter,
    sendMessage, sendMessageLoading, setWriting, writing
  }
) => {
  const isFirstLoadRef = useRef<boolean>(true);
  const latestPageRef = useRef<number>(page);
  const [memberFilterData, setMemberFilterData] = React.useState<IMemberFilterData>({
    fullName: '',
    email: '',
    role: ''
  });

  const updateFilterState = useCallback((type: any, value: any) => {
    setMemberFilterData({
      ...memberFilterData,
      [type]: value
    });
  }, [memberFilterData]);

  const clearFilterState = () => {
    setMemberFilterData(defaultFilterState);
    fetchMembers({ size: pageSize, page });
  };

  const applyFilterChanges = useCallback((isResetPage = true) => {
    if (isResetPage) {
      latestPageRef.current = DEFAULT_PAGE;
      setPage(DEFAULT_PAGE);
    }

    fetchMembersByFilter({
      size: pageSize,
      page: isResetPage ? DEFAULT_PAGE : page,
      text: memberFilterData.fullName,
      email: memberFilterData.email,
      includedRoleTypes: memberFilterData.role ? [memberFilterData.role] : [] });
  }, [
    fetchMembersByFilter,
    memberFilterData.email,
    memberFilterData.fullName,
    memberFilterData.role,
    page,
    pageSize,
    setPage
  ]);

  useEffect(() => {
    if (isFirstLoadRef.current && fetchMembers) {
      fetchMembers({ size: pageSize, page });
      isFirstLoadRef.current = false;
    } else if (latestPageRef.current !== page) {
      applyFilterChanges(false);
      latestPageRef.current = page;
    }
  }, [applyFilterChanges, fetchMembers, page, pageSize]);

  return (
    <DashboardPageWrapper
      title="Members"
      rightElement={(
        <PrimaryButton
          content="Add new"
          icon="plus"
          labelPosition="left"
          onClick={() => resetMemberDetails()}
        />
      )}
    >
      <DashboardSectionWrapper>
        <MembersSection
          items={members}
          fetchItems={() => null}
          itemsLoading={membersLoading}
          fetchMemberDetails={fetchMemberDetails}
          memberDetails={memberDetails}
          memberDetailsLoading={memberDetailsLoading}
          saveMemberProfile={saveMemberProfile}
          saveMemberProfileLoading={saveMemberProfileLoading}
          hideMemberDetails={hideMemberDetails}
          blockUser={blockUser}
          unblockUser={unblockUser}
          blockUserLoading={blockUserLoading}
          unblockUserLoading={unblockUserLoading}
          memberFilterData={memberFilterData}
          updateFilterState={updateFilterState}
          clearFilterState={clearFilterState}
          applyFilterChanges={applyFilterChanges}
          sendMessage={sendMessage}
          sendMessageLoading={sendMessageLoading}
          setWriting={setWriting}
          writing={writing}
        />
        {!membersLoading && totalPages > 1 && (
          <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
        )}
      </DashboardSectionWrapper>
    </DashboardPageWrapper>
  );
};

const mapStateToProps = state => ({
  members: extractMembers(state),
  memberDetails: extractMemberDetails(state),
  membersLoading: extractFetchMembersLoading(state),
  memberDetailsLoading: extractFetchMemberDetailsLoading(state),
  saveMemberProfileLoading: extractSaveMemberProfileLoading(state),
  totalResults: extractTotalResults(state),
  totalPages: extractTotalPages(state),
  page: extractCurrentPage(state),
  pageSize: extractCurrentSize(state),
  blockUserLoading: extractBlockUserLoading(state),
  unblockUserLoading: extractUnblockUserLoading(state),
  sendMessageLoading: extractSendMessageFromMembersLoading(state),
  writing: extractWriting(state)
});

const mapDispatchToProps = {
  saveMemberProfile: saveMemberProfileRoutine,
  fetchMembers: fetchMembersRoutine,
  fetchMembersByFilter: fetchMembersByFilterRoutine,
  fetchMemberDetails: fetchMemberDetailsRoutine,
  setPage: setPageRoutine.fulfill,
  resetMemberDetails: resetMemberDetailsRoutine.fulfill,
  hideMemberDetails: hideMemberDetailsRoutine.fulfill,
  blockUser: blockUserRoutine,
  unblockUser: unblockUserRoutine,
  sendMessage: sendMessageFromMembersRoutine,
  setWriting: setWritingMessageFromMembersRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(MembersSummary);
