import React from 'react';
import { Table, TableBody, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { IWrapperProps } from '@components/InformationSection';
import ScrollableTableWrapper from '@components/ScrollableTableWrapper';
import TableColumnTitle from '@components/NewDesign/Typography/FormAndTable/TableColumnTitle';
import styles from './styles.module.scss';

const TableWrapper: React.FC<IWrapperProps> = (
  { haveMore, titles, titlesElements, children }
) => (
  <ScrollableTableWrapper>
    <Table
      className={styles.table}
      unstackable
      singleLine
      attached={haveMore ? 'top' : undefined}
    >
      { (titles && !titlesElements) && (
        <TableHeader className={styles.table_header}>
          <TableRow className={styles.table_row}>
            {titles.map(title => (
              <TableHeaderCell
                className={styles.table_header_cell}
                key={title}
              >
                <TableColumnTitle>
                  {title}
                </TableColumnTitle>
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
      )}
      { (titlesElements && !titles) && (
        <TableHeader className={styles.table_header}>
          <TableRow className={styles.table_row}>
            {titlesElements.map(titleElement => (
              <TableHeaderCell
                className={styles.table_header_cell}
                key={titleElement.key}
              >
                <TableColumnTitle>
                  {titleElement}
                </TableColumnTitle>
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
      )}
      <TableBody className={styles.table_body}>
        {children}
      </TableBody>
    </Table>
  </ScrollableTableWrapper>
);

export default TableWrapper;
