import React from 'react';
import { LabelGroup } from 'semantic-ui-react';
import moment from 'moment';
import { colorOfScheduleStatus, iconOfScheduleStatus } from '@models/domain/schedule/ScheduleStatus';
import { colorOfScheduleType, iconOfScheduleType, ScheduleType } from '@models/domain/schedule/ScheduleType';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/Bookings/model/ScheduleResponse';
import { DATE_FORMAT } from '@helpers/date.helper';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';
import LabelWrapper from '@components/NewDesign/LabelWrapper';

export interface IScheduleRowProps {
  schedule: IScheduleResponseDto;
}

const ScheduleRow: React.FC<IScheduleRowProps> = ({ schedule }) => (
  <>
    <LabelGroupWrapper
      wrapper={LabelGroup}
      importantTitle
      title="Time"
      value={(
        <LabelWrapper>
          {moment(schedule.scheduledTime).format(`${DATE_FORMAT}, HH:mm A`)}
        </LabelWrapper>
      )}
    />
    <LabelGroupWrapper
      wrapper={LabelGroup}
      importantTitle
      title="Status"
      value={(
        <LabelWrapper
          content={schedule.status}
          color={colorOfScheduleStatus(schedule.status)}
          icon={iconOfScheduleStatus(schedule.status)}
        />
      )}
    />
    <LabelGroupWrapper
      wrapper={LabelGroup}
      importantTitle
      title="Type"
      value={(
        <LabelWrapper
          content={ScheduleType[schedule.type]}
          color={colorOfScheduleType(schedule.type)}
          icon={iconOfScheduleType(schedule.type)}
        />
      )}
    />
    {schedule.address1 && (
      <>
        <LabelGroupWrapper
          importantTitle
          title="Address Line 1"
          value={schedule.address1}
        />
        { schedule.address2 && (
          <LabelGroupWrapper
            importantTitle
            title="Address Line 2"
            value={schedule.address2}
          />
        )}
        <LabelGroupWrapper
          importantTitle
          title="City"
          value={schedule.city}
        />
        <LabelGroupWrapper
          importantTitle
          title="State"
          value={schedule.state}
        />
      </>
    )}
    {schedule.notes && (
      <LabelGroupWrapper
        importantTitle
        title="Notes"
        value={schedule.notes}
      />
    )}
  </>
);

export default ScheduleRow;
