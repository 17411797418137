import React, { useState } from 'react';
import * as Yup from 'yup';
import { FormikProps, withFormik } from 'formik';
import { IBindingCallback1 } from '@models/Callbacks';
import { passwordValidation, repeatPasswordValidation } from '@screens/Authorization/components/validation';
import { Form, Icon } from 'semantic-ui-react';
import ErrorPopup from '@components/FormErrorPopup';
import FormikInput from '../../../../components/formik/Input';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import Caption4 from '@components/NewDesign/Typography/Caption/Caption4';
import {
  CONFIRM_PASSWORD_REQUIREMENTS,
  PASSWORD_REQUIREMENTS
} from '@screens/Authorization/components/RegistrationForm';
import styles from './styles.module.scss';

export interface IResetPasswordFormProps {
  resetLoading: boolean;
  onResetClick: IBindingCallback1<string>;
}

const validationSchema = Yup.object().shape({
  password: passwordValidation,
  repeatPassword: repeatPasswordValidation
});

interface IFormValues {
  password: string;
  repeatPassword: string;
}

const ResetPasswordForm: React.FC<IResetPasswordFormProps & FormikProps<IFormValues>> = (
  {
    values, errors, handleChange, resetLoading,
    handleSubmit, isValid
  }
) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [repeatPasswordShown, setRepeatPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleRepeatPasswordVisibility = () => {
    setRepeatPasswordShown(!repeatPasswordShown);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ErrorPopup
        open={false}
        label={errors.password}
        component={(
          <div className={styles.password_group}>
            <div className={styles.passwordWrapper}>
              <FormikInput
                propsOrFieldName={{
                  name: 'password',
                  placeholder: 'Enter password',
                  type: passwordShown ? 'text' : 'password'
                }}
                semanticProps={{
                  autoFocus: true
                }}
              />
              <Icon name="eye" className={styles.showPassIcon} onClick={togglePasswordVisibility} />
            </div>
            <Caption4 className={styles.inputGuide}>
              {PASSWORD_REQUIREMENTS}
            </Caption4>
          </div>
        )}
      />
      <ErrorPopup
        open={false}
        label={errors.repeatPassword}
        component={(
          <div className={styles.password_group}>
            <div className={styles.passwordWrapper}>
              <FormikInput
                propsOrFieldName={{
                  name: 'repeatPassword',
                  type: repeatPasswordShown ? 'text' : 'password',
                  placeholder: 'Repeat password'
                }}
              />
              <Icon name="eye" className={styles.showPassIcon} onClick={toggleRepeatPasswordVisibility} />
            </div>
            <Caption4 className={styles.inputGuide}>
              {CONFIRM_PASSWORD_REQUIREMENTS}
            </Caption4>
          </div>
        )}
      />
      <div className={styles.button_container}>
        <PrimaryButton
          className={styles.confirm_button}
          type="submit"
          loading={resetLoading}
          disabled={!values.password || !values.repeatPassword || !isValid}
          content="Confirm"
        />
      </div>
    </Form>
  );
};

export default withFormik<IResetPasswordFormProps, IFormValues>({
  displayName: 'PasswordResetForm',
  validationSchema,
  handleSubmit: (values, { props }) => {
    props.onResetClick(values.password);
  }
})(ResetPasswordForm);
