import React, { useCallback, useMemo } from 'react';
import { SelectProps } from 'semantic-ui-react';
import { ICommonInputProps } from '@components/formik/common';
import FormikSelectBase from '@components/formik/select/SelectBase';
import { IBindingCallback2 } from '@models/Callbacks';

export interface IFormikSelectInlineProps extends ICommonInputProps<HTMLElement, SelectProps> {
  setFieldValue: IBindingCallback2<string, any>;
  setFieldTouched: IBindingCallback2<string, boolean>;
}

const FormikSelectInline: React.FC<IFormikSelectInlineProps> = props => {
  const { propsOrFieldName, setFieldValue, setFieldTouched } = props;

  const name = useMemo(
    () => (propsOrFieldName as any)?.name || propsOrFieldName,
    [propsOrFieldName]
  );

  const onChange = useCallback(
    (ev, { value }) => setFieldValue(name, value as any),
    [name, setFieldValue]
  );

  const onBlur = useCallback(
    () => setFieldTouched(name, true),
    [name, setFieldTouched]
  );

  return <FormikSelectBase onBlur={onBlur} onChange={onChange} {...props} />;
};

export default FormikSelectInline;
