import React from 'react';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import HostedByLabel from '@screens/BookingCheckout/components/BookingDetails/HostedByLabel';
import BuildingTitle from '@screens/BookingCheckout/components/BookingDetails/BuildingTitle';
import BuildingAddress from '@screens/BookingCheckout/components/BookingDetails/BuildingAddress';
import BuildingDescription from '@screens/BookingCheckout/components/BookingDetails/BuildingDescription';
import { IBindingCallback1 } from '@models/Callbacks';
import LikeButton from '@components/LikeButton';
import OwnerEditButton from '@components/EditBuildingButtons/OwnerEditButton';
import { AdminEditButton } from '@components/EditBuildingButtons/AdminEditButton';
import styles from './styles.module.scss';

export interface IBuildingHeaderInfoProps {
  building: IBuildingForDisplaying;
  toggleLike?: IBindingCallback1<string>;
  likeLoading?: boolean;
  hideMap?: boolean;
}

const BuildingHeaderInfo: React.FC<IBuildingHeaderInfoProps> = (
  { building, toggleLike, likeLoading, hideMap }
) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div>
        <HostedByLabel companyName={building?.companyName} />
        <BuildingTitle buildingName={building?.buildingName} />
        <BuildingAddress building={building} hideMap={hideMap} />
      </div>
      <div className={styles.actions}>
        <OwnerEditButton ownerId={building?.ownerId} buildingId={building?.id} />
        <AdminEditButton buildingId={building?.id} />
        {toggleLike && (
          <LikeButton
            liked={building?.favorite}
            size="massive"
            loading={likeLoading}
            onToggleLike={() => toggleLike(building?.id)}
          />
        )}
      </div>
    </div>
    <BuildingDescription text={building?.description} />
  </div>
);

export default BuildingHeaderInfo;
