import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setFooterLightBlue } from '@containers/AppRoute/reducer';

export interface IAppRouteProps extends RouteProps {
  // should the footer be lightBlue
  lightBlue?: boolean;
}

const AppRoute: React.FC<IAppRouteProps> = ({ lightBlue = true, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFooterLightBlue(!!lightBlue));
  }, [dispatch, lightBlue]);

  return (
    <Route {...props} />
  );
};

export default AppRoute;
