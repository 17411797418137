import React from 'react';
import { ISpaceTemplateDto } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import { fullCostToMonthlyPrice } from '@helpers/price.helper';
import styles from './styles.module.scss';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';
import { SpaceCategory } from '@models/domain/space/SpaceCategories';
import { SpaceType } from '@models/domain/space/SpaceTypes';

export interface ISpaceTemplateItemProps {
  spaceTemplate: ISpaceTemplateDto;
}

const SpaceTemplateItem: React.FC<ISpaceTemplateItemProps> = ({ spaceTemplate: st }) => {
  const textPair = (label: string, value) => (
    <LabelGroupWrapper title={label} value={value} />
  );

  return (
    <div className={styles.wrapper}>
      { textPair('Name', st.alias) }
      { textPair('Monthly Price', fullCostToMonthlyPrice(st.fullCost)) }
      { textPair('Type', SpaceType[st.type]) }
      { textPair('Category', SpaceCategory[st.category]) }
    </div>
  );
};

export default SpaceTemplateItem;
