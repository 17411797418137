import React from 'react';
import styles from './styles.module.scss';

const ScrollableTableWrapper: React.FC = (
  { children }
) => (
  <div className={styles.container}>
    {children}
  </div>
);

export default ScrollableTableWrapper;
