import { loadCreditCardsRoutine } from '@screens/BookingCheckout/routines';
import { ICreditCard } from '@screens/CreditCardConfiguration/model/CreditCard';
import { createReducer } from '@reduxjs/toolkit';

export interface IPaymentReducerState {
  creditCards: ICreditCard[];
}

const initialState: IPaymentReducerState = {
  creditCards: []
};

export const paymentReducer = createReducer(initialState, {
  [loadCreditCardsRoutine.SUCCESS]: (state, { payload }) => {
    state.creditCards = payload;
  }
});

