import React from 'react';
import {
  TableCell,
  TableCellProps,
  TableProps,
  TableRow,
  TableRowProps
} from 'semantic-ui-react';
import classNames from 'classnames';
import TableWrapper from '@components/NewDesign/Table/TableWrapper';
import styles from './styles.module.scss';

export interface INewTableProps {
  titles: string[];
  rows: Row[];
  tableProps?: TableProps;
}

type Row = {
  id: string;
  cells: Cell[];
  rowProps?: TableRowProps;
};

type Cell = {
  content: JSX.Element | string | React.FC;
  props?: TableCellProps;
};

const NewTable: React.FC<INewTableProps> = (
  { titles, rows, tableProps }
) => (
  <TableWrapper titles={titles}>
    {rows.map(row => (
      <TableRow {...row.rowProps} key={row.id} className={classNames(styles.table_row, row.rowProps.className)}>
        {row.cells.map((cell, index) => (
          <TableCell
            className={styles.table_cell}
            key={`${row.id}-${index.toExponential()}`}
            {...cell.props}
          >
            {cell.content}
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableWrapper>
);

export default NewTable;
