import React from 'react';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import WritingMessageForm, { IFormValues } from '@components/MessagesSection/WritingMessageForm';
import ModalWrapper from '@components/NewDesign/ModalWrapper';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';

interface IWritingMessageModal {
  onClose: IBindingAction;
  onSend: IBindingCallback1<ISendMessageDto>;
  sendLoading: boolean;
  receiverId: string;
  initValues?: Partial<IFormValues>;
  hideTopic?: boolean;
}

const WritingMessageModal: React.FC<IWritingMessageModal> = (
  { onClose, onSend, sendLoading, receiverId, initValues, hideTopic }
) => (
  <ModalWrapper open size="small" closeIcon onClose={onClose}>
    <Header3>Send message</Header3>
    <WritingMessageForm
      onSend={onSend}
      loading={sendLoading}
      receiverId={receiverId}
      initValues={initValues}
      hideTopic={hideTopic}
    />
  </ModalWrapper>
);

export default WritingMessageModal;
