import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import OrdersTable from '@screens/HavesDashboard/Orders/containers/OrdersTable';
import OrderDetailsModal from '@screens/HavesDashboard/Orders/components/OrderDetailsModal';
import {
  buildingName,
  isDisabled,
  orderStatusLabel,
  scheduledOn,
  serviceDetails,
  totalOrderedAmount,
  totalPrice
} from '@screens/NeedsDashboard/Orders/containers/OrdersPage';
import { setExpandedOrderRoutine } from '@screens/HavesDashboard/Orders/routines';
import styles from './styles.module.scss';

const OrdersPage: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const handleSetExpandedOrder = useCallback(orderDetails => () => {
    dispatch(setExpandedOrderRoutine.fulfill(orderDetails));
  }, [dispatch]);

  return (
    <>
      <DashboardPageWrapper title="Orders">
        <DashboardSectionWrapper>
          <OrdersTable
            tableProps={ordersDetails => ({
              titles: ['Service', 'Status', 'Building', 'Qty', 'Total', 'Scheduled On'],
              rows: ordersDetails.map(orderDetails => ({
                id: orderDetails.order.id,
                cells: [
                  { content: serviceDetails(orderDetails) },
                  { content: orderStatusLabel(orderDetails) },
                  { content: buildingName(orderDetails) },
                  { content: totalOrderedAmount(orderDetails) },
                  { content: totalPrice(orderDetails) },
                  { content: scheduledOn(orderDetails) }
                ],
                rowProps: {
                  onClick: handleSetExpandedOrder(orderDetails),
                  className: isDisabled(orderDetails.order) && styles.disabled
                }
              }))
            })}
          />
          <OrderDetailsModal />
        </DashboardSectionWrapper>
      </DashboardPageWrapper>
    </>
  );
};

export default OrdersPage;
