import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import { DropdownItemProps } from 'semantic-ui-react';
import { BookingStatus } from '@models/domain/BookingStatus';
import InputWrapper from '@components/NewDesign/Input';
import { useTranslation } from '@root/hooks/use-translation';
import { enumAsOptions } from '@helpers/enum.helper';
import { DropdownField } from '@components/NewDesign/DropdownField';
import { bookingStatusToTitle } from '@components/BookingStatusLabel';
import styles from './styles.module.scss';

const formatOption = (option: DropdownItemProps): DropdownItemProps => {
  const optionText = bookingStatusToTitle(option.text as BookingStatus);
  const lowerCase = optionText.toString().toLowerCase();
  const firstLetter = lowerCase.charAt(0).toUpperCase();
  const finalText = firstLetter + lowerCase.slice(1);
  return {
    ...option,
    text: finalText
  };
};

const FiltersBar = ({ filters, onUpdateFilters }) => {
  const { t } = useTranslation();

  const debouncedNameChange = debounce(buildingName => onUpdateFilters({ buildingName }), 600);
  const debouncedStatusChange = debounce(status => onUpdateFilters({ status }), 600);

  const handleChangeNameFilter = useCallback(event => {
    debouncedNameChange(event.target.value);
  }, [debouncedNameChange]);

  const handleChangeStatusFilter = useCallback((ev, { value }) => {
    debouncedStatusChange(value);
  }, [debouncedStatusChange]);

  return (
    <div className={styles.filters_bar__container}>
      <div className={styles.filters_bar__name_filter}>
        <InputWrapper
          fluid
          defaultValue={filters.buildingName}
          placeholder={`${t('Building name')}...`}
          onChange={handleChangeNameFilter}
        />
      </div>
      <div className={styles.filters_bar__status_filter}>
        <DropdownField
          defaultValue={filters.status}
          onChange={handleChangeStatusFilter}
          placeholder={`${t('Status')}...`}
          multiple
          clearable
          fluid
          selection
          options={enumAsOptions(BookingStatus).map(formatOption)}
        />
      </div>
    </div>
  );
};

export { FiltersBar };
