import React from 'react';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import styles from './styles.module.scss';

export interface IBookingInfoTitleWithNoteProps {
  note: string;
}

const BookingInfoTitleWithNote: React.FC<IBookingInfoTitleWithNoteProps> = (
  { children, note }
) => (
  <div className={styles.container}>
    <BodyText1 className={styles.title}>
      {children}
    </BodyText1>
    <BodyText2 className={styles.note}>
      {note}
    </BodyText2>
  </div>
);

export default BookingInfoTitleWithNote;
