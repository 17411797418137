import { all } from 'redux-saga/effects';
import buildingDetailsPageSagas from '@screens/NeedsDashboard/BuildingDetails/containers/BuildingDetailsPage/sagas';
import {
  spacesAndServicesSectionSagas
} from '@screens/NeedsDashboard/BuildingDetails/containers/SpacesAndServicesSection/sagas';

export default function* buildingDetailsSagas() {
  yield all([
    buildingDetailsPageSagas(),
    spacesAndServicesSectionSagas()
  ]);
}
