import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import {
  loadHaveAccountsListDataRoutine,
  removeHaveAccountDataRoutine,
  setAddingBankAccountRoutine,
  setVerifyingBankAccountRoutine,
  addBankingDataRoutine,
  verifyBankAccountRoutine, setDefaultAccountDataRoutine, setBankAccountProcessingRoutine
} from '@screens/HavesDashboard/FinancialBanking/routines';
import { toastr } from 'react-redux-toastr';
import stripePaymentService from '@screens/BookingCheckout/services/payment.service';
import { IStripeBankingDataPayload } from '@screens/HavesDashboard/FinancialBanking/model/StripeBankingDataPayload';
import {
  bankingDataToStripeAccountRequest
} from '@screens/HavesDashboard/FinancialBanking/model/BankingData';
import { showModalError } from '@screens/ErrorModal/containers/ErrorModalPage/sagas';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';

export const tryLoadHaveAccountData = (routine: Routine) => function* loadHaveAccountData() {
  try {
    const resp = yield call(stripePaymentService.getAccount);
    yield put(routine.success(resp));
  } catch (e) {
    yield showModalError('Unable to load banking information', e, HAVES_DASHBOARD_ENDPOINTS.ACCOUNT);
    yield put(routine.failure(e?.message));
  }
};

export const trySetDefaultHaveAccountData = (setProcessingRoutine: Routine, routine: Routine) => function* setDefaultHaveAccountData({ payload }: Routine<any>) {
  try {
    yield put(setProcessingRoutine.fulfill(payload));
    const resp = yield call(stripePaymentService.setDefaultAccount, { accountId: payload });
    yield put(routine.success(resp));
    toastr.success('Success', 'Bank account is set as default!');
    yield put(loadHaveAccountsListDataRoutine.trigger());
  } catch (e) {
    toastr.error('Unable to set default bank account', e?.message);
    yield put(routine.failure(e?.message));
  } finally {
    yield put(setProcessingRoutine.fulfill(undefined));
  }
};

export const tryRemoveHaveAccountData = (setProcessingRoutine: Routine, routine: Routine) => function* removeHaveAccountData({ payload }: Routine<any>) {
  try {
    yield put(setProcessingRoutine.fulfill(payload));
    const resp = yield call(stripePaymentService.deleteAccount, { accountId: payload });
    yield put(routine.success(resp));
    toastr.success('Success', 'Banking details removed!');
    yield put(loadHaveAccountsListDataRoutine.trigger());
  } catch (e) {
    toastr.error('Unable to remove bank account', e?.message);
    yield put(routine.failure(e?.message));
  } finally {
    yield put(setProcessingRoutine.fulfill(undefined));
  }
};

function* createBankAccountToken({ details, stripe }: IStripeBankingDataPayload) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const token = yield call(stripe.createToken, 'bank_account', bankingDataToStripeAccountRequest(details));
  if (token.error) {
    throw new Error(token.error.message);
  }
  return token.token.id;
}

export const tryAddBankingData = (routine: Routine) => function* addFinancialBanking({ payload }: Routine<any>) {
  try {
    const token = yield createBankAccountToken(payload);
    yield call(stripePaymentService.addAccount, { token });
    yield put(setAddingBankAccountRoutine.fulfill(false));
    yield put(routine.success());
    toastr.success('Success', 'Banking details saved!');
    yield put(loadHaveAccountsListDataRoutine.trigger());
  } catch (e) {
    toastr.error('Unable to add bank account', e.message);
    yield put(routine.failure(e?.message));
  }
};

export const tryVerifyBankAccount = (routine: Routine) => function* verifyBankAccount({ payload }: Routine<any>) {
  try {
    yield call(stripePaymentService.verifyAccount, payload);
    yield put(setVerifyingBankAccountRoutine.fulfill(false));
    yield put(routine.success());
    toastr.success('Success', 'Bank account has been verified!');
    yield put(loadHaveAccountsListDataRoutine.trigger());
  } catch (e) {
    toastr.error('Unable to verify bank account', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export default function* financialBankingPageSagas() {
  yield all([
    yield takeEvery(loadHaveAccountsListDataRoutine.TRIGGER, tryLoadHaveAccountData(loadHaveAccountsListDataRoutine)),
    yield takeEvery(
      setDefaultAccountDataRoutine.TRIGGER,
      trySetDefaultHaveAccountData(setBankAccountProcessingRoutine, setDefaultAccountDataRoutine)
    ),
    yield takeEvery(
      removeHaveAccountDataRoutine.TRIGGER,
      tryRemoveHaveAccountData(setBankAccountProcessingRoutine, removeHaveAccountDataRoutine)
    ),
    yield takeEvery(addBankingDataRoutine.TRIGGER, tryAddBankingData(addBankingDataRoutine)),
    yield takeEvery(verifyBankAccountRoutine.TRIGGER, tryVerifyBankAccount(verifyBankAccountRoutine))
  ]);
}
