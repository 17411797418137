import React from 'react';
import styles from './styles.module.scss';
import { env } from '@root/env';
import { ILatLngLiteral } from '@models/domain/location';

export interface IGoogleMapProps {
  location?: ILatLngLiteral;
}

const GoogleMap: React.FC<IGoogleMapProps> = (
  { location }
) => {
  const src = `https://www.google.com/maps/embed/v1/place?key=${env.googleApiKey}&q=${location.lat},${location.lng}`;

  return (
    <div className={styles.container}>
      <iframe
        className={styles.map}
        src={src}
        allowFullScreen
        title="google-map-component"
      />
    </div>
  );
};

export default GoogleMap;
