const formatPhoneNumber = value => {
  if (!value) {
    return value;
  }

  const cleaned = value.replace(/\D/g, '').slice(0, 10);
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return (!match[2] ? match[1] : `${match[1]}-${match[2]}`) + (match[3] ? `-${match[3]}` : '');
  }

  return value;
};

export { formatPhoneNumber };
