import React, { useState } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import * as Yup from 'yup';
import { FormikProps, withFormik } from 'formik';
import { IBindingCallback1 } from '@models/Callbacks';
import FormikInput from '@components/formik/Input';
import { IBankingData } from '@screens/HavesDashboard/FinancialBanking/model/BankingData';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import FormGroupWrapper from '@components/NewDesign/Form/FormGroupWrapper';
import styles from './styles.module.scss';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import InfoPopup from '@components/NewDesign/InfoPopup';

export interface IFinancialBankingFormProps {
  saveChanges: IBindingCallback1<IBankingData>;
  savingLoading: boolean;
  className?: string;
}

export interface IFormValues {
  bankAccountNumber: string;
  bankRoutingNumber: string;
  beneficiaryName: string;
}

const validationSchema = Yup.object().shape({
  beneficiaryName: Yup.string()
    .trim()
    .required('Beneficiary name is required'),
  bankAccountNumber: Yup.string()
    .required('Bank Account Number is required')
    .matches(/^\d{9,}$/, 'Bank Account Number should contain at least 9 digits'),
  bankRoutingNumber: Yup.string()
    .required('Bank Routing Number is required')
    .matches(/^\d{9}$/, 'Bank Routing Number should contain 9 digits')
});

const FinancialBankingForm: React.FC<IFinancialBankingFormProps & FormikProps<IFormValues>> = (
  {
    handleSubmit, savingLoading, className, isValid
  }
) => {
  const [agreement, setAgreement] = useState(false);

  return (
    <Form name="bankingForm" onSubmit={handleSubmit} className={className}>
      <FormGroupWrapper widths="equal">
        <FormikInput
          propsOrFieldName={{
            name: 'bankAccountNumber',
            placeholder: 'Bank Account Number (9+ Digits)'
          }}
          semanticProps={{
            label: 'Bank Account Number',
            required: true
          }}
        />
        <FormikInput
          propsOrFieldName={{
            name: 'bankRoutingNumber',
            placeholder: 'Bank Routing Number (9 Digits)'
          }}
          semanticProps={{
            label: 'Bank Routing Number',
            required: true
          }}
        />
      </FormGroupWrapper>
      <FormGroupWrapper widths="equal">
        <FormikInput
          propsOrFieldName={{
            name: 'beneficiaryName',
            placeholder: 'Beneficiary Name'
          }}
          semanticProps={{
            label: 'Beneficiary Name',
            required: true
          }}
        />
      </FormGroupWrapper>
      <InfoPopup
        iconClassName={styles.beneficiary_info}
        content="The account beneficiary is the OFFICIAL name on the bank account."
      />
      <div className={styles.disclaimer} onClick={() => setAgreement(a => !a)}>
        <Checkbox checked={agreement} />
        <Caption2>
          I certify that this information is for a bank account for the organization
          that owns or leases the spaces being hosted on Shared Spaces,
          and NOT for an individual&apos;s personal bank account.
        </Caption2>
      </div>
      <div>
        <PrimaryButton
          className={styles.form_button}
          content="Save"
          type="submit"
          loading={savingLoading}
          disabled={!isValid || !agreement}
        />
      </div>
    </Form>
  );
};

export default withFormik<IFinancialBankingFormProps, IFormValues>({
  displayName: 'FinancialBankingForm',
  validationSchema,
  handleSubmit: ((values, { props }) => {
    props.saveChanges(values);
  })
})(FinancialBankingForm);
