import React, { memo } from 'react';
import { Select, SelectProps } from 'semantic-ui-react';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

const SelectWrapper: React.FC<SelectProps> = ({ placeholder, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.select_wrapper}>
      <Select placeholder={t(placeholder)} {...props} />
    </div>
  );
};
export default memo(SelectWrapper);
