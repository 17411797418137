import { callApi } from '@helpers/api.helper';
import { IGetInTouchData } from '@screens/static/GetInTouch/model/IGetInTouchData';
import { IPartnerFormData } from '@screens/static/Partner/model/IPartnerFormData';
import { IExpertFormData } from '@screens/static/Expert/model/IExpertFormData';
import { IHelpSearchRequest, IUnauthorizedHelpSearchRequest } from '@screens/BrowseSpaces/model/HelpSearchRequest';

export const submitContactUs = async (data: IGetInTouchData) => callApi({
  endpoint: 'form-mail/contactus',
  method: 'POST',
  requestData: {
    address1: data.address1 || '',
    address2: data.address2 || '',
    city: data.city || '',
    companyName: data.companyName,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    message: data.message,
    phone: data.phone,
    state: data.state || null,
    zip: data.zip || ''
  }
});
export const submitJoinOurTeam = async (formData: FormData) => callApi({
  endpoint: 'form-mail/joinus',
  method: 'POST',
  formData
});
export const submitPartnerPageForm = async (data: IPartnerFormData) => callApi({
  endpoint: 'form-mail/partner',
  method: 'POST',
  requestData: {
    address1: data.address1 || '',
    address2: data.address2 || '',
    city: data.city || '',
    companyName: data.companyName,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    message: data.message,
    phone: data.phone,
    typeOfSpace: data.typeOfSpace,
    approximateSquareFootage: data.approximateSquareFootage,
    state: data.state || null,
    zip: data.zip || ''
  }
});
export const submitExpertPageForm = async (data: IExpertFormData) => callApi({
  endpoint: 'form-mail/expert',
  method: 'POST',
  requestData: {
    address1: data.address1 || '',
    address2: data.address2 || '',
    city: data.city || '',
    companyName: data.companyName,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    message: data.message,
    phone: data.phone,
    myExpertise: data.myExpertise,
    myAvailability: data.myAvailability,
    state: data.state || null,
    zip: data.zip || ''
  }
});
export const submitHelpWithSearch = async (data: IHelpSearchRequest) => callApi({
  endpoint: 'form-mail/help',
  method: 'POST',
  requestData: {
    ...data
  }
});
export const submitUnauthorizedHelpWithSearch = async (data: IUnauthorizedHelpSearchRequest) => callApi({
  endpoint: 'form-mail/unauthorized-help',
  method: 'POST',
  requestData: {
    ...data
  }
});
