import React from 'react';
import styles from '@screens/Authorization/components/SocialAuthForm/styles.module.scss';
import { GOOGLE_AUTH_URL } from '@screens/Authorization/constants';
import TransparentButton from '@components/NewDesign/Button/TransparentButton';

const SocialAuthForm: React.FC = () => {
  const handleGoogle = () => {
    window.location.href = GOOGLE_AUTH_URL;
  };

  // Facebook login button temporary hidden
  // const handleFacebook = () => {
  //   window.location.href = FACEBOOK_AUTH_URL;
  // };

  return (
    <div className={styles.socials_container}>
      <TransparentButton
        className={styles.socials_button_google}
        icon="google"
        content="Continue with Google"
        onClick={handleGoogle}
      />
      {/* <TransparentButton
        className={styles.socials_button_facebook}
        icon="facebook official"
        content="Continue with Facebook"
        onClick={handleFacebook}
      />*/}
    </div>
  );
};

export default SocialAuthForm;
