import React, { useCallback } from 'react';
import classNames from 'classnames';
import { TableCell, TableRow } from 'semantic-ui-react';
import moment from 'moment';
import { DATE_FORMAT } from '@helpers/date.helper';
import { IMessageDto, MessageDirection } from '@models/domain/message/MessageDto';
import { IBindingCallback1 } from '@models/Callbacks';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';
import TableRegularContent from '@components/NewDesign/Typography/FormAndTable/TableRegularContent';
import styles from './styles.module.scss';

interface IMessageRowProps {
  message: IMessageDto;
  readMessage: IBindingCallback1<string>;
  setMessageFull: IBindingCallback1<IMessageDto | undefined>;
}

const MessageRow: React.FC<IMessageRowProps> = ({ message, readMessage, setMessageFull }) => {
  const handleClick = useCallback(() => {
    setMessageFull(message);
    if (message.direction === MessageDirection.INCOMING) {
      readMessage(message.id);
    }
  }, [message, setMessageFull, readMessage]);

  const { companion } = message;

  const fullName = `${companion.firstName ?? ''} ${companion.lastName ?? ''}`;

  return (
    <TableRow
      key={message.id}
      onClick={handleClick}
      className={classNames(
        !message.read && message.direction === MessageDirection.INCOMING && styles.pending,
        styles.row
      )}
    >
      <TableCell>
        <div className={styles.senderWrapper}>
          <TableRegularContent>
            {message.direction === MessageDirection.INCOMING
              ? 'From: '
              : 'To: '}
          </TableRegularContent>
          <TableRegularContent disableTranslation className={styles.overflow}>
            {fullName}
          </TableRegularContent>
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.topicWrapper}>
          <TableImportantContent disableTranslation className={styles.overflow}>
            {message.topic}
          </TableImportantContent>
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.textWrapper}>
          <TableRegularContent disableTranslation className={styles.overflow}>
            {message.text}
          </TableRegularContent>
        </div>
      </TableCell>
      <TableCell>{moment(message.sentAt).format(`${DATE_FORMAT}, HH:mm`)}</TableCell>
    </TableRow>
  );
};

export default MessageRow;
