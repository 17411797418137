import React from 'react';
import Header4 from '@components/NewDesign/Typography/Headers/Header4';
import styles from './styles.module.scss';

export interface IDashboardSectionWrapperProps {
  title?: string;
  rightElement?: JSX.Element | undefined;
}

const DashboardSectionWrapper: React.FC<IDashboardSectionWrapperProps> = (
  { children, title, rightElement }
) => (
  <div className={styles.container}>
    { title && (
      <div className={styles.header_section}>
        <Header4 className={styles.header}>{title}</Header4>
        { rightElement ?? '' }
      </div>
    ) }
    <div className={styles.content}>
      {children}
    </div>
  </div>
);

export default DashboardSectionWrapper;
