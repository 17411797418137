import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { ISpaceBooked } from '@screens/BookingCheckout/model/BookingCheckout';
import { toPrice } from '@helpers/price.helper';
import BookingOneSpaceMetaData from '@screens/BookingCheckout/components/BookingDetails/BookingOneSpaceMetaData';
import { IBriefBookingDto, IPriceWithCurrency } from '@models/domain/Booking';
import { IBookingPaymentPeriods } from '@screens/BookingCheckout/helpers/bookingPeriods.helper';
import Caption3 from '@components/NewDesign/Typography/Caption/Caption3';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import styles from './styles.module.scss';

export interface IBookingOneSpaceSummaryProps {
  space: ISpaceBooked;
  booking: IBriefBookingDto;
  periods: IBookingPaymentPeriods;
  className?: string;
}

const BookingOneSpaceSummary: React.FC<IBookingOneSpaceSummaryProps> = (
  { space, booking, periods, className }
) => {
  const startMoment = moment(booking.startingDate);
  const endMoment = moment(booking.endingDate);

  const generalDatesStartInfo = `FROM ${startMoment.format('MMMM Do, YYYY')}`;
  const generalDatesEndInfo = booking.endingDate && ` TO ${endMoment.format('MMMM Do, YYYY')}`;

  const periodDatesFirst = periods.first
    && `${periods.first.start.format('MMMM Do')}-${periods.first.end.format('Do, YYYY')}`;
  const periodDatesSecond = periods.second
    && `Starting ${periods.second.format('MMMM Do, YYYY')}`;
  const periodDatesThird = periods.third
    && `${periods.third.start.format('MMMM Do')}-${periods.third.end.format('Do, YYYY')}`;

  const oneSpacePriceToSum = (price: number): IPriceWithCurrency => ({
    amount: price * space.amountBooked,
    currency: space.currency
  });

  const periodPriceFirst = periods.first
    && (periods.first.end.diff(startMoment, 'days') + 1) * space.pricePerDay;
  const periodPriceSecond = periods.second
    && space.pricePerMonth;
  const periodPriceThird = periods.third
    && (endMoment.diff(periods.third.start, 'days') + 1) * space.pricePerDay;

  return (
    <div className={classNames(styles.container, className)}>
      <BookingOneSpaceMetaData space={space} />
      <div className={styles.content}>
        <BodyText2 className={styles.datesDescription}>
          {generalDatesStartInfo}
          {generalDatesEndInfo}
        </BodyText2>
        {periods.first && (
          <div className={styles.paymentLine}>
            <div className={styles.text}>
              <Caption3>
                {periodDatesFirst}
                <span className={styles.type}>Prorated</span>
              </Caption3>
            </div>
            <div className={styles.dots} />
            <div className={styles.price}>
              <Caption3>{toPrice(oneSpacePriceToSum(periodPriceFirst))}</Caption3>
            </div>
          </div>
        )}
        {periods.second && (
          <div className={styles.paymentLine}>
            <div className={styles.text}>
              <Caption3>
                {periodDatesSecond}
                <span className={styles.type}>Monthly</span>
              </Caption3>
            </div>
            <div className={styles.dots} />
            <div className={styles.price}>
              <Caption3>{toPrice(oneSpacePriceToSum(periodPriceSecond))}</Caption3>
            </div>
          </div>
        )}
        {periods.third && (
          <div className={styles.paymentLine}>
            <div className={styles.text}>
              <Caption3>
                {periodDatesThird}
                <span className={styles.type}>Prorated</span>
              </Caption3>
            </div>
            <div className={styles.dots} />
            <div className={styles.price}>
              <Caption3>{toPrice(oneSpacePriceToSum(periodPriceThird))}</Caption3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingOneSpaceSummary;
