import React from 'react';
import classNames from 'classnames';
import { Popup, PopupProps } from 'semantic-ui-react';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import styles from './styles.module.scss';

const PopupWrapper: React.FC<PopupProps> = props => {
  const { className, children, content, ...rest } = props;

  const contentElement = children ?? content;
  const isNumberOrString = typeof contentElement === 'number' || typeof contentElement === 'string';

  return (
    <Popup {...rest} className={classNames(className, styles.container)}>
      {isNumberOrString ? (
        <Caption2>
          {contentElement}
        </Caption2>
      ) : contentElement}
    </Popup>
  );
};

export default PopupWrapper;
