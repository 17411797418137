import { combineReducers } from 'redux';
import { RootState } from '@root/store';
import { reducerCreator } from '@helpers/reducer.helper';
import { financialBankingReducer } from '@screens/HavesDashboard/FinancialBanking/containers/FinancialBankingPage/reducer';
/* PlopJS import placeholder. Do not remove */
import { setBankAccountProcessingRoutine } from '@screens/HavesDashboard/FinancialBanking/routines';
import { setDefaultAccountDataRoutine } from '@screens/HavesDashboard/FinancialBanking/routines';
import { verifyBankAccountRoutine } from '@screens/HavesDashboard/FinancialBanking/routines';
import { setVerifyingBankAccountRoutine } from '@screens/HavesDashboard/FinancialBanking/routines';
import { setAddingBankAccountRoutine } from '@screens/HavesDashboard/FinancialBanking/routines';
import { removeHaveAccountDataRoutine } from '@screens/HavesDashboard/FinancialBanking/routines';
import { addBankingDataRoutine, loadHaveAccountsListDataRoutine } from '@screens/HavesDashboard/FinancialBanking/routines';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  setBankAccountProcessingRequest: reducerCreator([setBankAccountProcessingRoutine.TRIGGER]),
  setDefaultAccountDataRequest: reducerCreator([setDefaultAccountDataRoutine.TRIGGER]),
  verifyBankAccountRequest: reducerCreator([verifyBankAccountRoutine.TRIGGER]),
  setVerifyingBankAccountRequest: reducerCreator([setVerifyingBankAccountRoutine.TRIGGER]),
  setUpdatingBankAccountRequest: reducerCreator([setAddingBankAccountRoutine.TRIGGER]),
  removeHaveAccountDataRequest: reducerCreator([removeHaveAccountDataRoutine.TRIGGER]),
  loadHaveAccountsListDataRequest: reducerCreator([loadHaveAccountsListDataRoutine.TRIGGER]),
  addBankingDataRequest: reducerCreator([addBankingDataRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  data: financialBankingReducer
});

const reqs = (state: RootState) => state.havesDashboard.financialBanking.requests;
const data = (state: RootState) => state.havesDashboard.financialBanking.data;

export const extractHaveAccountsListData = state => data(state).accountsListData;
export const extractAddingBankAccount = state => data(state).addingBankAccount;
export const extractVerifyingBankAccount = state => data(state).verifyingBankAccount;
export const extractProcessingBankAccountId = state => data(state).processingId;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractSetBankAccountProcessingLoading = state => reqs(state).setBankAccountProcessingRequest.loading;
export const extractSetBankAccountProcessingError = state => reqs(state).setBankAccountProcessingRequest.error;
export const extractSetDefaultAccountDataLoading = state => reqs(state).setDefaultAccountDataRequest.loading;
export const extractSetDefaultAccountDataError = state => reqs(state).setDefaultAccountDataRequest.error;
export const extractVerifyBankAccountLoading = state => reqs(state).verifyBankAccountRequest.loading;
export const extractVerifyBankAccountError = state => reqs(state).verifyBankAccountRequest.error;
export const extractSetVerifyingBankAccountLoading = state => reqs(state).setVerifyingBankAccountRequest.loading;
export const extractSetVerifyingBankAccountError = state => reqs(state).setVerifyingBankAccountRequest.error;
export const extractSetUpdatingAccountLoading = state => reqs(state).setUpdatingBankAccountRequest.loading;
export const extractSetUpdatingAccountError = state => reqs(state).setUpdatingBankAccountRequest.error;
export const extractRemoveHaveAccountDataLoading = state => reqs(state).removeHaveAccountDataRequest.loading;
export const extractRemoveHaveAccountDataError = state => reqs(state).removeHaveAccountDataRequest.error;
export const extractLoadHaveAccountsListDataLoading = state => reqs(state).loadHaveAccountsListDataRequest.loading;
export const extractLoadHaveAccountsListDataError = state => reqs(state).loadHaveAccountsListDataRequest.error;
export const extractAddBankingDataLoading = state => reqs(state).addBankingDataRequest.loading;
export const extractAddBankingDataError = state => reqs(state).addBankingDataRequest.error;
