import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { IBindingCallback1 } from '@models/Callbacks';
import Checkout from '@screens/Checkout/containers/CheckoutPage';
import { IServiceOrderCheckoutData } from '@screens/ServiceOrderCheckout/model/ServiceOrderCheckout';
import { ServiceOrderStatus } from '@models/domain/additional_service/ServiceOrderStatus';
import { loadServiceOrderDetailsRoutine } from '@screens/ServiceOrderCheckout/routines';
import {
  extractLoadServiceOrderDetailsLoading,
  extractServiceOrderDetails
} from '@screens/ServiceOrderCheckout/reducers';
import ServiceOrderDetailsCheckoutStep
  from '@screens/ServiceOrderCheckout/containers/ServiceOrderCheckoutPage/ServiceOrderDetailsCheckoutStep';
import SubmissionAndSummaryStep from '@screens/ServiceOrderCheckout/containers/ServiceOrderCheckoutPage/SubmissionAndSummaryStep';
import ServiceOrderDetailsPage from '@screens/ServiceOrderCheckout/containers/ServiceOrderDetailsPage';
import useIsMount from '@root/hooks/use-is-mount';

export interface IServiceOrderProps extends IState, IActions {
}

interface IState {
  serviceOrderLoading: boolean;
  serviceOrderDetails: IServiceOrderCheckoutData;
}

interface IActions {
  loadServiceOrder: IBindingCallback1<string>;
}

const ServiceOrderRootPage: React.FC<IServiceOrderProps> = (
  { loadServiceOrder, serviceOrderDetails, serviceOrderLoading }
) => {
  const { id: serviceOrderId } = useParams<{id: string}>();
  useEffect(() => {
    loadServiceOrder(serviceOrderId);
  }, [serviceOrderId, loadServiceOrder]);

  const serviceOrderDetailsStep = proceedFromDetails => (
    <ServiceOrderDetailsCheckoutStep
      proceedToNextStep={proceedFromDetails}
    />
  );

  const submission = paymentMethod => (
    <SubmissionAndSummaryStep
      orderId={serviceOrderId}
      paymentMethod={paymentMethod}
    />
  );

  const isMount = useIsMount();
  const loading = isMount || serviceOrderLoading || !serviceOrderDetails;

  return (
    <>
      {loading && <Loader active inline="centered" />}
      {!loading && (
        serviceOrderDetails.serviceOrder.order.status === ServiceOrderStatus.PENDING ? (
          <Checkout
            createdAt={serviceOrderDetails.serviceOrder.order.createdAt}
            detailsCheckoutStepComponent={serviceOrderDetailsStep}
            submissionAndSummaryStepComponent={submission}
            buildingName={serviceOrderDetails.building.buildingName}
            buildingDescription={serviceOrderDetails.building.description}
          />
        ) : (
          <ServiceOrderDetailsPage
            orderWithServices={serviceOrderDetails.serviceOrder}
            booking={serviceOrderDetails.booking}
            building={serviceOrderDetails.building}
          />
        )
      )}
    </>
  );
};

const mapStateToProps: (state) => IState = state => ({
  serviceOrderLoading: extractLoadServiceOrderDetailsLoading(state),
  serviceOrderDetails: extractServiceOrderDetails(state)
});

const mapDispatchToProps: IActions = {
  loadServiceOrder: loadServiceOrderDetailsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrderRootPage);
