import React from 'react';
import { Form } from 'semantic-ui-react';
import s from '@screens/BookingCheckout/containers/BookingCheckoutPage/BookingDetailsCheckoutStep/styles.module.scss';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import TextAreaWrapper from '@components/NewDesign/TextArea';

export interface IOtherNeedsNotesProps {
  notesText: string;
  setNotesText: (text: string) => void;
}

const OtherNeedsNotes: React.FC<IOtherNeedsNotesProps> = ({ notesText, setNotesText }) => (
  <>
    <SubHeader3 className={s.details__other_needs_label}>Other needs</SubHeader3>
    <Caption2 className={s.details__other_needs_tip}>Any other accommodations? Let us know.</Caption2>
    <Form>
      <TextAreaWrapper
        placeholder="Other comments"
        disabled={false}
        onChange={(ev, { value }) => setNotesText(value as string)}
        value={notesText}
      />
    </Form>
  </>
);

export default OtherNeedsNotes;
