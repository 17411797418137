import React from 'react';
import classNames from 'classnames';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import styles from './styles.module.scss';

interface IErrorMessageContainerProps {
  errorMessage: string | undefined;
  className?: string;
}

const ErrorMessageContainer: React.FC<IErrorMessageContainerProps> = ({ errorMessage, className }) => (
  <>
    {errorMessage && (
    <div className={classNames(styles.messageContainer, className)}>
      <div className={styles.message}>
        <BodyText2>{errorMessage}</BodyText2>
      </div>
    </div>
    )}
  </>
);

export default ErrorMessageContainer;
