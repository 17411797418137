import React from 'react';
import backgroundImage from '@images/auth_screen_background.png';
import styles from './styles.module.scss';

const AuthPageBackground = () => (
  <div data-relative-input="true" id="scene" style={{ position: 'fixed' }}>
    <div className={styles.background_text_container} style={{ backgroundImage: `url(${backgroundImage})` }} />
  </div>
);

export { AuthPageBackground };
