import { combineReducers } from 'redux';
/* PlopJS import placeholder. Do not remove */
import {
  loadCreditCardsRoutine,
  setActiveStepRoutine
} from '@screens/Checkout/routines';
import { reducerCreator } from '@helpers/reducer.helper';
import { paymentReducer } from '@screens/BookingCheckout/containers/BookingCheckoutPage/PaymentInformationStep/reducer';
import { RootState } from '@root/store';
import { loadServiceOrderDetailsRoutine } from '@screens/ServiceOrderCheckout/routines';
import { checkoutReducer } from '@screens/Checkout/containers/CheckoutPage/reducer';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  loadServiceOrderDetailsRequest: reducerCreator([loadServiceOrderDetailsRoutine.TRIGGER]),
  loadCreditCardsRequest: reducerCreator([loadCreditCardsRoutine.TRIGGER]),
  setActiveStepRequest: reducerCreator([setActiveStepRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  payment: paymentReducer,
  checkout: checkoutReducer
});

const reqs = (state: RootState) => state.serviceOrderCheckout.requests;
const checkout = (state: RootState) => state.checkout.checkout;

export const extractCheckoutStep = state => checkout(state).step;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractLoadServiceOrderDetailsLoading = state => reqs(state).loadServiceOrderDetailsRequest.loading;
export const extractLoadServiceOrderDetailsError = state => reqs(state).loadServiceOrderDetailsRequest.error;
