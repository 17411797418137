import React from 'react';
import { useTranslation } from '@root/hooks/use-translation';
import { Message, MessageProps } from 'semantic-ui-react';

const MessageWithTranslate = ({ children, ...props }: MessageProps) => {
  const { t } = useTranslation();

  return (
    <Message {...props}>
      {t(children)}
    </Message>
  );
};

export { MessageWithTranslate };
