import { useRef, useEffect } from 'react';
/**
 * @description This hook will calculate whether the component is mounted or not for first time.
 * @returns boolean
 */
const useIsMount = (): boolean => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};
export default useIsMount;
