import React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import styles from './styles.module.scss';

export interface INeedNotesProps {
  notesText: string;
  marginTop?: boolean;
  marginBottom?: boolean;
  className?: string;
}

const NeedNotes: React.FC<INeedNotesProps> = (
  { notesText, className, marginTop, marginBottom }
) => {
  const component = !isEmpty(notesText) ? (
    <div
      className={classNames(
        styles.container,
        marginTop && styles.marginTop,
        marginBottom && styles.marginBottom,
        className
      )}
    >
      <SubHeader3 className={styles.your_notes_label}>Your notes:</SubHeader3>
      <BodyText2 className={styles.notes}>{notesText}</BodyText2>
    </div>
  ) : <></>;

  return component;
};

export default NeedNotes;
