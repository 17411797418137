import { combineReducers } from 'redux';
import { RootState } from '@root/store';
import { reducerCreator } from '@helpers/reducer.helper';
import { payoutsReducer } from '@screens/AdminDashboard/Payouts/containers/PayoutsPage/reducer';
/* PlopJS import placeholder. Do not remove */
import {
  doPayoutRoutine,
  loadPayoutsHistoryRoutine,
  loadPayoutsSummaryRoutine,
  setCurrentPageRoutine
} from '@screens/AdminDashboard/Payouts/routines';
import { payoutsHistoryReducer } from '@screens/AdminDashboard/Payouts/containers/PayoutsHistoryPage/reducer';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  doPayoutRequest: reducerCreator([doPayoutRoutine.TRIGGER]),
  loadPayoutsSummaryRequest: reducerCreator([loadPayoutsSummaryRoutine.TRIGGER]),
  loadPayoutsHistoryRequest: reducerCreator([loadPayoutsHistoryRoutine.TRIGGER]),
  setCurrentPageRequest: reducerCreator([setCurrentPageRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  summary: payoutsReducer,
  history: payoutsHistoryReducer
});

const reqs = (state: RootState) => state.adminDashboard.payouts.requests;
const summary = (state: RootState) => state.adminDashboard.payouts.summary;
const history = (state: RootState) => state.adminDashboard.payouts.history;

export const extractPayoutsSummary = state => summary(state).summary;
export const extractPayoutsHistory = state => history(state).items;
export const extractTotalPages = state => history(state).totalPages;
export const extractCurrentPage = state => history(state).page;
export const extractPageSize = state => history(state).pageSize;
export const extractOrders = state => history(state).items;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractDoPayoutLoading = state => reqs(state).doPayoutRequest.loading;
export const extractDoPayoutError = state => reqs(state).doPayoutRequest.error;
export const extractLoadPayoutsSummaryLoading = state => reqs(state).loadPayoutsSummaryRequest.loading;
export const extractLoadPayoutsSummaryError = state => reqs(state).loadPayoutsSummaryRequest.error;
export const extractLoadPayoutsHistoryLoading = state => reqs(state).loadPayoutsHistoryRequest.loading;
export const extractLoadPayoutsHistoryError = state => reqs(state).loadPayoutsHistoryRequest.error;
export const extractSetCurrentPageLoading = state => reqs(state).setCurrentPageRequest.loading;
export const extractSetCurrentPageError = state => reqs(state).setCurrentPageRequest.error;
