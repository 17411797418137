import React, { useCallback, useEffect, useState } from 'react';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import { Slider } from '@components/NewDesign/Slider';
import styles from './styles.module.scss';

interface IContentSliderProps {
  id?: string;
  title: string;
  rightTitleComponent?: React.ReactNode;
  content?: React.ReactNode;
  children?: React.ReactNode;
  open?: boolean;
}

const ContentSlider = ({ id, title, content, children, rightTitleComponent, open = false }: IContentSliderProps) => {
  const [isSliderActive, setIsSliderActive] = useState<boolean>(open);

  useEffect(() => {
    if (open === true) {
      setIsSliderActive(open);
    }
  }, [open]);

  const handleOpenSlider = useCallback(() => {
    setIsSliderActive(prev => !prev);
  }, []);

  return (
    <Slider
      titleComponent={(
        <SubHeader1
          disableWordBreak
          className={styles.slider__title}
        >
          {title}
        </SubHeader1>
      )}
      id={id}
      rightTitleComponent={rightTitleComponent}
      contentClassName={styles.slider__content}
      headerClassName={styles.slider__header}
      active={isSliderActive}
      onClick={handleOpenSlider}
    >
      {children ?? content}
    </Slider>
  );
};

export { ContentSlider };
