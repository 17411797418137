import React, { useState } from 'react';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import BuildingOnMaps from '@components/BuildingOnMaps';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import DividingLine from '@components/NewDesign/DividingLine';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import styles from './styles.module.scss';

export interface IBuildingDetailsProps {
  building: IBuildingForDisplaying;
  showDividingLine?: boolean;
  hideHeader?: boolean;
}

const BuildingDetails: React.FC<IBuildingDetailsProps> = ({
  building,
  showDividingLine = true,
  hideHeader
}) => {
  const [showMap, setShowMap] = useState(false);

  return (
    <>
      {showDividingLine && <DividingLine light marginTop marginBottom />}
      {!hideHeader && <SubHeader1 className={styles.subheader}>Building</SubHeader1>}
      <LabelGroupWrapper
        title="Name"
        value={building.buildingName}
        disableValueTranslation
      />
      <LabelGroupWrapper
        title="Company"
        value={building.companyName}
        disableValueTranslation
      />
      <LabelGroupWrapper
        title="Address"
        value={(
          <div className={styles.text_pair}>
            <Caption2 disableTranslation={!!building.location?.address} className={styles.address}>
              {building.location?.address || 'Unknown'}
            </Caption2>
            &nbsp;
            { building.location && (
              <SmallLinkButton
                to=""
                onClick={() => setShowMap(true)}
                className={styles.link}
                right
              >
                Open on a map
              </SmallLinkButton>
            )}
          </div>
        )}
      />
      <BuildingOnMaps showMap={showMap} setShowMap={setShowMap} building={building} />
    </>
  );
};

export default BuildingDetails;
