import { combineReducers } from 'redux';
import { RootState } from '@root/store';
import { reducerCreator } from '@helpers/reducer.helper';
import { bookingReceiptPageReducer } from '@screens/ReceiptPage/containers/BookingReceiptPage/reducer';
import {
  createOrderReceiptAdditionalServicesOrderRoutine,
  fetchBookingReceiptDataRoutine,
  fetchServiceOrderReceiptDataRoutine,
  loadBookingReceiptAdditionalServicesWithAvailabilityRoutine, loadOrderReceiptAdditionalServicesWithAvailabilityRoutine
} from '@screens/ReceiptPage/routines';
import { serviceOrderReceiptPageReducer } from '@screens/ReceiptPage/containers/ServiceOrderReceiptPage/reducer';
/* PlopJS import placeholder. Do not remove */
import { createBookingReceiptAdditionalServicesOrderRoutine } from '@screens/ReceiptPage/routines';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  createOrderReceiptAdditionalServicesOrderRequest: reducerCreator([createOrderReceiptAdditionalServicesOrderRoutine.TRIGGER]),
  loadOrderReceiptAdditionalServicesWithAvailabilityRequest: reducerCreator([loadOrderReceiptAdditionalServicesWithAvailabilityRoutine.TRIGGER]),
  createBookingReceiptAdditionalServicesOrderRequest: reducerCreator([createBookingReceiptAdditionalServicesOrderRoutine.TRIGGER]),
  loadBookingReceiptAdditionalServicesWithAvailabilityRequest: reducerCreator([loadBookingReceiptAdditionalServicesWithAvailabilityRoutine.TRIGGER]),
  loadBookingReceiptRequest: reducerCreator([fetchBookingReceiptDataRoutine.TRIGGER]),
  loadServiceOrderReceiptRequest: reducerCreator([fetchServiceOrderReceiptDataRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  bookingReceiptData: bookingReceiptPageReducer,
  serviceOrderReceiptData: serviceOrderReceiptPageReducer
});

const reqs = (state: RootState) => state.receiptPage.requests;
const bookingReceipt = (state: RootState) => state.receiptPage.bookingReceiptData;
const serviceOrderReceipt = (state: RootState) => state.receiptPage.serviceOrderReceiptData;

const bookingReceiptData = (state: RootState) => bookingReceipt(state)?.receiptData;
const serviceOrderReceiptData = (state: RootState) => serviceOrderReceipt(state)?.receiptData;

export const extractTransactionWithBookingDetails = state => bookingReceiptData(state)?.transaction;
export const extractBuilding = state => bookingReceiptData(state)?.building;
export const extractBookingWithSpaces = state => bookingReceiptData(state)?.bookingWithSpaces;
export const extractBookingReceiptLoading = state => reqs(state).loadBookingReceiptRequest.loading;

export const extractBookingReceiptAdditionalServicesWithAvailability = state => bookingReceipt(state).additionalServices;
export const extractOrderReceiptAdditionalServicesWithAvailability = state => serviceOrderReceipt(state).additionalServices;

export const extractTransaction = state => serviceOrderReceiptData(state)?.transaction;
export const extractServiceOrderReceiptBuilding = state => serviceOrderReceiptData(state)?.building;
export const extractServiceOrder = state => serviceOrderReceiptData(state)?.serviceOrder;
export const extractServiceOrderReceiptLoading = state => reqs(state).loadServiceOrderReceiptRequest.loading;

/* PlopJS request_extractor placeholder. Do not remove */
export const extractCreateBookingReceiptAdditionalServicesOrderLoading = state => reqs(state).createBookingReceiptAdditionalServicesOrderRequest.loading;
export const extractCreateBookingReceiptAdditionalServicesOrderError = state => reqs(state).createBookingReceiptAdditionalServicesOrderRequest.error;
export const extractLoadBookingReceiptAdditionalServicesWithAvailabilityLoading = state => reqs(state).loadBookingReceiptAdditionalServicesWithAvailabilityRequest.loading;
export const extractLoadBookingReceiptAdditionalServicesWithAvailabilityError = state => reqs(state).loadBookingReceiptAdditionalServicesWithAvailabilityRequest.error;
export const extractCreateOrderReceiptAdditionalServicesOrderLoading = state => reqs(state).createOrderReceiptAdditionalServicesOrderRequest.loading;
export const extractCreateOrderReceiptAdditionalServicesOrderError = state => reqs(state).createOrderReceiptAdditionalServicesOrderRequest.error;
export const extractLoadOrderReceiptAdditionalServicesWithAvailabilityLoading = state => reqs(state).loadOrderReceiptAdditionalServicesWithAvailabilityRequest.loading;
export const extractLoadOrderReceiptAdditionalServicesWithAvailabilityError = state => reqs(state).loadOrderReceiptAdditionalServicesWithAvailabilityRequest.error;
