import React from 'react';
import { Segment } from 'semantic-ui-react';
import HorizontalLogo from '@components/logos/HorizontalLetters';
import { LanguageSelect } from '@containers/LanguageSelect';
import { AuthPageBackground } from '@screens/Authorization/components/AuthPageBackground';
import styles from './styles.module.scss';

const AuthPageTemplate: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <AuthPageBackground />

      <Segment className={styles.form_segment}>
        <div className={styles.form_container}>
          <>
            <HorizontalLogo className={styles.logo} />
            <LanguageSelect wrapperClassName={styles.language_select_button} />
            {children}
          </>
        </div>
      </Segment>
    </div>
  );
};

export default AuthPageTemplate;
