import { dashboard, LIST_YOUR_SPACE_COMMON_ENDPOINT } from '@screens/HavesDashboard/Root/components/Routing/endpoints';

export const NEEDS_DASHBOARD_ENDPOINTS = {
  DASHBOARD: dashboard(''),
  MESSAGES: dashboard('messages'),
  ACCOUNT: dashboard('account'),
  FAVORITES: dashboard('favorites'),
  SPACES: dashboard('spaces'),
  TRANSACTIONS: dashboard('transactions'),
  BOOKINGS: dashboard('bookings'),
  BOOKING_DETAILS: id => dashboard(`bookings/${id}`),
  ORDERS: dashboard('orders'),
  ORDER_DETAILS: id => dashboard(`orders/${id}`),
  CARDS_CONFIG: dashboard('cards_config'),
  LIST_YOUR_SPACE: LIST_YOUR_SPACE_COMMON_ENDPOINT
};
