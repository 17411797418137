export enum SpaceType {
  WAREHOUSE = 'Warehouse',
  OFFICE = 'Office',
  PARKING = 'Parking',
  VIRTUAL_ADDRESS = 'Virtual Address',
}

export const spaceTypeKey = val => Object.keys(SpaceType).filter(k => SpaceType[k] === val)[0];

export const generateLabel = (amount: number, one: string, plural: string) => {
  const form = amount === 1 ? one : plural;
  return `${amount} ${form}`;
};

export const spaceTypeToAmountLabel = (amount: number, type: SpaceType): string => {
  switch (type) {
    case SpaceType.OFFICE:
      return generateLabel(amount, 'office', 'offices');
    case SpaceType.VIRTUAL_ADDRESS:
      return generateLabel(amount, 'virtual address', 'virtual addresses');
    case SpaceType.WAREHOUSE:
      return generateLabel(amount, 'pallet', 'pallets');
    case SpaceType.PARKING:
      return generateLabel(amount, 'parking place', 'parking places');
    default:
      return '';
  }
};
