import React from 'react';
import StaticPageWrapper from '@components/NewDesign/StaticPageWrapper';
import OurStoryBlock, { IOurStoryBlockProps } from '@screens/static/OurStory/components/OurStoryBlock';
import Image1 from '@images/our_story/Our-Story-1.png';
import Image2 from '@images/our_story/Our-Story-2.png';
import Image3 from '@images/our_story/Our-Story-3.png';
import Image4 from '@images/our_story/Our-Story-4.png';
import Image5 from '@images/our_story/Our-Story-5.png';
import BgImage1 from '@images/our_story/Spot 1 Background.png';
import BgImage2 from '@images/our_story/Spot 2 Background.png';
import BgImage3 from '@images/our_story/Spot 3 Background.png';
import BgImage4 from '@images/our_story/Spot 4 Background.png';
import BgImage5 from '@images/our_story/Spot 5 Background.png';
import Family from '@images/our_story/Family.png';
import MetaTags from '@components/MetaTags';

const ourStoryBlocks: IOurStoryBlockProps[] = [
  {
    title: 'From Humble Beginnings',
    paragraphs: [
      'Our journey began quite a long time ago—in the year 1980, actually.',
      'This is when Alex Canizales, a teenager at the time, immigrated to the United States '
      + 'from his war-torn homeland of El Salvador.',
      'He came to the U.S. full of hope and with an entrepreneuring mind, '
      + 'and in 1998 was able to realize his dream of starting his own business.'
    ],
    image: Image1,
    bgImage: BgImage1
  },
  {
    title: 'Two Decades of Experience',
    paragraphs: [
      'In the decades since then, Alex has started and run a few businesses successfully. '
      + 'Throughout the journey, he has been able to support his wife and 5 kids. '
      + 'One might say he has been living the American dream!',
      'He has grown businesses, made a comfortable living,'
      + 'and been able to financially help his family in their dreams as well.'
    ],
    image: Image2,
    bgImage: BgImage2,
    reflected: true,
    note: {
      text: 'In the beginning, there were a lot of long days. '
        + 'The kids would play at the warehouse, building forts our of '
        + 'empty boxes or taking rides in the industrial mop bucket '
        + 'while they waited for Alex to get done with work!',
      image: Family
    }
  },
  {
    title: 'A Problem was Consistent',
    paragraphs: [
      'In the more recent years, Alex has reflected on his entrepreneurial '
      + 'journey and noticed a gaping problem that he has combated '
      + 'these 20+ years: as he has leased large warehouse spaces '
      + 'constantly, his inventory is often shifting and rarely ever fills '
      + 'that space to capacity. That’s money down the drain.'
    ],
    image: Image3,
    bgImage: BgImage3
  },
  {
    title: 'Entrepreneurial Challenges',
    paragraphs: [
      'On the other end, he knows the challenges of starting a small business, '
      + 'and knows the financial strain it can be.',
      'Many aspiring entrepreneurs can’t afford the commitment of a long-term '
      + 'lease on a massive warehouse space (nor do they need all that space to '
      + 'start out with!)'
    ],
    image: Image4,
    bgImage: BgImage4,
    reflected: true
  },
  {
    title: 'We Deliver the Solution',
    paragraphs: [
      'Eureka! And so Shared Spaces began.',
      'Alex began his next entrepreneurial journey to enable the '
      + 'American dream so that others could enjoy the success and '
      + 'joy he has found in running his own businesses.',
      'Shared Spaces is a platform that enables dreamers to bring '
      + 'their ideas to fruition, opening doors to save money and '
      + 'invest in one’s business on an affordable, monthly basis by '
      + 'letting offices and warehouses share out their extra space to '
      + 'small businesses who need that space.'
    ],
    image: Image5,
    bgImage: BgImage5
  }
];

const OurStoryPage: React.FC = () => (
  <StaticPageWrapper
    title="Living the American Dream, and enabling you to live yours"
    moreMaxWidth
  >
    <MetaTags
      title="Our Story. Living the American Dream, and enabling you to live yours"
      withSuffix
      description="Our journey began quite a long time ago—in the year 1980, actually.
                This is when Alex Canizales, a teenager at the time, immigrated to the
                United States from his war-torn homeland of El Salvador."
    />
    {ourStoryBlocks.map(b => (
      <OurStoryBlock
        {...b}
      />
    ))}
  </StaticPageWrapper>
);

export default OurStoryPage;
