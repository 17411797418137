/* eslint-disable max-len */
import { createRoutine } from 'redux-saga-routines';

const createServiceOrderCheckoutRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`SERVICE_ORDER_CHECKOUT:${actionName}`);
const createServiceOrderDetailsRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`SERVICE_ORDER_DETAILS:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const createAdditionalServicesOrderRoutine = createServiceOrderCheckoutRoutine('CREATE_ADDITIONAL_SERVICES_ORDER');
export const loadAdditionalServicesWithAvailabilityRoutine = createServiceOrderCheckoutRoutine('LOAD_ADDITIONAL_SERVICES_WITH_AVAILABILITY');
export const editOrderNotesRoutine = createServiceOrderCheckoutRoutine('EDIT_ORDER_NOTES');
export const setTransactionsPageRoutine = createServiceOrderCheckoutRoutine('SET_TRANSACTIONS_PAGE');
export const fetchTransactionsRoutine = createServiceOrderCheckoutRoutine('FETCH_TRANSACTIONS');

export const fetchOrderPaymentRequirementsRoutine = createServiceOrderCheckoutRoutine<string>('FETCH_ORDER_PAYMENT_REQUIREMENTS');
export const toggleFavoriteRoutine = createServiceOrderCheckoutRoutine('TOGGLE_FAVORITE');
export const loadServiceOrderDetailsRoutine = createServiceOrderDetailsRoutine('LOAD_SERVICE_ORDER_DETAILS');
export const clearServiceOrderCheckoutDetailsRoutine = createServiceOrderCheckoutRoutine('CLEAR_SERVICE_ORDER_CHECKOUT_DETAILS');
