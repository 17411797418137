import { ITemplate2Amount } from '@screens/NeedsDashboard/Bookings/model/BookingDetailsDto';

const getBookedSpacesDetails = (templatesToAmounts: ITemplate2Amount[]): string => {
  const t2a = templatesToAmounts;
  return t2a.length > 1
    ? `${t2a.length} kinds of spaces`
    : t2a[0].spaceTemplate.alias;
};

const getBookedSpacesQty = (templatesToAmounts: ITemplate2Amount[]): string => templatesToAmounts
  .map(t2a => t2a.spaces.length)
  .reduce((prev, curr) => prev + curr).toString();

export {
  getBookedSpacesDetails,
  getBookedSpacesQty
};
