import { all, takeEvery } from 'redux-saga/effects';
import {
  addNewCardRoutine,
  loadCreditCardsRoutine
} from '@screens/BookingCheckout/routines';
import {
  tryAddNewCreditCard,
  tryLoadCreditCards
} from '@screens/CreditCardConfiguration/containers/CreditCardConfiguration/sagas';
import { trigger } from '@helpers/sagas.helper';

export default function* paymentStepSagas() {
  yield all([
    yield takeEvery(loadCreditCardsRoutine.TRIGGER, tryLoadCreditCards(loadCreditCardsRoutine)),
    yield takeEvery(addNewCardRoutine.TRIGGER, tryAddNewCreditCard(addNewCardRoutine)),
    yield takeEvery(addNewCardRoutine.SUCCESS, trigger(loadCreditCardsRoutine))
  ]);
}
