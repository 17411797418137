import React, { useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Dropdown, Icon } from 'semantic-ui-react';
import { IBindingAction } from '@models/Callbacks';
import { logOutRoutine } from '@screens/Authorization/routines';
import { extractIsUserLoggedIn, extractUserRoles } from '@screens/Authorization/reducers';
import { extractLocationPathname } from '@helpers/url.helper';
import HorizontalLogo from '@components/logos/HorizontalLetters';
import { extractIsFooterLightBlue } from '@containers/AppRoute/reducer';
import { Role } from '@screens/Authorization/models/Roles';
import {
  HAVES_DASHBOARD_ENDPOINTS,
  LIST_YOUR_SPACE_COMMON_ENDPOINT
} from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import HamburgerMenu from '@containers/Header/HamburgerMenu';
import { ADMIN_DASHBOARD_ENDPOINTS } from '@screens/AdminDashboard/Root/components/Routing/endpoints';
import styles from './styles.module.scss';
import NavLinkButton from '@components/NewDesign/Button/NavLinkButton';
import SubNavLinkButton from '@components/NewDesign/Button/SubNavLinkButton';
import ExternalNavLinkButton from '@components/NewDesign/Button/ExternalNavLinkButton';
import { STATIC_SITE_LINKS } from '@root/assets/links/static-site-links';
import ExternalSubNavLinkButton from '@components/NewDesign/Button/ExternalSubNavLinkButton';
import { LanguageSelect } from '@containers/LanguageSelect';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IHeaderProps {
  loggedIn: boolean;
  logOut: IBindingAction;
  hidden: boolean;
  blackListPaths: string[];
  stompClient: any;
}

const Header: React.FC<IHeaderProps> = ({ loggedIn, logOut, hidden, stompClient }) => {
  const handleLogOut = () => logOut();
  const role = useSelector(extractUserRoles)[0];

  useEffect(() => {
    if (stompClient && stompClient.connected) {
      stompClient.subscribe('/socket/newUser', data => { console.log(data.body); });
    }
  }, [stompClient]);

  const listYourSpaceLink = useMemo(() => {
    if (!loggedIn || !role) {
      return LIST_YOUR_SPACE_COMMON_ENDPOINT;
    }
    if (role === Role.HAVE) {
      return HAVES_DASHBOARD_ENDPOINTS.BUILDINGS;
    }
    if (role === Role.ADMIN) {
      return ADMIN_DASHBOARD_ENDPOINTS.BUILDINGS_AND_SPACES;
    }
    return null;
  }, [loggedIn, role]);

  return (
    <>
      {hidden ? null : (
        <>
          <div className={styles.container} id="navbar">
            <div className={classNames(styles.wrapper, 'content_wrapper')}>
              <HamburgerMenu
                role={role}
                loggedIn={loggedIn}
                listYourSpaceLink={listYourSpaceLink}
                handleLogOut={handleLogOut}
              />
              <div className={styles.left_group}>
                <NavLinkButton exact to={ENDPOINTS.BROWSE}>
                  Browse Spaces
                </NavLinkButton>
                {role !== Role.NEED && (
                  <NavLinkButton to={listYourSpaceLink}>
                    List Your Space
                  </NavLinkButton>
                )}
              </div>
              <div className={styles.center_group}>
                <div className={styles.horizontal_logo_link}>
                  <HorizontalLogo className={styles.logo} />
                </div>
              </div>
              <div className={styles.right_group}>
                <ExternalNavLinkButton to={STATIC_SITE_LINKS.HOW_IT_WORKS}>
                  How it Works
                </ExternalNavLinkButton>
                <Dropdown
                  item
                  trigger={<NavLinkButton to="">About Us</NavLinkButton>}
                  icon={null}
                  className={styles.dropdownWrapper}
                >
                  <Dropdown.Menu className={styles.menuWrapper}>
                    <ExternalSubNavLinkButton to={STATIC_SITE_LINKS.PARTNER_WITH_US} className={styles.subLink}>
                      Partner with Us
                    </ExternalSubNavLinkButton>
                    <ExternalSubNavLinkButton to={STATIC_SITE_LINKS.JOIN_OUR_TEAM} className={styles.subLink}>
                      Join our Team
                    </ExternalSubNavLinkButton>
                    <ExternalSubNavLinkButton to={STATIC_SITE_LINKS.OUR_STORY} className={styles.subLink}>
                      Our Story
                    </ExternalSubNavLinkButton>
                    <ExternalSubNavLinkButton to={STATIC_SITE_LINKS.CONTACT_US} className={styles.subLink}>
                      Contact Us
                    </ExternalSubNavLinkButton>
                  </Dropdown.Menu>
                </Dropdown>
                { loggedIn
                  ? (
                    <Dropdown
                      item
                      trigger={<NavLinkButton to=""><Icon name="user" /></NavLinkButton>}
                      icon={null}
                      className={styles.dropdownWrapper}
                    >
                      <Dropdown.Menu className={styles.menuWrapper}>
                        <SubNavLinkButton to={NEEDS_DASHBOARD_ENDPOINTS.DASHBOARD} className={styles.subLink}>
                          Dashboard
                        </SubNavLinkButton>
                        <ExternalSubNavLinkButton to={STATIC_SITE_LINKS.CONTACT_US} className={styles.subLink}>
                          Help
                        </ExternalSubNavLinkButton>
                        <SubNavLinkButton to="" onClick={handleLogOut} className={styles.subLink}>
                          Log Out
                        </SubNavLinkButton>
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                  : (
                    <NavLinkButton to="/login">Log in</NavLinkButton>
                  )}
                <LanguageSelect wrapperClassName={styles.language_select} />
              </div>
            </div>
          </div>
          <div className={styles.header_filler} />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  logOut: logOutRoutine
};

const mapStateToProps = (state, ownProps) => ({
  lightBlue: extractIsFooterLightBlue(state),
  loggedIn: extractIsUserLoggedIn(state),
  hidden: ownProps.blackListPaths.includes(extractLocationPathname(state)),
  stompClient: state.stompClient
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
