/* eslint-disable max-len */
import { createRoutine } from 'redux-saga-routines';

const createReceiptPageRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`RECEIPT_PAGE:${actionName}`);

export const fetchBookingReceiptDataRoutine = createReceiptPageRoutine('FETCH_BOOKING_RECEIPT_DATA');
export const fetchServiceOrderReceiptDataRoutine = createReceiptPageRoutine('FETCH_SERVICE_ORDER_RECEIPT_DATA');

/* PlopJS routine placeholder. Do not remove */
export const createBookingReceiptAdditionalServicesOrderRoutine = createReceiptPageRoutine('CREATE_BOOKING_RECEIPT_ADDITIONAL_SERVICES_ORDER');
export const createOrderReceiptAdditionalServicesOrderRoutine = createReceiptPageRoutine('CREATE_ORDER_RECEIPT_ADDITIONAL_SERVICES_ORDER');
export const loadBookingReceiptAdditionalServicesWithAvailabilityRoutine = createReceiptPageRoutine('LOAD_BOOKING_RECEIPT_ADDITIONAL_SERVICES_WITH_AVAILABILITY');
export const loadOrderReceiptAdditionalServicesWithAvailabilityRoutine = createReceiptPageRoutine('LOAD_ORDER_RECEIPT_ADDITIONAL_SERVICES_WITH_AVAILABILITY');
