import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { extractCheckoutStep } from '@screens/Checkout/reducers';
import { CheckoutStep } from '@screens/Checkout/model/CheckoutStep';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import PaymentInformationStep from '@screens/BookingCheckout/containers/BookingCheckoutPage/PaymentInformationStep';
import SliderStep from '@components/NewDesign/SliderStep';
import GeneralPageHeader from '@components/NewDesign/GeneralPageHeader';
import { IPaymentMethodWithTitle } from '@components/StripePaymentForm';
import Timer from '@components/NewDesign/Timer';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import { setActiveStepRoutine, showErrorCheckoutExpirationRoutine } from '@screens/Checkout/routines';
import styles from './styles.module.scss';
import MetaTags from '@components/MetaTags';

export interface ICheckoutProps {
  step: CheckoutStep;
  setStoreStep: IBindingCallback1<CheckoutStep>;
  createdAt: string;
  showExpirationError: IBindingAction;
  detailsCheckoutStepComponent: any;
  submissionAndSummaryStepComponent: any;
  buildingName: string;
  buildingDescription: string;
}

export const EXPIRATION_MINUTES = 15;

const Checkout: React.FC<ICheckoutProps> = (
  {
    step, setStoreStep, createdAt, detailsCheckoutStepComponent,
    submissionAndSummaryStepComponent, showExpirationError, buildingName, buildingDescription
  }
) => {
  const [activeStep, setActiveStep] = useState(step);
  const [detailsConfirmed, setDetailsConfirmed] = useState(false);
  const [selectedPM, setSelectedPM] = useState<IPaymentMethodWithTitle | undefined>();

  useEffect(() => {
    setActiveStep(step);
  }, [step]);

  useEffect(() => {
    if (activeStep === CheckoutStep.RESULT) {
      window.location.reload();
    }
  }, [activeStep]);

  // setting step to LOADING when unmount to prevent firing extra fetches before status is known
  useEffect(() => () => setStoreStep(CheckoutStep.LOADING), [setStoreStep]);

  const proceedFromDetails = useCallback(() => {
    setActiveStep(CheckoutStep.PAYMENT);
    setDetailsConfirmed(true);
  }, []);

  const proceedFromPayment = useCallback((paymentMethod: IPaymentMethodWithTitle) => {
    setSelectedPM(paymentMethod);
    if (paymentMethod) {
      setActiveStep(CheckoutStep.SUBMISSION);
    }
  }, []);

  const getExpirationDate = () => {
    const createdAtDate = new Date(createdAt);
    createdAtDate.setMinutes(createdAtDate.getMinutes() + EXPIRATION_MINUTES);
    return createdAtDate;
  };

  const handleTimerExpired = () => {
    showExpirationError();
  };

  return (
    <div className="content_wrapper">
      <MetaTags
        title={`Checkout | ${buildingName}`}
        withSuffix
        description={buildingDescription}
      />
      <GeneralPageHeader title="Checkout" />
      <div className={styles.timer_section}>
        <Timer expirationDate={getExpirationDate()} onExpiration={handleTimerExpired} />
        <BodyText1 className={styles.text}>
          We can only reserve your space and services for fifteen minutes before
          releasing it to other potential browsers to purchase.
        </BodyText1>
      </div>
      <SliderStep
        index={1}
        title="Confirm Details"
        active={activeStep === CheckoutStep.DETAILS}
        onClick={() => setActiveStep(
          activeStep === CheckoutStep.DETAILS ? null : CheckoutStep.DETAILS
        )}
      >
        {detailsCheckoutStepComponent(proceedFromDetails)}
      </SliderStep>
      <SliderStep
        index={2}
        title="Payment Information"
        active={activeStep === CheckoutStep.PAYMENT}
        onClick={() => setActiveStep(
          activeStep === CheckoutStep.PAYMENT ? null : CheckoutStep.PAYMENT
        )}
        disabled={!detailsConfirmed}
      >
        <PaymentInformationStep selectPM={proceedFromPayment} />
      </SliderStep>
      <SliderStep
        index={3}
        title="Submission & Summary"
        active={activeStep === CheckoutStep.SUBMISSION}
        onClick={() => setActiveStep(
          activeStep === CheckoutStep.SUBMISSION ? null : CheckoutStep.SUBMISSION
        )}
        disabled={!selectedPM}
      >
        {submissionAndSummaryStepComponent(selectedPM)}
      </SliderStep>
    </div>
  );
};

const mapStateToProps = state => ({
  step: extractCheckoutStep(state)
});

const mapDispatchToProps = {
  setStoreStep: setActiveStepRoutine.fulfill,
  showExpirationError: showErrorCheckoutExpirationRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
