import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { IBindingCallback1 } from '@models/Callbacks';
import { registerRoutine } from '@screens/Authorization/routines';
import { isRegisterLoading } from '@screens/Authorization/reducers';
import RegistrationForm from '@screens/Authorization/components/RegistrationForm';
import { IRegistrationData } from '@screens/Authorization/models/RegistrationData';
import SocialAuthForm from '@screens/Authorization/components/SocialAuthForm';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import DividingLine from '@components/NewDesign/DividingLine';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import styles from './styles.module.scss';
import MetaTags from '@components/MetaTags';

export interface IRegistrationPageProps {
  register: IBindingCallback1<IRegistrationData>;
  registerLoading: boolean;
}

const RegistrationPage: React.FC<IRegistrationPageProps> = ({ register, registerLoading }) => {
  const registerCallback = useCallback((registrationData: IRegistrationData) => {
    register(registrationData);
  }, [register]);

  return (
    <>
      <MetaTags title="Register" withSuffix />
      <SubHeader3 marginBottom className={styles.header}>Create Your Account</SubHeader3>
      <RegistrationForm onRegisterClick={registerCallback} registrationLoading={registerLoading} />
      <DividingLine className={styles.dividing_line} />
      <SubHeader3 marginBottom className={styles.header}>Or Register with an External Account</SubHeader3>
      <SocialAuthForm />
      <div className={styles.register_button_wrapper}>
        <GoToButton to={ENDPOINTS.LOGIN}>ALREADY REGISTERED? LOG IN</GoToButton>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  registerLoading: isRegisterLoading(state)
});

const mapDispatchToProps = {
  register: registerRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
