import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IPayoutsSummary } from '@screens/AdminDashboard/Payouts/models/PayoutsSummary';
import { loadPayoutsSummaryRoutine } from '@screens/AdminDashboard/Payouts/routines';

export interface IPayoutsReducerState {
  summary?: IPayoutsSummary;
}

const initialState: IPayoutsReducerState = {
};

export const payoutsReducer = createReducer(initialState, {
  [loadPayoutsSummaryRoutine.SUCCESS]: (
    state, { payload }: PayloadAction<IPayoutsSummary>
  ) => {
    state.summary = payload;
  }
});
