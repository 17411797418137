import React from 'react';
import classNames from 'classnames';
import { TextArea, TextAreaProps } from 'semantic-ui-react';
import { TEXTAREA_MAX_LENGTH } from '@helpers/validation.helper';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

const TextAreaWrapper: React.FC<TextAreaProps> = ({ placeholder, error, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.textarea, { [styles.error]: error })}>
      <TextArea placeholder={t(placeholder)} {...props} maxLength={TEXTAREA_MAX_LENGTH} />
    </div>
  );
};

export default TextAreaWrapper;
