import React from 'react';
import { IPayoutsSummary } from '@screens/AdminDashboard/Payouts/models/PayoutsSummary';
import { toPrice } from '@helpers/price.helper';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import styles from './styles.module.scss';

export interface IPayoutsSummaryInformationProps {
  summary: IPayoutsSummary;
}

const PayoutsSummaryInformation: React.FC<IPayoutsSummaryInformationProps> = (
  { summary }
) => (
  <div className={styles.container}>
    <div className={styles.balanceWrapper}>
      <div className={styles.balanceBlock}>
        <Caption2 className={styles.balanceTitle}>Available Funds</Caption2>
        <SubHeader1 className={styles.balancePrice}>
          {toPrice(summary.available)}
        </SubHeader1>
      </div>
      <div className={styles.balanceBlock}>
        <Caption2 className={styles.balanceTitle}>Pending Funds</Caption2>
        <SubHeader1 className={styles.balancePrice}>
          {toPrice(summary.pending)}
        </SubHeader1>
      </div>
      <div className={styles.balanceBlock}>
        <Caption2 className={styles.balanceTitle}>Available to Pay Out</Caption2>
        <SubHeader1 className={styles.balancePrice}>
          {toPrice(summary.availablePayoutVolume)}
        </SubHeader1>
      </div>
      <div className={styles.balanceBlock}>
        <Caption2 className={styles.balanceTitle}>Pending to Pay Out</Caption2>
        <SubHeader1 className={styles.balancePrice}>
          {toPrice(summary.pendingPayoutVolume)}
        </SubHeader1>
      </div>
      <div className={styles.balanceBlock}>
        <Caption2 className={styles.balanceTitle}>Paid Out</Caption2>
        <SubHeader1 className={styles.balancePrice}>
          {toPrice(summary.donePayoutVolume)}
        </SubHeader1>
      </div>
    </div>
  </div>
);

export default PayoutsSummaryInformation;
