import React from 'react';
import { IBindingCallback1 } from '@models/Callbacks';
import ConfirmModal from '@components/NewDesign/ConfirmModal';

export interface IDeleteConfirmProps {
  pendingDelete: string;
  setPendingDelete: React.Dispatch<React.SetStateAction<string>>;
  deleteImage: IBindingCallback1<string>;
}

const DeleteConfirm: React.FC<IDeleteConfirmProps> = (
  { setPendingDelete, pendingDelete, deleteImage }
) => (
  <ConfirmModal
    open={!!pendingDelete}
    onCancel={() => setPendingDelete(undefined)}
    onConfirm={() => {
      deleteImage(pendingDelete);
      setPendingDelete(undefined);
    }}
    header="Delete"
  />
);

export default DeleteConfirm;
