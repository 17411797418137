import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { fetchBookingReceiptDataRoutine, loadBookingReceiptAdditionalServicesWithAvailabilityRoutine } from '@screens/ReceiptPage/routines';
import { IBookingReceiptData } from '@screens/ReceiptPage/model/BookingReceipt';

import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';

export interface IBookingReceiptPageReducerState {
  receiptData: IBookingReceiptData;
  additionalServices?: IAdditionalServiceWithAvailability[];
}

const initialState: IBookingReceiptPageReducerState = {
  receiptData: undefined
};

export const bookingReceiptPageReducer = createReducer(initialState, {
  [fetchBookingReceiptDataRoutine.SUCCESS]: (state, { payload }: PayloadAction<IBookingReceiptData>) => {
    state.receiptData = payload;
  },
  [loadBookingReceiptAdditionalServicesWithAvailabilityRoutine.SUCCESS]: (
    state, { payload }: PayloadAction<IAdditionalServiceWithAvailability[]>
  ) => {
    state.additionalServices = payload;
  }
});
