import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { PayloadAction } from '@reduxjs/toolkit';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import {
  completeOrderedServiceRoutine,
  completeOrderRoutine,
  fetchOrdersRoutine
} from '@screens/HavesDashboard/Orders/routines';
import ordersService from '@screens/HavesDashboard/Orders/service/orders.service';
import {
  ICompleteOrderedServiceRequestPayload
} from '@screens/HavesDashboard/Orders/model/CompleteOrderedServiceRequestPayload';

function* tryFetchOrders({ payload }: PayloadAction<IPageRequest>) {
  try {
    const resp = yield call(ordersService.fetchActiveOrders, payload);
    yield put(fetchOrdersRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to load orders', e?.message);
    yield put(fetchOrdersRoutine.failure(e?.message));
  }
}

function* tryCompleteOrderedService({ payload }: PayloadAction<ICompleteOrderedServiceRequestPayload>) {
  try {
    const resp = yield call(ordersService.completeOrderedService, payload);
    yield put(completeOrderedServiceRoutine.success({
      ...resp,
      orderId: payload.orderId
    }));
  } catch (e) {
    toastr.error('Unable to complete service', e?.message);
    yield put(completeOrderedServiceRoutine.failure(e?.message));
  }
}

function* tryCompleteOrder({ payload }: PayloadAction<number>) {
  try {
    const resp = yield call(ordersService.completeOrder, payload);
    yield put(completeOrderRoutine.success(resp));
  } catch (e) {
    toastr.error('Unable to complete order', e?.message);
    yield put(completeOrderRoutine.failure(e?.message));
  }
}

export default function* ordersTableSagas() {
  yield all([
    yield takeLatest(fetchOrdersRoutine.TRIGGER, tryFetchOrders),
    yield takeLatest(completeOrderedServiceRoutine.TRIGGER, tryCompleteOrderedService),
    yield takeLatest(completeOrderRoutine.TRIGGER, tryCompleteOrder)
  ]);
}
