import React from 'react';
import { PopupProps } from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import classNames from 'classnames';
import styles from './styles.module.scss';
import PopupWrapper from '@components/NewDesign/PopupWrapper';

export interface ISearchSelectorPopupProps extends PopupProps {
  mobileFixed?: boolean;
}

const SearchSelectorPopup: React.FC<ISearchSelectorPopupProps> = ({ mobileFixed, ...props }) => (
  <PopupWrapper
    {...props}
    position="bottom center"
    on={[]}
    className={classNames(styles.container, mobileFixed ? styles.mobile_fixed : '')}
  />
);

export default SearchSelectorPopup;
