import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { extractResetPasswordError, extractResetPasswordLoading } from '@screens/Authorization/reducers';
import { resetPasswordRoutine } from '@screens/Authorization/routines';
import { IBindingCallback1 } from '@models/Callbacks';
import { IResetPasswordData } from '@screens/Authorization/models/ResetPasswordVerificationData';
import { extractLocationQuery } from '@helpers/url.helper';
import ResetPasswordForm from '@screens/Authorization/components/ResetPasswordForm';
import DividingLine from '@components/NewDesign/DividingLine';
import SubHeader2 from '@components/NewDesign/Typography/SubHeaders/SubHeader2';
import FormPlaceholder from '@components/NewDesign/Typography/FormAndTable/FormPlaceholder';
import styles from './styles.module.scss';

export interface IResetPasswordPageProps {
  email: string;
  code: string;
  loading: boolean;
  error: string;
  resetPassword: IBindingCallback1<IResetPasswordData>;
}

const ResetPasswordPage: React.FC<IResetPasswordPageProps> = (
  { email, code, loading, error, resetPassword }
) => {
  const handlePasswordReset = useCallback((password: string) => {
    resetPassword({ code, email, password });
  }, [code, email, resetPassword]);

  return (
    <>
      <SubHeader2>Reset password</SubHeader2>
      <DividingLine light className={styles.dividing_line} />
      {error && <Message error>{error}</Message>}
      <FormPlaceholder className={styles.text}>
        By submitting this form, you will set your new password for email&nbsp;
        <b>{email}</b>
      </FormPlaceholder>
      <ResetPasswordForm
        onResetClick={handlePasswordReset}
        resetLoading={loading}
      />
    </>
  );
};

const mapStateToProps = state => ({
  email: extractLocationQuery(state).email,
  code: extractLocationQuery(state).code,
  loading: extractResetPasswordLoading(state),
  error: extractResetPasswordError(state)
});

const mapDispatchToProps = {
  resetPassword: resetPasswordRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
