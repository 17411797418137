import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import {
  createBuildingRoutine,
  fetchAllTagsRoutine,
  fetchBuildingsRoutine,
  setPageRoutine,
  toggleFavoriteBuildingRoutine
} from '@screens/HavesDashboard/Buildings/routines';
import {
  extractBuildings,
  extractCreateBuildingLoading,
  extractFetchAllTagsLoading,
  extractFetchBuildingsLoading,
  extractPage,
  extractPageSize,
  extractTags,
  extractTotalPages
} from '@screens/HavesDashboard/Buildings/reducers';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import { IResultBuildingItem } from '@screens/BrowseSpaces/model/ResultItem';
import { IPageable } from '@models/domain/PageableReducerState';
import ResultGridWithPagination from '@components/ResultGridWithPagination';
import AddBuildingButtonWithModal from '@screens/HavesDashboard/Buildings/components/AddBuildingButtonWithModal';
import { IBuildingForSave } from '@screens/BuildingEditor/model/BuildingForSave';
import { extractCurrentUser } from '@screens/Authorization/reducers';
import { ICurrentUser } from '@screens/Authorization/models/CurrentUser';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import { IAccountLocationState } from '@screens/NeedsDashboard/Account/containers/AccountPage';
import BeforeListYourSpacesContent from '@screens/HavesDashboard/Buildings/components/BeforeYouListYourSpaceContent';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import BodyText3 from '@components/NewDesign/Typography/BodyText/BodyText3';
import styles from './styles.module.scss';
import { useLocation } from 'react-router';

export interface IBuildingsProps extends IPageable {
  currentUser?: ICurrentUser;
  loadBuildings: IBindingCallback1<IPageRequest>;
  buildings: IResultBuildingItem[];
  buildingsLoading: boolean;
  toggleFavorite: IBindingCallback1<string>;
  setPage: IBindingCallback1<number>;
  loadTags: IBindingAction;
  tagsLoading: boolean;
  createBuilding: IBindingCallback1<IBuildingForSave>;
  creationLoading: boolean;
  tags: string[];
}

export interface IBuildingsPageLocationState {
  showChecklist?: boolean;
}

const Buildings: React.FC<IBuildingsProps> = (
  {
    loadBuildings, buildings, buildingsLoading, toggleFavorite, page, setPage, pageSize, totalPages,
    loadTags, tagsLoading, createBuilding, creationLoading, tags, currentUser
  }
) => {
  const [checklistShown, setChecklistShown] = useState<boolean>(false);

  const location = useLocation();
  const locationState = location.state as IBuildingsPageLocationState;

  useEffect(() => {
    if (locationState?.showChecklist) {
      setChecklistShown(true);
    }
  }, [locationState]);

  const managementAllowed = !!currentUser?.hasDetails;

  useEffect(() => {
    if (managementAllowed && loadBuildings) {
      loadBuildings({ page, size: pageSize });
    }
  }, [managementAllowed, loadBuildings, page, pageSize]);

  return (
    <DashboardPageWrapper
      title="Buildings"
      rightElement={
        managementAllowed && (
          <AddBuildingButtonWithModal
            tags={tags}
            loadTags={loadTags}
            tagsLoading={tagsLoading}
            creationLoading={creationLoading}
            createBuilding={createBuilding}
          />
        )
      }
    >
      <GoToButton
        className={styles.before_you_list__button}
        to=""
        onClick={() => setChecklistShown(true)}
      >
        Before you list your space...
      </GoToButton>
      {managementAllowed
        ? (
          <DashboardSectionWrapper title="My shared buildings">
            <ResultGridWithPagination
              setPage={setPage}
              loading={buildingsLoading}
              items={buildings}
              page={page}
              toggleFavorite={toggleFavorite}
              totalPages={totalPages}
            />
          </DashboardSectionWrapper>
        )
        : (
          <div className={styles.bannerWrapper}>
            <div className={styles.banner}>
              <BodyText3>
                The company name is required in order to access this page.
              </BodyText3>
              <GoToButton to={{
                pathname: HAVES_DASHBOARD_ENDPOINTS.ACCOUNT,
                state: {
                  autoFocusInputName: 'companyName'
                } as IAccountLocationState
              }}
              >
                Update profile
              </GoToButton>
            </div>
          </div>
        )}
      {checklistShown && (
        <Modal open onClose={() => setChecklistShown(false)} closeIcon size="large">
          <BeforeListYourSpacesContent onGo={() => setChecklistShown(false)} />
        </Modal>
      )}
    </DashboardPageWrapper>
  );
};

const mapStateToProps = state => ({
  currentUser: extractCurrentUser(state),
  buildings: extractBuildings(state),
  buildingsLoading: extractFetchBuildingsLoading(state),
  page: extractPage(state),
  pageSize: extractPageSize(state),
  totalPages: extractTotalPages(state),
  tagsLoading: extractFetchAllTagsLoading(state),
  creationLoading: extractCreateBuildingLoading(state),
  tags: extractTags(state)
});

const mapDispatchToProps = {
  loadBuildings: fetchBuildingsRoutine,
  toggleFavorite: toggleFavoriteBuildingRoutine,
  setPage: setPageRoutine.fulfill,
  loadTags: fetchAllTagsRoutine,
  createBuilding: createBuildingRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(Buildings);
