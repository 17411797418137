import { toastr } from 'react-redux-toastr';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import * as buildingService from '@screens/BuildingDetails/services/building.service';
import * as bookingService from '@screens/BuildingDetails/services/booking.service';
import {
  bookSpacesRoutine,
  fetchBuildingDetailsRoutine,
  fetchSpaceAvailabilityRoutine,
  sendMessageFromBrowseBuildingDetailsRoutine,
  setWritingMessageFromBrowseBuildingDetailsRoutine,
  toggleFavoriteBuildingRoutine,
  redirectWithBookingStateRoutine,
  toggleResetSelectedSpacesRoutine, loadAdditionalServicesWithAvailabilityRoutine
} from '@screens/BuildingDetails/routines';
import { history } from '@helpers/history.helper';
import { tryToggleFavoriteBuilding } from '@sagas/favorite.building.saga';
import { trySendMessage } from '@screens/HavesDashboard/Dashboard/containers/DashboardPage/sagas';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import { tryLoadAdditionalServicesWithAvailability } from '@screens/BookingCheckout/containers/BookingRootPage/sagas';

export const tryFetchBuildingDetails = (routine: Routine) => function* fetchBuildingDetails({ payload }: Routine<any>) {
  try {
    const resp = yield call(buildingService.fetchBuilding, payload);
    yield put(routine.success(resp));
  } catch (e) {
    toastr.error('Failed to load building details', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const tryFetchSpaceAvailability = (routine: Routine) => function* fetchSpaceAvailability({ payload }: Routine<any>) {
  try {
    if (!payload.dates?.startingDate) {
      return;
    }
    const resp = yield call(buildingService.fetchSpaceAvailability, payload);
    yield put(routine.success(resp));
  } catch (e) {
    toastr.error('Failed to fetch spaces', e?.message);
    yield put(routine.failure(e?.message));
  }
};

export const tryBookSpaces = (
  routine: Routine,
  spaceAvailabilityRoutine: Routine,
  toggleResetSpacesRoutine: Routine
) => function* bookSpaces({ payload }: Routine<any>) {
  try {
    const resp = yield call(bookingService.bookSpaces, payload);
    yield put(routine.success(resp));
    const { location: { pathname, search } } = history;
    history.push(`/booking/${resp}`, { prevPath: `${pathname}`, search });
  } catch (e) {
    toastr.error('Failed to book', e?.message, e?.data);
    yield put(spaceAvailabilityRoutine.trigger({
      spaceIds: payload.spacesToAmount.map(sp => sp.spaceTemplateId),
      dates: payload.dates
    }));
    yield put(toggleResetSpacesRoutine.fulfill());
    yield put(routine.failure(e));
  }
};

export const tryRedirectWithState = (routine: Routine) => function* RedirectWithState({ payload }: Routine<any>) {
  try {
    history.push(`${ENDPOINTS.LOGIN}?redirect=${payload.pathname}`);
    yield put(routine.success(payload));
  } catch (e) {
    toastr.error('Failed to redirect', e?.message, e?.data);
    yield put(routine.failure(e));
  }
};

export default function* buildingDetailsPageSagas() {
  yield all([
    yield takeEvery(fetchBuildingDetailsRoutine.TRIGGER, tryFetchBuildingDetails(fetchBuildingDetailsRoutine)),
    yield takeEvery(fetchSpaceAvailabilityRoutine.TRIGGER, tryFetchSpaceAvailability(fetchSpaceAvailabilityRoutine)),
    yield takeEvery(bookSpacesRoutine.TRIGGER,
      tryBookSpaces(bookSpacesRoutine, fetchSpaceAvailabilityRoutine, toggleResetSelectedSpacesRoutine)),
    yield takeEvery(redirectWithBookingStateRoutine.TRIGGER, tryRedirectWithState(redirectWithBookingStateRoutine)),
    yield takeEvery(toggleFavoriteBuildingRoutine, tryToggleFavoriteBuilding(toggleFavoriteBuildingRoutine)),
    yield takeEvery(sendMessageFromBrowseBuildingDetailsRoutine.TRIGGER, trySendMessage(
      sendMessageFromBrowseBuildingDetailsRoutine, setWritingMessageFromBrowseBuildingDetailsRoutine
    )),
    yield takeEvery(loadAdditionalServicesWithAvailabilityRoutine.TRIGGER,
      tryLoadAdditionalServicesWithAvailability(loadAdditionalServicesWithAvailabilityRoutine))
  ]);
}
