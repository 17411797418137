import React, { useRef, useState } from 'react';
import { ISpaceTemplateDto } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import SpaceTemplateItem from '@screens/BuildingEditor/components/SpacesEditingTab/SpaceTemplateItem';
import AdditiveListView from '@components/AdditiveListView';
import SpaceCreationModal from '@screens/BuildingEditor/components/SpacesEditingTab/SpaceCreationModal';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import SpaceEditorModal from '@components/NewDesign/Spaces/SpaceEditorModal';
import { ISpaceTemplateModificationRequest } from '@components/NewDesign/Spaces/SpacesEditingForm';

export interface ISpacesEditingTabProps {
  spaceTemplates: ISpaceTemplateDto[];
  loading: boolean;
  createSpace: IBindingCallback1<ISpaceTemplateModificationRequest>;
  spaceCreationLoading: boolean;
}

const SpacesEditingTab: React.FC<ISpacesEditingTabProps> = (
  { spaceTemplates, loading, spaceCreationLoading, createSpace }
) => {
  const triggerOpenModalRef = useRef<IBindingAction>();
  const [spaceEditor, setSpaceEditor] = useState<string>();

  return (
    <>
      <SpaceCreationModal
        triggerOpenRef={triggerOpenModalRef}
        createSpace={createSpace}
        creationLoading={spaceCreationLoading}
      />
      <AdditiveListView
        loading={loading}
        items={spaceTemplates}
        onAddClick={() => {
          triggerOpenModalRef.current();
        }}
        renderItem={st => <SpaceTemplateItem spaceTemplate={st} />}
        onItemClick={st => setSpaceEditor(st.id)}
        placeholderText="No spaces created"
      />
      <SpaceEditorModal
        spaceEditor={spaceEditor}
        setSpaceEditor={setSpaceEditor}
      />
    </>
  );
};

export default SpacesEditingTab;
