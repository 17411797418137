import React from 'react';
import { Menu } from 'semantic-ui-react';
import { VirtualAddressType } from '@models/domain/space/VirtualAddressType';
import { ISearchSelector } from '../model/SearchSelector';
import styles from './styles.module.scss';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';

export interface IVirtualAddressCategorySelectorProps extends ISearchSelector {
  value: VirtualAddressType;
}

const VirtualAddressCategorySelector: React.FC<IVirtualAddressCategorySelectorProps> = ({ value, onChange }) => {
  const handleTypeSelect = (type: VirtualAddressType) => {
    onChange(type);
  };

  return (
    <div className={styles.container}>
      <Menu vertical secondary fluid>
        <Menu.Item
          className={styles.item}
          name="BUSINESS_ADDRESS"
          active={value === VirtualAddressType.BUSINESS_ADDRESS}
          onClick={() => handleTypeSelect(VirtualAddressType.BUSINESS_ADDRESS)}
        >
          <Caption2>Business Address</Caption2>
        </Menu.Item>

        <Menu.Item
          className={styles.item}
          name="PACKAGE_DELIVERY"
          active={value === VirtualAddressType.PACKAGE_DELIVERY}
          onClick={() => handleTypeSelect(VirtualAddressType.PACKAGE_DELIVERY)}
        >
          <Caption2>Parcel/Package Delivery</Caption2>
        </Menu.Item>

        <Menu.Item
          className={styles.item}
          name="POSTAL_DELIVERY"
          active={value === VirtualAddressType.POSTAL_DELIVERY}
          onClick={() => handleTypeSelect(VirtualAddressType.POSTAL_DELIVERY)}
        >
          <Caption2>Postal Delivery</Caption2>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default VirtualAddressCategorySelector;
