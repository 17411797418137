/* eslint-disable max-len */
import { createRoutine } from 'redux-saga-routines';

const createMessageCenterRoutine = <T extends unknown>(actionName: string) => createRoutine<T>(`ADMIN_DASHBOARD__MESSAGE_CENTER:${actionName}`);

/* PlopJS routine placeholder. Do not remove */
export const readMessageFromMessageCenterRoutine = createMessageCenterRoutine('READ_MESSAGE_FROM_MESSAGE_CENTER');
export const setWritingMessageFromMessageCenterRoutine = createMessageCenterRoutine('SET_WRITING_MESSAGE_FROM_MESSAGE_CENTER');
export const setMessageFullFromMessageCenterRoutine = createMessageCenterRoutine('SET_MESSAGE_FULL_FROM_MESSAGE_CENTER');
export const sendMessageFromMessageCenterRoutine = createMessageCenterRoutine('SEND_MESSAGE_FROM_MESSAGE_CENTER');
export const fetchMessageCenterMessagesRoutine = createMessageCenterRoutine('FETCH_MESSAGE_CENTER_MESSAGES');
export const setMessageCenterMessagesPageRoutine = createMessageCenterRoutine('SET_MESSAGE_CENTER_MESSAGES_PAGE');
