export const ENDPOINTS = {
  ROOT: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  USER: '/user',
  OAUTH_REDIRECT: '/oauth/redirect',
  VERIFY: '/verify',
  RESET: '/reset',
  HOW_IT_WORKS: '/how_it_works',
  PARTNER_WITH_US: '/partner_with_us',
  JOIN_OUR_TEAM: '/join_our_team',
  CONTACT_US: '/contact_us',
  PARTNER_PAGE: '/partner_page',
  EXPERT_PAGE: '/expert_page',
  TERMS_AND_CONDITIONS: '/terms_and_conditions',
  OUR_STORY: '/our_story',
  BROWSE: '/browse',
  DETAILS: id => `/details/${id}`,
  BOOKING: id => `/booking/${id}`,
  SERVICE_ORDER_CHECKOUT: id => `/service_order/${id}`,
  BOOKING_RECEIPT: id => `/booking_receipt/${id}`,
  SERVICE_ORDER_RECEIPT: id => `/service_order_receipt/${id}`
};
