import React from 'react';
import { Placeholder, PlaceholderImage, PlaceholderLine } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import common from '../common.module.scss';
import LikeButton from '@components/LikeButton';
import { IBindingAction } from '@models/Callbacks';
import { LocationDescriptor } from 'history';
import ImageContainer from '@components/ImageContainer';
import BodyText1 from '@components/NewDesign/Typography/BodyText/BodyText1';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import { BuildingType } from '@models/domain/BuildingType';
import TableFillerContent from '@components/NewDesign/Typography/FormAndTable/TableFillerContent';

export interface IBuildingListCardProps {
  imageSrc: string;
  name: string;
  type?: BuildingType;
  meta?: string;
  liked: boolean;
  toggleLike: IBindingAction;
  likeLoading: boolean;
  linkLocation: LocationDescriptor;
  loading?: boolean;
  address?: string;
}

const BuildingListCard: React.FC<IBuildingListCardProps> = (
  { imageSrc, name, type, liked, loading,
    toggleLike, likeLoading, linkLocation, address }
) => (
  <div className={styles.container}>
    {
      loading ? (
        <Placeholder>
          <PlaceholderLine length="very short" />
        </Placeholder>
      ) : (
        <div>
          <LikeButton liked={liked} onToggleLike={toggleLike} loading={likeLoading} className={styles.like} />
        </div>
      )
    }
    <Link to={linkLocation} className={styles.link_area}>
      {loading ? (
        <Placeholder className={styles.image}>
          <PlaceholderImage square />
        </Placeholder>
      ) : (
        <ImageContainer src={imageSrc} className={styles.image} />
      )}
      {loading ? (
        <Placeholder className={styles.name}>
          <PlaceholderLine />
        </Placeholder>
      ) : (
        <BodyText1 disableTranslation className={`${styles.name} ${common.no_overflow}`}>{name}</BodyText1>
      )}
      {loading ? (
        <Placeholder className={styles.type}>
          <PlaceholderLine />
        </Placeholder>
      ) : (
        <Caption2 className={`${styles.type} ${common.no_overflow}`}>{type}</Caption2>
      )}
      {loading ? (
        <Placeholder className={styles.address}>
          <PlaceholderLine />
        </Placeholder>
      ) : (
        <TableFillerContent disableTranslation className={`${styles.address} ${common.no_overflow}`}>
          {address}
        </TableFillerContent>
      )}
    </Link>
  </div>
);

export default BuildingListCard;
