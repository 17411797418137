import React from 'react';
import { connect } from 'react-redux';
import { extractSendResetPasswordError, extractSendResetPasswordLoading } from '@screens/Authorization/reducers';
import { IBindingCallback1 } from '@models/Callbacks';
import { sendResetPasswordRoutine } from '@screens/Authorization/routines';
import ForgottenPasswordForm from '@screens/Authorization/components/ForgottenPasswordForm';
import { MessageWithTranslate } from '@components/WithTranslate/MessageWithTranslate';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import DividingLine from '@components/NewDesign/DividingLine';
import styles from './styles.module.scss';

export interface IForgottenPasswordPageProps {
  requestLoading: boolean;
  errorMessage: string;
  sendResetPassword: IBindingCallback1<string>;
}

const ForgottenPasswordPage: React.FC<IForgottenPasswordPageProps> = (
  { requestLoading, errorMessage, sendResetPassword }
) => (
  <>
    <SubHeader3 marginBottom className={styles.header}>Password Reset</SubHeader3>
    <DividingLine light className={styles.dividing_line} />
    {errorMessage && <MessageWithTranslate error>{errorMessage}</MessageWithTranslate>}
    <BodyText2 className={styles.text}>
      Forgot your password?  No sweat!
      <br />
      Please enter your email address below, and we will send you password reset instructions:
    </BodyText2>
    <ForgottenPasswordForm sendResetPassword={sendResetPassword} requestLoading={requestLoading} />
  </>
);
const mapStateToProps = state => ({
  requestLoading: extractSendResetPasswordLoading(state),
  errorMessage: extractSendResetPasswordError(state)
});

const mapDispatchToProps = {
  sendResetPassword: sendResetPasswordRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPasswordPage);
