import React from 'react';
import classNames from 'classnames';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import styles from './styles.module.scss';

export interface ITransactionFeeProps {
  feeAmount: string | number;
  className?: string;
}

const TransactionFee: React.FC<ITransactionFeeProps> = ({ feeAmount, className }) => (
  <div className={classNames(styles.fee, className)}>
    <div className={styles.label}>
      <Caption2>
        Transaction fee:
      </Caption2>
    </div>
    <div className={styles.value}>
      <Caption2>
        {feeAmount}
      </Caption2>
    </div>
  </div>
);

export default TransactionFee;
