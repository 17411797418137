import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import FormikInput from '@components/formik/Input';
import { stateNullableOptions } from '@models/domain/CountryState';
import GoogleAutocompleteInput, { IGooglePlace } from '@components/GoogleAutocompleteInput';
import { IBindingCallback1, IBindingCallback2 } from '@models/Callbacks';
import GoogleMap from '@components/GoogleMap';
import ErrorMessageContainer from '@components/NewDesign/ErrorMessageContainer';
import FormGroupWrapper from '@components/NewDesign/Form/FormGroupWrapper';
import FormikSelectInline from '@components/formik/select/SelectInline';
import FormPlaceholder from '@components/NewDesign/Typography/FormAndTable/FormPlaceholder';
import GoToButton from '@components/NewDesign/Button/GoToButton';

export interface IAddressFormPartProps<T extends IAddressValues> {
  values?: T;
  initialValues?: T;
  setValues?: IBindingCallback1<IBindingCallback1<T>>;
  showErrorMessage?: boolean;
  setFieldValue: IBindingCallback2<string, any>;
  setFieldTouched: IBindingCallback2<string, boolean>;
}

interface IAddressValues {
  address1: string;
  address2: string;
  state: string;
  city: string;
  country: string;
  zip: string;
  latitude: string;
  longitude: string;
}

export function AddressFormPart<T extends IAddressValues>(
  { values, initialValues, setValues, showErrorMessage,
    setFieldValue, setFieldTouched }: IAddressFormPartProps<T>
) {
  const [disabledFields, setDisabledFields] = useState({} as any);
  const [isAutocompleteTriggered, setIsAutocompleteTriggered] = useState<boolean>(false);
  const [isManualFilling, setIsManualFilling] = useState<boolean>(false);
  const [isManualFillingWasUsed, setIsManualFillingWasUsed] = useState<boolean>(false);

  useEffect(() => {
    if (isManualFilling) {
      setDisabledFields({
        address1: false,
        address2: false,
        city: false,
        zip: false,
        state: false,
        country: false
      });
    } else {
      setDisabledFields({
        address1: true,
        address2: true,
        city: true,
        zip: true,
        state: true,
        country: true
      });
    }
  }, [isManualFilling]);

  const resetAddressValues = useCallback(() => {
    const { address1, address2, city, zip, state, country, latitude, longitude } = initialValues;

    setValues(prevValues => ({
      ...prevValues,
      address1,
      address2,
      city,
      zip,
      state,
      country,
      latitude,
      longitude
    }));
  }, [initialValues, setValues]);

  const handleManualFillingOpen = useCallback(() => {
    if (!isManualFillingWasUsed) {
      setIsManualFillingWasUsed(true);
    }

    setIsManualFilling(true);

    setValues(prevValues => ({
      ...prevValues,
      latitude: null,
      longitude: null
    }));
  }, [isManualFillingWasUsed, setValues]);

  const handleGoogleFillingOpen = useCallback(() => {
    setIsManualFilling(false);

    if (isManualFillingWasUsed) {
      resetAddressValues();
    }
  }, [isManualFillingWasUsed, resetAddressValues]);

  const handlePlaceChange = useCallback((place: IGooglePlace) => {
    const { location } = place.geometry;
    let address1 = '';
    let zip = '';
    let city = '';
    let state = '';
    let country = '';

    place.address_components.forEach(component => {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number':
          address1 = `${component.long_name} ${address1}`;
          break;
        case 'route':
          address1 += component.short_name;
          break;
        case 'postal_code':
          zip = `${component.long_name}${zip}`;
          break;
        case 'postal_code_suffix':
          zip = `${zip}-${component.long_name}`;
          break;
        case 'locality':
          city = component.long_name;
          break;
        case 'administrative_area_level_1':
          state = component.short_name;
          break;
        case 'country':
          country = component.short_name;
          break;
        default:
          break;
      }
    });

    setDisabledFields({
      address1: !!address1,
      address2: false,
      city: !!city,
      zip: !!zip,
      state: !!state,
      country: !!country
    });

    setValues(prevValues => ({
      ...prevValues,
      address1,
      address2: '',
      city,
      zip,
      state,
      country,
      latitude: location.lat().toString(),
      longitude: location.lng().toString()
    }));

    setIsAutocompleteTriggered(true);
  }, [setValues]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title_container}>
        <FormPlaceholder className={styles.address_title}>
          Address
        </FormPlaceholder>
        <ManualFillingButton
          isManualFilling={isManualFilling}
          onManualFillingOpen={handleManualFillingOpen}
          onGoogleFillingOpen={handleGoogleFillingOpen}
          isAutocompleteTriggered={isAutocompleteTriggered}
        />
      </div>
      {showErrorMessage && (
        <ErrorMessageContainer errorMessage="Address should be filled" className={styles.error} />
      )}
      {!isManualFilling && (
        <GoogleAutocompleteInput
          onPlaceChange={handlePlaceChange}
          clearOnSelect
          placeholder="Start typing to choose new address"
          resultType="address"
        />
      )}
      <FormGroupWrapper widths="equal">
        <FormikInput
          propsOrFieldName={{
            name: 'address1',
            placeholder: 'Street address',
            required: true,
            disabled: disabledFields.address1
          }}
          semanticProps={{
            label: 'Address Line 1',
            className: styles.no_margin
          }}
        />
        <FormikInput
          propsOrFieldName={{
            name: 'address2',
            placeholder: 'Suite/Apt. number',
            disabled: disabledFields.address2
          }}
          semanticProps={{
            label: 'Address Line 2',
            className: styles.no_margin
          }}
        />
      </FormGroupWrapper>
      <FormGroupWrapper widths="equal">
        <FormikInput
          propsOrFieldName={{
            name: 'city',
            placeholder: 'City',
            required: true,
            disabled: disabledFields.city
          }}
          semanticProps={{
            label: 'City'
          }}
        />
        <FormikSelectInline
          propsOrFieldName={{
            name: 'state',
            placeholder: 'State',
            required: true,
            disabled: disabledFields.state
          }}
          semanticProps={{
            options: stateNullableOptions,
            required: true,
            label: 'State',
            disabled: disabledFields.state
          }}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      </FormGroupWrapper>
      <FormGroupWrapper widths="equal">
        <FormikInput
          propsOrFieldName={{
            name: 'zip',
            placeholder: 'State zip',
            required: true,
            disabled: disabledFields.zip
          }}
          semanticProps={{
            label: 'ZIP'
          }}
        />
        <FormikInput
          propsOrFieldName={{
            name: 'country',
            placeholder: 'Country',
            required: true,
            disabled: disabledFields.country
          }}
          semanticProps={{
            label: 'Country'
          }}
        />
      </FormGroupWrapper>
      {!isManualFilling && values?.latitude && values?.longitude && (
        <GoogleMap location={{ lng: Number.parseFloat(values.longitude), lat: Number.parseFloat(values.latitude) }} />
      )}
    </div>
  );
}

const ManualFillingButton = ({
  isManualFilling,
  onManualFillingOpen,
  onGoogleFillingOpen,
  isAutocompleteTriggered
}) => {
  if (!isAutocompleteTriggered) {
    return null;
  }

  return isManualFilling ? (
    <GoToButton to="" onClick={onGoogleFillingOpen} className={styles.manual_button}>
        Use google search
    </GoToButton>
  ) : (
    <GoToButton to="" onClick={onManualFillingOpen} className={styles.manual_button}>
        Can&apos;t find the address? Enter manually
    </GoToButton>
  );
};

export default AddressFormPart;
