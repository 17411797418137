import React from 'react';
import { IWrapperProps } from '@components/InformationSection';
import { IBindingCallback1 } from '@models/Callbacks';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import { IMessageDto } from '@models/domain/message/MessageDto';
import FullMessageModal from '@components/MessagesSection/FullMessageModal';
import TableWrapper from '@components/NewDesign/Table/TableWrapper';

export interface IMessageTableWrapperProps extends IWrapperProps {
  writing: boolean;
  setWriting: IBindingCallback1<boolean>;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  sendMessageLoading: boolean;
  messageFull?: IMessageDto;
  setMessageFull: IBindingCallback1<IMessageDto | undefined>;
  messageCenterLink?: string;
}

const MessageTableWrapper: React.FC<IMessageTableWrapperProps> = (
  { children, sendMessage, sendMessageLoading, setMessageFull, messageFull }
) => (
  <>
    <TableWrapper>
      {children}
    </TableWrapper>
    <FullMessageModal
      message={messageFull}
      onClose={() => setMessageFull(undefined)}
      onSend={sendMessage}
      sendLoading={sendMessageLoading}
    />
  </>
);

export default MessageTableWrapper;
