import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import ResultsWithPagination from '@components/ResultsWithPagination';
import { IBindingCallback1 } from '@models/Callbacks';
import { IPageRequest } from '@screens/NeedsDashboard/BookedSpaces/model/PageableRequest';
import { IPageable } from '@models/domain/PageableReducerState';
import NoDataContainer from '@components/NoDataContainer';
import NewTable, { INewTableProps } from '@components/NewDesign/Table/NewTable';

export interface ITableWithSchedulesProps extends IPageable {
  bookingsLoading: boolean;
  setPage: IBindingCallback1<number>;
  tableProps: INewTableProps;
  loadBookings?: IBindingCallback1<IPageRequest>;
}

const TableWithSchedules: React.FC<ITableWithSchedulesProps> = (
  { totalPages, page, bookingsLoading, tableProps, setPage, loadBookings, pageSize }
) => {
  useEffect(() => {
    if (typeof loadBookings === 'function') {
      loadBookings({ page, size: pageSize });
    }
  }, [loadBookings, page, pageSize]);

  return (
    <>
      {bookingsLoading && <Loader active inline="centered" />}
      {!bookingsLoading && (
        <ResultsWithPagination loading={bookingsLoading} setPage={setPage} totalPages={totalPages} page={page}>
          <NewTable {...tableProps} />
        </ResultsWithPagination>
      )}
      {!bookingsLoading && { ...tableProps }.rows.length === 0
        ? <NoDataContainer message="You do not have any Bookings & Scheduling yet" />
        : ''}
    </>
  );
};

export default TableWithSchedules;
