import React from 'react';
import { LabelGroup } from 'semantic-ui-react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';
import { IServiceOrderDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderDto';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import { DATE_FORMAT } from '@helpers/date.helper';
import OrderStatusLabel from '@components/OrderStatusLabel';
import styles from './styles.module.scss';

export interface IOrderDetailsProps {
  order: IServiceOrderDto;
}

const OrderDetails: React.FC<IOrderDetailsProps> = ({ order }) => (
  <div className={styles.container}>
    <SubHeader1 className={styles.subheader}>Order</SubHeader1>
    <LabelGroupWrapper
      wrapper={LabelGroup}
      title="Status"
      value={(<OrderStatusLabel status={order.status} createdAt={order.createdAt} />)}
    />
    <LabelGroupWrapper
      wrapper={LabelGroup}
      title="Scheduled On"
      value={moment(order.scheduledOnDate).format(DATE_FORMAT)}
    />
    {!isEmpty(order.note) && (
      <LabelGroupWrapper
        title="Notes"
        value={order.note}
      />
    )}
  </div>
);

export default OrderDetails;
