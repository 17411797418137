import React from 'react';
import { Form } from 'semantic-ui-react';
import { IBindingCallback1 } from '@models/Callbacks';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import FormikInput from '@components/formik/Input';
import FormikTextArea from '@components/formik/TextArea';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import styles from './styles.module.scss';

interface IWritingMessageFormProps {
  onSend: IBindingCallback1<ISendMessageDto>;
  loading: boolean;
  initValues?: Partial<IFormValues>;
  hideTopic?: boolean;
  receiverId: string;
}

export interface IFormValues {
  topic: string;
  text: string;
}

const validationSchema = Yup.object().shape({
  topic: Yup.string().trim().required(),
  text: Yup.string().trim().required()
});

const WritingMessageForm: React.FC<IWritingMessageFormProps & FormikProps<IFormValues>> = (
  { handleSubmit, loading, hideTopic }
) => (
  <Form onSubmit={handleSubmit}>
    {!hideTopic && (
      <FormikInput
        propsOrFieldName={{
          name: 'topic',
          placeholder: 'Message topic',
          required: true
        }}
        semanticProps={{
          label: 'Topic',
          className: styles.topicInput
        }}
      />
    )}
    <FormikTextArea
      propsOrFieldName={{
        name: 'text',
        placeholder: 'Message text',
        required: true
      }}
      semanticProps={{
        label: 'Text'
      }}
    />
    <div>
      <PrimaryButton
        content="Send"
        type="submit"
        loading={loading}
      />
    </div>
  </Form>
);

export default withFormik<IWritingMessageFormProps, IFormValues>({
  displayName: 'SendMessageForm',
  validationSchema,
  handleSubmit: ((values, { props }) => {
    props.onSend({ ...values, receiverId: props.receiverId });
  }),
  mapPropsToValues: ({ initValues }) => ({
    topic: initValues?.topic || '',
    text: initValues?.text || ''
  })
})(WritingMessageForm);
