import { combineReducers } from 'redux';
import { reducerCreator } from '@helpers/reducer.helper';
import { joinOurTeamReducer } from '@screens/static/JoinOurTeam/containers/JoinOurTeamPage/reducer';
/* PlopJS import placeholder. Do not remove */
import { submitJoinOurTeamRoutine } from '@screens/static/routines';
import { RootState } from '@root/store';

const requests = combineReducers({
  /* PlopJS request placeholder. Do not remove */
  submitJoinOurTeamFormRequest: reducerCreator([submitJoinOurTeamRoutine.TRIGGER])
});

export default combineReducers({
  requests,
  data: joinOurTeamReducer
});

const reqs = (state: RootState) => state.staticPage.joinOurTeamReducer.requests;
const data = (state: RootState) => state.staticPage.joinOurTeamReducer.data;

export const extractJoinOurTeamData = state => data(state);

/* PlopJS request_extractor placeholder. Do not remove */
export const extractSubmitJoinOurTeamFormLoading = state => reqs(state).submitJoinOurTeamFormRequest.loading;
export const extractSubmitJoinOurTeamFormError = state => reqs(state).submitJoinOurTeamFormRequest.error;
