export const termsAndConditions = path => `/terms_and_conditions${path === '' ? '' : `/${path}`}`;

export const TERMS_AND_CONDITIONS_ENDPOINTS = {
  BASIC_REQUIREMENTS_FOR_HOSTS: termsAndConditions('basic_requirements_for_hosts'),
  COMMUNITY_STANDARDS: termsAndConditions('community_standards'),
  CONTENT_POLICY: termsAndConditions('content_policy'),
  COOKIE_POLICY: termsAndConditions('cookie_policy'),
  COPYRIGHT_POLICY: termsAndConditions('copyright_policy'),
  DATA_SUBJECT_RIGHTS: termsAndConditions('data_subject_rights'),
  DISPUTE_MODERATION_FOR_REVIEWS: termsAndConditions('dispute_moderation_for_reviews'),
  EXTENUATING_CIRCUMSTANCES_POLICY: termsAndConditions('extenuating_circumstances_policy'),
  HOST_PRIVACY_STANDARDS: termsAndConditions('host_privacy_standards'),
  LAW_ENFORCEMENT_GUIDELINES: termsAndConditions('law_enforcement_guidelines'),
  NONDISCRIMINATION_POLICY: termsAndConditions('nondiscrimination_policy'),
  OFF_PLATFORM_POLICY: termsAndConditions('off_platform_policy'),
  OPTIMIZING_SEARCH_RESULTS: termsAndConditions('optimizing_search_results'),
  PAYMENT_TERMS_OF_SERVICE: termsAndConditions('payments_terms_of_service'),
  PRIVACY_POLICY: termsAndConditions('privacy_policy'),
  PRIVACY_SUPPLEMENT_OUTSIDE_OF_THE_US: termsAndConditions('privacy_supplement_outside_the_us'),
  REFERRAL_PROGRAM_TERMS_AND_CONDITIONS: termsAndConditions('referral_program_terms_and_conditions'),
  REVIEW_POLICY: termsAndConditions('review_policy'),
  SERVICE_FEES: termsAndConditions('service_fees'),
  SMS_TERMS_FOR_THE_US: termsAndConditions('sms_terms'),
  TERMS_AND_CONDITIONS: termsAndConditions(''),
  TERMS_AND_POLICIES_LIST: termsAndConditions('terms_and_policies_list'),
  WEATHER_EVENTS_EXCLUDED_FROM_EXTENUATING: termsAndConditions('weather_events')
};
