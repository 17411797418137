/* eslint-disable max-len */
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getInitialPageableState } from '@models/domain/PageableReducerState';
import { IPageableResult } from '@models/domain/PageableResult';
import {
  fetchOrdersRoutine,
  setCurrentPageRoutine
} from '@screens/NeedsDashboard/Orders/routines';
import { IServiceOrderDetailsDto } from '@screens/NeedsDashboard/Orders/model/ServiceOrderDetailsDto';
import { logOutRoutine } from '@screens/Authorization/routines';

export const ordersTableReducer = createReducer(getInitialPageableState<IServiceOrderDetailsDto>(), {
  [fetchOrdersRoutine.SUCCESS]: (state, { payload }: PayloadAction<IPageableResult<IServiceOrderDetailsDto>>) => {
    state.items = payload.items;
    state.totalPages = payload.totalPages;
    state.totalResults = payload.totalResults;
  },
  [setCurrentPageRoutine.FULFILL]: (state, { payload }) => {
    state.page = payload;
  },
  [logOutRoutine.SUCCESS]: () => getInitialPageableState<IServiceOrderDetailsDto>()
});
