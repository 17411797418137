import { all } from 'redux-saga/effects';
import serviceOrderRootPageSagas from '@screens/ServiceOrderCheckout/containers/ServiceOrderRootPage/sagas';
import orderSubmissionAndSummaryStepSagas
  from '@screens/ServiceOrderCheckout/containers/ServiceOrderCheckoutPage/SubmissionAndSummaryStep/sagas';
import orderDetailsPageSagas
  from '@screens/ServiceOrderCheckout/containers/ServiceOrderDetailsPage/TransactionsBlock/sagas';
import orderCheckoutPageSagas from '@screens/ServiceOrderCheckout/containers/ServiceOrderCheckoutPage/sagas';

export default function* serviceOrderSagas() {
  yield all([
    orderSubmissionAndSummaryStepSagas(),
    serviceOrderRootPageSagas(),
    orderDetailsPageSagas(),
    orderCheckoutPageSagas()
  ]);
}
