import React from 'react';
import { connect } from 'react-redux';
import PopularBuildingsSection from '@screens/AdminDashboard/Dashboard/components/PopularBuildingsSection';
import TransactionsSection from '@screens/NeedsDashboard/Dashboard/components/TransactionsSection';
import MessagesSection from '@components/MessagesSection';
import {
  fetchDashboardMessagesRoutine,
  sendMessageFromDashboardRoutine,
  setWritingMessageFromDashboardRoutine,
  readMessageFromDashboardRoutine,
  setMessageFullFromDashboardRoutine
} from '@screens/AdminDashboard/Dashboard/routines';
import {
  extractMyMessages,
  extractFetchDashboardMessagesLoading,
  extractMoreMessages,
  extractWriting,
  extractSendMessageFromDashboardLoading,
  extractFullMessage
} from '@screens/AdminDashboard/Dashboard/reducers';
import { ADMIN_DASHBOARD_ENDPOINTS } from '@screens/AdminDashboard/Root/components/Routing/endpoints';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { IMessageDto } from '@models/domain/message/MessageDto';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import styles from '@screens/NeedsDashboard/Dashboard/containers/DashboardPage/styles.module.scss';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';

export interface IDashboardProps {
  messages: IMessageDto[];
  fetchMyMessages: IBindingAction;
  messagesLoading: boolean;
  moreMessages: boolean;
  writing: boolean;
  sendMessageLoading: boolean;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  setWriting: IBindingCallback1<boolean>;
  readMessage: IBindingCallback1<string>;
  messageFull?: IMessageDto;
  setMessageFull: IBindingCallback1<IMessageDto | undefined>;
}

const Dashboard: React.FC<IDashboardProps> = ({
  messages, fetchMyMessages, messagesLoading, moreMessages, writing, sendMessageLoading,
  sendMessage, setWriting, readMessage, messageFull, setMessageFull
}) => (
  <DashboardPageWrapper title="Dashboard">
    <DashboardSectionWrapper>
      <MessagesSection
        items={messages}
        fetchItems={fetchMyMessages}
        itemsLoading={messagesLoading}
        haveMore={moreMessages}
        writing={writing}
        sendMessageLoading={sendMessageLoading}
        sendMessage={sendMessage}
        setWriting={setWriting}
        readMessage={readMessage}
        messageFull={messageFull}
        setMessageFull={setMessageFull}
        messageCenterLink={ADMIN_DASHBOARD_ENDPOINTS.MESSAGES}
      />
    </DashboardSectionWrapper>
    <DashboardSectionWrapper>
      <PopularBuildingsSection />
    </DashboardSectionWrapper>
    <DashboardSectionWrapper title="Recent Transactions">
      <TransactionsSection
        items={[]}
        fetchItems={() => console.warn('TODO: implement loading transactions')}
        itemsLoading={false}
        haveMore={false}
        placeholderClassName={styles.transactionSection}
      />
    </DashboardSectionWrapper>
  </DashboardPageWrapper>
);

const mapStateToProps = state => ({
  messages: extractMyMessages(state),
  messagesLoading: extractFetchDashboardMessagesLoading(state),
  moreMessages: extractMoreMessages(state),
  writing: extractWriting(state),
  sendMessageLoading: extractSendMessageFromDashboardLoading(state),
  messageFull: extractFullMessage(state)
});

const mapDispatchToProps = {
  fetchMyMessages: fetchDashboardMessagesRoutine,
  sendMessage: sendMessageFromDashboardRoutine,
  setWriting: setWritingMessageFromDashboardRoutine.fulfill,
  readMessage: readMessageFromDashboardRoutine,
  setMessageFull: setMessageFullFromDashboardRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
