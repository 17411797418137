import React, { memo } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import ErrorPopup, { IPopupPosition } from '@components/FormErrorPopup';
import { FieldHookConfig } from 'formik/dist/Field';
import { useTranslation } from '@root/hooks/use-translation';
import styles from './styles.module.scss';

export interface ICommonInputProps<T extends HTMLElement, SemanticProps> {
  propsOrFieldName: string | FieldHookConfig<T>;
  semanticProps?: SemanticProps;
  popupPosition?: IPopupPosition;
  element?: React.FC<SemanticProps> | React.ComponentClass<SemanticProps>;
}

function CommonInput<T extends HTMLElement, SemanticProps>(
  { popupPosition, propsOrFieldName, semanticProps, element: Element }: ICommonInputProps<T, SemanticProps>
) {
  const { t } = useTranslation();

  return (
    <Field name={(propsOrFieldName as any)?.name}>
      {({
        field,
        meta
      }) => {
        const isError = !!(meta.touched && meta.error);

        return (
          <ErrorPopup
            open={isError}
            label={meta.error}
            position={popupPosition}
            component={(
              <Element
                error={isError}
                {...field}
                {...semanticProps}
                {...propsOrFieldName}
                label={t((semanticProps as any)?.label)}
                placeholder={t((propsOrFieldName as any)?.placeholder)}
                value={field.value || ''}
                className={classNames(
                  (semanticProps as any)?.className,
                  (propsOrFieldName as any)?.className,
                  styles.element
                )}
              />
            )}
          />
        );
      }}
    </Field>
  );
}

export default memo(CommonInput);
