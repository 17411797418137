import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import { toastr } from 'react-redux-toastr';
import { history } from '@helpers/history.helper';
import { selectRoles } from '@screens/Authorization/services/role_selection.service';
import { selectRolesRoutine } from '@screens/Authorization/routines';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';
import { Role } from '@screens/Authorization/models/Roles';

function* trySelectRole({ payload }: Routine<any>) {
  try {
    const resp = yield call(selectRoles, payload);
    yield put(selectRolesRoutine.success(resp));
    history.push(payload === Role.NEED
      ? NEEDS_DASHBOARD_ENDPOINTS.ACCOUNT
      : HAVES_DASHBOARD_ENDPOINTS.ACCOUNT);
    toastr.success('Success', 'Role selected!');
  } catch (e) {
    yield put(selectRolesRoutine.failure(e?.message));
    toastr.error('Failed to select role', e?.message);
  }
}

export default function* selectRolePageSagas() {
  yield all([
    yield takeEvery(selectRolesRoutine.TRIGGER, trySelectRole)
  ]);
}
