import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Routine } from 'redux-saga-routines';
import { toastr } from 'react-redux-toastr';
import {
  createBookingReceiptAdditionalServicesOrderRoutine,
  fetchBookingReceiptDataRoutine,
  loadBookingReceiptAdditionalServicesWithAvailabilityRoutine } from '@screens/ReceiptPage/routines';
import { history } from '@helpers/history.helper';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import receiptService from '@screens/ReceiptPage/services/receipt.service';

import {
  tryCreateAdditionalServicesOrder,
  tryLoadAdditionalServicesWithAvailability
} from '@screens/BookingCheckout/containers/BookingRootPage/sagas';

export const tryFetchBookingReceiptData = (routine: Routine) => function* fetchBookingReceiptData({ payload }: Routine<any>) {
  try {
    const response = yield call(receiptService.fetchBookingReceiptData, payload);
    yield put(routine.success(response));
  } catch (e) {
    toastr.error('Failed to load booking receipt data', e?.message);
    yield put(routine.failure(e?.message));
    history.push(ENDPOINTS.BROWSE);
  }
};

export default function* bookingReceiptPagePageSagas() {
  yield all([
    yield takeEvery(fetchBookingReceiptDataRoutine.TRIGGER, tryFetchBookingReceiptData(fetchBookingReceiptDataRoutine)),
    yield takeEvery(
      createBookingReceiptAdditionalServicesOrderRoutine.TRIGGER,
      tryCreateAdditionalServicesOrder(createBookingReceiptAdditionalServicesOrderRoutine)
    ),
    yield takeEvery(
      loadBookingReceiptAdditionalServicesWithAvailabilityRoutine.TRIGGER,
      tryLoadAdditionalServicesWithAvailability(loadBookingReceiptAdditionalServicesWithAvailabilityRoutine)
    )
  ]);
}
